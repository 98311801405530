import React from 'react'
import { BillingHistory } from './billing-history/BillingHistory'
import { OrderList } from './order-list/OrderList'
import styles from './styles.module.scss'

export const OrdersDesktopPage = () => {
  return (
    <div className={styles.Container}>
      <OrderList />
      <BillingHistory />
    </div>
  )
}
