import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { useSiteVersion } from '../../../../../app/hooks/useSiteVersion'
import { RoutePath } from '../../../../../config/routes/constants/routePath'
import { Route } from '../../../../../config/routes/enums/route'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { Sticker } from '../../../../../shared/ui-kit-2/data-display/sticker/Sticker'
import { LandingButton } from '../../../../../shared/ui-kit-2/inputs/landing-button/LandingButton'
import { useAppSelector } from '../../../../../store'
import BackgroundFiguresGreen from '../../../assets/background-figures-green.png'
import { HomeSectionIds } from '../../../constants/section-anchors/SectionAnchors'
import { Slider } from '../../Slider/Slider'
import styles from './styles.module.scss'

export const Home07Messages = defineMessages({
  title: {
    id: 'Home07Messages.title',
    defaultMessage: 'Our plans',
  },
  subtitle1: {
    id: 'Home07Messages.subtitle1',
    defaultMessage:
      'No matter the size of your business, our plans offer the ideal solution with exceptional features and unmatched connectivity.',
  },
  subtitle2: {
    id: 'Home07Messages.subtitle2',
    defaultMessage:
      "Considering the Pro Plan but not sure if Telecorn is the right fit for you? Begin with our Basic Plan, and when you're convinced, seamlessly transition to the Pro Plan by converting your modem payment into credits.",
  },
  save: {
    id: 'Home07Messages.save',
    defaultMessage: 'Save',
  },
  month: {
    id: 'Home07Messages.month',
    defaultMessage: 'month',
  },
  year: {
    id: 'Home07Messages.year',
    defaultMessage: 'year',
  },
  monthlyTitle: {
    id: 'Home07Messages.monthlyTitle',
    defaultMessage: 'Monthly',
  },
  monthlySubtitle: {
    id: 'Home07Messages.monthlySubtitle',
    defaultMessage: 'Our starter pack',
  },
  annualTitle: {
    id: 'Home07Messages.annualTitle',
    defaultMessage: 'Annual',
  },
  annualSubtitle: {
    id: 'Home07Messages.annualSubtitle',
    defaultMessage: 'For those with more professionals needs',
  },
  enterpriseTitle: {
    id: 'Home07Messages.enterpriseTitle',
    defaultMessage: 'Enterprise Plan',
  },
  enterpriseSubtitle: {
    id: 'Home07Messages.enterpriseSubtitle',
    defaultMessage: 'More power for complex operations',
  },
  custom: {
    id: 'Home07Messages.custom',
    defaultMessage: 'Custom',
  },
  contactSales: {
    id: 'Home07Messages.contactSales',
    defaultMessage: 'Contact Sales',
  },
  choose: {
    id: 'Home07Messages.choose',
    defaultMessage: 'Choose',
  },
})

export const Home07 = () => {
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated)
  const isLessThanTablet = useLessThanDeviceScreen(ScreenDevices.LAPTOP)
  const navigate = useNavigate()
  const intl = useIntl()
  const { siteVersion } = useSiteVersion()

  const handleChoose = () => {
    navigate(
      isAuthenticated ? RoutePath[Route.ActivateDevice] : RoutePath[Route.Auth],
    )
  }

  const CardMonthly = () => (
    <div className={styles.Card}>
      <div className={styles.Card__Top}>
        <div className={clsx(styles.Card__Row, styles.Card__Row_title)}>
          <div className={styles.Card__Title}>
            {intl.formatMessage(Home07Messages.monthlyTitle)}
          </div>
        </div>
        <div className={styles.Card__Subtitle}>
          {intl.formatMessage(Home07Messages.monthlySubtitle)}
        </div>
      </div>
      <div className={styles.Card__Bottom}>
        <div className={clsx(styles.Card__Row, styles.Card__Row_price)}>
          <div className={styles.Card__Text}>
            <span className={styles.Card__Text_bold}>
              {siteVersion === 'ru' ? '500 ₽' : '€50'}
            </span>
            <span className={styles.Card__Text_sub}>
              /{intl.formatMessage(Home07Messages.month)}
            </span>
          </div>
          {siteVersion !== 'ru' && (
            <div
              className={clsx(styles.Card__Text, styles.Card__Text_perModem)}
            >
              <span className={styles.Card__Text_italics}>+€10</span>
              <span className={styles.Card__Text_sub}>/per modem</span>
            </div>
          )}
        </div>
        <LandingButton
          text={intl.formatMessage(Home07Messages.choose)}
          variant={'greenTextOutlined'}
          onClick={handleChoose}
        />
      </div>
    </div>
  )

  const CardAnnual = () => (
    <div className={styles.Card}>
      <div className={styles.Card__Top}>
        <div className={clsx(styles.Card__Row, styles.Card__Row_title)}>
          <div className={styles.Card__Title}>
            {intl.formatMessage(Home07Messages.annualTitle)}
          </div>
          <div className={styles.Card__Sticker}>
            <Sticker
              text={`${intl.formatMessage(Home07Messages.save)} ${siteVersion === 'ru' ? '1000 руб' : '32%'}`}
              color={'green'}
            />
          </div>
        </div>
        <div className={styles.Card__Subtitle}>
          {intl.formatMessage(Home07Messages.annualSubtitle)}
        </div>
      </div>
      <div className={styles.Card__Bottom}>
        <div className={clsx(styles.Card__Row, styles.Card__Row_price)}>
          <div className={styles.Card__Text}>
            <span className={styles.Card__Text_bold}>
              {siteVersion === 'ru' ? '5000 ₽' : '€500'}
            </span>
            {siteVersion === 'ru' && (
              <span className={styles.Card__Text_sub}>
                /{intl.formatMessage(Home07Messages.year)}
              </span>
            )}
          </div>
          {siteVersion !== 'ru' && (
            <div className={clsx(styles.Card__Text, styles.Card__Text_info)}>
              <span className={styles.Card__Text_green}>+ Free modem</span>
              <span className={styles.Card__Text_green}>+ 2 free months</span>
            </div>
          )}
        </div>
        <LandingButton
          text={intl.formatMessage(Home07Messages.choose)}
          variant={'green'}
          onClick={handleChoose}
        />
      </div>
    </div>
  )

  const CardEnterprise = () => (
    <div className={styles.Card}>
      <div className={styles.Card__Top}>
        <div className={clsx(styles.Card__Row, styles.Card__Row_title)}>
          <div className={styles.Card__Title}>
            {intl.formatMessage(Home07Messages.enterpriseTitle)}
          </div>
        </div>
        <div className={styles.Card__Subtitle}>
          {intl.formatMessage(Home07Messages.enterpriseSubtitle)}
        </div>
      </div>
      <div className={styles.Card__Bottom}>
        <div className={clsx(styles.Card__Row, styles.Card__Row_price)}>
          <div className={styles.Card__Text}>
            <span className={styles.Card__Text_bold}>
              {intl.formatMessage(Home07Messages.custom)}
            </span>
          </div>
          {siteVersion === 'en' && (
            <div className={clsx(styles.Card__Text, styles.Card__Text_info)}>
              <span
                className={clsx(
                  styles.Card__Text_sub,
                  styles.Card__Text_special,
                )}
              >
                Special for you
              </span>
            </div>
          )}
        </div>
        <LandingButton
          text={intl.formatMessage(Home07Messages.contactSales)}
          variant={'greenTextOutlined'}
          onClick={handleChoose}
        />
      </div>
    </div>
  )

  return (
    <div className={styles.Container} id={HomeSectionIds.PRICING}>
      <div className={styles.Background}>
        <img src={BackgroundFiguresGreen} alt="" />
      </div>
      <div className={styles.Wrapper}>
        <div className={styles.Row}>
          <div className={styles.Title}>
            {intl.formatMessage(Home07Messages.title)}
          </div>
          <div className={styles.Subtitle}>
            <p>{intl.formatMessage(Home07Messages.subtitle1)}</p>
            <p>{intl.formatMessage(Home07Messages.subtitle2)}</p>
          </div>
        </div>
        {isLessThanTablet ? (
          <div className={styles.Slider}>
            <Slider
              items={[<CardMonthly />, <CardAnnual />, <CardEnterprise />]}
              getCustomTranslationShift={(currentSlide) => 85 * -currentSlide}
            />
          </div>
        ) : (
          <div className={styles.Grid}>
            <CardMonthly />
            <CardAnnual />
            <CardEnterprise />
          </div>
        )}
      </div>
    </div>
  )
}
