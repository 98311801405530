import {
  MailingDeleteBackendResponse,
  MailingDeleteResponse,
} from '../../../../../types/mailing/_crud/delete'

export const mapMailingDeleteBackendToMailingDelete = (
  item: MailingDeleteBackendResponse,
): MailingDeleteResponse => {
  const { data } = item

  return {
    data: data,
  }
}
