import React, { useCallback, useContext, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../../../app/hooks/useModal'
import { ReactComponent as SearchIcon } from '../../../../../../../../assets/icons/search.svg'
import { MODAL_TYPES } from '../../../../../../../../layouts/modals/ModalsProvider'
import { ContactTable } from '../../../../../../../../layouts/tables/contact-table/ContactTable'
import { useDebounce } from '../../../../../../../../sdk/datagates/helpers/_common/debounce'
import { Card } from '../../../../../../../../shared/ui-kit-2/data-display/card/Card'
import { Counter } from '../../../../../../../../shared/ui-kit-2/data-display/counter/Counter'
import { Button } from '../../../../../../../../shared/ui-kit-2/inputs/button/Button'
import { Input } from '../../../../../../../../shared/ui-kit-2/inputs/input/Input'
import { ContactsPageContext } from '../../../../contexts/ContactsPageContext'
import styles from './styles.module.scss'

const ContactListMessages = defineMessages({
  title: {
    id: 'ContactListMessages.title',
    defaultMessage: 'Contacts',
  },
  searchPlaceholder: {
    id: 'ContactListMessages.searchPlaceholder',
    defaultMessage: 'Search',
  },
  addContact: {
    id: 'ContactListMessages.addContact',
    defaultMessage: 'Add contact',
  },
})

export const ContactList = () => {
  const intl = useIntl()

  const {
    setContactsSearchQuery,
    contactsPage,
    setContactsPage,
    contacts,
    contactsTotalCount,
    contactsLoading,
    handleFetchContacts,
    handleDeleteContact,
    handleFetchContactGroups,
    limitContactsPage,
  } = useContext(ContactsPageContext)

  const { handleOpenModal } = useModal()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [searchLoading, setSearchLoading] = useState<boolean>(false)
  const sliceContacts = contacts.slice(0, 3000)

  const debouncedSearch = useCallback(
    useDebounce((value: string) => {
      setContactsSearchQuery(value)
      setSearchLoading(false)
    }, 500),
    [],
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchLoading(true)
    setSearchQuery(event.target.value)
    debouncedSearch(event.target.value)
  }

  const openAddContactModal = () => {
    handleOpenModal({
      type: MODAL_TYPES.CONTACT_SETTINGS_MODAL,
      props: {
        handleFetchContacts,
        handleFetchContactGroups,
      },
    })
  }

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <div className={styles.Header}>
        <div className={styles.HeaderTitle}>
          <div>{intl.formatMessage(ContactListMessages.title)}</div>
          <Counter variant={'filled'} count={contacts.length} />
        </div>

        <div className={styles.HeaderActions}>
          <Input
            validationNeeded={false}
            postfix={<SearchIcon />}
            onChange={handleChange}
            placeholder={intl.formatMessage(
              ContactListMessages.searchPlaceholder,
            )}
            inputSize={'small'}
            value={searchQuery}
            additionalClassNames={[styles.SearchInputAdditional]}
          />
          <Button
            text={intl.formatMessage(ContactListMessages.addContact)}
            size={'small'}
            icon={'user-plus'}
            onClick={openAddContactModal}
            additionalClassNames={[styles.AddContactBtnAdditional]}
          />
        </div>
      </div>

      <ContactTable
        contacts={sliceContacts}
        totalCount={contactsTotalCount}
        page={contactsPage}
        setPage={setContactsPage}
        pageLimit={limitContactsPage}
        loading={contactsLoading || searchLoading}
        handleFetch={handleFetchContacts}
        handleFetchContactGroups={handleFetchContactGroups}
        handleDeleteContact={handleDeleteContact}
      />
    </Card>
  )
}
