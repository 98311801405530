import React, { FC, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { MailingGetResponse } from '../../../../../sdk/datagates/types/mailing/_crud/get'
import { MAILING_STATUSES } from '../../../../../sdk/hooks/use-mailing-statuses/constants/MailingStatuses'
import { useMailingStatuses } from '../../../../../sdk/hooks/use-mailing-statuses/useMailingStatuses'
import { formatDate } from '../../../../../shared/lib/utils/date-utils/formatDate'
import { SmsMailingCancelledContent } from './components/sms-mailing-cancelled-content/SmsMailingCancelledContent'
import { SmsMailingEndedContent } from './components/sms-mailing-ended-content/SmsMailingEndedContent'
import { SmsMailingInProcess } from './components/sms-mailing-in-process-content/SmsMailingInProcess'
import { SmsMailingPlanedContent } from './components/sms-mailing-planned-content/SmsMailingPlanedContent'
import styles from './styles.module.scss'

interface SmsMailingCreatedContentProps {
  mailing: MailingGetResponse
  handleHideModal: () => void
  onDeleteMailing: () => Promise<void>
  onCancelMailing: () => Promise<void>
  forceAction: 'delete' | 'cancel' | null
}

const SmsMailingCreatedContentMessages = defineMessages({
  detailDevice: {
    id: 'SmsMailingCreatedContentMessages.detailDevice',
    defaultMessage: 'Device',
  },
  detailNumbers: {
    id: 'SmsMailingCreatedContentMessages.detailNumbers',
    defaultMessage: 'Recipients',
  },
  detailSentDate: {
    id: 'SmsMailingCreatedContentMessages.detailSentDate',
    defaultMessage: 'Sent Date',
  },
  textTitle: {
    id: 'SmsMailingCreatedContentMessages.textTitle',
    defaultMessage: 'Text',
  },
})

export const SmsMailingCreatedContent: FC<SmsMailingCreatedContentProps> = (
  props,
) => {
  const {
    mailing,
    handleHideModal,
    onDeleteMailing,
    onCancelMailing,
    forceAction,
  } = props

  const intl = useIntl()
  const { getMailingStatusStickerComponent } = useMailingStatuses()

  const content = useMemo(() => {
    switch (mailing.mailingStatusId) {
      case MAILING_STATUSES.PLANNED:
        return (
          <SmsMailingPlanedContent
            onCancelMailing={onCancelMailing}
            forceAction={forceAction}
          />
        )
      case MAILING_STATUSES.IN_PROCESS:
        return (
          <SmsMailingInProcess
            mailing={mailing}
            handleHideModal={handleHideModal}
          />
        )
      case MAILING_STATUSES.ENDED:
        return (
          <SmsMailingEndedContent
            mailing={mailing}
            onDeleteMailing={onDeleteMailing}
            forceAction={forceAction}
          />
        )
      case MAILING_STATUSES.CANCELLED:
        return (
          <SmsMailingCancelledContent
            onDeleteMailing={onDeleteMailing}
            forceAction={forceAction}
          />
        )
    }
  }, [])

  const mailingSentDate = useMemo(() => {
    const sentDate = mailing.mailingHistory?.createdAt || mailing.scheduledAt

    return sentDate
      ? `${formatDate(sentDate, 0, 'date')}, ${formatDate(sentDate, 0, 'time')}`
      : ''
  }, [mailing.scheduledAt, mailing.mailingHistory?.createdAt])

  return (
    <div className={styles.Container}>
      <div className={styles.MailingInfoContainer}>
        <div className={styles.MailingInfoHeader}>
          <div className={styles.MailingInfoHeader__title}>{mailing.name}</div>
          {getMailingStatusStickerComponent(mailing.mailingStatusId)}
        </div>

        <div className={styles.MailingInfoDetails}>
          <div className={styles.MailingInfoDetail}>
            <div className={styles.MailingInfoDetail__title}>
              {intl.formatMessage(
                SmsMailingCreatedContentMessages.detailDevice,
              )}
            </div>
            <div className={styles.MailingInfoDetail__value}>
              {mailing.dongle.name}
            </div>
          </div>

          <div className={styles.MailingInfoDetails__divider} />

          <div className={styles.MailingInfoDetail}>
            <div className={styles.MailingInfoDetail__title}>
              {intl.formatMessage(
                SmsMailingCreatedContentMessages.detailNumbers,
              )}
            </div>
            <div className={styles.MailingInfoDetail__value}>
              {mailing.countMailingPhones}
            </div>
          </div>

          <div className={styles.MailingInfoDetails__divider} />

          <div className={styles.MailingInfoDetail}>
            <div className={styles.MailingInfoDetail__title}>
              {intl.formatMessage(
                SmsMailingCreatedContentMessages.detailSentDate,
              )}
            </div>
            <div className={styles.MailingInfoDetail__value}>
              {mailingSentDate}
            </div>
          </div>
        </div>

        <div className={styles.MessageContainer}>
          <div className={styles.MessageContainer__title}>
            {intl.formatMessage(SmsMailingCreatedContentMessages.textTitle)}
          </div>
          <div className={styles.MessageContainer__text}>{mailing.text}</div>
        </div>
      </div>

      {content}
    </div>
  )
}
