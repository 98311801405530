import { defineMessages, useIntl } from 'react-intl'

export const INVOICE_TYPE_IDS = {
  ORDER: 1,
  CREDIT: 2,
  AUTHORIZE: 3,
  REFUND: 4,
  WIRE_TRANSFER: 5,
  PAYMENT_METHOD: 6,
  TARIFF_CREDIT: 7,
}

const UseInvoiceTypesMessages = defineMessages({
  order: {
    id: 'UseInvoiceTypesMessages.order',
    defaultMessage: 'Order',
  },
  credit: {
    id: 'UseInvoiceTypesMessages.credit',
    defaultMessage: 'Credit',
  },
  authorize: {
    id: 'UseInvoiceTypesMessages.authorize',
    defaultMessage: 'Authorize',
  },
  refund: {
    id: 'UseInvoiceTypesMessages.refund',
    defaultMessage: 'Refund',
  },
  wireTransfer: {
    id: 'UseInvoiceTypesMessages.wireTransfer',
    defaultMessage: 'Wire transfer',
  },
  paymentMethod: {
    id: 'UseInvoiceTypesMessages.paymentMethod',
    defaultMessage: 'Payment method',
  },
  tariffCredit: {
    id: 'UseInvoiceTypesMessages.tariffCredit',
    defaultMessage: 'Tariff credit',
  },
  unknown: {
    id: 'UseInvoiceTypesMessages.unknown',
    defaultMessage: 'Unknown',
  },
})

export const useInvoiceTypes = () => {
  const intl = useIntl()

  const getInvoiceTypeTranslation = (invoiceTypeId: number) => {
    switch (invoiceTypeId) {
      case INVOICE_TYPE_IDS.ORDER:
        return intl.formatMessage(UseInvoiceTypesMessages.order)
      case INVOICE_TYPE_IDS.CREDIT:
        return intl.formatMessage(UseInvoiceTypesMessages.credit)
      case INVOICE_TYPE_IDS.AUTHORIZE:
        return intl.formatMessage(UseInvoiceTypesMessages.authorize)
      case INVOICE_TYPE_IDS.REFUND:
        return intl.formatMessage(UseInvoiceTypesMessages.refund)
      case INVOICE_TYPE_IDS.WIRE_TRANSFER:
        return intl.formatMessage(UseInvoiceTypesMessages.wireTransfer)
      case INVOICE_TYPE_IDS.PAYMENT_METHOD:
        return intl.formatMessage(UseInvoiceTypesMessages.paymentMethod)
      case INVOICE_TYPE_IDS.TARIFF_CREDIT:
        return intl.formatMessage(UseInvoiceTypesMessages.tariffCredit)
      default:
        return intl.formatMessage(UseInvoiceTypesMessages.unknown)
    }
  }

  return {
    getInvoiceTypeTranslation,
  }
}
