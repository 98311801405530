import React, {FC} from 'react';
import styles from "../../styles.module.scss";
import {InputField} from "../../../../../../../../../../shared/lib/form/form-field-adapters/v2/input-field/InputField";
import {
  PhoneInputField
} from "../../../../../../../../../../shared/lib/form/form-field-templates/v2/phone-input-field/PhoneInputField";
import {
  SelectSearchField
} from "../../../../../../../../../../shared/lib/form/form-field-adapters/v2/select-search-field/SelectSearchField";
import {ProfileFormMessages, ProfileFormType} from "../../ProfileForm";
import {useIntl} from "react-intl";
import {useFormRules} from "../../../../../../../../../../shared/lib/form/form-rules";
import {
  PhoneInputFieldNumber
} from "../../../../../../../../../../shared/lib/form/form-field-templates/v2/phone-input-field-new/PhoneInputFieldNew";

interface DesktopGridProps {
  form: any;
  values: any;
  isOrganization: boolean;
  userInitialPhone: string | null;
  countryOptions: {key: number, value: string, label: string, inputLabel: string}[];
  initialValues: Partial<ProfileFormType>;
}

export const DesktopGrid: FC<DesktopGridProps> = (props) => {
  const {
    form,
    values,
    isOrganization,
    userInitialPhone,
    countryOptions,
    initialValues
  } = props;
  const intl = useIntl();
  const { ruleRequired, ruleMustBeNumber, ruleNotIncludeNumbers } = useFormRules();
  return (
    <div className={styles.ProfileFormWrapper}>
      <div className={styles.ProfileFormGrid}>
        <InputField
          name="firstName"
          validate={ruleNotIncludeNumbers(true)}
          label={intl.formatMessage(ProfileFormMessages.firstNameLabel)}
          placeholder={intl.formatMessage(ProfileFormMessages.firstNameLabel)}
          markAsRequired={true}
        />
        <InputField
          name="lastName"
          validate={ruleNotIncludeNumbers(true)}
          label={intl.formatMessage(ProfileFormMessages.lastNameLabel)}
          placeholder={intl.formatMessage(ProfileFormMessages.lastNameLabel)}
          markAsRequired={true}
        />

        {isOrganization && (
          <InputField
            name="companyName"
            validate={ruleRequired()}
            label={intl.formatMessage(ProfileFormMessages.companyNameLabel)}
            placeholder={intl.formatMessage(ProfileFormMessages.companyNameLabel)}
            markAsRequired={true}
          />
        )}

        {isOrganization && (
          <InputField
            name="vatNumber"
            validate={ruleMustBeNumber(true)}
            label={intl.formatMessage(ProfileFormMessages.vatNumberLabel)}
            placeholder={intl.formatMessage(ProfileFormMessages.vatNumberLabel)}
            markAsRequired={true}
          />
        )}

        <div className={styles.fullSizeItem}>
          <PhoneInputFieldNumber
            name="userPhone"
            values={values}
            label={intl.formatMessage(ProfileFormMessages.phoneNumberLabel)}
            initialValue={userInitialPhone as string}
            markAsRequired={true}
            noHelperText={true}
          />
        </div>

        <InputField
          name="email"
          validate={ruleRequired()}
          label={intl.formatMessage(ProfileFormMessages.emailLabel)}
          placeholder={intl.formatMessage(ProfileFormMessages.emailLabel)}
          markAsRequired={true}
          disabled
        />

        <InputField
          name="billingEmail"
          label={intl.formatMessage(ProfileFormMessages.billingEmailLabel)}
          placeholder={intl.formatMessage(ProfileFormMessages.billingEmailLabel)}
        />
      </div>
      {
        <div className={styles.AddressTitle}>
          {intl.formatMessage(isOrganization ? ProfileFormMessages.legalAddressTitle : ProfileFormMessages.deliveryAddressTitle)}
        </div>
      }

      <div className={styles.ProfileFormGrid}>
        <SelectSearchField
          name="countryId"
          validate={ruleRequired()}
          options={countryOptions}
          label={intl.formatMessage(ProfileFormMessages.countryLabel)}
          placeholder={intl.formatMessage(ProfileFormMessages.countryPlaceholder)}
          markAsRequired={true}
          defaultInputValue={
            countryOptions.find((c) => c.value === initialValues.countryId)
              ?.inputLabel
          }
        />

        <InputField
          name="postalCode"
          label={intl.formatMessage(ProfileFormMessages.postalCodeLabel)}
          placeholder={intl.formatMessage(ProfileFormMessages.postalCodeLabel)}
        />

        <InputField
          name="streetAddress1"
          validate={ruleRequired()}
          label={intl.formatMessage(ProfileFormMessages.addressLine1Label)}
          placeholder={intl.formatMessage(ProfileFormMessages.addressLine1Label)}
          markAsRequired={true}
        />

        <InputField
          name="streetAddress2"
          label={intl.formatMessage(ProfileFormMessages.addressLine2Label)}
          placeholder={intl.formatMessage(ProfileFormMessages.addressLine2Label)}
        />

        <InputField
          name="city"
          validate={ruleNotIncludeNumbers()}
          label={intl.formatMessage(ProfileFormMessages.cityLabel)}
          placeholder={intl.formatMessage(ProfileFormMessages.cityLabel)}
        />

        <InputField
          name="region"
          validate={ruleNotIncludeNumbers()}
          label={intl.formatMessage(ProfileFormMessages.regionLabel)}
          placeholder={intl.formatMessage(ProfileFormMessages.regionLabel)}
        />
      </div>
    </div>
  )
}