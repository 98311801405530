import { useAppSelector } from '../index'
import { UserStatuses } from '../reducers/user/types'

export const useUserSubscription = () => {
  const { status, balance } = useAppSelector((state) => state.user)

  return {
    isSubscriptionExpired: status === UserStatuses.DECLINED,
    debtAmount: status === UserStatuses.DECLINED ? Math.abs(balance) : null,
  }
}
