import React, { FC } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../../../../../app/hooks/useModal'
import { ReactComponent as CaretRightIcon } from '../../../../../../../../../../assets/icons/caret-right.svg'
import { ReactComponent as TrashIcon } from '../../../../../../../../../../assets/icons/trash.svg'
import { MODAL_TYPES } from '../../../../../../../../../../layouts/modals/ModalsProvider'
import { RedirectionListResponse } from '../../../../../../../../../../sdk/datagates/types/redirection/_crud/list'
import { Counter } from '../../../../../../../../../../shared/ui-kit-2/data-display/counter/Counter'
import styles from './styles.module.scss'

interface AutoReplyCardProps {
  autoReply: RedirectionListResponse['redirections'][0]
  handleFetch: () => Promise<void>
  handleDeleteAutoReply: (redirectionId: number) => Promise<void>
}

const AutoReplyCardMessages = defineMessages({
  deleteItemName: {
    id: 'AutoReplyCardMessages.deletingItemName',
    defaultMessage: 'auto reply',
  },
  text: {
    id: 'AutoReplyCardMessages.text',
    defaultMessage: 'Text',
  },
  devices: {
    id: 'AutoReplyCardMessages.devices',
    defaultMessage: 'Devices',
  },
})

export const AutoReplyCard: FC<AutoReplyCardProps> = (props) => {
  const { autoReply, handleFetch, handleDeleteAutoReply } = props

  const { handleOpenModal } = useModal()
  const intl = useIntl()

  const openEditAutoReplyModal = () =>
    handleOpenModal({
      type: MODAL_TYPES.REDIRECTION_SETTINGS,
      props: {
        redirection: autoReply,
        redirectionTypeId: autoReply.redirectionTypeId,
        handleFetch: handleFetch,
        handleDeleteRedirection: handleDeleteAutoReply,
      },
    })

  const onDelete = () => {
    handleOpenModal({
      type: MODAL_TYPES.DELETE_ITEM,
      props: {
        handleSubmit: () => handleDeleteAutoReply(autoReply.redirectionId),
        deletingItemName: intl.formatMessage(
          AutoReplyCardMessages.deleteItemName,
        ),
      },
    })
  }

  return (
    <div className={styles.Card}>
      <div className={styles.Header}>
        <div className={styles.Title}>{autoReply.name}</div>
        <TrashIcon className={styles.Delete} onClick={onDelete} />
      </div>

      <div className={styles.Message}>
        <div className={styles.Message__title}>
          {intl.formatMessage(AutoReplyCardMessages.text)}
        </div>
        <div className={styles.Message__text}>{autoReply.autoReplyMessage}</div>
      </div>

      <div className={styles.Bottom}>
        <div className={styles.Devices}>
          <div>{intl.formatMessage(AutoReplyCardMessages.devices)}</div>
          <Counter
            count={autoReply.deviceIds.length}
            size={'small'}
            variant={'filled'}
          />
        </div>

        <div className={styles.MoreButton} onClick={openEditAutoReplyModal}>
          <CaretRightIcon />
        </div>
      </div>
    </div>
  )
}
