import { useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { MODAL_TYPES } from '../../../layouts/modals/ModalsProvider'
import { SNACKBAR_TYPES } from '../../../layouts/snackbar/SnackbarProvider'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import {
  contactGroupDeleteDatagate,
  contactGroupListDatagate,
} from '../../datagates/api/contact-group'
import {
  BackendErrorCodes,
  useBackendErrorCodes,
} from '../../datagates/helpers/_common/use-backend-error-codes'
import { ContactGroupListResponse } from '../../datagates/types/contact-group/_crud/list'

type UseContactGroupsProps = {
  page?: number
  limit?: number
}

const UseContactGroupsMessages = defineMessages({
  positiveDeleted: {
    id: 'UseContactGroupsMessages.positiveDeleted',
    defaultMessage: 'Contact group is successfully deleted',
  },
  negativeDeleted: {
    id: 'UseContactGroupsMessages.negativeDeleted',
    defaultMessage: 'An error occurred while deleting contact group',
  },
  deleteErrorUsedTitle: {
    id: 'UseContactGroupsMessages.deleteErrorUsedTitle',
    defaultMessage: 'This contact group cannot be deleted',
  },
  deleteErrorUsedDescription: {
    id: 'UseContactGroupsMessages.deleteErrorUsedDescription',
    defaultMessage:
      'It is used in auto-reply rules or redirects or mailings. First remove it from the rules, then try again',
  },
})

export const useContactGroups = (props: UseContactGroupsProps) => {
  const { page, limit } = props

  const intl = useIntl()
  const { resolveBackendError } = useBackendErrorCodes()
  const { handleHideModal, handleOpenModal } = useModal()
  const { handleOpenSnackbar } = useSnackbar()

  const [query, setQuery] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [contactGroups, setContactGroups] = useState<
    ContactGroupListResponse['contactGroups']
  >([])
  const [totalCount, setTotalCount] = useState<number>(0)

  const handleDeleteContactGroup = async (contactGroupId: number) => {
    try {
      const urlParams = [
        { name: 'contact_group_id', value: contactGroupId.toString() },
      ]

      await contactGroupDeleteDatagate(null, urlParams)
      await handleFetch()
      handleHideModal()
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(UseContactGroupsMessages.positiveDeleted),
      })
    } catch {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(UseContactGroupsMessages.negativeDeleted),
      })
    }
  }

  const handleFetch = async () => {
    try {
      setLoading(true)
      const queryParams = [
        { name: 'order_by', value: 'contact_group_id' },
        { name: 'order_type', value: 'desc' },
        { name: 'name', value: query },
      ]

      if (page && limit) {
        queryParams.push({ name: 'page', value: (page - 1).toString() })
        queryParams.push({ name: 'limit', value: limit.toString() })
      }

      const { data } = await contactGroupListDatagate(null, null, queryParams)

      setTotalCount(data.totalCount)
      setContactGroups(data.contactGroups)
    } catch (e) {
      if (
        getErrorMessage(e) ===
        BackendErrorCodes.CONTACT_ERROR_REDIRECTS_ARE_PRESENT_WHILE_DELETING_CONTACT_GROUP
      ) {
        handleHideModal()
        handleOpenModal({
          type: MODAL_TYPES.DELETE_FAILED_MODAL,
          props: {
            title: intl.formatMessage(
              UseContactGroupsMessages.deleteErrorUsedTitle,
            ),
            description: intl.formatMessage(
              UseContactGroupsMessages.deleteErrorUsedDescription,
            ),
          },
        })
      } else {
        handleOpenSnackbar({
          type: SNACKBAR_TYPES.error,
          text: resolveBackendError(getErrorMessage(e)),
        })
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void handleFetch()
  }, [page, limit, query])

  return {
    contactGroups,
    setQuery,
    totalCount,
    loading,
    handleFetch,
    handleDeleteContactGroup,
  }
}
