import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './app/App'
import { LanguageWrapper } from './config/intl/components/language-wrapper/LanguageWrapper'
import { ModalsProvider } from './layouts/modals/ModalsProvider'
import {DialogProvider} from "./layouts/dialog/DialogProvider"
import { SnackbarProvider } from './layouts/snackbar/SnackbarProvider'
import { store } from './store'
import './style.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <LanguageWrapper>
      <App />
      <ModalsProvider />
      <DialogProvider />
      <SnackbarProvider />
    </LanguageWrapper>
  </Provider>,
  // </React.StrictMode>
)
