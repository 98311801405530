import React, { useContext } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../../../app/hooks/useModal'
import { ReactComponent as PlusIcon } from '../../../../../../../../assets/icons/plus-bold.svg'
import { LoadingContent } from '../../../../../../../../layouts/loading-content/LoadingContent'
import { MODAL_TYPES } from '../../../../../../../../layouts/modals/ModalsProvider'
import { Card } from '../../../../../../../../shared/ui-kit-2/data-display/card/Card'
import { Counter } from '../../../../../../../../shared/ui-kit-2/data-display/counter/Counter'
import { ContactsPageContext } from '../../../../contexts/ContactsPageContext'
import { ContactGroupCard } from './components/contact-group-card/ContactGroupCard'
import styles from './styles.module.scss'

const ContactsGroupMessage = defineMessages({
  title: {
    id: 'ContactsGroupMessage.title',
    defaultMessage: 'Groups',
  },
  add: {
    id: 'ContactsGroupMessage.add',
    defaultMessage: 'Add Group',
  },
})

export const ContactGroupList = () => {
  const intl = useIntl()
  const { handleOpenModal } = useModal()

  const {
    contactGroups,
    contactGroupsLoading,
    handleFetchContactGroups,
    handleDeleteContactGroup,
    handleFetchContacts,
  } = useContext(ContactsPageContext)

  const addGroupHandler = () => {
    handleOpenModal({
      type: MODAL_TYPES.CONTACT_GROUP_SETTINGS_MODAL,
      props: {
        handleFetchContactGroups,
        handleFetchContacts,
      },
    })
  }

  const content = (
    <div className={styles.Container}>
      {contactGroups.map((contactGroup) => (
        <ContactGroupCard
          key={contactGroup.contactGroupId}
          contactGroup={contactGroup}
          handleFetch={handleFetchContactGroups}
          handleFetchContacts={handleFetchContacts}
          handleDeleteContactGroup={handleDeleteContactGroup}
        />
      ))}
    </div>
  )

  return (
    <Card additionalClassNames={[styles.ContactGroup]}>
      <div className={styles.ContactGroupHeader}>
        <div className={styles.Title}>
          {intl.formatMessage(ContactsGroupMessage.title)}
        </div>
        {!contactGroupsLoading && (
          <Counter variant={'filled'} count={contactGroups.length} />
        )}
      </div>

      <LoadingContent loading={contactGroupsLoading} content={content} />

      <button className={styles.Button} onClick={addGroupHandler}>
        <PlusIcon />
        {intl.formatMessage(ContactsGroupMessage.add)}
      </button>
    </Card>
  )
}
