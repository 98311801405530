import React, { useState } from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { ReactComponent as MobileMenuIcon } from '../../../../../../assets/icons/burger.svg'
import { ReactComponent as CloseMobileMenuIcon } from '../../../../../../assets/icons/close-burger.svg'
import { AuthRoutePath } from '../../../../../../config/routes/constants/authRoutePath'
import { RoutePath } from '../../../../../../config/routes/constants/routePath'
import { AuthRoute } from '../../../../../../config/routes/enums/authRoute'
import { Route } from '../../../../../../config/routes/enums/route'
import { HomeSectionAnchors } from '../../../../../../pages/home/constants/section-anchors/SectionAnchors'
import { LandingButton } from '../../../../../../shared/ui-kit-2/inputs/landing-button/LandingButton'
import { useAppSelector } from '../../../../../../store'
import styles from './styles.module.scss'

const GuestHeaderMenuMobileMessages = defineMessages({
  title: {
    id: 'GuestHeaderMenuMobileMessages.title',
    defaultMessage: 'Menu',
  },
  features: {
    id: 'GuestHeaderMenuMobileMessages.features',
    defaultMessage: 'Features',
  },
  product: {
    id: 'GuestHeaderMenuMobileMessages.product',
    defaultMessage: 'Product',
  },
  pricing: {
    id: 'GuestHeaderMenuMobileMessages.pricing',
    defaultMessage: 'Pricing',
  },
  contact: {
    id: 'GuestHeaderMenuMobileMessages.contact',
    defaultMessage: 'Contact',
  },
  signIn: {
    id: 'GuestHeaderMenuMobileMessages.signIn',
    defaultMessage: 'Log in',
  },
  signUp: {
    id: 'GuestHeaderMenuMobileMessages.signUp',
    defaultMessage: 'Register',
  },
  deviceList: {
    id: 'GuestHeaderMenuMobileMessages.deviceList',
    defaultMessage: 'Your Device List',
  },
})

export const GuestHeaderMenuMobile = () => {
  const { isAuthenticated } = useAppSelector((state) => state.user)
  const intl = useIntl()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const openDropdown = () => setIsOpen(true)
  const closeDropdown = () => {
    setIsOpen(false)
  }

  const toSignUp = () => {
    navigate(AuthRoutePath[AuthRoute.SignUp])
    closeDropdown()
  }

  const toSignIn = () => {
    navigate(AuthRoutePath[AuthRoute.SignIn])
    closeDropdown()
  }

  const toDeviceList = () => {
    navigate(RoutePath[Route.DeviceList])
    closeDropdown()
  }

  const AuthorizedActions = () => (
    <div className={styles.Dropdown__Actions}>
      <LandingButton
        variant={'greenTextOutlined'}
        text={intl.formatMessage(GuestHeaderMenuMobileMessages.deviceList)}
        onClick={toDeviceList}
      />
    </div>
  )

  const UnAuthorizedActions = () => (
    <div className={styles.Dropdown__Actions}>
      <LandingButton
        variant={'greenTextOutlined'}
        text={intl.formatMessage(GuestHeaderMenuMobileMessages.signIn)}
        onClick={toSignIn}
      />
      <LandingButton
        text={intl.formatMessage(GuestHeaderMenuMobileMessages.signUp)}
        onClick={toSignUp}
      />
    </div>
  )

  const menuDropdown = (
    <div className={styles.Dropdown} onClick={closeDropdown}>
      <div
        className={styles.Dropdown__Content}
        onClick={(e) => e.stopPropagation()}
      >
        <button className={styles.Dropdown__Closer} onClick={closeDropdown}>
          <CloseMobileMenuIcon />
        </button>
        <a
          href={HomeSectionAnchors.FEATURES}
          className={styles.Dropdown__Link}
          onClick={closeDropdown}
        >
          {intl.formatMessage(GuestHeaderMenuMobileMessages.features)}
        </a>
        <a
          href={HomeSectionAnchors.PRODUCT}
          className={styles.Dropdown__Link}
          onClick={closeDropdown}
        >
          {intl.formatMessage(GuestHeaderMenuMobileMessages.product)}
        </a>
        <a
          href={HomeSectionAnchors.PRICING}
          className={styles.Dropdown__Link}
          onClick={closeDropdown}
        >
          {intl.formatMessage(GuestHeaderMenuMobileMessages.pricing)}
        </a>
        <a
          href={HomeSectionAnchors.CONTACT}
          className={styles.Dropdown__Link}
          onClick={closeDropdown}
        >
          {intl.formatMessage(GuestHeaderMenuMobileMessages.contact)}
        </a>

        {isAuthenticated ? <AuthorizedActions /> : <UnAuthorizedActions />}
      </div>
    </div>
  )

  const menuButtonContent = isOpen ? <></> : <MobileMenuIcon />

  return (
    <>
      <div
        className={clsx(styles.Menu, isOpen && styles.Menu_opened)}
        onClick={openDropdown}
      >
        {menuButtonContent}
      </div>

      {isOpen && menuDropdown}
    </>
  )
}
