import React, { useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from "react-intl";
import { useClickOutside } from "../../../../shared/lib/hooks/useClickOutside";
import styles from './styles.module.scss';
import { Card } from "../../../../shared/ui-kit-2/data-display/card/Card";
import { LoadingContent } from "../../../loading-content/LoadingContent";
import { ReactComponent as CloseIcon } from '../../../../assets/icons/submodal-close.svg';
import { TARIFF_PACKAGE_ID } from "../../../../sdk/datagates/types/order/_common";
import { DongleGetResponse } from "../../../../sdk/datagates/types/dongle/_crud/get";
import {Button} from "../../../../shared/ui-kit-2/inputs/button/Button";
import {TariffPackageCard} from "./components/tariffPackageCard/TariffPackageCard";

export const UsePayDeviceMessages = defineMessages({
  title: {
    id: 'UsePayDeviceMessages.title',
    defaultMessage: 'Pay or change subscription ',
  },
  contactsCardTitle: {
    id: 'UseAddContactMessages.contactsCardTitle',
    defaultMessage: 'Contacts',
  },
  searchPlaceholder: {
    id: 'UseAddContactMessages.searchPlaceholder',
    defaultMessage: 'Search by name or phone number',
  },
  subscriptionStartTitle: {
    id: 'UseAddContactMessages.subscriptionStartTitle',
    defaultMessage: 'Subscription start',
  },
  subscriptionEndTitle: {
    id: 'UseAddContactMessages.subscriptionEndTitle',
    defaultMessage: 'Subscription end',
  },
  current: {
    id: 'UseAddContactMessages.current',
    defaultMessage: 'Current',
  },
  monthlyPaymentTitle: {
    id: 'UseAddContactMessages.monthlyPaymentTitle',
    defaultMessage: 'Monthly Payment',
  },
  monthlyPaymentDescription: {
    id: 'UseAddContactMessages.monthlyPaymentDescription',
    defaultMessage: 'You will be able to change your subscription to a monthly one only after your current annual subscription has expired',
  },
  annualPaymentTitle: {
    id: 'UseAddContactMessages.annualPaymentTitle',
    defaultMessage: 'Annual Payment',
  },
  annualPaymentDescription: {
    id: 'UseAddContactMessages.annualPaymentDescription',
    defaultMessage: 'Buy an annual subscription at a great price',
  },
  annualPaymentGift: {
    id: 'UseAddContactMessages.annualPaymentGift',
    defaultMessage: '+ gift 2 months',
  },
  pay: {
    id: 'UseAddContactMessages.pay',
    defaultMessage: 'Pay',
  },
});

interface UsePayDeviceProps {
  getTariffPackageTranslation: (tariffPackageId: number) => string;
  getTariffPackagePrice: (tariffPackageId: number, withDevice?: boolean, count?: number) => number | undefined;
  device?: DongleGetResponse;
  setIsPayOrChangeSubscription: React.Dispatch<React.SetStateAction<boolean>>
}

export const usePayDevice = (propsPayDevice: UsePayDeviceProps) => {
  const {
    getTariffPackageTranslation,
    getTariffPackagePrice,
    device,
    setIsPayOrChangeSubscription,
  } = propsPayDevice;
  const intl = useIntl();
  const [isOpen, setIsPayDeviceOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const [tariffPackageId, setTariffPackageId] = useState<number>(TARIFF_PACKAGE_ID.MONTH);

  useEffect(() => {
    if (device) {
      setTariffPackageId(device.tariffPackageId);
    }
  }, [device]);

  const paymentMethods: {
    title: string
    description: string
    paymentMethodType: number
    disabled?: boolean
  }[] = [
    {
      title: intl.formatMessage(UsePayDeviceMessages.monthlyPaymentTitle),
      paymentMethodType: TARIFF_PACKAGE_ID.MONTH,
      description: intl.formatMessage(UsePayDeviceMessages.monthlyPaymentDescription),
      disabled: device?.tariffPackageId === TARIFF_PACKAGE_ID.ANNUAL && device.isTariffPackageActive
    },
    {
      title: intl.formatMessage(UsePayDeviceMessages.annualPaymentTitle),
      paymentMethodType: TARIFF_PACKAGE_ID.ANNUAL,
      description: intl.formatMessage(UsePayDeviceMessages.annualPaymentDescription),
    },
  ];

  const onClose = () => {
    setIsPayDeviceOpen(false);
  };

  const handlePayClick = () => {
    setIsPayDeviceOpen(false)
    setIsPayOrChangeSubscription(true)
  };

  useClickOutside(ref, onClose);

  const content = (
    <div className={styles.Content}>
      {paymentMethods.map((paymentMethod) => (
        <TariffPackageCard
          key={paymentMethod.paymentMethodType}
          device={device}
          getTariffPackagePrice={getTariffPackagePrice}
          getTariffPackageTranslation={getTariffPackageTranslation}
          tariffPackageId={tariffPackageId}
          paymentMethod={paymentMethod}
          setTariffPackageId={setTariffPackageId}
          title={paymentMethod.title}
        />
      ))}
    </div>
  );

  const component = (
    <div className={styles.Overlay}>
      <Card additionalClassNames={[styles.CardAdditional]} currentRef={ref}>
        <div className={styles.Header}>
          <div className={styles.Title}>{intl.formatMessage(UsePayDeviceMessages.title)}</div>
          <CloseIcon className={styles.Close} onClick={onClose} />
        </div>
        <LoadingContent loading={false} content={content} />
        <Button text={intl.formatMessage(UsePayDeviceMessages.pay)} onClick={handlePayClick} />
      </Card>
    </div>
  );

  return {
    setIsPayDeviceOpen,
    isOpen,
    component: isOpen ? component : null,
    tariffPackageId,
  };
};