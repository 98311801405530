import React, { useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../app/hooks/useModal'
import { ReactComponent as RedirectionIcon } from '../../../../../../assets/icons/redirection-title.svg'
import { CommonButtonMessages } from '../../../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { MODAL_TYPES } from '../../../../../../layouts/modals/ModalsProvider'
import { EmptySmsRedirections } from '../../../../../../layouts/status-layouts/empty-sms-redirections/EmptySmsRedirections'
import { REDIRECTION_TYPES } from '../../../../../../sdk/hooks/use-redirection-types/constants/RedirectionTypes'
import { useRedirections } from '../../../../../../sdk/hooks/use-redirections/useRedirections'
import { Card } from '../../../../../../shared/ui-kit-2/data-display/card/Card'
import { Counter } from '../../../../../../shared/ui-kit-2/data-display/counter/Counter'
import { Button } from '../../../../../../shared/ui-kit-2/inputs/button/Button'
import { Loader } from '../../../../../../shared/ui-kit/data-display/loader/Loader'
import { SmsRedirectionCard } from '../sms-rules/components/sms-redirection-list/components/sms-redirection-card/SmsRedirectionCard'
import styles from './style.module.scss'

const SmsRedirectionPageMessages = defineMessages({
  title: {
    id: 'SmsRedirectionPageMessages.title',
    defaultMessage: 'Redirection Settings',
  },
})

export const SmsRedirectionPage = () => {
  const intl = useIntl()
  const { handleOpenModal } = useModal()
  const {
    redirections,
    totalCount,
    handleFetch,
    handleDeleteRedirection,
    loading,
  } = useRedirections({
    page: 0,
    limit: 0,
    redirectionTypes: [REDIRECTION_TYPES.SMS],
    takeAll: true,
  })

  const [initialLoading, setInitialLoading] = useState<boolean>(true)
  const [atLeastOneRedirectionCreated, setAtLeastOneRedirectionCreated] =
    useState<boolean>(false)

  useEffect(() => {
    if (totalCount > 0) setAtLeastOneRedirectionCreated(true)
  }, [totalCount])

  useEffect(() => {
    if (!loading) setInitialLoading(false)
  }, [loading])

  const handleOpenAutoReplySettingsModal = () => {
    handleOpenModal({
      type: MODAL_TYPES.REDIRECTION_SETTINGS,
      props: {
        redirectionTypeId: REDIRECTION_TYPES.SMS,
        handleFetch: handleFetch,
        phoneSourceVariant: 'all',
      },
    })
  }

  const content = !atLeastOneRedirectionCreated ? (
    <EmptySmsRedirections />
  ) : loading ? (
    <Loader />
  ) : (
    <div className={styles.ScrollableContent}>
      {redirections.map((redirection) => (
        <SmsRedirectionCard
          key={redirection.redirectionId}
          redirection={redirection}
          handleFetch={handleFetch}
          handleDeleteRedirection={handleDeleteRedirection}
        />
      ))}
    </div>
  )

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <div className={styles.Header}>
        <div className={styles.HeaderLeft}>
          <RedirectionIcon />
          <div className={styles.HeaderLeft__title}>
            {intl.formatMessage(SmsRedirectionPageMessages.title)}
          </div>
          {!loading && (
            <Counter
              count={totalCount}
              variant={'filled'}
              additionalClassNames={[styles.HeaderLeft__counterAdditional]}
            />
          )}
        </div>
      </div>
      {initialLoading ? <Loader /> : content}
      <Button
        text={intl.formatMessage(CommonButtonMessages.create)}
        size={'small'}
        icon={'edit'}
        onClick={handleOpenAutoReplySettingsModal}
        additionalClassNames={[styles.CreateBtnAdditional]}
      />
    </Card>
  )
}
