import React, { FC } from 'react'
import clsx from 'clsx'
import { Radio, RADIO_VARIANT_LIST, RadioProps } from './components/Radio'
import styles from './styles.module.scss'

export interface RadioGroupProps {
  name: string
  group: Array<RadioProps>
  variant?: (typeof RADIO_VARIANT_LIST)[keyof typeof RADIO_VARIANT_LIST]
  additionalClassNames?: string[]
}

export const RadioGroup: FC<RadioGroupProps> = (props) => {
  const {
    name,
    group,
    variant = RADIO_VARIANT_LIST.filled,
    additionalClassNames = [],
  } = props

  return (
    <div className={clsx(styles.GroupContainer, ...additionalClassNames)}>
      {group.map(({ label, value, ...otherOptionProps }) => (
        <Radio
          key={value}
          name={name}
          label={label}
          value={value}
          variant={variant}
          {...otherOptionProps}
        />
      ))}
    </div>
  )
}
