import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { AuthRoutePath } from '../../../../../config/routes/constants/authRoutePath'
import { AuthRoute } from '../../../../../config/routes/enums/authRoute'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { LandingButton } from '../../../../../shared/ui-kit-2/inputs/landing-button/LandingButton'
import styles from './styles.module.scss'

export const Home01Messages = defineMessages({
  title: {
    id: 'Home01Messages.title',
    defaultMessage: 'Leading all your business needs',
  },
  info: {
    id: 'Home01Messages.info',
    defaultMessage:
      'Reach your customers through various channels at lower costs with our seamless modem and cloud-based platform. Simplify your communication without complications.',
  },
  start: {
    id: 'Home01Messages.start',
    defaultMessage: "Let's Start",
  },
  contactSales: {
    id: 'Home01Messages.contactSales',
    defaultMessage: 'Contact Sales',
  },
})

export const Home01 = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const toSignIn = () => navigate(AuthRoutePath[AuthRoute.SignIn])
  const isLessThanTablet = useLessThanDeviceScreen(ScreenDevices.LAPTOP)

  const handleStart = () => {
    toSignIn()
  }

  const handleContactSales = () => {
    toSignIn()
  }

  return (
    <div className={styles.Container}>
      <div className={styles.VideoContainer}>
        <video
          autoPlay
          muted
          loop
          controls={false}
          className={styles.Video}
          playsInline
        >
          <source
            src={`/videos/${isLessThanTablet ? 'landing-1-mobile' : 'landing-1'}.mp4`}
            type="video/mp4"
          />
        </video>
        <div className={styles.Mask} />
      </div>
      <div className={styles.Wrapper}>
        <div className={styles.Col}>
          <div className={styles.Title}>
            {intl.formatMessage(Home01Messages.title)}
          </div>
        </div>
        <div className={styles.Col}>
          <div className={styles.Info}>
            {intl.formatMessage(Home01Messages.info)}
          </div>
          <div className={styles.Actions}>
            <LandingButton
              text={intl.formatMessage(Home01Messages.start)}
              onClick={handleStart}
            />
            <LandingButton
              text={intl.formatMessage(Home01Messages.contactSales)}
              variant={'greenTextOutlined'}
              onClick={handleContactSales}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
