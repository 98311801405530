async function unsecuredCopyToClipboard(text: string) {
  const textArea = document.createElement('textarea')
  textArea.style.position = 'fixed'
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  try {
    document.execCommand('copy')
  } catch (err) {
    console.error('Unable to copy to clipboard: ' + err)
  }
  document.body.removeChild(textArea)
}

export const copyToClipboard = async (value: string) => {
  if (window.isSecureContext && navigator.clipboard) {
    await navigator.clipboard.writeText(value)
  } else {
    void unsecuredCopyToClipboard(value)
  }
}
