import React from 'react'
import { useLocation } from 'react-router'
import { RoutePath } from '../../config/routes/constants/routePath'
import { Route } from '../../config/routes/enums/route'
import { useAppSelector } from '../../store'
import { GuestHeader } from '../header/components/guest-header/GuestHeader'
import { UserMobileHeader } from './components/user-mobile-header/UserMobileHeader'
import styles from './styles.module.scss'

export const MobileHeader = () => {
  const { isAuthenticated } = useAppSelector((state) => state.user)
  const { pathname } = useLocation()

  return (
    <div className={styles.MobileHeader}>
      {isAuthenticated && pathname !== RoutePath[Route.Home] ? (
        <UserMobileHeader />
      ) : (
        <GuestHeader />
      )}
    </div>
  )
}
