import React, { FC } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ReactComponent as ArrowDown } from '../../../../../assets/icons/arrow-down-green.svg'
import { SmsStatus } from '../../../../../pages/lk/subpages/sms/components/SmsStatus/SmsStatus'
import { SmsListResponse } from '../../../../../sdk/datagates/types/sms/_crud/list'
import { formatPhoneNumber } from '../../../../../sdk/formatters/format-phone-number'
import { isDeviceSender } from '../../../../../sdk/hooks/use-smses/useSmses'
import { formatDate } from '../../../../../shared/lib/utils/date-utils/formatDate'
import { LC } from '../../../../../tests/e2e/locators'
import { SmsTypeBadge } from '../../../../custom-badges/sms-type-badge/CallTypeBadge'
import styles from './styles.module.scss'

interface SmsViewInfoProps {
  sms: SmsListResponse['smses'][0]
}

const SmsViewInfoMessages = defineMessages({
  smsText: {
    id: 'SmsViewInfoMessages.smsText',
    defaultMessage: 'Sms Text',
  },
  phoneNumber: {
    id: 'SmsViewInfoMessages.phoneNumber',
    defaultMessage: 'Phone number',
  },
  sentSms: {
    id: 'SmsViewInfoMessages.sentSms',
    defaultMessage: 'Sent SMS',
  },
  receivedSms: {
    id: 'SmsViewInfoMessages.receivedSms',
    defaultMessage: 'Received SMS',
  },
})

export const SmsViewInfo: FC<SmsViewInfoProps> = (props) => {
  const { sms } = props
  const intl = useIntl()

  const sentFromDevice = isDeviceSender(sms.smsTypeId)

  const contactSenderName = sentFromDevice
    ? sms.dongleName
    : sms.contact
      ? sms.contact.name
      : intl.formatMessage(SmsViewInfoMessages.phoneNumber)

  const contactReceiverName = sentFromDevice
    ? sms.contact
      ? sms.contact.name
      : intl.formatMessage(SmsViewInfoMessages.phoneNumber)
    : sms.dongleName

  const sentDate = `${formatDate(sms.createdAt, 0, 'date')} ${formatDate(sms.createdAt, 0, 'time')}`

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <div className={styles.SmsType}>
          <SmsTypeBadge type={sms.smsTypeId} />
          <div>
            {intl.formatMessage(
              sentFromDevice
                ? SmsViewInfoMessages.sentSms
                : SmsViewInfoMessages.receivedSms,
            )}
          </div>
        </div>
        <div className={styles.SmsTimeAndStatus}>
          <div>{sentDate}</div>
          {SmsStatus({
            smsStatusId: sms.smsStatusId,
            testId: LC.SMS.TABLE.ROW.STATUS(sms.smsId),
          })}
        </div>
      </div>

      <div className={styles.GrayCard}>
        <div className={styles.ContactContainer}>
          <div
            className={
              sentFromDevice
                ? styles.ContactContainer__greenDark
                : styles.ContactContainer__textGray
            }
          >
            {contactSenderName}
          </div>

          <div className={styles.ContactContainer__darkText}>
            {formatPhoneNumber(sms.numberCaller)}
          </div>
        </div>

        <div className={styles.Arrow}>
          <ArrowDown />
        </div>
      </div>

      <div className={styles.GrayCard}>
        <div className={styles.ContactContainer}>
          <div
            className={
              sentFromDevice
                ? styles.ContactContainer__textGray
                : styles.ContactContainer__greenDark
            }
          >
            {contactReceiverName}
          </div>

          <div className={styles.ContactContainer__darkText}>
            {formatPhoneNumber(sms.numberReceiver)}
          </div>
        </div>
      </div>

      <div className={styles.GrayCard}>
        <div className={styles.MessageContainer}>
          <div className={styles.MessageContainer__title}>
            {intl.formatMessage(SmsViewInfoMessages.smsText)}
          </div>
          <div className={styles.MessageContainer__text}>
            {sms.messageDecoded}
          </div>
        </div>
      </div>
    </div>
  )
}
