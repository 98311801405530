import { Method } from 'axios'
import axiosApiInstance from '../../../../../config/api'
import { getErrorMessage } from '../../../../../shared/lib/utils/get-error-message/getErrorMessage'
import { resolveResponseData } from '../resolve-response-data'

// dongle/{id}
// id - parameter name that will be replaced with value
type UrlParameter = {
  name: string
  value: string
}

export type QueryParameter = {
  name: string
  value: string | string[]
}

// QUERY PARAMS SUPPORT
export function wrapApiRequest<TReq, TBackendRes, TRes>(
  requestUrl: string,
  mapper: (item: TBackendRes) => TRes,
  method: Method = 'POST',
) {
  return async (
    reqBody: TReq | null = null,
    urlParams: UrlParameter[] | null = [],
    queryParams: QueryParameter[] | null = [],
  ) => {
    try {
      // Setting utl parameters
      let requestUrlWithParams = requestUrl.slice()
      if (urlParams) {
        for (const param of urlParams)
          requestUrlWithParams = requestUrlWithParams.replace(
            '{' + param.name + '}',
            param.value,
          )
      }

      // Setting query parameters
      if (queryParams) {
        requestUrlWithParams += queryParams.reduce(
          (acc: string, cur, curIndex) => {
            if (Array.isArray(cur.value)) {
              const nodes = cur.value
                .map((node) => {
                  return `${cur.name}[]=${node}`
                })
                .join('&')

              if (curIndex === 0) {
                return `${acc}?${nodes}`
              }

              return `${acc}&${nodes}`
            }

            if (curIndex === 0) {
              return `${acc}?${cur.name}=${cur.value}`
            }

            return `${acc}&${cur.name}=${cur.value}`
          },
          '',
        )
      }

      const response = await axiosApiInstance({
        method: method,
        url: requestUrlWithParams,
        data: reqBody,
      })

      const { data: backendData } = resolveResponseData<TBackendRes>(response)

      const data = mapper(backendData)

      return { data }
    } catch (e) {
      console.log("e", e)
      // e.message is backend error code (string)
      throw new Error(getErrorMessage(e))
    }
  }
}
