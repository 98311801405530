import {
  DongleUssdBackendResponse,
  DongleUssdResponse,
} from '../../../../types/dongle/ussd'

export const mapDongleUssdBackendToDongleUssd = (
  item: DongleUssdBackendResponse,
): DongleUssdResponse => {
  const { result } = item.data

  return {
    result: result,
  }
}
