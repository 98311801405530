import React, { useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSiteVersion } from '../../../app/hooks/useSiteVersion'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { CommonButtonMessages } from '../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import {
  ProfileFormMessages,
  ProfileFormType,
} from '../../../pages/lk/subpages/profile/components/profilePageEn/components/profile-page-form/ProfileForm'
import { userUpdateDatagate } from '../../../sdk/datagates/api/user'
import { useBackendErrorCodes } from '../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { USER_TYPE_IDS } from '../../../sdk/datagates/types/user/_crud/get'
import { useCountrySelectOptions } from '../../../sdk/hooks/use-countries/useCountrySelectOptions'
import { InputField } from '../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import { SelectSearchField } from '../../../shared/lib/form/form-field-adapters/v2/select-search-field/SelectSearchField'
import { PhoneInputField } from '../../../shared/lib/form/form-field-templates/v2/phone-input-field/PhoneInputField'
import { useFormRules } from '../../../shared/lib/form/form-rules'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import { getPhoneString } from '../../../shared/lib/utils/libphonenumber/create-phone-string'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import { SegmentedControlOptionProps } from '../../../shared/ui-kit-2/inputs/segmented-control/components/SegmentedControlOption'
import { SegmentedControl } from '../../../shared/ui-kit-2/inputs/segmented-control/SegmentedControl'
import { LoadingContent } from '../../loading-content/LoadingContent'
import { SNACKBAR_TYPES } from '../../snackbar/SnackbarProvider'
import styles from './styles.module.scss'
import {Checkbox} from "../../../shared/ui-kit-2/inputs/checkbox/Checkbox";
import {LegalAddressProfileFormMobile} from "../../../pages/lk/subpages/profile/components/profilePageEn/components/profile-page-form/components/legalAddressProfileFormMobile/LegalAddressProfileFormMobile";
import {useChangeProfileType} from "../_hooks/use-change-profile-type/useChangeProfileType";
import {
  PhoneInputFieldNumber
} from "../../../shared/lib/form/form-field-templates/v2/phone-input-field-new/PhoneInputFieldNew";
import {getClearedPhoneNumber} from "../../../shared/lib/utils/get-cleared-phone-number/getClearedPhoneNumber";

export const EditProfileModal = () => {
  const {
    handleHideModal,
    props: {
      initialValues,
      userInitialPhone,
      fetchDefaultPageData,
      userTypeInitial,
      isLegalAddressSameEdit
    },
  } = useModal()

  const [userType, setUserType] = useState(userTypeInitial)
  const { siteVersion } = useSiteVersion()
  const { handleOpenSnackbar } = useSnackbar()
  const intl = useIntl()
  const { resolveBackendError } = useBackendErrorCodes()
  const { ruleRequired, ruleMustBeNumber, ruleNotIncludeNumbers } = useFormRules()
  const { countryOptions, loading: fetchCountriesLoading } = useCountrySelectOptions()
  const [isLegalAddressSame, setIsLegalAddressSame] = useState<boolean>(isLegalAddressSameEdit);
  const {setIsOpen, component,handleChangeType}= useChangeProfileType({
    setUserType,
  });

  const handleChangeTypeProfile = (userType: string) => {
    setIsOpen(true)
    handleChangeType(userType)
  };

  const accountTypeOptions: SegmentedControlOptionProps[] = [
    {
      label: intl.formatMessage(ProfileFormMessages.accountTypePerson),
      value: USER_TYPE_IDS.NATURAL_PERSON.toString(),
      comparedValue: userType,
      onClick: () => handleChangeTypeProfile(USER_TYPE_IDS.NATURAL_PERSON.toString()),
    },
    {
      label: intl.formatMessage(ProfileFormMessages.accountTypeOrganization),
      value: USER_TYPE_IDS.ORGANIZATION.toString(),
      comparedValue: userType,
      onClick: () => handleChangeTypeProfile(USER_TYPE_IDS.ORGANIZATION.toString()),
    },
  ]

  const onEditProfile = async (values: ProfileFormType) => {
    try {
      !isLegalAddressSame
        ? await userUpdateDatagate({
          user_type_id: +userType,
          name: values.firstName,
          last_name: values.lastName,
          company_name: isOrganization ? values.companyName : '',
          company_phone: isOrganization ? getClearedPhoneNumber(values.companyPhone) : '',
          phone: getClearedPhoneNumber(values.userPhone),
          billing_email: values.billingEmail ?? '',
          vat_number: isOrganization ? values.vatNumber : '',
          country_id: +values.countryId,
          postal_code: values.postalCode ?? '',
          street_address_1: values.streetAddress1 ?? '',
          street_address_2: values.streetAddress2 ?? '',
          city: values.city ?? '',
          region: values.region ?? '',
          legal_street_address_1: values?.legal?.streetAddress1 ?? '',
          legal_street_address_2: values?.legal?.streetAddress2 ?? '',
          legal_postal_code: values?.legal?.postalCode ?? '',
          legal_country_id: values.legal?.countryId !== undefined ? +values.legal.countryId : undefined,
          legal_city: values?.legal?.city ?? '',
          legal_region: values?.legal?.region ?? '',
        })
        : await userUpdateDatagate({
          user_type_id: +userType,
          name: values.firstName,
          last_name: values.lastName,
          company_name: isOrganization ? values.companyName : '',
          company_phone: isOrganization ? getClearedPhoneNumber(values.companyPhone) : '',
          phone: getClearedPhoneNumber(values.userPhone),
          billing_email: values.billingEmail ?? '',
          vat_number: isOrganization ? values.vatNumber : '',
          country_id: +values.countryId,
          postal_code: values.postalCode ?? '',
          street_address_1: values.streetAddress1 ?? '',
          street_address_2: values.streetAddress2 ?? '',
          city: values.city ?? '',
          region: values.region ?? '',
          legal_street_address_1: values.streetAddress1 ?? '',
          legal_street_address_2: values.streetAddress2 ?? '',
          legal_postal_code: values.postalCode ?? '',
          legal_country_id: +values.countryId,
          legal_city: values.city ?? '',
          legal_region: values.region ?? '',
        });

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(ProfileFormMessages.success),
      });

      void fetchDefaultPageData();
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(ProfileFormMessages.error),
        ),
      });
    }
  }

  const content = (
    <Form
      initialValues={initialValues}
      onSubmit={onEditProfile}
      render={({ handleSubmit, submitting, values, form }) => (
        <form className={styles.Form} onSubmit={handleSubmit}>
          <div className={styles.ProfileFormGrid}>
            {siteVersion === 'en' && (
              <SegmentedControl
                name={'accountType'}
                group={accountTypeOptions}
              />
            )}
            <InputField
              name="firstName"
              validate={ruleNotIncludeNumbers(true)}
              label={intl.formatMessage(ProfileFormMessages.firstNameLabel)}
              placeholder={intl.formatMessage(
                ProfileFormMessages.firstNameLabel,
              )}
              markAsRequired={true}
            />

            <InputField
              name="lastName"
              validate={ruleNotIncludeNumbers(true)}
              label={intl.formatMessage(ProfileFormMessages.lastNameLabel)}
              placeholder={intl.formatMessage(
                ProfileFormMessages.lastNameLabel,
              )}
              markAsRequired={true}
            />
            <InputField
              name="email"
              validate={ruleRequired()}
              label={intl.formatMessage(ProfileFormMessages.emailLabel)}
              placeholder={intl.formatMessage(ProfileFormMessages.emailLabel)}
              markAsRequired={true}
              disabled
            />
            <InputField
              name="billingEmail"
              label={intl.formatMessage(
                ProfileFormMessages.billingEmailLabel,
              )}
              placeholder={intl.formatMessage(
                ProfileFormMessages.billingEmailLabel,
              )}
            />
            <div className={styles.fullSizeItem}>
              <PhoneInputFieldNumber
                name="userPhone"
                label={intl.formatMessage(ProfileFormMessages.phoneNumberLabel)}
                initialValue={userInitialPhone}
                markAsRequired={true}
                noHelperText={true}
              />
            </div>
            {
              isOrganization && siteVersion === 'en' && (
                <div className={styles.RowItems}>
                  <InputField
                    name="companyName"
                    additionalClassNames={[styles.ItemFieldCustom]}
                    validate={ruleRequired()}
                    label={intl.formatMessage(ProfileFormMessages.companyNameLabel)}
                    placeholder={intl.formatMessage(
                      ProfileFormMessages.companyNameLabel,
                    )}
                    markAsRequired={true}
                  />
                  <InputField
                    name="vatNumber"
                    additionalClassNames={[styles.ItemFieldCustom]}
                    validate={ruleMustBeNumber(true)}
                    label={intl.formatMessage(ProfileFormMessages.vatNumberLabel)}
                    placeholder={intl.formatMessage(
                      ProfileFormMessages.vatNumberLabel,
                    )}
                    markAsRequired={true}
                  />
                </div>
              )
            }
            {siteVersion === 'en' && (
              <>
                <div className={styles.Title}>
                  {intl.formatMessage(isOrganization ? ProfileFormMessages.legalAddressTitle : ProfileFormMessages.deliveryAddressTitle)}
                </div>
                <div className={styles.ProfileFormGrid}>
                  <InputField
                    name="streetAddress1"
                    validate={ruleRequired()}
                    label={intl.formatMessage(
                      ProfileFormMessages.addressLine1Label,
                    )}
                    placeholder={intl.formatMessage(
                      ProfileFormMessages.addressLine1Label,
                    )}
                    markAsRequired={true}
                  />
                </div>
                <div className={styles.ProfileFormGrid}>
                  <InputField
                    name="streetAddress2"
                    label={intl.formatMessage(
                      ProfileFormMessages.addressLine2Label,
                    )}
                    placeholder={intl.formatMessage(
                      ProfileFormMessages.addressLine2Label,
                    )}
                  />
                </div>
                <div className={styles.RowItems}>
                  <SelectSearchField
                    additionalClassNames={[styles.ItemFieldCustom]}
                    name="countryId"
                    validate={ruleRequired()}
                    options={countryOptions}
                    label={intl.formatMessage(ProfileFormMessages.countryLabel)}
                    placeholder={intl.formatMessage(
                      ProfileFormMessages.countryPlaceholder,
                    )}
                    markAsRequired={true}
                    defaultInputValue={
                      countryOptions.find(
                        (c) => c.value === initialValues.countryId,
                      )?.inputLabel
                    }
                  />

                  <InputField
                    name="postalCode"
                    additionalClassNames={[styles.ItemFieldCustom]}
                    label={intl.formatMessage(
                      ProfileFormMessages.postalCodeLabel,
                    )}
                    placeholder={intl.formatMessage(
                      ProfileFormMessages.postalCodeLabel,
                    )}
                  />
                </div>
                <div className={styles.RowItems}>
                  <InputField
                    name="city"
                    validate={ruleNotIncludeNumbers()}
                    label={intl.formatMessage(ProfileFormMessages.cityLabel)}
                    placeholder={intl.formatMessage(
                      ProfileFormMessages.cityLabel,
                    )}
                    additionalClassNames={[styles.ItemFieldCustom]}
                  />

                  <InputField
                    name="region"
                    validate={ruleNotIncludeNumbers()}
                    label={intl.formatMessage(ProfileFormMessages.regionLabel)}
                    placeholder={intl.formatMessage(
                      ProfileFormMessages.regionLabel,
                    )}
                    additionalClassNames={[styles.ItemFieldCustom]}
                  />
                </div>
              </>
            )}
          </div>
          {isOrganization && !isLegalAddressSame &&
            <LegalAddressProfileFormMobile
              initialValues={initialValues}
              countryOptions={countryOptions}
            />
          }

           {isOrganization &&
	           <div className={styles.CheckBoxLegal}>
		           <Checkbox
			           checked={isLegalAddressSame}
			           text={intl.formatMessage(ProfileFormMessages.deliveryCheckBoxLabel)}
			           onChange={() => setIsLegalAddressSame(prevState => !prevState)}
		           />
	           </div>
            }
          <div className={styles.Actions}>
            <Button
              onClick={handleHideModal}
              text={intl.formatMessage(CommonButtonMessages.cancel)}
              variant={'blackTextBlackBorderOutlined'}
              additionalClassNames={[styles.actions_cancel]}
            />
            <Button
              type={'submit'}
              text={intl.formatMessage(CommonButtonMessages.save)}
              disabled={submitting}
              additionalClassNames={[styles.actions_save]}
            />
          </div>
        </form>
      )}
    />
  )

  const isOrganization = useMemo(
    () => userType === USER_TYPE_IDS.ORGANIZATION.toString(),
    [userType],
  )

  return (
    <Drawer
      title={intl.formatMessage(ProfileFormMessages.accountInfo)}
      isOpen={true}
      close={handleHideModal}
      limitedWidth={false}
      additionalClassNames={[styles.DrawerAdditional]}
      fullWidth
    >
      <LoadingContent loading={fetchCountriesLoading} content={content} />
      {component}
    </Drawer>
  )
}
