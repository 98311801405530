import React, { FC } from 'react'
import clsx from 'clsx'
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/arrow-up.svg'
import { ReactComponent as HourglassIcon } from '../../../assets/icons/hourglass.svg'
import { CallType } from '../../../sdk/hooks/use-calls/useCalls'
import styles from './styles.module.scss'

interface CallTypeBadgeProps {
  type: number
}

export const CallTypeBadge: FC<CallTypeBadgeProps> = (props) => {
  const { type } = props

  return (
    <div
      className={clsx(
        styles.Badge,
        type === CallType.INCOMING && styles.Badge_incoming,
        type === CallType.OUTGOING && styles.Badge_outgoing,
        type === CallType.DELAYED && styles.Badge_delayed,
      )}
    >
      {type === CallType.DELAYED && (
        <div className={styles.Badge__delayedIcon}>
          <HourglassIcon />
        </div>
      )}

      <ArrowUpIcon />
    </div>
  )
}
