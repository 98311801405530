import {useRef, useState} from "react";
import {ussdDeleteDatagate, ussdGetDatagate, ussdPostDatagate} from "../../datagates/api/ussd";
import {SNACKBAR_TYPES} from "../../../layouts/snackbar/SnackbarProvider";
import {useSnackbar} from "../../../app/hooks/useSnackbar";

interface IGetMessages {
  deviceId: number
}

interface IDeleteMessages {
  deviceId: number
}

export interface ISendMessage {
  deviceId: number
  message: string
}

export const useUssd = () => {
  const [messages, setMessages] = useState<Array<any> | null>(null);
  const messagesRef = useRef<Array<any> | null>(null);
  const [loadingGetMessages, setLoadingGetMessages] = useState<boolean>(false);
  const [loadingDeleteMessages, setLoadingDeleteMessages] = useState<boolean>(false);
  const [loadingSendMessage, setLoadingSendMessage] = useState<boolean>(false);
  const [errorGetMessages, setErrorGetMessages] = useState<boolean>(false);
  const [errorDeleteMessages, setErrorDeleteMessages] = useState<boolean>(false);
  const [errorSendMessage, setErrorSendMessage] = useState<boolean>(false);

  const { handleOpenSnackbar } = useSnackbar();

  const getMessages = async ({ deviceId }: { deviceId: number }) => {
    try {
      setLoadingGetMessages(true);

      const { data } = await ussdGetDatagate(
        null,
        [{ name: 'dongle_id', value: `${deviceId}` }],
        [
          { name: 'order_by', value: 'ussd_id' },
          { name: 'order_type', value: 'asc' },
        ]
      );
      const fetchedUssds = data.data.ussds || data.ussds;

      if (fetchedUssds.length > 0) {
        const messagesMap = new Map(messagesRef.current?.map(message => [message.ussd_id, message.ussd_status_id]));
        const messagesWasChanged = fetchedUssds.some((fetchedUssd: { ussd_id: any; ussd_status_id: any }) => {
          const existingStatus = messagesMap.get(fetchedUssd.ussd_id);
          return existingStatus === undefined || existingStatus !== fetchedUssd.ussd_status_id;
        });

        if (messagesWasChanged) {
          setMessages(fetchedUssds);
          messagesRef.current = fetchedUssds;
        }
      } else {
        setMessages(fetchedUssds);
      }
    } catch (e) {
      setErrorGetMessages(true);
    } finally {
      setLoadingGetMessages(false);
    }
  };

  const sendMessage = async ({ deviceId, message }: ISendMessage) => {
    try {
      setLoadingSendMessage(true);

      const { data } = await ussdPostDatagate({
        command: message
      }, [
        { name: 'dongle_id', value: `${deviceId}` },
      ]);

      setMessages(prev => [
        ...(prev || []),
        data.data
      ]);
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: 'USSD hasn\'t been sent',
      })
      setErrorSendMessage(true);
    } finally {
      setLoadingSendMessage(false);
    }
  }

  const deleteMessages = async ({ deviceId }: IDeleteMessages) => {
    try {
      setLoadingDeleteMessages(true);

      await ussdDeleteDatagate(null, [
        { name: 'dongle_id', value: `${deviceId}` },
      ]);

      setTimeout(() => setMessages([]), 2000)
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: 'An error has occurred. The messages haven\'t been deleted.',
      })
      setErrorDeleteMessages(true);
    } finally {
      setTimeout(() => setLoadingDeleteMessages(false), 2000)
    }
  }

  return {
    messages,
    getMessages,
    deleteMessages,
    sendMessage,
    loadingGetMessages,
    loadingDeleteMessages,
    loadingSendMessage,
    errorGetMessages,
    errorDeleteMessages,
    errorSendMessage,
  }
}