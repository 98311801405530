import { CallbackRequestBackendResponse } from '../../../../types/callback/callback-request'
import { CallbackRequestFormResponse } from '../../../../types/callback/callback-request-form'

export const mapCallbackRequestFormBackendToCallbackRequestForm = (
  item: CallbackRequestBackendResponse,
): CallbackRequestFormResponse => {
  const { status } = item.data

  return {
    status: status,
  }
}
