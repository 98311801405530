import React, {FC} from 'react';
import styles from "../../styles.module.scss";
import {ProfileFormMessages, ProfileFormType} from "../../ProfileForm";
import {useIntl} from "react-intl";

interface MobileGridProps {
  form: any;
  values: any;
  isOrganization: boolean;
  countryOptions: {key: number, value: string, label: string, inputLabel: string}[];
  initialValues: Partial<ProfileFormType>;
  isLegalAddress: boolean;
}

export const MobileGrid: FC<MobileGridProps> = (props) => {
  const {
    form,
    values,
    isOrganization,
    countryOptions,
    initialValues,
    isLegalAddress,
  } = props;
  const intl = useIntl();
  const Item = ({
    fieldName,
    fieldValue,
  }: {
    fieldName: string
    fieldValue?: string
  }) => (
    <div className={styles.MobileGrid__Item}>
      <div className={styles.MobileGrid__FieldName}>{fieldName}</div>
      <div className={styles.MobileGrid__FieldValue}>
        {fieldValue || ' - '}
      </div>
    </div>
  )

  return (
    <>
      <div className={styles.MobileGrid}>
        <Item
          fieldName={intl.formatMessage(ProfileFormMessages.firstNameLabel)}
          fieldValue={values.firstName}
        />
        <Item
          fieldName={intl.formatMessage(ProfileFormMessages.lastNameLabel)}
          fieldValue={values.lastName}
        />
        {isOrganization && (
          <>
            <Item
              fieldName={intl.formatMessage(
                ProfileFormMessages.companyNameLabel,
              )}
              fieldValue={values.companyName}
            />
            <Item
              fieldName={intl.formatMessage(ProfileFormMessages.vatNumberLabel)}
              fieldValue={values.vatNumber}
            />
          </>
        )}
        <Item
          fieldName={intl.formatMessage(ProfileFormMessages.phoneNumberLabel)}
          fieldValue={values.companyPhoneNationalNumber}
        />
        <Item
          fieldName={intl.formatMessage(ProfileFormMessages.emailLabel)}
          fieldValue={values.email}
        />
      </div>
      <div className={styles.Title}>
        {intl.formatMessage( isOrganization ? ProfileFormMessages.legalAddressTitle : ProfileFormMessages.deliveryAddressTitle)}
      </div>
      <div className={styles.MobileGrid}>
        <Item
          fieldName={intl.formatMessage(ProfileFormMessages.countryLabel)}
          fieldValue={
            countryOptions.find((c) => c.value === initialValues.countryId)
              ?.inputLabel
          }
        />
        <Item
          fieldName={intl.formatMessage(ProfileFormMessages.postalCodeLabel)}
          fieldValue={values.postalCode}
        />
        <Item
          fieldName={intl.formatMessage(ProfileFormMessages.addressLine1Label)}
          fieldValue={values.streetAddress1}
        />
        <Item
          fieldName={intl.formatMessage(ProfileFormMessages.addressLine2Label)}
          fieldValue={values.streetAddress2}
        />
        <Item
          fieldName={intl.formatMessage(ProfileFormMessages.cityLabel)}
          fieldValue={values.city}
        />
        <Item
          fieldName={intl.formatMessage(ProfileFormMessages.regionLabel)}
          fieldValue={values.region}
        />
      </div>
      {isLegalAddress ?
	      <div className={styles.DeliverySameTitle}>
          {intl.formatMessage(ProfileFormMessages.deliverySameMobile, {
            same:
              <span className={styles.SameWord}>
                {intl.formatMessage(ProfileFormMessages.sameMobile)}
              </span>,
          })}
        </div>
        :
        <>
          <div className={styles.DeliverySameTitle}>
            {intl.formatMessage(ProfileFormMessages.deliveryAddressTitle)}
          </div>
          <div className={styles.MobileGrid}>
            <Item
              fieldName={intl.formatMessage(ProfileFormMessages.countryLabel)}
              fieldValue={
                countryOptions.find((c) => values?.legal?.countryId && c.value === values?.legal?.countryId.toString())?.inputLabel
              }
            />
            <Item
              fieldName={intl.formatMessage(ProfileFormMessages.postalCodeLabel)}
              fieldValue={values?.legal?.postalCode}
            />
            <Item
              fieldName={intl.formatMessage(ProfileFormMessages.addressLine1Label)}
              fieldValue={values?.legal?.streetAddress1}
            />
            <Item
              fieldName={intl.formatMessage(ProfileFormMessages.addressLine2Label)}
              fieldValue={values?.legal?.streetAddress2}
            />
            <Item
              fieldName={intl.formatMessage(ProfileFormMessages.cityLabel)}
              fieldValue={values?.legal?.city}
            />
            <Item
              fieldName={intl.formatMessage(ProfileFormMessages.regionLabel)}
              fieldValue={values?.legal?.region}
            />
          </div>
        </>
      }
    </>
  )
}