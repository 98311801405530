import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SnackbarType } from './types'

const snackbarInitialState: SnackbarType = {
  type: null,
  text: null,
}

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: snackbarInitialState,
  reducers: {
    setSnackbar(state, { payload }: PayloadAction<SnackbarType>) {
      state.type = payload.type
      state.text = payload.text
    },
    clearSnackbar(state, { payload }: PayloadAction<{}>) {
      state.type = null
      state.text = null
    },
  },
})

export const { setSnackbar, clearSnackbar } = snackbarSlice.actions
export default snackbarSlice.reducer
