import {
  PaymentMethodUpdateBackendResponse,
  PaymentMethodUpdateResponse,
} from '../../../../../../types/payment/payment-method/_crud/update'

export const mapPaymentMethodUpdateBackendToPaymentMethodUpdate = (
  item: PaymentMethodUpdateBackendResponse,
): PaymentMethodUpdateResponse => {
  const { payment_method_id, name, is_default } = item.data

  return {
    paymentMethodId: payment_method_id,
    name: name,
    isDefault: is_default,
  }
}
