import React, {Dispatch, FC, SetStateAction, useEffect} from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useAddTelegramId } from '../../../_hooks/use-add-telegram-id/useAddTelegramId'
import { ReactComponent as CloseItemIcon } from '../../../../../assets/icons/close-mini.svg'
import { ReactComponent as EmailsIcon } from '../../../../../assets/icons/redirection-emails.svg'
import { DashedAddButton } from '../../../../custom-buttons/dashed-add-button/DashedAddButton'
import styles from './styles.module.scss'

interface RedirectionSettingsTelegramProps {
  telegramIds: string[]
  setTelegramIds: Dispatch<SetStateAction<string[]>>
}

const RedirectionSettingsTelegramMessages = defineMessages({
  title: {
    id: 'RedirectionSettingsTelegramMessages.title',
    defaultMessage: 'Telegram',
  },
})

export const RedirectionSettingsTelegram: FC<
  RedirectionSettingsTelegramProps
> = (props) => {
  const { telegramIds, setTelegramIds } = props
  const { setIsOpen, component } = useAddTelegramId({
    processOnSave: (telegramId) =>
      setTelegramIds((prev) => [...prev, telegramId]),
    telegramIdList: telegramIds,
  })

  const intl = useIntl()

  const removeTelegramId = (telegramId: string) => {
    setTelegramIds((prev) => prev.filter((t) => t !== telegramId))
  }

  return (
    <>
      <div className={styles.Container}>
        <div className={styles.Header}>
          <div className={styles.Title}>
            <EmailsIcon />
            <div>
              {intl.formatMessage(RedirectionSettingsTelegramMessages.title)}
            </div>
          </div>
        </div>

        <div className={styles.Content}>
          {telegramIds.map((ti) => (
            <div className={styles.Item} key={ti}>
              <div>{ti}</div>
              <CloseItemIcon
                onClick={() => removeTelegramId(ti)}
                className={styles.Item__close}
              />
            </div>
          ))}
          {telegramIds.length < 100 && (
            <DashedAddButton onClick={() => setIsOpen(true)} />
          )}
        </div>
      </div>
      {component}
    </>
  )
}
