import React, { FC, useEffect, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../app/hooks/useModal'
import { CommonTabMessages } from '../../../../../../config/intl/common-messages/common-tab-messages/CommonTabMessages'
import { FilterOpenButton } from '../../../../../../layouts/custom-buttons/filter-open-button/FilterOpenButton'
import {
  useFilters,
  UseFiltersTestIdsType,
} from '../../../../../../layouts/filters/hook/useFilters'
import { MODAL_TYPES } from '../../../../../../layouts/modals/ModalsProvider'
import { SmsTable } from '../../../../../../layouts/tables/sms-table/SmsTable'
import { DongleListResponse } from '../../../../../../sdk/datagates/types/dongle/_crud/list'
import {
  SmsTypeTabs,
  useSmses,
} from '../../../../../../sdk/hooks/use-smses/useSmses'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { Card } from '../../../../../../shared/ui-kit-2/data-display/card/Card'
import { Button } from '../../../../../../shared/ui-kit-2/inputs/button/Button'
import { SegmentedControl } from '../../../../../../shared/ui-kit-2/inputs/segmented-control/SegmentedControl'
import { LC } from '../../../../../../tests/e2e/locators'
import styles from './styles.module.scss'
import {getUrlParams} from "../../../../../../shared/lib/utils/get-url-params/get-url-params";
import {changeUrlParams} from "../../../../../../shared/lib/utils/change-url-params/change-url-params";

interface SmsListPageProps {
  activeDevices: DongleListResponse['dongles']
  activeDevicesLoading: boolean
}

const SmsListPageMessages = defineMessages({
  title: {
    id: 'SmsListPageMessages.title',
    defaultMessage: 'SMS',
  },
  newSms: {
    id: 'SmsListPageMessages.newSms',
    defaultMessage: 'SMS',
  },
})

const useFiltersTestIds: UseFiltersTestIdsType = {
  dateFromTestId: LC.SMS.FILTER.DATEPICKER_FROM,
  dateToTestId: LC.SMS.FILTER.DATEPICKER_TO,
  devicesMultiSelectTestId: LC.SMS.FILTER.DEVICES_MULTISELECT,
  clearAllButtonTestId: LC.SMS.FILTER.CLEAR_ALL_BTN,
  submitButtonTestId: LC.SMS.FILTER.SUBMIT_BTN,
}

export const SmsListPage: FC<SmsListPageProps> = ({
  activeDevices,
  activeDevicesLoading,
}) => {
  const intl = useIntl()
  const urlParams = getUrlParams()

  const [smsTypeTab, setSmsTypeTab] = useState<string>(SmsTypeTabs.ALL)
  const [settings, setSettings] = useState<Record<string, string | any>>({
    page: +urlParams?.page || 1,
    sortBy: urlParams?.sortBy || 'dongle_id',
    orderBy: urlParams?.orderBy || 'desc',
    limit: +urlParams?.limit || 50,
  })

  const isMobile = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK)

  const handleChangeSettings = (fields: Object) => setSettings(prev => ({
    ...prev,
    ...fields
  }))

  const { filters, openFilters } = useFilters({
    filterFields: ['dates', 'devices', 'phone-numbers', 'sms-text'],
    devices: activeDevices,
    testIds: useFiltersTestIds,
    typeTab: smsTypeTab,
    setTypeTab: setSmsTypeTab,
    handleChangeSettings
  })

  const { smses, totalCount, handleFetch, handleDeleteSms, loading } = useSmses(
    {
      page: settings.page,
      limit: settings.limit,
      sortBy: settings.sortBy,
      orderBy: settings.orderBy,
      smsTypeTab,
      filters,
    },
  )

  const { handleOpenModal } = useModal()



   /*
  useEffect(() => {
    changeUrlParams(Object.entries(settings).reduce((prev, [key, value]) => value
      ? ({ ...prev, [key]: value })
      : prev
    , {}))
  }, [settings]);
  */

  const openSendSmsModal = () => {
    handleOpenModal({
      type: MODAL_TYPES.SEND_SMS,
      props: { handleFetch },
    })
  }

  const sliceSmses = smses.slice(0, 3000)

  const smsTypeSegmentedGroup = useMemo(
    () => [
      {
        value: SmsTypeTabs.ALL,
        comparedValue: smsTypeTab,
        label: intl.formatMessage(CommonTabMessages.filterAll),
        onClick: () => setSmsTypeTab(SmsTypeTabs.ALL),
        testId: LC.SMS.TYPE.ALL,
      },
      {
        value: SmsTypeTabs.INCOMING,
        comparedValue: smsTypeTab,
        label: intl.formatMessage(CommonTabMessages.filterIncoming),
        onClick: () => setSmsTypeTab(SmsTypeTabs.INCOMING),
        testId: LC.SMS.TYPE.INCOMING,
      },
      {
        value: SmsTypeTabs.OUTGOING,
        comparedValue: smsTypeTab,
        label: intl.formatMessage(CommonTabMessages.filterOutgoing),
        onClick: () => setSmsTypeTab(SmsTypeTabs.OUTGOING),
        testId: LC.SMS.TYPE.OUTGOING,
      },
      {
        value: SmsTypeTabs.DELAYED,
        comparedValue: smsTypeTab,
        label: intl.formatMessage(CommonTabMessages.filterDelayed),
        onClick: () => setSmsTypeTab(SmsTypeTabs.DELAYED),
        testId: LC.SMS.TYPE.DELAYED,
      },
    ],
    [smsTypeTab],
  )

  useEffect(() => {
    handleChangeSettings({ page: 1 })
  }, [smsTypeTab, filters])

  const hasFilters = !!Object.keys(filters).length

  return (
    <Card additionalClassNames={[styles.Card]}>
      <div className={styles.Header}>
        <div className={styles.Title}>
          {intl.formatMessage(SmsListPageMessages.title)}
        </div>
        <div className={styles.HeaderActions}>
          {!isMobile && (
            <SegmentedControl
              name={'sms-types'}
              group={smsTypeSegmentedGroup}
            />
          )}
          <FilterOpenButton
            hasFilters={hasFilters}
            onClick={openFilters}
            loading={activeDevicesLoading}
          />
          <Button
            text={intl.formatMessage(SmsListPageMessages.newSms)}
            size={'small'}
            icon={'edit'}
            additionalClassNames={[styles.NewSmsBtn]}
            onClick={openSendSmsModal}
            testId={LC.SMS.NEW}
          />
        </div>
      </div>

      <SmsTable
        smses={sliceSmses}
        totalCount={totalCount}
        loading={activeDevicesLoading || loading}
        handleFetch={handleFetch}
        handleDeleteSms={handleDeleteSms}
        activeDevicesTotalCount={activeDevices.length}
        settings={settings}
        handleChangeSettings={handleChangeSettings}
      />
    </Card>
  )
}
