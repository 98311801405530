import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { useSiteVersion } from '../../../../../../app/hooks/useSiteVersion'
import { useSnackbar } from '../../../../../../app/hooks/useSnackbar'
import { AuthRoutePath } from '../../../../../../config/routes/constants/authRoutePath'
import { AuthRoute } from '../../../../../../config/routes/enums/authRoute'
import { SNACKBAR_TYPES } from '../../../../../../layouts/snackbar/SnackbarProvider'
import { forgetPasswordDatagate } from '../../../../../../sdk/datagates/api/auth'
import { useBackendErrorCodes } from '../../../../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { InputField } from '../../../../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import {
  composeValidators,
  useFormRules,
} from '../../../../../../shared/lib/form/form-rules'
import { getErrorMessage } from '../../../../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Button } from '../../../../../../shared/ui-kit-2/inputs/button/Button'
import { TextButton } from '../../../../../../shared/ui-kit-2/inputs/text-button/TextButton'
import { LC } from '../../../../../../tests/e2e/locators'
import styles from './styles.module.scss'

type ForgotPasswordFormType = {
  email: string
}

interface ForgotPasswordFormProps {
  setEmail: Dispatch<SetStateAction<string>>
}

const ForgotPasswordFormMessages = defineMessages({
  backButton: {
    id: 'ForgotPasswordFormMessages.backButton',
    defaultMessage: 'Back',
  },
  title: {
    id: 'ForgotPasswordFormMessages.title',
    defaultMessage: 'Enter your email',
  },
  emailLabel: {
    id: 'ForgotPasswordFormMessages.emailLabel',
    defaultMessage: 'Your Email',
  },
  emailPlaceholder: {
    id: 'ForgotPasswordFormMessages.emailPlaceholder',
    defaultMessage: 'Enter email',
  },
  submit: {
    id: 'ForgotPasswordFormMessages.submit',
    defaultMessage: 'Enter',
  },
})

export const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({
  setEmail,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { siteDomainId } = useSiteVersion()
  const { resolveBackendError } = useBackendErrorCodes()
  const { handleOpenSnackbar } = useSnackbar()
  const { ruleRequired, ruleEmail } = useFormRules()
  const [loading, setLoading] = useState<boolean>(false)

  const toSignIn = () => navigate(AuthRoutePath[AuthRoute.SignIn])

  const onSignIn = async (values: ForgotPasswordFormType) => {
    try {
      setLoading(true)

      await forgetPasswordDatagate({
        email: values.email,
        site_domain_id: siteDomainId,
      })

      setEmail(values.email)
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={styles.Container}>
      <TextButton
        text={intl.formatMessage(ForgotPasswordFormMessages.backButton)}
        onClick={toSignIn}
        icon={'caret-left'}
      />

      <div className={styles.Title}>
        {intl.formatMessage(ForgotPasswordFormMessages.title)}
      </div>

      <Form
        onSubmit={onSignIn}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className={styles.Form}>
            <InputField
              name="email"
              validate={composeValidators(ruleRequired(), ruleEmail())}
              label={intl.formatMessage(ForgotPasswordFormMessages.emailLabel)}
              placeholder={intl.formatMessage(
                ForgotPasswordFormMessages.emailPlaceholder,
              )}
              markAsRequired={true}
              testId={LC.FORGOT_PASSWORD_PAGE.EMAIL_INPUT}
            />

            <Button
              type={'submit'}
              text={intl.formatMessage(ForgotPasswordFormMessages.submit)}
              loading={loading}
              disabled={loading || submitting}
              additionalClassNames={[styles.SubmitButtonAdditional]}
              testId={LC.FORGOT_PASSWORD_PAGE.SUBMIT_BTN}
            />
          </form>
        )}
      />
    </div>
  )
}
