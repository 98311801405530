import React, {useMemo, useState} from 'react';
import styles from './styles.module.scss';
import {DeviceDetailsModalMessages} from "../../messages/DeviceDetailsModalMessages";
import {formatPrice} from "../../../../../sdk/formatters/format-price";
import {formatDate} from "../../../../../shared/lib/utils/date-utils/formatDate";
import {Switcher} from "../../../../../shared/ui-kit-2/inputs/switcher/Switcher";
import {useIntl} from "react-intl";
import {DongleGetResponse} from "../../../../../sdk/datagates/types/dongle/_crud/get";
import {dongleUpdateDatagate} from "../../../../../sdk/datagates/api/dongle";
import {SNACKBAR_TYPES} from "../../../../snackbar/SnackbarProvider";
import {CommonFormMessages} from "../../../../../config/intl/common-messages/common-form-messages/CommonFormMessages";
import {useSnackbar} from "../../../../../app/hooks/useSnackbar";
import clsx from "clsx";

interface SubscriptionsProps {
  getTariffPackagePrice: (tariffPackageId?: (number | undefined), withDevice?: boolean, count?: number) => (number | undefined)
  getTariffPackageTranslation: (tariffPackageId?: (number | undefined)) => string
  device: DongleGetResponse
  tariffPackageEnable: boolean
}
export const Subscriptions = (props: SubscriptionsProps ) => {
  const {
    getTariffPackageTranslation,
    getTariffPackagePrice,
    device,
    tariffPackageEnable,
  } = props;

  const subscriptionType = useMemo(() => getTariffPackageTranslation(device?.tariffPackageId), [],)
  const subscriptionPrice = useMemo(() => getTariffPackagePrice(device?.tariffPackageId), [],)
  const intl = useIntl();
  const { handleOpenSnackbar } = useSnackbar()
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [activeAutoSubscription, setActiveAutoSubscription] = useState<boolean>(device.isTariffPackageEnable);
  const handleSwitchAutoRenewal = async (dongleId: number) => {
    setIsDisabled(true);
    setActiveAutoSubscription(prevState => !prevState);
    try {
      const urlParams = [{ name: 'dongle_id', value: dongleId.toString() }]
      await dongleUpdateDatagate(
        {
          is_tariff_package_enabled: !activeAutoSubscription,
        },
        urlParams,
      )
      setIsDisabled(false);
    } catch {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(CommonFormMessages.fetchError),
      })
      setTimeout(() => setIsDisabled(false), 3000);
      setActiveAutoSubscription(device.isTariffPackageEnable)
    }
  }

  return (
    <div className={styles.Content}>
      <div className={styles.List}>
        <div className={styles.Row}>
          <div className={styles.Label}>
            {intl.formatMessage(
              DeviceDetailsModalMessages.subscriptionType,
            )}
          </div>
          <div className={styles.Value}>{subscriptionType}</div>
        </div>
        <div className={styles.Row}>
          <div className={styles.Label}>
            {intl.formatMessage(DeviceDetailsModalMessages.tariffCost)}
          </div>
          <div className={styles.Value}>
            {formatPrice(subscriptionPrice)}
          </div>
        </div>
        <div className={styles.Row}>
          <div className={styles.Label}>
            {intl.formatMessage(
              DeviceDetailsModalMessages.subscriptionStarted,
            )}
          </div>
          {(device.activatedAt || device.createdAt ) && (
            <div className={styles.Value}>
              {formatDate(device.activatedAt ? device.activatedAt : device.createdAt, 0, 'date')}
            </div>
          )}
        </div>
        <div className={styles.Row}>
          <div className={styles.Label}>
            {intl.formatMessage(DeviceDetailsModalMessages.nextPayment)}
          </div>
          {device.tariffPackageEnd && (
            <div className={clsx(styles.Value, tariffPackageEnable && styles.Error)}>
              {formatDate(device.tariffPackageEnd, 0, 'date')}
            </div>
          )}
        </div>
        <div className={styles.Row}>
          <div className={styles.Label}>
            {intl.formatMessage(DeviceDetailsModalMessages.autoRenewal)}
          </div>
          <Switcher
            onChange={() => handleSwitchAutoRenewal(device.dongleId)}
            checked={activeAutoSubscription}
            disabled={isDisabled}
          />
        </div>
      </div>
    </div>
  );
};
