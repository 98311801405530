import React, { FC, useState } from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { useSiteVersion } from '../../../../../../app/hooks/useSiteVersion'
import { useSnackbar } from '../../../../../../app/hooks/useSnackbar'
import { AuthRoutePath } from '../../../../../../config/routes/constants/authRoutePath'
import { AuthRoute } from '../../../../../../config/routes/enums/authRoute'
import { SNACKBAR_TYPES } from '../../../../../../layouts/snackbar/SnackbarProvider'
import { updateForgetPasswordDatagate } from '../../../../../../sdk/datagates/api/auth'
import { useBackendErrorCodes } from '../../../../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { InputField } from '../../../../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import { InputPasswordField } from '../../../../../../shared/lib/form/form-field-adapters/v2/input-password-field/InputPasswordField'
import {
  composeValidators,
  useFormRules,
} from '../../../../../../shared/lib/form/form-rules'
import { getErrorMessage } from '../../../../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Button } from '../../../../../../shared/ui-kit-2/inputs/button/Button'
import { TextButton } from '../../../../../../shared/ui-kit-2/inputs/text-button/TextButton'
import { LC } from '../../../../../../tests/e2e/locators'
import styles from '../forgot-password-form/styles.module.scss'

type SetNewPasswordFormType = {
  password: string
  repeatPassword: string
}

interface SetNewPasswordFormProps {
  email: string
  verificationToken: string
}

const SetNewPasswordFormMessages = defineMessages({
  title: {
    id: 'SetNewPasswordFormMessages.title',
    defaultMessage: 'Enter New Password',
  },
  passwordLabel: {
    id: 'SetNewPasswordFormMessages.passwordLabel',
    defaultMessage: 'New password',
  },
  passwordPlaceholder: {
    id: 'SetNewPasswordFormMessages.passwordPlaceholder',
    defaultMessage: 'Enter new password',
  },
  repeatPasswordLabel: {
    id: 'SetNewPasswordFormMessages.repeatPasswordLabel',
    defaultMessage: 'Repeat password',
  },
  repeatPasswordPlaceholder: {
    id: 'SetNewPasswordFormMessages.repeatPasswordPlaceholder',
    defaultMessage: 'Repeat new password',
  },
  submit: {
    id: 'SetNewPasswordFormMessages.submit',
    defaultMessage: 'Confirm',
  },
  success: {
    id: 'SetNewPasswordFormMessages.success',
    defaultMessage:
      'Your password is successfully changed. Please log in your account with new credentials.',
  },
})

export const SetNewPasswordForm: FC<SetNewPasswordFormProps> = (props) => {
  const { verificationToken, email } = props

  const intl = useIntl()
  const navigate = useNavigate()
  const { siteDomainId } = useSiteVersion()
  const { resolveBackendError } = useBackendErrorCodes()
  const { handleOpenSnackbar } = useSnackbar()
  const { ruleRequired, rulePassword, rulePasswordsMismatch } = useFormRules()
  const [loading, setLoading] = useState<boolean>(false)

  const onSetNewPassword = async (values: SetNewPasswordFormType) => {
    try {
      setLoading(true)

      await updateForgetPasswordDatagate({
        email: email,
        password: values.password,
        repeat_password: values.repeatPassword,
        password_reset_token: verificationToken,
        site_domain_id: siteDomainId,
      })

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(SetNewPasswordFormMessages.success),
      })
      navigate(AuthRoutePath[AuthRoute.SignIn])
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={styles.Container}>
      <div className={styles.Title}>
        {intl.formatMessage(SetNewPasswordFormMessages.title)}
      </div>

      <Form
        onSubmit={onSetNewPassword}
        render={({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit} className={styles.Form}>
            <div className={styles.FormFields}>
              <InputPasswordField
                name="password"
                validate={composeValidators(ruleRequired(), rulePassword())}
                label={intl.formatMessage(
                  SetNewPasswordFormMessages.passwordLabel,
                )}
                placeholder={intl.formatMessage(
                  SetNewPasswordFormMessages.passwordPlaceholder,
                )}
                markAsRequired={true}
                testId={LC.SET_NEW_PASSWORD_PAGE.PASSWORD}
              />

              <InputPasswordField
                name="repeatPassword"
                validate={composeValidators(
                  ruleRequired(),
                  rulePasswordsMismatch('password', values),
                )}
                label={intl.formatMessage(
                  SetNewPasswordFormMessages.repeatPasswordLabel,
                )}
                placeholder={intl.formatMessage(
                  SetNewPasswordFormMessages.repeatPasswordPlaceholder,
                )}
                markAsRequired={true}
                testId={LC.SET_NEW_PASSWORD_PAGE.REPEAT_PASSWORD}
              />
            </div>

            <Button
              type={'submit'}
              text={intl.formatMessage(SetNewPasswordFormMessages.submit)}
              loading={loading}
              disabled={loading || submitting}
              additionalClassNames={[styles.SubmitButtonAdditional]}
              testId={LC.SET_NEW_PASSWORD_PAGE.SUBMIT_BTN}
            />
          </form>
        )}
      />
    </div>
  )
}
