import {
  OrderGetBackendResponse,
  OrderGetResponse,
} from '../../../../../types/order/_crud/get'

export const mapOrderGetBackendToOrderGet = (
  item: OrderGetBackendResponse,
): OrderGetResponse => {
  const {
    order_id,
    user_id,
    order_status_id,
    order_payment_type_id,
    first_name,
    last_name,
    country_id,
    phone,
    region,
    city,
    street_address1,
    street_address2,
    postal_code,
    created_at,
    updated_at,
    amount,
    delivery_price,
    delivery_price_old,
    order_dongles,
    delivery_status,
    tracking_url,
  } = item.data

  let totalPrice: number | null = amount / 100

  if (delivery_price !== null) totalPrice += delivery_price / 100
  else totalPrice = null

  return {
    orderId: order_id,
    userId: user_id,
    orderStatusId: order_status_id,
    orderPaymentTypeId: order_payment_type_id,
    firstName: first_name,
    lastName: last_name,
    countryId: country_id,
    phone: phone,
    region: region,
    city: city,
    streetAddress1: street_address1,
    streetAddress2: street_address2,
    postalCode: postal_code,
    createdAt: created_at,
    updatedAt: updated_at,
    amount: amount / 100,
    deliveryPrice: delivery_price !== null ? delivery_price / 100 : null,
    deliveryPriceOld: delivery_price_old !== null ? delivery_price_old / 100 : null,
    totalPrice: totalPrice,
    orderDongles: order_dongles.map((od) => ({
      dongleId: od.dongle_id,
      orderDongleId: od.order_dongle_id,
      orderId: od.order_id,
      tariffPackageId: od.tariff_package_id,
    })),
    trackingUrl: tracking_url,
    deliveryStatus: {
      fedexDeliveryStatusId: delivery_status?.fedex_delivery_status_id,
      orderId: delivery_status?.order_id,
      externalTransactionId: delivery_status?.external_transaction_id,
      externalTrackingNumber: delivery_status?.external_tracking_number,
      externalStatus: delivery_status?.external_status,
    }
  }
}
