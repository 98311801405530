import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { ReactComponent as RedsysIMG } from '../../../assets/icons/redsys.svg'
import cardIMG from '../../../assets/images/add-card.png'
import { handlePaymentRedirection } from '../../../integrations/payment-redirection'
import { paymentMethodCreateDatagate } from '../../../sdk/datagates/api/payment'
import { useBackendErrorCodes } from '../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { InputField } from '../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import { ItemWrapper } from '../../../shared/ui-kit/data-display/ItemWrapper/ItemWrapper'
import { SNACKBAR_TYPES } from '../../snackbar/SnackbarProvider'
import styles from './styles.module.scss'

const AddCardModalMessages = defineMessages({
  title: {
    id: 'AddCardModalMessages.title',
    defaultMessage: 'Add a new card',
  },
  fieldLabel: {
    id: 'AddCardModalMessages.fieldLabel',
    defaultMessage: 'Enter name',
  },
  fieldPlaceholder: {
    id: 'AddCardModalMessages.fieldPlaceholder',
    defaultMessage: 'Any name for your convenience',
  },
  attention: {
    id: 'AddCardModalMessages.attention',
    defaultMessage:
      'You will be redirected to the Redsys platform to confirm your card',
  },
  submitText: {
    id: 'AddCardModalMessages.submitText',
    defaultMessage: 'Next',
  },
  negativeCreated: {
    id: 'AddCardModalMessages.negativeCreated',
    defaultMessage: 'An error occurred while creating payment method',
  },
})

interface PaymentSettingsFormType {
  paymentMethodName: string
  paymentProviderId: number
}

export const AddCardModal = () => {
  const { handleHideModal, props } = useModal()
  const intl = useIntl()
  const { resolveBackendError } = useBackendErrorCodes()
  const { handleOpenSnackbar } = useSnackbar()
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async (values: PaymentSettingsFormType) => {
    try {
      setLoading(true)

      if (!Number.isInteger(values.paymentProviderId))
        throw new Error('No payment provider given')

      const { data } = await paymentMethodCreateDatagate({
        name: values.paymentMethodName,
        payment_provider_id: +values.paymentProviderId,
      })

      handlePaymentRedirection(data.paymentCredentials)
    } catch (e) {
      setLoading(false)

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(AddCardModalMessages.negativeCreated),
        ),
      })
    }
  }

  return (
    <Drawer
      isOpen={true}
      close={handleHideModal}
      title={intl.formatMessage(AddCardModalMessages.title)}
    >
      <div className={styles.MainIMG}>
        <img src={cardIMG} alt="" />
      </div>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          paymentProviderId: props?.paymentProviderId,
        }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className={styles.Content}>
            <ItemWrapper>
              <InputField
                name={'paymentMethodName'}
                label={intl.formatMessage(AddCardModalMessages.fieldLabel)}
                placeholder={intl.formatMessage(
                  AddCardModalMessages.fieldPlaceholder,
                )}
                required={true}
              />
            </ItemWrapper>
            <div className={styles.Attention}>
              <RedsysIMG />
              <div className={styles.AttentionText}>
                {intl.formatMessage(AddCardModalMessages.attention)}
              </div>
            </div>
            <Button
              type={'submit'}
              text={intl.formatMessage(AddCardModalMessages.submitText)}
              additionalClassNames={[styles.Next]}
              loading={loading}
              disabled={loading}
            />
          </form>
        )}
      />
    </Drawer>
  )
}
