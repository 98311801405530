import React, { FC, ReactNode } from 'react'
import clsx from 'clsx'
import { ReactComponent as CaretRightIcon } from '../../../../assets/icons/caret-right.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg'
import { ReactComponent as DocumentIcon } from '../../../../assets/icons/document.svg'
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg'
import { ReactComponent as FilterIcon } from '../../../../assets/icons/filter.svg'
import { ReactComponent as SearchIcon } from '../../../../assets/icons/icon-search.svg'
import { ReactComponent as PDFIcon } from '../../../../assets/icons/pdf.svg'
import { ReactComponent as SettingsIcon } from '../../../../assets/icons/settings.svg'
import { Loader } from '../../data-display/loader/Loader'
import styles from './styles.module.scss'

export const ICON_BUTTON_VIEWS = {
  white: 'white',
  green: 'green',
  search: 'search',
  text: 'text',
}

export const ICON_BUTTON_ICONS = {
  settings: 'settings',
  filter: 'filter',
  search: 'search',
  delete: 'delete',
  edit: 'edit',
  document: 'document',
  caretRight: 'caretRight',
  pdf: 'pdf',
}

const ICON_BUTTON_ICONS_COMPONENTS = {
  [ICON_BUTTON_ICONS.settings]: <SettingsIcon />,
  [ICON_BUTTON_ICONS.filter]: <FilterIcon />,
  [ICON_BUTTON_ICONS.search]: <SearchIcon />,
  [ICON_BUTTON_ICONS.delete]: <DeleteIcon />,
  [ICON_BUTTON_ICONS.edit]: <EditIcon />,
  [ICON_BUTTON_ICONS.document]: <DocumentIcon />,
  [ICON_BUTTON_ICONS.caretRight]: <CaretRightIcon />,
  [ICON_BUTTON_ICONS.pdf]: <PDFIcon />,
}

interface IconButtonType extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string
  theme?: string
  alwaysActive?: boolean
  loading?: boolean
  additionalClassNames?: string[]
  testId?: string
  additionalText?: string | ReactNode
}

export const IconButton: FC<IconButtonType> = ({
  icon = ICON_BUTTON_ICONS.settings,
  theme = ICON_BUTTON_VIEWS.white,
  alwaysActive = false,
  loading = false,
  additionalClassNames = [],
  testId,
  additionalText,
  ...props
}) => {
  const content = loading ? (
    <Loader color={'#080E16'} isSmall={true} />
  ) : (
    ICON_BUTTON_ICONS_COMPONENTS[icon]
  )

  return (
    <button
      className={clsx(
        styles.IconButton,
        theme === ICON_BUTTON_VIEWS.white && styles.IconButton_white,
        theme === ICON_BUTTON_VIEWS.green && styles.IconButton_green,
        theme === ICON_BUTTON_VIEWS.search && styles.IconButton_search,
        theme === ICON_BUTTON_VIEWS.text && styles.IconButton_text,
        alwaysActive && [
          styles.IconButton_search_active,
          styles.IconButton_green_active,
        ],
        icon === 'delete' &&
          theme !== ICON_BUTTON_VIEWS.text &&
          styles.IconButton_delete,
        ...additionalClassNames,
      )}
      data-test-id={testId}
      {...props}
    >
      {content}
      {additionalText}
    </button>
  )
}
