import { parseStringAsUTCDate } from '../../../../../../../shared/lib/utils/date-utils/parseStringAsUTCDate'
import {
  MailingGetBackendResponse,
  MailingGetResponse,
} from '../../../../../types/mailing/_crud/get'

export const mapMailingGetBackendToMailingGet = (
  item: MailingGetBackendResponse,
): MailingGetResponse => {
  const {
    mailing_id,
    name,
    mailing_type_id,
    mailing_status_id,
    count_mailing_phones,
    text,
    dongle,
    mailing_phones,
    contacts,
    contact_groups,
    scheduled_at,
    mailing_history,
  } = item.data

  const scheduledAtDate = scheduled_at
    ? parseStringAsUTCDate(scheduled_at)
    : undefined

  return {
    mailingId: mailing_id,
    name: name,
    mailingTypeId: mailing_type_id,
    mailingStatusId: mailing_status_id,
    countMailingPhones: count_mailing_phones,
    text: text,
    dongle: {
      dongleId: dongle.dongle_id,
      name: dongle.name,
      number: dongle.number,
    },
    mailingPhones: mailing_phones?.map((mp) => ({
      mailingPhoneId: mp.mailing_phone_id,
      phone: mp.phone,
      mailingId: mp.mailing_id,
    })),
    contacts: contacts?.map((c) => {
      return {
        contactId: c.contact_id,
        name: c.name,
        phone: c.phone,
      }
    }),
    contactGroups: contact_groups?.map((cg) => ({
      contactGroupId: cg.contact_group_id,
      name: cg.name,
    })),
    mailingHistory: mailing_history
      ? {
          mailingHistoryId: mailing_history.mailing_history_id,
          mailingId: mailing_history.mailing_id,
          createdAt: parseStringAsUTCDate(mailing_history.created_at),
        }
      : undefined,
    scheduledAt: scheduledAtDate,
  }
}
