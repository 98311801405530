import React, {useState} from 'react';
import styles from './styles.module.scss';
import clsx from "clsx";
import {DeviceDetailsModalMessages} from "../../messages/DeviceDetailsModalMessages";
import {IconButton} from "../../../../../shared/ui-kit-2/inputs/icon-button/IconButton";
import {LC} from "../../../../../tests/e2e/locators";
import {useIntl} from "react-intl";
import {DongleGetResponse} from "../../../../../sdk/datagates/types/dongle/_crud/get";
import {SNACKBAR_TYPES} from "../../../../snackbar/SnackbarProvider";
import {copyToClipboard} from "../../../../../shared/lib/utils/copy-to-clipboard/copyToClipboard";
import {useSnackbar} from "../../../../../app/hooks/useSnackbar";
import { ReactComponent as CopyIcon } from '../../../../../assets/icons/copy-v2.svg'
import { ReactComponent as EditIcon } from '../../../../../assets/icons/edit.svg'
import { ReactComponent as RebootIcon } from '../../../../../assets/icons/reboot.svg'
import {MODAL_TYPES} from "../../../ModalsProvider";
import {useModal} from "../../../../../app/hooks/useModal";
import {dongleIMEIChangeDatagate, dongleRebootDatagate} from "../../../../../sdk/datagates/api/dongle";
import {getErrorMessage} from "../../../../../shared/lib/utils/get-error-message/getErrorMessage";
import {StatusNetwork} from "../../../../../shared/ui-kit-2/data-display/status-network/StatusNetwork";
import {useBackendErrorCodes} from "../../../../../sdk/datagates/helpers/_common/use-backend-error-codes";
import {RebootDeviceDialog} from "../../dialogs/rebootDeviceDialog/RebootDeviceDialog";
import {ChangeIMEIDialog} from "../../dialogs/changeIMEIDialog/ChangeIMEIDialog";
import {useSiteVersion} from "../../../../../app/hooks/useSiteVersion";

interface ModemInfoProps {
  device: DongleGetResponse
  handleFetchDevices: () => Promise<void>
  handleFetchDevice: (deviceId: number) => Promise<DongleGetResponse | undefined>
  handleDeleteDevice: (dongleId: number) => Promise<void>
  isTariffPackageEnabled: boolean
}


export const ModemInfo = (propsModemInfo: ModemInfoProps) => {
  const {
    device,
    handleFetchDevices,
    handleFetchDevice,
    handleDeleteDevice,
    isTariffPackageEnabled,
  } = propsModemInfo;
  const intl = useIntl();
  const { handleOpenSnackbar } = useSnackbar()
  const { handleOpenModal } = useModal()
  const { resolveBackendError } = useBackendErrorCodes()
  const { siteVersion } = useSiteVersion()
  const isRuSiteVersion = siteVersion === 'ru';
  const [openRebootDeviceDialog, setOpenRebootDeviceDialog] = useState<boolean>(false);
  const [openChangeIMEIDialog, setOpenChangeIMEIDialog] = useState<boolean>(false);
  const [changeIMEILoading, setChangeIMEILoading] = useState<boolean>(false);
  const [rebootIMEILoading, setRebootIMEILoading] = useState<boolean>(false);

  const handleCopyIMEI = (string: string) => {
    handleOpenSnackbar({
      type: SNACKBAR_TYPES.success,
      text: intl.formatMessage(DeviceDetailsModalMessages.handleCopy),
    })
    if (device)
      void copyToClipboard(string)
  }

  const handleEdit = () => {
    if (device)
      handleOpenModal({
        type: MODAL_TYPES.EDIT_DEVICE_NAME,
        props: {
          deviceId: device.dongleId,
          deviceName: device.name,
          handleFetchDevices: handleFetchDevices,
        },
      })
  }

  const handleDelete = () => {
    if (device)
      handleOpenModal({
        type: MODAL_TYPES.DELETE_ITEM,
        props: {
          handleSubmit: () => handleDeleteDevice(device.dongleId),
          deletingItemName: intl.formatMessage(
            DeviceDetailsModalMessages.deleteItemName,
          ),
        },
      })
  }

  const handleReboot = async () => {
    if (device)
      try {
        setRebootIMEILoading(true);
        await dongleRebootDatagate(null, [
          {
            name: 'dongle_id',
            value: device?.dongleId.toString(),
          },
        ])
        handleOpenSnackbar({
          type: SNACKBAR_TYPES.success,
          text: intl.formatMessage(DeviceDetailsModalMessages.successReboot),
        })
      } catch (e) {
        handleOpenSnackbar({
          type: SNACKBAR_TYPES.error,
          text: resolveBackendError(
            getErrorMessage(e),
            intl.formatMessage(DeviceDetailsModalMessages.errorReboot),
          ),
        })
      } finally {
        setRebootIMEILoading(false);
        setOpenRebootDeviceDialog(false);
        await handleFetchDevices();
      }
  }

  const handleChangeImei = async () => {
    if (device) {
      try {
        setChangeIMEILoading(true);
        await dongleIMEIChangeDatagate(null, [
          {
            name: 'dongle_id',
            value: device?.dongleId.toString(),
          },
        ])
        handleOpenSnackbar({
          type: SNACKBAR_TYPES.success,
          text: intl.formatMessage(DeviceDetailsModalMessages.successChangeIMEI),
        })
      } catch (e) {
        handleOpenSnackbar({
          type: SNACKBAR_TYPES.error,
          text: resolveBackendError(
            getErrorMessage(e),
            intl.formatMessage(DeviceDetailsModalMessages.errorChangeIMEI),
          ),
        })
      } finally {
        setOpenChangeIMEIDialog(false);
        setChangeIMEILoading(false);
        await handleFetchDevices();
      }
    }
  }

  const imeiManufacture = device.imeiManufacture || device?.bootstrapDongle.imeiManufacture;
  const imeiFake = device?.fakeImei?.imei || device?.bootstrapDongle?.imeiFake || imeiManufacture;

  return (
    <div className={clsx(styles.Card, styles.Card_modemInfo)}>
      <div className={styles.Title}>{device.name}</div>
      <div className={styles.Item}>
        <div className={styles.Label}>
          {intl.formatMessage(DeviceDetailsModalMessages.id, {
            id: device.dongleId
          })}
        </div>
      </div>
      {imeiManufacture && (
        <div className={styles.Item}>
          <div className={styles.Label}>
            {intl.formatMessage(DeviceDetailsModalMessages.imeiManufacture, {
              imei: imeiManufacture,
            })}
          </div>
          <button className={styles.Action} onClick={() => handleCopyIMEI(imeiManufacture)}>
            <span className={styles.CopyIcon}>
              <CopyIcon />
            </span>
            {intl.formatMessage(DeviceDetailsModalMessages.copy)}
          </button>
        </div>
      )}
      {imeiFake && !isRuSiteVersion && (
        <div className={styles.Item}>
          <div className={styles.Label}>
            {intl.formatMessage(DeviceDetailsModalMessages.imeiReal, {
              imei: imeiFake
            })}
          </div>
          <button className={styles.Action} onClick={() => handleCopyIMEI(imeiFake)}>
            <span className={styles.CopyIcon}>
              <CopyIcon />
            </span>
            {intl.formatMessage(DeviceDetailsModalMessages.copy)}
          </button>
          <button className={styles.Action} onClick={() => setOpenChangeIMEIDialog(true)}>
            <span className={styles.EditIcon}>
              <EditIcon />
            </span>
            {intl.formatMessage(DeviceDetailsModalMessages.imeiChange)}
          </button>
        </div>
      )}
      <div className={styles.Bottom}>
        <div className={styles.Status}>
          <StatusNetwork
            isOnline={device.isOnline}
            dongleSignalQualityId={device.dongleSignalQualityId}
          />
          {isTariffPackageEnabled &&
            <div>
              {intl.formatMessage(DeviceDetailsModalMessages.needMoney)}
            </div>
          }
        </div>

        <div className={styles.Row}>
          <button
            className={styles.RebootButton}
            onClick={() => setOpenRebootDeviceDialog(true)}
          >
            <RebootIcon/>
            {intl.formatMessage(DeviceDetailsModalMessages.reboot)}
          </button>
          <div className={styles.Control}>
            <IconButton
              icon={'edit'}
              onClick={handleEdit}
              data-test-id={
                LC.DEVICES.TABLE.ROW.ACTIONS.DEVICE_MENU_BTN.EDIT
              }
            />
          </div>
          <div className={styles.Control}>
            <IconButton
              icon={'trash'}
              onClick={handleDelete}
              data-test-id={
                LC.DEVICES.TABLE.ROW.ACTIONS.DEVICE_MENU_BTN.DELETE
              }
            />
          </div>
        </div>
      </div>
      {!isRuSiteVersion && (
        <div className={styles.VideoContainer}>
          <video
            autoPlay
            muted
            loop
            controls={false}
            className={styles.Video}
            playsInline
          >
            <source src={`/videos/wifi-hotspot.mp4`} type="video/mp4" />
          </video>
        </div>
      )}
      <div className={styles.Mask}></div>
      <RebootDeviceDialog
        open={openRebootDeviceDialog}
        onClose={() => setOpenRebootDeviceDialog(false)}
        onSuccess={handleReboot}
        loading={rebootIMEILoading}
      />
      <ChangeIMEIDialog
        open={openChangeIMEIDialog}
        onClose={() => setOpenChangeIMEIDialog(false)}
        onSuccess={handleChangeImei}
        loading={changeIMEILoading}
      />
    </div>
  );
};