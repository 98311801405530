import React, { FC } from 'react'
import { IconButtonProps } from '@mui/material'
import clsx from 'clsx'
import { IconButton } from '../../../shared/ui-kit-2/inputs/icon-button/IconButton'
import { LC } from '../../../tests/e2e/locators'
import styles from './styles.module.scss'

interface FilterOpenButtonProps extends Partial<IconButtonProps> {
  hasFilters: boolean;
  loading: boolean;
}

export const FilterOpenButton: FC<FilterOpenButtonProps> = (props) => {
  const { hasFilters, loading, ...otherProps } = props

  return (
    <div className={clsx(styles.Container, loading && styles.LoadingContainer)}>
      <div
        className={clsx(
          styles.HasFilters,
          hasFilters && styles.HasFilters_active,
        )}
      />
      <IconButton
        testId={LC.SMS.FILTER.BTN}
        {...otherProps}
        icon={'filter'}
        variant={'filled'}
        disabled={loading}
      />
    </div>
  )
}
