import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction, useEffect,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { LoadingContent } from '../../../../../layouts/loading-content/LoadingContent'
import { OrderGetResponse } from '../../../../../sdk/datagates/types/order/_crud/get'
import { useOrder } from '../../../../../sdk/hooks/use-order/useOrder'
import {PurchaseStepsFormType} from "../components/purchase-steps/PurchaseSteps";
import {useTariffPackages} from "../../../../../sdk/hooks/use-tariff-packages/useTariffPackages";
import {formatPrice} from "../../../../../sdk/formatters/format-price";
import {TARIFF_PACKAGE_ID} from "../../../../../sdk/datagates/types/order/_common";
import {orderDeliveryValidate} from "../../../../../sdk/datagates/api/order";
import {CALCULATE_DELIVERY_ERRORS} from "../constants/calculateDeliveryErrors";
import {getPhoneString} from "../../../../../shared/lib/utils/libphonenumber/create-phone-string";

interface PurchaseProcessProviderProps {
  children: ReactNode
}

export interface PurchaseProcessPageContextProps {
  formData?: PurchaseStepsFormType
  setFormData: Dispatch<SetStateAction<PurchaseStepsFormType | undefined>>
  order?: OrderGetResponse | null
  currentStep: string
  setCurrentStep: Dispatch<SetStateAction<string>>
  annualPlanCount: number
  setAnnualPlanCount: Dispatch<SetStateAction<number>>
  monthPlanCount: number
  setMonthPlanCount: Dispatch<SetStateAction<number>>
  deliveryPriceWasFetched: boolean
  setDeliveryPriceWasFetched: Dispatch<SetStateAction<boolean>>,

  calculateDelivery: (arg1: PurchaseStepsFormType) => void,
  calculateDeliveryLoading: boolean,
  setCalculateDeliveryLoading: Dispatch<SetStateAction<boolean>>,
  calculateDeliveryErrors: string[] | null,
  setCalculateDeliveryErrors: Dispatch<SetStateAction<string[] | null>>,

  totalPrice: string,
  setTotalPrice: Dispatch<SetStateAction<string>>,
  deliveryPrice: string,
  setDeliveryPrice: Dispatch<SetStateAction<string>>,
}

export const PURCHASE_STEPS = {
  CUSTOMER: 'CUSTOMER',
  SHIPPING: 'SHIPPING',
  PAYMENT: 'PAYMENT',
}

export const PurchaseProcessPageContext =
  createContext<PurchaseProcessPageContextProps>({
    formData: undefined,
    setFormData: () => {},
    order: null,
    currentStep: PURCHASE_STEPS.CUSTOMER,
    setCurrentStep: () => {},
    annualPlanCount: 0,
    setAnnualPlanCount: () => {},
    monthPlanCount: 0,
    setMonthPlanCount: () => {},
    deliveryPriceWasFetched: false,
    setDeliveryPriceWasFetched: () => {},

    calculateDelivery: () => {},
    calculateDeliveryLoading: false,
    setCalculateDeliveryLoading: () => {},
    calculateDeliveryErrors: [],
    setCalculateDeliveryErrors: () => {},

    totalPrice: '',
    setTotalPrice: () => {},
    deliveryPrice: '',
    setDeliveryPrice: () => {}
  })

export const PurchaseProcessPageContextProvider: FC<
  PurchaseProcessProviderProps
> = ({ children }) => {
  const [params, _] = useSearchParams()
  const orderId = params.get('order_id') ?? undefined

  const [monthPlanCount, setMonthPlanCount] = useState<number>(0)
  const [annualPlanCount, setAnnualPlanCount] = useState<number>(0)
  const [currentStep, setCurrentStep] = useState<string>(
    PURCHASE_STEPS.CUSTOMER,
  )
  const [deliveryPriceWasFetched, setDeliveryPriceWasFetched] = useState<boolean>(false);
  const [formData, setFormData] = useState<
    PurchaseStepsFormType | undefined
  >(undefined)
  const [calculateDeliveryLoading, setCalculateDeliveryLoading] = useState<boolean>(false);
  const [calculateDeliveryErrors, setCalculateDeliveryErrors] = useState<string[] | null>(null);
  const { getTotalPrices } = useTariffPackages()
  const [totalPrice, setTotalPrice] = useState<string>(formatPrice(null))
  const [deliveryPrice, setDeliveryPrice] = useState<string>(formatPrice(null))
  const { order, loading } = useOrder({ orderId: orderId || null })

  useEffect(() => {
    if (order) {
      setCurrentStep(PURCHASE_STEPS.PAYMENT)
    }
  }, [order]);

  const calculateDelivery = async (editedFormData: PurchaseStepsFormType) => {
    if (monthPlanCount <= 0 && annualPlanCount <= 0) return formatPrice(null)

    setCalculateDeliveryLoading(true);

    try {
      const { data } = await orderDeliveryValidate({
        order_dongles: [
            { tariffPackage_id: TARIFF_PACKAGE_ID.MONTH, count: monthPlanCount },
            { tariffPackage_id: TARIFF_PACKAGE_ID.ANNUAL, count: annualPlanCount },
        ].map((tp) => ({
            tariff_package_id: tp.tariffPackage_id,
            count: tp.count,
        })),
        first_name: editedFormData?.firstName,
        last_name: editedFormData?.lastName,
        phone: getPhoneString(
          editedFormData?.phoneCountryCode,
          editedFormData?.phoneNationalNumber,
        ),
        country_id: editedFormData?.countryId,
        postal_code: editedFormData?.postalCode,
        region: editedFormData?.region,
        city: editedFormData?.city,
        street_address1: editedFormData?.streetAddress1,
        street_address2: editedFormData?.streetAddress2,
        delivery_provider_id: 1
      });

      // We can get 3 variants: 1. data: null, 2. data: { errors: null }, 3. data: { errors: [] }, 4. data: { errors: [...] }.
      // 1, 2, 3 variants = no errors
      if (!data || !data.errors || data.errors.length === 0) {
        await getTotalPrices(
          [
            { tariffPackageId: TARIFF_PACKAGE_ID.MONTH, count: monthPlanCount },
            { tariffPackageId: TARIFF_PACKAGE_ID.ANNUAL, count: annualPlanCount },
          ],
          editedFormData?.countryId,
          editedFormData?.postalCode,
          editedFormData?.region,
          editedFormData?.city,
          editedFormData?.streetAddress1,
          editedFormData?.streetAddress2,
          1
        )
          .then((data) => {
            setTotalPrice(data.totalPrice)
            setDeliveryPrice(data.deliveryPrice)
          })
          .catch((data) => {
            setTotalPrice(data.totalPrice)
            setDeliveryPrice(data.deliveryPrice)
            setCalculateDeliveryErrors([CALCULATE_DELIVERY_ERRORS.POSTAL_CODE])
            setDeliveryPriceWasFetched(false);
          })
      } else {
        // @ts-ignore
        setCalculateDeliveryErrors(data?.errors)
      }

      setDeliveryPriceWasFetched(true);
    } catch (e) {
      setCalculateDeliveryErrors([CALCULATE_DELIVERY_ERRORS.VALIDATOR_400])
    } finally {
      setCalculateDeliveryLoading(false);
    }
  }

  return (
    <PurchaseProcessPageContext.Provider
      value={{
        formData,
        setFormData,
        order,
        currentStep,
        setCurrentStep,
        annualPlanCount,
        setAnnualPlanCount,
        monthPlanCount,
        setMonthPlanCount,
        deliveryPriceWasFetched,
        calculateDelivery,
        setDeliveryPriceWasFetched,
        calculateDeliveryLoading,
        setCalculateDeliveryLoading,
        calculateDeliveryErrors,
        setCalculateDeliveryErrors,
        deliveryPrice,
        setDeliveryPrice,
        totalPrice,
        setTotalPrice
      }}
    >
      <LoadingContent loading={loading} content={children} />
    </PurchaseProcessPageContext.Provider>
  )
}