import { useState } from 'react'
import clsx from 'clsx'
import { IconButton } from '../../../../shared/ui-kit-2/inputs/icon-button/IconButton'
import styles from './styles.module.scss'

type FaqProps = {
  items: Array<{
    label: string
    value: string
  }>
}

export const Faq = ({ items }: FaqProps) => {
  const [expandedItem, setExpandedItem] = useState<number | null>(null)

  return (
    <div className={styles.Faq}>
      {items.map(({ label, value }, i) => {
        const isExpandedItem = expandedItem === i

        return (
          <div className={styles.Item}>
            <div
              className={styles.Top}
              onClick={() => setExpandedItem((prev) => (prev !== i ? i : null))}
            >
              <div className={styles.Label}>{label}</div>
              <IconButton
                icon={isExpandedItem ? 'minus' : 'plus'}
                additionalClassNames={[styles.Button]}
              />
            </div>
            <div
              className={clsx(
                styles.Value,
                isExpandedItem && styles.Value_expanded,
              )}
            >
              {value}
            </div>
          </div>
        )
      })}
    </div>
  )
}
