import { defineMessages, useIntl } from 'react-intl'
import { CommonButtonMessages } from '../../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { useBoolActionSubmodal } from '../useBoolActionSubmodal'

interface UseDeleteMailingSubmodalProps {
  onDeleteMailing: () => Promise<void>
}

const UseDeleteMailingSubmodalMessages = defineMessages({
  actionName: {
    id: 'UseDeleteMailingSubmodalMessages.actionName',
    defaultMessage: 'delete',
  },
  actionItem: {
    id: 'UseDeleteMailingSubmodalMessages.actionItem',
    defaultMessage: 'mailing',
  },
  description: {
    id: 'UseDeleteMailingSubmodalMessages.description',
    defaultMessage: 'This action cannot be undone',
  },
})

export const useDeleteMailingSubmodal = (
  props: UseDeleteMailingSubmodalProps,
) => {
  const { onDeleteMailing } = props

  const intl = useIntl()

  const {
    setIsOpen: setIsDeleteSubmodalOpen,
    component: deleteSubmodalComponent,
  } = useBoolActionSubmodal({
    handleAction: onDeleteMailing,
    actionName: intl.formatMessage(UseDeleteMailingSubmodalMessages.actionName),
    itemName: intl.formatMessage(UseDeleteMailingSubmodalMessages.actionItem),
    description: intl.formatMessage(
      UseDeleteMailingSubmodalMessages.description,
    ),
    danger: true,
    actionButtonText: intl.formatMessage(CommonButtonMessages.yesDelete),
    actionButtonIcon: 'trash',
  })

  return {
    setIsDeleteSubmodalOpen,
    deleteSubmodalComponent,
  }
}
