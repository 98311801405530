import React, { FC } from 'react'
import { useNavigate } from 'react-router'
import { ReactComponent as LogoVector } from '../../assets/icons/logo.svg'
import { RoutePath } from '../../config/routes/constants/routePath'
import { Route } from '../../config/routes/enums/route'
import styles from './styles.module.scss'
import {useRootPath} from "../../shared/lib/hooks/useRootPath";

interface PageWrapperNoGUIProps {
  showLogo?: boolean
  children: React.ReactNode
}

export const PageWrapperNoGUI: FC<PageWrapperNoGUIProps> = ({
  showLogo = true,
  children,
}) => {
  const navigate = useNavigate();
  const rootPath = useRootPath();

  const toHome = () => navigate(rootPath);

  return (
    <div className={styles.Container}>
      {showLogo && <LogoVector className={styles.Logo} onClick={toHome} />}

      <div className={styles.Content}>{children}</div>
    </div>
  )
}
