import {
  DongleRedirectionListBackendResponse,
  DongleRedirectionListResponse,
} from '../../../../../../types/dongle/redirection/_crud/list'

export const mapDongleRedirectionListBackendToDongleRedirectionList = (
  item: DongleRedirectionListBackendResponse,
): DongleRedirectionListResponse => {
  const { redirections, total_count } = item.data

  return {
    redirections: redirections.map((el) => {
      const condition = JSON.parse(el.condition) as Array<any>
      const params = JSON.parse(el.params) as any

      const type = condition.slice(1).some((x) => x[1].startsWith('call'))
        ? 'call'
        : 'sms'
      const phones = condition.slice(1).map((p) => p[2])
      const emails = params.hasOwnProperty('emails') ? params['emails'] : []
      const telegramChatIds = params.hasOwnProperty('telegram_chat_ids')
        ? params['telegram_chat_ids']
        : []

      return {
        redirectionId: el.redirection_id,
        dongleId: el.dongle_id,
        name: el.name,
        type: type,
        fromPhones: phones,
        toEmails: emails,
        toTelegramChatIds: telegramChatIds,
        createdAt: el.created_at,
        updatedAt: el.updated_at,
      }
    }),
    totalCount: total_count,
  }
}
