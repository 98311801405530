import {
  PaymentMethodDeleteBackendResponse,
  PaymentMethodDeleteResponse,
} from '../../../../../../types/payment/payment-method/_crud/delete'

export const mapPaymentMethodDeleteBackendToPaymentMethodDelete = (
  item: PaymentMethodDeleteBackendResponse,
): PaymentMethodDeleteResponse => {
  return {
    data: item.data,
  }
}
