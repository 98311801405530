import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useSiteVersion } from '../../../app/hooks/useSiteVersion'
import { useAppSelector } from '../../../store'
import { JivoLoader } from '../jivo-loader/JivoLoader'

const setJivoChatUserData = (
  email: string,
  username: string,
  phone: string,
) => {
  //@ts-ignore
  jivo_api.setContactInfo({
    name: username,
    email: email,
    phone: phone,
  })
}

const mount = (
  id: string,
  email: string,
  setShowLoader: Dispatch<SetStateAction<boolean>>,
  username: string = 'Not specified',
  phone: string = 'Not specified',
) => {
  const script = document.createElement('script')

  script.type = 'text/javascript'
  script.async = true
  script.src = `//code.jivosite.com/script/widget/${id}`
  script.setAttribute('time', new Date().getTime().toString())

  const neighbour = document.getElementsByTagName('script')[0]
  neighbour.parentNode?.insertBefore(script, neighbour)

  const checkJdivMount = () => {
    const jdiv = document.getElementsByTagName('jdiv')[0]
    if (jdiv) {
      setShowLoader(false)

      //@ts-ignore
      jdiv.style.display = 'inline'
    } else setTimeout(checkJdivMount, 50)
  }

  const onJivoChatLoad = () => {
    //@ts-ignore
    if (typeof jivo_api !== 'undefined') {
      //@ts-ignore
      setJivoChatUserData(email, username, phone)
      checkJdivMount()
    } else {
      // Object jivo_site is not defined, continue waiting
      setTimeout(onJivoChatLoad, 50)
    }
  }

  script.onload = onJivoChatLoad

  return () => {
    script.remove()

    const jdiv = document.getElementsByTagName('jdiv')[0]
    if (jdiv) {
      //@ts-ignore
      jdiv.style.display = 'none'
    }
  }
}

const JivoChatId = 'zTvIORLFIk'

export const useJivoChat = () => {
  const { isAuthenticated, isAuthChecked } = useAppSelector(
    (state) => state.user,
  )
  const [showLoader, setShowLoader] = useState<boolean>(true)
  const { siteVersion } = useSiteVersion()

  useEffect(() => {
    let unmount: (() => void) | undefined

    if (isAuthChecked && isAuthenticated && siteVersion !== 'ru') {
      unmount = mount(JivoChatId, '', setShowLoader, '', '')
    } else {
      unmount && unmount()
      setShowLoader(true)
    }

    return () => {
      unmount && unmount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthChecked, isAuthenticated])

  if (isAuthenticated && showLoader && siteVersion !== 'ru') {
    return <JivoLoader />
  }

  return null
}
