import { wrapApiRequest } from '../../helpers/_common/wrap-api-request'
import { mapContactGroupCreateBackendToContactGroupCreate } from '../../mappers/contact-group/_crud/create/map-contact-group-create-backend-to-contact-group-create'
import { mapContactGroupDeleteBackendToContactGroupDelete } from '../../mappers/contact-group/_crud/delete/map-contact-group-delete-backend-to-contact-group-delete'
import { mapContactGroupGetBackendToContactGroupGet } from '../../mappers/contact-group/_crud/get/map-contact-group-get-backend-to-contact-group-get'
import { mapContactGroupListBackendToContactGroupList } from '../../mappers/contact-group/_crud/list/map-contact-group-list-backend-to-contact-group-list'
import { mapContactGroupUpdateBackendToContactGroupUpdate } from '../../mappers/contact-group/_crud/update/map-contact-group-update-backend-to-contact-group-update'
import {
  ContactGroupCreateBackendResponse,
  ContactGroupCreateRequest,
  ContactGroupCreateResponse,
} from '../../types/contact-group/_crud/create'
import {
  ContactGroupDeleteBackendResponse,
  ContactGroupDeleteRequest,
  ContactGroupDeleteResponse,
} from '../../types/contact-group/_crud/delete'
import {
  ContactGroupGetBackendResponse,
  ContactGroupGetRequest,
  ContactGroupGetResponse,
} from '../../types/contact-group/_crud/get'
import {
  ContactGroupListBackendResponse,
  ContactGroupListRequest,
  ContactGroupListResponse,
} from '../../types/contact-group/_crud/list'
import {
  ContactGroupUpdateBackendResponse,
  ContactGroupUpdateRequest,
  ContactGroupUpdateResponse,
} from '../../types/contact-group/_crud/update'

export const contactGroupListDatagate = wrapApiRequest<
  ContactGroupListRequest,
  ContactGroupListBackendResponse,
  ContactGroupListResponse
>('contact-group', mapContactGroupListBackendToContactGroupList, 'GET')

export const contactGroupGetDatagate = wrapApiRequest<
  ContactGroupGetRequest,
  ContactGroupGetBackendResponse,
  ContactGroupGetResponse
>(
  'contact-group/{contact_group_id}',
  mapContactGroupGetBackendToContactGroupGet,
  'GET',
)

export const contactGroupCreateDatagate = wrapApiRequest<
  ContactGroupCreateRequest,
  ContactGroupCreateBackendResponse,
  ContactGroupCreateResponse
>('contact-group', mapContactGroupCreateBackendToContactGroupCreate, 'POST')

export const contactGroupUpdateDatagate = wrapApiRequest<
  ContactGroupUpdateRequest,
  ContactGroupUpdateBackendResponse,
  ContactGroupUpdateResponse
>(
  'contact-group/{contact_group_id}',
  mapContactGroupUpdateBackendToContactGroupUpdate,
  'PATCH',
)

export const contactGroupDeleteDatagate = wrapApiRequest<
  ContactGroupDeleteRequest,
  ContactGroupDeleteBackendResponse,
  ContactGroupDeleteResponse
>(
  'contact-group/{contact_group_id}',
  mapContactGroupDeleteBackendToContactGroupDelete,
  'DELETE',
)
