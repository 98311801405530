import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import howToConnectModemModemImage from '../../../../../../../../assets/images/how-to-connect-modem-modem-image.png'
import { Card } from '../../../../../../../../shared/ui-kit-2/data-display/card/Card'
import styles from './styles.module.scss'

const HowToConnectModemMessages = defineMessages({
  title: {
    id: 'HowToConnectModemMessages.title',
    defaultMessage: 'How to connect {productName} modem?',
  },
  step1Title: {
    id: 'HowToConnectModemMessages.step1Title',
    defaultMessage: 'Insert the SIM card',
  },
  step1Description: {
    id: 'HowToConnectModemMessages.step1Description',
    defaultMessage:
      'The SIM card slot is located on the back side under the modem cover. ',
  },
  step2Title: {
    id: 'HowToConnectModemMessages.step2Title',
    defaultMessage: 'Enter your IMEI code and WiFi password',
  },
  step2Description: {
    id: 'HowToConnectModemMessages.step2Description',
    defaultMessage:
      'They can be found on a sticker under the back cover. Enter them in the fields at the top of this page',
  },
  step3Title: {
    id: 'HowToConnectModemMessages.step3Title',
    defaultMessage: 'Select and pay for your subscription',
  },
  step3Description: {
    id: 'HowToConnectModemMessages.step3Description',
    defaultMessage:
      'On the next screen, select and pay for your subscription. After this you will be able to use the modem',
  },
  step4Title: {
    id: 'HowToConnectModemMessages.step4Title',
    defaultMessage: 'Start enjoying all features!',
  },
})

export const HowToConnectModem = () => {
  const intl = useIntl()

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <div className={styles.Header}>
        {intl.formatMessage(HowToConnectModemMessages.title, {
          productName: <div className={styles.ProductName}>Telecorn</div>,
        })}
      </div>

      <div className={styles.Content}>
        <img
          src={howToConnectModemModemImage}
          alt="Modem"
          className={styles.ModemImage}
        />

        <div className={styles.Stepper}>
          <div className={styles.StepperTextContent}>
            <div className={styles.VerticalLine} />

            <div className={styles.StepperTextContent__title}>
              {intl.formatMessage(HowToConnectModemMessages.step1Title)}

              <div className={styles.Dot} />
            </div>
            <div className={styles.StepperTextContent__description}>
              {intl.formatMessage(HowToConnectModemMessages.step1Description)}
            </div>
          </div>
          <div className={styles.StepperTextContent}>
            <div className={styles.VerticalLine} />

            <div className={styles.StepperTextContent__title}>
              {intl.formatMessage(HowToConnectModemMessages.step2Title)}

              <div className={styles.Dot} />
            </div>
            <div className={styles.StepperTextContent__description}>
              {intl.formatMessage(HowToConnectModemMessages.step2Description)}
            </div>
          </div>
          <div className={styles.StepperTextContent}>
            <div className={styles.VerticalLine} />

            <div className={styles.StepperTextContent__title}>
              {intl.formatMessage(HowToConnectModemMessages.step3Title)}

              <div className={styles.Dot} />
            </div>
            <div className={styles.StepperTextContent__description}>
              {intl.formatMessage(HowToConnectModemMessages.step3Description)}
            </div>
          </div>
          <div className={styles.StepperTextContent}>
            <div className={styles.StepperTextContent__title}>
              {intl.formatMessage(HowToConnectModemMessages.step4Title)}

              <div className={styles.Dot} />
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}
