import React, { useCallback, useContext, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../../../app/hooks/useModal'
import { LoadingContent } from '../../../../../../../../layouts/loading-content/LoadingContent'
import { MODAL_TYPES } from '../../../../../../../../layouts/modals/ModalsProvider'
import { useDebounce } from '../../../../../../../../sdk/datagates/helpers/_common/debounce'
import { Card } from '../../../../../../../../shared/ui-kit-2/data-display/card/Card'
import { Counter } from '../../../../../../../../shared/ui-kit-2/data-display/counter/Counter'
import { PaginationMobile } from '../../../../../../../../shared/ui-kit-2/data-display/pagination/PaginationMobile'
import { Button } from '../../../../../../../../shared/ui-kit-2/inputs/button/Button'
import { SearchInputMobile } from '../../../../../../../../shared/ui-kit-2/inputs/searchInputMobile/SearchInputMobile'
import { ContactsPageContext } from '../../../../contexts/ContactsPageContext'
import { CardsGroupMobile } from './components/CardsGroupMobile'
import styles from './styles.module.scss'

const GroupsMobileMessages = defineMessages({
  title: {
    id: 'GroupsMobileMessages.title',
    defaultMessage: 'Groups',
  },
  searchGroups: {
    id: 'GroupsMobileMessages.searchContacts',
    defaultMessage: 'Enter group name',
  },
})

export const GroupsMobile = () => {
  const intl = useIntl()
  const [searchQuery, setSearchQuery] = useState<string>('')

  const debouncedSearch = useCallback(
    useDebounce((value: string) => {
      setGroupsSearchQuery(value)
    }, 700),
    [],
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
    debouncedSearch(event.target.value)
  }

  const {
    contactGroups,
    contactsLoading,
    handleFetchContacts,
    handleFetchContactGroups,
    setGroupsSearchQuery,
    setGroupsLimitPage,
    limitGroupsPage,
    groupsTotalCount,
    setGroupsPage,
    groupsPage,
  } = useContext(ContactsPageContext)

  const { handleOpenModal } = useModal()

  const openAddGroupModal = () => {
    handleOpenModal({
      type: MODAL_TYPES.CONTACT_GROUP_SETTINGS_MODAL,
      props: {
        handleFetchContacts,
        handleFetchContactGroups,
      },
    })
  }

  const handlePageChange = (page: number, limit: number) => {
    setGroupsPage(page)
    setGroupsLimitPage(limit)
  }

  const content = (
    <>
      <div className={styles.ContainerList}>
        {contactGroups.map((group) => (
          <CardsGroupMobile group={group} key={group.contactGroupId} />
        ))}
      </div>
    </>
  )

  return (
    <Card additionalClassNames={[styles.Container]}>
      <div className={styles.Header}>
        <div className={styles.Title}>
          {intl.formatMessage(GroupsMobileMessages.title)}
          <Counter variant={'filled'} count={contactGroups.length} />
        </div>
        <div className={styles.Actions}>
          <Button
            text={''}
            size={'small'}
            icon={'user-plus'}
            onClick={openAddGroupModal}
            additionalClassNames={[styles.AddGroupBtnAdditional]}
          />
          <SearchInputMobile
            value={searchQuery}
            defaultValue={searchQuery}
            placeholder={intl.formatMessage(GroupsMobileMessages.searchGroups)}
            onChange={handleChange}
          />
        </div>
      </div>
      <LoadingContent loading={contactsLoading} content={content} />
      <PaginationMobile
        totalCount={groupsTotalCount}
        onPageChange={handlePageChange}
        itemsPerPage={limitGroupsPage}
        currentPage={groupsPage}
      />
    </Card>
  )
}
