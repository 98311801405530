export const changeUrlParams = (params: { [key: string]: string | number | null | undefined }): void => {
  const currentUrl = new URL(window.location.href);

  currentUrl.search = '';

  Object.entries(params).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      currentUrl.searchParams.append(key, value.toString());
    }
  });

  window.history.replaceState({}, '', currentUrl.toString());
}