import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useSiteVersion } from '../../../../../../app/hooks/useSiteVersion'
import { useSnackbar } from '../../../../../../app/hooks/useSnackbar'
import { RoutePath } from '../../../../../../config/routes/constants/routePath'
import { Route } from '../../../../../../config/routes/enums/route'
import { SNACKBAR_TYPES } from '../../../../../../layouts/snackbar/SnackbarProvider'
import { signUpDatagate } from '../../../../../../sdk/datagates/api/auth'
import { useBackendErrorCodes } from '../../../../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { CheckboxField } from '../../../../../../shared/lib/form/form-field-adapters/v2/checkbox-field/CheckboxField'
import { InputField } from '../../../../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import { InputPasswordField } from '../../../../../../shared/lib/form/form-field-adapters/v2/input-password-field/InputPasswordField'
import {
  composeValidators,
  useFormRules,
} from '../../../../../../shared/lib/form/form-rules'
import { getErrorMessage } from '../../../../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Button } from '../../../../../../shared/ui-kit-2/inputs/button/Button'
import { LC } from '../../../../../../tests/e2e/locators'
import styles from './styles.module.scss'

interface SignUpFormProps {
  setEmail: Dispatch<SetStateAction<string | undefined>>
}

type SignUpFormType = {
  email: string
  password: string
  repeatPassword: string
  agreement: boolean
}

const SignUpFormMessages = defineMessages({
  emailLabel: {
    id: 'SignUpFormMessages.emailLabel',
    defaultMessage: 'Email',
  },
  emailPlaceholder: {
    id: 'SignUpFormMessages.emailPlaceholder',
    defaultMessage: 'Enter email',
  },
  passwordLabel: {
    id: 'SignUpFormMessages.passwordLabel',
    defaultMessage: 'Password',
  },
  passwordPlaceholder: {
    id: 'SignUpFormMessages.passwordPlaceholder',
    defaultMessage: 'Enter password',
  },
  repeatPasswordLabel: {
    id: 'SignUpFormMessages.repeatPasswordLabel',
    defaultMessage: 'Repeat password',
  },
  repeatPasswordPlaceholder: {
    id: 'SignUpFormMessages.repeatPasswordPlaceholder',
    defaultMessage: 'Repeat password',
  },
  agreementLabel: {
    id: 'SignUpFormMessages.agreementLabel',
    defaultMessage: 'I agree with {termsOfUse}',
  },
  termsOfUse: {
    id: 'SignUpFormMessages.termsOfUse',
    defaultMessage: 'Terms & Conditions',
  },
  submit: {
    id: 'SignUpFormMessages.submit',
    defaultMessage: 'Register',
  },
})

export const SignUpForm: FC<SignUpFormProps> = ({ setEmail }) => {
  const intl = useIntl()
  const { siteVersion, siteDomainId } = useSiteVersion()
  const { handleOpenSnackbar } = useSnackbar()
  const { resolveBackendError } = useBackendErrorCodes()
  const { ruleRequired, ruleEmail, rulePassword, rulePasswordsMismatch } =
    useFormRules()
  const [loading, setLoading] = useState<boolean>(false)

  const onSignUp = async (values: SignUpFormType) => {
    try {
      setLoading(true)

      await signUpDatagate({
        email: values.email,
        password: values.password,
        repeat_password: values.repeatPassword,
        site_domain_id: siteDomainId,
        locale_id: siteDomainId,
      })

      setEmail(values.email)
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    } finally {
      setLoading(false)
    }
  }

  const toTermsOfUse = () => window.open(RoutePath[Route.TermsOfUse], '_blank')

  return (
    <Form
      onSubmit={onSignUp}
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit} className={styles.Form}>
          <div className={styles.FormFields}>
            <InputField
              name={'email'}
              validate={composeValidators(ruleRequired(), ruleEmail())}
              label={intl.formatMessage(SignUpFormMessages.emailLabel)}
              placeholder={intl.formatMessage(
                SignUpFormMessages.emailPlaceholder,
              )}
              markAsRequired={true}
              testId={LC.SIGN_UP_PAGE.EMAIL_INPUT}
            />

            <div className={styles.PasswordFields}>
              <InputPasswordField
                name={'password'}
                validate={composeValidators(ruleRequired(), rulePassword())}
                label={intl.formatMessage(SignUpFormMessages.passwordLabel)}
                placeholder={intl.formatMessage(
                  SignUpFormMessages.passwordPlaceholder,
                )}
                markAsRequired={true}
                testId={LC.SIGN_UP_PAGE.PASSWORD_INPUT}
              />

              <InputPasswordField
                name={'repeatPassword'}
                validate={composeValidators(
                  ruleRequired(),
                  rulePasswordsMismatch('password', values),
                )}
                label={intl.formatMessage(
                  SignUpFormMessages.repeatPasswordLabel,
                )}
                placeholder={intl.formatMessage(
                  SignUpFormMessages.repeatPasswordPlaceholder,
                )}
                markAsRequired={true}
                testId={LC.SIGN_UP_PAGE.REPEAT_PASSWORD_INPUT}
              />
            </div>

            {siteVersion !== 'ru' && (
              <CheckboxField
                name={'agreement'}
                validate={ruleRequired()}
                text={
                  <div>
                    {intl.formatMessage(SignUpFormMessages.agreementLabel, {
                      termsOfUse: (
                        <a
                          className={styles.AgreementLink}
                          onClick={toTermsOfUse}
                        >
                          {intl.formatMessage(SignUpFormMessages.termsOfUse)}
                        </a>
                      ),
                    })}
                  </div>
                }
                markAsRequired={true}
                testId={LC.SIGN_UP_PAGE.TERMS_CHECKBOX}
              />
            )}
          </div>

          <Button
            type={'submit'}
            text={intl.formatMessage(SignUpFormMessages.submit)}
            loading={loading}
            disabled={loading || submitting}
            additionalClassNames={[styles.SignUpButtonAdditional]}
            testId={LC.SIGN_UP_PAGE.SUBMIT_BTN}
          />
        </form>
      )}
    />
  )
}
