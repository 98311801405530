import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { LC } from '../../../../tests/e2e/locators'
import { useClickOutside } from '../../../lib/hooks/useClickOutside'
import { Counter } from '../../data-display/counter/Counter'
import { IconButton } from '../icon-button/IconButton'
import { Input } from '../input/Input'
import { Switcher } from '../switcher/Switcher'
import { ReactComponent as DeleteBadgeIcon } from './assets/icons/delete-badge.svg'
import { ReactComponent as ErrorIcon } from './assets/icons/error.svg'
import { ReactComponent as SearchIcon } from './assets/icons/search.svg'
import { ReactComponent as SelectedIcon } from './assets/icons/selected.svg'
import { ReactComponent as ShowIcon } from './assets/icons/show.svg'
import styles from './styles.module.scss'

type MultiselectSize = 'default' | 'small'

export type MultiselectOption = {
  key: number | string
  label?: string
  subLabel?: string
  value: string
  disabled?: boolean
}

export interface MultiselectProps extends React.HTMLProps<HTMLInputElement> {
  options: MultiselectOption[]
  label?: string | undefined
  placeholder?: string
  notFoundText?: string
  searchPlaceholder?: string
  allOptionsSwitcher?: boolean
  allOptionsSwitcherLabel?: string
  multiselectSize?: MultiselectSize
  error?: string | undefined
  controlledValues?: string[] | undefined
  setControlledValues?: Dispatch<SetStateAction<string[]>> | undefined
  defaultValues?: MultiselectOption[] | undefined
  helperText?: string | undefined
  width?: number | undefined
  additionalClassNames?: string[]
  testId?: string
}

const MultiselectMessages = defineMessages({
  notFound: {
    id: 'MultiselectMessages.notFound',
    defaultMessage: 'Nothing found',
  },
})

export const Multiselect: FC<MultiselectProps> = (props) => {
  const {
    options,
    label,
    placeholder = 'Choose...',
    searchPlaceholder = 'Search',
    notFoundText,
    multiselectSize,
    error,
    controlledValues,
    setControlledValues,
    defaultValues,
    helperText,
    width,
    additionalClassNames = [],
    testId,
    allOptionsSwitcher = true,
    allOptionsSwitcherLabel = 'All',
    ...otherProps
  } = props

  const intl = useIntl()

  const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false)
  const [selectedOptions, setSelectedOptions] = useState<MultiselectOption[]>(
    defaultValues ?? [],
  )
  const [searchQuery, setSearchQuery] = useState('')
  const areaEl = useRef<HTMLDivElement>(null)
  const areaWidth = useMemo(() => (width ? width + 'px' : '100%'), [width])
  const isAllOptionsSelected = useMemo(() => {
    return selectedOptions.length === options.filter((o) => !o.disabled).length
  }, [selectedOptions])
  const disabled = useMemo(
    () => isAllOptionsSelected || otherProps.disabled,
    [isAllOptionsSelected, otherProps.disabled],
  )

  useEffect(() => {
    const newValues = selectedOptions.map((option) => option.value)

    if (controlledValues && setControlledValues) {
      setControlledValues(newValues)
      return
    }

    otherProps.onChange &&
      (otherProps.onChange as (event: any) => void)(newValues)
  }, [selectedOptions])

  const closeDropdown = () => {
    setSearchQuery('')
    setIsDropdownOpened(false)
  }

  useClickOutside(areaEl, closeDropdown)
  const onSelectChange = (option: MultiselectOption) => {
    setSelectedOptions((prevSelectedOptions) => {
      const isOptionSelected = prevSelectedOptions.some(
        (selectedOption) => selectedOption.key === option.key,
      )
      let newSelectedOptions

      if (isOptionSelected) {
        newSelectedOptions = prevSelectedOptions.filter(
          (selectedOption) => selectedOption.key !== option.key,
        )
      } else {
        newSelectedOptions = [...prevSelectedOptions, option]
      }

      return newSelectedOptions
    })
  }

  const handleTab = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab') closeDropdown()
  }

  const selectAllOptions = () => {
    setSelectedOptions(options.filter((option) => !option.disabled))
  }

  const deselectAllOptions = () => {
    setSelectedOptions([])
  }

  const handleAllSelectedToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = event.target.checked
    isChecked ? selectAllOptions() : deselectAllOptions()
  }

  const handleDeleteOption = (optionKey: number | string) => {
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.filter((option) => option.key !== optionKey),
    )
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }

  const handleTrashIcon = () => {
    setSelectedOptions([])
  }

  const filteredAndSortedOptions = useMemo(() => {
    return options
      .filter(
        (option) =>
          option.label!.toLowerCase().includes(searchQuery.toLowerCase()) ||
          option.subLabel?.toLowerCase().includes(searchQuery.toLowerCase()),
      )
      .sort((a, b) => {
        if (a.label! < b.label!) return -1
        if (a.label! > b.label!) return 1
        if (a.subLabel! < b.subLabel!) return -1
        if (a.subLabel! > b.subLabel!) return 1
        return 0
      })
  }, [options, searchQuery])

  return (
    <div
      className={clsx(styles.Select, ...additionalClassNames)}
      style={{ width: areaWidth }}
    >
      <div className={styles.LabelContainer}>
        {label && <label className={styles.Label}>{label}</label>}
        {allOptionsSwitcher && (
          <Switcher
            label={allOptionsSwitcherLabel}
            checked={selectedOptions.length === options.length}
            onChange={handleAllSelectedToggle}
            testId={LC.MULTISELECT.SWITCHER}
          />
        )}
      </div>

      <div
        className={clsx(
          styles.Area,
          error && styles.Area_error,
          disabled && styles.Area_disabled,
          multiselectSize === 'small' && styles.Area_small,
        )}
        ref={areaEl}
        tabIndex={0}
        onKeyDown={handleTab}
        onClick={
          !otherProps.disabled
            ? () => setIsDropdownOpened((prevState) => !prevState)
            : () => {}
        }
        data-test-id={testId}
      >
        {/* REAL INPUT */}
        <input
          type={'text'}
          value={controlledValues || otherProps.value || ''}
          readOnly={true}
          style={{ display: 'none' }}
          {...otherProps}
        />

        <div className={styles.BadgesContainer}>
          <div className={styles.Badges}>
            {isAllOptionsSelected && selectedOptions.length !== 0 ? (
              <div
                className={clsx(
                  styles.Badge,
                  multiselectSize === 'small' && styles.Badge_small,
                )}
              >
                {allOptionsSwitcherLabel}
              </div>
            ) : selectedOptions.length === 0 ? (
              <div className={styles.Placeholder}>{placeholder}</div>
            ) : (
              selectedOptions.map((option, index) => {
                return (
                  <div
                    key={option.key}
                    className={clsx(
                      styles.Badge,
                      multiselectSize === 'small' && styles.Badge_small,
                    )}
                    data-test-id={LC.MULTISELECT.OPTION(index)}
                  >
                    {option.label}
                    <DeleteBadgeIcon
                      onClick={(event) => {
                        event.stopPropagation()
                        handleDeleteOption(option.key)
                      }}
                    />
                  </div>
                )
              })
            )}
          </div>
          <div className={styles.InputPostfix}>
            {selectedOptions.length > 0 && (
              <Counter count={selectedOptions.length} />
            )}
            <ShowIcon
              className={clsx(
                styles.Arrow,
                isDropdownOpened && styles.Arrow_active,
              )}
            />
            <div
              className={clsx(
                styles.InputPostfix__overlay,
                styles.InputPostfix__overlay_small,
              )}
            ></div>
          </div>
        </div>

        {isDropdownOpened && (
          <div
            className={clsx(
              styles.Dropdown,
              multiselectSize === 'small' && styles.Dropdown_small,
            )}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.select_area_dropdown_content}>
              <div className={styles.SearchContainer}>
                <Input
                  placeholder={searchPlaceholder || 'Search...'}
                  inputSize={'small'}
                  additionalClassNames={[styles.Search]}
                  validationNeeded={false}
                  postfix={<SearchIcon className={styles.Search__icon} />}
                  onChange={handleSearchChange}
                />
                <IconButton
                  icon={'trash'}
                  variant={'outlined'}
                  onClick={handleTrashIcon}
                />
              </div>
              <div className={styles.Options}>
                {filteredAndSortedOptions.length > 0 ? (
                  filteredAndSortedOptions.map((option, index) => (
                    <label
                      key={option.key}
                      data-test-id={LC.MULTISELECT.OPTION(index)}
                      className={clsx(
                        styles.Option,
                        option.disabled && styles.Option_disabled,
                      )}
                      onClick={() => !option.disabled && onSelectChange(option)}
                    >
                      <div className={styles.Option__text}>
                        <span>{option.subLabel}</span>
                        {option.label}
                      </div>
                      {selectedOptions.some(
                        (selectedOption) => selectedOption.key === option.key,
                      ) && <SelectedIcon />}
                    </label>
                  ))
                ) : (
                  <div className={styles.NotFound}>
                    {notFoundText ||
                      intl.formatMessage(MultiselectMessages.notFound)}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {helperText && <div className={styles.HelperText}>{helperText}</div>}
      {error && (
        <div className={styles.Error}>
          <ErrorIcon />
          <span>{error}</span>
        </div>
      )}
    </div>
  )
}
