import React, { ComponentProps, FC } from 'react'
import clsx from 'clsx'
import { Field, FieldRenderProps } from 'react-final-form'
import { getFieldError } from '../../../../../../../shared/lib/form/form-helpers/get-field-error/getFieldError'
import styles from './styles.module.scss'

type ContactUsInputFieldProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  ComponentProps<typeof Field<string>>

export const ContactUsInputField: FC<ContactUsInputFieldProps> = (props) => {
  const { ...otherProps } = props

  return (
    <Field {...otherProps}>
      {(fieldRenderProps) => {
        const { input, meta, ...otherFieldRenderProps } =
          fieldRenderProps as FieldRenderProps<string>
        const error = getFieldError(meta)

        return (
          <div className={styles.Wrapper}>
            <input
              className={clsx(styles.Input, error && styles.Input_error)}
              {...input}
              {...otherFieldRenderProps}
            />
          </div>
        )
      }}
    </Field>
  )
}
