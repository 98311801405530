import React, { FC, useRef, useState } from 'react'
import clsx from 'clsx'
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg'
import { useClickOutside } from '../../../lib/hooks/useClickOutside'
import styles from './styles.module.scss'

const THEMES = {
  outlined: 'outlined',
  filled: 'filled',
}

export interface InputProps extends React.HTMLProps<HTMLInputElement> {
  placeholder?: string
  value: string
  theme?: 'outlined' | 'filled'
}

export const SearchInputMobile: FC<InputProps> = (props) => {
  const { placeholder, value, theme = 'filled', ...otherProps } = props

  const [isActive, setIsActive] = useState<boolean>(false)

  const ref = useRef<HTMLInputElement>(null)

  useClickOutside(ref, () => setIsActive(false))

  return (
    <div
      className={clsx(
        styles.InputContainer,
        theme === THEMES.outlined && styles.InputContainer_outlined,
        value && !isActive && styles.InputHasValue,
      )}
      onClick={() => !isActive && setIsActive(true)}
    >
      <input
        {...otherProps}
        className={clsx(styles.Input, isActive && styles.InputActive)}
        ref={ref}
        placeholder={placeholder}
      />
      {!isActive && <SearchIcon />}
    </div>
  )
}
