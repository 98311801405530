import { parseStringAsUTCDate } from '../../../../../../../../shared/lib/utils/date-utils/parseStringAsUTCDate'
import {
  TransactionGetBackendResponse,
  TransactionGetResponse,
} from '../../../../../../types/billing/transaction/_crud/get'

export const mapTransactionGetBackendToTransactionGet = (
  item: TransactionGetBackendResponse,
): TransactionGetResponse => {
  const {
    transaction_id,
    invoice_id,
    user_id,
    transaction_type_id,
    amount,
    created_at,
    updated_at,
    payment_method_id,
    parent_transaction_id,
    out_id,
  } = item.data

  return {
    transactionId: transaction_id,
    invoiceId: invoice_id,
    userId: user_id,
    transactionTypeId: transaction_type_id,
    amount: amount / 100,
    createdAt: parseStringAsUTCDate(created_at),
    updatedAt: parseStringAsUTCDate(updated_at),
    paymentMethodId: payment_method_id,
    parentTransactionId: parent_transaction_id,
    outId: out_id,
  }
}
