import { wrapApiRequest } from '../../helpers/_common/wrap-api-request'
import { mapMailingCreateBackendToMailingCreate } from '../../mappers/mailing/_crud/create/map-mailing-create-backend-to-mailing-create'
import { mapMailingDeleteBackendToMailingDelete } from '../../mappers/mailing/_crud/delete/map-mailing-delete-backend-to-mailing-delete'
import { mapMailingGetBackendToMailingGet } from '../../mappers/mailing/_crud/get/map-mailing-get-backend-to-mailing-get'
import { mapMailingListBackendToMailingList } from '../../mappers/mailing/_crud/list/map-mailing-list-backend-to-mailing-list'
import { mapMailingUpdateBackendToMailingUpdate } from '../../mappers/mailing/_crud/update/map-mailing-update-backend-to-mailing-update'
import {
  MailingCreateBackendResponse,
  MailingCreateRequest,
  MailingCreateResponse,
} from '../../types/mailing/_crud/create'
import {
  MailingDeleteBackendResponse,
  MailingDeleteRequest,
  MailingDeleteResponse,
} from '../../types/mailing/_crud/delete'
import {
  MailingGetBackendResponse,
  MailingGetRequest,
  MailingGetResponse,
} from '../../types/mailing/_crud/get'
import {
  MailingListBackendResponse,
  MailingListRequest,
  MailingListResponse,
} from '../../types/mailing/_crud/list'
import {
  MailingUpdateBackendResponse,
  MailingUpdateRequest,
  MailingUpdateResponse,
} from '../../types/mailing/_crud/update'

export const mailingListDatagate = wrapApiRequest<
  MailingListRequest,
  MailingListBackendResponse,
  MailingListResponse
>('mailing', mapMailingListBackendToMailingList, 'GET')

export const mailingGetDatagate = wrapApiRequest<
  MailingGetRequest,
  MailingGetBackendResponse,
  MailingGetResponse
>('mailing/{mailing_id}', mapMailingGetBackendToMailingGet, 'GET')

export const mailingCreateDatagate = wrapApiRequest<
  MailingCreateRequest,
  MailingCreateBackendResponse,
  MailingCreateResponse
>('mailing', mapMailingCreateBackendToMailingCreate, 'POST')

export const mailingUpdateDatagate = wrapApiRequest<
  MailingUpdateRequest,
  MailingUpdateBackendResponse,
  MailingUpdateResponse
>('mailing/{mailing_id}', mapMailingUpdateBackendToMailingUpdate, 'PATCH')

export const mailingDeleteDatagate = wrapApiRequest<
  MailingDeleteRequest,
  MailingDeleteBackendResponse,
  MailingDeleteResponse
>('mailing/{mailing_id}', mapMailingDeleteBackendToMailingDelete, 'DELETE')
