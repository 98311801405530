import React, { FC, useCallback, useRef, useState } from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { ReactComponent as ShowIcon } from '../../../../../../assets/icons/uncover.svg'
import { ReactComponent as SelectedIcon } from '../../../../../../shared/ui-kit-2/inputs/input-password/assets/icons/validated.svg'
import { useClickOutside } from '../../../../../lib/hooks/useClickOutside'
import styles from './styles.module.scss'

const TableShowElementsMessage = defineMessages({
  title: {
    id: 'TableShowElementsMessage.title',
    defaultMessage: 'Show by:',
  },
})

const elementsShowOptions: number[] = [50, 100]

interface TableShowElementsProps {
  elementsShow: number
  setElementsShow: React.Dispatch<React.SetStateAction<number>>
  onPageChange?: (page: number, limit: number) => void
}

export const TableShowElements: FC<TableShowElementsProps> = (props) => {
  const { elementsShow, setElementsShow, onPageChange } = props
  const ref = useRef<HTMLDivElement>(null)

  const intl = useIntl()
  const [open, setIsOpen] = useState(false)
  useClickOutside(ref, () => setIsOpen(false))

  const toggleDropdown = useCallback(() => setIsOpen((prev) => !prev), [])
  const handleSetElementsShow = useCallback((number: number) => {
    setElementsShow(number)
    if (onPageChange) onPageChange(1, elementsShow)
    setIsOpen(false)
  }, [])

  const title = `${intl.formatMessage(TableShowElementsMessage.title)} ${elementsShow}`

  return (
    <div className={styles.Container} onClick={toggleDropdown}>
      {title}
      <ShowIcon className={clsx(open && styles.ContainerIsOpen)} />
      {open && (
        <div className={styles.DropDownContainer} ref={ref}>
          {elementsShowOptions.map((num) => (
            <div
              key={num}
              className={styles.DropDownOption}
              onClick={() => handleSetElementsShow(num)}
            >
              {num}
              {elementsShow === num && <SelectedIcon />}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
