import {
  ContactListBackendResponse,
  ContactListResponse,
} from '../../../../../types/contact/_crud/list'

export const mapContactListBackendToContactList = (
  item: ContactListBackendResponse,
): ContactListResponse => {
  const { contacts, total_count } = item.data

  return {
    contacts: contacts.map((c) => {
      return {
        contactId: c.contact_id,
        groupsCount: c.groups_count,
        userId: c.user_id,
        phone: c.phone,
        name: c.name,
      }
    }),
    totalCount: total_count,
  }
}
