import axios, { HttpStatusCode } from 'axios'
import { debounce, throttle } from 'lodash'
import { checkAuthDatagate } from '../../sdk/datagates/api/auth'
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from '../../store/reducers/user'
import { getNginxHost } from './helpers/getApiUrl'

export const getApiUrl = () => getNginxHost('REACT_APP_API_URL')

const ACCESS_TOKEN_HEADER_KEY = 'access-token'

const axiosApiInstance = axios.create({
  baseURL: getApiUrl(),
  validateStatus: (status) => {
    return (status >= 200 && status < 300) || status === 400 || status === 403
  },
})

axiosApiInstance.interceptors.request.use(
  async (config) => {
    config.headers[ACCESS_TOKEN_HEADER_KEY] = getAccessToken()
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

const checkAuthDatagateThrottled = debounce(
  () => checkAuthDatagate({ refresh_token: getRefreshToken() }),
  1000,
  {
    leading: true,
    trailing: false,
  },
)

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    const originalRequest = error.config

    if (
      error.response.status === HttpStatusCode.Unauthorized &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true

      const response = await checkAuthDatagateThrottled()

      if (response?.data) {
        setAccessToken(response.data.accessToken)
        setRefreshToken(response.data.refreshToken)
        return axiosApiInstance(originalRequest)
      } else {
        delete axios.defaults.headers.common[ACCESS_TOKEN_HEADER_KEY]
      }
    }
    return Promise.reject(error)
  },
)

export default axiosApiInstance
