import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import emptyRedirectionsImg from '../../../assets/images/empty-redirections.png'
import styles from './styles.module.scss'
import {useSiteVersion} from "../../../app/hooks/useSiteVersion";

const EmptyAutoRepliesMessages = defineMessages({
  title: {
    id: 'EmptyAutoRepliesMessages.title',
    defaultMessage: 'Automatic reply to incoming messages',
  },
  description: {
    id: 'EmptyAutoRepliesMessages.description',
    defaultMessage:
      'Convenient set up for any type of numbers or for specific contacts or groups of contacts',
  },
})

export const EmptyAutoReplies = () => {
  const intl = useIntl()
  const { siteVersion } = useSiteVersion()

  return (
    <div className={styles.Container}>
      {siteVersion !== 'ru' && (
        <img
          src={emptyRedirectionsImg}
          alt="Redirections"
          className={styles.Image}
        />
      )}

      <div className={styles.Content}>
        <div className={styles.Title}>
          {intl.formatMessage(EmptyAutoRepliesMessages.title)}
        </div>
        <div className={styles.Description}>
          {intl.formatMessage(EmptyAutoRepliesMessages.description)}
        </div>
      </div>
    </div>
  )
}
