import React, { FC, ReactNode } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

interface GuestHeaderMenuButtonProps
  extends React.HTMLProps<HTMLAnchorElement> {
  children: ReactNode
  variant: 'text' | 'bordered' | 'gradient'
  href?: string
  isHeaderOnTop?: boolean
}

export const GuestHeaderMenuButton: FC<GuestHeaderMenuButtonProps> = (
  props,
) => {
  const { isHeaderOnTop, variant, href, children, ...otherProps } = props

  const className = clsx(
    styles.HeaderButton,
    variant === 'text' &&
      (isHeaderOnTop ? styles.HeaderButton : styles.HeaderButton),
    variant === 'bordered' &&
      (isHeaderOnTop ? styles.HeaderButton : styles.HeaderButton),
    variant === 'gradient' && styles.HeaderButton,
  )

  return (
    <a href={href} className={className} {...otherProps}>
      {children}
    </a>
  )
}
