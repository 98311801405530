import { wrapApiRequest } from '../../helpers/_common/wrap-api-request'
import { mapOAuthGoogleAuthUrlBackendToOAuthGoogleAuthUrl } from '../../mappers/oauth/google/auth-url/map-oauth-google-auth-url-backend-to-oauth-google-auth-url'
import { mapOAuthGoogleCallbackBackendToOAuthGoogleCallback } from '../../mappers/oauth/google/callback/map-oauth-google-callback-backend-to-oauth-google-callback'
import {
  OAuthGoogleAuthUrlBackendResponse,
  OAuthGoogleAuthUrlRequest,
  OAuthGoogleAuthUrlResponse,
} from '../../types/oauth/google/auth-url'
import {
  OAuthGoogleCallbackBackendResponse,
  OAuthGoogleCallbackRequest,
  OAuthGoogleCallbackResponse,
} from '../../types/oauth/google/callback'

// Get google oauth sign in link
export const getGoogleAuthUrlDatagate = wrapApiRequest<
  OAuthGoogleAuthUrlRequest,
  OAuthGoogleAuthUrlBackendResponse,
  OAuthGoogleAuthUrlResponse
>(
  'oauth/google/auth-url',
  mapOAuthGoogleAuthUrlBackendToOAuthGoogleAuthUrl,
  'GET',
)

// Get google oauth sign in link
export const googleAuthCallbackDatagate = wrapApiRequest<
  OAuthGoogleCallbackRequest,
  OAuthGoogleCallbackBackendResponse,
  OAuthGoogleCallbackResponse
>(
  'oauth/google/callback',
  mapOAuthGoogleCallbackBackendToOAuthGoogleCallback,
  'POST',
)
