import { HomePage } from '../../../../../pages/home/HomePage'
import { RoutePath } from '../../../constants/routePath'
import { Route } from '../../../enums/route'
import { RouteDescriptor } from '../../../types/routeDescriptor'

export const home: RouteDescriptor<Route.Home> = {
  route: Route.Home,
  type: 'page',
  title: 'Telecorn',
  path: RoutePath[Route.Home],
  render: HomePage,
  accessLevel: 'all',
  noAccessRedirectPath: '/',
}
