import {
  PaymentMethodCreateBackendResponse,
  PaymentMethodCreateResponse,
} from '../../../../../../types/payment/payment-method/_crud/create'

export const mapPaymentMethodCreateBackendToPaymentMethodCreate = (
  item: PaymentMethodCreateBackendResponse,
): PaymentMethodCreateResponse => {
  const { status, payment_credentials } = item.data

  return {
    status: status,
    paymentCredentials: {
      paymentProviderId: payment_credentials.service,
      ...payment_credentials,
    },
  }
}
