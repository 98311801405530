import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
  CloudTabs,
  CloudTabType,
} from '../../../../../../shared/ui-kit-2/navigation/cloud-tabs/CloudTabs'
import { ContactsMobile } from './components/contacts/ContactsMobile'
import { GroupsMobile } from './components/groups/GroupsMobile'
import styles from './styles.module.scss'

enum CONTACTS_PAGE_TABS {
  CONTACTS,
  GROUPS,
}

const ContactsMobilePageMessage = defineMessages({
  contactsTab: {
    id: 'ContactsMobilePageMessage.contactsTab',
    defaultMessage: 'Contacts',
  },
  groupsTab: {
    id: 'ContactsMobilePageMessage.groupsTab',
    defaultMessage: 'Groups',
  },
})

export const ContactsMobilePage = () => {
  const intl = useIntl()

  const cloudTabOptions: CloudTabType[] = [
    {
      id: CONTACTS_PAGE_TABS.CONTACTS.toString(),
      label: intl.formatMessage(ContactsMobilePageMessage.contactsTab),
      render: <ContactsMobile />,
    },
    {
      id: CONTACTS_PAGE_TABS.GROUPS.toString(),
      label: intl.formatMessage(ContactsMobilePageMessage.groupsTab),
      render: <GroupsMobile />,
    },
  ]

  return (
    <div className={styles.Container}>
      <CloudTabs items={cloudTabOptions} />
    </div>
  )
}
