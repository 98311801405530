import React, {FC} from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { CommonTableMessages } from '../../../config/intl/common-messages/common-table-messages/CommonTableMessages'
import { DongleListResponse } from '../../../sdk/datagates/types/dongle/_crud/list'
import {
  Table,
  TableColType,
} from '../../../shared/ui-kit-2/data-display/table/Table'
import { LC } from '../../../tests/e2e/locators'
import { NoDevices } from '../../status-layouts/no-devices/NoDevices'
import { DeviceTableRows } from './components/device-table-rows/DeviceTableRows'
import { ReactComponent as SortAlphabetIconUnActive } from "../../../assets/icons/sort-by-alphabet-unactive.svg";
import { ReactComponent as SortAlphabetIconTrue } from "../../../assets/icons/sort-by-alphabet-true.svg";
import { ReactComponent as SortAlphabetIconFalse } from "../../../assets/icons/sort-by-alphabet-false.svg";
import styles from './styles.module.scss'

const DeviceTableMessage = defineMessages({
  phoneNumber: {
    id: 'DeviceTableMessage.phoneNumber',
    defaultMessage: 'Phone number',
  },
})

interface DeviceTableProps {
  devices: DongleListResponse['dongles']
  totalCount: number
  inactiveDevicesTotalCount: number
  loading: boolean
  handleFetchDevices: () => void
  handleDeleteDevice: (dongleId: number) => Promise<void>
  handleSwitchHotspot: (dongleId: number, enabled: boolean) => Promise<void>
  settings: {
    page: number,
    limit: number,
    sortBy: string,
    orderBy: string
  }
  handleChangeSettings: (arg: Object) => void
}

const SORTED_COLS = {
  dongle_id: 'dongle_id',
  name: 'name',
  status: 'is_tariff_package_active',
  network: 'is_online'
}

export const DeviceTable: FC<DeviceTableProps> = (props) => {
  const {
    devices,
    loading,
    totalCount,
    inactiveDevicesTotalCount,
    handleFetchDevices,
    handleDeleteDevice,
    handleSwitchHotspot,
    settings,
    handleChangeSettings
  } = props

  const intl = useIntl()

  const handlePageChange = (page: number, limit: number) => handleChangeSettings({ page, limit })

  const handleSorted = (field: string) => {
    if (!loading) {
      const orderBy = field !== settings.sortBy
        ? 'desc'
        : settings.orderBy === 'asc'
          ? 'desc'
          : 'asc'
      handleChangeSettings({ orderBy, sortBy: field, page: 1 })
    }
  }

  const getIsActive = (field: string) => settings.sortBy === field;
  const getIsSorted = (field: string) => getIsActive(field) && settings.orderBy === 'desc';

  const getSortIcon = (field: string) => {
    if (settings.sortBy === field) {
      return settings.orderBy === 'desc' ? <SortAlphabetIconTrue /> : <SortAlphabetIconFalse />
    }

    return <SortAlphabetIconUnActive />
  }

  const cols: TableColType[] = [
    {
      title: intl.formatMessage(CommonTableMessages.columnId),
      handleSorted: () => handleSorted(SORTED_COLS.dongle_id),
      isSorted: getIsSorted(SORTED_COLS.dongle_id),
      isActive: getIsActive(SORTED_COLS.dongle_id)
    },
    {
      title: intl.formatMessage(CommonTableMessages.columnDevice),
      handleSorted: () => handleSorted(SORTED_COLS.name),
      isSorted: getIsSorted(SORTED_COLS.name),
      isActive: getIsActive(SORTED_COLS.name),
      isAZZA: true
    },
    { title: intl.formatMessage(DeviceTableMessage.phoneNumber) },
    { title: intl.formatMessage(CommonTableMessages.columnSms) },
    { title: intl.formatMessage(CommonTableMessages.columnCalls) },
    {
      title: intl.formatMessage(CommonTableMessages.columnStatus),
      handleSorted: () => handleSorted(SORTED_COLS.status),
      isSorted: getIsSorted(SORTED_COLS.status),
      sortIcon: getSortIcon(SORTED_COLS.status)
    },
    {
      title: intl.formatMessage(CommonTableMessages.columnNetwork),
      handleSorted: () => handleSorted(SORTED_COLS.network),
      isSorted: getIsSorted(SORTED_COLS.network),
      sortIcon: getSortIcon(SORTED_COLS.network)
    },
    {
      title: intl.formatMessage(CommonTableMessages.columnActions),
      position: 'right',
    },
  ]

  const rows = DeviceTableRows(
    devices,
    handleFetchDevices,
    handleDeleteDevice,
    handleSwitchHotspot,
  )

  const noData = totalCount + inactiveDevicesTotalCount === 0

  return (
    <Table
      cols={cols}
      rowGroups={rows}
      currentPage={settings.page}
      totalCount={totalCount}
      onPageChange={handlePageChange}
      itemsPerPage={settings.limit}
      columnWidths={[2, 5, 4, 4, 4, 2, 2, 1]}
      loading={loading}
      noDataComponent={noData ? <NoDevices /> : undefined}
      testId={LC.DEVICES.TABLE._}
      classes={{
        rowGroups: noData ? styles.RowGroups : '',
        container: styles.ContainerDevices
      }}
    />
  )
}
