import {
  DongleDeleteBackendResponse,
  DongleDeleteResponse,
} from '../../../../../types/dongle/_crud/delete'

export const mapDongleDeleteBackendToDongleDelete = (
  item: DongleDeleteBackendResponse,
): DongleDeleteResponse => {
  const { dongle_id, name, imei } = item.data

  return {
    dongleId: dongle_id,
    name: name,
    imei: imei,
  }
}
