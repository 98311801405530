import { defineMessages, useIntl } from 'react-intl'
import { REDIRECTION_TYPES } from './constants/RedirectionTypes'

const UseRedirectionTypesMessages = defineMessages({
  sms: {
    id: 'UseRedirectionTypesMessages.sms',
    defaultMessage: 'SMS',
  },
  call: {
    id: 'UseRedirectionTypesMessages.call',
    defaultMessage: 'Call',
  },
  unknown: {
    id: 'UseRedirectionTypesMessages.unknown',
    defaultMessage: 'Unknown',
  },
})

export const useRedirectionTypes = () => {
  const intl = useIntl()

  const getRedirectionTypeTranslation = (redirectionTypeId: number) => {
    switch (redirectionTypeId) {
      case REDIRECTION_TYPES.SMS:
        return intl.formatMessage(UseRedirectionTypesMessages.sms)
      case REDIRECTION_TYPES.CALL:
        return intl.formatMessage(UseRedirectionTypesMessages.call)
      default:
        return intl.formatMessage(UseRedirectionTypesMessages.unknown)
    }
  }

  return { getRedirectionTypeTranslation }
}
