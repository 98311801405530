import { AuthRoute } from './enums/authRoute'
import { recoverPassword } from './route-descriptors/guest/auth/subpages/recover-password/recoverPassword'
import { signIn } from './route-descriptors/guest/auth/subpages/sign-in/signIn'
import { signUp } from './route-descriptors/guest/auth/subpages/sign-up/signUp'
import { thirdParty } from './route-descriptors/guest/auth/subpages/third-party/thirdParty'
import { RouteDescriptor } from './types/routeDescriptor'

export const authRoutes: { [key in AuthRoute]: RouteDescriptor<key> } = {
  [AuthRoute.SignUp]: signUp,
  [AuthRoute.SignIn]: signIn,
  [AuthRoute.RecoverPassword]: recoverPassword,
  [AuthRoute.ThirdParty]: thirdParty,
}
