import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { OrderListResponse } from '../../../../../sdk/datagates/types/order/_crud/list'
import { useOrders } from '../../../../../sdk/hooks/use-orders/useOrders'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../../shared/lib/hooks/useLessThanDeviceScreen'

interface OrdersPageContextProps {
  ordersPage: number
  setOrdersPage: Dispatch<SetStateAction<number>>
  setOrdersLimitPage: Dispatch<SetStateAction<number>>
  limitOrdersPage: number
  orderFullList: OrderListResponse['orders']
  totalCount: number
  loading: boolean
  hasMoreOrders: boolean
  fetchMoreOrders: () => void
  fetchOrdersFirstPage: () => void
}

interface HomeContextProviderProps {
  children: ReactNode
}

export const OrdersPageContext = createContext<OrdersPageContextProps>({
  ordersPage: 0,
  setOrdersPage: () => {},
  setOrdersLimitPage: () => {},
  limitOrdersPage: 0,
  orderFullList: [],
  totalCount: 0,
  loading: true,
  hasMoreOrders: false,
  fetchMoreOrders: async () => {},
  fetchOrdersFirstPage: async () => {},
})

export const OrdersPageContextProvider: FC<HomeContextProviderProps> = ({
  children,
}) => {
  const isMobile = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK)

  const [page, setPage] = useState(1)
  const [limitOrdersPage, setLimitOrdersPage] = useState(50)
  const { orders, loading, totalCount, handleFetch, initialLoaded } = useOrders(
    {
      page,
      limit: limitOrdersPage,
    },
  )

  const [orderFullList, setOrderFullList] =
    useState<OrderListResponse['orders']>(orders)

  const clearOrderFullList = () => setOrderFullList([])

  const fetchOrdersFirstPage = () => {
    clearOrderFullList()

    if (page > 1) {
      setPage(1)
      return
    }

    void handleFetch()
  }

  const fetchMoreOrders = () => {
    setPage((prev) => prev + 1)
  }

  const hasMoreOrders = useMemo(
    () => orderFullList.length < totalCount,
    [orderFullList],
  )

  useEffect(() => {
    if (!initialLoaded && page === 1) void handleFetch()
  }, [page])

  useEffect(() => {
    if (page !== 1 && !isMobile) {
      setOrderFullList((prev) => [...prev, ...orders])
    } else {
      setOrderFullList(orders)
    }
  }, [orders, isMobile])

  return (
    <OrdersPageContext.Provider
      value={{
        ordersPage: page,
        setOrdersPage: setPage,
        setOrdersLimitPage: setLimitOrdersPage,
        limitOrdersPage,
        orderFullList,
        totalCount,
        loading,
        hasMoreOrders,
        fetchOrdersFirstPage,
        fetchMoreOrders,
      }}
    >
      {children}
    </OrdersPageContext.Provider>
  )
}
