import React from 'react'
import { Loader } from '../../../shared/ui-kit/data-display/loader/Loader'
import styles from './styles.module.scss'

export const JivoLoader = () => {
  return (
    <div className={styles.loader_wrapper}>
      <Loader color={'#ffffff'} />
    </div>
  )
}
