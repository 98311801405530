import { SmsRoute } from './enums/smsRoute'
import { smsAutoReply } from './route-descriptors/user/lk/subpages/sms/subpages/sms-auto-reply/sms-auto-reply'
import { smsList } from './route-descriptors/user/lk/subpages/sms/subpages/sms-list/sms-list'
import { smsMailing } from './route-descriptors/user/lk/subpages/sms/subpages/sms-mailing/sms-mailing'
import { smsRedirection } from './route-descriptors/user/lk/subpages/sms/subpages/sms-redirection/sms-redirection'
import { smsRules } from './route-descriptors/user/lk/subpages/sms/subpages/sms-rules/sms-rules'
import { RouteDescriptor } from './types/routeDescriptor'

export const smsRoutes: { [key in SmsRoute]: RouteDescriptor<key> } = {
  [SmsRoute.SmsList]: smsList,
  [SmsRoute.SmsRules]: smsRules,
  [SmsRoute.SmsRedirection]: smsRedirection,
  [SmsRoute.SmsAutoReply]: smsAutoReply,
  [SmsRoute.SmsMailing]: smsMailing,
}
