import { useDispatch, useSelector } from 'react-redux'
import { clearSnackbar, setSnackbar } from '../../store/reducers/snackbar'
import { SnackbarType } from '../../store/reducers/snackbar/types'

export const useSnackbar = () => {
  // @ts-ignore
  const { type, text } = useSelector((store) => store.snackbar)
  const dispatch = useDispatch()

  const handleHideSnackbar = () => dispatch(clearSnackbar({}))

  const handleOpenSnackbar = ({ type, text }: SnackbarType) => {
    dispatch(
      setSnackbar({
        type,
        text,
      }),
    )
  }

  return {
    handleOpenSnackbar,
    handleHideSnackbar,
    type,
    text,
  }
}
