import {
  ContactGroupUpdateBackendResponse,
  ContactGroupUpdateResponse,
} from '../../../../../types/contact-group/_crud/update'

export const mapContactGroupUpdateBackendToContactGroupUpdate = (
  item: ContactGroupUpdateBackendResponse,
): ContactGroupUpdateResponse => {
  const { contact_group_id, user_id, name } = item.data

  return {
    contactGroupId: contact_group_id,
    userId: user_id,
    name: name,
  }
}
