import { defineMessages } from 'react-intl'

export const CommonMultiselectMessages = defineMessages({
  all: {
    id: 'CommonMultiselectMessages.all',
    defaultMessage: 'All',
  },
  search: {
    id: 'CommonMultiselectMessages.search',
    defaultMessage: 'Search',
  },
  nothingFound: {
    id: 'CommonMultiselectMessages.nothingFound',
    defaultMessage: 'Nothing found',
  },
})
