import styles from './styles.module.scss'

type TextActionProps = {
  text: string
  onClick?: () => void
  disabled?: boolean
}

export const TextAction = ({
  text,
  onClick = () => null,
  disabled,
}: TextActionProps) => {
  return (
    <button className={styles.TextAction} onClick={onClick} disabled={disabled}>
      {text}
    </button>
  )
}
