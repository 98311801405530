import React, { FC } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import {defineMessages, useIntl} from "react-intl";

export interface TextareaProps extends React.HTMLProps<HTMLTextAreaElement> {
  label?: string
  error?: string
  markAsRequired?: boolean
  additionalClassNames?: string[]
  testId?: string
}

const TextareaMessages = defineMessages({
  maximum: {
    id: 'TextareaMessages.maximum',
    defaultMessage:
      'Maximum {maxLength} characters',
  },
})


export const Textarea: FC<TextareaProps> = (props) => {
  const {
    label,
    error,
    markAsRequired,
    additionalClassNames = [],
    maxLength = 1139,
    testId,
    ...otherProps
  } = props

  const intl = useIntl();

  return (
    <div className={styles.Container}>
      <div className={clsx(styles.Content, ...additionalClassNames)}>
        {label && (
          <label className={styles.Label}>
            <div className={styles.Label__title}>
              {label}
              {markAsRequired && <span>*</span>}
            </div>

            <div className={styles.Label__maxLengthText}>
              {intl.formatMessage(TextareaMessages.maximum, { maxLength })}
            </div>
          </label>
        )}

        <textarea
          className={styles.Textarea}
          {...otherProps}
          maxLength={maxLength}
          data-test-id={testId}
        />

        <div className={clsx(styles.Area, error && styles.Area_error)} />
      </div>

      {error && <div className={styles.Error}>{error}</div>}
    </div>
  )
}
