import { SmsPage } from '../../../../../../../pages/lk/subpages/sms/SmsPage'
import { AuthRoutePath } from '../../../../../constants/authRoutePath'
import { RoutePath } from '../../../../../constants/routePath'
import { AuthRoute } from '../../../../../enums/authRoute'
import { Route } from '../../../../../enums/route'
import { RouteDescriptor } from '../../../../../types/routeDescriptor'
import {CallsPage} from "../../../../../../../pages/lk/subpages/calls/CallsPage";

export const calls: RouteDescriptor<Route.Calls> = {
  route: Route.Calls,
  type: 'subpage',
  title: 'Telecorn',
  path: RoutePath[Route.Calls],
  render: CallsPage,
  accessLevel: 'user',
  noAccessRedirectPath: AuthRoutePath[AuthRoute.SignIn],
}
