import { AuthPage } from '../../../../../pages/auth/AuthPage'
import { RoutePath } from '../../../constants/routePath'
import { Route } from '../../../enums/route'
import { RouteDescriptor } from '../../../types/routeDescriptor'

export const auth: RouteDescriptor<Route.Auth> = {
  route: Route.Auth,
  type: 'page',
  title: 'Telecorn',
  path: RoutePath[Route.Auth],
  render: AuthPage,
  accessLevel: 'guest',
  noAccessRedirectPath: RoutePath[Route.DeviceList],
}
