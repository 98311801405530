import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import emptyRedirectionsImg from '../../../assets/images/empty-redirections.png'
import styles from './styles.module.scss'
import {useSiteVersion} from "../../../app/hooks/useSiteVersion";

const EmptyCallRedirectionsMessages = defineMessages({
  title: {
    id: 'EmptyCallRedirectionsMessages.title',
    defaultMessage: 'Flexible redirect rules',
  },
  description: {
    id: 'EmptyCallRedirectionsMessages.description',
    defaultMessage:
      'Receive notifications about Calls and SMS directly on your Email or Telegram',
  },
})

export const EmptyCallRedirections = () => {
  const intl = useIntl()
  const { siteVersion } = useSiteVersion();

  return (
    <div className={styles.Container}>
      {siteVersion !== 'ru' && (
        <img
          src={emptyRedirectionsImg}
          alt="Redirections"
          className={styles.Image}
        />
      )}

      <div className={styles.Content}>
        <div className={styles.Title}>
          {intl.formatMessage(EmptyCallRedirectionsMessages.title)}
        </div>
        <div className={styles.Description}>
          {intl.formatMessage(EmptyCallRedirectionsMessages.description)}
        </div>
      </div>
    </div>
  )
}
