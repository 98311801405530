import {
  DongleMobileNetworkIdsBackendResponse,
  DongleMobileNetworkIdsResponse,
} from '../../../../types/dongle/mobile-network-ids'

export const mapDongleMobileNetworkIdsBackendToDongleMobileNetworkIds = (
  item: DongleMobileNetworkIdsBackendResponse,
): DongleMobileNetworkIdsResponse => {
  const result = item.data.result

  return {
    networkIdsList: Object.entries(result).map(([k, v]) => {
      return { propName: k, propValue: v.toString() }
    }),
  }
}
