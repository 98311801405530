import React, { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { InvoiceTable } from '../../../../../../../layouts/tables/invoice-table/InvoiceTable'
import { useInvoices } from '../../../../../../../sdk/hooks/use-invoices/useInvoices'
import { Card } from '../../../../../../../shared/ui-kit-2/data-display/card/Card'
import styles from './styles.module.scss'

const BillingHistoryMessages = defineMessages({
  title: {
    id: 'BillingHistoryMessages.title',
    defaultMessage: 'Billing history',
  },
})

export const BillingHistory = () => {
  const intl = useIntl()
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(50)
  const { invoices, loading, totalCount } = useInvoices({
    page: page,
    limit: limit,
  })

  const sliceInvoices = invoices.slice(0, 3000)

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <div className={styles.Title}>
        {intl.formatMessage(BillingHistoryMessages.title)}
      </div>

      <InvoiceTable
        invoices={sliceInvoices}
        totalCount={totalCount}
        page={page}
        setPage={setPage}
        setLimit={setLimit}
        pageLimit={limit}
        loading={loading}
      />
    </Card>
  )
}
