import {
  ForgetPasswordBackendResponse,
  ForgetPasswordResponse,
} from '../../../../types/auth/forget-password'

export const mapForgetPasswordBackendToForgetPassword = (
  item: ForgetPasswordBackendResponse,
): ForgetPasswordResponse => {
  const { status } = item.data

  return {
    status: status,
  }
}
