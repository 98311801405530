import React from 'react'
import { PageWrapper } from '../../../../layouts/page-wrapper/PageWrapper'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { OrdersDesktopPage } from './components/ordersDesktopPage/OrdersDesktopPage'
import { OrdersMobilePage } from './components/ordersMobilePage/OrdersMobilePage'
import { OrdersPageContextProvider } from './contexts/OrdersPageContext'

export const OrdersPage = () => {
  const isLessThanLaptop = useLessThanDeviceScreen(ScreenDevices.LAPTOP)

  return (
    <PageWrapper>
      <OrdersPageContextProvider>
        {isLessThanLaptop ? <OrdersMobilePage /> : <OrdersDesktopPage />}
      </OrdersPageContextProvider>
    </PageWrapper>
  )
}
