import {
  CheckForgetPasswordCodeBackendResponse,
  CheckForgetPasswordCodeResponse,
} from '../../../../types/auth/check-forget-password-code'

export const mapCheckForgetPasswordCodeBackendToCheckForgetPasswordCode = (
  item: CheckForgetPasswordCodeBackendResponse,
): CheckForgetPasswordCodeResponse => {
  const { status } = item.data

  return {
    status: status,
  }
}
