import {defineMessages} from "react-intl";

export const DeviceDetailsModalMessages = defineMessages({
  title: {
    id: 'DeviceDetailsModalMessages.title',
    defaultMessage: 'Modem details',
  },
  copy: {
    id: 'DeviceDetailsModalMessages.copy',
    defaultMessage: 'Copy',
  },
  imeiChange: {
    id: 'DeviceDetailsModalMessages.imeiChange',
    defaultMessage: 'Change'
  },
  imeiManufacture: {
    id: 'DeviceDetailsModalMessages.imeiManufacture',
    defaultMessage: 'IMEI manufacture: {imei}',
  },
  imeiReal: {
    id: 'DeviceDetailsModalMessages.imeiReal',
    defaultMessage: 'IMEI real: {imei}',
  },
  id: {
    id: 'DeviceDetailsModalMessages.id',
    defaultMessage: 'ID: {id}',
  },
  wifiName: {
    id: 'DeviceDetailsModalMessages.wifiName',
    defaultMessage: 'WI-FI Name',
  },
  wifiPassword: {
    id: 'DeviceDetailsModalMessages.wifiPassword',
    defaultMessage: 'WI-FI Password',
  },
  wifi: {
    id: 'DeviceDetailsModalMessages.wifi',
    defaultMessage: 'WI-FI',
  },
  subscription: {
    id: 'DeviceDetailsModalMessages.subscription',
    defaultMessage: 'Subscription',
  },
  manage: {
    id: 'DeviceDetailsModalMessages.manage',
    defaultMessage: 'Manage',
  },
  networkInformation: {
    id: 'DeviceDetailsModalMessages.networkInformation',
    defaultMessage: 'Network Information',
  },
  redirects: {
    id: 'DeviceDetailsModalMessages.redirects',
    defaultMessage: 'Redirects Settings',
  },
  subscriptionType: {
    id: 'DeviceDetailsModalMessages.subscriptionType',
    defaultMessage: 'Subscription type',
  },
  tariffCost: {
    id: 'DeviceDetailsModalMessages.tariffCost',
    defaultMessage: 'Tariff cost',
  },
  subscriptionStarted: {
    id: 'DeviceDetailsModalMessages.subscriptionStarted',
    defaultMessage: 'Subscription started',
  },
  nextPayment: {
    id: 'DeviceDetailsModalMessages.nextPayment',
    defaultMessage: 'Next payment',
  },
  networkName: {
    id: 'DeviceDetailsModalMessages.networkName',
    defaultMessage: 'Network Name',
  },
  networkType: {
    id: 'DeviceDetailsModalMessages.networkType',
    defaultMessage: 'Network type',
  },
  number: {
    id: 'DeviceDetailsModalMessages.number',
    defaultMessage: 'Number',
  },
  details: {
    id: 'DeviceDetailsModalMessages.details',
    defaultMessage: 'Details',
  },
  handleCopy: {
    id: 'DeviceDetailsModalMessages.handleCopy',
    defaultMessage: 'IMEI was copied to clipboard',
  },
  deleteItemName: {
    id: 'DeviceDetailsModalMessages.deleteItemName',
    defaultMessage: 'device',
  },
  handleDelete: {
    id: 'DeviceDetailsModalMessages.handleDelete',
    defaultMessage: 'Device was deleted successfully',
  },
  online: {
    id: 'DeviceDetailsModalMessages.online',
    defaultMessage: 'Online',
  },
  offline: {
    id: 'DeviceDetailsModalMessages.offline',
    defaultMessage: 'Offline',
  },
  soon: {
    id: 'DeviceDetailsModalMessages.soon',
    defaultMessage: 'Soon',
  },
  reboot: {
    id: 'DeviceDetailsModalMessages.reboot',
    defaultMessage: 'Reboot',
  },
  successReboot: {
    id: 'DeviceDetailsModalMessages.successReboot',
    defaultMessage: 'Device is rebooting',
  },
  errorReboot: {
    id: 'DeviceDetailsModalMessages.errorReboot',
    defaultMessage: 'Device rebooting error',
  },
  warning: {
    id: 'DeviceDetailsModalMessages.warning',
    defaultMessage: 'Please connect the modem to the network',
  },
  active: {
    id: 'DeviceDetailsModalMessages.active',
    defaultMessage: 'active',
  },
  inactive: {
    id: 'DeviceDetailsModalMessages.inactive',
    defaultMessage: 'inactive',
  },
  autoRenewal: {
    id: 'DeviceDetailsModalMessages.autoRenewal',
    defaultMessage: 'Auto-renewal of subscription',
  },
  needMoney: {
    id: 'DeviceDetailsModalMessages.needMoney',
    defaultMessage: 'Needs to pay',
  },
  errorChangeIMEI: {
    id: 'DeviceDetailsModalMessages.errorChangeIMEI',
    defaultMessage: 'Device changing IMEI error',
  },
  successChangeIMEI: {
    id: 'DeviceDetailsModalMessages.successChangeIMEI',
    defaultMessage: 'IMEI of device was successfully changed',
  },
  detailsTab: {
    id: 'DeviceDetailsModalMessages.detailsTab',
    defaultMessage: 'Details',
  },
  ussdTab: {
    id: 'DeviceDetailsModalMessages.ussdTab',
    defaultMessage: 'USSD',
  }
})
