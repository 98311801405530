import React, { ComponentProps, FC } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import {
  Radio,
  RadioProps,
} from '../../../../../ui-kit-2/inputs/radio/components/Radio'
import { getFieldError } from '../../../form-helpers/get-field-error/getFieldError'

type RadioFieldProps = RadioProps & ComponentProps<typeof Field<string>>

export const RadioField: FC<RadioFieldProps> = (props) => {
  const { label, value, variant, ...otherProps } = props

  return (
    <Field {...otherProps}>
      {(fieldRenderProps) => {
        const { input, meta, ...otherFieldRenderProps } =
          fieldRenderProps as FieldRenderProps<string>

        return (
          <Radio
            label={label}
            error={getFieldError(meta)}
            {...input}
            {...otherFieldRenderProps}
            value={value}
            variant={variant}
            comparedValue={input.value}
          />
        )
      }}
    </Field>
  )
}
