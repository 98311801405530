import React, {useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { LoadingContent } from '../../loading-content/LoadingContent'
import styles from './styles.module.scss'
import {useDevice} from "../../../sdk/hooks/use-device/useDevice";
import {DeviceDetailsModalMessages} from "./messages/DeviceDetailsModalMessages";
import {TabType} from "../../../shared/ui-kit-2/navigation/tabs/Tabs";
import {CloudTabs} from "../../../shared/ui-kit-2/navigation/cloud-tabs/CloudTabs";
import {Details} from "./components/details/Details";
import {Ussd} from "./components/ussd/Ussd";

const TABS = {
  DETAILS: 'DETAILS',
  USSD: 'USSD'
};

export const DeviceDetailsModal = () => {
  const { handleHideModal, props } = useModal()
  const {
    deviceId,
    handleSwitchHotspot,
    handleFetchDevices,
    handleDeleteDevice,
  }: {
    deviceId: number
    handleFetchDevices: () => Promise<void>
    handleDeleteDevice: (dongleId: number) => Promise<void>
    handleSwitchHotspot: (dongleId: number, enabled: boolean) => Promise<void>
  } = props;

  const intl = useIntl()
  const {
    device,
    loading,
    handleFetch: handleFetchDevice
  } = useDevice({ deviceId });

  const [tab, setTab] = useState<string>(TABS.DETAILS);

  const tabs: TabType[] = [
    {
      id: TABS.DETAILS,
      label: intl.formatMessage(DeviceDetailsModalMessages.detailsTab),
    },
    {
      id: TABS.USSD,
      label: intl.formatMessage(DeviceDetailsModalMessages.ussdTab),
    },
  ];

  const content = useMemo(() => device
    ? (
      <div className={styles.Container}>
        <CloudTabs
          defaultTabId={TABS.DETAILS}
          items={tabs}
          handleChange={setTab}
          classes={{
            container: styles.CloudTabs
          }}
        />

        {tab === TABS.DETAILS && (
          <Details
            device={device}
            handleSwitchHotspot={handleSwitchHotspot}
            handleFetchDevices={handleFetchDevices}
            handleFetchDevice={handleFetchDevice}
            handleDeleteDevice={handleDeleteDevice}
          />
        )}

        {tab === TABS.USSD && (
          <Ussd device={device} />
        )}
      </div>
    ): <></>
  , [device, tab]);

  return (
    <Drawer
      isOpen={true}
      close={handleHideModal}
      title={intl.formatMessage(DeviceDetailsModalMessages.title)}
      limitedWidth={false}
      // additionalClassNames={[clsx((isOpenPayDevice || isPayOrChangeSubscription) && styles.ScrollOff)]}
      fullWidth
    >
     <LoadingContent loading={loading} content={content}/>
    </Drawer>
  )
}
