
import { defineMessages, useIntl } from "react-intl";
import { DialogComponent } from "../../../../../shared/ui-kit-2/data-display/dialog/Dialog"

type RebootDeviceDialogType = {
  open: boolean,
  onClose: () => void
  onSuccess: () => void
  loading: boolean
}

const ChangeIMEIDialogMessages = defineMessages({
  title: {
    id: 'ChangeIMEIDialogMessages.title',
    defaultMessage: 'Change IMEI',
  },
  content: {
    id: 'ChangeIMEIDialogMessages.content',
    defaultMessage: 'Are you sure you want to change IMEI of your device?'
  },
  actionNo: {
      id: 'ChangeIMEIDialogMessages.actionNo',
    defaultMessage: 'No'
  },
  actionYes: {
    id: 'ChangeIMEIDialogMessages.actionYes',
    defaultMessage: 'Yes, let\'s change IMEI'
  },
})

export const ChangeIMEIDialog = ({ open, onClose, onSuccess, loading }: RebootDeviceDialogType) => {
  const intl = useIntl()

  return (
    <DialogComponent
      onClose={onClose}
      onSuccess={onSuccess}
      open={open}
      loading={loading}
      title={intl.formatMessage(ChangeIMEIDialogMessages.title)}
      content={intl.formatMessage(ChangeIMEIDialogMessages.content)}
      actions={{
        cancel: intl.formatMessage(ChangeIMEIDialogMessages.actionNo) as string,
        submit: intl.formatMessage(ChangeIMEIDialogMessages.actionYes) as string,
      }}
    />
  )
}