import {useSiteVersion} from "../../../app/hooks/useSiteVersion";
import {RoutePath} from "../../../config/routes/constants/routePath";
import {Route} from "../../../config/routes/enums/route";

export const useRootPath = () => {
  const { siteVersion } = useSiteVersion();

  if (siteVersion === 'ru') {
    return RoutePath[Route.Auth];
  }

  return RoutePath[Route.Home];
}