export const formatDeliveryAddress = (data: {
  countryName?: string
  streetAddress1: string
  region?: string
  city?: string
  postalCode?: number
}) => {
  const { countryName, streetAddress1, region, city, postalCode } = data

  let address = '' + streetAddress1

  if (city) address += ', ' + city

  if (region) address += ', ' + region

  if (countryName) address += ', ' + countryName

  if (postalCode) address += ', ' + postalCode

  return address
}
