import {PageWrapper} from "../../../../layouts/page-wrapper/PageWrapper";
import {Navigate, Routes} from "react-router-dom";
import {AccessRoute} from "../../../../routing/AccessRoute";
import {RoutePath} from "../../../../config/routes/constants/routePath";
import {Route} from "../../../../config/routes/enums/route";
import React, {useEffect, useMemo} from "react";
import {defineMessages, useIntl} from "react-intl";
import { Route as ReactRoute, useLocation, useNavigate} from "react-router";
import {ScreenDevices, useLessThanDeviceScreen} from "../../../../shared/lib/hooks/useLessThanDeviceScreen";
import {CallsRoutePath} from "../../../../config/routes/constants/callsRoutePath";
import {CallsRoute} from "../../../../config/routes/enums/callsRoute";
import {callsRoutes} from "../../../../config/routes/callsRoutes";
import {CloudTabs, CloudTabType} from "../../../../shared/ui-kit-2/navigation/cloud-tabs/CloudTabs";
import styles from "./styles.module.scss";

const CALLS_PAGE_TABS_IDS = {
  CALLS_LIST: CallsRoutePath[CallsRoute.CallsList],
  CALLS_SETTINGS: CallsRoutePath[CallsRoute.CallsSettings],
}

const CallsPageMessages = defineMessages({
  callsList: {
    id: 'CallsPageMessages.callsList',
    defaultMessage: 'Calls',
  },
  callsSettings: {
    id: 'CallsPageMessages.callsSettings',
    defaultMessage: 'Settings',
  },
})



export const CallsPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const defaultTabId = useMemo(() => pathname, [])
  const isLaptopL = useLessThanDeviceScreen(ScreenDevices.LAPTOP_L)


  const cloudTabOptions: CloudTabType[] = [
    {
      id: CALLS_PAGE_TABS_IDS.CALLS_LIST,
      label: intl.formatMessage(CallsPageMessages.callsList),
      onClick: () => navigate(CallsRoutePath[CallsRoute.CallsList]),
    },
    {
      id: CALLS_PAGE_TABS_IDS.CALLS_SETTINGS,
      label: intl.formatMessage(CallsPageMessages.callsSettings),
      onClick: () => navigate(CallsRoutePath[CallsRoute.CallsSettings]),
    },
  ]

  const cloudTabOptionsMobile: CloudTabType[] = [
    {
      id: CALLS_PAGE_TABS_IDS.CALLS_LIST,
      label: intl.formatMessage(CallsPageMessages.callsList),
      onClick: () => navigate(CallsRoutePath[CallsRoute.CallsList]),
    },
    {
      id: CALLS_PAGE_TABS_IDS.CALLS_SETTINGS,
      label: intl.formatMessage(CallsPageMessages.callsSettings),
      onClick: () => navigate(CallsRoutePath[CallsRoute.CallsSettings]),
    },
  ]

  useEffect(() => {
    if (pathname === RoutePath[Route.Calls]) {
      navigate(CallsRoutePath[CallsRoute.CallsList])
    }
  }, [pathname])

  const handleTabChange = (path: string) => {
    navigate(path)
  }

  const currentCloudTabOptions = () => {
    if (isLaptopL) {
      return cloudTabOptionsMobile
    } else {
      return cloudTabOptions
    }
  }

  return (
    <PageWrapper>
      <Routes>
        {Object.values(callsRoutes).map((r) => (
          <ReactRoute
            key={r.route}
            path={r.path.split('/').pop()}
            element={
              <AccessRoute
                key={r.route}
                route={r}
              />
            }
          />
        ))}

        <ReactRoute
          path={'*'}
          element={<Navigate to={RoutePath[Route.NotFound]} replace={true} />}
        />
      </Routes>
    </PageWrapper>
  )
}