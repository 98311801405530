import React, { FC } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../../../../../../app/hooks/useModal'
import { ReactComponent as ForwardToIcon } from '../../../../../../../../../../../assets/icons/forward-to.svg'
import { MODAL_TYPES } from '../../../../../../../../../../../layouts/modals/ModalsProvider'
import { RedirectionListResponse } from '../../../../../../../../../../../sdk/datagates/types/redirection/_crud/list'
import { Counter } from '../../../../../../../../../../../shared/ui-kit-2/data-display/counter/Counter'
import { TextButton } from '../../../../../../../../../../../shared/ui-kit-2/inputs/text-button/TextButton'
import styles from './styles.module.scss'
import {useUserProfile} from "../../../../../../../../purchase-process/hooks/useUserProfile";

interface CallRedirectionCardProps {
  redirection: RedirectionListResponse['redirections'][0]
  handleFetch: () => Promise<void>
  handleDeleteRedirection: (redirectionId: number) => Promise<void>
}

const CallRedirectionCardMessages = defineMessages({
  devices: {
    id: 'CallRedirectionCardMessages.devices',
    defaultMessage: 'Devices',
  },
  all: {
    id: 'CallRedirectionCardMessages.all',
    defaultMessage: 'All',
  },
  any: {
    id: 'CallRedirectionCardMessages.any',
    defaultMessage: 'Any',
  },
  notSpecified: {
    id: 'CallRedirectionCardMessages.notSpecified',
    defaultMessage: 'Not specified',
  },
  details: {
    id: 'CallRedirectionCardMessages.details',
    defaultMessage: 'Details',
  },
  numbers: {
    id: 'CallRedirectionCardMessages.numbers',
    defaultMessage: 'Numbers',
  },
  contacts: {
    id: 'CallRedirectionCardMessages.contacts',
    defaultMessage: 'Contacts',
  },
  groups: {
    id: 'CallRedirectionCardMessages.groups',
    defaultMessage: 'Groups',
  },
  emails: {
    id: 'CallRedirectionCardMessages.emails',
    defaultMessage: 'Emails',
  },
  telegramChats: {
    id: 'CallRedirectionCardMessages.telegramChats',
    defaultMessage: 'Telegram chats',
  },
})

export const CallsRedirectionCard: FC<CallRedirectionCardProps> = (props) => {
  const { redirection, handleFetch, handleDeleteRedirection } = props

  const intl = useIntl()
  const { handleOpenModal } = useModal()
  const {
    userProfileData,
  } = useUserProfile();

  const openEditRedirectionModal = () =>
    handleOpenModal({
      type: MODAL_TYPES.REDIRECTION_SETTINGS,
      props: {
        redirection: redirection,
        redirectionTypeId: redirection.redirectionTypeId,
        handleFetch: handleFetch,
        handleDeleteRedirection: handleDeleteRedirection,
        phoneSourceVariant: 'international-only',
      },
    })

  const fromText =
    redirection.fromPhones.length === 0 &&
    redirection.fromContactIds.length === 0 &&
    redirection.fromContactGroupIds.length === 0 &&
    intl.formatMessage(CallRedirectionCardMessages.any)

  const toText =
    redirection.toEmails.length === 0 &&
    redirection.toTelegramChatIds.length === 0 &&
    userProfileData?.email;

  return (
    <div className={styles.Card} key={redirection.redirectionId}>
      <div className={styles.Header}>
        <div className={styles.Title}>{redirection.name}</div>

        <TextButton
          text={intl.formatMessage(CallRedirectionCardMessages.details)}
          icon={'edit'}
          onClick={openEditRedirectionModal}
          additionalClassNames={[styles.EditTextBtn]}
        />
      </div>

      <div className={styles.InnerHeader}>
        <div>{intl.formatMessage(CallRedirectionCardMessages.devices)}</div>
        {redirection.deviceIds.length > 0 ? (
          <Counter
            count={redirection.deviceIds.length}
            size={'small'}
            variant={'filled'}
          />
        ) : (
          intl.formatMessage(CallRedirectionCardMessages.all)
        )}
      </div>

      <div className={styles.InnerCardsContainer}>
        <div className={styles.InnerCard}>
          {fromText && (
            <div className={styles.InnerBody}>
              <div>
                {intl.formatMessage(CallRedirectionCardMessages.numbers)}
              </div>
              <div>{fromText}</div>
            </div>
          )}

          {redirection.fromPhones.length > 0 && (
            <div className={styles.InnerBody}>
              <div>
                {intl.formatMessage(CallRedirectionCardMessages.numbers)}
              </div>
              <Counter
                variant={'filled'}
                count={redirection.fromPhones.length}
                size={'small'}
              />
            </div>
          )}

          {redirection.fromContactIds.length > 0 && (
            <div className={styles.InnerBody}>
              <div>
                {intl.formatMessage(CallRedirectionCardMessages.contacts)}
              </div>
              <Counter
                variant={'filled'}
                count={redirection.fromContactIds.length}
                size={'small'}
              />
            </div>
          )}

          {redirection.fromContactGroupIds.length > 0 && (
            <div className={styles.InnerBody}>
              <div>
                {intl.formatMessage(CallRedirectionCardMessages.groups)}
              </div>
              <Counter
                variant={'filled'}
                count={redirection.fromContactGroupIds.length}
                size={'small'}
              />
            </div>
          )}

          <ForwardToIcon className={styles.ForwardTo} />
        </div>

        <div className={styles.InnerCard}>
          {toText && (
            <div className={styles.InnerHeader}>
              <div>-</div>
              <div>{toText}</div>
            </div>
          )}

          {redirection.toEmails.length > 0 && (
            <div className={styles.InnerBody}>
              <div>
                {intl.formatMessage(CallRedirectionCardMessages.emails)}
              </div>
              <Counter
                variant={'filled'}
                count={redirection.toEmails.length}
                size={'small'}
              />
            </div>
          )}

          {redirection.toTelegramChatIds.length > 0 && (
            <div className={styles.InnerBody}>
              <div>
                {intl.formatMessage(CallRedirectionCardMessages.telegramChats)}
              </div>
              <Counter
                variant={'filled'}
                count={redirection.toTelegramChatIds.length}
                size={'small'}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
