import React, { FC } from 'react'
import clsx from 'clsx'
import { useAppSelector } from '../../store'
import styles from './styles.module.scss'

export const PageWrapper: FC<React.HTMLProps<HTMLDivElement>> = ({
  children,
}) => {
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated)

  return (
    <div
      className={clsx(
        styles.Wrapper,
        isAuthenticated && styles.Wrapper_authenticated,
      )}
    >
      <div
        className={clsx(
          styles.Content,
          isAuthenticated && styles.Content_authenticated,
        )}
      >
        {children}
      </div>
    </div>
  )
}
