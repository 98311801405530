import React, { ChangeEvent, FC } from 'react'
import styles from '../styles.module.scss'

export interface SegmentedControlOptionProps
  extends React.HTMLProps<HTMLInputElement> {
  label: string
  value: string
  comparedValue?: string
  testId?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

export const SegmentedControlOption: FC<SegmentedControlOptionProps> = (
  props,
) => {
  const { label, value, comparedValue, testId, onChange, ...otherProps } = props

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event)
    }
  }

  return (
    <label className={styles.OptionContainer} data-test-id={testId}>
      <input
        type="radio"
        className={styles.HiddenInput}
        name={props.name}
        value={value}
        checked={value === comparedValue}
        onChange={handleChange}
        {...otherProps}
      />
      <div className={styles.Option}>{label}</div>
    </label>
  )
}
