import {
  CallbackRequestBackendResponse,
  CallbackRequestResponse,
} from '../../../../types/callback/callback-request'

export const mapCallbackRequestBackendToCallbackRequest = (
  item: CallbackRequestBackendResponse,
): CallbackRequestResponse => {
  const { status } = item.data

  return {
    status: status,
  }
}
