import React, { FC } from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { ReactComponent as BasketIcon } from '../../../assets/icons/header-dropdown-buy-new-device.svg'
import { LC } from '../../../tests/e2e/locators'
import { MODAL_TYPES } from '../../modals/ModalsProvider'
import styles from './styles.module.scss'

interface BuyDeviceButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  additionalClassNames?: string[]
}

const BuyDeviceButtonMessage = defineMessages({
  buy: {
    id: 'BuyDeviceButtonMessage.buy',
    defaultMessage: 'Buy a new device',
  },
})

export const BuyDeviceButton: FC<BuyDeviceButtonProps> = (props) => {
  const { additionalClassNames = [], ...otherProps } = props

  const intl = useIntl()
  const { handleOpenModal } = useModal()

  const toBuyNewDevice = () =>
    handleOpenModal({
      type: MODAL_TYPES.BUY_NEW_DEVICE,
    })

  return (
    <button
      className={clsx(styles.Button, ...additionalClassNames)}
      data-test-id={LC.HEADER.BUY_NEW_DEVICE_BTN}
      onClick={toBuyNewDevice}
      {...otherProps}
    >
      <div className={styles.Button__icon}>
        <BasketIcon />
      </div>
      <div className={styles.Button__text}>
        {intl.formatMessage(BuyDeviceButtonMessage.buy)}
      </div>
    </button>
  )
}
