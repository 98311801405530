import { parseStringAsUTCDate } from '../../../../../../../shared/lib/utils/date-utils/parseStringAsUTCDate'
import {
  MailingCreateBackendResponse,
  MailingCreateResponse,
} from '../../../../../types/mailing/_crud/create'

export const mapMailingCreateBackendToMailingCreate = (
  item: MailingCreateBackendResponse,
): MailingCreateResponse => {
  const {
    mailing_id,
    name,
    mailing_type_id,
    mailing_status_id,
    text,
    dongle_id,
    mailing_phones,
    contacts,
    contact_groups,
    scheduled_at,
  } = item.data

  const scheduledAtDate = scheduled_at
    ? parseStringAsUTCDate(scheduled_at)
    : undefined

  return {
    mailingId: mailing_id,
    name: name,
    mailingTypeId: mailing_type_id,
    mailingStatusId: mailing_status_id,
    text: text,
    dongleId: dongle_id,
    mailingPhones: mailing_phones.map((mp) => ({
      mailingPhoneId: mp.mailing_phone_id,
      phone: mp.phone,
      mailingId: mp.mailing_id,
    })),
    contacts: contacts.map((c) => {
      return {
        contactId: c.contact_id,
        name: c.name,
        phone: c.phone,
      }
    }),
    contactGroups: contact_groups.map((cg) => ({
      contactGroupId: cg.contact_group_id,
      name: cg.name,
    })),
    scheduledAt: scheduledAtDate,
  }
}
