import React, { FC, ReactNode, useContext, useRef, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { useSnackbar } from '../../../../../../../../app/hooks/useSnackbar'
import { CommonButtonMessages } from '../../../../../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { RoutePath } from '../../../../../../../../config/routes/constants/routePath'
import { Route } from '../../../../../../../../config/routes/enums/route'
import { SNACKBAR_TYPES } from '../../../../../../../../layouts/snackbar/SnackbarProvider'
import { useVerificationCodeInput } from '../../../../../../../../layouts/verification-code-input/useVerificationCodeInput'
import { dongleCreateDatagate } from '../../../../../../../../sdk/datagates/api/dongle'
import {
  BackendErrorCodes,
  useBackendErrorCodes,
} from '../../../../../../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { getErrorMessage } from '../../../../../../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Card } from '../../../../../../../../shared/ui-kit-2/data-display/card/Card'
import { Button } from '../../../../../../../../shared/ui-kit-2/inputs/button/Button'
import { Input } from '../../../../../../../../shared/ui-kit-2/inputs/input/Input'
import {
  ACTIVATE_DEVICE_STEPS,
  ActivateDeviceContext,
} from '../../../../contexts/ActivateDeviceContext'
import styles from './styles.module.scss'

const ConnectModemFormMessages = defineMessages({
  title: {
    id: 'ConnectModemFormMessages.title',
    defaultMessage: 'Activate your modem',
  },
  description: {
    id: 'ConnectModemFormMessages.description',
    defaultMessage:
      'Fill in the fields, insert the SIM card and turn on the modem',
  },
  imeiLabel: {
    id: 'ConnectModemFormMessages.imeiLabel',
    defaultMessage: 'Enter IMEI (Last 6 digits)',
  },
  hotspotPasswordLabel: {
    id: 'ConnectModemFormMessages.hotspotPasswordLabel',
    defaultMessage: 'Wi-Fi password',
  },
  hotspotPasswordPlaceholder: {
    id: 'ConnectModemFormMessages.hotspotPasswordPlaceholder',
    defaultMessage: 'Written on the back cover of the modem',
  },
  validationError: {
    id: 'ConnectModemFormMessages.validationError',
    defaultMessage:
      'Please enter last 6 digits of IMEI and Wi-Fi password of your device',
  },
})

const ConnectModemFormWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const intl = useIntl()

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <div className={styles.Content}>
        <div className={styles.Header}>
          <div className={styles.Title}>
            {intl.formatMessage(ConnectModemFormMessages.title)}
          </div>
          <div className={styles.Description}>
            {intl.formatMessage(ConnectModemFormMessages.description)}
          </div>
        </div>

        {children}
      </div>

      <div className={styles.VideoContainer}>
        <video
          autoPlay
          muted
          loop
          controls={false}
          className={styles.Video}
          playsInline
        >
          <source src="/videos/wifi-hotspot.mp4" type="video/mp4" />
        </video>
        <div className={styles.Mask}></div>
      </div>
    </Card>
  )
}

export const ConnectModemForm = () => {
  const { setCurrentStep, setImeiLastSixDigits } = useContext(
    ActivateDeviceContext,
  )

  const intl = useIntl()
  const navigate = useNavigate()
  const { handleOpenSnackbar } = useSnackbar()
  const { resolveBackendError } = useBackendErrorCodes()
  const hotspotPasswordInputRef = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [wifiPassword, setWifiPassword] = useState<string>('')

  const { component, fullCode } = useVerificationCodeInput(
    (code: string) => {
      hotspotPasswordInputRef.current?.focus()
    },
    loading,
    false,
  )

  const onSubmit = async () => {
    try {
      setLoading(true)
      if (!fullCode || !wifiPassword) {
        handleOpenSnackbar({
          type: SNACKBAR_TYPES.error,
          text: intl.formatMessage(ConnectModemFormMessages.validationError),
        })
        setLoading(false)
        return
      }

      await dongleCreateDatagate({
        name: 'Device_' + fullCode,
        imei_manufacture: fullCode,
        hotspot_password: wifiPassword,
      })

      navigate(RoutePath[Route.DeviceList])
    } catch (e) {
      const backendErrCode = getErrorMessage(e)

      setImeiLastSixDigits(fullCode)

      //TODO process case with existing payed orders
      if (
        backendErrCode ===
        BackendErrorCodes.DONGLE_ERROR_DONGLE_CREATE_ORDER_NOT_FOUND
      ) {
        setCurrentStep(ACTIVATE_DEVICE_STEPS.SUBSCRIPTION_PAYMENT)
        return
      }

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <ConnectModemFormWrapper>
      <div className={styles.Form}>
        <div className={styles.ImeiInputContainer}>
          <div className={styles.ImeiInputContainer__label}>
            {intl.formatMessage(ConnectModemFormMessages.imeiLabel)}
          </div>
          {component}
        </div>

        <Input
          name={'hotspotPassword'}
          label={intl.formatMessage(
            ConnectModemFormMessages.hotspotPasswordLabel,
          )}
          placeholder={intl.formatMessage(
            ConnectModemFormMessages.hotspotPasswordPlaceholder,
          )}
          value={wifiPassword}
          // @ts-ignore
          onChange={(e) => setWifiPassword(e.target.value)}
          inputRef={hotspotPasswordInputRef}
        />

        <Button
          onClick={onSubmit}
          text={intl.formatMessage(CommonButtonMessages.activate)}
          additionalClassNames={[styles.SubmitBtn]}
          loading={loading}
          disabled={loading}
        />
      </div>
    </ConnectModemFormWrapper>
  )
}
