import React, { FC, useContext } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../../../../app/hooks/useModal'
import { ReactComponent as CaretRight } from '../../../../../../../../../assets/icons/caret-right.svg'
import { ReactComponent as GroupIcon } from '../../../../../../../../../assets/icons/group-icon.svg'
import { MODAL_TYPES } from '../../../../../../../../../layouts/modals/ModalsProvider'
import { ContactGroupListResponse } from '../../../../../../../../../sdk/datagates/types/contact-group/_crud/list'
import { ContactsPageContext } from '../../../../../contexts/ContactsPageContext'
import styles from './styles.module.scss'

const CardsGroupMobileMessages = defineMessages({
  contactsCountTitle: {
    id: 'CardsGroupMobileMessages.contactsCountTitle',
    defaultMessage: 'Contacts',
  },
  contactCountTitle: {
    id: 'CardsGroupMobileMessages.contactCountTitle',
    defaultMessage: 'Contact',
  },
  deleteTitle: {
    id: 'CardsGroupMobileMessages.deleteTitle',
    defaultMessage: 'Are you sure you want to delete the group?',
  },
})

interface CardsGroupsMobileProps {
  group: ContactGroupListResponse['contactGroups'][0]
}

export const CardsGroupMobile: FC<CardsGroupsMobileProps> = (props) => {
  const { group } = props
  const intl = useIntl()
  const { handleOpenModal } = useModal()

  const {
    handleFetchContacts,
    handleFetchContactGroups,
    handleDeleteContactGroup,
  } = useContext(ContactsPageContext)

  const groupContactsTitle = () => {
    if (group.contactsCount === 1 || group.contactsCount === 0) {
      return `${intl.formatMessage(CardsGroupMobileMessages.contactCountTitle)}`
    } else {
      return `${intl.formatMessage(CardsGroupMobileMessages.contactsCountTitle)}`
    }
  }

  const openContactGroupSettingsModal = (contactGroupId: number) => {
    handleOpenModal({
      type: MODAL_TYPES.CONTACT_GROUP_SETTINGS_MODAL,
      props: {
        contactGroupId,
        handleFetchContacts,
        handleFetchContactGroups,
        handleDeleteContactGroup: () =>
          handleDeleteContactGroup(contactGroupId),
      },
    })
  }

  return (
    <div className={styles.ContactCardContainer}>
      <div className={styles.CardInfo}>
        <div className={styles.CardName}>{group.name}</div>
        <div className={styles.CardCountContainer}>
          <GroupIcon />
          <div className={styles.CardTitle}>{groupContactsTitle()}</div>
          <div className={styles.CardCounter}>{group.contactsCount}</div>
        </div>
      </div>

      <button
        className={styles.DetailsBtn}
        onClick={() => openContactGroupSettingsModal(group.contactGroupId)}
      >
        <CaretRight />
      </button>
    </div>
  )
}
