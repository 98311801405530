import { PaymentFailPage } from '../../../../../../../pages/lk/subpages/payment-fail/PaymentFailPage'
import { AuthRoutePath } from '../../../../../constants/authRoutePath'
import { RoutePath } from '../../../../../constants/routePath'
import { AuthRoute } from '../../../../../enums/authRoute'
import { Route } from '../../../../../enums/route'
import { RouteDescriptor } from '../../../../../types/routeDescriptor'

export const paymentFail: RouteDescriptor<Route.PaymentFail> = {
  route: Route.PaymentFail,
  type: 'subpage',
  title: 'Telecorn',
  path: RoutePath[Route.PaymentFail],
  render: PaymentFailPage,
  accessLevel: 'user',
  noAccessRedirectPath: AuthRoutePath[AuthRoute.SignIn],
}
