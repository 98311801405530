import { parseStringAsUTCDate } from '../../../../../../../shared/lib/utils/date-utils/parseStringAsUTCDate'
import {
  MailingListBackendResponse,
  MailingListResponse,
} from '../../../../../types/mailing/_crud/list'

export const mapMailingListBackendToMailingList = (
  item: MailingListBackendResponse,
): MailingListResponse => {
  const { mailings, total_count } = item.data

  return {
    mailings: mailings.map((m) => {
      const scheduledAtDate = m.scheduled_at
        ? parseStringAsUTCDate(m.scheduled_at)
        : undefined

      return {
        mailingId: m.mailing_id,
        name: m.name,
        mailingTypeId: m.mailing_type_id,
        mailingStatusId: m.mailing_status_id,
        text: m.text,
        dongle: {
          dongleId: m.dongle.dongle_id,
          name: m.dongle.name,
          number: m.dongle.number,
        },
        mailingHistory: m.mailing_history
          ? {
              mailingHistoryId: m.mailing_history.mailing_history_id,
              mailingId: m.mailing_history.mailing_id,
              createdAt: parseStringAsUTCDate(m.mailing_history.created_at),
            }
          : undefined,
        scheduledAt: scheduledAtDate,
        countMailingPhones: m.count_mailing_phones,
      }
    }),
    totalCount: total_count,
  }
}
