import React from 'react'
import clsx from 'clsx'
import styles from '../styles.module.scss'

export const useTablePages = (
  currentPage: number,
  pagesCount: number,
  onPageChange: (page: number, limit: number) => void,
  currentLimit: number,
) => {
  if (pagesCount <= 6) {
    return Array.from({ length: pagesCount }, (_, i) => i + 1).map(
      (page, index) => {
        return (
          <div
            key={index}
            className={clsx(
              styles.Pagination__page,
              currentPage === page && styles.Pagination__page_active,
            )}
            onClick={() => onPageChange(page, currentLimit)}
          >
            {page}
          </div>
        )
      },
    )
  }

  const rest = (
    <div
      className={clsx(styles.Pagination__page, styles.Pagination__page_rest)}
    >
      ...
    </div>
  )

  if (currentPage <= 3) {
    const left = Array.from({ length: 4 }, (_, i) => i + 1).map(
      (page, index) => {
        return (
          <div
            key={index}
            className={clsx(
              styles.Pagination__page,
              currentPage === page && styles.Pagination__page_active,
            )}
            onClick={() => onPageChange(page, currentLimit)}
          >
            {page}
          </div>
        )
      },
    )

    const right = (
      <div
        className={clsx(styles.Pagination__page)}
        onClick={() => onPageChange(pagesCount, currentLimit)}
      >
        {pagesCount}
      </div>
    )

    return (
      <>
        {left}
        {rest}
        {right}
      </>
    )
  }

  if (currentPage >= pagesCount - 2) {
    const left = (
      <div
        className={clsx(styles.Pagination__page)}
        onClick={() => onPageChange(1, currentLimit)}
      >
        {1}
      </div>
    )

    const right = Array.from({ length: 4 }, (_, i) => i + pagesCount - 3).map(
      (page, index) => {
        return (
          <div
            key={index}
            className={clsx(
              styles.Pagination__page,
              currentPage === page && styles.Pagination__page_active,
            )}
            onClick={() => onPageChange(page, currentLimit)}
          >
            {page}
          </div>
        )
      },
    )

    return (
      <>
        {left}
        {rest}
        {right}
      </>
    )
  }

  const left = (
    <div
      className={clsx(styles.Pagination__page)}
      onClick={() => onPageChange(1, currentLimit)}
    >
      {1}
    </div>
  )

  const right = (
    <div
      className={clsx(styles.Pagination__page)}
      onClick={() => onPageChange(pagesCount - 1, currentLimit)}
    >
      {pagesCount - 1}
    </div>
  )

  const middle = Array.from({ length: 3 }, (_, i) => i + currentPage - 1).map(
    (page, index) => {
      return (
        <div
          key={index}
          className={clsx(
            styles.Pagination__page,
            currentPage === page && styles.Pagination__page_active,
          )}
          onClick={() => onPageChange(page, currentLimit)}
        >
          {page}
        </div>
      )
    },
  )

  return (
    <>
      {left}
      {rest}
      {middle}
      {rest}
      {right}
    </>
  )
}
