import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { useSiteVersion } from '../../../../../../app/hooks/useSiteVersion'
import { useSnackbar } from '../../../../../../app/hooks/useSnackbar'
import { AuthRoutePath } from '../../../../../../config/routes/constants/authRoutePath'
import { AuthRoute } from '../../../../../../config/routes/enums/authRoute'
import { SNACKBAR_TYPES } from '../../../../../../layouts/snackbar/SnackbarProvider'
import { useVerificationCodeInput } from '../../../../../../layouts/verification-code-input/useVerificationCodeInput'
import {
  checkForgetPasswordCodeCheckDatagate,
  generatePasswordResetTokenDatagate,
} from '../../../../../../sdk/datagates/api/auth'
import { useBackendErrorCodes } from '../../../../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { getErrorMessage } from '../../../../../../shared/lib/utils/get-error-message/getErrorMessage'
import { TextButton } from '../../../../../../shared/ui-kit-2/inputs/text-button/TextButton'
import styles from '../../../sign-up/components/sign-up-approve/styles.module.scss'

interface ForgotPasswordVerificationFormProps {
  email: string
  setVerificationToken: Dispatch<SetStateAction<string>>
}

const ForgotPasswordVerificationFormMessages = defineMessages({
  title: {
    id: 'ForgotPasswordVerificationFormMessages.title',
    defaultMessage: 'Enter Verification Code',
  },
  description: {
    id: 'ForgotPasswordVerificationFormMessages.description',
    defaultMessage: 'The code was sent to {email}',
  },
  backButton: {
    id: 'ForgotPasswordVerificationFormMessages.backButton',
    defaultMessage: 'Back to sign up',
  },
})

export const ForgotPasswordVerificationForm: FC<
  ForgotPasswordVerificationFormProps
> = (props) => {
  const { email, setVerificationToken } = props

  const intl = useIntl()
  const { siteDomainId } = useSiteVersion()
  const { handleOpenSnackbar } = useSnackbar()
  const { resolveBackendError } = useBackendErrorCodes()
  const [loading, setLoading] = useState<boolean>(false)
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const navigate = useNavigate()

  const handleVerify = async (code: string) => {
    try {
      setLoading(true)
      await checkForgetPasswordCodeCheckDatagate({
        password_reset_token: code,
        email: email,
        site_domain_id: siteDomainId,
      })
      setVerificationToken(code)
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
      setHasErrors(true)
    } finally {
      setLoading(false)
    }
  }

  const { component } = useVerificationCodeInput(
    handleVerify,
    loading,
    hasErrors,
    () =>
      generatePasswordResetTokenDatagate({
        email: email,
        site_domain_id: siteDomainId,
      }),
  )
  const toSignIn = () => navigate(AuthRoutePath[AuthRoute.SignIn])

  return (
    <div className={styles.VerificationContainer}>
      <TextButton
        additionalClassNames={[styles.BackBtn]}
        text={intl.formatMessage(
          ForgotPasswordVerificationFormMessages.backButton,
        )}
        onClick={toSignIn}
        icon={'caret-left'}
      />

      <div className={styles.VerifySignUpContent}>
        <div className={styles.Title}>
          {intl.formatMessage(ForgotPasswordVerificationFormMessages.title)}
        </div>

        <div className={styles.Description}>
          {intl.formatMessage(
            ForgotPasswordVerificationFormMessages.description,
            { email: email },
          )}
        </div>

        {component}
      </div>
    </div>
  )
}
