import { AddCardSuccessPage } from '../../../../../../../pages/lk/subpages/add-card-success/AddCardSuccessPage'
import { AuthRoutePath } from '../../../../../constants/authRoutePath'
import { RoutePath } from '../../../../../constants/routePath'
import { AuthRoute } from '../../../../../enums/authRoute'
import { Route } from '../../../../../enums/route'
import { RouteDescriptor } from '../../../../../types/routeDescriptor'

export const addCardSuccess: RouteDescriptor<Route.AddCardSuccess> = {
  route: Route.AddCardSuccess,
  type: 'subpage',
  title: 'Telecorn',
  path: RoutePath[Route.AddCardSuccess],
  render: AddCardSuccessPage,
  accessLevel: 'user',
  noAccessRedirectPath: AuthRoutePath[AuthRoute.SignIn],
}
