import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { ReactComponent as FailPayIcon } from '../../../../assets/icons/payment-fail.svg'
import { RoutePath } from '../../../../config/routes/constants/routePath'
import { Route } from '../../../../config/routes/enums/route'
import { PageWrapper } from '../../../../layouts/page-wrapper/PageWrapper'
import { Card } from '../../../../shared/ui-kit-2/data-display/card/Card'
import { Button } from '../../../../shared/ui-kit-2/inputs/button/Button'
import styles from './styles.module.scss'

const PaymentFailPageMessages = defineMessages({
  title: {
    id: 'PaymentFailPageMessages.title',
    defaultMessage: 'Something went wrong',
  },
  description: {
    id: 'PaymentFailPageMessages.description',
    defaultMessage: 'Something went wrong during payment.',
  },
  myOrders: {
    id: 'PaymentFailPageMessages.myOrders',
    defaultMessage: 'To order',
  },
})

export const PaymentFailPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const orderId = new URLSearchParams(window.location.search).get('order_id');

  const toOrders = () => {
    if (orderId) {
      navigate(`${RoutePath[Route.Orders]}?order_id=${orderId}`)
    } else {
      navigate(RoutePath[Route.Orders])
    }
  }

  return (
    <PageWrapper>
      <Card additionalClassNames={[styles.Card]}>
        <FailPayIcon />

        <div className={styles.DescriptionAndButton}>
          <div className={styles.Description}>
            <div className={styles.Description__title}>
              {intl.formatMessage(PaymentFailPageMessages.title)}
            </div>
            <div>{intl.formatMessage(PaymentFailPageMessages.description)}</div>
          </div>
          <Button
            onClick={toOrders}
            text={intl.formatMessage(PaymentFailPageMessages.myOrders)}
          />
        </div>
      </Card>
    </PageWrapper>
  )
}
