import React, { FC, ReactNode } from 'react'
import { useIntl } from 'react-intl'
import styles from './styles.module.scss'

interface TipProps {
  imageSource: string
  text: ReactNode | string
}

export const Tip: FC<TipProps> = (props) => {
  const { imageSource, text } = props

  const intl = useIntl()

  return (
    <div className={styles.Container}>
      <img src={imageSource} alt="Tip" className={styles.Image} />
      <div className={styles.Text}>{text}</div>
    </div>
  )
}
