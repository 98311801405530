import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { useSiteVersion } from '../../../../../app/hooks/useSiteVersion'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { LandingButton } from '../../../../../shared/ui-kit-2/inputs/landing-button/LandingButton'
import DotsBackground from '../../../assets/dots.png'
import { Faq } from '../../Faq/Faq'
import { Home07Messages } from '../home-07/Home07'
import styles from './styles.module.scss'
import {RoutePath} from "../../../../../config/routes/constants/routePath";
import {Route} from "../../../../../config/routes/enums/route";
import {useNavigate} from "react-router";

export const Home08Messages = defineMessages({
  title: {
    id: 'Home08Messages.title',
    defaultMessage: 'Frequently Asked Questions',
  },
  seeAll: {
    id: 'Home08Messages.seeAll',
    defaultMessage: 'See all',
  },
  faqItem1Label: {
    id: 'Home08Messages.faqItem1Label',
    defaultMessage: 'What is Telecorn?',
  },
  faqItem1Value: {
    id: 'Home08Messages.faqItem1Value',
    defaultMessage:
      'Telecorn is a unique complex that combines a device and a cloud-platform. Our product is designed to improve business connections. Telecorn modems offer high-speed Internet access, SMS, and Call management, and IoT support.',
  },
  faqItem2Label: {
    id: 'Home08Messages.faqItem2Label',
    defaultMessage: 'What features does Telecorn offer?',
  },
  faqItem2Value: {
    id: 'Home08Messages.faqItem2Value',
    defaultMessage:
      'Using several modems, the user can manage them from one place - his personal account. Scheduled SMS, SMS mailings, calls, Wi Fi - manage all the functions of your SIM card remotely!',
  },
  faqItem3Label: {
    id: 'Home08Messages.faqItem3Label',
    defaultMessage: 'Who can use Telecorn services?',
  },
  faqItem3Value: {
    id: 'Home08Messages.faqItem3Value',
    defaultMessage:
      'Telecorn serves a wide range of businesses, from small businesses to large corporations. Also available for use by individuals, for remote access, in the smart home industry. Our services are valuable in a variety of industries, including transportation, agriculture, healthcare and more.',
  },
  faqItem4Label: {
    id: 'Home08Messages.faqItem4Label',
    defaultMessage: 'How can Telecorn improve my IoT experience?',
  },
  faqItem4Value: {
    id: 'Home08Messages.faqItem4Value',
    defaultMessage:
      "Telecorn's reliable LTE connectivity ensures seamless communication for IoT devices. It supports real-time data transfer for applications in security, energy, transportation, and more.",
  },
  faqItem5Label: {
    id: 'Home08Messages.faqItem5Label',
    defaultMessage: 'Is Telecorn suitable for international use? ',
  },
  faqItem5Value: {
    id: 'Home08Messages.faqItem5Value',
    defaultMessage:
      'Yes, Telecorn modems are designed for use worldwide, providing international businesses with the connectivity they need. However, different fees may apply, according to your carrier.',
  },
  faqItem6Label: {
    id: 'Home08Messages.faqItem6Label',
    defaultMessage: 'What makes Telecorn modems different from other options?',
  },
  faqItem6Value: {
    id: 'Home08Messages.faqItem6Value',
    defaultMessage:
      'Telecorn modems are known for their reliability, adaptability, and cost-effectiveness. Our devices offer advanced features, extended coverage, and support for multiple users, at an affordable price.',
  },
  faqItem7Label: {
    id: 'Home08Messages.faqItem7Label',
    defaultMessage:
      'Does Telecorn ship its product with a SIM card, or do I need to obtain one separately?',
  },
  faqItem7Value: {
    id: 'Home08Messages.faqItem7Value',
    defaultMessage:
      'Telecorn does not include a SIM card with its product. Customers and businesses are free to choose their own SIM card from their preferred mobile carrier, providing flexibility in selecting a carrier and data plan that meets their connectivity needs.',
  },
})

export const Home08 = () => {
  const isLessThanTablet = useLessThanDeviceScreen(ScreenDevices.LAPTOP)
  const intl = useIntl()
  const { siteVersion } = useSiteVersion()
  const navigate = useNavigate()

  const FAQ_ITEMS = [
    {
      label: intl.formatMessage(Home08Messages.faqItem1Label),
      value: intl.formatMessage(Home08Messages.faqItem1Value),
      en: true,
      ru: true,
    },
    {
      label: intl.formatMessage(Home08Messages.faqItem2Label),
      value: intl.formatMessage(Home08Messages.faqItem2Value),
      en: true,
      ru: true,
    },
    {
      label: intl.formatMessage(Home08Messages.faqItem3Label),
      value: intl.formatMessage(Home08Messages.faqItem3Value),
      en: true,
      ru: true,
    },
    {
      label: intl.formatMessage(Home08Messages.faqItem4Label),
      value: intl.formatMessage(Home08Messages.faqItem4Value),
      en: true,
      ru: true,
    },
    {
      label: intl.formatMessage(Home08Messages.faqItem5Label),
      value: intl.formatMessage(Home08Messages.faqItem5Value),
      en: true,
      ru: false,
    },
    {
      label: intl.formatMessage(Home08Messages.faqItem6Label),
      value: intl.formatMessage(Home08Messages.faqItem6Value),
      en: true,
      ru: false,
    },
    {
      label: intl.formatMessage(Home08Messages.faqItem7Label),
      value: intl.formatMessage(Home08Messages.faqItem7Value),
      en: true,
      ru: true,
    },
  ]

  const toFAQ = () => navigate(RoutePath[Route.FAQ])

  return (
    <div className={clsx(styles.Container)}>
      {!isLessThanTablet && (
        <div className={styles.Background}>
          <img src={DotsBackground} alt="" />
        </div>
      )}
      <div className={styles.Wrapper}>
        <div className={styles.Col}>
          <div className={styles.Title}>
            {intl.formatMessage(Home08Messages.title)}
          </div>
          <LandingButton
            text={intl.formatMessage(Home08Messages.seeAll)}
            variant={'greenTextOutlined'}
            additionalClassNames={[styles.Button]}
            onClick={toFAQ}
          />
        </div>
        <div className={styles.FaqList}>
          <Faq items={FAQ_ITEMS.filter((item) => item[siteVersion])} />
        </div>
      </div>
    </div>
  )
}
