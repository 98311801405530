import { wrapApiRequest } from '../../helpers/_common/wrap-api-request'
import { mapInvoiceGetBackendToInvoiceGet } from '../../mappers/billing/invoice/_crud/get/map-invoice-get-backend-to-invoice-get'
import { mapInvoiceListBackendToInvoiceList } from '../../mappers/billing/invoice/_crud/list/map-invoice-list-backend-to-invoice-list'
import { mapTariffPackageListBackendToTariffPackageList } from '../../mappers/billing/tariff-package/_crud/list/map-tariff-package-list-backend-to-package-list'
import { mapTransactionGetBackendToTransactionGet } from '../../mappers/billing/transaction/_crud/get/map-transaction-get-backend-to-transaction-get'
import { mapTransactionListBackendToTransactionList } from '../../mappers/billing/transaction/_crud/list/map-transaction-list-backend-to-transaction-list'
import {
  InvoiceGetBackendResponse,
  InvoiceGetRequest,
  InvoiceGetResponse,
} from '../../types/billing/invoice/_crud/get'
import {
  InvoiceListBackendResponse,
  InvoiceListRequest,
  InvoiceListResponse,
} from '../../types/billing/invoice/_crud/list'
import {
  TariffPackageListBackendResponse,
  TariffPackageListRequest,
  TariffPackageListResponse,
} from '../../types/billing/tariff-package/_crud/list'
import {
  TransactionGetBackendResponse,
  TransactionGetRequest,
  TransactionGetResponse,
} from '../../types/billing/transaction/_crud/get'
import {
  TransactionListBackendResponse,
  TransactionListRequest,
  TransactionListResponse,
} from '../../types/billing/transaction/_crud/list'

export const transactionListDatagate = wrapApiRequest<
  TransactionListRequest,
  TransactionListBackendResponse,
  TransactionListResponse
>('transaction', mapTransactionListBackendToTransactionList, 'GET')

export const transactionGetDatagate = wrapApiRequest<
  TransactionGetRequest,
  TransactionGetBackendResponse,
  TransactionGetResponse
>(
  'transaction/{transaction_id}',
  mapTransactionGetBackendToTransactionGet,
  'GET',
)

export const invoiceListDatagate = wrapApiRequest<
  InvoiceListRequest,
  InvoiceListBackendResponse,
  InvoiceListResponse
>('invoice', mapInvoiceListBackendToInvoiceList, 'GET')

export const invoiceGetDatagate = wrapApiRequest<
  InvoiceGetRequest,
  InvoiceGetBackendResponse,
  InvoiceGetResponse
>('invoice/{invoice_id}', mapInvoiceGetBackendToInvoiceGet, 'GET')

export const tariffPackageListDatagate = wrapApiRequest<
  TariffPackageListRequest,
  TariffPackageListBackendResponse,
  TariffPackageListResponse
>('tariff-package', mapTariffPackageListBackendToTariffPackageList, 'GET')
