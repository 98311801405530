import { useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { SNACKBAR_TYPES } from '../../../layouts/snackbar/SnackbarProvider'
import { countryListDatagate } from '../../datagates/api/country'
import { CountryMessages } from './constants/ContryMessages'
import { CountryFlagEmojis } from './constants/CountryFlagEmojis'

export type Country = {
  id: number
  iso: string
  name: string
  flagEmoji?: string
}

const UseCountriesMessages = defineMessages({
  fetchError: {
    id: 'UseCountriesMessages.fetchError',
    defaultMessage: 'An error occurred while fetching countries',
  },
})

export const useCountries = () => {
  const intl = useIntl()
  const { handleOpenSnackbar } = useSnackbar()
  const [loading, setLoading] = useState<boolean>(true)
  const [countries, setCountries] = useState<Country[]>([])

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await countryListDatagate()

        const countries = data.countries.map<Country>((c) => {
          const name = CountryMessages.hasOwnProperty(c.iso)
            ? intl.formatMessage(
                CountryMessages[c.iso as keyof typeof CountryMessages],
              )
            : c.iso

          const flagEmoji = CountryFlagEmojis.hasOwnProperty(c.iso)
            ? CountryFlagEmojis[c.iso as keyof typeof CountryFlagEmojis]
            : undefined

          return {
            id: c.countryId,
            iso: c.iso,
            name: name,
            flagEmoji: flagEmoji,
          }
        })

        setCountries(countries)
      } catch (e) {
        handleOpenSnackbar({
          type: SNACKBAR_TYPES.error,
          text: intl.formatMessage(UseCountriesMessages.fetchError),
        })
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return {
    loading,
    countries,
  }
}
