import parsePhoneNumber from 'libphonenumber-js'

export const parsePhoneInfo = (
  phone?: string,
): {
  internationalPhone?: string
  shortNumber?: string
  alphaName?: string
} => {
  if (!phone)
    return {
      internationalPhone: undefined,
      shortNumber: undefined,
      alphaName: undefined,
    }

  if (phone.match(/^[A-Za-z]+$/))
    return {
      internationalPhone: undefined,
      shortNumber: undefined,
      alphaName: phone,
    }

  if (phone.length <= 5)
    return {
      internationalPhone: undefined,
      shortNumber: phone,
      alphaName: undefined,
    }

  return {
    internationalPhone: phone,
    shortNumber: undefined,
    alphaName: undefined,
  }
}
