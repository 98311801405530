import { combineReducers } from 'redux'
import cartSlice from './cart'
import modalSlice from './modal'
import snackbarSlice from './snackbar'
import userSlice from './user'
import dialogSlice from './dialog'

export const rootReducer = combineReducers({
  user: userSlice,
  modal: modalSlice,
  snackbar: snackbarSlice,
  cart: cartSlice,
  dialog: dialogSlice
})
