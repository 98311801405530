import {
  DongleCallCreateBackendResponse,
  DongleCallCreateResponse,
} from '../../../../../../types/dongle/call/_crud/create'

export const mapDongleCallCreateBackendToDongleCallCreate = (
  item: DongleCallCreateBackendResponse,
): DongleCallCreateResponse => {
  const {
    call_id,
    dongle_id,
    call_type_id,
    number_caller,
    number_receiver,
    api_response_data,
    created_at,
    updated_at,
  } = item.data

  return {
    callId: call_id,
    dongleId: dongle_id,
    callTypeId: call_type_id,
    numberCaller: number_caller,
    numberReceiver: number_receiver,
    apiResponseData: api_response_data,
    createdAt: created_at,
    updatedAt: updated_at,
  }
}
