import {
  DongleStatisticBackendResponse,
  DongleStatisticResponse,
} from '../../../../types/dongle/statistic'

export const mapDongleStatisticBackendToDongleStatistic = (
  item: DongleStatisticBackendResponse,
): DongleStatisticResponse => {
  const { data } = item

  return {
    statisticList: data.map((d) => {
      return {
        dongleId: d.dongle_id,
        read: d.read,
        write: d.write,
        time: d.time,
      }
    }),
  }
}
