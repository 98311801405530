import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import deleteFailedImageSrc from '../../../assets/images/delete-failed.png'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import styles from './styles.module.scss'

const DeleteFailedModalMessage = defineMessages({
  title: {
    id: 'FailedContactDeleteModalMessage.title',
    defaultMessage: 'This item cannot be deleted',
  },
  content: {
    id: 'FailedContactDeleteModalMessage.content',
    defaultMessage: 'Please, try again later or contact our technical support.',
  },
  okay: {
    id: 'FailedContactDeleteModalMessage.button',
    defaultMessage: 'Okay',
  },
})

export const DeleteFailedModal = () => {
  const intl = useIntl()

  const { handleHideModal, props } = useModal()

  const { title, description, buttonText } = props

  return (
    <Drawer isOpen={true} close={handleHideModal}>
      <div className={styles.Container}>
        <img
          src={deleteFailedImageSrc}
          alt={'Delete failed'}
          className={styles.Image}
        />

        <div className={styles.Title}>
          {title ?? intl.formatMessage(DeleteFailedModalMessage.title)}
        </div>
        <div className={styles.Description}>
          {description ?? intl.formatMessage(DeleteFailedModalMessage.content)}
        </div>
        <Button
          text={buttonText ?? intl.formatMessage(DeleteFailedModalMessage.okay)}
          onClick={handleHideModal}
        />
      </div>
    </Drawer>
  )
}
