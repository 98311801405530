import { wrapApiRequest } from '../../helpers/_common/wrap-api-request'
import { mapUserGetBackendToUserGet } from '../../mappers/user/_crud/get/map-user-get-backend-to-user-get'
import { mapUserUpdateBackendToUserUpdate } from '../../mappers/user/_crud/update/map-user-update-backend-to-user-update'
import { mapUserApiKeyBackendToUserApiKey } from '../../mappers/user/api-key/map-user-api-key-backend-to-user-api-key'
import { mapUserUpdatePasswordBackendToUserUpdatePassword } from '../../mappers/user/update-password/map-user-update-password-backend-to-user-update-password'
import {
  UserGetBackendResponse,
  UserGetRequest,
  UserGetResponse,
} from '../../types/user/_crud/get'
import {
  UserUpdateBackendResponse,
  UserUpdateRequest,
  UserUpdateResponse,
} from '../../types/user/_crud/update'
import {
  UserApiKeyBackendResponse,
  UserApiKeyRequest,
  UserApiKeyResponse,
} from '../../types/user/api-key'
import {
  UserUpdatePasswordBackendResponse,
  UserUpdatePasswordRequest,
  UserUpdatePasswordResponse,
} from '../../types/user/update-password'

export const userGetDatagate = wrapApiRequest<
  UserGetRequest,
  UserGetBackendResponse,
  UserGetResponse
>('user', mapUserGetBackendToUserGet, 'GET')

export const userUpdateDatagate = wrapApiRequest<
  UserUpdateRequest,
  UserUpdateBackendResponse,
  UserUpdateResponse
>('user', mapUserUpdateBackendToUserUpdate, 'PUT')

export const userUpdatePasswordDatagate = wrapApiRequest<
  UserUpdatePasswordRequest,
  UserUpdatePasswordBackendResponse,
  UserUpdatePasswordResponse
>(
  'user/update-password',
  mapUserUpdatePasswordBackendToUserUpdatePassword,
  'PATCH',
)

export const userApiKeyDatagate = wrapApiRequest<
  UserApiKeyRequest,
  UserApiKeyBackendResponse,
  UserApiKeyResponse
>('user/api-key', mapUserApiKeyBackendToUserApiKey, 'GET')
