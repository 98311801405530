export const HomeSectionIds = {
  ALL_SECTIONS: 'home_all_sections',
  TOP: 'home_section_top',
  FEATURES: 'home_section_features',
  PRODUCT: 'home_section_product',
  PRICING: 'home_section_pricing',
  CONTACT: 'home_section_contact',
}

export const HomeSectionAnchors = {
  ALL_SECTIONS: '#home_all_sections',
  TOP: '#home_section_top',
  FEATURES: '#home_section_features',
  PRODUCT: '#home_section_product',
  PRICING: '#home_section_pricing',
  CONTACT: '#home_section_contact',
}
