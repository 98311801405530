import React, { FC, ReactNode } from 'react'
import { Loader } from '../../shared/ui-kit/data-display/loader/Loader'
import { DisplayError } from '../../shared/ui-kit/utils/form-error/FormError'

interface LoadingContentProps {
  loading: boolean
  errorText?: string | null
  content: ReactNode
}

export const LoadingContent: FC<LoadingContentProps> = (props) => {
  const { loading, errorText = null, content } = props

  if (loading) return <Loader />

  if (errorText) return <DisplayError errorText={errorText} />

  return <>{content}</>
}
