export enum TARIFF_PACKAGE_ID {
  MONTH = 1,
  ANNUAL = 2,
}

export const TARIFF_PACKAGE_IDS_EN_TO_RU = {
    [TARIFF_PACKAGE_ID.MONTH]: 3,
    [TARIFF_PACKAGE_ID.ANNUAL]: 4
}

export const ORDER_PAYMENT_TYPE_IDS = {
  BANK_CARD: 1,
  WIRE_TRANSFER: 2,
  BALANCE: 3,
}
