import React, { ComponentProps, FC } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import {
  InputPassword,
  InputPasswordProps,
} from '../../../../../ui-kit-2/inputs/input-password/InputPassword'
import { getFieldError } from '../../../form-helpers/get-field-error/getFieldError'

type InputPasswordFieldProps = InputPasswordProps &
  ComponentProps<typeof Field<string>>

export const InputPasswordField: FC<InputPasswordFieldProps> = (props) => {
  const { label, helperText, width, testId, ...otherProps } = props

  return (
    <Field {...otherProps}>
      {(fieldRenderProps) => {
        const { input, meta, ...otherFieldRenderProps } =
          fieldRenderProps as FieldRenderProps<string>

        return (
          <InputPassword
            label={label}
            error={getFieldError(meta)}
            helperText={helperText}
            width={width}
            testId={testId}
            {...input}
            {...otherFieldRenderProps}
          />
        )
      }}
    </Field>
  )
}
