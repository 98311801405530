
import {FC} from "react";
import { ReactComponent as HelperIcon } from './assets/helperIcon.svg';
import styles from './styles.module.scss';

interface IHelperFrame {
  text: string
}

export const HelperFrame: FC<IHelperFrame> = ({ text }) => (
  <div className={styles.HelperFrame}>
    <div className={styles.IconContainer}>
      <div className={styles.Icon}>
        <HelperIcon />
      </div>
    </div>
    <div className={styles.Text}>
      { text }
    </div>
  </div>
)