import React, {Dispatch, FC, SetStateAction, useEffect} from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useAddEmail } from '../../../_hooks/use-add-email/useAddEmail'
import { ReactComponent as CloseItemIcon } from '../../../../../assets/icons/close-mini.svg'
import { ReactComponent as EmailsIcon } from '../../../../../assets/icons/redirection-emails.svg'
import { DashedAddButton } from '../../../../custom-buttons/dashed-add-button/DashedAddButton'
import styles from './styles.module.scss'
import clsx from "clsx";

interface RedirectionSettingsEmailProps {
  emails: string[]
  setEmails: Dispatch<SetStateAction<string[]>>
  error?: boolean
  onChange?: () => void
}

const RedirectionSettingsEmailMessages = defineMessages({
  title: {
    id: 'RedirectionSettingsEmailMessages.title',
    defaultMessage: 'Email',
  },
})

export const RedirectionSettingsEmail: FC<RedirectionSettingsEmailProps> = (
  props,
) => {
  const { emails, setEmails, error } = props
  const { setIsOpen, component } = useAddEmail({
    processOnSave: (email) => setEmails((prev) => [...prev, email]),
    emailList: emails,
  })

  const intl = useIntl()

  const removeEmail = (email: string) => {
    setEmails((prev) => prev.filter((e) => e !== email))
  }

  useEffect(() => {
    props?.onChange?.();
  }, [emails]);

  return (
    <>
      <div className={clsx(styles.Container, error && styles.Container_error)}>
        <div className={styles.Header}>
          <div className={styles.Title}>
            <EmailsIcon />
            <div>
              {intl.formatMessage(RedirectionSettingsEmailMessages.title)}
            </div>
          </div>
        </div>

        <div className={styles.Content}>
          {emails.map((email) => (
            <div className={styles.Item} key={email}>
              <div>{email}</div>
              <CloseItemIcon
                onClick={() => removeEmail(email)}
                className={styles.Item__close}
              />
            </div>
          ))}
          {emails.length < 100 && (
            <DashedAddButton onClick={() => setIsOpen(true)} />
          )}
        </div>
      </div>
      {component}
    </>
  )
}
