import {
  ContactGroupCreateBackendResponse,
  ContactGroupCreateResponse,
} from '../../../../../types/contact-group/_crud/create'

export const mapContactGroupCreateBackendToContactGroupCreate = (
  item: ContactGroupCreateBackendResponse,
): ContactGroupCreateResponse => {
  const { contact_group_id, user_id, name } = item.data

  return {
    contactGroupId: contact_group_id,
    userId: user_id,
    name: name,
  }
}
