import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Card } from '../../../shared/ui-kit-2/data-display/card/Card'
import styles from '../privacy-policy/styles.module.scss'
import { TermsOfUseMessages } from './messages'

export const TermsOfUsePage = () => {
  const intl = useIntl()

  useEffect(() => {
    document.documentElement.lang = 'es'

    return () => {
      document.documentElement.lang = 'en'
    }
  }, [])

  return (
    <div className={styles.Page}>
      <Card additionalClassNames={[styles.Container]}>
        <div className={styles.Content}>
          <div className={styles.Text}>
            <div className={styles.Title}>
              {intl.formatMessage(TermsOfUseMessages.title)}
            </div>
            <h3>{intl.formatMessage(TermsOfUseMessages.introduction)}</h3>
            {intl.formatMessage(TermsOfUseMessages.introductionText)}
            <br />
            <h3>
              {intl.formatMessage(TermsOfUseMessages.bringoTelecomSlDetails)}
            </h3>
            {intl.formatMessage(TermsOfUseMessages.bringoTelecomSlDetailsText1)}
            <br />
            {intl.formatMessage(TermsOfUseMessages.bringoTelecomSlDetailsText2)}
            <br />
            {intl.formatMessage(TermsOfUseMessages.bringoTelecomSlDetailsText3)}
            <br />
            <h3>{intl.formatMessage(TermsOfUseMessages.generalConditions)}</h3>
            1. {intl.formatMessage(TermsOfUseMessages.generalConditionsTextP1)}
            <br />
            2. {intl.formatMessage(TermsOfUseMessages.generalConditionsTextP2)}
            <br />
            3. {intl.formatMessage(TermsOfUseMessages.generalConditionsTextP3)}
            <br />
            4. {intl.formatMessage(TermsOfUseMessages.generalConditionsTextP4)}
            <br />
            5. {intl.formatMessage(TermsOfUseMessages.generalConditionsTextP5)}
            <br />
            6. {intl.formatMessage(TermsOfUseMessages.generalConditionsTextP6)}
            <br />
            7. {intl.formatMessage(TermsOfUseMessages.generalConditionsTextP7)}
            <br />
            8. {intl.formatMessage(TermsOfUseMessages.generalConditionsTextP8)}
            <br />
            9. {intl.formatMessage(TermsOfUseMessages.generalConditionsTextP9)}
            <br />
            10.{' '}
            {intl.formatMessage(TermsOfUseMessages.generalConditionsTextP10)}
            <br />
            11.{' '}
            {intl.formatMessage(TermsOfUseMessages.generalConditionsTextP11)}
            <br />
            12.{' '}
            {intl.formatMessage(TermsOfUseMessages.generalConditionsTextP12)}
            <br />
            13.{' '}
            {intl.formatMessage(TermsOfUseMessages.generalConditionsTextP13)}
            <br />
            14.{' '}
            {intl.formatMessage(TermsOfUseMessages.generalConditionsTextP14)}
            <br />
            15.{' '}
            {intl.formatMessage(TermsOfUseMessages.generalConditionsTextP15)}
            <br />
            16.{' '}
            {intl.formatMessage(TermsOfUseMessages.generalConditionsTextP16)}
            <br />
            17.{' '}
            {intl.formatMessage(TermsOfUseMessages.generalConditionsTextP17)}
            <br />
            18.{' '}
            {intl.formatMessage(TermsOfUseMessages.generalConditionsTextP18)}
            <br />
            19.{' '}
            {intl.formatMessage(TermsOfUseMessages.generalConditionsTextP19)}
            <br />
            20.{' '}
            {intl.formatMessage(TermsOfUseMessages.generalConditionsTextP20)}
            <br />
            21.{' '}
            {intl.formatMessage(TermsOfUseMessages.generalConditionsTextP21)}
            <br />
            <h3>
              {intl.formatMessage(TermsOfUseMessages.intellectualProperty)}
            </h3>
            1.{' '}
            {intl.formatMessage(TermsOfUseMessages.intellectualPropertyTextP1)}
            <br />
            2.{' '}
            {intl.formatMessage(TermsOfUseMessages.intellectualPropertyTextP2)}
            <br />
            a.{' '}
            {intl.formatMessage(TermsOfUseMessages.intellectualPropertyTextP2A)}
            <br />
            b.{' '}
            {intl.formatMessage(TermsOfUseMessages.intellectualPropertyTextP2B)}
            <br />
            c.{' '}
            {intl.formatMessage(TermsOfUseMessages.intellectualPropertyTextP2C)}
            <br />
            3.{' '}
            {intl.formatMessage(TermsOfUseMessages.intellectualPropertyTextP3)}
            <br />
            4.{' '}
            {intl.formatMessage(TermsOfUseMessages.intellectualPropertyTextP4)}
            <br />
            5.{' '}
            {intl.formatMessage(TermsOfUseMessages.intellectualPropertyTextP5)}
            <br />
            <h3>{intl.formatMessage(TermsOfUseMessages.prohibitedUse)}</h3>
            1. {intl.formatMessage(TermsOfUseMessages.prohibitedUseTextP1)}
            <br />
            a. {intl.formatMessage(TermsOfUseMessages.prohibitedUseTextP1A)}
            <br />
            b. {intl.formatMessage(TermsOfUseMessages.prohibitedUseTextP1B)}
            <br />
            c. {intl.formatMessage(TermsOfUseMessages.prohibitedUseTextP1C)}
            <br />
            <h3>{intl.formatMessage(TermsOfUseMessages.registration)}</h3>
            1. {intl.formatMessage(TermsOfUseMessages.registrationTextP1)}
            <br />
            2. {intl.formatMessage(TermsOfUseMessages.registrationTextP2)}
            <br />
            3. {intl.formatMessage(TermsOfUseMessages.registrationTextP3)}
            <br />
            4. {intl.formatMessage(TermsOfUseMessages.registrationTextP4)}
            <br />
            <h3>
              {intl.formatMessage(TermsOfUseMessages.passwordAndSecurity)}
            </h3>
            1.{' '}
            {intl.formatMessage(TermsOfUseMessages.passwordAndSecurityTextP1)}
            <br />
            2.{' '}
            {intl.formatMessage(TermsOfUseMessages.passwordAndSecurityTextP2)}
            <br />
            <h3>
              {intl.formatMessage(TermsOfUseMessages.linkToOtherWebsites)}
            </h3>
            1.{' '}
            {intl.formatMessage(TermsOfUseMessages.linkToOtherWebsitesTextP1)}
            <br />
            2.{' '}
            {intl.formatMessage(TermsOfUseMessages.linkToOtherWebsitesTextP2)}
            <br />
            3.{' '}
            {intl.formatMessage(TermsOfUseMessages.linkToOtherWebsitesTextP3)}
            <br />
            <h3>
              {intl.formatMessage(TermsOfUseMessages.privacyPolicyAndCookieUse)}
            </h3>
            {intl.formatMessage(
              TermsOfUseMessages.privacyPolicyAndCookieUseText,
            )}
            <br />
            <h3>
              {intl.formatMessage(
                TermsOfUseMessages.websiteAvailabilityAndDisclaimers,
              )}
            </h3>
            1.{' '}
            {intl.formatMessage(
              TermsOfUseMessages.websiteAvailabilityAndDisclaimersTextP1,
            )}
            <br />
            2.{' '}
            {intl.formatMessage(
              TermsOfUseMessages.websiteAvailabilityAndDisclaimersTextP2,
            )}
            <br />
            3.{' '}
            {intl.formatMessage(
              TermsOfUseMessages.websiteAvailabilityAndDisclaimersTextP3,
            )}
            <br />
            4.{' '}
            {intl.formatMessage(
              TermsOfUseMessages.websiteAvailabilityAndDisclaimersTextP4,
            )}
            <br />
            <h3>
              {intl.formatMessage(TermsOfUseMessages.limitationOfLiability)}
            </h3>
            1.{' '}
            {intl.formatMessage(TermsOfUseMessages.limitationOfLiabilityTextP1)}
            <br />
            2.{' '}
            {intl.formatMessage(TermsOfUseMessages.limitationOfLiabilityTextP2)}
            <br />
            3.{' '}
            {intl.formatMessage(TermsOfUseMessages.limitationOfLiabilityTextP3)}
            <br />
            a.{' '}
            {intl.formatMessage(
              TermsOfUseMessages.limitationOfLiabilityTextP3A,
            )}
            <br />
            b.{' '}
            {intl.formatMessage(
              TermsOfUseMessages.limitationOfLiabilityTextP3B,
            )}
            <br />
            c.{' '}
            {intl.formatMessage(
              TermsOfUseMessages.limitationOfLiabilityTextP3C,
            )}
            <br />
            <h3>{intl.formatMessage(TermsOfUseMessages.general)}</h3>
            1. {intl.formatMessage(TermsOfUseMessages.generalTextP1)}
            <br />
            2. {intl.formatMessage(TermsOfUseMessages.generalTextP2)}
            <br />
            3. {intl.formatMessage(TermsOfUseMessages.generalTextP3)}
            <br />
            4. {intl.formatMessage(TermsOfUseMessages.generalTextP4)}
            <br />
            5. {intl.formatMessage(TermsOfUseMessages.generalTextP5)}
            <br />
            6. {intl.formatMessage(TermsOfUseMessages.generalTextP6)}
            <br />
            7. {intl.formatMessage(TermsOfUseMessages.generalTextP7)}
            <br />
            <h3>{intl.formatMessage(TermsOfUseMessages.attribution)}</h3>
            {intl.formatMessage(TermsOfUseMessages.attributionText)}
            <br />
          </div>
        </div>
      </Card>
    </div>
  )
}
