export enum UserStatuses {
  GUEST = 0,
  INACTIVE = 1,
  ACTIVE = 2,
  DECLINED = 3,
  DATA_PROCESSING = 4,
}

export enum UserAutoRenewal {
  FROM_BALANCE = 2,
  FROM_BANK_CARD = 1,
}

export interface UserInitialState {
  isAuthenticated: boolean
  isAuthChecked: boolean
  balance: number
  status: UserStatuses
  email?: string
  firstName?: string
  lastName?: string
  companyName?: string
  vatNumber?: string
  billingEmail?: string
  phone?: string
  paymentTypeId?: UserAutoRenewal,
  countryId?: number,
}
