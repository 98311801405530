import {
  GenerateEmailVerificationTokenBackendResponse,
  GenerateEmailVerificationTokenResponse,
} from '../../../../types/auth/generate-email-verification-token'

export const mapGenerateEmailVerificationTokenBackendToGenerateEmailVerificationToken =
  (
    item: GenerateEmailVerificationTokenBackendResponse,
  ): GenerateEmailVerificationTokenResponse => {
    const { status } = item.data

    return {
      status: status,
    }
  }
