import { wrapApiRequest } from '../../helpers/_common/wrap-api-request'
import { mapRedirectionCreateBackendToRedirectionCreate } from '../../mappers/redirection/_crud/create/map-redirection-create-backend-to-redirection-create'
import { mapRedirectionDeleteBackendToRedirectionDelete } from '../../mappers/redirection/_crud/delete/map-redirection-delete-backend-to-redirection-delete'
import { mapRedirectionGetBackendToRedirectionGet } from '../../mappers/redirection/_crud/get/map-redirection-get-backend-to-redirection-get'
import { mapRedirectionListBackendToRedirectionList } from '../../mappers/redirection/_crud/list/map-redirection-list-backend-to-redirection-list'
import { mapRedirectionUpdateBackendToRedirectionUpdate } from '../../mappers/redirection/_crud/update/map-redirection-update-backend-to-redirection-update'
import {
  RedirectionCreateBackendResponse,
  RedirectionCreateRequest,
  RedirectionCreateResponse,
} from '../../types/redirection/_crud/create'
import {
  RedirectionDeleteBackendResponse,
  RedirectionDeleteRequest,
  RedirectionDeleteResponse,
} from '../../types/redirection/_crud/delete'
import {
  RedirectionGetBackendResponse,
  RedirectionGetRequest,
  RedirectionGetResponse,
} from '../../types/redirection/_crud/get'
import {
  RedirectionListBackendResponse,
  RedirectionListRequest,
  RedirectionListResponse,
} from '../../types/redirection/_crud/list'
import {
  RedirectionUpdateBackendResponse,
  RedirectionUpdateRequest,
  RedirectionUpdateResponse,
} from '../../types/redirection/_crud/update'

export const redirectionListDatagate = wrapApiRequest<
  RedirectionListRequest,
  RedirectionListBackendResponse,
  RedirectionListResponse
>('redirection', mapRedirectionListBackendToRedirectionList, 'GET')

export const redirectionGetDatagate = wrapApiRequest<
  RedirectionGetRequest,
  RedirectionGetBackendResponse,
  RedirectionGetResponse
>(
  'redirection/{redirection_id}',
  mapRedirectionGetBackendToRedirectionGet,
  'GET',
)

export const redirectionCreateDatagate = wrapApiRequest<
  RedirectionCreateRequest,
  RedirectionCreateBackendResponse,
  RedirectionCreateResponse
>('redirection', mapRedirectionCreateBackendToRedirectionCreate, 'POST')

export const redirectionUpdateDatagate = wrapApiRequest<
  RedirectionUpdateRequest,
  RedirectionUpdateBackendResponse,
  RedirectionUpdateResponse
>(
  'redirection/{redirection_id}',
  mapRedirectionUpdateBackendToRedirectionUpdate,
  'PATCH',
)

export const redirectionDeleteDatagate = wrapApiRequest<
  RedirectionDeleteRequest,
  RedirectionDeleteBackendResponse,
  RedirectionDeleteResponse
>(
  'redirection/{redirection_id}',
  mapRedirectionDeleteBackendToRedirectionDelete,
  'DELETE',
)
