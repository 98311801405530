import React, {useMemo} from 'react';
import {usePaymentMethods} from "../../../../../../../../sdk/hooks/use-payment-methods/usePaymentMethods";
import styles from "../bank-cards/styles.module.scss";
import {Button} from "../../../../../../../../shared/ui-kit-2/inputs/button/Button";
import {TextAction} from "../../../text-action/TextAction";
import {IconButton} from "../../../../../../../../shared/ui-kit-2/inputs/icon-button/IconButton";
import {MODAL_TYPES} from "../../../../../../../../layouts/modals/ModalsProvider";
import {PAYMENT_PROVIDERS_IDS} from "../../../../../../../../sdk/datagates/types/payment/_common/constants";
import {useModal} from "../../../../../../../../app/hooks/useModal";
import {defineMessages, useIntl} from "react-intl";
import {LoadingContent} from "../../../../../../../../layouts/loading-content/LoadingContent";
import {ScreenDevices, useLessThanDeviceScreen} from "../../../../../../../../shared/lib/hooks/useLessThanDeviceScreen";

const BankCardsMessages = defineMessages({
  title: {
    id: 'BankCardsMessages.title',
    defaultMessage: 'Bank Cards',
  },
  newPaymentMethod: {
    id: 'BankCardsMessages.addCard',
    defaultMessage: 'Add Card',
  },
  subText: {
    id: 'BankCardsMessages.subText',
    defaultMessage:
      'By linking your card, you can set up automatic payments in the future, as well as pay for any purchases within your personal account with it.',
  },
  main: {
    id: 'BankCardsMessages.main',
    defaultMessage: 'Main',
  },
  secondary: {
    id: 'BankCardsMessages.secondary',
    defaultMessage: 'Secondary',
  },
  makeMain: {
    id: 'BankCardsMessages.makeMain',
    defaultMessage: 'Make main',
  },
  makeSecondary: {
    id: 'BankCardsMessages.makeSecondary',
    defaultMessage: 'Make secondary',
  },
  successMadeMain: {
    id: 'BankCardsMessages.successMadeMain',
    defaultMessage: 'Payment method is successfully made main',
  },
  successMadeSecondary: {
    id: 'BankCardsMessages.successMadeSecondary',
    defaultMessage: 'Payment method is successfully made secondary',
  },
  errorMadeMain: {
    id: 'BankCardsMessages.errorMadeMain',
    defaultMessage: 'Error when payment method made main',
  },
  errorMadeSecondary: {
    id: 'BankCardsMessages.errorMadeSecondary',
    defaultMessage: 'Error when payment method made secondary',
  },
})

type Method = {
  paymentMethodId: number
  name: string
  isDefault: boolean
}
type ReducedMethods = {
  isDefault: null | Method
  others: Array<Method>
}

export const BankCards = () => {
  const intl = useIntl()
  const {
    paymentMethods,
    handleUpdatePaymentMethod,
    handleDeletePaymentMethod,
    loading,
  } = usePaymentMethods();
  const { handleOpenModal, handleHideModal } = useModal()
  const isMobile = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK)

  const paymentMethodsSlice = paymentMethods.slice(0, 2);
  const handleAddCard = () => {
    if (paymentMethodsSlice.length < 2) {
      handleOpenModal({
        type: MODAL_TYPES.ADD_CARD,
        props: {
          paymentProviderId: PAYMENT_PROVIDERS_IDS.REDSYS,
        },
      })
    } else {
      handleOpenModal({
        type: MODAL_TYPES.ADD_CARD_WARNING,
      })
    }
  }


  const sortedPaymentMethods = useMemo(() => {
    const reducedMethods = paymentMethodsSlice.reduce<ReducedMethods>(
      (reducer, method) => {
        if (method.isDefault) {
          return {
            ...reducer,
            isDefault: method,
          }
        }

        return {
          ...reducer,
          others: [...reducer.others, method],
        }
      },
      {
        isDefault: null,
        others: [],
      },
    )

    const result = []

    if (reducedMethods.isDefault) result.push(reducedMethods.isDefault)

    return [...result, ...reducedMethods.others] as Array<Method>
  }, [paymentMethodsSlice])

  const BankCardsList = () => (
    <div className={styles.BankCards}>
      <div className={styles.Row}>
        <div className={styles.Title}>
          {intl.formatMessage(BankCardsMessages.title)}
        </div>
        <div className={styles.Action}>
          <Button
            text={intl.formatMessage(BankCardsMessages.newPaymentMethod)}
            icon="add"
            variant="greenTextOutlined"
            onClick={handleAddCard}
          />
        </div>
      </div>
      <div className={styles.BankCardsList}>
        {sortedPaymentMethods.map(
          ({ paymentMethodId, name, isDefault }) => (

            <div className={styles.PaymentMethod} key={paymentMethodId}>
              <div className={styles.PaymentMethodName}>{name}</div>
              <div className={styles.PaymentMethodActions}>
                {isDefault ? (
                  <>
                    <TextAction
                      text={intl.formatMessage(BankCardsMessages.main)}
                      disabled
                    />
                    <IconButton
                      icon={'trash'}
                      additionalClassNames={[styles.PaymentMethodTrash]}
                      onClick={() =>
                        handleOpenModal({
                          type: MODAL_TYPES.DELETE_ITEM,
                          props: {
                            deletingItemName: 'card',
                            handleSubmit: async () => {
                              await handleDeletePaymentMethod(paymentMethodId)
                              handleHideModal()
                            },
                          },
                        })
                      }
                    />
                  </>
                ) : (
                  <>
                    <TextAction
                      text={intl.formatMessage(BankCardsMessages.makeMain)}
                      onClick={() =>
                        handleUpdatePaymentMethod(paymentMethodId, 'main', {
                          successText: intl.formatMessage(
                            BankCardsMessages.successMadeMain,
                          ),
                          errorText: intl.formatMessage(
                            BankCardsMessages.errorMadeMain,
                          ),
                        })
                      }
                    />

                    <IconButton
                      icon={'trash'}
                      additionalClassNames={[styles.PaymentMethodTrash]}
                      onClick={() =>
                        handleOpenModal({
                          type: MODAL_TYPES.DELETE_ITEM,
                          props: {
                            deletingItemName: 'card',
                            handleSubmit: async () => {
                              await handleDeletePaymentMethod(paymentMethodId)
                              handleHideModal()
                            },
                          },
                        })
                      }
                    />
                  </>
                )}
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  );

  const EmptyBankCards = () => (
    <div className={styles.Empty}>
      <div className={styles.Row}>
        <div className={styles.Title}>
          {intl.formatMessage(BankCardsMessages.title)}
        </div>
        <div className={styles.Action}>
          <Button
            variant={isMobile ? 'greenTextOutlined' : 'green'}
            text={intl.formatMessage(BankCardsMessages.newPaymentMethod)}
            icon="add"
            onClick={handleAddCard}
          />
        </div>
      </div>
      <div className={styles.SubText}>
        {intl.formatMessage(BankCardsMessages.subText)}
      </div>
    </div>
  )


  return (
    <div className={styles.Container}>
      <LoadingContent
        loading={loading}
        content={
          paymentMethods.length > 0 ? <BankCardsList /> : <EmptyBankCards />
        }
      />
    </div>
  );
};