import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../../../app/hooks/useModal'
import { MODAL_TYPES } from '../../../../../../../../layouts/modals/ModalsProvider'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { Button } from '../../../../../../../../shared/ui-kit-2/inputs/button/Button'
import { useAppSelector } from '../../../../../../../../store'
import styles from './styles.module.scss'

const BalanceMessages = defineMessages({
  title: {
    id: 'BalanceMessages.title',
    defaultMessage: 'Balance',
  },
  recharge: {
    id: 'BalanceMessages.recharge',
    defaultMessage: 'Recharge balance',
  },
  rechargeShort: {
    id: 'BalanceMessages.rechargeShort',
    defaultMessage: 'Recharge',
  },
})

export const Balance = () => {
  const intl = useIntl()
  const { balance } = useAppSelector((state) => state.user)
  const { handleOpenModal } = useModal()
  const isMobile = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK)

  const handleRecharge = () =>
    handleOpenModal({
      type: MODAL_TYPES.RECHARGE_BALANCE,
    })

  return (
    <div className={styles.Balance}>
      <div className={styles.Money}>
        <div className={styles.Label}>
          {intl.formatMessage(BalanceMessages.title)}:
        </div>
        <div className={styles.Counter}>€{balance}</div>
      </div>
      <div className={styles.Action}>
        <Button
          text={intl.formatMessage(
            isMobile ? BalanceMessages.rechargeShort : BalanceMessages.recharge,
          )}
          onClick={handleRecharge}
        />
      </div>
    </div>
  )
}
