import { defineMessages, useIntl } from 'react-intl'
import { Sticker } from '../../../shared/ui-kit-2/data-display/sticker/Sticker'
import { CALL_STATUSES } from './constants/CallStatuses'

const UseCallStatusesMessages = defineMessages({
  created: {
    id: 'UseCallStatusesMessages.created',
    defaultMessage: 'Created',
  },
  enqueued: {
    id: 'UseCallStatusesMessages.enqueued',
    defaultMessage: 'Enqueued',
  },
  sent: {
    id: 'UseCallStatusesMessages.sent',
    defaultMessage: 'Delivered',
  },
  notSent: {
    id: 'UseCallStatusesMessages.notSent',
    defaultMessage: 'Failed',
  },
  received: {
    id: 'UseCallStatusesMessages.received',
    defaultMessage: 'Successful',
  },
  cancelled: {
    id: 'UseCallStatusesMessages.cancelled',
    defaultMessage: 'Cancelled',
  },
  unknown: {
    id: 'UseCallStatusesMessages.unknown',
    defaultMessage: 'Unknown',
  },
})

export const useCallStatuses = () => {
  const intl = useIntl()

  const getCallStatusStickerComponent = (callStatusId: number) => {
    switch (callStatusId) {
      case CALL_STATUSES.CREATED_STATUS:
        return (
          <Sticker
            text={intl.formatMessage(UseCallStatusesMessages.created)}
            color={'blue'}
          />
        )
      case CALL_STATUSES.ENQUEUED_STATUS:
        return (
          <Sticker
            text={intl.formatMessage(UseCallStatusesMessages.enqueued)}
            color={'blue'}
          />
        )
      case CALL_STATUSES.SENDED_STATUS:
        return (
          <Sticker
            text={intl.formatMessage(UseCallStatusesMessages.sent)}
            color={'lightGreen'}
          />
        )
      case CALL_STATUSES.RECEIVED_STATUS:
        return (
          <Sticker
            text={intl.formatMessage(UseCallStatusesMessages.received)}
            color={'lightGreen'}
          />
        )
      case CALL_STATUSES.NOT_RECEIVED_STATUS:
        return (
          <Sticker
            text={intl.formatMessage(UseCallStatusesMessages.notSent)}
            color={'red'}
          />
        )
      case CALL_STATUSES.CANCELED_STATUS:
        return (
          <Sticker
            text={intl.formatMessage(UseCallStatusesMessages.cancelled)}
            color={'red'}
          />
        )
      default:
        return (
          <Sticker
            text={intl.formatMessage(UseCallStatusesMessages.unknown)}
            color={'red'}
          />
        )
    }
  }

  return { getCallStatusStickerComponent }
}
