import {
  DongleRebootBackendResponse,
  DongleRebootResponse,
} from '../../../../types/dongle/reboot'

export const mapDongleRebootBackendToDongleReboot = (
  item: DongleRebootBackendResponse,
): DongleRebootResponse => {
  const { result } = item.data

  return {
    result: result,
  }
}
