import {
  DongleSmsListBackendResponse,
  DongleSmsListResponse,
} from '../../../../../../types/dongle/sms/_crud/list'

export const mapDongleSmsListBackendToDongleSmsList = (
  item: DongleSmsListBackendResponse,
): DongleSmsListResponse => {
  const { smses, total_count } = item.data

  return {
    smses: smses.map((el) => {
      return {
        dongleId: el.dongle_id,
        dongleName: el.dongle_name,
        smsId: el.sms_id,
        smsTypeId: el.sms_type_id,
        numberCaller: el.number_caller,
        numberReceiver: el.number_receiver,
        messageDecoded: el.message_decoded,
        apiResponseData: el.api_response_data,
        smsCenter: el.sms_center,
        smsStatusId: el.sms_status_id,
        createdAt: el.created_at,
        updatedAt: el.updated_at,
        scheduled_at: el.scheduled_at,
      }
    }),
    totalCount: total_count,
  }
}
