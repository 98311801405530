import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import deleteFailedImageSrc from '../../../assets/images/delete-failed.png'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import styles from './styles.module.scss'
import {Button} from "../../../shared/ui-kit-2/inputs/button/Button";

const ChangeProfileTypeModalMessage = defineMessages({
  description: {
    id: 'AddCardModalMessages.description',
    defaultMessage: 'Are you sure you want to change your profile type?',
  },
  cancel: {
    id: 'AddCardModalMessages.cancel',
    defaultMessage: 'Cancel',
  },
  change: {
    id: 'AddCardModalMessages.change',
    defaultMessage: 'Change',
  }
})

export const ChangeProfileTypeModal = () => {
  const { handleHideModal, props: { onConfirm } } = useModal()
  const intl = useIntl()

  const handleChangeProfileType = () => {
    if (onConfirm) {
      onConfirm()
    }
    handleHideModal()
  }

  return (
    <Drawer
      isOpen={true}
      close={handleHideModal}
      additionalClassNames={[styles.DrawerCustom]}
    >
      <div className={styles.Wrapper}>
        <div className={styles.ImageAndDescription}>
          <img
            src={deleteFailedImageSrc}
            alt={'Delete failed'}
            className={styles.Image}
          />
          <div className={styles.Description}>
            {intl.formatMessage(ChangeProfileTypeModalMessage.description)}
          </div>
        </div>
        <div className={styles.Actions}>
          <Button
            variant={'white'}
            text={intl.formatMessage(ChangeProfileTypeModalMessage.cancel)}
            onClick={() => handleHideModal()}
          />
          <Button
            text={intl.formatMessage(ChangeProfileTypeModalMessage.change)}
            onClick={handleChangeProfileType}
          />
        </div>
      </div>
    </Drawer>
  )
}
