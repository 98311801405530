import React, { Dispatch, FC, SetStateAction } from 'react'
import {
  DatepickerField,
  DatepickerFieldProps,
} from '../../../form-field-adapters/v2/datepicker-field/DatepickerField'
import { ReactComponent as CalendarIcon } from './assets/icons/calendar.svg'
import styles from './styles.module.scss'

interface DatepickerOptionalFieldProps extends DatepickerFieldProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  title?: string
  onRemove?: () => void
}

export const DatepickerOptionalField: FC<DatepickerOptionalFieldProps> = (
  props,
) => {
  const {
    title = 'Add date',
    isOpen,
    setIsOpen,
    onRemove,
    ...otherProps
  } = props

  const handleChangeIsOpen = () => {
    if (isOpen && onRemove) {
      onRemove()
    }

    setIsOpen((prev) => !prev)
  }

  return (
    <div className={styles.Container}>
      <div className={styles.Header} onClick={handleChangeIsOpen}>
        <div className={styles.HeaderTitle}>
          <CalendarIcon />
          <div>{title}</div>
        </div>

        <div className={styles.AddOrRemove}>{isOpen ? '—' : '+'}</div>
      </div>
      {isOpen && <DatepickerField {...otherProps} />}
    </div>
  )
}
