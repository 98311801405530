import React, { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useCancelMailingSubmodal } from '../../../../../_hooks/use-bool-action-submodal/templates/useCancelMailingSubmodal'
import { CommonButtonMessages } from '../../../../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { Button } from '../../../../../../../shared/ui-kit-2/inputs/button/Button'
import styles from './styles.module.scss'

interface SmsMailingPlanedContentProps {
  onCancelMailing: () => Promise<void>
  forceAction: 'delete' | 'cancel' | null
}

export const SmsMailingPlanedContent: FC<SmsMailingPlanedContentProps> = (
  props,
) => {
  const { onCancelMailing, forceAction } = props

  const intl = useIntl()
  const { setIsCancelSubmodalOpen, cancelSubmodalComponent } =
    useCancelMailingSubmodal({ onCancelMailing })

  useEffect(() => {
    if (forceAction) setIsCancelSubmodalOpen(true)
  }, [])

  return (
    <>
      <div className={styles.Container}>
        <Button
          text={intl.formatMessage(CommonButtonMessages.cancel)}
          variant={'danger'}
          icon={'cancel'}
          onClick={() => setIsCancelSubmodalOpen(true)}
          additionalClassNames={[styles.Button]}
        />
      </div>
      {cancelSubmodalComponent}
    </>
  )
}
