import React from 'react'
import { SmsAutoReplyList } from './components/sms-auto-reply-list/SmsAutoReplyList'
import { SmsRedirectionList } from './components/sms-redirection-list/SmsRedirectionList'
import styles from './styles.module.scss'

export const SmsRulesPage = () => {
  return (
    <div className={styles.Container}>
      <SmsRedirectionList />
      <SmsAutoReplyList />
    </div>
  )
}
