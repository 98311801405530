import React, { FC } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

interface CardProps extends React.HTMLProps<HTMLDivElement> {
  additionalClassNames?: string[]
  currentRef?: React.RefObject<HTMLDivElement>
}

export const Card: FC<CardProps> = (props) => {
  const {
    children,
    additionalClassNames = [],
    currentRef,
    ...otherProps
  } = props

  return (
    <div
      className={clsx(styles.Card, ...additionalClassNames)}
      {...otherProps}
      ref={currentRef}
    >
      {children}
    </div>
  )
}
