import {
  DongleSmsDeleteBackendResponse,
  DongleSmsDeleteResponse,
} from '../../../../../../types/dongle/sms/_crud/delete'

export const mapDongleSmsDeleteBackendToDongleSmsDelete = (
  item: DongleSmsDeleteBackendResponse,
): DongleSmsDeleteResponse => {
  const { data } = item

  return {
    data: data,
  }
}
