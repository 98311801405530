import React, { FC } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
  Sticker,
  StickerColorType,
} from '../../../../../../shared/ui-kit-2/data-display/sticker/Sticker'
import { SMS_STATUSES } from './constants/SmsStatuses'

const UseSmsStatusesMessages = defineMessages({
  sent: {
    id: 'UseSmsStatusesMessages.sent',
    defaultMessage: 'Sent',
  },
  delivered: {
    id: 'UseSmsStatusesMessages.delivered',
    defaultMessage: 'Delivered',
  },
  notDelivered: {
    id: 'UseSmsStatusesMessages.notDelivered',
    defaultMessage: 'Not delivered',
  },
  created: {
    id: 'UseSmsStatusesMessages.created',
    defaultMessage: 'Created',
  },
  waitingToBeSent: {
    id: 'UseSmsStatusesMessages.waitingToBeSent',
    defaultMessage: 'Planned',
  },
  failedDelivery: {
    id: 'UseSmsStatusesMessages.failedDelivery',
    defaultMessage: 'Delivery failed',
  },
  unknown: {
    id: 'UseSmsStatusesMessages.unknown',
    defaultMessage: 'Unknown',
  },
})

const idsMap: IdsMapType | undefined = {
  [SMS_STATUSES.SENT]: {
    text: UseSmsStatusesMessages.created,
    color: 'blue',
  },
  [SMS_STATUSES.DELIVERED]: {
    text: UseSmsStatusesMessages.delivered,
    color: 'lightGreen',
  },
  [SMS_STATUSES.NOT_DELIVERED]: {
    text: UseSmsStatusesMessages.notDelivered,
    color: 'red',
  },
  [SMS_STATUSES.CREATED]: {
    text: UseSmsStatusesMessages.created,
    color: 'blue',
  },
  [SMS_STATUSES.ENQUEUED]: {
    text: UseSmsStatusesMessages.waitingToBeSent,
    color: 'yellow',
  },
  [SMS_STATUSES.DELIVERY_FAIL]: {
    text: UseSmsStatusesMessages.notDelivered,
    color: 'red',
  },
  [SMS_STATUSES.DEFAULT]: {
    text: UseSmsStatusesMessages.unknown,
    color: 'red',
  },
}

interface IdsMapType {
  [p: number]: {
    text: { id: string; defaultMessage: string }
    color: StickerColorType
  }
}

export const SmsStatus: FC<{ smsStatusId: number; testId?: string }> = ({
  smsStatusId,
  testId,
}) => {
  const intl = useIntl()

  const { color, text } = idsMap.hasOwnProperty(smsStatusId)
    ? idsMap[smsStatusId]
    : idsMap[SMS_STATUSES.DEFAULT]

  return (
    <Sticker text={intl.formatMessage(text)} color={color} testId={testId} />
  )
}
