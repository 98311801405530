import React, { ComponentProps, FC } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import {
  Checkbox,
  CheckboxProps,
} from '../../../../../ui-kit-2/inputs/checkbox/Checkbox'
import { getFieldError } from '../../../form-helpers/get-field-error/getFieldError'

type CheckboxFieldProps = CheckboxProps & ComponentProps<typeof Field<string>>

export const CheckboxField: FC<CheckboxFieldProps> = (props) => {
  const { text, ...otherProps } = props

  return (
    <Field type={'checkbox'} {...otherProps}>
      {(fieldRenderProps) => {
        const { input, meta, ...otherFieldRenderProps } =
          fieldRenderProps as FieldRenderProps<string>

        return (
          <Checkbox
            text={text}
            error={getFieldError(meta)}
            {...input}
            {...otherFieldRenderProps}
          />
        )
      }}
    </Field>
  )
}
