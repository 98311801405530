import React, { FC } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../../../../../app/hooks/useModal'
import { ReactComponent as ForwardToIcon } from '../../../../../../../../../../assets/icons/forward-to.svg'
import { MODAL_TYPES } from '../../../../../../../../../../layouts/modals/ModalsProvider'
import { RedirectionListResponse } from '../../../../../../../../../../sdk/datagates/types/redirection/_crud/list'
import { Counter } from '../../../../../../../../../../shared/ui-kit-2/data-display/counter/Counter'
import { TextButton } from '../../../../../../../../../../shared/ui-kit-2/inputs/text-button/TextButton'
import styles from './styles.module.scss'
import {useUserProfile} from "../../../../../../../purchase-process/hooks/useUserProfile";

interface SmsRedirectionCardProps {
  redirection: RedirectionListResponse['redirections'][0]
  handleFetch: () => Promise<void>
  handleDeleteRedirection: (redirectionId: number) => Promise<void>
}

const SmsRedirectionCardMessages = defineMessages({
  devices: {
    id: 'SmsRedirectionCardMessages.devices',
    defaultMessage: 'Devices',
  },
  all: {
    id: 'SmsRedirectionCardMessages.all',
    defaultMessage: 'All',
  },
  any: {
    id: 'SmsRedirectionCardMessages.any',
    defaultMessage: 'Any',
  },
  notSpecified: {
    id: 'SmsRedirectionCardMessages.notSpecified',
    defaultMessage: 'Not specified',
  },
  details: {
    id: 'SmsRedirectionCardMessages.details',
    defaultMessage: 'Details',
  },
  numbers: {
    id: 'SmsRedirectionCardMessages.numbers',
    defaultMessage: 'Numbers',
  },
  contacts: {
    id: 'SmsRedirectionCardMessages.contacts',
    defaultMessage: 'Contacts',
  },
  groups: {
    id: 'SmsRedirectionCardMessages.groups',
    defaultMessage: 'Groups',
  },
  emails: {
    id: 'SmsRedirectionCardMessages.emails',
    defaultMessage: 'Emails',
  },
  telegramChats: {
    id: 'SmsRedirectionCardMessages.telegramChats',
    defaultMessage: 'Telegram chats',
  },
})

export const SmsRedirectionCard: FC<SmsRedirectionCardProps> = (props) => {
  const { redirection, handleFetch, handleDeleteRedirection } = props

  const intl = useIntl()
  const { handleOpenModal } = useModal()
  const {
    userProfileData,
  } = useUserProfile();

  const openEditRedirectionModal = () =>
    handleOpenModal({
      type: MODAL_TYPES.REDIRECTION_SETTINGS,
      props: {
        redirection: redirection,
        redirectionTypeId: redirection.redirectionTypeId,
        handleFetch: handleFetch,
        handleDeleteRedirection: handleDeleteRedirection,
        phoneSourceVariant: 'all',
      },
    })

  const fromText =
    redirection.fromPhones.length === 0 &&
    redirection.fromContactIds.length === 0 &&
    redirection.fromContactGroupIds.length === 0 &&
    intl.formatMessage(SmsRedirectionCardMessages.any)

  const toText =
    redirection.toEmails.length === 0 &&
    redirection.toTelegramChatIds.length === 0 &&
    userProfileData?.email;

  return (
    <div className={styles.Card} key={redirection.redirectionId}>
      <div className={styles.Header}>
        <div className={styles.Title}>{redirection.name}</div>

        <TextButton
          text={intl.formatMessage(SmsRedirectionCardMessages.details)}
          icon={'edit'}
          onClick={openEditRedirectionModal}
          additionalClassNames={[styles.EditTextBtn]}
        />
      </div>

      <div className={styles.InnerCardsContainer}>
        <div className={styles.InnerHeader}>
          <div>{intl.formatMessage(SmsRedirectionCardMessages.devices)}</div>
          {redirection.deviceIds.length > 0 ? (
            <Counter
              count={redirection.deviceIds.length}
              size={'small'}
              variant={'filled'}
            />
          ) : (
            intl.formatMessage(SmsRedirectionCardMessages.all)
          )}
        </div>
        <div className={styles.InnerCard}>
          {fromText && (
            <div className={styles.InnerBody}>
              <div>
                {intl.formatMessage(SmsRedirectionCardMessages.numbers)}
              </div>
              <div>{fromText}</div>
            </div>
          )}

          {redirection.fromPhones.length > 0 && (
            <div className={styles.InnerBody}>
              <div>
                {intl.formatMessage(SmsRedirectionCardMessages.numbers)}
              </div>
              <Counter
                variant={'filled'}
                count={redirection.fromPhones.length}
                size={'small'}
              />
            </div>
          )}

          {redirection.fromContactIds.length > 0 && (
            <div className={styles.InnerBody}>
              <div>
                {intl.formatMessage(SmsRedirectionCardMessages.contacts)}
              </div>
              <Counter
                variant={'filled'}
                count={redirection.fromContactIds.length}
                size={'small'}
              />
            </div>
          )}

          {redirection.fromContactGroupIds.length > 0 && (
            <div className={styles.InnerBody}>
              <div>{intl.formatMessage(SmsRedirectionCardMessages.groups)}</div>
              <Counter
                variant={'filled'}
                count={redirection.fromContactGroupIds.length}
                size={'small'}
              />
            </div>
          )}

          <ForwardToIcon className={styles.ForwardTo} />
        </div>

        <div className={styles.InnerCard}>
          {toText && (
            <div className={styles.InnerHeader}>
              <div>-</div>
              <div>{toText}</div>
            </div>
          )}

          {redirection.toEmails.length > 0 && (
            <div className={styles.InnerBody}>
              <div>{intl.formatMessage(SmsRedirectionCardMessages.emails)}</div>
              <Counter
                variant={'filled'}
                count={redirection.toEmails.length}
                size={'small'}
              />
            </div>
          )}

          {redirection.toTelegramChatIds.length > 0 && (
            <div className={styles.InnerBody}>
              <div>
                {intl.formatMessage(SmsRedirectionCardMessages.telegramChats)}
              </div>
              <Counter
                variant={'filled'}
                count={redirection.toTelegramChatIds.length}
                size={'small'}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
