import { defineMessages, useIntl } from 'react-intl'

export const TRANSACTION_TYPE_IDS = {
  CREDIT: 1,
  AUTHORIZE: 2,
  ORDER: 3,
  REFUND: 4,
}

const UseTransactionTypesMessages = defineMessages({
  credit: {
    id: 'UseTransactionTypesMessages.credit',
    defaultMessage: 'Credit',
  },
  authorize: {
    id: 'UseTransactionTypesMessages.authorize',
    defaultMessage: 'Authorize',
  },
  order: {
    id: 'UseTransactionTypesMessages.order',
    defaultMessage: 'Order',
  },
  refund: {
    id: 'UseTransactionTypesMessages.refund',
    defaultMessage: 'Refund',
  },
  unknown: {
    id: 'UseTransactionTypesMessages.unknown',
    defaultMessage: 'Unknown',
  },
})

export const useTransactionTypes = () => {
  const intl = useIntl()

  const getTransactionTypeTranslationByTransactionTypeId = (id: number) => {
    switch (id) {
      case TRANSACTION_TYPE_IDS.CREDIT:
        return intl.formatMessage(UseTransactionTypesMessages.credit)
      case TRANSACTION_TYPE_IDS.AUTHORIZE:
        return intl.formatMessage(UseTransactionTypesMessages.authorize)
      case TRANSACTION_TYPE_IDS.ORDER:
        return intl.formatMessage(UseTransactionTypesMessages.order)
      case TRANSACTION_TYPE_IDS.REFUND:
        return intl.formatMessage(UseTransactionTypesMessages.refund)
      default:
        return intl.formatMessage(UseTransactionTypesMessages.unknown)
    }
  }

  return {
    getTransactionTypeTranslationByTransactionTypeId,
  }
}
