import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { CommonTableMessages } from '../../../../../config/intl/common-messages/common-table-messages/CommonTableMessages'

export const useTableFooterInfo = (
  page: number,
  pageLimit: number,
  totalCount: number,
) => {
  const intl = useIntl()

  const minNumber = useMemo(() => (page - 1) * pageLimit + 1, [page])
  const maxNumber = useMemo(() => page * pageLimit, [page])
  const showingFrom = useMemo(
    () => (minNumber < totalCount ? minNumber : totalCount),
    [minNumber, totalCount],
  )
  const showingTo = useMemo(
    () => (maxNumber < totalCount ? maxNumber : totalCount),
    [maxNumber, totalCount],
  )
  const footerInfo = useMemo(
    () =>
      intl.formatMessage(CommonTableMessages.footerInfo, {
        from: showingFrom,
        to: showingTo,
        total: totalCount,
      }),
    [showingFrom, showingTo],
  )

  return {
    footerInfo,
  }
}
