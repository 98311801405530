import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { ReactComponent as MinusIcon } from '../../../../../assets/icons/minus.svg'
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/plus.svg'
import { Card } from '../../../../../shared/ui-kit-2/data-display/card/Card'
import { Sticker } from '../../../../../shared/ui-kit-2/data-display/sticker/Sticker'
import styles from './styles.module.scss'

export type ProductCardDetailType = {
  title: string
  value?: string
  valueSmaller?: string
  valueGreen?: string
  valueGreenCrossedOut?: string
}

interface DeviceProductCounterProps {
  title: string
  subtitle?: string
  details: ProductCardDetailType[]
  count: number
  setCount: Dispatch<SetStateAction<number>>
  amount: string
}

const min = 0
const max = 999
const isNotNegativeInteger = /^\d+$/

const ProductCounterCardMessages = defineMessages({
  amountTitle: {
    id: 'ProductCounterCardMessages.amountTitle',
    defaultMessage: 'Amount without delivery',
  },
  modems: {
    id: 'ProductCounterCardMessages.modems',
    defaultMessage: "modems"
  },
  modem: {
    id: 'ProductCounterCardMessages.modem',
    defaultMessage: "modem"
  },
})

export const ProductCounterCard: FC<DeviceProductCounterProps> = (props) => {
  const { title, subtitle, details, count, setCount, amount } = props

  const intl = useIntl()
  const [inputValue, setInputValue] = useState<string>(count.toString())
  const isMinusDisabled = useMemo(() => count === min, [count])
  const isPlusDisabled = useMemo(() => count === max, [count])

  const onCountChange = (
    value: string | number,
    operation?: 'plus' | 'minus',
  ) => {
    value = value.toString()

    if (operation) {
      let num = value ? Number.parseInt(value) : 0

      switch (operation) {
        case 'minus':
          num--
          break
        case 'plus':
          num++
          break
      }

      if (num < min) {
        setCount(min)
        setInputValue(min.toString())
      } else if (num > max) {
        setCount(max)
        setInputValue(max.toString())
      } else {
        setCount(num)
        setInputValue(num.toString())
      }

      return
    }

    if (!(value === '' || value.match(isNotNegativeInteger))) {
      return
    } else {
      setInputValue(value)

      if (value === '') {
        setCount(0)
      } else {
        let num = Number.parseInt(value)

        if (num < min) {
          setCount(min)
          setInputValue(min.toString())
        } else if (num > max) {
          setCount(max)
          setInputValue(max.toString())
        } else {
          setCount(num)
          setInputValue(num.toString())
        }
      }
    }
  }

  const onInputFocus = () => setInputValue('')

  const onInputBlur = () => setInputValue(count.toString())

  return (
    <Card>
      <div className={styles.Content}>
        <div className={styles.Content__titleAndDetails}>
          <div className={styles.TitleContainer}>
            <div className={styles.Title}>{title}</div>
            {subtitle && <Sticker text={subtitle} color={'lightGreen'} />}
          </div>

          <div className={styles.DetailList}>
            {details.map((d) => (
              <div className={styles.Detail} key={d.title}>
                <div className={styles.Detail__title}>{d.title}</div>
                <div className={styles.Detail__value}>
                  {d.value && (
                    <div className={styles.Detail__value_default}>
                      {d.value}
                    </div>
                  )}
                  {d.valueSmaller && (
                    <div className={styles.Detail__value_smaller}>
                      {d.valueSmaller}
                    </div>
                  )}
                  {d.valueGreen && (
                    <div className={styles.Detail__value_green}>
                      {d.valueGreen}
                    </div>
                  )}
                  {d.valueGreenCrossedOut && (
                    <div className={styles.Detail__value_greenCrossedOut}>
                      {d.valueGreenCrossedOut}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.Content__countAndAmount}>
          <div className={styles.Counter}>
            <span
              className={clsx(
                styles.Counter__operation,
                isMinusDisabled && styles.Counter__operation_disabled,
              )}
              onClick={() => onCountChange(count, 'minus')}
            >
              <MinusIcon />
            </span>

            <span>
              <input
                value={inputValue}
                onChange={(e) => onCountChange(e.target.value)}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
                className={clsx(
                  styles.Counter__Input,
                  +inputValue > 10 && +inputValue < 99 && styles.Counter__Input_long,
                  +inputValue > 99 && styles.Counter__Input_2long
                )}
              />
              <span className={styles.Counter__Label}>
                {intl.formatMessage(ProductCounterCardMessages[inputValue === '1' ? 'modem' : 'modems'])}
              </span>
            </span>

            <span
              className={clsx(
                styles.Counter__operation,
                isPlusDisabled && styles.Counter__operation_disabled,
              )}
              onClick={() => onCountChange(count, 'plus')}
            >
              <PlusIcon />
            </span>
          </div>

          <div className={styles.Amount}>
            <div className={styles.Amount__title}>
              {intl.formatMessage(ProductCounterCardMessages.amountTitle)}
            </div>
            <div className={styles.Amount__value}>{amount}</div>
          </div>
        </div>
      </div>
    </Card>
  )
}
