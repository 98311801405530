import React from 'react';
import styles from './styles.module.scss'
import {REDIRECTION_TYPES} from "../../../../../sdk/hooks/use-redirection-types/constants/RedirectionTypes";
import {Button} from "../../../../../shared/ui-kit-2/inputs/button/Button";
import {
  CommonButtonMessages
} from "../../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages";
import {ReactComponent as RedirectionTypeSmsIcon} from "../../../../../assets/icons/device-redireciton-sms.svg";
import {ReactComponent as RedirectionTypeCallIcon} from "../../../../../assets/icons/device-redirection-call.svg";
import {defineMessages, useIntl} from "react-intl";
import {useRedirections} from "../../../../../sdk/hooks/use-redirections/useRedirections";
import {RedirectionListResponse} from "../../../../../sdk/datagates/types/redirection/_crud/list";
import {MODAL_TYPES} from "../../../ModalsProvider";
import {useModal} from "../../../../../app/hooks/useModal";
import {useRedirectionTypes} from "../../../../../sdk/hooks/use-redirection-types/useRedirectionTypes";

const RedirectionsDetailsModalMessage = defineMessages({
  emptyRedirectsFirst: {
    id: 'RedirectionsDetailsModalMessage.emptyRedirectsFirst',
    defaultMessage:
      'Redirect rules can be created in the SMS or Calls section.',
  },
  emptyRedirectsSecond: {
    id: 'DeviceDetailsModalMessages.emptyRedirectsSecond',
    defaultMessage:
      'You will be able to receive notifications about SMS and Calls via Email or Telegram.',
  },
})

interface RedirectionsDetailsModalProps {
  deviceId: number;
  handleFetchDevices: () => Promise<void>
  isTariffPackageEnabled: boolean
}
export const RedirectionsDetails = (props: RedirectionsDetailsModalProps) => {
  const {
    deviceId,
    handleFetchDevices,
    isTariffPackageEnabled
  } = props;
  const intl = useIntl();
  const { handleOpenModal, handleHideModal } = useModal()
  const { getRedirectionTypeTranslation } = useRedirectionTypes()

  const {
    redirections,
    loading: redirectionsLoading,
    handleDeleteRedirection,
  } = useRedirections({
    page: 0,
    limit: 0,
    deviceIds: [deviceId.toString()],
    takeAll: true,
    redirectionTypes: [REDIRECTION_TYPES.SMS, REDIRECTION_TYPES.CALL],
  })
  const openEditRedirectionModal = (
    redirection: RedirectionListResponse['redirections'][0],
  ) =>
    handleOpenModal({
      type: MODAL_TYPES.REDIRECTION_SETTINGS,
      props: {
        redirection: redirection,
        redirectionTypeId: redirection.redirectionTypeId,
        handleFetch: handleFetchDevices,
        handleDeleteRedirection: handleDeleteRedirection,
        phoneSourceVariant:
          redirection.redirectionTypeId === REDIRECTION_TYPES.CALL
            ? 'international-only'
            : 'all',
      },
    })
  return (
    <>
      {redirections.length > 0 ?
        <div className={styles.GridRedirects}>
          {redirections.map((redirection) => (
            <div className={styles.Redirect} key={redirection.redirectionId}>
              <div className={styles.Redirect__title}>{redirection.name}</div>
              <div className={styles.RedirectActions}>
                <div className={styles.RedirectionType}>
                  {redirection.redirectionTypeId === REDIRECTION_TYPES.SMS && (
                    <RedirectionTypeSmsIcon />
                  )}
                  {redirection.redirectionTypeId === REDIRECTION_TYPES.CALL && (
                    <RedirectionTypeCallIcon />
                  )}
                  <div>
                    {getRedirectionTypeTranslation(redirection.redirectionTypeId)}
                  </div>
                </div>

                <Button
                  text={intl.formatMessage(CommonButtonMessages.details)}
                  onClick={() => openEditRedirectionModal(redirection)}
                  variant={'white'}
                  size={'small'}
                  disabled={isTariffPackageEnabled}
                  additionalClassNames={[styles.RedirectionDetailsBtn]}
                />
              </div>
            </div>
          ))}
        </div>
        :
        <div className={styles.EmptyRedirects}>
          <div>
            {intl.formatMessage(RedirectionsDetailsModalMessage.emptyRedirectsFirst)}
          </div>
          <div>
            {intl.formatMessage(RedirectionsDetailsModalMessage.emptyRedirectsSecond)}
          </div>
        </div>
      }
    </>
  );
};

