import { Alert, Snackbar } from '@mui/material'
import { useSnackbar } from '../../app/hooks/useSnackbar'
import styles from './styles.module.scss'

export const SNACKBAR_TYPES = {
  error: 'error',
  warning: 'warning',
  info: 'info',
  success: 'success',
}

export const SnackbarProvider = () => {
  // @ts-ignore
  const { type, text, handleHideSnackbar } = useSnackbar()

  if (!type) {
    return <></>
  }

  return (
    <div className={styles.Container}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!type}
        onClose={handleHideSnackbar}
        autoHideDuration={2000}
      >
        <Alert
          onClose={handleHideSnackbar}
          severity={type}
          sx={{ width: '100%' }}
        >
          {text}
        </Alert>
      </Snackbar>
    </div>
  )
}
