import React, { FC } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { SmsStatus } from '../../../../../pages/lk/subpages/sms/components/SmsStatus/SmsStatus'
import { SmsListResponse } from '../../../../../sdk/datagates/types/sms/_crud/list'
import { SmsType } from '../../../../../sdk/hooks/use-smses/useSmses'
import { formatDate } from '../../../../../shared/lib/utils/date-utils/formatDate'
import { LC } from '../../../../../tests/e2e/locators'
import { SmsTypeBadge } from '../../../../custom-badges/sms-type-badge/CallTypeBadge'
import styles from './styles.module.scss'

interface SmsViewRepliedFromSmsProps {
  repliedFromSms: SmsListResponse['smses'][0]['repliedFromSms']
}

const SmsViewRepliedFromSmsMessages = defineMessages({
  title: {
    id: 'SmsViewRepliedFromSmsMessages.smsText',
    defaultMessage: 'Sent {replyType}',
  },
  reply: {
    id: 'SmsViewRepliedFromSmsMessages.phoneNumber',
    defaultMessage: 'Reply',
  },
  autoReply: {
    id: 'SmsViewRepliedFromSmsMessages.sentSms',
    defaultMessage: 'Auto Reply',
  },
  smsText: {
    id: 'SmsViewRepliedFromSmsMessages.smsText',
    defaultMessage: 'Sms text',
  },
})

export const SmsViewRepliedFromSms: FC<SmsViewRepliedFromSmsProps> = (
  props,
) => {
  const { repliedFromSms } = props

  const intl = useIntl()

  if (!repliedFromSms) return null

  const isAutoReply = repliedFromSms.smsTypeId === SmsType.AUTO_REPLY

  const sentDate = `${formatDate(repliedFromSms.createdAt, 0, 'date')} ${formatDate(repliedFromSms.createdAt, 0, 'time')}`

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <div className={styles.ReplyType}>
          <SmsTypeBadge type={repliedFromSms.smsTypeId} />
          <div>
            {intl.formatMessage(SmsViewRepliedFromSmsMessages.title, {
              replyType: isAutoReply
                ? intl.formatMessage(SmsViewRepliedFromSmsMessages.autoReply)
                : intl.formatMessage(SmsViewRepliedFromSmsMessages.reply),
            })}
          </div>
        </div>
        <div className={styles.SmsTimeAndStatus}>
          <div>{sentDate}</div>
          {SmsStatus({
            smsStatusId: repliedFromSms.smsStatusId,
            testId: LC.SMS.TABLE.ROW.STATUS(repliedFromSms.smsId),
          })}
        </div>
      </div>

      <div className={styles.GrayCard}>
        <div className={styles.MessageContainer}>
          <div className={styles.MessageContainer__title}>
            {intl.formatMessage(SmsViewRepliedFromSmsMessages.smsText)}
          </div>
          <div className={styles.MessageContainer__text}>
            {repliedFromSms.messageDecoded}
          </div>
        </div>
      </div>
    </div>
  )
}
