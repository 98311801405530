import {
  DocumentGetBackendResponse,
  DocumentGetResponse,
} from '../../../../../types/document/_crud/get'

export const mapDocumentGetBackendToDocumentGet = (
  item: DocumentGetBackendResponse,
): DocumentGetResponse => {
  const {
    download_url,
    file_id,
    user_id,
    name,
    file_type_id,
    created_at,
    updated_at,
  } = item.data

  return {
    downloadUrl: download_url,
    fileId: file_id,
    userId: user_id,
    name: name,
    fileTypeId: file_type_id,
    createdAt: created_at,
    updatedAt: updated_at,
  }
}
