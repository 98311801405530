import { parseStringAsUTCDate } from '../../../../../../../../shared/lib/utils/date-utils/parseStringAsUTCDate'
import {
  InvoiceListBackendResponse,
  InvoiceListResponse,
} from '../../../../../../types/billing/invoice/_crud/list'

export const mapInvoiceListBackendToInvoiceList = (
  item: InvoiceListBackendResponse,
): InvoiceListResponse => {
  const { invoices, total_count } = item.data

  return {
    invoices: invoices.map((el) => ({
      invoiceId: el.invoice_id,
      userId: el.user_id,
      createdAt: parseStringAsUTCDate(el.created_at),
      updatedAt: parseStringAsUTCDate(el.updated_at),
      orderId: el.order_id,
      amount: el.amount / 100,
      invoiceFileId: el.invoice_file_id,
      receiptFileId: el.receipt_file_id,
      invoiceStatusId: el.invoice_status_id,
      invoiceTypeId: el.invoice_type_id,
    })),
    totalCount: total_count,
  }
}
