import React, { FC, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useSnackbar } from '../../../../../../app/hooks/useSnackbar'
import { ReactComponent as SearchIcon } from '../../../../../../assets/icons/search.svg'
import { CommonButtonMessages } from '../../../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { formatPhoneNumber } from '../../../../../../sdk/formatters/format-phone-number'
import { useContactGroups } from '../../../../../../sdk/hooks/use-contact-groups/useContactGroups'
import { useContacts } from '../../../../../../sdk/hooks/use-contacts/useContacts'
import { Button } from '../../../../../../shared/ui-kit-2/inputs/button/Button'
import {
  Checkbox,
  CheckboxProps,
} from '../../../../../../shared/ui-kit-2/inputs/checkbox/Checkbox'
import { Input } from '../../../../../../shared/ui-kit-2/inputs/input/Input'
import {
  Tabs,
  TabType,
} from '../../../../../../shared/ui-kit-2/navigation/tabs/Tabs'
import { Loader } from '../../../../../../shared/ui-kit/data-display/loader/Loader'
import { SNACKBAR_TYPES } from '../../../../../snackbar/SnackbarProvider'
import { Source } from '../../useAddSource'
import styles from './styles.module.scss'

interface SourceContactsProps {
  onSave: (source: Source) => void
  sourceList: Source[]
  onClose: () => void
}

const SOURCE_TYPES = {
  CONTACT: 'contact',
  CONTACT_GROUP: 'contact-group',
}

const SourceContactsMessages = defineMessages({
  contacts: {
    id: 'SourceContactsMessages.contacts',
    defaultMessage: 'Contacts',
  },
  searchPlaceholder: {
    id: 'SourceContactsMessages.searchPlaceholder',
    defaultMessage: 'Search',
  },
  groups: {
    id: 'SourceContactsMessages.groups',
    defaultMessage: 'Groups',
  },
  allContacts: {
    id: 'SourceContactsMessages.allContacts',
    defaultMessage: 'All contacts',
  },
  duplicates: {
    id: 'SourceContactsMessages.duplicates',
    defaultMessage:
      'Some of the selected contacts are already in the list. Only new contacts were added.',
  },
  emptyError: {
    id: 'SourceContactsMessages.emptyError',
    defaultMessage: 'Please select at least one option',
  },
})

export const SourceContacts: FC<SourceContactsProps> = (props) => {
  const { onSave, sourceList } = props

  const intl = useIntl()
  const { handleOpenSnackbar } = useSnackbar()
  const [tab, setTab] = useState<string>(SOURCE_TYPES.CONTACT)
  const [all, setAll] = useState<boolean>(false)
  const { contacts, loading: contactsLoading } = useContacts({})
  const { contactGroups, loading: contactGroupsLoading } = useContactGroups({})
  const [selectedContactIds, setSelectedContactIds] = useState<string[]>([])
  const [selectedContactGroupIds, setSelectedContactGroupIds] = useState<
    string[]
  >([])
  const [searchValue, setSearchValue] = useState<string>('')

  const onSubmit = () => {
    if (
      selectedContactGroupIds.some((cgId) =>
        sourceList.some(
          (s) => s.sourceType === 'contact-groups' && cgId === s.sourceValue,
        ),
      ) ||
      selectedContactIds.some((cId) =>
        sourceList.some(
          (s) => s.sourceType === 'contacts' && cId === s.sourceValue,
        ),
      ) ||
      sourceList.some((s) => s.sourceType === 'all-contacts')
    ) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.warning,
        text: intl.formatMessage(SourceContactsMessages.duplicates),
      })
    }

    if (all) {
      !sourceList.some((s) => s.sourceType === 'all-contacts') &&
        onSave({
          sourceType: 'all-contacts',
          sourceName: intl.formatMessage(SourceContactsMessages.allContacts),
          sourceValue: '',
        })
      return
    }

    if (
      selectedContactGroupIds.length === 0 &&
      selectedContactIds.length === 0
    ) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(SourceContactsMessages.emptyError),
      })
      return
    }

    if (selectedContactIds.length > 0) {
      const selectedContacts = contacts.filter((c) => {
        return (
          selectedContactIds.includes(c.contactId.toString()) &&
          !sourceList.some(
            (s) =>
              s.sourceValue === c.contactId.toString() &&
              s.sourceType === 'contacts',
          )
        )
      })
      selectedContacts.forEach((c) => {
        onSave({
          sourceType: 'contacts',
          sourceName: c.name + ' ' + formatPhoneNumber(c.phone),
          sourceValue: c.contactId.toString(),
        })
      })
    }

    if (selectedContactGroupIds.length > 0) {
      const selectedContactGroups = contactGroups.filter((c) => {
        return (
          selectedContactGroupIds.includes(c.contactGroupId.toString()) &&
          !sourceList.some(
            (s) =>
              s.sourceValue === c.contactGroupId.toString() &&
              s.sourceType === 'contact-groups',
          )
        )
      })
      selectedContactGroups.forEach((cg) => {
        onSave({
          sourceType: 'contact-groups',
          sourceName: cg.name,
          sourceValue: cg.contactGroupId.toString(),
        })
      })
    }
  }

  const onContactClick = (contactId: string) => {
    if (selectedContactIds.includes(contactId)) {
      setSelectedContactIds((prev) => prev.filter((id) => id !== contactId))
    } else {
      setSelectedContactIds((prev) => [...prev, contactId])
    }
  }

  const onContactGroupClick = (contactGroupId: string) => {
    if (selectedContactGroupIds.includes(contactGroupId)) {
      setSelectedContactGroupIds((prev) =>
        prev.filter((id) => id !== contactGroupId),
      )
    } else {
      setSelectedContactGroupIds((prev) => [...prev, contactGroupId])
    }
  }

  const contactsCheckboxes = contacts.map<CheckboxProps>((c) => ({
    key: c.contactId.toString(),
    name: 'contacts',
    text: c.name,
    subText: formatPhoneNumber(c.phone),
    checked: selectedContactIds.includes(c.contactId.toString()),
  }))

  const contactGroupsCheckboxes = contactGroups.map<CheckboxProps>((c) => ({
    key: c.contactGroupId.toString(),
    name: 'contact-groups',
    text: c.name,
    checked: selectedContactGroupIds.includes(c.contactGroupId.toString()),
  }))

  const searchComponent = (
    <Input
      value={searchValue}
      // @ts-ignore
      onChange={(e) => setSearchValue(e.target.value)}
      placeholder={intl.formatMessage(SourceContactsMessages.searchPlaceholder)}
      inputSize={'small'}
      postfix={<SearchIcon />}
      validationNeeded={false}
    />
  )

  const contactsCheckboxesFiltered = useMemo(
    () =>
      contactsCheckboxes
        .filter((c) => {
          if (typeof c.text === 'string')
            return (
              c.text?.toLowerCase().includes(searchValue.toLowerCase()) ||
              c.subText?.toLowerCase().includes(searchValue.toLowerCase())
            )
        })
        .sort((a, b) => {
          if (a.text && b.text) {
            if (a.text < b.text) return -1
            if (a.text > b.text) return 1

            if (a.subText && b.subText) {
              if (a.subText < b.subText) return -1
              if (a.subText > b.subText) return 1
            }
          }
          return 0
        }),
    [contactsCheckboxes, searchValue],
  )

  const contactGroupsCheckboxesFiltered = useMemo(
    () =>
      contactGroupsCheckboxes
        .filter((c) => {
          if (typeof c.text === 'string')
            return c.text?.toLowerCase().includes(searchValue.toLowerCase())
        })
        .sort((a, b) => {
          if (typeof a.text === 'string' && typeof b.text === 'string') {
            if (a.text < b.text) return -1
            if (a.text > b.text) return 1
          }
          return 0
        }),
    [contactGroupsCheckboxes, searchValue],
  )

  const contactsTabComponent = (
    <div className={styles.Form}>
      {all && (
        <div
          className={styles.FormOverlay}
          onClick={(e) => e.stopPropagation()}
        />
      )}
      {searchComponent}
      <div className={styles.ScrollableContent}>
        {contactsLoading ? (
          <Loader />
        ) : (
          contactsCheckboxesFiltered.map((cc) => (
            <Checkbox
              key={cc.key}
              checked={cc.checked}
              text={cc.text}
              subText={cc.subText}
              onChange={() => onContactClick(cc.key as string)}
              variant={'outlined'}
              additionalClassNames={[styles.ContactCheckbox]}
            />
          ))
        )}
      </div>
    </div>
  )

  const contactGroupsTabComponent = (
    <div className={styles.Form}>
      {all && <div className={styles.FormOverlay} />}
      {searchComponent}
      <div className={styles.ScrollableContent}>
        {contactGroupsLoading ? (
          <Loader />
        ) : (
          contactGroupsCheckboxesFiltered.map((cg) => (
            <Checkbox
              key={cg.key}
              checked={cg.checked}
              text={cg.text}
              onChange={() => onContactGroupClick(cg.key as string)}
              variant={'outlined'}
              additionalClassNames={[styles.ContactCheckbox]}
            />
          ))
        )}
      </div>
    </div>
  )

  const tabs: TabType[] = [
    {
      id: SOURCE_TYPES.CONTACT,
      label: intl.formatMessage(SourceContactsMessages.contacts),
    },
    {
      id: SOURCE_TYPES.CONTACT_GROUP,
      label: intl.formatMessage(SourceContactsMessages.groups),
    },
  ]

  const handleTabChange = (id: string) => {
    setTab(id)

    if (id === SOURCE_TYPES.CONTACT) setSelectedContactGroupIds([])
    if (id === SOURCE_TYPES.CONTACT_GROUP) setSelectedContactIds([])

    setSearchValue('')
  }

  return (
    <div className={styles.Container}>
      <div className={styles.GrayCard}>
        <div className={styles.Header}>
          <Tabs
            items={tabs}
            defaultTabId={SOURCE_TYPES.CONTACT}
            handleChange={handleTabChange}
            size={'small'}
            additionalClassNames={[styles.TabsAdditional]}
          />

          <Checkbox
            checked={all}
            onChange={() => setAll((prev) => !prev)}
            variant={'outlined'}
            text={intl.formatMessage(SourceContactsMessages.allContacts)}
          />
        </div>

        {tab === SOURCE_TYPES.CONTACT && contactsTabComponent}
        {tab === SOURCE_TYPES.CONTACT_GROUP && contactGroupsTabComponent}
      </div>

      <Button
        text={intl.formatMessage(CommonButtonMessages.add)}
        onClick={onSubmit}
      />
    </div>
  )
}
