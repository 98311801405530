
import {FAQArticles} from "./components/FAQArticles/FAQArticles";
import {FAQNavigation} from "./components/FAQNavigation/FAQNavigation";
import styles from './styles.module.scss';
import {useEffect} from "react";

export const FAQPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);

  return (
    <div className={styles.FAQ}>
      <div className={styles.Head}>
        <div className={styles.Title}>
          FAQ
        </div>
      </div>
      <div className={styles.Body}>
        <FAQNavigation />
        <FAQArticles />
      </div>
    </div>
  )
}