import { parseStringAsUTCDate } from '../../../../../../../shared/lib/utils/date-utils/parseStringAsUTCDate'
import {
  SmsListBackendResponse,
  SmsListResponse,
} from '../../../../../types/sms/_crud/list'

export const mapSmsListBackendToSmsList = (
  item: SmsListBackendResponse,
): SmsListResponse => {
  const { smses, total_count } = item.data

  return {
    smses: smses.map((el) => {
      const redirectedTo = el.redirections.reduce((acc, cur) => {
        const emails: string[] = (cur.params as any)['emails'] ?? []
        const telegramChatIds: string[] =
          (cur.params as any)['telegram_chat_ids'] ?? []
        return acc.concat(emails).concat(telegramChatIds)
      }, [] as string[])

      return {
        dongleId: el.dongle_id,
        dongleName: el.dongle_name,
        smsId: el.sms_id,
        smsTypeId: el.sms_type_id,
        numberCaller: el.number_caller,
        numberReceiver: el.number_receiver,
        messageDecoded: el.message_decoded,
        apiResponseData: el.api_response_data,
        smsCenter: el.sms_center,
        smsStatusId: el.sms_status_id,
        createdAt: parseStringAsUTCDate(el.created_at),
        updatedAt: parseStringAsUTCDate(el.updated_at),
        scheduled_at: el.scheduled_at
          ? parseStringAsUTCDate(el.scheduled_at)
          : undefined,
        contact: el.contact
          ? {
              contactId: el.contact.contact_id,
              name: el.contact.name,
              phone: el.contact.phone,
              userId: el.contact.user_id,
            }
          : null,
        repliedToSmsId: el.replied_to_sms_id,
        repliedFromSms: el.replied_from_sms
          ? {
              smsId: el.replied_from_sms.sms_id,
              smsTypeId: el.replied_from_sms.sms_type_id,
              smsStatusId: el.replied_from_sms.sms_status_id,
              createdAt: parseStringAsUTCDate(el.replied_from_sms.created_at),
              messageDecoded: el.replied_from_sms.message_decoded,
            }
          : null,
        redirectedTo: redirectedTo,
      }
    }),
    totalCount: total_count,
  }
}
