import {
  DongleRedirectionCreateBackendResponse,
  DongleRedirectionCreateResponse,
} from '../../../../../../types/dongle/redirection/_crud/create'

export const mapDongleRedirectionCreateBackendToDongleRedirectionCreate = (
  item: DongleRedirectionCreateBackendResponse,
): DongleRedirectionCreateResponse => {
  const {
    redirection_id,
    dongle_id,
    condition,
    name,
    params,
    created_at,
    updated_at,
  } = item.data

  return {
    redirectionId: redirection_id,
    dongleId: dongle_id,
    condition: condition,
    name: name,
    params: params,
    createdAt: created_at,
    updatedAt: updated_at,
  }
}
