// @ts-nocheck

import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { useAddContacts } from '../../../_hooks/use-add-contacts/useAddContacts'
import { Source } from '../../../_hooks/use-add-source/useAddSource'
import { ReactComponent as SearchIcon } from '../../../../../assets/icons/search.svg'
import { ReactComponent as TrashIcon } from '../../../../../assets/icons/trash.svg'
import { Counter } from '../../../../../shared/ui-kit-2/data-display/counter/Counter'
import { Button } from '../../../../../shared/ui-kit-2/inputs/button/Button'
import { Input } from '../../../../../shared/ui-kit-2/inputs/input/Input'
import { TextButton } from '../../../../../shared/ui-kit-2/inputs/text-button/TextButton'
import styles from './styles.module.scss'

interface AddContactsToGroupProps {
  contactSources: Source[]
  setContactSources: Dispatch<SetStateAction<Source[]>>
  selectedContact?: string[]
  contactGroupId?: number
}

const AddContactsToGroupMessages = defineMessages({
  title: {
    id: 'AddContactsToGroupMessages.title',
    defaultMessage: 'Add contacts',
  },
  titleNotEmpty: {
    id: 'AddContactsToGroupMessages.titleNotEmpty',
    defaultMessage: 'Contacts',
  },
  addContacts: {
    id: 'AddContactsToGroupMessages.addContacts',
    defaultMessage: 'Add contacts',
  },
  searchPlaceholder: {
    id: 'AddContactsToGroupMessages.searchPlaceholder',
    defaultMessage: 'Search by name or phone number',
  },
  description: {
    id: 'AddContactsToGroupMessages.description',
    defaultMessage: 'Add contacts from your list to the group',
  },
})

export const AddContactsToGroup: FC<AddContactsToGroupProps> = (props) => {
  const intl = useIntl()

  const { contactSources, setContactSources, contactGroupId } = props

  const [search, setSearch] = useState<string>('')

  const { setIsOpen, component, setSelectedContactIds } = useAddContacts({
    sourceList: contactSources,
    contactGroupId: contactGroupId,
    onSave: (source: Source[]) => {
      setContactSources(source)
    },
  })
  const title = useMemo(() => {
    return intl.formatMessage(
      contactSources.length > 0
        ? AddContactsToGroupMessages.titleNotEmpty
        : AddContactsToGroupMessages.title,
    )
  }, [contactSources])

  const filteredSources = useMemo(() => {
    return contactSources
      .filter((cs) => {
        const name = cs.metaInformation?.name
        const phone = cs.metaInformation?.phone
        return (
          name?.toLowerCase().includes(search.toLowerCase()) ||
          phone?.includes(search.toLowerCase())
        )
      })
      .sort((a, b) => {
        const aName = a.metaInformation?.name
        const aPhone = a.metaInformation?.phone
        const bName = b.metaInformation?.name
        const bPhone = b.metaInformation?.phone

        if (aName && bName) {
          if (aName < bName) return -1
          if (aName > bName) return 1

          if (aPhone && bPhone) {
            if (aPhone < bName) return -1
            if (aPhone > bName) return 1
          }
        }
        return 0
      })
  }, [contactSources, search])

  const atLeastOneSourceChosen = useMemo(
    () => contactSources.length > 0,
    [contactSources],
  )
  const handleDelete = (s: Source) => {
    setContactSources((prev) =>
      prev.filter((p) => p.sourceValue !== s.sourceValue),
    )
    setSelectedContactIds((prev) => prev.filter((p) => p !== s.sourceValue))
  }

  return (
    <>
      <div className={styles.Container}>
        <div className={styles.Header}>
          <div className={styles.Title}>
            <div>{title}</div>
            {atLeastOneSourceChosen && (
              <Counter
                count={contactSources.length}
                size={'small'}
                variant={'filled'}
              />
            )}
          </div>

          {atLeastOneSourceChosen && (
            <TextButton
              text={intl.formatMessage(AddContactsToGroupMessages.addContacts)}
              onClick={() => setIsOpen(true)}
              icon={'contact'}
            />
          )}
        </div>

        <div className={styles.ContactList}>
          <div className={styles.SearchContainer}>
            <Input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              validationNeeded={false}
              inputSize={'small'}
              postfix={<SearchIcon />}
              placeholder={intl.formatMessage(
                AddContactsToGroupMessages.searchPlaceholder,
              )}
              additionalClassNames={[styles.SearchAdditional]}
            />
          </div>

          {!atLeastOneSourceChosen && (
            <div className={styles.EmptyContent}>
              <div>
                {intl.formatMessage(AddContactsToGroupMessages.description)}
              </div>
              <Button
                text={intl.formatMessage(
                  AddContactsToGroupMessages.addContacts,
                )}
                onClick={() => setIsOpen(true)}
                size={'small'}
                variant={'white'}
                additionalClassNames={[styles.AddContactWhenEmptyBtn]}
              />
            </div>
          )}

          {filteredSources.map((s, index) => {
            return (
              <div
                className={clsx(
                  styles.Contact,
                  index === 0 && styles.Contact_top,
                )}
                key={s.sourceValue}
              >
                <div className={styles.ContactText}>
                  {s.metaInformation?.name && (
                    <div className={styles.ContactText__textGray}>
                      {s.metaInformation.phone}
                    </div>
                  )}
                  {s.metaInformation?.phone && (
                    <div className={styles.ContactText__darkText}>
                      {s.metaInformation.name}
                    </div>
                  )}
                </div>

                <TrashIcon
                  className={styles.Delete}
                  onClick={() => handleDelete(s)}
                />
              </div>
            )
          })}
        </div>
      </div>

      {component}
    </>
  )
}
