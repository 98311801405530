import {
  TariffPackageListBackendResponse,
  TariffPackageListResponse,
} from '../../../../../../types/billing/tariff-package/_crud/list'

export const mapTariffPackageListBackendToTariffPackageList = (
  item: TariffPackageListBackendResponse,
): TariffPackageListResponse => {
  const { tariff_packages } = item.data

  return {
    // @ts-ignore
    tariffPackages: tariff_packages.map((el) => ({
      tariffPackageId: el.tariff_package_id,
      name: el.name,
      dongleLimit: el.dongle_limit,
      donglePrice: el.dongle_price / 100,
      period: el.period,
      periodTypeId: el.period_type_id,
      price: el.price / 100,
      siteDomainId: el.site_domain_id,
    })),
  }
}
