import React from 'react'
import { ConnectModemForm } from './components/connect-modem-form/ConnectModemForm'
import { HowToConnectModem } from './components/how-to-connect-modem/HowToConnectModem'
import styles from './styles.module.scss'

export const StepConnectDevice = () => {
  return (
    <div className={styles.Container}>
      <HowToConnectModem />
      <ConnectModemForm />
    </div>
  )
}
