import {
  PaymentMethodListBackendResponse,
  PaymentMethodListResponse,
} from '../../../../../../types/payment/payment-method/_crud/list'

export const mapPaymentMethodListBackendToPaymentMethodList = (
  item: PaymentMethodListBackendResponse,
): PaymentMethodListResponse => {
  const { payment_methods } = item.data

  return {
    paymentMethods: payment_methods.map((el) => ({
      paymentMethodId: el.payment_method_id,
      name: el.name,
      isDefault: el.is_default,
      isSecondary: el.is_secondary,
    })),
  }
}
