import {wrapApiRequest} from "../../helpers/_common/wrap-api-request";

export const ussdGetDatagate = wrapApiRequest<
  any,
  any,
  any
>('dongle/{dongle_id}/ussd', data => data, 'GET')

export const ussdDeleteDatagate = wrapApiRequest<
  any,
  any,
  any
>('dongle/{dongle_id}/ussds', data => data, 'DELETE')

export const ussdPostDatagate = wrapApiRequest<
  any,
  any,
  any
>('dongle/{dongle_id}/ussd', data => data, 'POST')

