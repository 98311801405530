import React, { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useAddContactGroups } from '../../../_hooks/use-add-contact-groups/useAddContactGroups'
import { Source } from '../../../_hooks/use-add-source/useAddSource'
import { ReactComponent as TrashIcon } from '../../../../../assets/icons/trash.svg'
import { Counter } from '../../../../../shared/ui-kit-2/data-display/counter/Counter'
import { TextButton } from '../../../../../shared/ui-kit-2/inputs/text-button/TextButton'
import styles from './styles.module.scss'

interface AddToGroupProps {
  contactGroupSources: Source[]
  setContactGroupSources: Dispatch<SetStateAction<Source[]>>
}

const AddToGroupMessages = defineMessages({
  title: {
    id: 'AddToGroupMessages.title',
    defaultMessage: 'Add to groups',
  },
  titleNotEmpty: {
    id: 'AddToGroupMessages.titleNotEmpty',
    defaultMessage: 'Groups',
  },
  chooseGroups: {
    id: 'AddToGroupMessages.chooseGroups',
    defaultMessage: 'Choose groups',
  },
  contacts: {
    id: 'AddToGroupMessages.contacts',
    defaultMessage: 'contacts',
  },
})

export const AddToGroup: FC<AddToGroupProps> = (props) => {
  const intl = useIntl()

  const { contactGroupSources, setContactGroupSources } = props
  const { setIsOpen, component } = useAddContactGroups({
    sourceList: contactGroupSources,
    onSave: (source: Source) => {
      setContactGroupSources((prev) => [...prev, source])
    },
  })

  const title = useMemo(() => {
    return intl.formatMessage(
      contactGroupSources.length > 0
        ? AddToGroupMessages.titleNotEmpty
        : AddToGroupMessages.title,
    )
  }, [contactGroupSources])

  return (
    <>
      <div className={styles.Container}>
        <div className={styles.Header}>
          <div className={styles.Title}>{title}</div>
          <TextButton
            text={intl.formatMessage(AddToGroupMessages.chooseGroups)}
            onClick={() => setIsOpen(true)}
            icon={'contact'}
          />
        </div>

        <div className={styles.ContactGroupList}>
          {contactGroupSources.map((s) => {
            const hasContactsCount =
              s.metaInformation?.contactsCount !== undefined

            return (
              <div className={styles.ContactGroup} key={s.sourceValue}>
                <div>{s.sourceName}</div>

                <div className={styles.Actions}>
                  {hasContactsCount && (
                    <Counter
                      count={s.metaInformation.contactsCount}
                      text={intl.formatMessage(AddToGroupMessages.contacts)}
                      size={'small'}
                    />
                  )}

                  <TrashIcon
                    className={styles.Delete}
                    onClick={() =>
                      setContactGroupSources((prev) =>
                        prev.filter((p) => p.sourceValue !== s.sourceValue),
                      )
                    }
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>

      {component}
    </>
  )
}
