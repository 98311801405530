import React, { useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useSnackbar } from '../../../../app/hooks/useSnackbar'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-mini.svg'
import { CommonButtonMessages } from '../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { InputField } from '../../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import {
  composeValidators,
  useFormRules,
} from '../../../../shared/lib/form/form-rules'
import { useClickOutside } from '../../../../shared/lib/hooks/useClickOutside'
import { Card } from '../../../../shared/ui-kit-2/data-display/card/Card'
import { Button } from '../../../../shared/ui-kit-2/inputs/button/Button'
import { SNACKBAR_TYPES } from '../../../snackbar/SnackbarProvider'
import styles from './styles.module.scss'

interface UseAddEmailProps {
  processOnSave: (email: string) => void
  emailList?: string[]
}

type UseAddEmailFormValues = {
  email: string
}

const UseAddEmailMessages = defineMessages({
  title: {
    id: 'UseAddEmailMessages.title',
    defaultMessage: 'Add email address',
  },
  emailLabel: {
    id: 'UseAddEmailMessages.emailLabel',
    defaultMessage: 'Your email address',
  },
  emailPlaceholder: {
    id: 'UseAddEmailMessages.emailPlaceholder',
    defaultMessage: 'hello@gmail.com',
  },
  alreadyExists: {
    id: 'UseAddEmailMessages.alreadyExists',
    defaultMessage: 'Email already added',
  },
})

export const useAddEmail = (props: UseAddEmailProps) => {
  const { processOnSave, emailList } = props

  const intl = useIntl()
  const { handleOpenSnackbar } = useSnackbar()
  const { ruleRequired, ruleEmail } = useFormRules()
  const [isOpen, setIsOpen] = useState<boolean>()
  const ref = useRef<HTMLDivElement>(null)

  const onClose = () => {
    setIsOpen(false)
  }

  useClickOutside(ref, onClose)

  const onSave = (values: UseAddEmailFormValues) => {
    if (emailList?.includes(values.email)) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(UseAddEmailMessages.alreadyExists),
      })

      return
    }

    processOnSave(values.email)
    onClose()
  }

  const content = (
    <Form
      onSubmit={onSave}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} className={styles.Content}>
          <InputField
            name={'email'}
            validate={composeValidators(ruleRequired(), ruleEmail())}
            label={intl.formatMessage(UseAddEmailMessages.emailLabel)}
            placeholder={intl.formatMessage(
              UseAddEmailMessages.emailPlaceholder,
            )}
            markAsRequired={true}
          />

          <Button
            type={'submit'}
            text={intl.formatMessage(CommonButtonMessages.add)}
            onClick={handleSubmit}
            disabled={submitting}
            loading={submitting}
          />
        </form>
      )}
    />
  )

  const component = (
    <div className={styles.Overlay}>
      <Card additionalClassNames={[styles.CardAdditional]} currentRef={ref}>
        <div className={styles.Header}>
          <div className={styles.Title}>
            {intl.formatMessage(UseAddEmailMessages.title)}
          </div>
          <CloseIcon className={styles.Close} onClick={onClose} />
        </div>
        {content}
      </Card>
    </div>
  )

  return {
    setIsOpen,
    component: isOpen ? component : null,
  }
}
