import { UserStatuses } from '../../../../../../../store/reducers/user/types'
import {
  UserUpdateBackendResponse,
  UserUpdateResponse,
} from '../../../../../types/user/_crud/update'

export const mapUserUpdateBackendToUserUpdate = (
  item: UserUpdateBackendResponse,
): UserUpdateResponse => {
  const {
    user_id,
    user_status_id,
    balance,
    email,
    user_type_id,
    name,
    last_name,
    company_name,
    company_phone,
    phone,
    billing_email,
    vat_number,
    postal_code,
    street_address_1,
    street_address_2,
    country_id,
    city,
    region,
    user_payment_type_id,
    legal,
  } = item.data

  return {
    userId: user_id,
    status: user_status_id as UserStatuses,
    balance: balance,
    email: email,
    userTypeId: user_type_id,
    firstName: name,
    lastName: last_name,
    companyName: company_name,
    companyPhone: company_phone,
    userPhone: phone,
    billingEmail: billing_email,
    vatNumber: vat_number,
    postalCode: postal_code,
    streetAddress1: street_address_1,
    streetAddress2: street_address_2,
    countryId: country_id,
    city: city,
    region: region,
    userPaymentTypeId: user_payment_type_id,
    legal: {
      postalCode: legal?.postal_code,
      streetAddress1: legal?.street_address_1,
      streetAddress2: legal?.street_address_2,
      countryId: legal?.country_id,
      city: legal?.city,
      region: legal?.region,
    }
  }
}
