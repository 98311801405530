import React, { useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router'
import { useModal } from '../../../../../../app/hooks/useModal'
import { useSiteVersion } from '../../../../../../app/hooks/useSiteVersion'
import { ReactComponent as AvatarIcon } from '../../../../../../assets/icons/avatar.svg'
import { ReactComponent as HideIcon } from '../../../../../../assets/icons/cover.svg'
import { ReactComponent as DashboardIcon } from '../../../../../../assets/icons/dashboard.svg'
import { ReactComponent as AccountDetailsIcon } from '../../../../../../assets/icons/header-dropdown-account-details.svg'
import { ReactComponent as ActivateDeviceIcon } from '../../../../../../assets/icons/header-dropdown-activate-devices.svg'
import { ReactComponent as BuyDeviceIcon } from '../../../../../../assets/icons/header-dropdown-buy-new-device.svg'
import { ReactComponent as LogoutIcon } from '../../../../../../assets/icons/header-dropdown-logout.svg'
import { ReactComponent as OrdersIcon } from '../../../../../../assets/icons/header-dropdown-orders.svg'
import { ReactComponent as ShowIcon } from '../../../../../../assets/icons/uncover.svg'
import { RoutePath } from '../../../../../../config/routes/constants/routePath'
import { Route } from '../../../../../../config/routes/enums/route'
import { useClickOutside } from '../../../../../../shared/lib/hooks/useClickOutside'
import { useAppDispatch, useAppSelector } from '../../../../../../store'
import { clearUser } from '../../../../../../store/reducers/user'
import { MODAL_TYPES } from '../../../../../modals/ModalsProvider'
import styles from './styles.module.scss'

const UserHeaderMenuMessages = defineMessages({
  dashboard: {
    id: 'UserHeaderMenuMessages.dashboard',
    defaultMessage: 'Dashboard',
  },
  accountDetails: {
    id: 'UserHeaderMenuMessages.accountDetails',
    defaultMessage: 'Account details',
  },
  orders: {
    id: 'UserHeaderMenuMessages.orders',
    defaultMessage: 'My orders',
  },
  logout: {
    id: 'UserHeaderMenuMessages.logout',
    defaultMessage: 'Logout',
  },
  buyDevice: {
    id: 'UserHeaderMenuMessages.buyDevice',
    defaultMessage: 'Buy device',
  },
  activateDevice: {
    id: 'UserHeaderMenuMessages.activateDevice',
    defaultMessage: 'Activate device',
  },
})

type UserHeaderMenuProps = {
  vwMode?: boolean
}

export const UserHeaderMenu = ({ vwMode }: UserHeaderMenuProps) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { handleOpenModal } = useModal()
  const { siteVersion } = useSiteVersion()

  const { firstName, lastName, companyName, email } = useAppSelector(
    (state) => state.user,
  )
  const [opened, setOpened] = useState(false)
  const { pathname } = useLocation()

  const ref = useRef<HTMLDivElement>(null)
  useClickOutside(ref, () => setOpened(false))

  const nickname = useMemo(() => {
    if (companyName) return companyName
    else if (firstName && lastName)
      return `${firstName} ${lastName?.slice(0, 1)}.`
    else return email
  }, [firstName, lastName, companyName, email])

  const wrapCloseDropdownAction = (fn: any) => {
    fn()
    setOpened(false)
  }

  const toAccountDetailsPage = () => {
    wrapCloseDropdownAction(() => navigate(RoutePath[Route.Profile]))
  }
  const toOrdersPage = () => {
    wrapCloseDropdownAction(() => navigate(RoutePath[Route.Orders]))
  }

  const toBuyNewDevice = () =>
    handleOpenModal({
      type: MODAL_TYPES.BUY_NEW_DEVICE,
    })

  const toActivateDevices = () => {
    wrapCloseDropdownAction(() => navigate(RoutePath[Route.ActivateDevice]))
  }

  const handleLogout = () => {
    wrapCloseDropdownAction(() => dispatch(clearUser()))
  }

  return (
    <div
      className={clsx(styles.Container, vwMode && styles.Container_vw)}
      ref={ref}
    >
      <div
        className={clsx(styles.MenuBtn, opened && styles.MenuBtn_hidden)}
        onClick={() => setOpened(true)}
      >
        <div className={styles.AvatarAndNickname}>
          <div className={styles.Avatar}>
            <AvatarIcon />
          </div>
          <div className={styles.Nickname}>{nickname}</div>
        </div>

        <div className={styles.Icon}>
          <ShowIcon />
        </div>
      </div>

      {opened && (
        <div className={styles.DropdownContainer}>
          <div
            className={clsx(styles.MenuBtn, styles.MenuBtn_opened)}
            onClick={() => setOpened(false)}
          >
            <div className={styles.AvatarAndNickname}>
              <div className={styles.Avatar}>
                <AvatarIcon />
              </div>
              <div className={styles.Nickname}>{nickname}</div>
            </div>

            <div className={styles.Icon}>
              <HideIcon />
            </div>
          </div>

          <div className={styles.Dropdown}>
            {pathname === RoutePath[Route.Home] ? (
              <div className={styles.DropdownPages}>
                <div
                  className={styles.DropdownPages__page}
                  onClick={toAccountDetailsPage}
                >
                  <div className={styles.Icon}>
                    <DashboardIcon className={styles.SvgStroke} />
                  </div>
                  <div>
                    {intl.formatMessage(UserHeaderMenuMessages.dashboard)}
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.DropdownPages}>
                <div
                  className={styles.DropdownPages__page}
                  onClick={toAccountDetailsPage}
                >
                  <div className={styles.Icon}>
                    <AccountDetailsIcon className={styles.SvgStroke} />
                  </div>
                  <div>
                    {intl.formatMessage(UserHeaderMenuMessages.accountDetails)}
                  </div>
                </div>
                {siteVersion !== 'ru' && (
                  <div
                    className={styles.DropdownPages__page}
                    onClick={toOrdersPage}
                  >
                    <div className={styles.Icon}>
                      <OrdersIcon className={styles.SvgStroke} />
                    </div>
                    <div>
                      {intl.formatMessage(UserHeaderMenuMessages.orders)}
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className={styles.DropdownDivider} />

            <div
              className={clsx(
                styles.DropdownPages__page,
                styles.DropdownPages__page_danger,
              )}
              onClick={handleLogout}
            >
              <div className={styles.Icon}>
                <LogoutIcon />
              </div>
              <div>{intl.formatMessage(UserHeaderMenuMessages.logout)}</div>
            </div>

            <div className={styles.Buttons}>
              <div
                className={styles.ButtonBuyNewDevice}
                onClick={toBuyNewDevice}
              >
                <div className={styles.Icon}>
                  <BuyDeviceIcon />
                </div>
                <div>
                  {intl.formatMessage(UserHeaderMenuMessages.buyDevice)}
                </div>
              </div>
              <div
                className={styles.ButtonActivateDevices}
                onClick={toActivateDevices}
              >
                <div className={styles.Icon}>
                  <ActivateDeviceIcon />
                </div>
                <div>
                  {intl.formatMessage(UserHeaderMenuMessages.activateDevice)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
