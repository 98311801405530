import { defineMessages } from 'react-intl'

export const CountryMessages = defineMessages({
  AU: {
    id: 'CountryTranslations.AU',
    defaultMessage: 'Australia',
  },
  AT: {
    id: 'CountryTranslations.AT',
    defaultMessage: 'Austria',
  },
  AZ: {
    id: 'CountryTranslations.AZ',
    defaultMessage: 'Azerbaijan',
  },
  AX: {
    id: 'CountryTranslations.AX',
    defaultMessage: 'Åland Islands',
  },
  AL: {
    id: 'CountryTranslations.AL',
    defaultMessage: 'Albania',
  },
  DZ: {
    id: 'CountryTranslations.DZ',
    defaultMessage: 'Algeria',
  },
  VI: {
    id: 'CountryTranslations.VI',
    defaultMessage: 'Virgin Islands (U.S.)',
  },
  AS: {
    id: 'CountryTranslations.AS',
    defaultMessage: 'American Samoa',
  },
  AI: {
    id: 'CountryTranslations.AI',
    defaultMessage: 'Anguilla',
  },
  AO: {
    id: 'CountryTranslations.AO',
    defaultMessage: 'Angola',
  },
  AD: {
    id: 'CountryTranslations.AD',
    defaultMessage: 'Andorra',
  },
  AQ: {
    id: 'CountryTranslations.AQ',
    defaultMessage: 'Antarctica',
  },
  AG: {
    id: 'CountryTranslations.AG',
    defaultMessage: 'Antigua & Barbuda',
  },
  AR: {
    id: 'CountryTranslations.AR',
    defaultMessage: 'Argentina',
  },
  AM: {
    id: 'CountryTranslations.AM',
    defaultMessage: 'Armenia',
  },
  AW: {
    id: 'CountryTranslations.AW',
    defaultMessage: 'Aruba',
  },
  AF: {
    id: 'CountryTranslations.AF',
    defaultMessage: 'Afghanistan',
  },
  BS: {
    id: 'CountryTranslations.BS',
    defaultMessage: 'Bahamas',
  },
  BD: {
    id: 'CountryTranslations.BD',
    defaultMessage: 'Bangladesh',
  },
  BB: {
    id: 'CountryTranslations.BB',
    defaultMessage: 'Barbados',
  },
  BH: {
    id: 'CountryTranslations.BH',
    defaultMessage: 'Bahrain',
  },
  BZ: {
    id: 'CountryTranslations.BZ',
    defaultMessage: 'Belize',
  },
  BY: {
    id: 'CountryTranslations.BY',
    defaultMessage: 'Belarus',
  },
  BE: {
    id: 'CountryTranslations.BE',
    defaultMessage: 'Belgium',
  },
  BJ: {
    id: 'CountryTranslations.BJ',
    defaultMessage: 'Benin',
  },
  BM: {
    id: 'CountryTranslations.BM',
    defaultMessage: 'Bermuda',
  },
  BG: {
    id: 'CountryTranslations.BG',
    defaultMessage: 'Bulgaria',
  },
  BO: {
    id: 'CountryTranslations.BO',
    defaultMessage: 'Bolivia',
  },
  BQ: {
    id: 'CountryTranslations.BQ',
    defaultMessage: 'Bonaire, Sint Eustatius and Saba',
  },
  BA: {
    id: 'CountryTranslations.BA',
    defaultMessage: 'Bosnia & Herzegovina',
  },
  BW: {
    id: 'CountryTranslations.BW',
    defaultMessage: 'Botswana',
  },
  BR: {
    id: 'CountryTranslations.BR',
    defaultMessage: 'Brazil',
  },
  IO: {
    id: 'CountryTranslations.IO',
    defaultMessage: 'British Indian Ocean Territory',
  },
  VG: {
    id: 'CountryTranslations.VG',
    defaultMessage: 'British Virgin Islands',
  },
  BN: {
    id: 'CountryTranslations.BN',
    defaultMessage: 'Brunei',
  },
  BF: {
    id: 'CountryTranslations.BF',
    defaultMessage: 'Burkina Faso',
  },
  BI: {
    id: 'CountryTranslations.BI',
    defaultMessage: 'Burundi',
  },
  BT: {
    id: 'CountryTranslations.BT',
    defaultMessage: 'Bhutan',
  },
  VU: {
    id: 'CountryTranslations.VU',
    defaultMessage: 'Vanuatu',
  },
  VA: {
    id: 'CountryTranslations.VA',
    defaultMessage: 'Vatican City',
  },
  GB: {
    id: 'CountryTranslations.GB',
    defaultMessage: 'United Kingdom',
  },
  HU: {
    id: 'CountryTranslations.HU',
    defaultMessage: 'Hungary',
  },
  VE: {
    id: 'CountryTranslations.VE',
    defaultMessage: 'Venezuela',
  },
  UM: {
    id: 'CountryTranslations.UM',
    defaultMessage: 'United States Minor Outlying Islands',
  },
  TL: {
    id: 'CountryTranslations.TL',
    defaultMessage: 'Timor-Leste',
  },
  VN: {
    id: 'CountryTranslations.VN',
    defaultMessage: 'Vietnam',
  },
  GA: {
    id: 'CountryTranslations.GA',
    defaultMessage: 'Gabon',
  },
  HT: {
    id: 'CountryTranslations.HT',
    defaultMessage: 'Haiti',
  },
  GY: {
    id: 'CountryTranslations.GY',
    defaultMessage: 'Guyana',
  },
  GM: {
    id: 'CountryTranslations.GM',
    defaultMessage: 'Gambia',
  },
  GH: {
    id: 'CountryTranslations.GH',
    defaultMessage: 'Ghana',
  },
  GP: {
    id: 'CountryTranslations.GP',
    defaultMessage: 'Guadeloupe',
  },
  GT: {
    id: 'CountryTranslations.GT',
    defaultMessage: 'Guatemala',
  },
  GF: {
    id: 'CountryTranslations.GF',
    defaultMessage: 'French Guiana',
  },
  GN: {
    id: 'CountryTranslations.GN',
    defaultMessage: 'Guinea',
  },
  GW: {
    id: 'CountryTranslations.GW',
    defaultMessage: 'Guinea-Bissau',
  },
  DE: {
    id: 'CountryTranslations.DE',
    defaultMessage: 'Germany',
  },
  GG: {
    id: 'CountryTranslations.GG',
    defaultMessage: 'Guernsey',
  },
  GI: {
    id: 'CountryTranslations.GI',
    defaultMessage: 'Gibraltar',
  },
  HN: {
    id: 'CountryTranslations.HN',
    defaultMessage: 'Honduras',
  },
  HK: {
    id: 'CountryTranslations.HK',
    defaultMessage: 'Hong Kong',
  },
  GD: {
    id: 'CountryTranslations.GD',
    defaultMessage: 'Grenada',
  },
  GL: {
    id: 'CountryTranslations.GL',
    defaultMessage: 'Greenland',
  },
  GR: {
    id: 'CountryTranslations.GR',
    defaultMessage: 'Greece',
  },
  GE: {
    id: 'CountryTranslations.GE',
    defaultMessage: 'Georgia',
  },
  GU: {
    id: 'CountryTranslations.GU',
    defaultMessage: 'Guam',
  },
  DK: {
    id: 'CountryTranslations.DK',
    defaultMessage: 'Denmark',
  },
  JE: {
    id: 'CountryTranslations.JE',
    defaultMessage: 'Jersey',
  },
  DJ: {
    id: 'CountryTranslations.DJ',
    defaultMessage: 'Djibouti',
  },
  DM: {
    id: 'CountryTranslations.DM',
    defaultMessage: 'Dominica',
  },
  DO: {
    id: 'CountryTranslations.DO',
    defaultMessage: 'Dominican Republic',
  },
  CD: {
    id: 'CountryTranslations.CD',
    defaultMessage: 'Democratic Republic of the Congo',
  },
  EU: {
    id: 'CountryTranslations.EU',
    defaultMessage: 'European Union',
  },
  EG: {
    id: 'CountryTranslations.EG',
    defaultMessage: 'Egypt',
  },
  ZM: {
    id: 'CountryTranslations.ZM',
    defaultMessage: 'Zambia',
  },
  EH: {
    id: 'CountryTranslations.EH',
    defaultMessage: 'Western Sahara',
  },
  ZW: {
    id: 'CountryTranslations.ZW',
    defaultMessage: 'Zimbabwe',
  },
  IL: {
    id: 'CountryTranslations.IL',
    defaultMessage: 'Israel',
  },
  IN: {
    id: 'CountryTranslations.IN',
    defaultMessage: 'India',
  },
  ID: {
    id: 'CountryTranslations.ID',
    defaultMessage: 'Indonesia',
  },
  JO: {
    id: 'CountryTranslations.JO',
    defaultMessage: 'Jordan',
  },
  IQ: {
    id: 'CountryTranslations.IQ',
    defaultMessage: 'Iraq',
  },
  IR: {
    id: 'CountryTranslations.IR',
    defaultMessage: 'Iran',
  },
  IE: {
    id: 'CountryTranslations.IE',
    defaultMessage: 'Ireland',
  },
  IS: {
    id: 'CountryTranslations.IS',
    defaultMessage: 'Iceland',
  },
  ES: {
    id: 'CountryTranslations.ES',
    defaultMessage: 'Spain',
  },
  IT: {
    id: 'CountryTranslations.IT',
    defaultMessage: 'Italy',
  },
  YE: {
    id: 'CountryTranslations.YE',
    defaultMessage: 'Yemen',
  },
  CV: {
    id: 'CountryTranslations.CV',
    defaultMessage: 'Cape Verde',
  },
  KZ: {
    id: 'CountryTranslations.KZ',
    defaultMessage: 'Kazakhstan',
  },
  KY: {
    id: 'CountryTranslations.KY',
    defaultMessage: 'Cayman Islands',
  },
  KH: {
    id: 'CountryTranslations.KH',
    defaultMessage: 'Cambodia',
  },
  CM: {
    id: 'CountryTranslations.CM',
    defaultMessage: 'Cameroon',
  },
  CA: {
    id: 'CountryTranslations.CA',
    defaultMessage: 'Canada',
  },
  QA: {
    id: 'CountryTranslations.QA',
    defaultMessage: 'Qatar',
  },
  KE: {
    id: 'CountryTranslations.KE',
    defaultMessage: 'Kenya',
  },
  CY: {
    id: 'CountryTranslations.CY',
    defaultMessage: 'Cyprus',
  },
  KG: {
    id: 'CountryTranslations.KG',
    defaultMessage: 'Kyrgyzstan',
  },
  KI: {
    id: 'CountryTranslations.KI',
    defaultMessage: 'Kiribati',
  },
  TW: {
    id: 'CountryTranslations.TW',
    defaultMessage: 'Taiwan',
  },
  KP: {
    id: 'CountryTranslations.KP',
    defaultMessage: 'North Korea',
  },
  CN: {
    id: 'CountryTranslations.CN',
    defaultMessage: 'China',
  },
  CC: {
    id: 'CountryTranslations.CC',
    defaultMessage: 'Cocos (Keeling) Islands',
  },
  CO: {
    id: 'CountryTranslations.CO',
    defaultMessage: 'Colombia',
  },
  KM: {
    id: 'CountryTranslations.KM',
    defaultMessage: 'Comoros',
  },
  CR: {
    id: 'CountryTranslations.CR',
    defaultMessage: 'Costa Rica',
  },
  CI: {
    id: 'CountryTranslations.CI',
    defaultMessage: "Côte d'Ivoire",
  },
  CU: {
    id: 'CountryTranslations.CU',
    defaultMessage: 'Cuba',
  },
  KW: {
    id: 'CountryTranslations.KW',
    defaultMessage: 'Kuwait',
  },
  CW: {
    id: 'CountryTranslations.CW',
    defaultMessage: 'Curaçao',
  },
  LA: {
    id: 'CountryTranslations.LA',
    defaultMessage: 'Laos',
  },
  LV: {
    id: 'CountryTranslations.LV',
    defaultMessage: 'Latvia',
  },
  LS: {
    id: 'CountryTranslations.LS',
    defaultMessage: 'Lesotho',
  },
  LR: {
    id: 'CountryTranslations.LR',
    defaultMessage: 'Liberia',
  },
  LB: {
    id: 'CountryTranslations.LB',
    defaultMessage: 'Lebanon',
  },
  LY: {
    id: 'CountryTranslations.LY',
    defaultMessage: 'Libya',
  },
  LT: {
    id: 'CountryTranslations.LT',
    defaultMessage: 'Lithuania',
  },
  LI: {
    id: 'CountryTranslations.LI',
    defaultMessage: 'Liechtenstein',
  },
  LU: {
    id: 'CountryTranslations.LU',
    defaultMessage: 'Luxembourg',
  },
  MU: {
    id: 'CountryTranslations.MU',
    defaultMessage: 'Mauritius',
  },
  MR: {
    id: 'CountryTranslations.MR',
    defaultMessage: 'Mauritania',
  },
  MG: {
    id: 'CountryTranslations.MG',
    defaultMessage: 'Madagascar',
  },
  YT: {
    id: 'CountryTranslations.YT',
    defaultMessage: 'Mayotte',
  },
  MO: {
    id: 'CountryTranslations.MO',
    defaultMessage: 'Macau',
  },
  MK: {
    id: 'CountryTranslations.MK',
    defaultMessage: 'North Macedonia',
  },
  MW: {
    id: 'CountryTranslations.MW',
    defaultMessage: 'Malawi',
  },
  MY: {
    id: 'CountryTranslations.MY',
    defaultMessage: 'Malaysia',
  },
  ML: {
    id: 'CountryTranslations.ML',
    defaultMessage: 'Mali',
  },
  MV: {
    id: 'CountryTranslations.MV',
    defaultMessage: 'Maldives',
  },
  MT: {
    id: 'CountryTranslations.MT',
    defaultMessage: 'Malta',
  },
  MA: {
    id: 'CountryTranslations.MA',
    defaultMessage: 'Morocco',
  },
  MQ: {
    id: 'CountryTranslations.MQ',
    defaultMessage: 'Martinique',
  },
  MH: {
    id: 'CountryTranslations.MH',
    defaultMessage: 'Marshall Islands',
  },
  MX: {
    id: 'CountryTranslations.MX',
    defaultMessage: 'Mexico',
  },
  FM: {
    id: 'CountryTranslations.FM',
    defaultMessage: 'Micronesia',
  },
  MZ: {
    id: 'CountryTranslations.MZ',
    defaultMessage: 'Mozambique',
  },
  MD: {
    id: 'CountryTranslations.MD',
    defaultMessage: 'Moldova',
  },
  MC: {
    id: 'CountryTranslations.MC',
    defaultMessage: 'Monaco',
  },
  MN: {
    id: 'CountryTranslations.MN',
    defaultMessage: 'Mongolia',
  },
  MS: {
    id: 'CountryTranslations.MS',
    defaultMessage: 'Montserrat',
  },
  MM: {
    id: 'CountryTranslations.MM',
    defaultMessage: 'Myanmar',
  },
  NA: {
    id: 'CountryTranslations.NA',
    defaultMessage: 'Namibia',
  },
  NR: {
    id: 'CountryTranslations.NR',
    defaultMessage: 'Nauru',
  },
  NP: {
    id: 'CountryTranslations.NP',
    defaultMessage: 'Nepal',
  },
  NE: {
    id: 'CountryTranslations.NE',
    defaultMessage: 'Niger',
  },
  NG: {
    id: 'CountryTranslations.NG',
    defaultMessage: 'Nigeria',
  },
  NL: {
    id: 'CountryTranslations.NL',
    defaultMessage: 'Netherlands',
  },
  NI: {
    id: 'CountryTranslations.NI',
    defaultMessage: 'Nicaragua',
  },
  NU: {
    id: 'CountryTranslations.NU',
    defaultMessage: 'Niue',
  },
  NZ: {
    id: 'CountryTranslations.NZ',
    defaultMessage: 'New Zealand',
  },
  NC: {
    id: 'CountryTranslations.NC',
    defaultMessage: 'New Caledonia',
  },
  NO: {
    id: 'CountryTranslations.NO',
    defaultMessage: 'Norway',
  },
  AE: {
    id: 'CountryTranslations.AE',
    defaultMessage: 'United Arab Emirates',
  },
  OM: {
    id: 'CountryTranslations.OM',
    defaultMessage: 'Oman',
  },
  BV: {
    id: 'CountryTranslations.BV',
    defaultMessage: 'Bouvet Island',
  },
  IM: {
    id: 'CountryTranslations.IM',
    defaultMessage: 'Isle of Man',
  },
  CK: {
    id: 'CountryTranslations.CK',
    defaultMessage: 'Cook Islands',
  },
  NF: {
    id: 'CountryTranslations.NF',
    defaultMessage: 'Norfolk Island',
  },
  CX: {
    id: 'CountryTranslations.CX',
    defaultMessage: 'Christmas Island',
  },
  PN: {
    id: 'CountryTranslations.PN',
    defaultMessage: 'Pitcairn',
  },
  SH: {
    id: 'CountryTranslations.SH',
    defaultMessage: 'Saint Helena',
  },
  PK: {
    id: 'CountryTranslations.PK',
    defaultMessage: 'Pakistan',
  },
  PW: {
    id: 'CountryTranslations.PW',
    defaultMessage: 'Palau',
  },
  PS: {
    id: 'CountryTranslations.PS',
    defaultMessage: 'Palestine',
  },
  PA: {
    id: 'CountryTranslations.PA',
    defaultMessage: 'Panama',
  },
  PG: {
    id: 'CountryTranslations.PG',
    defaultMessage: 'Papua New Guinea',
  },
  PY: {
    id: 'CountryTranslations.PY',
    defaultMessage: 'Paraguay',
  },
  PE: {
    id: 'CountryTranslations.PE',
    defaultMessage: 'Peru',
  },
  PL: {
    id: 'CountryTranslations.PL',
    defaultMessage: 'Poland',
  },
  PT: {
    id: 'CountryTranslations.PT',
    defaultMessage: 'Portugal',
  },
  PR: {
    id: 'CountryTranslations.PR',
    defaultMessage: 'Puerto Rico',
  },
  CG: {
    id: 'CountryTranslations.CG',
    defaultMessage: 'Republic of the Congo',
  },
  KR: {
    id: 'CountryTranslations.KR',
    defaultMessage: 'South Korea',
  },
  RE: {
    id: 'CountryTranslations.RE',
    defaultMessage: 'Réunion',
  },
  RU: {
    id: 'CountryTranslations.RU',
    defaultMessage: 'Russia',
  },
  RW: {
    id: 'CountryTranslations.RW',
    defaultMessage: 'Rwanda',
  },
  RO: {
    id: 'CountryTranslations.RO',
    defaultMessage: 'Romania',
  },
  SV: {
    id: 'CountryTranslations.SV',
    defaultMessage: 'El Salvador',
  },
  WS: {
    id: 'CountryTranslations.WS',
    defaultMessage: 'Samoa',
  },
  SM: {
    id: 'CountryTranslations.SM',
    defaultMessage: 'San Marino',
  },
  ST: {
    id: 'CountryTranslations.ST',
    defaultMessage: 'São Tomé and Príncipe',
  },
  SA: {
    id: 'CountryTranslations.SA',
    defaultMessage: 'Saudi Arabia',
  },
  SZ: {
    id: 'CountryTranslations.SZ',
    defaultMessage: 'Eswatini',
  },
  MP: {
    id: 'CountryTranslations.MP',
    defaultMessage: 'Northern Mariana Islands',
  },
  SC: {
    id: 'CountryTranslations.SC',
    defaultMessage: 'Seychelles',
  },
  BL: {
    id: 'CountryTranslations.BL',
    defaultMessage: 'Saint Barthélemy',
  },
  MF: {
    id: 'CountryTranslations.MF',
    defaultMessage: 'Saint Martin',
  },
  PM: {
    id: 'CountryTranslations.PM',
    defaultMessage: 'Saint Pierre and Miquelon',
  },
  SN: {
    id: 'CountryTranslations.SN',
    defaultMessage: 'Senegal',
  },
  VC: {
    id: 'CountryTranslations.VC',
    defaultMessage: 'Saint Vincent and the Grenadines',
  },
  KN: {
    id: 'CountryTranslations.KN',
    defaultMessage: 'Saint Kitts and Nevis',
  },
  LC: {
    id: 'CountryTranslations.LC',
    defaultMessage: 'Saint Lucia',
  },
  RS: {
    id: 'CountryTranslations.RS',
    defaultMessage: 'Serbia',
  },
  SG: {
    id: 'CountryTranslations.SG',
    defaultMessage: 'Singapore',
  },
  SX: {
    id: 'CountryTranslations.SX',
    defaultMessage: 'Sint Maarten',
  },
  SY: {
    id: 'CountryTranslations.SY',
    defaultMessage: 'Syria',
  },
  SK: {
    id: 'CountryTranslations.SK',
    defaultMessage: 'Slovakia',
  },
  SI: {
    id: 'CountryTranslations.SI',
    defaultMessage: 'Slovenia',
  },
  SB: {
    id: 'CountryTranslations.SB',
    defaultMessage: 'Solomon Islands',
  },
  SO: {
    id: 'CountryTranslations.SO',
    defaultMessage: 'Somalia',
  },
  SD: {
    id: 'CountryTranslations.SD',
    defaultMessage: 'Sudan',
  },
  SR: {
    id: 'CountryTranslations.SR',
    defaultMessage: 'Suriname',
  },
  US: {
    id: 'CountryTranslations.US',
    defaultMessage: 'United States',
  },
  SL: {
    id: 'CountryTranslations.SL',
    defaultMessage: 'Sierra Leone',
  },
  TJ: {
    id: 'CountryTranslations.TJ',
    defaultMessage: 'Tajikistan',
  },
  TH: {
    id: 'CountryTranslations.TH',
    defaultMessage: 'Thailand',
  },
  TZ: {
    id: 'CountryTranslations.TZ',
    defaultMessage: 'Tanzania',
  },
  TC: {
    id: 'CountryTranslations.TC',
    defaultMessage: 'Turks and Caicos Islands',
  },
  TG: {
    id: 'CountryTranslations.TG',
    defaultMessage: 'Togo',
  },
  TK: {
    id: 'CountryTranslations.TK',
    defaultMessage: 'Tokelau',
  },
  TO: {
    id: 'CountryTranslations.TO',
    defaultMessage: 'Tonga',
  },
  TT: {
    id: 'CountryTranslations.TT',
    defaultMessage: 'Trinidad and Tobago',
  },
  TV: {
    id: 'CountryTranslations.TV',
    defaultMessage: 'Tuvalu',
  },
  TN: {
    id: 'CountryTranslations.TN',
    defaultMessage: 'Tunisia',
  },
  TM: {
    id: 'CountryTranslations.TM',
    defaultMessage: 'Turkmenistan',
  },
  TR: {
    id: 'CountryTranslations.TR',
    defaultMessage: 'Turkey',
  },
  UG: {
    id: 'CountryTranslations.UG',
    defaultMessage: 'Uganda',
  },
  UZ: {
    id: 'CountryTranslations.UZ',
    defaultMessage: 'Uzbekistan',
  },
  UA: {
    id: 'CountryTranslations.UA',
    defaultMessage: 'Ukraine',
  },
  WF: {
    id: 'CountryTranslations.WF',
    defaultMessage: 'Wallis and Futuna',
  },
  UY: {
    id: 'CountryTranslations.UY',
    defaultMessage: 'Uruguay',
  },
  FO: {
    id: 'CountryTranslations.FO',
    defaultMessage: 'Faroe Islands',
  },
  FJ: {
    id: 'CountryTranslations.FJ',
    defaultMessage: 'Fiji',
  },
  PH: {
    id: 'CountryTranslations.PH',
    defaultMessage: 'Philippines',
  },
  FI: {
    id: 'CountryTranslations.FI',
    defaultMessage: 'Finland',
  },
  FK: {
    id: 'CountryTranslations.FK',
    defaultMessage: 'Falkland Islands',
  },
  FR: {
    id: 'CountryTranslations.FR',
    defaultMessage: 'France',
  },
  PF: {
    id: 'CountryTranslations.PF',
    defaultMessage: 'French Polynesia',
  },
  TF: {
    id: 'CountryTranslations.TF',
    defaultMessage: 'French Southern Territories',
  },
  HM: {
    id: 'CountryTranslations.HM',
    defaultMessage: 'Heard Island and McDonald Islands',
  },
  HR: {
    id: 'CountryTranslations.HR',
    defaultMessage: 'Croatia',
  },
  CF: {
    id: 'CountryTranslations.CF',
    defaultMessage: 'Central African Republic',
  },
  TD: {
    id: 'CountryTranslations.TD',
    defaultMessage: 'Chad',
  },
  ME: {
    id: 'CountryTranslations.ME',
    defaultMessage: 'Montenegro',
  },
  CZ: {
    id: 'CountryTranslations.CZ',
    defaultMessage: 'Czech Republic',
  },
  CL: {
    id: 'CountryTranslations.CL',
    defaultMessage: 'Chile',
  },
  CH: {
    id: 'CountryTranslations.CH',
    defaultMessage: 'Switzerland',
  },
  SE: {
    id: 'CountryTranslations.SE',
    defaultMessage: 'Sweden',
  },
  SJ: {
    id: 'CountryTranslations.SJ',
    defaultMessage: 'Svalbard and Jan Mayen',
  },
  LK: {
    id: 'CountryTranslations.LK',
    defaultMessage: 'Sri Lanka',
  },
  EC: {
    id: 'CountryTranslations.EC',
    defaultMessage: 'Ecuador',
  },
  GQ: {
    id: 'CountryTranslations.GQ',
    defaultMessage: 'Equatorial Guinea',
  },
  ER: {
    id: 'CountryTranslations.ER',
    defaultMessage: 'Eritrea',
  },
  EE: {
    id: 'CountryTranslations.EE',
    defaultMessage: 'Estonia',
  },
  ET: {
    id: 'CountryTranslations.ET',
    defaultMessage: 'Ethiopia',
  },
  ZA: {
    id: 'CountryTranslations.ZA',
    defaultMessage: 'South Africa',
  },
  GS: {
    id: 'CountryTranslations.GS',
    defaultMessage: 'South Georgia and the South Sandwich Islands',
  },
  SS: {
    id: 'CountryTranslations.SS',
    defaultMessage: 'South Sudan',
  },
  JM: {
    id: 'CountryTranslations.JM',
    defaultMessage: 'Jamaica',
  },
  JP: {
    id: 'CountryTranslations.JP',
    defaultMessage: 'Japan',
  },
})
