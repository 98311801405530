import {DonglePayBackendResponse, DonglePayResponse} from "../../../../types/dongle/pay";

export const mapDonglePayBackend = (
  item: DonglePayBackendResponse,
): DonglePayResponse => {
  const { status, payment_credentials } = item.data

  return {
    status: status,
    paymentCredentials: payment_credentials
      ? {
        paymentProviderId: payment_credentials.service,
        ...payment_credentials,
      }
      : undefined,
  }
}
