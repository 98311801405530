import { defineMessages, useIntl } from 'react-intl'
import { Sticker } from '../../../shared/ui-kit-2/data-display/sticker/Sticker'
import { MAILING_STATUSES } from './constants/MailingStatuses'

const UseMailingStatusesMessages = defineMessages({
  draft: {
    id: 'UseMailingStatusesMessages.draft',
    defaultMessage: 'Draft',
  },
  planned: {
    id: 'UseMailingStatusesMessages.planned',
    defaultMessage: 'Planned',
  },
  inProcess: {
    id: 'UseMailingStatusesMessages.inProcess',
    defaultMessage: 'In process',
  },
  ended: {
    id: 'UseMailingStatusesMessages.ended',
    defaultMessage: 'Ended',
  },
  cancelled: {
    id: 'UseMailingStatusesMessages.cancelled',
    defaultMessage: 'Cancelled',
  },
  unknown: {
    id: 'UseMailingStatusesMessages.unknown',
    defaultMessage: 'Unknown',
  },
})

export const useMailingStatuses = () => {
  const intl = useIntl()

  const getMailingStatusStickerComponent = (mailingStatusId: number) => {
    switch (mailingStatusId) {
      case MAILING_STATUSES.DRAFT:
        return (
          <Sticker
            text={intl.formatMessage(UseMailingStatusesMessages.draft)}
            color={'purple'}
          />
        )
      case MAILING_STATUSES.PLANNED:
        return (
          <Sticker
            text={intl.formatMessage(UseMailingStatusesMessages.planned)}
            color={'yellow'}
          />
        )
      case MAILING_STATUSES.IN_PROCESS:
        return (
          <Sticker
            text={intl.formatMessage(UseMailingStatusesMessages.inProcess)}
            color={'blue'}
          />
        )
      case MAILING_STATUSES.ENDED:
        return (
          <Sticker
            text={intl.formatMessage(UseMailingStatusesMessages.ended)}
            color={'lightGreen'}
          />
        )
      case MAILING_STATUSES.CANCELLED:
        return (
          <Sticker
            text={intl.formatMessage(UseMailingStatusesMessages.cancelled)}
            color={'red'}
          />
        )
      default:
        return (
          <Sticker
            text={intl.formatMessage(UseMailingStatusesMessages.unknown)}
            color={'red'}
          />
        )
    }
  }

  return { getMailingStatusStickerComponent }
}
