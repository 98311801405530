import { useEffect } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useDeleteSmsSubmodal } from '../_hooks/use-bool-action-submodal/templates/useDeleteSmsSubmodal'
import { useReplyToSms } from '../_hooks/use-reply-to-sms/useReplyToSms'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { CommonButtonMessages } from '../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { SmsListResponse } from '../../../sdk/datagates/types/sms/_crud/list'
import { canSmsBeReplied } from '../../../sdk/hooks/use-smses/useSmses'
import { getSmsCenter } from '../../../shared/lib/utils/get-sms-center/getSmsCenter'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import { LC } from '../../../tests/e2e/locators'
import { SNACKBAR_TYPES } from '../../snackbar/SnackbarProvider'
import { SmsViewInfo } from './components/sms-view-info/SmsViewInfo'
import { SmsViewRedirections } from './components/sms-view-redirections/SmsViewRedirections'
import { SmsViewRepliedFromSms } from './components/sms-view-replied-from-sms/SmsViewRepliedFromSms'
import styles from './styles.module.scss'

const SmsViewModalMessages = defineMessages({
  title: {
    id: 'SmsViewModalMessages.title',
    defaultMessage: 'SMS details',
  },
  columnMessageId: {
    id: 'SmsViewModalMessages.columnMessageId',
    defaultMessage: 'Message ID',
  },
  columnDevice: {
    id: 'SmsViewModalMessages.columnDevice',
    defaultMessage: 'Device',
  },
  columnSender: {
    id: 'SmsViewModalMessages.columnSender',
    defaultMessage: 'Sender',
  },
  columnReceiver: {
    id: 'SmsViewModalMessages.columnReceiver',
    defaultMessage: 'Receiver',
  },
  columnSmsCenter: {
    id: 'SmsViewModalMessages.columnSmsCenter',
    defaultMessage: 'SMS Center',
  },
  columnRedirect: {
    id: 'SmsViewModalMessages.columnRedirect',
    defaultMessage: 'Redirect',
  },
  columnDate: {
    id: 'SmsViewModalMessages.columnDate',
    defaultMessage: 'Date',
  },
  columnText: {
    id: 'SmsViewModalMessages.columnText',
    defaultMessage: 'Text',
  },
  smsOpeningError: {
    id: 'SmsViewModalMessages.smsOpeningError',
    defaultMessage: 'An error occurred while opening SMS',
  },
  smsc: {
    id: 'SmsViewModalMessages.smsc',
    defaultMessage: 'SMSC',
  },
})

export const SmsViewModal = () => {
  const { handleHideModal, props } = useModal()

  const { handleFetch, onDeleteSms } = props
  const sms = props.sms ? (props.sms as SmsListResponse['smses'][0]) : null
  const forceAction = props.forceAction
    ? (props.forceAction as 'reply' | 'delete' | null)
    : null

  const { handleOpenSnackbar } = useSnackbar()
  const intl = useIntl()

  const afterAction = async () => {
    await handleFetch?.()
    handleHideModal()
  }

  const { setIsOpen: setIsReplyModalOpen, component } = useReplyToSms({
    sms: sms,
    afterSuccessCallback: async () => {
      await afterAction()
    },
  })

  const { setIsDeleteSubmodalOpen, deleteSubmodalComponent } =
    useDeleteSmsSubmodal({
      onDeleteSms: async () => {
        await onDeleteSms?.()
        await afterAction()
      },
    })

  useEffect(() => {
    switch (forceAction) {
      case 'reply':
        setIsReplyModalOpen(true)
        break
      case 'delete':
        setIsDeleteSubmodalOpen(true)
        break
    }
  }, [])

  if (!sms) {
    handleOpenSnackbar({
      type: SNACKBAR_TYPES.error,
      text: intl.formatMessage(SmsViewModalMessages.smsOpeningError),
    })
    handleHideModal()
    return null
  }

  const getAction = () => {
    if (canSmsBeReplied(sms)) {
      return (
        <Button
          text={intl.formatMessage(CommonButtonMessages.reply)}
          onClick={() => setIsReplyModalOpen(true)}
          icon={'reply'}
          additionalClassNames={[styles.ReplyBtnAdditional]}
        />
      )
    }

    return (
      <Button
        text={intl.formatMessage(CommonButtonMessages.close)}
        onClick={handleHideModal}
        variant={'blackTextBlackBorderOutlined'}
      />
    )
  }

  return (
    <Drawer
      isOpen={true}
      close={handleHideModal}
      title={intl.formatMessage(SmsViewModalMessages.title)}
      additionalClassNames={[styles.DrawerAdditional]}
    >
      <div className={styles.Container}>
        <SmsViewInfo sms={sms} />

        <div className={styles.ContainerSmsc}>
          <div className={styles.Title}>
            {intl.formatMessage(SmsViewModalMessages.smsc)}
          </div>
          <div>{getSmsCenter(sms?.smsCenter)}</div>
        </div>

        <SmsViewRedirections redirectedTo={sms.redirectedTo} />

        <SmsViewRepliedFromSms repliedFromSms={sms.repliedFromSms} />

        <div className={styles.Actions}>
          <Button
            text={intl.formatMessage(CommonButtonMessages.delete)}
            onClick={() => setIsDeleteSubmodalOpen(true)}
            variant={'danger'}
            icon={'trash'}
            testId={LC.MODAL.DELETE}
          />
          {getAction()}
        </div>
      </div>

      {component}
      {deleteSubmodalComponent}
    </Drawer>
  )
}
