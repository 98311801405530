import React from 'react'
import { FormApi } from 'final-form'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useSiteVersion } from '../../../../../../../app/hooks/useSiteVersion'
import { useSnackbar } from '../../../../../../../app/hooks/useSnackbar'
import { SNACKBAR_TYPES } from '../../../../../../../layouts/snackbar/SnackbarProvider'
import { callbackRequestFormDatagate } from '../../../../../../../sdk/datagates/api/callback'
import { useBackendErrorCodes } from '../../../../../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import {
  composeValidators,
  useFormRules,
} from '../../../../../../../shared/lib/form/form-rules'
import { getErrorMessage } from '../../../../../../../shared/lib/utils/get-error-message/getErrorMessage'
import { LandingButton } from '../../../../../../../shared/ui-kit-2/inputs/landing-button/LandingButton'
import { ContactUsInputField } from '../contact-us-input-field/ContactUsInputField'
import styles from './styles.module.scss'

interface ContactUsFormType {
  email: string
  name: string
  message: string
}

export const ContactUsFormMessages = defineMessages({
  emailPlaceholder: {
    id: 'ContactUsFormMessages.emailPlaceholder',
    defaultMessage: 'Your Email',
  },
  namePlaceholder: {
    id: 'ContactUsFormMessages.namePlaceholder',
    defaultMessage: 'What’s your name',
  },
  messagePlaceholder: {
    id: 'ContactUsFormMessages.messagePlaceholder',
    defaultMessage: 'Tell us about your question',
  },
  submit: {
    id: 'ContactUsFormMessages.submit',
    defaultMessage: 'Send',
  },
  success: {
    id: 'ContactUsFormMessages.success',
    defaultMessage: 'Request is sent successfully',
  },
  error: {
    id: 'ContactUsFormMessages.error',
    defaultMessage: 'An error occurred while sending request',
  },
})

export const ContactUsForm = () => {
  const intl = useIntl()
  const { resolveBackendError } = useBackendErrorCodes()
  const { ruleRequired, ruleEmail } = useFormRules()
  const { handleOpenSnackbar } = useSnackbar()
  const { siteDomainId } = useSiteVersion()

  const onSubmit = async (
    values: ContactUsFormType,
    form: FormApi<ContactUsFormType, Partial<ContactUsFormType>>,
  ) => {
    try {
      await callbackRequestFormDatagate({
        email: values.email,
        name: values.name,
        message: values.message,
        site_domain_id: siteDomainId,
      })

      form.restart({})

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(ContactUsFormMessages.success),
      })
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(ContactUsFormMessages.error),
        ),
      })
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} className={styles.Form}>
          <div className={styles.Row}>
            <ContactUsInputField
              name={'name'}
              validate={ruleRequired()}
              placeholder={intl.formatMessage(
                ContactUsFormMessages.namePlaceholder,
              )}
            />
            <ContactUsInputField
              name={'email'}
              validate={composeValidators(ruleRequired(), ruleEmail())}
              placeholder={intl.formatMessage(
                ContactUsFormMessages.emailPlaceholder,
              )}
            />
          </div>
          <ContactUsInputField
            name={'message'}
            validate={ruleRequired()}
            placeholder={intl.formatMessage(
              ContactUsFormMessages.messagePlaceholder,
            )}
          />
          <LandingButton
            text={intl.formatMessage(ContactUsFormMessages.submit)}
            onClick={handleSubmit}
            loading={submitting}
            variant={'greenTextFilled'}
            additionalClassNames={[styles.Button]}
          />
        </form>
      )}
    />
  )
}
