import { useNavigate } from "react-router";
import { useSiteVersion } from '../../app/hooks/useSiteVersion'
import { Home01 } from './components/sections/home-01/Home01'
import { Home02 } from './components/sections/home-02/Home02'
import { Home03 } from './components/sections/home-03/Home03'
import { Home04 } from './components/sections/home-04/Home04'
import { Home05 } from './components/sections/home-05/Home05'
import { Home06 } from './components/sections/home-06/Home06'
import { Home07 } from './components/sections/home-07/Home07'
import { Home08 } from './components/sections/home-08/Home08'
import { Home09 } from './components/sections/home-09/Home09'
import { useRootPath } from "../../shared/lib/hooks/useRootPath";
import styles from './styles.module.scss'

export const HomePage = () => {
  const { siteVersion } = useSiteVersion();
  const navigate = useNavigate();
  const rootPath = useRootPath();

  if (siteVersion === 'ru') {
    navigate(rootPath);
  }

  const renderMap = [
    <Home01 />,
    <Home02 />,
    <Home03 />,
    <Home04 />,
    <Home05 />,
    <Home06 />,
    <Home07 />,
    <Home08 />,
    <Home09 />,
  ];

  return <div className={styles.HomePage}>{renderMap.map((home) => home)}</div>
}
