import React, { FC, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../../../app/hooks/useModal'
import { useSnackbar } from '../../../../../../../../app/hooks/useSnackbar'
import { LoadingContent } from '../../../../../../../../layouts/loading-content/LoadingContent'
import { MODAL_TYPES } from '../../../../../../../../layouts/modals/ModalsProvider'
import { SNACKBAR_TYPES } from '../../../../../../../../layouts/snackbar/SnackbarProvider'
import {
  userGetDatagate,
  userUpdateDatagate,
} from '../../../../../../../../sdk/datagates/api/user'
import { useBackendErrorCodes } from '../../../../../../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { USER_TYPE_IDS } from '../../../../../../../../sdk/datagates/types/user/_crud/get'
import { useCountrySelectOptions } from '../../../../../../../../sdk/hooks/use-countries/useCountrySelectOptions'
import { useDevices } from '../../../../../../../../sdk/hooks/use-devices/useDevices'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { getErrorMessage } from '../../../../../../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Button } from '../../../../../../../../shared/ui-kit-2/inputs/button/Button'
import { SegmentedControlOptionProps } from '../../../../../../../../shared/ui-kit-2/inputs/segmented-control/components/SegmentedControlOption'
import { SegmentedControl } from '../../../../../../../../shared/ui-kit-2/inputs/segmented-control/SegmentedControl'
import { ItemWrapper } from '../../../../../../../../shared/ui-kit/data-display/ItemWrapper/ItemWrapper'
import { useAppDispatch } from '../../../../../../../../store'
import { setUser } from '../../../../../../../../store/reducers/user'
import styles from './styles.module.scss'
import {DeliveryAddressProfileFormDesktop} from "./components/deliveryAddressProfileFormDesktop/DeliveryAddressProfileFormDesktop";
import {Checkbox} from "../../../../../../../../shared/ui-kit-2/inputs/checkbox/Checkbox";
import {DesktopGrid} from "./components/desktopGrid/DesktopGrid";
import {MobileGrid} from "./components/mobileGrid/MobileGrid";
import {compareAddresses} from "../../../../../../../../shared/lib/utils/compare-addresses";

export type ProfileFormType = {
  firstName: string
  lastName: string
  email: string
  companyName?: string
  userPhone?: string
  companyPhone?: string
  nationalNumber?: string
  vatNumber?: string
  billingEmail?: string
  countryId: string
  postalCode?: string
  streetAddress1: string
  streetAddress2?: string
  city?: string
  region?: string
  legal?: {
    streetAddress1?: string
    streetAddress2?: string
    postalCode?: string
    countryId?: string
    city?: string
    region?: string
  }
}

export const ProfileFormMessages = defineMessages({
  accountInfo: {
    id: 'ProfileFormMessages.accountInfo',
    defaultMessage: 'Account info',
  },
  devicesCount: {
    id: 'ProfileFormMessages.devicesCount',
    defaultMessage: 'Devices: {count}',
  },
  onlineCount: {
    id: 'ProfileFormMessages.onlineCount',
    defaultMessage: 'Online: {count}',
  },
  accountTypeLabel: {
    id: 'ProfileFormMessages.accountTypeLabel',
    defaultMessage: 'Account type:',
  },
  accountTypeOrganization: {
    id: 'ProfileFormMessages.accountTypeOrganization',
    defaultMessage: 'Organization',
  },
  accountTypePerson: {
    id: 'ProfileFormMessages.accountTypePerson',
    defaultMessage: 'Individual',
  },
  firstNameLabel: {
    id: 'ProfileFormMessages.firstNameLabel',
    defaultMessage: 'First name',
  },
  lastNameLabel: {
    id: 'ProfileFormMessages.lastNameLabel',
    defaultMessage: 'Last name',
  },
  emailLabel: {
    id: 'ProfileFormMessages.emailLabel',
    defaultMessage: 'Email',
  },
  companyNameLabel: {
    id: 'ProfileFormMessages.companyNameLabel',
    defaultMessage: 'Company name',
  },
  companyPhoneLabel: {
    id: 'ProfileFormMessages.companyPhoneLabel',
    defaultMessage: 'Company phone',
  },
  phoneNumberLabel: {
    id: 'ProfileFormMessages.phoneNumberLabel',
    defaultMessage: 'Phone Number',
  },
  vatNumberLabel: {
    id: 'ProfileFormMessages.vatNumberLabel',
    defaultMessage: 'VAT Number',
  },
  billingEmailLabel: {
    id: 'ProfileFormMessages.billingEmailLabel',
    defaultMessage: 'Billing email',
  },
  countryLabel: {
    id: 'ProfileFormMessages.countryLabel',
    defaultMessage: 'Country',
  },
  countryPlaceholder: {
    id: 'ProfileFormMessages.countryPlaceholder',
    defaultMessage: 'Select country',
  },
  postalCodeLabel: {
    id: 'ProfileFormMessages.postalCodeLabel',
    defaultMessage: 'Postal code',
  },
  addressLine1Label: {
    id: 'ProfileFormMessages.addressLine1Label',
    defaultMessage: 'Address line 1',
  },
  addressLine2Label: {
    id: 'ProfileFormMessages.addressLine2Label',
    defaultMessage: 'Address line 2',
  },
  cityLabel: {
    id: 'ProfileFormMessages.cityLabel',
    defaultMessage: 'City',
  },
  regionLabel: {
    id: 'ProfileFormMessages.regionLabel',
    defaultMessage: 'Region',
  },
  cancelLabel: {
    id: 'ProfileFormMessages.cancelLabel',
    defaultMessage: 'Cancel',
  },
  submitLabel: {
    id: 'ProfileFormMessages.submitLabel',
    defaultMessage: 'Save',
  },
  fetchUserError: {
    id: 'ProfileFormMessages.fetchUserError',
    defaultMessage: 'An error occurred while fetching user',
  },
  success: {
    id: 'ProfileFormMessages.success',
    defaultMessage: 'User profile is successfully updated',
  },
  error: {
    id: 'ProfileFormMessages.error',
    defaultMessage: 'An error occurred while updating user profile',
  },
  changePassword: {
    id: 'ProfileFormMessages.changePassword',
    defaultMessage: 'Change password',
  },
  edit: {
    id: 'ProfileFormMessages.edit',
    defaultMessage: 'Edit',
  },
  deliveryCheckBoxLabel: {
    id: 'ProfileFormMessages.deliveryCheckBoxLabel',
    defaultMessage: 'Delivery address is the same',
  },
  legalAddressTitle: {
    id: 'ProfileFormMessages.legalAddressTitle',
    defaultMessage: 'Legal Address',
  },
  deliveryAddressTitle: {
    id: 'ProfileFormMessages.deliveryAddressTitle',
    defaultMessage: 'Delivery Address',
  },
  deliverySameMobile: {
    id: 'ProfileFormMessages.deliverySameMobile',
    defaultMessage: 'Delivery Address – {same}',
  },
  sameMobile: {
    id: 'ProfileFormMessages.sameMobile',
    defaultMessage: 'same',
  }
})

export const ProfileForm: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { resolveBackendError } = useBackendErrorCodes();
  const { handleOpenSnackbar } = useSnackbar();
  const isMobile = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK);

  const {
    devices: devices,
    totalCount: devicesCount,
    loading: fetchActiveDevicesLoading,
  } = useDevices({
    page: 0,
    limit: 0,
    takeAll: true,
    isActive: true,
  });

  const { countryOptions, loading: fetchCountriesLoading } = useCountrySelectOptions();

  const [initialValues, setInitialValues] = useState<Partial<ProfileFormType>>({});
  const [userInitialPhone, setUserInitialPhone] = useState<string | null>(null);
  const [fetchUserLoading, setFetchUserLoading] = useState<boolean>(false);
  const [userType, setUserType] = useState(USER_TYPE_IDS.NATURAL_PERSON.toString());
  const [isLegalAddressSame, setIsLegalAddressSame] = useState<boolean>(true);
  const { handleOpenModal } = useModal();
  const isOrganization = useMemo(
    () => userType === USER_TYPE_IDS.ORGANIZATION.toString(),
    [userType],
  );
  const onlineDevicesCount = useMemo(
    () => devices.filter((d) => d.isOnline).length,
    [fetchActiveDevicesLoading],
  );

  const fetchDefaultPageData = async () => {
    try {
      setFetchUserLoading(true);
      const { data } = await userGetDatagate();
      const { userPhone, companyPhone } = data;
      setUserInitialPhone(userPhone ?? null);
      let initialValues: {
        firstName: string | undefined;
        lastName: string | undefined;
        email: string;
        companyName: string | undefined;
        vatNumber: string | undefined;
        billingEmail: string | undefined;
        countryId: string | undefined;
        postalCode: string | undefined;
        streetAddress1: string | undefined;
        streetAddress2: string | undefined;
        userPhone: string | undefined;
        companyPhone: string | undefined;
        city: string | undefined;
        region: string | undefined;
        legal?: {
          countryId: string | undefined;
          postalCode: string | undefined;
          streetAddress1: string | undefined;
          streetAddress2: string | undefined;
          city: string | undefined;
          region: string | undefined;
        };
      } = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        companyName: data.companyName,
        userPhone: data.userPhone,
        companyPhone: data.companyPhone,
        vatNumber: data.vatNumber,
        billingEmail: data.billingEmail,
        countryId: data.countryId?.toString(),
        postalCode: data.postalCode,
        streetAddress1: data.streetAddress1,
        streetAddress2: data.streetAddress2,
        city: data.city,
        region: data.region,
      };

      let isSameAddress = true;

      if (data?.legal) {
        const legalAddress = {
          countryId: data.legal.countryId?.toString() ?? data.countryId?.toString(),
          postalCode: data.legal.postalCode,
          streetAddress1: data.legal.streetAddress1,
          streetAddress2: data.legal.streetAddress2,
          city: data.legal.city,
          region: data.legal.region,
        };

        initialValues = {
          ...initialValues,
          legal: legalAddress,
        };

        const primaryAddress = {
          countryId: data.countryId?.toString(),
          postalCode: data.postalCode,
          streetAddress1: data.streetAddress1,
          streetAddress2: data.streetAddress2,
          city: data.city,
          region: data.region,
        };

        isSameAddress = compareAddresses(legalAddress, primaryAddress);
      }

      setIsLegalAddressSame(isSameAddress);
      setInitialValues(initialValues);

      setUserType(
        data.userTypeId?.toString() ?? USER_TYPE_IDS.ORGANIZATION.toString(),
      );

      dispatch(setUser(data));
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(ProfileFormMessages.fetchUserError),
        ),
      });
    } finally {
      setFetchUserLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchDefaultPageData();
    })();
  }, []);

  const onSetNewProfile = async (values: ProfileFormType) => {
    try {
      !isLegalAddressSame
        ? await userUpdateDatagate({
          user_type_id: +userType,
          name: values.firstName,
          last_name: values.lastName,
          company_name: isOrganization ? values.companyName : '',
          company_phone: isOrganization ? values.companyPhone : '',
          phone: values.userPhone,
          billing_email: values.billingEmail ?? '',
          vat_number: isOrganization ? values.vatNumber : '',
          country_id: +values.countryId,
          postal_code: values.postalCode ?? '',
          street_address_1: values.streetAddress1 ?? '',
          street_address_2: values.streetAddress2 ?? '',
          city: values.city ?? '',
          region: values.region ?? '',
          legal_street_address_1: values?.legal?.streetAddress1 ?? '',
          legal_street_address_2: values?.legal?.streetAddress2 ?? '',
          legal_postal_code: values?.legal?.postalCode ?? '',
          legal_country_id: values.legal?.countryId !== undefined ? +values.legal.countryId : undefined,
          legal_city: values?.legal?.city ?? '',
          legal_region: values?.legal?.region ?? '',
        })
        : await userUpdateDatagate({
          user_type_id: +userType,
          name: values.firstName,
          last_name: values.lastName,
          company_name: isOrganization ? values.companyName : '',
          company_phone: isOrganization ? values.companyPhone : '',
          phone: values.userPhone,
          billing_email: values.billingEmail ?? '',
          vat_number: isOrganization ? values.vatNumber : '',
          country_id: +values.countryId,
          postal_code: values.postalCode ?? '',
          street_address_1: values.streetAddress1 ?? '',
          street_address_2: values.streetAddress2 ?? '',
          city: values.city ?? '',
          region: values.region ?? '',
          legal_street_address_1: values.streetAddress1 ?? '',
          legal_street_address_2: values.streetAddress2 ?? '',
          legal_postal_code: values.postalCode ?? '',
          legal_country_id: +values.countryId,
          legal_city: values.city ?? '',
          legal_region: values.region ?? '',
        });

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(ProfileFormMessages.success),
      });

      void fetchDefaultPageData();
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(ProfileFormMessages.error),
        ),
      });
    }
  };

  const handleChangeTypeProfile = (userType: string) => {
    handleOpenModal({
      type: MODAL_TYPES.CHANGE_PROFILE_TYPE,
      props: {
        onConfirm: () => setUserType(userType),
      },
    });
  };

  const accountTypeOptions: SegmentedControlOptionProps[] = [
    {
      label: intl.formatMessage(ProfileFormMessages.accountTypePerson),
      value: USER_TYPE_IDS.NATURAL_PERSON.toString(),
      comparedValue: userType,
      onClick: () => handleChangeTypeProfile(USER_TYPE_IDS.NATURAL_PERSON.toString()),
    },
    {
      label: intl.formatMessage(ProfileFormMessages.accountTypeOrganization),
      value: USER_TYPE_IDS.ORGANIZATION.toString(),
      comparedValue: userType,
      onClick: () => handleChangeTypeProfile(USER_TYPE_IDS.ORGANIZATION.toString()),
    },
  ];

  const handleChangePassword = () => {
    handleOpenModal({ type: MODAL_TYPES.CHANGE_PASSWORD });
  };

  const handleEdit = () => {
    handleOpenModal({
      type: MODAL_TYPES.EDIT_PROFILE,
      props: {
        initialValues,
        onClose: fetchDefaultPageData,
        userInitialPhone,
        fetchDefaultPageData,
        userTypeInitial: userType,
        isLegalAddressSameEdit: isLegalAddressSame,
        setIsLegalAddressSame,
      },
    });
  };

  const content = (
    <Form
      onSubmit={onSetNewProfile}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, form, values }) => {
        let nickname = initialValues.email

        if (initialValues.companyName) nickname = initialValues.companyName
        else if (initialValues.lastName && initialValues.firstName)
          nickname = `${initialValues.firstName} ${initialValues.lastName}`

        return (
          <form onSubmit={handleSubmit} className={styles.ProfileForm}>
            <ItemWrapper additionalClassNames={[styles.NicknameHeader]}>
              <div className={styles.Header}>
                <div className={styles.NicknameSection}>
                  <div className={styles.Nickname}>{nickname}</div>
                  <div className={styles.DevicesAmount}>
                    <div className={styles.DevicesAmount__inactive}>
                      {intl.formatMessage(ProfileFormMessages.devicesCount, {
                        count: devicesCount,
                      })}
                    </div>
                    <div className={styles.DevicesAmount__active}>
                      {intl.formatMessage(ProfileFormMessages.onlineCount, {
                        count: onlineDevicesCount,
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </ItemWrapper>

            {isMobile && <div className={styles.Divider} />}

            {!isMobile && (
              <ItemWrapper>
                <div className={styles.Controls}>
                  <SegmentedControl
                    name={'accountType'}
                    group={accountTypeOptions}
                  />
                  <div className={styles.ChangePassword}>
                    <Button
                      text={intl.formatMessage(
                        ProfileFormMessages.changePassword,
                      )}
                      variant="blackTextGreenBorderOutlined"
                      onClick={handleChangePassword}
                      size={'small'}
                    />
                  </div>
                </div>
              </ItemWrapper>
            )}

            {isMobile ? (
              <MobileGrid
                isOrganization={isOrganization}
                form={form}
                values={values}
                initialValues={initialValues}
                countryOptions={countryOptions}
                isLegalAddress={isLegalAddressSame}
              />
            ) : (
              <DesktopGrid
                isOrganization={isOrganization}
                initialValues={initialValues}
                countryOptions={countryOptions}
                userInitialPhone={userInitialPhone}
                form={form}
                values={values}
              />
            )}
            {isOrganization && !isLegalAddressSame && !isMobile &&
 	            <div className={styles.Title}>
                {intl.formatMessage(ProfileFormMessages.deliveryAddressTitle)}
	            </div>
            }
            {isOrganization &&
              !isLegalAddressSame &&
              !isMobile &&
              <DeliveryAddressProfileFormDesktop
                initialValues={initialValues}
                countryOptions={countryOptions}
              />
            }

            {isOrganization && !isMobile &&
              <div className={styles.CheckBoxLegal}>
                <Checkbox
                  checked={isLegalAddressSame}
                  text={intl.formatMessage(ProfileFormMessages.deliveryCheckBoxLabel)}
                  onChange={() => setIsLegalAddressSame(prevState => !prevState)}
                />
              </div>
            }

            {isMobile && <div className={styles.Divider} />}

            <div className={styles.Actions}>
              {isMobile ? (
                <>
                  <div className={styles.ChangePassword}>
                    <Button
                      text={intl.formatMessage(
                        ProfileFormMessages.changePassword,
                      )}
                      onClick={handleChangePassword}
                      variant="greenTextOutlined"
                      size={'small'}
                    />
                  </div>
                  <div className={styles.Edit}>
                    <Button
                      text={intl.formatMessage(ProfileFormMessages.edit)}
                      onClick={handleEdit}
                      size={'small'}
                    />
                  </div>
                </>
              ) : (
                <Button
                  type={'submit'}
                  text={intl.formatMessage(ProfileFormMessages.submitLabel)}
                  disabled={submitting}
                  additionalClassNames={[styles.Actions_submit]}
                />
              )}
            </div>
          </form>
        )
      }}
    />
  )

  return (
    <LoadingContent
      loading={
        fetchCountriesLoading ||
        fetchUserLoading ||
        fetchActiveDevicesLoading ||
        fetchActiveDevicesLoading
      }
      content={content}
    />
  )
}
