import React, { FC } from 'react'
import clsx from 'clsx'
import styles from '../styles.module.scss'

export const RADIO_VARIANT_LIST = {
  filled: 'filled',
  outlined: 'outlined',
} as const

export interface RadioProps extends React.HTMLProps<HTMLInputElement> {
  label?: string
  subLabel?: string
  value: string
  comparedValue?: string
  variant?: (typeof RADIO_VARIANT_LIST)[keyof typeof RADIO_VARIANT_LIST]
  additionalClassNames?: string[]
  error?: string
  testId?: string
}

export const Radio: FC<RadioProps> = (props) => {
  const {
    label,
    subLabel,
    value,
    comparedValue,
    variant = RADIO_VARIANT_LIST.filled,
    additionalClassNames = [],
    error,
    testId,
    ...otherProps
  } = props

  return (
    <label
      className={clsx(styles.Container, ...additionalClassNames)}
      data-test-id={testId}
      tabIndex={0}
    >
      <input
        type="radio"
        className={styles.HiddenInput}
        name={props.name}
        value={value}
        {...otherProps}
        checked={value === comparedValue}
      />

      <div
        className={clsx(
          styles.Radio,
          variant === RADIO_VARIANT_LIST.outlined && styles.Radio_outlined,
        )}
      >
        <div className={styles.RadioChecked} />
      </div>

      {label && (
        <div className={styles.LabelWrapper}>
          {label && (
            <div className={styles.Label}>
              <div>{label}</div>
              {subLabel && <div className={styles.SubLabel}>{subLabel}</div>}
            </div>
          )}
          {error && <div className={styles.Error}>{error}</div>}
        </div>
      )}
    </label>
  )
}
