import { useEffect, useState } from 'react'
import { LangCodeEnum } from '../../config/intl/components/language-wrapper/LanguageWrapper'
import { getSiteVersion } from '../../shared/lib/utils/get-site-version/getSiteVersion'

type SiteVersionType = 'ru' | 'en'

export const useSiteVersion = () => {
  const [siteVersion, setSiteVersion] = useState<SiteVersionType>('en')
  const [langCode, setLangCode] = useState<LangCodeEnum>(LangCodeEnum.RU)

  useEffect(() => {
    const siteVersionData = getSiteVersion()
    setSiteVersion(siteVersionData.siteVersion as SiteVersionType)
    setLangCode(siteVersionData.langCode)
  }, [])

  return {
    siteVersion,
    langCode,
    setLangCode,
    siteDomainId: siteVersion === 'en' ? 1 : 2,
  }
}
