import {
  ContactGroupListBackendResponse,
  ContactGroupListResponse,
} from '../../../../../types/contact-group/_crud/list'

export const mapContactGroupListBackendToContactGroupList = (
  item: ContactGroupListBackendResponse,
): ContactGroupListResponse => {
  const { contact_groups, total_count } = item.data

  return {
    contactGroups: contact_groups.map((cg) => {
      return {
        contactGroupId: cg.contact_group_id,
        userId: cg.user_id,
        name: cg.name,
        contactsCount: cg.contacts_count,
      }
    }),
    totalCount: total_count,
  }
}
