import React from 'react';
import styles from './styles.module.scss';
import {ReactComponent as TriangleAlertIcon} from "../../../../assets/icons/triangle-alert.svg";
import {ReactComponent as GreenLikeIcon} from "../../../../assets/icons/like-green.svg";
import {defineMessages, useIntl} from "react-intl";
import clsx from "clsx";

const StatusSubscriptionMessages = defineMessages({
  active: {
    id: 'StatusSubscriptionMessages.active',
    defaultMessage: 'Active',
  },
  inactive: {
    id: 'StatusSubscriptionMessages.inactive',
    defaultMessage: 'Inactive',
  }
});

interface StatusSubscriptionProps {
  isTariffPackageActive: boolean;
}

export const StatusSubscription = (props: StatusSubscriptionProps) => {
  const {
    isTariffPackageActive
  } = props;

  const intl = useIntl();

  return (
    <div className={clsx(styles.StatusSubscription, !isTariffPackageActive && styles.Inactive)}>
      {
        isTariffPackageActive
        ?
          <>
            <GreenLikeIcon/>
            {intl.formatMessage(StatusSubscriptionMessages.active)}
          </>
        :
          <>
            <TriangleAlertIcon/>
            {intl.formatMessage(StatusSubscriptionMessages.inactive)}
          </>
      }
    </div>
  );
};

