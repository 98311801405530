import { parseStringAsUTCDate } from '../../../../../../../../shared/lib/utils/date-utils/parseStringAsUTCDate'
import {
  DongleCallListBackendResponse,
  DongleCallListResponse,
} from '../../../../../../types/dongle/call/_crud/list'

export const mapDongleCallListBackendToDongleCallList = (
  item: DongleCallListBackendResponse,
): DongleCallListResponse => {
  const { calls, total_count } = item.data

  return {
    calls: calls.map((el) => {
      return {
        dongleId: el.dongle_id,
        dongleName: el.dongle_name,
        callId: el.call_id,
        callTypeId: el.call_type_id,
        numberCaller: el.number_caller,
        numberReceiver: el.number_receiver,
        apiResponseData: el.api_response_data,
        createdAt: parseStringAsUTCDate(el.created_at),
        updatedAt: parseStringAsUTCDate(el.updated_at),
      }
    }),
    totalCount: total_count,
  }
}
