import { useEffect, useState } from 'react'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import {dongleMobileNetworkIdsDatagate, dongleMobileNetworkInfoDatagate} from "../../datagates/api/dongle";
import {DongleGetResponse} from "../../datagates/types/dongle/_crud/get";

type UseNetworkInformationProps = {
  device: DongleGetResponse | undefined
}

export const useNetworkInformation = (device?: DongleGetResponse) => {
  const [networkInformation, setNetworkInformation] = useState<Record<string, string>>({});
  const [networkInformationLoading, setNetworkInformationLoading] = useState<boolean>(true)

  const handleFetchNetworkInformation = async () => {
    if (device)
      try {
        setNetworkInformationLoading(true);
        const [infoResponse, idsResponse] = await Promise.all([
          dongleMobileNetworkInfoDatagate(null, [
            { name: 'dongle_id', value: device.dongleId.toString() },
          ]),
          dongleMobileNetworkIdsDatagate(null, [
            { name: 'dongle_id', value: device.dongleId.toString() },
          ])
        ]);

        const { data: infoData } = infoResponse;
        const { data: idsData } = idsResponse;

        const mappedData = [
          ...infoData.networkInfoList,
          ...idsData.networkIdsList,
        ].reduce((reducer, item) => {
          return {
            ...reducer,
            [item.propName]: item.propValue,
          };
        }, {});

        setNetworkInformation(mappedData)
      } catch (e) {
        console.error('e', getErrorMessage(e))
      } finally {
        setNetworkInformationLoading(false);
      }
  }

  useEffect(() => {
    void handleFetchNetworkInformation()
  }, [device])

  return {
    networkInformation,
    networkInformationLoading,
    handleFetchNetworkInformation,
  }
}
