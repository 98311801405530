import React, {useContext} from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { globalHistory } from '../../../../../../app/providers/with-router/withRouter'
import { LoadingContent } from '../../../../../../layouts/loading-content/LoadingContent'
import { USER_TYPE_IDS } from '../../../../../../sdk/datagates/types/user/_crud/get'
import { InputField } from '../../../../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import {
  composeValidators,
  useFormRules,
} from '../../../../../../shared/lib/form/form-rules'
import { Button } from '../../../../../../shared/ui-kit-2/inputs/button/Button'
import { TextButton } from '../../../../../../shared/ui-kit-2/inputs/text-button/TextButton'
import {PURCHASE_STEPS, PurchaseProcessPageContext} from '../../context/PurchaseProcessPageContextProvider'
import styles from './styles.module.scss'
import {PurchaseStepsFormType} from "../purchase-steps/PurchaseSteps";
import {useModal} from "../../../../../../app/hooks/useModal";
import {MODAL_TYPES} from "../../../../../../layouts/modals/ModalsProvider";
import {
  PhoneInputFieldNumber
} from "../../../../../../shared/lib/form/form-field-templates/v2/phone-input-field-new/PhoneInputFieldNew";

const PurchaseStepCustomerMessages = defineMessages({
  accountTypeIndividual: {
    id: 'PurchaseStepCustomerMessages.accountTypeIndividual',
    defaultMessage: 'Individual',
  },
  accountTypeOrganization: {
    id: 'PurchaseStepCustomerMessages.accountTypeOrganization',
    defaultMessage: 'Organization',
  },
  fillFromProfile: {
    id: 'PurchaseStepCustomerMessages.fillFromProfile',
    defaultMessage: 'Fill from profile',
  },
  firstNameLabel: {
    id: 'PurchaseStepCustomerMessages.firstNameLabel',
    defaultMessage: 'First name',
  },
  firstNamePlaceholder: {
    id: 'PurchaseStepCustomerMessages.firstNamePlaceholder',
    defaultMessage: 'Enter Name',
  },
  lastNameLabel: {
    id: 'PurchaseStepCustomerMessages.lastNameLabel',
    defaultMessage: 'Last name',
  },
  lastNamePlaceholder: {
    id: 'PurchaseStepCustomerMessages.lastNamePlaceholder',
    defaultMessage: 'Enter Last name',
  },
  companyNameLabel: {
    id: 'PurchaseStepCustomerMessages.companyNameLabel',
    defaultMessage: 'Company Name',
  },
  companyNamePlaceholder: {
    id: 'PurchaseStepCustomerMessages.companyNamePlaceholder',
    defaultMessage: 'Company Name',
  },
  companyPhoneLabel: {
    id: 'PurchaseStepCustomerMessages.companyPhoneLabel',
    defaultMessage: 'Company Phone Number',
  },
  companyPhonePlaceholder: {
    id: 'PurchaseStepCustomerMessages.companyPhonePlaceholder',
    defaultMessage: 'Enter number',
  },
  vatNumberLabel: {
    id: 'PurchaseStepCustomerMessages.vatNumberLabel',
    defaultMessage: 'VAT Number',
  },
  vatNumberPlaceholder: {
    id: 'PurchaseStepCustomerMessages.vatNumberPlaceholder',
    defaultMessage: 'GB424532',
  },
  billingEmailLabel: {
    id: 'PurchaseStepCustomerMessages.billingEmailLabel',
    defaultMessage: 'Billing email',
  },
  billingEmailPlaceholder: {
    id: 'PurchaseStepCustomerMessages.billingEmailPlaceholder',
    defaultMessage: 'Enter Billing email',
  },
  phoneLabel: {
    id: 'PurchaseStepCustomerMessages.phoneLabel',
    defaultMessage: 'Phone number',
  },
  back: {
    id: 'PurchaseStepCustomerMessages.back',
    defaultMessage: 'Back',
  },
  submit: {
    id: 'PurchaseStepCustomerMessages.submit',
    defaultMessage: 'Next Step',
  },
  fetchUserError: {
    id: 'PurchaseStepCustomerMessages.fetchUserError',
    defaultMessage: 'Failed to fetch user profile',
  },
})

export const PurchaseStepCustomer = () => {
  const intl = useIntl()
  const { ruleRequired, ruleNotIncludeNumbers, ruleMustBeNumber } = useFormRules()
  const { handleOpenModal } = useModal()

  const {
    formData,
    setFormData,
    setCurrentStep,
    monthPlanCount,
    annualPlanCount,
  } = useContext(PurchaseProcessPageContext)

  const handleOpenBuyNewDeviceModal = () => {
    globalHistory.back()
    handleOpenModal({
      type: MODAL_TYPES.BUY_NEW_DEVICE,
      props: {
        monthPlanCount,
        annualPlanCount
      }
    })
  }

  const onSubmit = (values: PurchaseStepsFormType) => {
    const finalValues =
      values.accountType === USER_TYPE_IDS.NATURAL_PERSON.toString()
        ? {
            ...values,
            companyName: undefined,
            vatNumber: undefined,
            accountType: formData?.accountType as string,
          }
        : {
            ...values,
            accountType: formData?.accountType as string,
          }

    setFormData(prev => ({ ...prev, ...finalValues}))
    setCurrentStep(PURCHASE_STEPS.SHIPPING)
  }

  const content = (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        ...(formData || {}),
      }}
      render={({ handleSubmit, values, errors }) => {
        const isOrganization =
          formData?.accountType === USER_TYPE_IDS.ORGANIZATION.toString()

        const initialPhone = isOrganization ? formData?.companyPhone : formData?.userPhone;

        return (
          <form onSubmit={handleSubmit} className={styles.Container}>
            <div className={styles.InputGrid}>
              <InputField
                name="firstName"
                validate={composeValidators(
                  ruleRequired(),
                  ruleNotIncludeNumbers(true),
                )}
                label={intl.formatMessage(
                  PurchaseStepCustomerMessages.firstNameLabel,
                )}
                placeholder={intl.formatMessage(
                  PurchaseStepCustomerMessages.firstNameLabel,
                )}
                markAsRequired={true}
              />

              <InputField
                name="lastName"
                validate={composeValidators(
                  ruleRequired(),
                  ruleNotIncludeNumbers(true),
                )}
                label={intl.formatMessage(
                  PurchaseStepCustomerMessages.lastNameLabel,
                )}
                placeholder={intl.formatMessage(
                  PurchaseStepCustomerMessages.lastNameLabel,
                )}
                markAsRequired={true}
              />

              <PhoneInputFieldNumber
                name={isOrganization ? "companyPhone" : "userPhone"}
                values={values}
                label={intl.formatMessage(
                  PurchaseStepCustomerMessages.phoneLabel,
                )}
                initialValue={initialPhone}
                markAsRequired={true}
                noHelperText={true}
              />

              {isOrganization && (
                <InputField
                  name="companyPhone"
                  validate={ruleRequired()}
                  label={intl.formatMessage(
                    PurchaseStepCustomerMessages.companyPhoneLabel,
                  )}
                  placeholder={intl.formatMessage(
                    PurchaseStepCustomerMessages.companyPhonePlaceholder,
                  )}
                  markAsRequired={true}
                />
              )}

              {isOrganization && (
                <InputField
                  name="companyName"
                  validate={ruleRequired()}
                  label={intl.formatMessage(
                    PurchaseStepCustomerMessages.companyNameLabel,
                  )}
                  placeholder={intl.formatMessage(
                    PurchaseStepCustomerMessages.companyNameLabel,
                  )}
                  markAsRequired={true}
                />
              )}

              {isOrganization && (
                <InputField
                  name="vatNumber"
                  validate={composeValidators(
                    ruleRequired(),
                    ruleMustBeNumber(true),
                  )}
                  label={intl.formatMessage(
                    PurchaseStepCustomerMessages.vatNumberLabel,
                  )}
                  placeholder={intl.formatMessage(
                    PurchaseStepCustomerMessages.vatNumberPlaceholder,
                  )}
                  markAsRequired={true}
                />
              )}
            </div>

            <div className={styles.Footer}>
              <div className={styles.BackAndSave}>
                <TextButton
                  type={'button'}
                  text={intl.formatMessage(PurchaseStepCustomerMessages.back)}
                  icon={'caret-left'}
                  onClick={handleOpenBuyNewDeviceModal}
                />
              </div>

              <Button
                type={'submit'}
                text={intl.formatMessage(PurchaseStepCustomerMessages.submit)}
                additionalClassNames={[styles.SubmitBtn]}
                disabled={errors && Object.keys(errors).length > 0}
              />
            </div>
          </form>
        )
      }}
    />
  )

  return <LoadingContent loading={!formData} content={content} />
}
