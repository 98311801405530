import React, { useCallback, useContext, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../../../app/hooks/useModal'
import { LoadingContent } from '../../../../../../../../layouts/loading-content/LoadingContent'
import { MODAL_TYPES } from '../../../../../../../../layouts/modals/ModalsProvider'
import { useDebounce } from '../../../../../../../../sdk/datagates/helpers/_common/debounce'
import { Card } from '../../../../../../../../shared/ui-kit-2/data-display/card/Card'
import { Counter } from '../../../../../../../../shared/ui-kit-2/data-display/counter/Counter'
import { PaginationMobile } from '../../../../../../../../shared/ui-kit-2/data-display/pagination/PaginationMobile'
import { Button } from '../../../../../../../../shared/ui-kit-2/inputs/button/Button'
import { SearchInputMobile } from '../../../../../../../../shared/ui-kit-2/inputs/searchInputMobile/SearchInputMobile'
import { ContactsPageContext } from '../../../../contexts/ContactsPageContext'
import { CardsContactMobile } from './components/CardContactsMobile'
import styles from './styles.module.scss'

const ContactsMobileMessages = defineMessages({
  title: {
    id: 'ContactsMobileMessages.title',
    defaultMessage: 'Contacts',
  },
  searchContacts: {
    id: 'ContactsMobileMessages.searchContacts',
    defaultMessage: 'Enter contact name',
  },
})

export const ContactsMobile = () => {
  const intl = useIntl()
  const [searchQuery, setSearchQuery] = useState<string>('')

  const debouncedSearch = useCallback(
    useDebounce((value: string) => {
      setContactsSearchQuery(value)
    }, 700),
    [],
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
    debouncedSearch(event.target.value)
  }

  const {
    setContactsSearchQuery,
    contactsPage,
    setContactsPage,
    setContactsLimitPage,
    contacts,
    limitContactsPage,
    contactsTotalCount,
    contactsLoading,
    handleFetchContacts,
    handleFetchContactGroups,
  } = useContext(ContactsPageContext)

  const handlePageChange = (page: number, limit: number) => {
    setContactsLimitPage(limit)
    setContactsPage(page)
  }

  const { handleOpenModal } = useModal()

  const openAddContactModal = () => {
    handleOpenModal({
      type: MODAL_TYPES.CONTACT_SETTINGS_MODAL,
      props: {
        handleFetchContacts,
        handleFetchContactGroups,
      },
    })
  }

  const content = (
    <>
      <div className={styles.ContainerList}>
        {contacts.map((contact, index) => (
          <CardsContactMobile contact={contact} key={index} />
        ))}
      </div>
    </>
  )

  return (
    <Card additionalClassNames={[styles.Container]}>
      <div className={styles.Header}>
        <div className={styles.Title}>
          {intl.formatMessage(ContactsMobileMessages.title)}
          <Counter variant={'filled'} count={contactsTotalCount} />
        </div>
        <div className={styles.Actions}>
          <Button
            text={''}
            size={'small'}
            icon={'user-plus'}
            onClick={openAddContactModal}
            additionalClassNames={[styles.AddContactBtnAdditional]}
          />
          <SearchInputMobile
            value={searchQuery}
            defaultValue={searchQuery}
            placeholder={intl.formatMessage(
              ContactsMobileMessages.searchContacts,
            )}
            onChange={handleChange}
          />
        </div>
      </div>
      <LoadingContent loading={contactsLoading} content={content} />
      <PaginationMobile
        totalCount={contactsTotalCount}
        onPageChange={handlePageChange}
        currentPage={contactsPage}
        itemsPerPage={limitContactsPage}
      />
    </Card>
  )
}
