import React, { useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router'
import { ReactComponent as ApiIcon } from '../../../../assets/icons/api-header.svg'
import { RoutePath } from '../../../../config/routes/constants/routePath'
import { Route } from '../../../../config/routes/enums/route'
import { UserHeaderMenu } from './components/user-header-menu/UserHeaderMenu'
import styles from './styles.module.scss'
import {useSiteVersion} from "../../../../app/hooks/useSiteVersion";
import clsx from "clsx";

const HeaderNotShownRoutePathGroup = [Route.Home, Route.PurchaseProcess].map(
  (route) => RoutePath[route],
)

const UserHeaderMessages = defineMessages({
  api: {
    id: 'UserHeaderMessages.api',
    defaultMessage: 'API',
  },
})

export const UserHeader = () => {
  const { pathname } = useLocation()
  const intl = useIntl()
  const navigate = useNavigate()
  const { siteVersion } = useSiteVersion()
  const isRuSiteVersion = siteVersion === 'ru'

  const isHeaderHidden = useMemo(
    () => HeaderNotShownRoutePathGroup.some((path) => path === pathname),
    [pathname],
  )

  const toApiPage = () => navigate(RoutePath[Route.Api])

  if (isHeaderHidden) return null

  return (
    <div className={styles.Container}>
      <div className={clsx(styles.Content, isRuSiteVersion && styles.Content_right)}>
        {!isRuSiteVersion && (
          <button className={styles.ApiButton} onClick={toApiPage}>
            <ApiIcon />
            <div>{intl.formatMessage(UserHeaderMessages.api)}</div>
          </button>
        )}

        <div className={styles.Bar}>
          <UserHeaderMenu />
        </div>
      </div>
    </div>
  )
}
