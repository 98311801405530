import { wrapApiRequest } from '../../helpers/_common/wrap-api-request'
import { mapCheckAuthBackendToCheckAuth } from '../../mappers/auth/check-auth/map-check-auth-backend-to-check-auth'
import { mapCheckForgetPasswordCodeBackendToCheckForgetPasswordCode } from '../../mappers/auth/check-forget-password-code/map-check-forget-password-code-backend-to-check-forget-password-code'
import { mapForgetPasswordBackendToForgetPassword } from '../../mappers/auth/forget-password/map-forget-password-backend-to-forget-password'
import { mapGenerateEmailVerificationTokenBackendToGenerateEmailVerificationToken } from '../../mappers/auth/generate-email-verification-token/map-generate-email-verification-token-backend-to-generate-email-verification-token'
import { mapGeneratePasswordResetTokenBackendToGeneratePasswordResetToken } from '../../mappers/auth/generate-password-reset-token/map-generate-password-reset-token-backend-to-generate-password-reset-token'
import { mapLoginBackendToLogin } from '../../mappers/auth/login/map-login-backend-to-login'
import { mapSignUpApproveBackendToSignUpApprove } from '../../mappers/auth/sign-up-approve/sign-up-approve-backend-to-sign-up-approve'
import { mapSignUpBackendToSignUp } from '../../mappers/auth/sign-up/map-sign-up-backend-to-sign-up'
import { mapUpdateForgetPasswordBackendToUpdateForgetPassword } from '../../mappers/auth/update-forget-password/map-update-forget-password-backend-to-update-forget-password'
import {
  CheckAuthBackendResponse,
  CheckAuthRequest,
  CheckAuthResponse,
} from '../../types/auth/check-auth'
import {
  CheckForgetPasswordCodeBackendResponse,
  CheckForgetPasswordCodeRequest,
  CheckForgetPasswordCodeResponse,
} from '../../types/auth/check-forget-password-code'
import {
  ForgetPasswordBackendResponse,
  ForgetPasswordRequest,
  ForgetPasswordResponse,
} from '../../types/auth/forget-password'
import {
  GenerateEmailVerificationTokenBackendResponse,
  GenerateEmailVerificationTokenRequest,
  GenerateEmailVerificationTokenResponse,
} from '../../types/auth/generate-email-verification-token'
import {
  GeneratePasswordResetTokenBackendResponse,
  GeneratePasswordResetTokenRequest,
  GeneratePasswordResetTokenResponse,
} from '../../types/auth/generate-password-reset-token'
import {
  LoginBackendResponse,
  LoginRequest,
  LoginResponse,
} from '../../types/auth/login'
import {
  SignUpBackendResponse,
  SignUpRequest,
  SignUpResponse,
} from '../../types/auth/sign-up'
import {
  SignUpApproveBackendResponse,
  SignUpApproveRequest,
  SignUpApproveResponse,
} from '../../types/auth/sign-up-approve'
import {
  UpdateForgetPasswordBackendResponse,
  UpdateForgetPasswordRequest,
  UpdateForgetPasswordResponse,
} from '../../types/auth/update-forget-password'

export const checkAuthDatagate = wrapApiRequest<
  CheckAuthRequest,
  CheckAuthBackendResponse,
  CheckAuthResponse
>('auth/login', mapCheckAuthBackendToCheckAuth)

export const loginDatagate = wrapApiRequest<
  LoginRequest,
  LoginBackendResponse,
  LoginResponse
>('auth/login', mapLoginBackendToLogin)

export const signUpDatagate = wrapApiRequest<
  SignUpRequest,
  SignUpBackendResponse,
  SignUpResponse
>('auth/signup', mapSignUpBackendToSignUp)

export const generateEmailVerificationTokenDatagate = wrapApiRequest<
  GenerateEmailVerificationTokenRequest,
  GenerateEmailVerificationTokenBackendResponse,
  GenerateEmailVerificationTokenResponse
>(
  'auth/generate-email-verification-token',
  mapGenerateEmailVerificationTokenBackendToGenerateEmailVerificationToken,
)

export const signUpApproveDatagate = wrapApiRequest<
  SignUpApproveRequest,
  SignUpApproveBackendResponse,
  SignUpApproveResponse
>('auth/signup-approve', mapSignUpApproveBackendToSignUpApprove)

export const forgetPasswordDatagate = wrapApiRequest<
  ForgetPasswordRequest,
  ForgetPasswordBackendResponse,
  ForgetPasswordResponse
>('auth/forget-password', mapForgetPasswordBackendToForgetPassword)

export const generatePasswordResetTokenDatagate = wrapApiRequest<
  GeneratePasswordResetTokenRequest,
  GeneratePasswordResetTokenBackendResponse,
  GeneratePasswordResetTokenResponse
>(
  'auth/generate-password-reset-token',
  mapGeneratePasswordResetTokenBackendToGeneratePasswordResetToken,
)

export const checkForgetPasswordCodeCheckDatagate = wrapApiRequest<
  CheckForgetPasswordCodeRequest,
  CheckForgetPasswordCodeBackendResponse,
  CheckForgetPasswordCodeResponse
>(
  'auth/check-forget-password-code',
  mapCheckForgetPasswordCodeBackendToCheckForgetPasswordCode,
)

export const updateForgetPasswordDatagate = wrapApiRequest<
  UpdateForgetPasswordRequest,
  UpdateForgetPasswordBackendResponse,
  UpdateForgetPasswordResponse
>(
  'auth/update-forget-password',
  mapUpdateForgetPasswordBackendToUpdateForgetPassword,
)
