import React, { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { useSiteVersion } from '../../../../app/hooks/useSiteVersion'
import { AuthRoutePath } from '../../../../config/routes/constants/authRoutePath'
import { AuthRoute } from '../../../../config/routes/enums/authRoute'
import { Card } from '../../../../shared/ui-kit-2/data-display/card/Card'
import { Tabs, TabType } from '../../../../shared/ui-kit-2/navigation/tabs/Tabs'
import { LC } from '../../../../tests/e2e/locators'
import { SocialMediaAuth } from '../../components/social-media-auth/SocialMediaAuth'
import { SignUpApprove } from './components/sign-up-approve/SignUpApprove'
import { SignUpForm } from './components/sign-up-form/SignUpForm'
import styles from './styles.module.scss'

const SIGN_UP_TAB_IDS = {
  SIGN_IN: 'sign-in-page',
  SIGN_UP: 'sign-up-page',
}

const SignUpPageMessages = defineMessages({
  logIn: {
    id: 'SignUpPageMessages.logIn',
    defaultMessage: 'Log in',
  },
  register: {
    id: 'SignUpPageMessages.register',
    defaultMessage: 'Registration',
  },
})

export const SignUpPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [email, setEmail] = useState<string | undefined>('')
  const { siteVersion } = useSiteVersion()

  const onTabChange = (id: string) => {
    if (id === SIGN_UP_TAB_IDS.SIGN_IN)
      navigate(AuthRoutePath[AuthRoute.SignIn])
  }

  const tabs: TabType[] = [
    {
      id: SIGN_UP_TAB_IDS.SIGN_UP,
      label: intl.formatMessage(SignUpPageMessages.register),
    },
    {
      id: SIGN_UP_TAB_IDS.SIGN_IN,
      label: intl.formatMessage(SignUpPageMessages.logIn),
      testId: LC.SIGN_UP_PAGE.SIGN_IN_LINK,
    },
  ]

  const registrationFormContent = (
    <>
      <Tabs
        items={tabs}
        defaultTabId={SIGN_UP_TAB_IDS.SIGN_UP}
        handleChange={onTabChange}
        additionalClassNames={[styles.TabsAdditional]}
      />

      <SignUpForm setEmail={setEmail} />

      {siteVersion !== 'ru' && <SocialMediaAuth />}
    </>
  )

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <div className={styles.CardContent}>
        {email ? <SignUpApprove email={email} /> : registrationFormContent}
      </div>
    </Card>
  )
}
