import React, { Dispatch, FC, SetStateAction, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useTableFooterInfo } from '../_helpers/hooks/use-table-footer-info/useTableFooterInfo'
import { CommonTableMessages } from '../../../config/intl/common-messages/common-table-messages/CommonTableMessages'
import { ContactsPageContext } from '../../../pages/lk/subpages/contacts/contexts/ContactsPageContext'
import { ContactListResponse } from '../../../sdk/datagates/types/contact/_crud/list'
import {
  Table,
  TableColType,
} from '../../../shared/ui-kit-2/data-display/table/Table'
import { LC } from '../../../tests/e2e/locators'
import { ContactTableRows } from './components/contact-table-rows/ContactTableRows'

interface ContactTableProps {
  contacts: ContactListResponse['contacts']
  totalCount: number
  page: number
  setPage: Dispatch<SetStateAction<number>>
  pageLimit: number
  loading: boolean
  handleFetch: () => Promise<void>
  handleFetchContactGroups: () => Promise<void>
  handleDeleteContact: (contactId: number) => Promise<void>
}

export const ContactTable: FC<ContactTableProps> = (props) => {
  const {
    contacts,
    page,
    setPage,
    pageLimit,
    totalCount,
    loading,
    handleFetch,
    handleFetchContactGroups,
    handleDeleteContact,
  } = props

  const { handleSortedOrderTypeAsc, isSortedOrderTypeAsc } =
    useContext(ContactsPageContext)

  const intl = useIntl()
  const { footerInfo } = useTableFooterInfo(page, pageLimit, totalCount)

  const cols: TableColType[] = [
    {
      title: intl.formatMessage(CommonTableMessages.columnName),
      handleSorted: handleSortedOrderTypeAsc,
      isSorted: isSortedOrderTypeAsc,
      isActive: true,
      isAZZA: true
    },
    { title: intl.formatMessage(CommonTableMessages.columnPhone) },
    { title: intl.formatMessage(CommonTableMessages.columnGroups) },
    {
      title: intl.formatMessage(CommonTableMessages.columnActions),
      position: 'right',
    },
  ]

  const rowGroups = ContactTableRows(
    contacts,
    handleFetch,
    handleFetchContactGroups,
    handleDeleteContact,
  )

  return (
    <Table
      cols={cols}
      rowGroups={rowGroups}
      currentPage={page}
      totalCount={totalCount}
      onPageChange={setPage}
      itemsPerPage={pageLimit}
      loading={loading}
      footerInfo={footerInfo}
      columnWidths={[1, 1, 1, 1]}
      testId={LC.CALLS.TABLE._}
    />
  )
}
