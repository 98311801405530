import React, { FC } from 'react'
import clsx from 'clsx'
import { ReactComponent as CaretLeftIcon } from './assets/icons/caret-left.svg'
import { ReactComponent as ContactIcon } from './assets/icons/contact.svg'
import { ReactComponent as EditIcon } from './assets/icons/edit.svg'
import { ReactComponent as PdfDownloadIcon } from './assets/icons/pdf-download.svg'
import styles from './styles.module.scss'

const TEXT_BUTTON_VARIANT_LIST = {
  default: 'default',
  bold: 'bold',
  small: 'small',
} as const

const TEXT_BUTTON_ICON_LIST = {
  edit: 'edit',
  caretLeft: 'caret-left',
  contact: 'contact',
  pdf: 'pdf',
} as const

const ICONS = {
  [TEXT_BUTTON_ICON_LIST.edit]: <EditIcon />,
  [TEXT_BUTTON_ICON_LIST.caretLeft]: <CaretLeftIcon />,
  [TEXT_BUTTON_ICON_LIST.contact]: <ContactIcon />,
  [TEXT_BUTTON_ICON_LIST.pdf]: <PdfDownloadIcon />,
}

interface TextButtonType extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string
  variant?: (typeof TEXT_BUTTON_VARIANT_LIST)[keyof typeof TEXT_BUTTON_VARIANT_LIST]
  icon?: (typeof TEXT_BUTTON_ICON_LIST)[keyof typeof TEXT_BUTTON_ICON_LIST]
  additionalClassNames?: string[]
  testId?: string
}

export const TextButton: FC<TextButtonType> = (props) => {
  const {
    text,
    variant = 'default',
    icon,
    additionalClassNames = [],
    testId,
    ...otherProps
  } = props

  return (
    <button
      type={'button'}
      className={clsx(
        styles.TextButton,
        variant === TEXT_BUTTON_VARIANT_LIST.default &&
          styles.TextButton_default,
        variant === TEXT_BUTTON_VARIANT_LIST.bold && styles.TextButton_bold,
        variant === TEXT_BUTTON_VARIANT_LIST.small && styles.TextButton_small,
        ...additionalClassNames,
      )}
      role="button"
      data-test-id={testId}
      {...otherProps}
    >
      {icon && (
        <>
          <div className={styles.TextButton__icon}>{ICONS[icon]}</div>
        </>
      )}

      <span>{text}</span>
    </button>
  )
}
