import React, { useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useSnackbar } from '../../../../app/hooks/useSnackbar'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/submodal-close.svg'
import { CommonButtonMessages } from '../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { InputField } from '../../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import {
  composeValidators,
  useFormRules,
} from '../../../../shared/lib/form/form-rules'
import { useClickOutside } from '../../../../shared/lib/hooks/useClickOutside'
import { Card } from '../../../../shared/ui-kit-2/data-display/card/Card'
import { Button } from '../../../../shared/ui-kit-2/inputs/button/Button'
import { SNACKBAR_TYPES } from '../../../snackbar/SnackbarProvider'
import styles from './styles.module.scss'

interface UseAddTelegramIdProps {
  processOnSave: (telegram: string) => void
  telegramIdList?: string[]
}

type UseAddTelegramIdFormValues = {
  telegramId: string
}

const UseAddTelegramIdMessages = defineMessages({
  title: {
    id: 'UseAddTelegramIdMessages.title',
    defaultMessage: 'Add telegram chat ID',
  },
  telegramLabel: {
    id: 'UseAddTelegramIdMessages.telegramLabel',
    defaultMessage: 'Your telegram chat ID',
  },
  telegramPlaceholder: {
    id: 'UseAddTelegramIdMessages.telegramPlaceholder',
    defaultMessage: 'Enter telegram chat ID',
  },
  alreadyExists: {
    id: 'UseAddTelegramIdMessages.alreadyExists',
    defaultMessage: 'Telegram chat ID already added',
  },
})

export const useAddTelegramId = (props: UseAddTelegramIdProps) => {
  const { processOnSave, telegramIdList } = props

  const intl = useIntl()
  const { handleOpenSnackbar } = useSnackbar()
  const { ruleRequired } = useFormRules()
  const [isOpen, setIsOpen] = useState<boolean>()
  const ref = useRef<HTMLDivElement>(null)

  const onClose = () => {
    setIsOpen(false)
  }

  useClickOutside(ref, onClose)

  const onSave = (values: UseAddTelegramIdFormValues) => {
    if (telegramIdList?.includes(values.telegramId)) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(UseAddTelegramIdMessages.alreadyExists),
      })

      return
    }

    processOnSave(values.telegramId)
    onClose()
  }

  const content = (
    <Form
      onSubmit={onSave}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} className={styles.Content}>
          <InputField
            name={'telegramId'}
            validate={composeValidators(ruleRequired())}
            label={intl.formatMessage(UseAddTelegramIdMessages.telegramLabel)}
            placeholder={intl.formatMessage(
              UseAddTelegramIdMessages.telegramPlaceholder,
            )}
            markAsRequired={true}
          />

          <Button
            type={'submit'}
            text={intl.formatMessage(CommonButtonMessages.add)}
            onClick={handleSubmit}
            disabled={submitting}
            loading={submitting}
          />
        </form>
      )}
    />
  )

  const component = (
    <div className={styles.Overlay}>
      <Card additionalClassNames={[styles.CardAdditional]} currentRef={ref}>
        <div className={styles.Header}>
          <div className={styles.Title}>
            {intl.formatMessage(UseAddTelegramIdMessages.title)}
          </div>
          <CloseIcon className={styles.Close} onClick={onClose} />
        </div>
        {content}
      </Card>
    </div>
  )

  return {
    setIsOpen,
    component: isOpen ? component : null,
  }
}
