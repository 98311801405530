import { parseStringAsUTCDate } from '../../../../../../../../shared/lib/utils/date-utils/parseStringAsUTCDate'
import {
  TransactionListBackendResponse,
  TransactionListResponse,
} from '../../../../../../types/billing/transaction/_crud/list'

export const mapTransactionListBackendToTransactionList = (
  item: TransactionListBackendResponse,
): TransactionListResponse => {
  const { transactions, total_count } = item.data

  return {
    transactions: transactions.map((el) => ({
      transactionId: el.transaction_id,
      invoiceId: el.invoice_id,
      userId: el.user_id,
      transactionTypeId: el.transaction_type_id,
      amount: el.amount / 100,
      createdAt: parseStringAsUTCDate(el.created_at),
      updatedAt: parseStringAsUTCDate(el.updated_at),
      paymentMethodId: el.payment_method_id,
      parentTransactionId: el.parent_transaction_id,
      outId: el.out_id,
      invoiceFileId: el.invoice?.invoice_file_id,
      receiptFileId: el.invoice?.receipt_file_id,
      dongleName: el.invoice?.dongle?.name,
    })),
    totalCount: total_count,
  }
}
