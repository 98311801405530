import React, { FC, ReactNode, useState } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

const TABS_SIZE_LIST = {
  default: 'default',
  small: 'small',
} as const

export type TabType = {
  id: string
  label: string
  render?: ReactNode
  onClick?: () => void
  testId?: string
}

type TabsType = Array<TabType>

interface TabsProps {
  items: TabsType
  defaultTabId?: string
  handleChange?: (id: string) => void
  size?: (typeof TABS_SIZE_LIST)[keyof typeof TABS_SIZE_LIST]
  additionalClassNames?: string[]
}

export const Tabs: FC<TabsProps> = ({
  items,
  defaultTabId,
  handleChange = () => null,
  size = TABS_SIZE_LIST.default,
  additionalClassNames = [],
  ...props
}) => {
  const getTabById = (foundedId: string) =>
    items.find(({ id }) => id === foundedId) || items[0]

  const [selectedTab, setSelectedTab] = useState<TabType>(
    defaultTabId ? getTabById(defaultTabId) : items[0],
  )

  const handleChangeTab = (id: string) => {
    handleChange(id)
    setSelectedTab(getTabById(id))
  }

  return (
    <div {...props}>
      <div
        className={clsx(
          styles.Tabs,
          size === TABS_SIZE_LIST.small && styles.Tabs_small,
          ...additionalClassNames,
        )}
      >
        {items?.map(({ id, label, testId }) => (
          <div
            key={id}
            className={clsx(
              styles.Tab,
              selectedTab.id === id && styles.Tab_selected,
              size === TABS_SIZE_LIST.small && styles.Tab_small,
            )}
            onClick={() => handleChangeTab(id)}
            data-test-id={testId}
          >
            <span>{label}</span>
            <div
              className={clsx(
                styles.Shadow,
                selectedTab.id === id && styles.Shadow_active,
              )}
            />
          </div>
        ))}
      </div>
      {selectedTab?.render}
    </div>
  )
}
