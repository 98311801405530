import React, { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { BalanceHistoryTable } from '../../../../../../../../layouts/tables/balance-history-table/BalanceHistoryTable'
import { useTransactions } from '../../../../../../../../sdk/hooks/use-transactions/useTransactions'
import { Card } from '../../../../../../../../shared/ui-kit-2/data-display/card/Card'
import styles from './styles.module.scss'

const BalanceHistoryMessages = defineMessages({
  title: {
    id: 'BalanceHistoryMessages.title',
    defaultMessage: 'Balance history',
  },
  date: {
    id: 'BalanceHistoryMessages.date',
    defaultMessage: 'Date',
  },
  type: {
    id: 'BalanceHistoryMessages.type',
    defaultMessage: 'Type',
  },
  amount: {
    id: 'BalanceHistoryMessages.amount',
    defaultMessage: 'Amount',
  },
  download: {
    id: 'BalanceHistoryMessages.download',
    defaultMessage: 'Download',
  },
})

export const BalanceHistory = () => {
  const intl = useIntl()
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(50)
  const { transactions, loading, totalCount } = useTransactions({
    page: page,
    limit: limit,
  })

  const sliceTransactions = transactions.slice(0, 3000)

  return (
    <Card additionalClassNames={[styles.Card]}>
      <div className={styles.Header}>
        {intl.formatMessage(BalanceHistoryMessages.title)}
      </div>

      <BalanceHistoryTable
        balanceHistory={sliceTransactions}
        loading={loading}
        totalCount={totalCount}
        pageLimit={limit}
        page={page}
        setPage={setPage}
        setLimit={setLimit}
      />
    </Card>
  )
}
