import React, { FC } from 'react'
import {
  SegmentedControlOption,
  SegmentedControlOptionProps,
} from './components/SegmentedControlOption'
import styles from './styles.module.scss'

export interface SegmentedControlProps {
  name: string
  group: Array<SegmentedControlOptionProps>
}

export const SegmentedControl: FC<SegmentedControlProps> = (props) => {
  const { name, group } = props

  return (
    <div className={styles.Container}>
      {group.map(({ label, value, testId, ...otherOptionProps }) => (
        <SegmentedControlOption
          key={value}
          name={name}
          label={label}
          value={value}
          testId={testId}
          {...otherOptionProps}
        />
      ))}
    </div>
  )
}
