import React, { FC, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { ReactComponent as PrevPageIcon } from '../../../../assets/icons/caret-left.svg'
import { ReactComponent as NextPageIcon } from '../../../../assets/icons/caret-right.svg'
import { TableShowElements } from '../table/components/tableShowElements/TableShowElements'
import { useTablePages } from '../table/hooks/useTablePages'
import styles from './styles.module.scss'

interface PaginationContactsMobileProps {
  currentPage: number
  totalCount: number
  onPageChange: (page: number, limit: number) => void
  itemsPerPage: number
}

const FirstPage = 1

export const PaginationMobile: FC<PaginationContactsMobileProps> = (props) => {
  const { currentPage, totalCount, onPageChange, itemsPerPage } = props
  const [elementsShow, setElementsShow] = useState(itemsPerPage)

  const pagesCount = useMemo(
    () => Math.ceil(totalCount / elementsShow),
    [totalCount, elementsShow],
  )

  const pages = useTablePages(
    currentPage,
    pagesCount,
    onPageChange,
    itemsPerPage,
  )
  const prevPageAvailable = useMemo(
    () => currentPage !== FirstPage && pagesCount > 1,
    [currentPage, pagesCount],
  )
  const nextPageAvailable = useMemo(
    () => currentPage !== pagesCount - (1 - FirstPage) && pagesCount > 1,
    [currentPage, pagesCount],
  )

  useEffect(() => {
    onPageChange(currentPage, elementsShow)
  }, [currentPage, elementsShow])

  return (
    <div className={clsx(styles.PaginationContainer)}>
      <TableShowElements
        elementsShow={itemsPerPage}
        setElementsShow={setElementsShow}
      />
      {pagesCount > 1 && (
        <div className={styles.Pagination}>
          <div
            onClick={() =>
              prevPageAvailable && onPageChange(currentPage - 1, itemsPerPage)
            }
            className={clsx(
              styles.Pagination__arrow,
              !prevPageAvailable && styles.Pagination__arrow_disabled,
            )}
          >
            <PrevPageIcon />
          </div>
          {pages}
          <div
            onClick={() =>
              nextPageAvailable && onPageChange(currentPage + 1, itemsPerPage)
            }
            className={clsx(
              styles.Pagination__arrow,
              !nextPageAvailable && styles.Pagination__arrow_disabled,
            )}
          >
            <NextPageIcon />
          </div>
        </div>
      )}
    </div>
  )
}
