import {
  UpdateForgetPasswordBackendResponse,
  UpdateForgetPasswordResponse,
} from '../../../../types/auth/update-forget-password'

export const mapUpdateForgetPasswordBackendToUpdateForgetPassword = (
  item: UpdateForgetPasswordBackendResponse,
): UpdateForgetPasswordResponse => {
  const { status } = item.data

  return {
    status: status,
  }
}
