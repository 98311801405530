import {
  OrderCreateBackendResponse,
  OrderCreateResponse,
} from '../../../../../types/order/_crud/create'

export const mapOrderCreateBackendToOrderCreate = (
  item: OrderCreateBackendResponse,
): OrderCreateResponse => {
  const {
    order_id,
    order_type_id,
    user_id,
    order_status_id,
    order_payment_type_id,
    first_name,
    last_name,
    country_id,
    phone,
    region,
    city,
    street_address1,
    street_address2,
    postal_code,
    created_at,
    updated_at,
    amount,
  } = item.data

  return {
    orderId: order_id,
    orderTypeId: order_type_id,
    userId: user_id,
    orderStatusId: order_status_id,
    orderPaymentTypeId: order_payment_type_id,
    firstName: first_name,
    lastName: last_name,
    countryId: country_id,
    phone: phone,
    region: region,
    city: city,
    streetAddress1: street_address1,
    streetAddress2: street_address2,
    postalCode: postal_code,
    createdAt: created_at,
    updatedAt: updated_at,
    amount: amount ? amount / 100 : undefined,
  }
}
