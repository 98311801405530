import React from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { CommonButtonMessages } from '../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { userUpdatePasswordDatagate } from '../../../sdk/datagates/api/user'
import { useBackendErrorCodes } from '../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { InputPasswordField } from '../../../shared/lib/form/form-field-adapters/v2/input-password-field/InputPasswordField'
import {
  composeValidators,
  useFormRules,
} from '../../../shared/lib/form/form-rules'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import { ItemWrapper } from '../../../shared/ui-kit/data-display/ItemWrapper/ItemWrapper'
import { SNACKBAR_TYPES } from '../../snackbar/SnackbarProvider'
import styles from './styles.module.scss'

type EditPasswordFormType = {
  oldPassword: string
  password: string
  repeatPassword: string
}

const ChangePasswordModalMessages = defineMessages({
  title: {
    id: 'ChangePasswordModalMessages.title',
    defaultMessage: 'Change password',
  },
  currentPasswordLabel: {
    id: 'ChangePasswordModalMessages.currentPasswordLabel',
    defaultMessage: 'Current password',
  },
  currentPasswordPlaceholder: {
    id: 'ChangePasswordModalMessages.currentPasswordPlaceholder',
    defaultMessage: 'Enter current password',
  },
  passwordLabel: {
    id: 'ChangePasswordModalMessages.newPasswordLabel',
    defaultMessage: 'New password',
  },
  passwordPlaceholder: {
    id: 'ChangePasswordModalMessages.passwordPlaceholder',
    defaultMessage: 'Enter new password',
  },
  repeatPasswordLabel: {
    id: 'ChangePasswordModalMessages.confirmNewPasswordLabel',
    defaultMessage: 'Confirm new password',
  },
  repeatPasswordPlaceholder: {
    id: 'ChangePasswordModalMessages.repeatPasswordPlaceholder',
    defaultMessage: 'Repeat new password',
  },
  defaultPlaceholderLabel: {
    id: 'ChangePasswordModalMessages.defaultPlaceholderLabel',
    defaultMessage: 'placeholder',
  },
  success: {
    id: 'ChangePasswordModalMessages.success',
    defaultMessage: 'Password is successfully updated',
  },
  error: {
    id: 'ChangePasswordModalMessages.error',
    defaultMessage: 'An error occurred while updating password',
  },
})

export const PasswordModal = () => {
  const { handleHideModal } = useModal()
  const intl = useIntl()
  const { resolveBackendError } = useBackendErrorCodes()
  const { ruleRequired, rulePasswordsMismatch } = useFormRules()
  const { handleOpenSnackbar } = useSnackbar()

  const onSetNewPassword = async (values: EditPasswordFormType) => {
    try {
      await userUpdatePasswordDatagate({
        old_password: values.oldPassword,
        password: values.password,
        repeat_password: values.repeatPassword,
      })

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(ChangePasswordModalMessages.success),
      })

      handleHideModal()
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(ChangePasswordModalMessages.error),
        ),
      })
    }
  }

  return (
    <Drawer
      title={intl.formatMessage(ChangePasswordModalMessages.title)}
      isOpen={true}
      close={handleHideModal}
      limitedWidth={false}
      additionalClassNames={[styles.DrawerAdditional]}
    >
      <Form
        onSubmit={onSetNewPassword}
        render={({ handleSubmit, submitting, values }) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            <div>
              <ItemWrapper>
                <InputPasswordField
                  name={'oldPassword'}
                  label={intl.formatMessage(
                    ChangePasswordModalMessages.currentPasswordLabel,
                  )}
                  placeholder={intl.formatMessage(
                    ChangePasswordModalMessages.currentPasswordPlaceholder,
                  )}
                  required={true}
                />
              </ItemWrapper>

              <ItemWrapper>
                <InputPasswordField
                  name={'password'}
                  label={intl.formatMessage(
                    ChangePasswordModalMessages.passwordLabel,
                  )}
                  placeholder={intl.formatMessage(
                    ChangePasswordModalMessages.passwordPlaceholder,
                  )}
                  required={true}
                />
              </ItemWrapper>

              <ItemWrapper>
                <InputPasswordField
                  name={'repeatPassword'}
                  label={intl.formatMessage(
                    ChangePasswordModalMessages.repeatPasswordLabel,
                  )}
                  placeholder={intl.formatMessage(
                    ChangePasswordModalMessages.repeatPasswordPlaceholder,
                  )}
                  required={true}
                  validate={composeValidators(
                    ruleRequired(),
                    rulePasswordsMismatch('password', values),
                  )}
                />
              </ItemWrapper>
            </div>

            <div className={styles.actions}>
              <Button
                onClick={handleHideModal}
                text={intl.formatMessage(CommonButtonMessages.cancel)}
                variant={'blackTextBlackBorderOutlined'}
                additionalClassNames={[styles.actions_cancel]}
              />
              <Button
                type={'submit'}
                text={intl.formatMessage(CommonButtonMessages.save)}
                disabled={submitting}
                additionalClassNames={[styles.actions_save]}
              />
            </div>
          </form>
        )}
      />
    </Drawer>
  )
}
