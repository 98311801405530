import { defineMessages } from 'react-intl'

export const TermsOfUseMessages = defineMessages({
  title: {
    id: 'TermsOfUseMessages.title',
    defaultMessage: 'TÉRMINOS Y CONDICIONES DE USO',
  },
  introduction: {
    id: 'TermsOfUseMessages.introduction',
    defaultMessage: 'Introducción',
  },
  introductionText: {
    id: 'TermsOfUseMessages.introductionText',
    defaultMessage:
      'Estos términos y condiciones se aplican entre usted, el usuario de este sitio web (incluidos los subdominios, a menos que estén expresamente excluidos por sus propios términos y condiciones), y Bringo Telecom SL, siendo el propietario y operador de este sitio web www.telecorn.com.',
  },
  bringoTelecomSlDetails: {
    id: 'TermsOfUseMessages.bringoTelecomSlDetails',
    defaultMessage: 'Bringo Telecom SL detalles',
  },
  bringoTelecomSlDetailsText1: {
    id: 'TermsOfUseMessages.bringoTelecomSlDetailsText1',
    defaultMessage:
      'Bringo Telecom SL, en adelante “la compañía” es una empresa constituida en España, con CIF B72427412, con Domicilio Social y Fiscal en la calle Marina 16-18, Planta 27, Barcelona con CP 08005. Puede ponerse en contacto con Bringo Telecom SL por correo electrónico en info@bringo.eu.',
  },
  bringoTelecomSlDetailsText2: {
    id: 'TermsOfUseMessages.bringoTelecomSlDetailsText2',
    defaultMessage:
      'Lea estos términos y condiciones cuidadosamente, ya que afectan sus derechos legales. Su aceptación de cumplir y estar sujeto a estos términos y condiciones se considera que ocurre en su primer uso del sitio web. Si no acepta estar sujeto a estos términos y condiciones, debe dejar de usar el sitio web de inmediato.',
  },
  bringoTelecomSlDetailsText3: {
    id: 'TermsOfUseMessages.bringoTelecomSlDetailsText3',
    defaultMessage:
      'En estos términos y condiciones, Usuario/a o Usuarios significa cualquier tercero que acceda al Sitio Web y no sea (i) empleado por Bringo Telecom SL y que actúe en el curso de su empleo o (ii) contratado como consultor o que preste servicios a Bringo Telecom SL y acceda al Sitio Web en relación con la prestación de dichos servicios.   Debe tener al menos 18 años de edad para utilizar este sitio web. Al utilizar el sitio web y aceptar estos términos y condiciones, usted declara y garantiza que tiene al menos 18 años de edad.',
  },
  generalConditions: {
    id: 'TermsOfUseMessages.generalConditions',
    defaultMessage: 'Condiciones Generales del Servicio y Dispositivo Telecorn',
  },
  generalConditionsTextP1: {
    id: 'TermsOfUseMessages.generalConditionsTextP1',
    defaultMessage:
      'Las Condiciones Generales del servicio son de aplicación para todo/a usuario/a por el uso del dispositivo Telecorn en adelante “el dispositivo”.',
  },
  generalConditionsTextP2: {
    id: 'TermsOfUseMessages.generalConditionsTextP2',
    defaultMessage:
      'La compañía no se hace responsable por el mal uso/ indebido/ fraudulento uso del dispositivo y/o sus funcionalidades.',
  },
  generalConditionsTextP3: {
    id: 'TermsOfUseMessages.generalConditionsTextP3',
    defaultMessage:
      'La compañía no se hace responsable de la pérdida o las roturas del dispositivo u otro tipo de uso irresponsable. Si el/la usuario/a desea seguir disfrutando del servicio, tendrá que volver a comprar un dispositivo nuevo.',
  },
  generalConditionsTextP4: {
    id: 'TermsOfUseMessages.generalConditionsTextP4',
    defaultMessage:
      'La compañía ofrece la garantía legal de 3 años desde el momento de la compra del dispositivo.',
  },
  generalConditionsTextP5: {
    id: 'TermsOfUseMessages.generalConditionsTextP5',
    defaultMessage:
      'Para el correcto uso del dispositivo, la parte de conectividad será por cuenta del usuario/a, donde se requerirá la contratación de tarjeta SIM, que incluya datos de un mínimo de 200 MB mensuales.',
  },
  generalConditionsTextP6: {
    id: 'TermsOfUseMessages.generalConditionsTextP6',
    defaultMessage:
      'El consumo que produzca la tarjeta SIM de la mensajería SMS, voz, datos y otros conceptos será por cuenta del/la usuario/a y nunca formará parte de esta relación contractual. Todas cuestiones de conectividad, tanto la calidad como el precio, serán determinadas y tratadas con el operador móvil correspondiente.',
  },
  generalConditionsTextP7: {
    id: 'TermsOfUseMessages.generalConditionsTextP7',
    defaultMessage:
      'La suscripción mensual por el uso del dispositivo es de 50 euros al mes IVA incluido. La suscripción anual es de 480 euros al año, IVA incluido.',
  },
  generalConditionsTextP8: {
    id: 'TermsOfUseMessages.generalConditionsTextP8',
    defaultMessage:
      'Gastos de envío. El envío tiene coste de 10 euros, que serán cobrados al cliente, si la suscripción es mensual. La suscripción anual incluye los gastos de envío.',
  },
  generalConditionsTextP9: {
    id: 'TermsOfUseMessages.generalConditionsTextP9',
    defaultMessage:
      'El precio del dispositivo es de 100 euros. Si la suscripción es mensual los 100 euros se cobrarán junto con la cuota mensual del servicio. Si la suscripción es anual los 100 euros serán incluidos en el precio de la suscripción anual.',
  },
  generalConditionsTextP10: {
    id: 'TermsOfUseMessages.generalConditionsTextP10',
    defaultMessage:
      'Al concluir el contrato el dispositivo dejará de funcionar completamente. Si el/la usuario/a desea activar el dispositivo, tendrá que volver a activar la suscripción mensual o anual. La suscripción anual no incluirá el envío de un dispositivo nuevo.',
  },
  generalConditionsTextP11: {
    id: 'TermsOfUseMessages.generalConditionsTextP11',
    defaultMessage:
      'El impago supone el incumplimiento contractual por parte del/la usuario/a y la desactivación completa del dispositivo a los 14 días siguientes a la fecha debida de cobro. Si el cliente desea activar el dispositivo tendrá que pagar la deuda y reactivar el servicio.',
  },
  generalConditionsTextP12: {
    id: 'TermsOfUseMessages.generalConditionsTextP12',
    defaultMessage:
      'El cobro del servicio será mediante tarjeta bancaria que introducirá el cliente a la hora de contratar el servicio. El cliente tendrá la opción para cambiar la tarjeta e introducir una nueva. La suscripción, tanto anual como mensual se cobrará en los primeros 3 días de cada mes. El precio mensual o anual no incluye las comisiones que el cliente tenga con su banco.',
  },
  generalConditionsTextP13: {
    id: 'TermsOfUseMessages.generalConditionsTextP13',
    defaultMessage:
      'La compañía se reserva el derecho de modificar las condiciones contractuales con el preaviso de 2 (dos) meses de antelación.',
  },
  generalConditionsTextP14: {
    id: 'TermsOfUseMessages.generalConditionsTextP14',
    defaultMessage:
      'Tanto la compañía como el/la usuario/a podrán avisar con el plazo de 1 (un) mes de antelación sobre la conclusión o la no renovación contractual.\n',
  },
  generalConditionsTextP15: {
    id: 'TermsOfUseMessages.generalConditionsTextP15',
    defaultMessage:
      'Ante la conclusión contractual, el usuario/a tendrá la posibilidad solicitar y ejercitar el derecho de supresión de sus datos personales, datos de contacto, medios de pago y otros  datos personales de conformidad con el artículo 17 del Reglamento (UE) 2016/679 del Parlamento europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (Reglamento General de Protección de Datos), y con el artículo 15 de la Ley Orgánica 3/2018 de 5 de diciembre, de protección de datos personales y garantía de los derechos digitales, siguiendo las condiciones que establece.',
  },
  generalConditionsTextP16: {
    id: 'TermsOfUseMessages.generalConditionsTextP16',
    defaultMessage:
      'Una vez entregado al servicio de mensajería, la compañía no se hace responsable de la entrega.',
  },
  generalConditionsTextP17: {
    id: 'TermsOfUseMessages.generalConditionsTextP17',
    defaultMessage:
      'La compañía podrá cobrar al usuario el coste de envío de devolución del dispositivo en caso de devolución.',
  },
  generalConditionsTextP18: {
    id: 'TermsOfUseMessages.generalConditionsTextP18',
    defaultMessage:
      'La compañía reembolsará el dispositivo y la suscripción si el usuario devuelve el dispositivo sin daños dentro de los 15 días posteriores a su recepción.',
  },
  generalConditionsTextP19: {
    id: 'TermsOfUseMessages.generalConditionsTextP19',
    defaultMessage:
      'La compañía no reembolsa dinero por un dispositivo roto por el usuario.',
  },
  generalConditionsTextP20: {
    id: 'TermsOfUseMessages.generalConditionsTextP20',
    defaultMessage:
      'La compañía iniciará el proceso de reembolso sólo después de recibir el dispositivo por parte del servicio de mensajería.',
  },
  generalConditionsTextP21: {
    id: 'TermsOfUseMessages.generalConditionsTextP21',
    defaultMessage:
      'Para cualquier pregunta relacionada con cancelar una compra o devolver un dispositivo, también puedes contactar con nuestro equipo de soporte en info@telecorn.com.',
  },
  intellectualProperty: {
    id: 'TermsOfUseMessages.intellectualProperty',
    defaultMessage: 'Propiedad intelectual y uso aceptable',
  },
  intellectualPropertyTextP1: {
    id: 'TermsOfUseMessages.intellectualPropertyTextP1',
    defaultMessage:
      'Todo el Contenido incluido en el Sitio Web, a menos que sea cargado por los Usuarios, es propiedad de Bringo Telecom SL, nuestros afiliados u otros terceros relevantes. En estos términos y condiciones, Contenido significa cualquier texto, gráficos, imágenes, audio, video, software, compilaciones de datos, diseño de página, código y software subyacentes y cualquier otra forma de información que pueda almacenarse en una computadora que aparezca o forme parte de este sitio web, incluido cualquier contenido cargado por los Usuarios. Al continuar utilizando el sitio web, usted reconoce que dicho contenido está protegido por derechos de autor, marcas comerciales, derechos de bases de datos y otros derechos de propiedad intelectual. Nada en este sitio se interpretará como una concesión, por implicación, impedimento o de otro modo, de ninguna licencia o derecho para usar cualquier marca comercial, logotipo o marca de servicio que se muestre en el sitio sin el permiso previo por escrito del propietario.',
  },
  intellectualPropertyTextP2: {
    id: 'TermsOfUseMessages.intellectualPropertyTextP2',
    defaultMessage:
      'Usted puede, para su propio uso personal, no comercial solamente, hacer lo siguiente:',
  },
  intellectualPropertyTextP2A: {
    id: 'TermsOfUseMessages.intellectualPropertyTextP2A',
    defaultMessage: 'recuperar, mostrar y ver el Contenido en un dispositivo',
  },
  intellectualPropertyTextP2B: {
    id: 'TermsOfUseMessages.intellectualPropertyTextP2B',
    defaultMessage:
      'descargar y almacenar el Contenido en formato electrónico en un disco (pero no en ningún servidor u otro dispositivo de almacenamiento conectado a una red)',
  },
  intellectualPropertyTextP2C: {
    id: 'TermsOfUseMessages.intellectualPropertyTextP2C',
    defaultMessage: 'imprimir una copia del Contenido',
  },
  intellectualPropertyTextP3: {
    id: 'TermsOfUseMessages.intellectualPropertyTextP3',
    defaultMessage:
      'No debe reproducir, modificar, copiar, distribuir o utilizar con fines comerciales ningún Contenido sin el permiso por escrito de Bringo Telecom SL.',
  },
  intellectualPropertyTextP4: {
    id: 'TermsOfUseMessages.intellectualPropertyTextP4',
    defaultMessage:
      "Usted reconoce que es responsable de cualquier Contenido que pueda enviar a través del Sitio web, incluida la legalidad, confiabilidad, idoneidad, originalidad y derechos de autor de dicho Contenido. No puede cargar, distribuir o publicar a través del sitio web ningún Contenido que (i) sea confidencial, propietario, falso, fraudulento, calumnioso, difamatorio, obsceno, amenazante, invasivo de los derechos de privacidad o publicidad, que infrinja los derechos de propiedad intelectual, abusivo, ilegal u objetable; (ii) pueda constituir o alentar un delito penal, violar los derechos de cualquier parte o dar lugar a responsabilidad o violar cualquier ley; o (iii) puede contener virus de software, campañas políticas, cartas en cadena, correos masivos o cualquier forma de 'spam'. No puede usar una dirección de correo electrónico falsa u otra información de identificación, hacerse pasar por otra persona o entidad o inducir a error en cuanto al origen de cualquier contenido.",
  },
  intellectualPropertyTextP5: {
    id: 'TermsOfUseMessages.intellectualPropertyTextP5',
    defaultMessage:
      'Usted declara y garantiza que posee o controla todos los derechos sobre el Contenido que publica; que el Contenido es preciso; que el uso del Contenido que suministra no viola ninguna disposición de estos términos y condiciones y no causará daño a ninguna persona; y que indemnizará a Bringo Telecom SL por todas las reclamaciones resultantes del Contenido que suministre.',
  },
  prohibitedUse: {
    id: 'TermsOfUseMessages.prohibitedUse',
    defaultMessage: 'Uso prohibido',
  },
  prohibitedUseTextP1: {
    id: 'TermsOfUseMessages.prohibitedUseTextP1',
    defaultMessage:
      'No puede utilizar el sitio web para ninguno de los siguientes propósitos:',
  },
  prohibitedUseTextP1A: {
    id: 'TermsOfUseMessages.prohibitedUseTextP1A',
    defaultMessage:
      'de cualquier manera que cause, o pueda causar, daños al sitio web o interfiera con el uso o disfrute del sitio web por parte de cualquier otra persona;',
  },
  prohibitedUseTextP1B: {
    id: 'TermsOfUseMessages.prohibitedUseTextP1B',
    defaultMessage:
      'de cualquier manera que sea dañina, ilegal, ilegal, abusiva, acosadora, amenazante u objetable de otra manera o que infrinja cualquier ley, regulación u orden gubernamental aplicable;',
  },
  prohibitedUseTextP1C: {
    id: 'TermsOfUseMessages.prohibitedUseTextP1C',
    defaultMessage:
      'hacer, transmitir o almacenar copias electrónicas de Contenido protegido por derechos de autor sin el permiso del propietario.',
  },
  registration: {
    id: 'TermsOfUseMessages.registration',
    defaultMessage: 'Registro',
  },
  registrationTextP1: {
    id: 'TermsOfUseMessages.registrationTextP1',
    defaultMessage:
      'Debe asegurarse de que los datos proporcionados por usted en el registro o en cualquier momento sean correctos y completos.',
  },
  registrationTextP2: {
    id: 'TermsOfUseMessages.registrationTextP2',
    defaultMessage:
      'Debe informarnos inmediatamente de cualquier cambio en la información que proporcione al registrarse actualizando sus datos personales para garantizar que podamos comunicarnos con usted de manera efectiva.',
  },
  registrationTextP3: {
    id: 'TermsOfUseMessages.registrationTextP3',
    defaultMessage:
      'Podemos suspender o cancelar su registro con efecto inmediato para cualquier propósito razonable o si incumple estos términos y condiciones.',
  },
  registrationTextP4: {
    id: 'TermsOfUseMessages.registrationTextP4',
    defaultMessage:
      'Puede cancelar su registro en cualquier momento informándonos por escrito a la dirección que figura al final de estos términos y condiciones. Si lo hace, debe dejar de usar el sitio web de inmediato. La cancelación o suspensión de su registro no afecta a ningún derecho legal.',
  },
  passwordAndSecurity: {
    id: 'TermsOfUseMessages.passwordAndSecurity',
    defaultMessage: 'Contraseña y seguridad',
  },
  passwordAndSecurityTextP1: {
    id: 'TermsOfUseMessages.passwordAndSecurityTextP1',
    defaultMessage:
      'Cuando se registre en este sitio web, se le pedirá que cree una contraseña, que debe mantener confidencial y no divulgar ni compartir con nadie.',
  },
  passwordAndSecurityTextP2: {
    id: 'TermsOfUseMessages.passwordAndSecurityTextP2',
    defaultMessage:
      'Si tenemos motivos para creer que hay o es probable que haya algún uso indebido del sitio web o violación de la seguridad, podemos solicitarle que cambie su contraseña o suspenda su cuenta.',
  },
  linkToOtherWebsites: {
    id: 'TermsOfUseMessages.linkToOtherWebsites',
    defaultMessage: 'Enlaces a otros sitios web',
  },
  linkToOtherWebsitesTextP1: {
    id: 'TermsOfUseMessages.linkToOtherWebsitesTextP1',
    defaultMessage:
      'Este sitio web puede contener enlaces a otros sitios. A menos que se indique expresamente, estos sitios no están bajo el control  de Bringo Telecom SL o el de nuestros afiliados.',
  },
  linkToOtherWebsitesTextP2: {
    id: 'TermsOfUseMessages.linkToOtherWebsitesTextP2',
    defaultMessage:
      'No asumimos ninguna responsabilidad por el contenido de dichos sitios web y renunciamos a la responsabilidad por todas y cada una de las formas de pérdida o daño que surjan del uso de ellos.',
  },
  linkToOtherWebsitesTextP3: {
    id: 'TermsOfUseMessages.linkToOtherWebsitesTextP3',
    defaultMessage:
      'La inclusión de un enlace a otro sitio en este sitio web no implica ningún respaldo de los sitios en sí mismos o de quienes los controlan.',
  },
  privacyPolicyAndCookieUse: {
    id: 'TermsOfUseMessages.privacyPolicyAndCookieUse',
    defaultMessage: 'Política de privacidad y uso de cookies',
  },
  privacyPolicyAndCookieUseText: {
    id: 'TermsOfUseMessages.privacyPolicyAndCookieUseText',
    defaultMessage:
      'El uso del Sitio Web también se rige por nuestra Política de Privacidad, que también refleja información sobre las cookies, que se incorpora a estos términos y condiciones a través de este enlace. Para leer nuestra Política de Privacidad y Cookies, visite: https://telecorn.com/privacy-policy.',
  },
  websiteAvailabilityAndDisclaimers: {
    id: 'TermsOfUseMessages.websiteAvailabilityAndDisclaimers',
    defaultMessage:
      'Disponibilidad del sitio web y exenciones de responsabilidad',
  },
  websiteAvailabilityAndDisclaimersTextP1: {
    id: 'TermsOfUseMessages.websiteAvailabilityAndDisclaimersTextP1',
    defaultMessage:
      'Cualquier instalación, herramienta, servicio o información en línea que Bringo Telecom SL ponga a disposición a través del sitio web (el Servicio) se proporciona tal cual y según disponibilidad. No garantizamos que el Servicio esté libre de defectos y/o fallas. En la medida máxima permitida por la ley, no ofrecemos garantías (expresas o implícitas) de idoneidad para un propósito particular, exactitud de la información, compatibilidad y calidad satisfactoria.  Bringo Telecom SL no tiene ninguna obligación de actualizar la información en el sitio web.',
  },
  websiteAvailabilityAndDisclaimersTextP2: {
    id: 'TermsOfUseMessages.websiteAvailabilityAndDisclaimersTextP2',
    defaultMessage:
      'Si bien Bringo Telecom SL realiza esfuerzos razonables para garantizar que el sitio web sea seguro y esté libre de errores, virus y otro malware, no damos ninguna garantía a ese respecto y todos los usuarios asumen la responsabilidad de su propia seguridad, la de sus datos personales y sus computadoras.',
  },
  websiteAvailabilityAndDisclaimersTextP3: {
    id: 'TermsOfUseMessages.websiteAvailabilityAndDisclaimersTextP3',
    defaultMessage:
      'Bringo Telecom SL no acepta ninguna responsabilidad por cualquier interrupción o falta de disponibilidad del sitio web.',
  },
  websiteAvailabilityAndDisclaimersTextP4: {
    id: 'TermsOfUseMessages.websiteAvailabilityAndDisclaimersTextP4',
    defaultMessage:
      'Bringo Telecom SL se reserva el derecho de alterar, suspender o interrumpir cualquier parte (o la totalidad) del sitio web, incluidos, entre otros, los productos y / o servicios disponibles. Estos términos y condiciones continuarán aplicándose a cualquier versión modificada del sitio web a menos que se indique expresamente lo contrario.',
  },
  limitationOfLiability: {
    id: 'TermsOfUseMessages.limitationOfLiability',
    defaultMessage: 'Limitación de responsabilidad',
  },
  limitationOfLiabilityTextP1: {
    id: 'TermsOfUseMessages.limitationOfLiabilityTextP1',
    defaultMessage:
      'Nada en estos términos y condiciones: (a) limitará o excluirá nuestra o su responsabilidad por muerte o lesiones personales resultantes de nuestra negligencia o la suya, según corresponda; (b) limitar o excluir nuestra o su responsabilidad por fraude o tergiversación fraudulenta; o (c) limitar o excluir cualquiera de nuestras responsabilidades o las suyas de cualquier manera que no esté permitida por la ley aplicable.',
  },
  limitationOfLiabilityTextP2: {
    id: 'TermsOfUseMessages.limitationOfLiabilityTextP2',
    defaultMessage:
      'No seremos responsables ante usted con respecto a ninguna pérdida que surja de eventos más allá de nuestro control razonable.',
  },
  limitationOfLiabilityTextP3: {
    id: 'TermsOfUseMessages.limitationOfLiabilityTextP3',
    defaultMessage:
      'En la medida máxima permitida por la ley, Bringo Telecom SL no acepta ninguna responsabilidad por cualquiera de los siguientes:',
  },
  limitationOfLiabilityTextP3A: {
    id: 'TermsOfUseMessages.limitationOfLiabilityTextP3A',
    defaultMessage:
      'cualquier pérdida comercial, como pérdida de beneficios, ingresos, ingresos, ahorros anticipados, negocios, contratos, fondo de comercio u oportunidades comerciales;',
  },
  limitationOfLiabilityTextP3B: {
    id: 'TermsOfUseMessages.limitationOfLiabilityTextP3B',
    defaultMessage:
      'pérdida o corrupción de cualquier dato, base de datos o software;',
  },
  limitationOfLiabilityTextP3C: {
    id: 'TermsOfUseMessages.limitationOfLiabilityTextP3C',
    defaultMessage:
      'cualquier pérdida o daño especial, indirecto o consecuente.',
  },
  general: {
    id: 'TermsOfUseMessages.general',
    defaultMessage: 'General',
  },
  generalTextP1: {
    id: 'TermsOfUseMessages.generalTextP1',
    defaultMessage:
      'No puede transferir ninguno de sus derechos bajo estos términos y condiciones a ninguna otra persona. Podemos transferir nuestros derechos bajo estos términos y condiciones cuando creamos razonablemente que sus derechos no se verán afectados.',
  },
  generalTextP2: {
    id: 'TermsOfUseMessages.generalTextP2',
    defaultMessage:
      'Estos términos y condiciones pueden ser modificados por nosotros de vez en cuando. Dichos términos revisados se aplicarán al sitio web a partir de la fecha de publicación. Los usuarios deben revisar los términos y condiciones regularmente para asegurarse de familiaridad con la versión actual en ese momento.',
  },
  generalTextP3: {
    id: 'TermsOfUseMessages.generalTextP3',
    defaultMessage:
      'Estos términos y condiciones y la Política de Privacidad y Política de Cookies contienen el acuerdo completo entre las partes en relación con su objeto y sustituyen a cualquier discusión, entendimiento o acuerdo previo que pueda haber ocurrido con respecto a estos términos y condiciones.',
  },
  generalTextP4: {
    id: 'TermsOfUseMessages.generalTextP4',
    defaultMessage:
      'La Ley de Contratos (Derechos de Terceros) de 1999 no se aplicará a estos términos y condiciones y ningún tercero tendrá derecho a hacer cumplir o confiar en ninguna disposición de estos términos y condiciones.',
  },
  generalTextP5: {
    id: 'TermsOfUseMessages.generalTextP5',
    defaultMessage:
      'Si cualquier tribunal o autoridad competente determina que alguna disposición de estos términos y condiciones (o parte de cualquier disposición) es inválida, ilegal o inaplicable, esa disposición o disposición parcial, en la medida requerida, se considerará eliminada, y la validez y aplicabilidad de las demás disposiciones de estos términos y condiciones no se verán afectadas.',
  },
  generalTextP6: {
    id: 'TermsOfUseMessages.generalTextP6',
    defaultMessage:
      'A menos que se acuerde lo contrario, ninguna demora, acto u omisión por parte de una parte en el ejercicio de cualquier derecho o recurso se considerará una renuncia a ese o cualquier otro derecho o recurso.',
  },
  generalTextP7: {
    id: 'TermsOfUseMessages.generalTextP7',
    defaultMessage:
      'El presente Acuerdo se regirá e interpretará de conformidad con las leyes de España y todas las disputas que surjan en relación con el Acuerdo (incluidas las disputas o reclamaciones no contractuales) estarán sujetas a la jurisdicción exclusiva de los tribunales de España.',
  },
  attribution: {
    id: 'TermsOfUseMessages.attribution',
    defaultMessage: 'Atribución',
  },
  attributionText: {
    id: 'TermsOfUseMessages.attributionText',
    defaultMessage:
      'Estos términos y condiciones se crearon utilizando un documento de Rocket Lawyer (https://www.rocketlawyer.com).',
  },
})
