import {
  ContactDeleteBackendResponse,
  ContactDeleteResponse,
} from '../../../../../types/contact/_crud/delete'

export const mapContactDeleteBackendToContactDelete = (
  item: ContactDeleteBackendResponse,
): ContactDeleteResponse => {
  const { data } = item

  return {
    data,
  }
}
