import React from 'react'
import styles from '../../styles.module.scss'
import { ContactGroupList } from './components/contact-group-list/ContactGroupList'
import { ContactList } from './components/contact-list/ContactList'

export const ContactsDesktopPage = () => {
  return (
    <div className={styles.Container}>
      <ContactList />
      <ContactGroupList />
    </div>
  )
}
