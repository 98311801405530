// @ts-nocheck
import { useState, useEffect, useRef } from 'react';
import { UserAgent, Inviter, Invitation, Registerer, SessionState } from 'sip.js';
import {useModal} from "../../../app/hooks/useModal";

export const useSip = () => {
  const [ua, setUa] = useState(null);
  const [hasOutgoingCall, setHasOutgoingCall] = useState(false);
  const [hasIncomingCall, setHasIncomingCall] = useState(false);
  const [callHasAcceptedByOtherSide, setCallHasAcceptedByOtherSide] = useState(false);
  const [loading, setLoading] = useState(false);
  const callRef = useRef(null);
  const registererRef = useRef(null);
  const audioRef = useRef(null);

  const { handleHideModal } = useModal()

  const params = new URLSearchParams(window.location.search);

  const displayName = params.get('displayName') || '100';
  const uri = params.get('uri') || '';
  const targetUriSip = params.get('targetUriSip') || '';
  const targetUriID = params.get('targetUriID') || '';
  const targetUri = `sip:${targetUriSip}%23${targetUriID}@pbx.telecorn.com`
  const password = params.get('password') || '';
  const socket = params.get('socket') || '';

  useEffect(() => {
    console.log('CONFIG', { displayName, uri, targetUri, password, socket });
  }, []);

  useEffect(() => {
    const userAgent = new UserAgent({
      uri: UserAgent.makeURI(uri),
      transportOptions: {
        wsServers: [socket],
      },
      authorizationUsername: displayName,
      authorizationPassword: password,
      displayName,
    });

    userAgent.start()
      .then(() => {
        registererRef.current = new Registerer(userAgent);
        registererRef.current.register()
          .then(() => console.log("UserAgent registered successfully"))
          .catch((error) => console.error("Failed to register:", error));

        setUa(userAgent);
      })
      .catch((error) => console.error("Failed to start UserAgent:", error));

    userAgent.delegate = {
      onInvite: (invitation) => {
        console.log("Incoming call received");
        callRef.current = invitation;
        setHasIncomingCall(true);

        invitation.delegate = {
          onCancel: () => {
            console.log("Incoming call was canceled by the other side");
            handleRemoteHangup();
          },
          onTerminated: () => {
            console.log("Incoming call was terminated by the other side");
            handleRemoteHangup();
          }
        };
      }
    };

    return () => {
      userAgent.stop();
      if (registererRef.current) registererRef.current.unregister();
    };
  }, [displayName, uri, password, socket]);

  const connectMediaStream = (sessionDescriptionHandler) => {
    const remoteStream = new MediaStream();
    sessionDescriptionHandler.peerConnection.getReceivers().forEach(receiver => {
      if (receiver.track) {
        remoteStream.addTrack(receiver.track);
      }
    });

    if (audioRef.current) {
      audioRef.current.srcObject = remoteStream;
      audioRef.current.play().catch(error => console.error("Failed to play audio:", error));
    }
  };

  const makeCall = () => {
    if (!ua) return;

    setLoading(true);

    const inviter = new Inviter(ua, UserAgent.makeURI(targetUri), {
      sessionDescriptionHandlerOptions: { constraints: { audio: true, video: false } }
    });

    inviter.stateChange.addListener((newState) => {
      console.log("Inviter state changed:", newState);
      if (newState === SessionState.Established) {
        setCallHasAcceptedByOtherSide(true);
        setLoading(false);
      }
      if (newState === SessionState.Terminated) {
        handleRemoteHangup();
      }
    });

    inviter.delegate = {
      onAccept: () => {
      },
      onTerminate: () => {
        console.log("Outgoing call was terminated by the other side");
        handleRemoteHangup();
      }
    };

    inviter.invite()
      .then(() => {
        console.log("Outgoing call initiated");
        callRef.current = inviter;
        setHasOutgoingCall(true);
        connectMediaStream(inviter.sessionDescriptionHandler);
        // setCallHasAcceptedByOtherSide(true);
        setLoading(false);
      })
      .catch((error) => console.error("Failed to make call:", error));
  };

  const answerIncomingCall = () => {
    if (callRef.current && callRef.current instanceof Invitation) {
      callRef.current.accept({ sessionDescriptionHandlerOptions: { constraints: { audio: true, video: false } } })
        .then(() => {
          console.log("Incoming call accepted");
          setCallHasAcceptedByOtherSide(true);
          setHasIncomingCall(true);
          setLoading(false);
          connectMediaStream(callRef.current.sessionDescriptionHandler);
        })
        .catch((error) => console.error("Failed to answer call:", error));
    }
  };

  const endCall = () => {
    if (!callRef.current) return;

    switch (callRef.current.state) {
      case SessionState.Initial:
      case SessionState.Establishing:
        if (callRef.current instanceof Inviter) {
          console.log("Cancelling outgoing call");
          callRef.current.cancel();
        } else {
          console.log("Rejecting incoming call");
          callRef.current.reject();
        }
        break;

      case SessionState.Established:
        console.log("Ending established call");
        callRef.current.bye();
        break;

      case SessionState.Terminating:
      case SessionState.Terminated:
        console.log("Call is already terminating or terminated");
        break;

      default:
        console.log("Unknown session state");
        break;
    }

    resetCallState();
  };

  const handleRemoteHangup = () => {
    if (callRef.current) {
      console.log("Call was terminated by the other side");
      resetCallState();
    }
  };

  const resetCallState = () => {
    setHasOutgoingCall(false);
    setHasIncomingCall(false);
    setCallHasAcceptedByOtherSide(false);
    setLoading(false);
    handleHideModal();

    callRef.current = null;
  };

  return {
    makeCall,
    answerIncomingCall,
    endCall,
    ua,
    hasOutgoingCall,
    hasIncomingCall,
    callHasAcceptedByOtherSide,
    loading,
    setAudioRef: (ref) => (audioRef.current = ref),
  };
};
