import React, { FC } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import styles from './styles.module.scss'

interface SmsViewRedirectionsProps {
  redirectedTo: string[]
}

const SmsViewRedirectionsMessages = defineMessages({
  text: {
    id: 'SmsViewRedirectionsMessages.text',
    defaultMessage: 'Redirect to: {redirections}',
  },
})

export const SmsViewRedirections: FC<SmsViewRedirectionsProps> = (props) => {
  const { redirectedTo } = props

  const intl = useIntl()

  if (redirectedTo.length === 0) return null

  return (
    <div className={styles.Container}>
      {/*TODO icon*/}

      <div className={styles.Redirections}>
        {intl.formatMessage(SmsViewRedirectionsMessages.text, {
          redirections: <span>{redirectedTo.join(', ')}</span>,
        })}
      </div>
    </div>
  )
}
