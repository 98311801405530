import { ReactNode, useState } from 'react'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { IconButton } from '../../../../shared/ui-kit-2/inputs/icon-button/IconButton'
import styles from './styles.module.scss'

type sliderProps = {
  title?: string
  items: Array<ReactNode>
  getCustomTranslationShift?: (currentSlide: number) => number
}

export const Slider = ({
  title,
  items,
  getCustomTranslationShift,
}: sliderProps) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0)
  const [touchStartX, setTouchStartX] = useState<number | null>(null)
  const [touchMoveX, setTouchMoveX] = useState<number | null>(null)
  const isLaptopL = useLessThanDeviceScreen(ScreenDevices.LAPTOP_L)
  const isLessThanTablet = useLessThanDeviceScreen(ScreenDevices.LAPTOP)

  // Don't scroll after (n-3) or (n-1) slide for better visual
  const maxVisibleSlide =
    items.length - (isLessThanTablet ? 1 : isLaptopL ? 2 : 3) || 0
  const translationShift =
    (getCustomTranslationShift && getCustomTranslationShift(currentSlide)) ||
    (isLessThanTablet ? 65 : isLaptopL ? 30 : 26) * -currentSlide

  const handleTouchStart = (event: React.TouchEvent) => {
    event.preventDefault()
    setTouchStartX(event.touches[0].clientX)
  }

  const handleTouchMove = (event: React.TouchEvent) => {
    event.preventDefault()
    if (touchStartX === null) return

    setTouchMoveX(event.touches[0].clientX)
  }

  const handleTouchEnd = () => {
    if (touchStartX === null || touchMoveX === null) return

    const diff = touchStartX - touchMoveX

    if (diff > 0) {
      handleChangeSlide(1) // Свайп влево
    } else if (diff < 0) {
      handleChangeSlide(-1) // Свайп вправо
    }

    setTouchStartX(null)
    setTouchMoveX(null)
  }

  const handleChangeSlide = (value: number) => {
    if (value > 0) {
      setCurrentSlide((prev) =>
        prev + value <= maxVisibleSlide ? prev + value : prev,
      )
    } else {
      setCurrentSlide((prev) => (prev + value >= 0 ? prev + value : 0))
    }
  }

  const Actions = () => (
    <div className={styles.Actions}>
      <IconButton
        additionalClassNames={[styles.Action]}
        icon={'arrowLeft'}
        onClick={() => handleChangeSlide(-1)}
        disabled={currentSlide === 0}
      />
      <IconButton
        additionalClassNames={[styles.Action]}
        icon={'arrowRight'}
        onClick={() => handleChangeSlide(1)}
        disabled={currentSlide === maxVisibleSlide}
      />
    </div>
  )

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        {title && <div className={styles.Title}>{title}</div>}
        {!isLessThanTablet && <Actions />}
      </div>
      <div
        className={styles.Slider}
        style={{ transform: `translateX(${translationShift}rem)` }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {items.map((item) => item)}
      </div>
      {isLessThanTablet && <Actions />}
    </div>
  )
}
