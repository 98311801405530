import {
  SignUpBackendResponse,
  SignUpResponse,
} from '../../../../types/auth/sign-up'

export const mapSignUpBackendToSignUp = (
  item: SignUpBackendResponse,
): SignUpResponse => {
  const { user_id, access_token, refresh_token } = item.data

  return {
    userId: user_id,
    accessToken: access_token,
    refreshToken: refresh_token,
  }
}
