import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { ReactComponent as SuccessPayIcon } from '../../../../assets/icons/payment-success.svg'
import { RoutePath } from '../../../../config/routes/constants/routePath'
import { Route } from '../../../../config/routes/enums/route'
import { PageWrapper } from '../../../../layouts/page-wrapper/PageWrapper'
import { Card } from '../../../../shared/ui-kit-2/data-display/card/Card'
import { Button } from '../../../../shared/ui-kit-2/inputs/button/Button'
import styles from './styles.module.scss'

const AddCardSuccessPageMessages = defineMessages({
  title: {
    id: 'AddCardSuccessPageMessages.title',
    defaultMessage: 'Your card has been added successfully',
  },
  profile: {
    id: 'AddCardSuccessPageMessages.profile',
    defaultMessage: 'Profile',
  },
})

export const AddCardSuccessPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const toOrders = () => navigate(RoutePath[Route.Orders])

  return (
    <PageWrapper>
      <Card additionalClassNames={[styles.Card]}>
        <SuccessPayIcon />
        <div className={styles.DescriptionAndButton}>
          <div className={styles.Description}>
            {intl.formatMessage(AddCardSuccessPageMessages.title)}
          </div>

          <Button
            onClick={toOrders}
            text={intl.formatMessage(AddCardSuccessPageMessages.profile)}
            additionalClassNames={[styles.card_button]}
          />
        </div>
      </Card>
    </PageWrapper>
  )
}
