import {
  OAuthGoogleAuthUrlBackendResponse,
  OAuthGoogleAuthUrlResponse,
} from '../../../../../types/oauth/google/auth-url'

export const mapOAuthGoogleAuthUrlBackendToOAuthGoogleAuthUrl = (
  item: OAuthGoogleAuthUrlBackendResponse,
): OAuthGoogleAuthUrlResponse => {
  const { auth_url } = item.data

  return {
    authUrl: auth_url,
  }
}
