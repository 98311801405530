import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../../../app/hooks/useModal'
import { ReactComponent as BalanceIconMobile } from '../../../../../../../../assets/icons/balance-mobile.svg'
import { ReactComponent as BalanceIcon } from '../../../../../../../../assets/icons/balance.svg'
import { MODAL_TYPES } from '../../../../../../../../layouts/modals/ModalsProvider'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { Button } from '../../../../../../../../shared/ui-kit-2/inputs/button/Button'
import { Card } from '../../../profilePageEn/components/card/Card'
import styles from './styles.module.scss'

const BalanceMessages = defineMessages({
  title: {
    id: 'BalanceMessages.title',
    defaultMessage: 'Balance',
  },
  recharge: {
    id: 'BalanceMessages.recharge',
    defaultMessage: 'Recharge balance',
  },
  rechargeShort: {
    id: 'BalanceMessages.rechargeShort',
    defaultMessage: 'Recharge',
  },
})

export const Balance = () => {
  const intl = useIntl()
  const isMobile = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK)

  return (
    <Card noPadding={{ all: true }}>
      <div className={styles.Balance}>
        <div className={styles.Money}>
          <div className={styles.Label}>
            {intl.formatMessage(BalanceMessages.title)}:
          </div>
          <div className={styles.Counter}>0 ₽</div>
        </div>
        <div className={styles.Action}>
          <Button
            text={intl.formatMessage(
              isMobile
                ? BalanceMessages.rechargeShort
                : BalanceMessages.recharge,
            )}
            onClick={() => {}}
          />
        </div>
      </div>
      <div className={styles.BalanceIcon}>
        {isMobile ? <BalanceIconMobile /> : <BalanceIcon />}
      </div>
      <div className={styles.DevelopInfo}> В разработке</div>
    </Card>
  )
}
