import React, { FC, useState } from 'react'
import clsx from 'clsx'
import { ReactComponent as ErrorIcon } from './assets/icons/error.svg'
import { ReactComponent as EyeClosed } from './assets/icons/eye-closed.svg'
import { ReactComponent as EyeOpened } from './assets/icons/eye-opened.svg'
import { ReactComponent as ValidatedIcon } from './assets/icons/validated.svg'
import styles from './styles.module.scss'

export interface InputPasswordProps extends React.HTMLProps<HTMLInputElement> {
  label?: string
  error?: string
  helperText?: string
  markAsRequired?: boolean
  validationNeeded?: boolean
  inputRef?: React.LegacyRef<HTMLInputElement>
  testId?: string
}

export const InputPassword: FC<InputPasswordProps> = (props) => {
  const {
    label,
    error,
    helperText,
    markAsRequired,
    validationNeeded = true,
    inputRef,
    testId,
    ...otherProps
  } = props

  const [inputType, setInputType] = useState<string>('password')
  const [hasValueOnBlur, setHasValueOnBlur] = useState<boolean>()

  const onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    otherProps.onFocus?.(e)
    setHasValueOnBlur(false)
  }
  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    otherProps.onBlur?.(e)
    setHasValueOnBlur(!!e.target.value)
  }

  const switchInputType = () => {
    if (inputType === 'password') {
      setInputType('text')
    } else {
      setInputType('password')
    }
  }

  return (
    <div className={styles.Container}>
      {label && (
        <label className={styles.Label}>
          {label}
          {markAsRequired && <span>*</span>}
        </label>
      )}

      <div className={styles.Area}>
        <input
          {...otherProps}
          type={inputType}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          ref={inputRef}
          data-test-id={testId}
        />
        <div
          className={clsx(
            styles.Area__background,
            error && styles.Area__background_error,
          )}
        />

        <div className={styles.AdditionalContent}>
          {!error && hasValueOnBlur && validationNeeded && (
            <div className={styles.AdditionalContent__validationElement}>
              <ValidatedIcon />
            </div>
          )}
          {error && validationNeeded && (
            <div className={styles.AdditionalContent__validationElement}>
              <ErrorIcon />
            </div>
          )}

          <div
            className={styles.AdditionalContent__inputTypeSwitch}
            onClick={switchInputType}
          >
            {inputType === 'password' ? <EyeClosed /> : <EyeOpened />}
          </div>
        </div>
      </div>

      {helperText && <div className={styles.HelperText}>{helperText}</div>}
      {error && <div className={styles.Error}>{error}</div>}
    </div>
  )
}
