import React, { useContext } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../app/hooks/useModal'
import { OrdersPageContext } from '../../../../../pages/lk/subpages/orders/contexts/OrdersPageContext'
import { InvoiceListResponse } from '../../../../../sdk/datagates/types/billing/invoice/_crud/list'
import { formatPrice } from '../../../../../sdk/formatters/format-price'
import { useDocuments } from '../../../../../sdk/hooks/use-documents/useDocuments'
import {
  INVOICE_TYPE_IDS,
  useInvoiceTypes,
} from '../../../../../sdk/hooks/use-invoice-types/useInvoiceTypes'
import { formatDate } from '../../../../../shared/lib/utils/date-utils/formatDate'
import { TextButton } from '../../../../../shared/ui-kit-2/inputs/text-button/TextButton'
import { MODAL_TYPES } from '../../../../modals/ModalsProvider'
import styles from './styles.module.scss'

type InvoiceGroupsType = {
  [key: string]: InvoiceListResponse['invoices']
}

const InvoiceTableRowsMessages = defineMessages({
  order: {
    id: 'InvoiceTableRowsMessages.order',
    defaultMessage: 'Order #{orderId}',
  },
  invoice: {
    id: 'InvoiceTableRowsMessages.invoice',
    defaultMessage: 'Invoice',
  },
  receipt: {
    id: 'InvoiceTableRowsMessages.receipt',
    defaultMessage: 'Receipt',
  },
})

export const InvoiceTableRows = (invoices: InvoiceListResponse['invoices']) => {
  const intl = useIntl()
  const { handleOpenModal } = useModal()
  const { fetchOrdersFirstPage } = useContext(OrdersPageContext)
  const { getInvoiceTypeTranslation } = useInvoiceTypes()
  const { handleOpenPdf } = useDocuments()

  const invoiceGroups = invoices.reduce<InvoiceGroupsType>(
    (acc, invoice, idx) => {
      const date = formatDate(invoice.createdAt, 0, 'date')
      if (acc.hasOwnProperty(date)) acc[date as keyof typeof acc].push(invoice)
      else acc[date as keyof typeof acc] = [invoice]

      return acc
    },
    {},
  )

  return Object.entries(invoiceGroups).map(([date, invoices]) => {
    return {
      groupHeader: (
        <div className={styles.Header}>
          <div className={styles.Header__date}>{date}</div>
        </div>
      ),
      rows: invoices.map((invoice) => {
        const handleOpenOrderModal = () =>
          handleOpenModal({
            type: MODAL_TYPES.ORDER,
            props: {
              orderId: invoice.orderId,
              afterSuccessCancelCallback: fetchOrdersFirstPage,
            },
          })

        const type =
          invoice.invoiceTypeId === INVOICE_TYPE_IDS.ORDER ? (
            <TextButton
              text={intl.formatMessage(InvoiceTableRowsMessages.order, {
                orderId: invoice.orderId,
              })}
              onClick={handleOpenOrderModal}
              variant={'small'}
              additionalClassNames={[styles.OrderLink]}
            />
          ) : (
            getInvoiceTypeTranslation(invoice.invoiceTypeId)
          )

        return [
          <div className={styles.SimpleText}>
            {formatDate(invoice.createdAt, 0, 'time')}
          </div>,
          <div className={styles.SimpleText}>{type}</div>,
          <div className={styles.SimpleText}>
            {formatPrice(invoice.amount)}
          </div>,
          <div className={styles.Document}>
            {invoice.invoiceFileId && (
              <TextButton
                text={intl.formatMessage(InvoiceTableRowsMessages.invoice)}
                icon={'pdf'}
                onClick={() =>
                  invoice.invoiceFileId && handleOpenPdf(invoice.invoiceFileId)
                }
                additionalClassNames={[styles.DocumentLink]}
                variant={'small'}
              />
            )}
          </div>,
        ]
      }),
    }
  })
}
