import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserGetResponse } from '../../../sdk/datagates/types/user/_crud/get'
import {UserAutoRenewal, UserInitialState, UserStatuses} from './types'

const ACCESS_TOKEN_LS_KEY = 'access_token'
const REFRESH_TOKEN_LS_KEY = 'refresh_token'
export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_LS_KEY)
export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_LS_KEY)
export const setAccessToken = (token: string) =>
  localStorage.setItem(ACCESS_TOKEN_LS_KEY, token)
export const setRefreshToken = (token: string) =>
  localStorage.setItem(REFRESH_TOKEN_LS_KEY, token)
export const removeAccessToken = () =>
  localStorage.removeItem(ACCESS_TOKEN_LS_KEY)
export const removeRefreshToken = () =>
  localStorage.removeItem(REFRESH_TOKEN_LS_KEY)

const userInitialState: UserInitialState = {
  isAuthChecked: false,
  isAuthenticated: false,
  balance: 0,
  status: UserStatuses.GUEST,
  paymentTypeId: UserAutoRenewal.FROM_BALANCE,
}

const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    clearUser(state) {
      removeAccessToken()
      removeRefreshToken()
      state.isAuthenticated = false
      state.isAuthChecked = true

      state.firstName = undefined
      state.lastName = undefined
      state.companyName = undefined
    },
    setUser(state, { payload }: PayloadAction<UserGetResponse>) {
      state.balance = payload.balance
      state.status = payload.status

      state.isAuthenticated = true
      state.isAuthChecked = true

      state.email = payload.email
      state.firstName = payload.firstName
      state.lastName = payload.lastName
      state.companyName = payload.companyName
      state.vatNumber = payload.vatNumber
      state.billingEmail = payload.billingEmail
      state.phone = payload.userPhone
      state.paymentTypeId = payload.userPaymentTypeId
    },
  },
})

export const { clearUser, setUser} = userSlice.actions
export default userSlice.reducer
