import React, {Dispatch, FC, SetStateAction, useContext, useMemo, useState} from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { useModal } from '../../../../../../app/hooks/useModal'
import { useSnackbar } from '../../../../../../app/hooks/useSnackbar'
import { globalHistory } from '../../../../../../app/providers/with-router/withRouter'
import { RoutePath } from '../../../../../../config/routes/constants/routePath'
import { Route } from '../../../../../../config/routes/enums/route'
import { MODAL_TYPES } from '../../../../../../layouts/modals/ModalsProvider'
import { SNACKBAR_TYPES } from '../../../../../../layouts/snackbar/SnackbarProvider'
import {orderCreateDatagate, orderPayDatagate} from '../../../../../../sdk/datagates/api/order'
import { useBackendErrorCodes } from '../../../../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import {ORDER_PAYMENT_TYPE_IDS, TARIFF_PACKAGE_ID} from '../../../../../../sdk/datagates/types/order/_common'
import { ORDER_STATUSES } from '../../../../../../sdk/hooks/use-order-status/useOrderStatusTranslation'
import { getErrorMessage } from '../../../../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Button } from '../../../../../../shared/ui-kit-2/inputs/button/Button'
import { TextButton } from '../../../../../../shared/ui-kit-2/inputs/text-button/TextButton'
import { useAppSelector } from '../../../../../../store'
import {PURCHASE_STEPS, PurchaseProcessPageContext} from '../../context/PurchaseProcessPageContextProvider'
import { PaymentMethodCard } from './components/PaymentMethodCard/PaymentMethodCard'
import { BankCardPaymentMethod } from './components/paymentsMethods/BankCardPaymentMethod/BankCardPaymentMethod'
import { LegalEntitiesPaymentMethod } from './components/paymentsMethods/LegalEntitiesPaymentMethod/LegalEntitiesPaymentMethod'
import { BalancePaymentMethod } from './components/paymentsMethods/TelecornBalancePaymentMethod/BalancePaymentMethod'
import styles from './styles.module.scss'
import {PurchaseStepsFormType} from "../purchase-steps/PurchaseSteps";
import {getPhoneString} from "../../../../../../shared/lib/utils/libphonenumber/create-phone-string";
import {ORDER_TYPES} from "../../../../../../sdk/hooks/use-order/useOrder";
import {useCreateOrder} from "../../hooks/useCreateOrder";
import {ScreenDevices, useLessThanDeviceScreen} from "../../../../../../shared/lib/hooks/useLessThanDeviceScreen";

interface PurchaseStepPaymentProps {
  formData?: PurchaseStepsFormType
  setStep: Dispatch<SetStateAction<string>>
}

export enum PaymentMethods {
  CARD = 1,
  BALANCE = 2,
  WIRE_TRANSFER = 3,
}

const PaymentPageMessages = defineMessages({
  orderNotFound: {
    id: 'PaymentPageMessages.orderNotFound',
    defaultMessage: 'Order is not found',
  },
  orderCannotBePaid: {
    id: 'PaymentPageMessages.orderCannotBePaid',
    defaultMessage: 'Order cannot be paid',
  },
  title: {
    id: 'PaymentPageMessages.title',
    defaultMessage: 'Payment ORDER {orderNumber}',
  },
  total: {
    id: 'PaymentPageMessages.total',
    defaultMessage: 'Total: {total}',
  },
  pay: {
    id: 'PaymentPageMessages.pay',
    defaultMessage: 'Pay',
  },
  payLater: {
    id: 'PaymentPageMessages.payLater',
    defaultMessage: 'Pay Later'
  },
  payLaterMobile: {
    id: 'PaymentPageMessages.payLaterMobile',
    defaultMessage: 'Later'
  },
  error: {
    id: 'PaymentPageMessages.error',
    defaultMessage: 'An error occurred while payment',
  },
  bankCard: {
    id: 'PaymentPageMessages.bankCard',
    defaultMessage: 'Bank Card',
  },
  balanceCard: {
    id: 'PaymentPageMessages.balanceCard',
    defaultMessage: 'Telecorn balance',
  },
  legalEntityCard: {
    id: 'PaymentPageMessages.legalEntityCard',
    defaultMessage: 'Payment by details',
  },
  back: {
    id: 'PaymentPageMessages.back',
    defaultMessage: 'Back',
  },
})

export const PurchaseStepPayment = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {
    formData,
    setCurrentStep
  } = useContext(PurchaseProcessPageContext)
  const { handleOpenSnackbar } = useSnackbar()
  const { handleOpenModal } = useModal()
  const { balance } = useAppSelector((state) => state.user)
  const [paymentMethod, setPaymentMethod] = useState<number>(
    PaymentMethods.CARD,
  )
  const [loading, setLoading] = useState<boolean>(false)
  const {
    monthPlanCount,
    annualPlanCount,
  } = useContext(PurchaseProcessPageContext)
  const { createOrder } = useCreateOrder({ formData, annualPlanCount, monthPlanCount });
  const isMobile = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK)

  const orderIdFromURL = new URLSearchParams(window.location.search).get('order_id');

  const onBack = () => {
    setCurrentStep(PURCHASE_STEPS.SHIPPING)
  }

  const order = {
    totalPrice: 750,
    orderStatusId: 3
  }

  const isPayWithBalanceDisabled = useMemo(() => {
    return !!(order && order.totalPrice && order.totalPrice > balance)
  }, [balance, order])

  const balanceNotEnoughAmount = useMemo(() => {
    if (!order || !order.totalPrice || order.totalPrice <= balance) return null

    return Math.ceil(order.totalPrice - balance)
  }, [order, balance])

  if (!order || !order.totalPrice) {
    handleOpenSnackbar({
      type: SNACKBAR_TYPES.error,
      text: intl.formatMessage(PaymentPageMessages.orderNotFound),
    })
    navigate(RoutePath[Route.NotFound])
    return null
  }

  if (order.orderStatusId !== ORDER_STATUSES.PAYMENT_WAITING) {
    handleOpenSnackbar({
      type: SNACKBAR_TYPES.error,
      text: intl.formatMessage(PaymentPageMessages.orderCannotBePaid),
    })
    navigate(RoutePath[Route.Orders])
    return null
  }

  const handlePayment = async (withRedirect: boolean = true) => {
    setLoading(true)
    let newOrderId;

    if (!orderIdFromURL) {
      newOrderId = await createOrder(withRedirect);
    }

    const notFormattedOrderId = orderIdFromURL || newOrderId;
    const orderId = notFormattedOrderId ? +notFormattedOrderId : null;

    if (orderId) {
      try {
        const urlParams = [
          {
            name: 'order_id',
            value: orderId.toString(),
          },
        ]

        switch (paymentMethod) {
          case PaymentMethods.CARD:
            handleOpenModal({
              type: MODAL_TYPES.PAYMENT_BANK_CARD_CHOOSE,
              props: {
                orderId,
                onExistingCardPaymentSuccess: () => {
                  navigate(RoutePath[Route.DeviceList])
                },
              },
            })
            return
          case PaymentMethods.BALANCE:
            await orderPayDatagate(
              {
                order_payment_type_id: ORDER_PAYMENT_TYPE_IDS.BALANCE,
                payment_method_id: null,
                payment_provider_id: null,
                order_id: orderId ? +orderId : null
              },
              urlParams,
            )
            navigate(`${RoutePath[Route.PaymentSuccess]}?order_id=${orderId}`)
            return
          case PaymentMethods.WIRE_TRANSFER:
            await orderPayDatagate(
              {
                order_payment_type_id: ORDER_PAYMENT_TYPE_IDS.WIRE_TRANSFER,
                payment_method_id: null,
                payment_provider_id: null,
                order_id: orderId ? +orderId : null
              },
              urlParams,
            )
            handleOpenModal({
              type: MODAL_TYPES.WIRE_TRANSFER_SUCCESS,
              props: {
                onClose: () => globalHistory.push(RoutePath[Route.Orders]),
              },
            })
        }
      } catch (e) {
        navigate(`${RoutePath[Route.PaymentFail]}?order_id=${orderId}`)
      } finally {
        setLoading(false)
      }
    } else {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(PaymentPageMessages.orderNotFound),
      })
    }
  }

  const handlePayLater = async () => {
    if (!orderIdFromURL) {
      await createOrder();
    }

    navigate(RoutePath[Route.Orders])
  }

  return (
    <>
      <div className={styles.StepPaymentWrapper}>
        <div className={styles.PaymentsWrapper}>
          <PaymentMethodCard
            title={intl.formatMessage(PaymentPageMessages.bankCard)}
            paymentMethodType={PaymentMethods.CARD}
            children={<BankCardPaymentMethod />}
            currentMethodType={paymentMethod}
            setPaymentMethod={setPaymentMethod}
          />
          <PaymentMethodCard
            title={intl.formatMessage(PaymentPageMessages.balanceCard)}
            children={
              <BalancePaymentMethod
                balanceNotEnoughAmount={balanceNotEnoughAmount}
              />
            }
            paymentMethodType={PaymentMethods.BALANCE}
            currentMethodType={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            disabled={isPayWithBalanceDisabled}
          />
          <PaymentMethodCard
            title={intl.formatMessage(PaymentPageMessages.legalEntityCard)}
            children={<LegalEntitiesPaymentMethod />}
            paymentMethodType={PaymentMethods.WIRE_TRANSFER}
            currentMethodType={paymentMethod}
            setPaymentMethod={setPaymentMethod}
          />
        </div>
      </div>
      <div className={styles.StepPaymentActionsWrapper}>
        {!orderIdFromURL ? (
          <TextButton
            type={'button'}
            text={intl.formatMessage(PaymentPageMessages.back)}
            icon={'caret-left'}
            onClick={onBack}
          />
        ) : <div></div>}
        <div className={styles.Actions}>
          <Button
            text={intl.formatMessage(isMobile ? PaymentPageMessages.payLaterMobile : PaymentPageMessages.payLater)}
            onClick={handlePayLater}
            additionalClassNames={[styles.Button]}
            variant='greenTextOutlined'
            loading={loading}
            disabled
            // disabled={loading}
          />
          <Button
            text={intl.formatMessage(PaymentPageMessages.pay)}
            onClick={() => handlePayment(false)}
            additionalClassNames={[styles.Button]}
            loading={loading}
            disabled
            // disabled={loading}
          />
        </div>
      </div>
    </>
  )
}
