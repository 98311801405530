import { PAYMENT_PROVIDERS_IDS } from '../../sdk/datagates/types/payment/_common/constants'
import { PaymentMethodCreateResponse } from '../../sdk/datagates/types/payment/payment-method/_crud/create'

export const handlePaymentRedirection = (paymentCredentials: {
  paymentProviderId: number
  [key: string]: string | number
}) => {
  switch (paymentCredentials.paymentProviderId) {
    case PAYMENT_PROVIDERS_IDS.REDSYS:
      handleRedsysRedirection(paymentCredentials)
  }
}

const handleRedsysRedirection = (paymentCredentials: {
  paymentProviderId: number
  [key: string]: string | number
}) => {
  const { algorithm, parametersHash, parametersSign, paymentUrl } =
    paymentCredentials

  if (!algorithm || !parametersHash || !parametersSign || !paymentUrl)
    throw new Error('An error occurred while redirection to payment')

  const alg = algorithm as string
  const hash = parametersHash as string
  const sign = parametersSign as string
  const url = paymentUrl as string

  const form = document.createElement('form')
  form.action = url
  form.method = 'POST'
  form.style.display = 'none'

  const signatureVersionInput = document.createElement('input')
  signatureVersionInput.type = 'text'
  signatureVersionInput.value = alg
  signatureVersionInput.name = 'Ds_SignatureVersion'
  form.appendChild(signatureVersionInput)

  const signatureInput = document.createElement('input')
  signatureInput.type = 'text'
  signatureInput.value = sign
  signatureInput.name = 'Ds_Signature'
  form.appendChild(signatureInput)

  const merchantParametersInput = document.createElement('input')
  merchantParametersInput.type = 'text'
  merchantParametersInput.value = hash
  merchantParametersInput.name = 'Ds_MerchantParameters'
  form.appendChild(merchantParametersInput)

  const submitButton = document.createElement('input')
  submitButton.type = 'submit'
  form.appendChild(submitButton)

  document.body.appendChild(form)

  form.submit()

  setTimeout(() => {
    document.body.removeChild(form)
  }, 0)
}
