import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { TextButton } from '../../shared/ui-kit-2/inputs/text-button/TextButton'
import { LC } from '../../tests/e2e/locators'
import styles from './styles.module.scss'

const useVerificationCodeInputMessages = defineMessages({
  didntGetCode: {
    id: 'useVerificationCodeInputMessages.didntGetCode',
    defaultMessage: "Didn't get the code?",
  },
  clickToResend: {
    id: 'useVerificationCodeInputMessages.clickToResend',
    defaultMessage: 'Click to resend',
  },
  timer: {
    id: 'useVerificationCodeInputMessages.timer',
    defaultMessage: 'The code can be resent in {time} sec',
  },
})

export const useVerificationCodeInput = (
  onEnterComplete: (code: string) => any,
  isLoading: boolean,
  hasErrors: boolean,
  getCode?: () => any,
) => {
  const intl = useIntl()
  const [fullCode, setFullCode] = useState<string>('')
  const [timer, setTimer] = useState<number | undefined>(undefined)

  const refNum1 = useRef<HTMLInputElement>(null)
  const refNum2 = useRef<HTMLInputElement>(null)
  const refNum3 = useRef<HTMLInputElement>(null)
  const refNum4 = useRef<HTMLInputElement>(null)
  const refNum5 = useRef<HTMLInputElement>(null)
  const refNum6 = useRef<HTMLInputElement>(null)

  const [valueNum1, setValueNum1] = useState<string | undefined>(undefined)
  const [valueNum2, setValueNum2] = useState<string | undefined>(undefined)
  const [valueNum3, setValueNum3] = useState<string | undefined>(undefined)
  const [valueNum4, setValueNum4] = useState<string | undefined>(undefined)
  const [valueNum5, setValueNum5] = useState<string | undefined>(undefined)
  const [valueNum6, setValueNum6] = useState<string | undefined>(undefined)

  const node1 = { ref: refNum1, value: valueNum1, setValue: setValueNum1 }
  const node2 = { ref: refNum2, value: valueNum2, setValue: setValueNum2 }
  const node3 = { ref: refNum3, value: valueNum3, setValue: setValueNum3 }
  const node4 = { ref: refNum4, value: valueNum4, setValue: setValueNum4 }
  const node5 = { ref: refNum5, value: valueNum5, setValue: setValueNum5 }
  const node6 = { ref: refNum6, value: valueNum6, setValue: setValueNum6 }

  const linkedList = [
    { ...node1, prevNode: null, nextNode: node2 },
    { ...node2, prevNode: node1, nextNode: node3 },
    { ...node3, prevNode: node2, nextNode: node4 },
    { ...node4, prevNode: node3, nextNode: node5 },
    { ...node5, prevNode: node4, nextNode: node6 },
    { ...node6, prevNode: node5, nextNode: null },
  ]

  useEffect(() => {
    setTimer(60)
  }, [])

  useEffect(() => {
    if (timer) {
      setTimeout(() => {
        setTimer(timer - 1)
      }, 1000)
    } else if (timer === 0) {
      setTimer(undefined)
    }
  }, [timer])

  useEffect(() => {
    if (
      valueNum1 &&
      valueNum2 &&
      valueNum3 &&
      valueNum4 &&
      valueNum5 &&
      valueNum6
    )
      setFullCode(
        `${valueNum1}${valueNum2}${valueNum3}${valueNum4}${valueNum5}${valueNum6}`,
      )
    else setFullCode('')
  }, [valueNum1, valueNum2, valueNum3, valueNum4, valueNum5, valueNum6])

  useEffect(() => {
    if (fullCode.length === 6) {
      onEnterComplete(fullCode)
    }
  }, [fullCode])

  const handleInputsRowClick = () => {
    for (let i = 0; i < linkedList.length; i++) {
      if (!linkedList[i].ref.current?.value) {
        linkedList[i].ref.current?.focus()
        break
      } else if (i === linkedList.length - 1) {
        linkedList[linkedList.length - 1].ref.current?.focus()
        break
      }
    }
  }

  const eraseCurrentInput = () => {
    for (let i = 0; i < linkedList.length; i++) {
      if (linkedList[i].ref.current?.contains(document.activeElement)) {
        linkedList[i].setValue('')
        if (linkedList[i].prevNode) {
          linkedList[i].prevNode?.ref.current?.focus()
        }
        break
      }
    }
  }

  const fillCurrentInput = (newValue: string | undefined) => {
    for (let i = 0; i < linkedList.length; i++) {
      if (linkedList[i].ref.current?.contains(document.activeElement)) {
        if (newValue?.match(/^[0-9]$/)) {
          linkedList[i].setValue(newValue)
        } else {
          linkedList[i].setValue(linkedList[i].value)
          break
        }

        if (linkedList[i].nextNode) {
          linkedList[i].nextNode?.ref.current?.focus()
        } else {
          linkedList[i].ref.current?.blur()
        }
        break
      }
    }
  }

  const fillNextInput = (newValue: string | undefined) => {
    for (let i = 0; i < linkedList.length; i++) {
      if (linkedList[i].ref.current?.contains(document.activeElement)) {
        if (linkedList[i].nextNode) {
          linkedList[i].nextNode?.setValue(newValue)
          linkedList[i].nextNode?.ref.current?.focus()
        } else {
          linkedList[i].ref.current?.blur()
        }
        break
      }
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const value = e.target.value

    if (value.length === 1 && value.match(/^[0-9]$/)) {
      fillCurrentInput(value)
    } else if (value.length === 2 && value.match(/^[0-9]{2}$/)) {
      fillNextInput(value.substring(1, 2))
    } else {
      e.target.value = ''
    }
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      eraseCurrentInput()
    }
  }

  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = e.clipboardData.getData('text')
    const text = pastedValue.replaceAll(' ', '').substring(0, 6)
    const isValid = text.match(/^[0-9]{0,6}$/)
    if (isValid) {
      const values = text.split('').map((x) => x.trim())

      for (let i = 0; i < values.length; i++) {
        linkedList[i].setValue(values[i])
      }

      setTimeout(() => handleInputsRowClick(), 0)
      setTimeout(() => refNum6.current?.blur(), 0)
    }
  }

  const handleClickToResend = () => {
    setTimer(60)
    getCode?.()
  }

  const component = (
    <div className={styles.Container}>
      <div className={styles.InputsRow} onClick={handleInputsRowClick}>
        <input
          type="tel"
          className={clsx(
            styles.Input,
            hasErrors && styles.Input_error,
            isLoading && styles.Input_disabled,
          )}
          placeholder={'0'}
          ref={refNum1}
          value={valueNum1}
          disabled={isLoading}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          data-test-id={LC.FORGOT_PASSWORD_PAGE.VERIFICATION_CODE(0)}
        />

        <input
          type="tel"
          className={clsx(
            styles.Input,
            hasErrors && styles.Input_error,
            isLoading && styles.Input_disabled,
          )}
          placeholder={'0'}
          ref={refNum2}
          value={valueNum2}
          disabled={isLoading}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          data-test-id={LC.FORGOT_PASSWORD_PAGE.VERIFICATION_CODE(1)}
        />

        <input
          type="tel"
          className={clsx(
            styles.Input,
            hasErrors && styles.Input_error,
            isLoading && styles.Input_disabled,
          )}
          placeholder={'0'}
          ref={refNum3}
          value={valueNum3}
          disabled={isLoading}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          data-test-id={LC.FORGOT_PASSWORD_PAGE.VERIFICATION_CODE(2)}
        />

        <input
          type="tel"
          className={clsx(
            styles.Input,
            hasErrors && styles.Input_error,
            isLoading && styles.Input_disabled,
          )}
          placeholder={'0'}
          ref={refNum4}
          value={valueNum4}
          disabled={isLoading}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          data-test-id={LC.FORGOT_PASSWORD_PAGE.VERIFICATION_CODE(3)}
        />

        <input
          type="tel"
          className={clsx(
            styles.Input,
            hasErrors && styles.Input_error,
            isLoading && styles.Input_disabled,
          )}
          placeholder={'0'}
          ref={refNum5}
          value={valueNum5}
          disabled={isLoading}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          data-test-id={LC.FORGOT_PASSWORD_PAGE.VERIFICATION_CODE(4)}
        />

        <input
          type="tel"
          className={clsx(
            styles.Input,
            hasErrors && styles.Input_error,
            isLoading && styles.Input_disabled,
          )}
          placeholder={'0'}
          ref={refNum6}
          value={valueNum6}
          disabled={isLoading}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          data-test-id={LC.FORGOT_PASSWORD_PAGE.VERIFICATION_CODE(5)}
        />
      </div>

      {getCode && (
        <div className={styles.Bottom}>
          <div className={styles.DidntGetCode}>
            {timer
              ? intl.formatMessage(useVerificationCodeInputMessages.timer, {
                  time: timer,
                })
              : intl.formatMessage(
                  useVerificationCodeInputMessages.didntGetCode,
                )}
          </div>

          <TextButton
            variant={'bold'}
            text={intl.formatMessage(
              useVerificationCodeInputMessages.clickToResend,
            )}
            onClick={handleClickToResend}
            disabled={!!timer}
            additionalClassNames={[styles.ClickToResend]}
            testId={LC.FORGOT_PASSWORD_PAGE.RESEND_BTN}
          />
        </div>
      )}
    </div>
  )

  return {
    fullCode: fullCode,
    component: component,
  }
}
