import React, { useMemo, useRef, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../app/hooks/useModal'
import { ReactComponent as GroupIcon } from '../../../../assets/icons/group-icon.svg'
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/submodal-close.svg'
import { CommonButtonMessages } from '../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { formatPhoneNumber } from '../../../../sdk/formatters/format-phone-number'
import { useContacts } from '../../../../sdk/hooks/use-contacts/useContacts'
import { useClickOutside } from '../../../../shared/lib/hooks/useClickOutside'
import { Card } from '../../../../shared/ui-kit-2/data-display/card/Card'
import { Counter } from '../../../../shared/ui-kit-2/data-display/counter/Counter'
import { Button } from '../../../../shared/ui-kit-2/inputs/button/Button'
import {
  Checkbox,
  CheckboxProps,
} from '../../../../shared/ui-kit-2/inputs/checkbox/Checkbox'
import { Input } from '../../../../shared/ui-kit-2/inputs/input/Input'
import { LoadingContent } from '../../../loading-content/LoadingContent'
import { MODAL_TYPES } from '../../ModalsProvider'
import { Source } from '../use-add-source/useAddSource'
import styles from './styles.module.scss'

interface UseAddContactsProps {
  onSave: (source: Source[]) => void
  sourceList: Source[]
  selectedContact?: string[]
  contactGroupId?: number
}

const UseAddContactsMessages = defineMessages({
  title: {
    id: 'UseAddContactsMessages.title',
    defaultMessage: 'Choose contacts',
  },
  contactsCardTitle: {
    id: 'UseAddContactsMessages.contactsCardTitle',
    defaultMessage: 'Contacts',
  },
  searchPlaceholder: {
    id: 'UseAddContactsMessages.searchPlaceholder',
    defaultMessage: 'Search by name or phone number',
  },
  selected: {
    id: 'UseAddContactsMessages.selected',
    defaultMessage: 'Selected',
  },
})

export const useAddContacts = (props: UseAddContactsProps) => {
  const { onSave, sourceList, contactGroupId } = props
  const selectedContactsIdsDefault = sourceList.map((el) => el.sourceValue)
  const intl = useIntl()
  const { contacts, loading: contactsLoading } = useContacts({})
  const [selectedContactIds, setSelectedContactIds] = useState<string[]>(
    selectedContactsIdsDefault,
  )
  const [searchValue, setSearchValue] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const { handleOpenModal } = useModal()

  const createContactHandler = () => {
    handleOpenModal({
      type: MODAL_TYPES.CONTACT_SETTINGS_MODAL,
      props: {
        selectedContacts: selectedContactIds,
        backTo: true,
        contactGroupId: contactGroupId,
      },
    })
  }

  const onClose = () => {
    setIsOpen(false)
  }

  useClickOutside(ref, onClose)

  const onSubmit = () => {
    const selectedContacts = contacts.filter((c) =>
      selectedContactIds.includes(c.contactId.toString()),
    )

    const sourcesToUpdate = selectedContacts.map<Source>((c) => ({
      sourceType: 'contacts',
      sourceName: c.name + ' ' + formatPhoneNumber(c.phone),
      sourceValue: c.contactId.toString(),
      metaInformation: {
        name: c.name,
        phone: formatPhoneNumber(c.phone),
      },
    }))

    onSave(sourcesToUpdate)
    setIsOpen(false)
  }

  const onContactClick = (contactId: string) => {
    if (selectedContactIds.includes(contactId)) {
      setSelectedContactIds((prev) => prev.filter((c) => c !== contactId))
    } else {
      setSelectedContactIds((prev) => [...prev, contactId])
    }
  }

  const contactsCheckboxes = contacts.map<CheckboxProps>((c) => ({
    key: c.contactId.toString(),
    name: 'contacts',
    text: c.name,
    subText: formatPhoneNumber(c.phone),
    checked: selectedContactIds.includes(c.contactId.toString()),
  }))

  const searchComponent = (
    <Input
      value={searchValue}
      // @ts-ignore
      onChange={(e) => setSearchValue(e.target.value)}
      placeholder={intl.formatMessage(UseAddContactsMessages.searchPlaceholder)}
      inputSize={'small'}
      postfix={<SearchIcon />}
      validationNeeded={false}
    />
  )

  const contactsCheckboxesFiltered = useMemo(
    () =>
      contactsCheckboxes
        .filter((c) => {
          if (typeof c.text === 'string')
            return (
              c.text?.toLowerCase().includes(searchValue.toLowerCase()) ||
              c.subText?.toLowerCase().includes(searchValue.toLowerCase())
            )
        })
        .sort((a, b) => {
          if (a.text && b.text) {
            if (a.text < b.text) return -1
            if (a.text > b.text) return 1

            if (a.subText && b.subText) {
              if (a.subText < b.subText) return -1
              if (a.subText > b.subText) return 1
            }
          }
          return 0
        }),
    [contactsCheckboxes, searchValue],
  )

  const content = (
    <div className={styles.Container}>
      <div className={styles.GrayCard}>
        <div className={styles.GrayCardHeader}>
          <div>
            {intl.formatMessage(UseAddContactsMessages.contactsCardTitle)}
          </div>
          <div className={styles.CounterContainer}>
            <GroupIcon />
            <div>{intl.formatMessage(UseAddContactsMessages.selected)}</div>
            <Counter
              count={selectedContactIds.length}
              size={'small'}
              variant={'filled'}
            />
          </div>
        </div>

        <div className={styles.Form}>
          {searchComponent}
          <div className={styles.ScrollableContent}>
            {contactsCheckboxesFiltered.map((cc) => (
              <Checkbox
                key={cc.key}
                checked={cc.checked}
                text={cc.text}
                subText={cc.subText}
                onChange={() => onContactClick(cc.key as string)}
                variant={'outlined'}
                additionalClassNames={[styles.ContactCheckbox]}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={styles.ActionButtons}>
        <Button
          additionalClassNames={[styles.CrateNewContactButton]}
          variant={'white'}
          text={intl.formatMessage(CommonButtonMessages.newContact)}
          onClick={createContactHandler}
        />
        <Button
          text={intl.formatMessage(CommonButtonMessages.add)}
          onClick={onSubmit}
        />
      </div>
    </div>
  )

  const component = (
    <div className={styles.Overlay}>
      <Card additionalClassNames={[styles.CardAdditional]} currentRef={ref}>
        <div className={styles.Header}>
          <div className={styles.Title}>
            {intl.formatMessage(UseAddContactsMessages.title)}
          </div>
          <CloseIcon className={styles.Close} onClick={onClose} />
        </div>

        <LoadingContent loading={contactsLoading} content={content} />
      </Card>
    </div>
  )

  return {
    setIsOpen,
    setSelectedContactIds,
    component: isOpen ? component : null,
  }
}
