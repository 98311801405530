import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { useSiteVersion } from '../../../../app/hooks/useSiteVersion'
import { AuthRoutePath } from '../../../../config/routes/constants/authRoutePath'
import { AuthRoute } from '../../../../config/routes/enums/authRoute'
import { Card } from '../../../../shared/ui-kit-2/data-display/card/Card'
import { Tabs, TabType } from '../../../../shared/ui-kit-2/navigation/tabs/Tabs'
import { LC } from '../../../../tests/e2e/locators'
import { SocialMediaAuth } from '../../components/social-media-auth/SocialMediaAuth'
import { SignInForm } from './components/sign-in-form/SignInForm'
import styles from './styles.module.scss'

const SIGN_IN_TAB_IDS = {
  SIGN_IN: 'sign-in-page',
  SIGN_UP: 'sign-up-page',
}

const SignInPageMessages = defineMessages({
  logIn: {
    id: 'SignInPageMessages.logIn',
    defaultMessage: 'Log in',
  },
  register: {
    id: 'SignInPageMessages.register',
    defaultMessage: 'Registration',
  },
})

export const SignInPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { siteVersion } = useSiteVersion()

  const onTabChange = (id: string) => {
    if (id === SIGN_IN_TAB_IDS.SIGN_UP)
      navigate(AuthRoutePath[AuthRoute.SignUp])
  }

  const tabs: TabType[] = [
    {
      id: SIGN_IN_TAB_IDS.SIGN_UP,
      label: intl.formatMessage(SignInPageMessages.register),
      testId: LC.SIGN_IN_PAGE.SIGN_UP_LINK,
    },
    {
      id: SIGN_IN_TAB_IDS.SIGN_IN,
      label: intl.formatMessage(SignInPageMessages.logIn),
    },
  ]

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <div className={styles.CardContent}>
        <Tabs
          items={tabs}
          defaultTabId={SIGN_IN_TAB_IDS.SIGN_IN}
          handleChange={onTabChange}
          additionalClassNames={[styles.TabsAdditional]}
        />

        <SignInForm />

        {siteVersion !== 'ru' && <SocialMediaAuth />}
      </div>
    </Card>
  )
}
