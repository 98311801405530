import React, { useMemo, useRef, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useSnackbar } from '../../../../app/hooks/useSnackbar'
import { ReactComponent as GroupIcon } from '../../../../assets/icons/group-icon.svg'
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/submodal-close.svg'
import { CommonButtonMessages } from '../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { useContactGroups } from '../../../../sdk/hooks/use-contact-groups/useContactGroups'
import { useClickOutside } from '../../../../shared/lib/hooks/useClickOutside'
import { Card } from '../../../../shared/ui-kit-2/data-display/card/Card'
import { Counter } from '../../../../shared/ui-kit-2/data-display/counter/Counter'
import { Button } from '../../../../shared/ui-kit-2/inputs/button/Button'
import {
  Checkbox,
  CheckboxProps,
} from '../../../../shared/ui-kit-2/inputs/checkbox/Checkbox'
import { Input } from '../../../../shared/ui-kit-2/inputs/input/Input'
import { LoadingContent } from '../../../loading-content/LoadingContent'
import { SNACKBAR_TYPES } from '../../../snackbar/SnackbarProvider'
import { Source } from '../use-add-source/useAddSource'
import styles from './styles.module.scss'

interface UseAddContactGroupsProps {
  onSave: (source: Source) => void
  sourceList: Source[]
}

const UseAddContactsMessages = defineMessages({
  title: {
    id: 'UseAddContactsMessages.title',
    defaultMessage: 'Choose contact groups',
  },
  contactsCardTitle: {
    id: 'UseAddContactsMessages.contactsCardTitle',
    defaultMessage: 'Contact groups',
  },
  searchPlaceholder: {
    id: 'UseAddContactsMessages.searchPlaceholder',
    defaultMessage: 'Search by name or phone number',
  },
  selected: {
    id: 'UseAddContactsMessages.selected',
    defaultMessage: 'Selected',
  },
  contacts: {
    id: 'UseAddContactsMessages.contacts',
    defaultMessage: 'Contacts',
  },
  duplicates: {
    id: 'UseAddContactsMessages.duplicates',
    defaultMessage:
      'Some of the selected contact groups are already in the list. Only new contact groups were added.',
  },
  emptyError: {
    id: 'UseAddContactsMessages.emptyError',
    defaultMessage: 'Please select at least one option',
  },
})

export const useAddContactGroups = (props: UseAddContactGroupsProps) => {
  const { onSave, sourceList } = props

  const intl = useIntl()
  const { handleOpenSnackbar } = useSnackbar()
  const { contactGroups, loading: contactGroupsLoading } = useContactGroups({})
  const [selectedContactGroupIds, setSelectedContactGroupIds] = useState<
    string[]
  >([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  const onClose = () => {
    setSelectedContactGroupIds([])
    setIsOpen(false)
  }

  useClickOutside(ref, onClose)

  const onSubmit = () => {
    if (
      selectedContactGroupIds.some((cgId) =>
        sourceList.some((s) => s.sourceValue === cgId),
      )
    ) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.warning,
        text: intl.formatMessage(UseAddContactsMessages.duplicates),
      })
    }

    if (selectedContactGroupIds.length === 0) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(UseAddContactsMessages.emptyError),
      })
      return
    }

    if (selectedContactGroupIds.length > 0) {
      const selectedContacts = contactGroups.filter((cg) => {
        return (
          selectedContactGroupIds.includes(cg.contactGroupId.toString()) &&
          !sourceList.some(
            (s) => s.sourceValue === cg.contactGroupId.toString(),
          )
        )
      })

      selectedContacts.forEach((c) => {
        onSave({
          sourceType: 'contact-groups',
          sourceName: c.name,
          sourceValue: c.contactGroupId.toString(),
          metaInformation: {
            contactsCount: c.contactsCount,
          },
        })
      })

      setIsOpen(false)
      setSelectedContactGroupIds([])
    }
  }

  const onContactGroupClick = (contactGroupId: string) => {
    if (selectedContactGroupIds.includes(contactGroupId)) {
      setSelectedContactGroupIds((prev) =>
        prev.filter((id) => id !== contactGroupId),
      )
    } else {
      setSelectedContactGroupIds((prev) => [...prev, contactGroupId])
    }
  }

  const contactsCheckboxes = contactGroups.map<CheckboxProps>((c) => ({
    key: c.contactGroupId.toString(),
    name: 'contacts',
    text: c.name,
    textReplacedElement: (
      <div className={styles.CheckboxText}>
        <div>{c.name}</div>
        <Counter
          count={c.contactsCount}
          text={intl.formatMessage(UseAddContactsMessages.contacts)}
          size={'small'}
        />
      </div>
    ),
    checked: selectedContactGroupIds.includes(c.contactGroupId.toString()),
  }))

  const searchComponent = (
    <Input
      value={searchValue}
      // @ts-ignore
      onChange={(e) => setSearchValue(e.target.value)}
      placeholder={intl.formatMessage(UseAddContactsMessages.searchPlaceholder)}
      inputSize={'small'}
      postfix={<SearchIcon />}
      validationNeeded={false}
    />
  )

  const contactsCheckboxesFiltered = useMemo(
    () =>
      contactsCheckboxes
        .filter((c) => {
          if (typeof c.text === 'string')
            return (
              c.text?.toLowerCase().includes(searchValue.toLowerCase()) ||
              c.subText?.toLowerCase().includes(searchValue.toLowerCase())
            )
        })
        .sort((a, b) => {
          if (a.text && b.text) {
            if (a.text < b.text) return -1
            if (a.text > b.text) return 1

            if (a.subText && b.subText) {
              if (a.subText < b.subText) return -1
              if (a.subText > b.subText) return 1
            }
          }
          return 0
        }),
    [contactsCheckboxes, searchValue],
  )

  const content = (
    <div className={styles.Container}>
      <div className={styles.GrayCard}>
        <div className={styles.GrayCardHeader}>
          <div className={styles.GrayCardTitle}>
            {intl.formatMessage(UseAddContactsMessages.contactsCardTitle)}
          </div>
          <div className={styles.CounterContainer}>
            <GroupIcon />
            <div className={styles.GrayCardTitle}>
              {intl.formatMessage(UseAddContactsMessages.selected)}
            </div>
            <Counter
              count={selectedContactGroupIds.length}
              size={'small'}
              variant={'filled'}
            />
          </div>
        </div>

        <div className={styles.Form}>
          {searchComponent}
          <div className={styles.ScrollableContent}>
            {contactsCheckboxesFiltered.map((cc) => (
              <Checkbox
                key={cc.key}
                checked={cc.checked}
                text={cc.text}
                textReplacedElement={cc.textReplacedElement}
                onChange={() => onContactGroupClick(cc.key as string)}
                variant={'outlined'}
                additionalClassNames={[styles.ContactCheckbox]}
              />
            ))}
          </div>
        </div>
      </div>

      <Button
        text={intl.formatMessage(CommonButtonMessages.add)}
        onClick={onSubmit}
      />
    </div>
  )

  const component = (
    <div className={styles.Overlay}>
      <Card additionalClassNames={[styles.CardAdditional]} currentRef={ref}>
        <div className={styles.Header}>
          <div className={styles.Title}>
            {intl.formatMessage(UseAddContactsMessages.title)}
          </div>
          <CloseIcon className={styles.Close} onClick={onClose} />
        </div>

        <LoadingContent loading={contactGroupsLoading} content={content} />
      </Card>
    </div>
  )

  return {
    setIsOpen,
    component: isOpen ? component : null,
  }
}
