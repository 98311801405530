import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import messageImage from '../../../assets/images/message.png'
import { Tip } from '../Tip'

const SmsMailingInProgressTipMessages = defineMessages({
  text: {
    id: 'SmsMailingInProgressTipMessages.text',
    defaultMessage: 'We send messages every 3-10 seconds to avoid blocking',
  },
})

export const SmsMailingInProgressTip = () => {
  const intl = useIntl()

  return (
    <Tip
      imageSource={messageImage}
      text={intl.formatMessage(SmsMailingInProgressTipMessages.text)}
    />
  )
}
