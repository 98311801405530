import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CartInitialState } from './types'

const CART_MONTH_PLAN_COUNT_LS_KEY = 'cart_month_plan_count'
const CART_ANNUAL_PLAN_COUNT_LS_KEY = 'cart_annual_plan_count'
export const setCartMonthPlanCount = (count: number) =>
  localStorage.setItem(CART_MONTH_PLAN_COUNT_LS_KEY, count.toString())
export const setCartAnnualPlanCount = (count: number) =>
  localStorage.setItem(CART_ANNUAL_PLAN_COUNT_LS_KEY, count.toString())
export const removeCartMonthPlanCount = () =>
  localStorage.removeItem(CART_MONTH_PLAN_COUNT_LS_KEY)
export const removeCartAnnualPlanCount = () =>
  localStorage.removeItem(CART_ANNUAL_PLAN_COUNT_LS_KEY)

const getSafeCartTariffPlanCount = (
  localStorageKey: 'cart_month_plan_count' | 'cart_annual_plan_count',
): number => {
  const value: string | null = localStorage.getItem(localStorageKey)
  if (value === null || Number.isInteger(value)) return 0
  return +value
}

const cartInitialState: CartInitialState = {
  monthPlanCount: getSafeCartTariffPlanCount(CART_MONTH_PLAN_COUNT_LS_KEY),
  annualPlanCount: getSafeCartTariffPlanCount(CART_ANNUAL_PLAN_COUNT_LS_KEY),
}

const cartSlice = createSlice({
  name: 'user',
  initialState: cartInitialState,
  reducers: {
    clearCart(state) {
      state.monthPlanCount = 0
      state.annualPlanCount = 0
      removeCartMonthPlanCount()
      removeCartAnnualPlanCount()
    },
    setCart(
      state,
      {
        payload,
      }: PayloadAction<{ monthPlanCount: number; annualPlanCount: number }>,
    ) {
      state.monthPlanCount = payload.monthPlanCount
      state.annualPlanCount = payload.annualPlanCount

      setCartMonthPlanCount(payload.monthPlanCount)
      setCartAnnualPlanCount(payload.annualPlanCount)
    },
  },
})

export const { clearCart, setCart } = cartSlice.actions
export default cartSlice.reducer
