import {useDispatch} from "react-redux";
import {clearDialog, setDialog} from "../../store/reducers/dialog";

export const useDialog = () => {
  const dispatch = useDispatch()

  const handleOpenDialog = ({ props } : { props: Record<string, unknown> }) => {
    dispatch(
      setDialog({
        isOpen: true,
        props
      })
    )
  }

  const handleHideDialog = () => dispatch(clearDialog())

  return {
    handleOpenDialog,
    handleHideDialog
  }
}