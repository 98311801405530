import { useEffect, useState } from 'react'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { SNACKBAR_TYPES } from '../../../layouts/snackbar/SnackbarProvider'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import { invoiceListDatagate } from '../../datagates/api/billing'
import { useBackendErrorCodes } from '../../datagates/helpers/_common/use-backend-error-codes'
import { InvoiceListResponse } from '../../datagates/types/billing/invoice/_crud/list'
import { INVOICE_TYPE_IDS } from '../use-invoice-types/useInvoiceTypes'
import { ORDER_TYPES } from '../use-order/useOrder'

type UseInvoicesProps = {
  page: number
  limit: number
  orderTypes?: number[]
}

export const useInvoices = (props: UseInvoicesProps) => {
  const {
    page,
    limit,
    orderTypes = [ORDER_TYPES.DEVICES_WITH_SUBSCRIPTION],
  } = props

  const { resolveBackendError } = useBackendErrorCodes()
  const { handleOpenSnackbar } = useSnackbar()

  const [loading, setLoading] = useState<boolean>(true)
  const [invoices, setInvoices] = useState<InvoiceListResponse['invoices']>([])
  const [totalCount, setTotalCount] = useState<number>(0)

  const handleFetch = async () => {
    try {
      setLoading(true)

      const queryParams = [
        { name: 'page', value: (page - 1).toString() },
        { name: 'limit', value: limit.toString() },
        { name: 'order_by', value: 'created_at' },
        { name: 'order_type', value: 'desc' },
        {
          name: 'invoice_type_ids',
          value: [
            INVOICE_TYPE_IDS.ORDER,
            INVOICE_TYPE_IDS.CREDIT,
            INVOICE_TYPE_IDS.AUTHORIZE,
            INVOICE_TYPE_IDS.REFUND,
            INVOICE_TYPE_IDS.WIRE_TRANSFER,
            INVOICE_TYPE_IDS.TARIFF_CREDIT,
          ].map((t) => t.toString()),
        },
        { name: 'order_type_ids', value: orderTypes.map((o) => o.toString()) },
      ]

      const { data } = await invoiceListDatagate(null, null, queryParams)

      setTotalCount(data.totalCount)
      setInvoices(data.invoices)
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void handleFetch()
  }, [page, limit])

  return {
    invoices,
    totalCount,
    loading,
    handleFetch,
  }
}
