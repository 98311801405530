import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import styles from './styles.module.scss'

const LegalEntitiesPaymentMethodMessages = defineMessages({
  title: {
    id: 'LegalEntitiesPaymentMethodMessages.title',
    defaultMessage:
      'Payment by bank transfer (SEPA/WIRE)  for legal entities. Our manager will contact you with further details.',
  },
})

export const LegalEntitiesPaymentMethod = () => {
  const intl = useIntl()

  return (
    <div className={styles.Wrapper}>
      {intl.formatMessage(LegalEntitiesPaymentMethodMessages.title)}
    </div>
  )
}
