import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from 'react'
import { StepConnectDevice } from '../components/step-connect-device/StepConnectDevice'
import { StepSubscriptionPayment } from '../components/step-subscription-payment/StepSubscriptionPayment'

export enum ACTIVATE_DEVICE_STEPS {
  CONNECT,
  SUBSCRIPTION_PAYMENT,
}

interface ActivateDeviceContextProps {
  currentStep: ACTIVATE_DEVICE_STEPS
  setCurrentStep: Dispatch<SetStateAction<ACTIVATE_DEVICE_STEPS>>
  imeiLastSixDigits: string
  setImeiLastSixDigits: Dispatch<SetStateAction<string>>
  stepContent: ReactNode
}

interface ActivateDeviceContextProviderProps {}

export const ActivateDeviceContext = createContext<ActivateDeviceContextProps>({
  currentStep: ACTIVATE_DEVICE_STEPS.CONNECT,
  setCurrentStep: () => {},
  imeiLastSixDigits: '',
  setImeiLastSixDigits: () => {},
  stepContent: null,
})

export const ActivateDeviceContextProvider: FC<
  ActivateDeviceContextProviderProps
> = ({}) => {
  const [currentStep, setCurrentStep] = useState<ACTIVATE_DEVICE_STEPS>(
    ACTIVATE_DEVICE_STEPS.CONNECT,
  )
  const [imeiLastSixDigits, setImeiLastSixDigits] = useState<string>('')

  const stepContent = useMemo(() => {
    switch (currentStep) {
      case ACTIVATE_DEVICE_STEPS.CONNECT:
        return <StepConnectDevice />
      case ACTIVATE_DEVICE_STEPS.SUBSCRIPTION_PAYMENT:
        return <StepSubscriptionPayment />
      default:
        return null
    }
  }, [currentStep])

  return (
    <ActivateDeviceContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        imeiLastSixDigits,
        setImeiLastSixDigits,
        stepContent,
      }}
    >
      {stepContent}
    </ActivateDeviceContext.Provider>
  )
}
