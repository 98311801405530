import {
  DongleSmsGetBackendResponse,
  DongleSmsGetResponse,
} from '../../../../../../types/dongle/sms/_crud/get'

export const mapDongleSmsGetBackendToDongleSmsGet = (
  item: DongleSmsGetBackendResponse,
): DongleSmsGetResponse => {
  const {
    sms_id,
    dongle_id,
    sms_type_id,
    number_caller,
    number_receiver,
    message_decoded,
    api_response_data,
    created_at,
    updated_at,
  } = item.data

  return {
    smsId: sms_id,
    dongleId: dongle_id,
    smsTypeId: sms_type_id,
    numberCaller: number_caller,
    numberReceiver: number_receiver,
    messageDecoded: message_decoded,
    apiResponseData: api_response_data,
    createdAt: created_at,
    updatedAt: updated_at,
  }
}
