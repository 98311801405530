import { ApiPage } from '../../../../../../../pages/lk/subpages/api/ApiPage'
import { AuthRoutePath } from '../../../../../constants/authRoutePath'
import { RoutePath } from '../../../../../constants/routePath'
import { AuthRoute } from '../../../../../enums/authRoute'
import { Route } from '../../../../../enums/route'
import { RouteDescriptor } from '../../../../../types/routeDescriptor'

export const api: RouteDescriptor<Route.Api> = {
  route: Route.Api,
  type: 'subpage',
  title: 'Telecorn',
  path: RoutePath[Route.Api],
  render: ApiPage,
  accessLevel: 'user',
  noAccessRedirectPath: AuthRoutePath[AuthRoute.SignIn],
}
