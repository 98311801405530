import React from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { globalHistory } from '../../../app/providers/with-router/withRouter'
import { CommonButtonMessages } from '../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { RoutePath } from '../../../config/routes/constants/routePath'
import { Route } from '../../../config/routes/enums/route'
import { TARIFF_PACKAGE_ID } from '../../../sdk/datagates/types/order/_common'
import { formatDeliveryAddress } from '../../../sdk/formatters/format-delivery-address'
import { formatPhoneNumber } from '../../../sdk/formatters/format-phone-number'
import { formatPrice } from '../../../sdk/formatters/format-price'
import { formatRecipient } from '../../../sdk/formatters/format-recipient'
import { useCountries } from '../../../sdk/hooks/use-countries/useCountries'
import {
  ORDER_STATUSES,
  useOrderStatus,
} from '../../../sdk/hooks/use-order-status/useOrderStatusTranslation'
import { useOrder } from '../../../sdk/hooks/use-order/useOrder'
import { useTariffPackages } from '../../../sdk/hooks/use-tariff-packages/useTariffPackages'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import { Loader } from '../../../shared/ui-kit/data-display/loader/Loader'
import { SNACKBAR_TYPES } from '../../snackbar/SnackbarProvider'
import {ReactComponent as FedexIcon} from "../../../assets/icons/fedex.svg";
import { ReactComponent as CopyIcon } from  "../../../assets/icons/copy-icon.svg";
import styles from './styles.module.scss'
import {copyToClipboard} from "../../../shared/lib/utils/copy-to-clipboard/copyToClipboard";

export const OrderModalMessages = defineMessages({
  title: {
    id: 'OrderModalMessages.title',
    defaultMessage: 'Order #{orderId}',
  },
  totalAmount: {
    id: 'OrderModalMessages.totalAmount',
    defaultMessage: 'Total: {amount}',
  },
  deliveryAddress: {
    id: 'OrderModalMessages.deliveryAddress',
    defaultMessage: 'Delivery address',
  },
  recipient: {
    id: 'OrderModalMessages.recipient',
    defaultMessage: 'Recipient',
  },
  annualPlan: {
    id: 'OrderModalMessages.annualPlan',
    defaultMessage: 'Annual subscription',
  },
  modem: {
    id: 'OrderModalMessages.modem',
    defaultMessage: 'modem',
  },
  monthlyPlan: {
    id: 'OrderModalMessages.monthlyPlan',
    defaultMessage: 'Monthly subscription',
  },
  delivery: {
    id: 'OrderModalMessages.delivery',
    defaultMessage: 'Delivery',
  },
  free: {
    id: 'OrderModalMessages.free',
    defaultMessage: 'FREE',
  },
  deliveryDescription: {
    id: 'OrderModalMessages.deliveryDescription',
    defaultMessage: 'Shipping cost has changed',
  },
  calculated: {
    id: 'OrderModalMessages.calculated',
    defaultMessage: 'Will be calculated',
  },
  openingError: {
    id: 'OrderModalMessages.openingError',
    defaultMessage: 'An error occurred while opening the order',
  },
  handleCopy: {
    id: 'OrderModalMessages.handleCopy',
    defaultMessage: 'Track number was copied to clipboard',
  },
  copy: {
    id: 'OrderModalMessages.copy',
    defaultMessage: 'Copy',
  },
  orderTrackNumberTitle: {
    id: 'OrderModalMessages.orderTrackNumberTitle',
    defaultMessage: 'Your order has been sent',
  },
  orderTrackNumber: {
    id: 'OrderModalMessages.orderTrackNumber',
    defaultMessage: 'Your track number:',
  },
})

export const OrderModal = () => {
  const { handleHideModal, props } = useModal()

  const { orderId, afterSuccessCancelCallback } = props

  const intl = useIntl()
  const { handleOpenSnackbar } = useSnackbar()
  const { countries } = useCountries()
  const { order, loading, openCancelOrderModal } = useOrder({ orderId })
  const { getTariffPackagePrice } = useTariffPackages()
  const {
    getOrderStatusStickerByStatusId,
    getOrderCancellationAvailableByStatusId,
  } = useOrderStatus()
  const { statusComponent } = getOrderStatusStickerByStatusId(
    order?.orderStatusId ?? 0,
  )
  const { isCancellationAvailable } = getOrderCancellationAvailableByStatusId(
    order?.orderStatusId ?? 0,
  )

  const toPayment = () => {
    order &&
      globalHistory.push(
        RoutePath[Route.PurchaseProcess] + '?order_id=' + order.orderId,
      )
    handleHideModal()
  }

  if (loading) {
    return (
      <Drawer
        isOpen={true}
        close={handleHideModal}
        title={intl.formatMessage(OrderModalMessages.title, { orderId: '' })}
        additionalClassNames={[styles.DrawerAdditional]}
      >
        <Loader />
      </Drawer>
    )
  }

  if (!order) {
    handleOpenSnackbar({
      type: SNACKBAR_TYPES.error,
      text: intl.formatMessage(OrderModalMessages.openingError),
    })
    handleHideModal()
    return null
  }

  const countryName: string | undefined = countries.find(
    (c) => c.id === order?.countryId,
  )?.name
  const monthPlanCount =
    order?.orderDongles.filter(
      (od) => od.tariffPackageId === TARIFF_PACKAGE_ID.MONTH,
    )?.length ?? 0
  const annualPlanCount =
    order?.orderDongles.filter(
      (od) => od.tariffPackageId === TARIFF_PACKAGE_ID.ANNUAL,
    )?.length ?? 0

  const isDeliveryPriceChange = order.deliveryPrice !== order.deliveryPriceOld;
  const handleCopyTrackNumber = () => {
    handleOpenSnackbar({
      type: SNACKBAR_TYPES.success,
      text: intl.formatMessage(OrderModalMessages.handleCopy),
    })

    void copyToClipboard(order?.deliveryStatus?.externalTrackingNumber ?? ' ')
  }

  const content = loading ? (
    <Loader />
  ) : (
    order && (
      <div className={styles.Container}>
        <div className={styles.Details}>
          <div className={styles.Detail}>
            <div className={styles.Detail__title}>
              {intl.formatMessage(OrderModalMessages.monthlyPlan)}
            </div>
            <div className={styles.Detail__value}>
              {monthPlanCount} {intl.formatMessage(OrderModalMessages.modem)} x{' '}
              {formatPrice(
                getTariffPackagePrice(TARIFF_PACKAGE_ID.MONTH, true),
              )}
            </div>
          </div>
          <div className={styles.Detail}>
            <div className={styles.Detail__title}>
              {intl.formatMessage(OrderModalMessages.annualPlan)}
            </div>
            <div className={styles.Detail__value}>
              {annualPlanCount} {intl.formatMessage(OrderModalMessages.modem)} x{' '}
              {formatPrice(getTariffPackagePrice(TARIFF_PACKAGE_ID.ANNUAL))}
            </div>
          </div>
          <div className={styles.Detail}>
            <div className={styles.Detail__title}>
              {intl.formatMessage(OrderModalMessages.delivery)}
            </div>
            {isDeliveryPriceChange && order.deliveryPrice !== 0 ?
              <div className={styles.DeliveryPriceWrapper}>
                <div className={styles.DeliveryPriceChangedWrapper}>
                  <div className={styles.DeliveryPrice}>
                    {formatPrice(order.deliveryPriceOld)}
                  </div>
                  <div className={clsx(
                    styles.Detail__value,
                    order.deliveryPrice === 0 && styles.Detail__value_green,
                  )}>
                    {order.deliveryPrice === 0
                      ? intl.formatMessage(OrderModalMessages.free)
                      : formatPrice(order.deliveryPrice)}
                  </div>
                  <div className={styles.DeliveryPriceDescription}>
                    {intl.formatMessage(OrderModalMessages.deliveryDescription)}
                  </div>
                </div>
                <FedexIcon/>
              </div>
              :
              <div className={styles.DeliveryPriceWrapper}>
                <div
                  className={clsx(
                    styles.Detail__value,
                    order.deliveryPrice === 0 && styles.Detail__value_green,
                  )}
                >
                  {order.deliveryPrice === 0
                    ? intl.formatMessage(OrderModalMessages.free)
                    : formatPrice(order.deliveryPrice)}
                </div>
                {(order.deliveryPrice || order.deliveryPriceOld || order.deliveryPrice === 0) &&
	                <FedexIcon/>
                }
              </div>
            }
          </div>
        </div>

        <div className={styles.TotalAmount}>
          <div>
            {intl.formatMessage(OrderModalMessages.totalAmount, {
              amount: formatPrice(order.totalPrice),
            })}
          </div>

          {statusComponent}
        </div>

        <div className={styles.AdditionalInfo}>
          <div className={styles.AdditionalInfoItem}>
            <div className={styles.AdditionalInfoItem__title}>
              {intl.formatMessage(OrderModalMessages.deliveryAddress)}
            </div>
            <div className={styles.AdditionalInfoItem__value}>
              {formatDeliveryAddress({
                countryName: countryName,
                streetAddress1: order.streetAddress1,
                region: order.region,
                city: order.city,
                postalCode: order.postalCode,
              })}
            </div>
          </div>
          <div className={styles.AdditionalInfoItem}>
            <div className={styles.AdditionalInfoItem__title}>
              {intl.formatMessage(OrderModalMessages.recipient)}
            </div>
            <div className={styles.AdditionalInfoItem__value}>
              {formatRecipient({
                firstName: order.firstName,
                lastName: order.lastName,
                phone: formatPhoneNumber(order.phone),
              })}
            </div>
          </div>
        </div>
        {order?.deliveryStatus?.externalTrackingNumber &&
	        <div className={styles.TrackNumberContainer}>
		        <div className={styles.TrackNumberDescription}>
			        <div>
                {intl.formatMessage(OrderModalMessages.orderTrackNumberTitle)}
			        </div>
			        <div className={styles.TrackNumberWrapper}>
				        <div className={styles.TrackNumberTitle}>
                  {intl.formatMessage(OrderModalMessages.orderTrackNumber)}
				        </div>
				        <div className={styles.TrackNumber}>
					        <div>
                    {order.deliveryStatus.externalTrackingNumber}
					        </div>
					        <button className={styles.Copy} onClick={handleCopyTrackNumber}>
                  <span className={styles.CopyIcon}>
                    <CopyIcon />
                  </span>
                    {intl.formatMessage(OrderModalMessages.copy)}
					        </button>
				        </div>
			        </div>
		        </div>
		        <FedexIcon/>
	        </div>
        }
        <div className={styles.Actions}>
          {isCancellationAvailable
            ? order.orderStatusId === ORDER_STATUSES.PAYMENT_WAITING ? (
              <Button
                text={intl.formatMessage(CommonButtonMessages.cancel)}
                variant={'danger'}
                icon={'trash'}
                onClick={() => openCancelOrderModal(afterSuccessCancelCallback, true)}
              />
            ) : (
              <Button
                text={intl.formatMessage(CommonButtonMessages.delete)}
                variant={'danger'}
                icon={'trash'}
                onClick={() => openCancelOrderModal(afterSuccessCancelCallback)}
              />
            )
          : <></>}
          {order.orderStatusId === ORDER_STATUSES.PAYMENT_WAITING ? (
            <Button
              text={intl.formatMessage(CommonButtonMessages.pay)}
              additionalClassNames={[styles.Action]}
              onClick={toPayment}
            />
          ) : (
            <Button
              text={intl.formatMessage(CommonButtonMessages.close)}
              additionalClassNames={[styles.Action]}
              onClick={handleHideModal}
            />
          )}
        </div>
      </div>
    )
  )

  return (
    <Drawer
      isOpen={true}
      close={handleHideModal}
      title={intl.formatMessage(OrderModalMessages.title, { orderId: orderId })}
      additionalClassNames={[styles.DrawerAdditional]}
    >
      {content}
    </Drawer>
  )
}
