import { FC } from 'react'
import { Button, ButtonProps } from '../button/Button'
import styles from './styles.module.scss'

export const LandingButton: FC<ButtonProps> = (props) => {
  return (
    <Button
      {...props}
      additionalClassNames={[
        ...(props?.additionalClassNames || []),
        styles.LandingButton,
      ]}
    />
  )
}
