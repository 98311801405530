import {
  ContactCreateBackendResponse,
  ContactCreateResponse,
} from '../../../../../types/contact/_crud/create'

export const mapContactCreateBackendToContactCreate = (
  item: ContactCreateBackendResponse,
): ContactCreateResponse => {
  const { contact_id, user_id, phone, name } = item.data

  return {
    contactId: contact_id,
    userId: user_id,
    phone: phone,
    name: name,
  }
}
