import React, { useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useSnackbar } from '../../../../app/hooks/useSnackbar'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/submodal-close.svg'
import { CommonButtonMessages } from '../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { dongleSmsCreateDatagate } from '../../../../sdk/datagates/api/dongle'
import { DongleSmsCreateRequest } from '../../../../sdk/datagates/types/dongle/sms/_crud/create'
import { SmsListResponse } from '../../../../sdk/datagates/types/sms/_crud/list'
import { TextareaField } from '../../../../shared/lib/form/form-field-adapters/v2/textarea-field/TextareaField'
import {
  composeValidators,
  useFormRules,
} from '../../../../shared/lib/form/form-rules'
import { useClickOutside } from '../../../../shared/lib/hooks/useClickOutside'
import { Card } from '../../../../shared/ui-kit-2/data-display/card/Card'
import { Button } from '../../../../shared/ui-kit-2/inputs/button/Button'
import { SNACKBAR_TYPES } from '../../../snackbar/SnackbarProvider'
import styles from './styles.module.scss'

interface UseReplyToSmsProps {
  sms: SmsListResponse['smses'][0] | null
  afterSuccessCallback: () => void | Promise<void>
}

type UseReplyToSmsFormValues = {
  message: string
}

const UseReplyToSmsMessages = defineMessages({
  title: {
    id: 'UseReplyToSmsMessages.title',
    defaultMessage: 'Enter your reply',
  },
  messageLabel: {
    id: 'UseReplyToSmsMessages.messageLabel',
    defaultMessage: 'Enter SMS message',
  },
  messagePlaceholder: {
    id: 'UseReplyToSmsMessages.messagePlaceholder',
    defaultMessage: 'Enter SMS message you want to be sent as reply',
  },
  positiveSent: {
    id: 'UseReplyToSmsMessages.positiveSent',
    defaultMessage: 'Reply to SMS is successfully sent',
  },
  negativeSent: {
    id: 'UseReplyToSmsMessages.negativeSent',
    defaultMessage: 'An error occurred while replying to SMS',
  },
})

export const useReplyToSms = (props: UseReplyToSmsProps) => {
  const { sms, afterSuccessCallback } = props

  const intl = useIntl()
  const { handleOpenSnackbar } = useSnackbar()
  const { ruleRequired } = useFormRules()
  const [isOpen, setIsOpen] = useState<boolean>()
  const ref = useRef<HTMLDivElement>(null)

  const onClose = () => {
    setIsOpen(false)
  }

  useClickOutside(ref, onClose)

  const onSubmit = async (values: UseReplyToSmsFormValues) => {
    try {
      if (!sms) throw new Error('No sms provided')

      const urlParams = [{ name: 'dongle_id', value: sms.dongleId.toString() }]

      let reqBody: DongleSmsCreateRequest = {
        message: values.message,
        replied_to_sms_id: sms.smsId,
      }

      if (sms.contact?.contactId) reqBody.contact_id = sms.contact.contactId
      else reqBody.number = sms.numberCaller

      await dongleSmsCreateDatagate(reqBody, urlParams)

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(UseReplyToSmsMessages.positiveSent),
      })

      onClose()
      afterSuccessCallback?.()
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(UseReplyToSmsMessages.negativeSent),
      })
    }
  }

  const content = (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} className={styles.Content}>
          <TextareaField
            name={'message'}
            validate={composeValidators(ruleRequired())}
            label={intl.formatMessage(UseReplyToSmsMessages.messageLabel)}
            placeholder={intl.formatMessage(
              UseReplyToSmsMessages.messagePlaceholder,
            )}
            markAsRequired={true}
          />

          <Button
            type={'submit'}
            text={intl.formatMessage(CommonButtonMessages.send)}
            onClick={handleSubmit}
            disabled={submitting}
            loading={submitting}
          />
        </form>
      )}
    />
  )

  const component = (
    <div className={styles.Overlay}>
      <Card additionalClassNames={[styles.CardAdditional]} currentRef={ref}>
        <div className={styles.Header}>
          <div className={styles.Title}>
            {intl.formatMessage(UseReplyToSmsMessages.title)}
          </div>
          <CloseIcon className={styles.Close} onClick={onClose} />
        </div>
        {content}
      </Card>
    </div>
  )

  return {
    setIsOpen,
    component: isOpen ? component : null,
  }
}
