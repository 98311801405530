import React, {useContext, useEffect} from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useTariffPackages } from '../../../../../../../sdk/hooks/use-tariff-packages/useTariffPackages'
import { Card } from '../../../../../../../shared/ui-kit-2/data-display/card/Card'
import { PaginationMobile } from '../../../../../../../shared/ui-kit-2/data-display/pagination/PaginationMobile'
import { OrdersPageContext } from '../../../contexts/OrdersPageContext'
import { OrderCard } from './components/order-card/OrderCard'
import styles from './styles.module.scss'
import {MODAL_TYPES} from "../../../../../../../layouts/modals/ModalsProvider";
import {useModal} from "../../../../../../../app/hooks/useModal";

const OrderListMessages = defineMessages({
  title: {
    id: 'OrderListMessages.title',
    defaultMessage: 'My orders',
  },
})

export const OrderList = () => {
  const intl = useIntl()
  const {
    orderFullList,
    loading,
    ordersPage,
    setOrdersPage,
    setOrdersLimitPage,
    limitOrdersPage,
    totalCount,
  } = useContext(OrdersPageContext)
  const { handleOpenModal } = useModal()

  const handlePageChange = (page: number, limit: number) => {
    setOrdersLimitPage(limit)
    setOrdersPage(page)
  }
  const sliceOrder = orderFullList.slice(0, 3000)

  const { getTariffPackagePrice, getTariffPackageTranslation } =
    useTariffPackages()

  useEffect(() => {
    const orderId = new URLSearchParams(window.location.search).get('order_id');

    if (orderId) {
      handleOpenModal({
        type: MODAL_TYPES.ORDER,
        props: {
          orderId: orderId,
        },
      })
    }
  }, []);

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <div className={styles.Title}>
        {intl.formatMessage(OrderListMessages.title)}
      </div>

      <div className={styles.ScrollableContent}>
        {sliceOrder.map((order) => (
          <OrderCard
            key={order.orderId}
            order={order}
            getTariffPackagePrice={getTariffPackagePrice}
            getTariffPackageTranslation={getTariffPackageTranslation}
          />
        ))}
      </div>
      <PaginationMobile
        currentPage={ordersPage}
        totalCount={totalCount}
        onPageChange={handlePageChange}
        itemsPerPage={limitOrdersPage}
      />
    </Card>
  )
}
