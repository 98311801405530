import React from 'react'
import { ReactComponent as AppleIcon } from '../../../../../../../../../assets/icons/payment-apple-pay.svg'
import { ReactComponent as GoogleIcon } from '../../../../../../../../../assets/icons/payment-google-pay.svg'
import { ReactComponent as MasterCardIcon } from '../../../../../../../../../assets/icons/payment-master-card.svg'
import { ReactComponent as VisaIcon } from '../../../../../../../../../assets/icons/payment-visa.svg'
import styles from './styles.module.scss'

export const BankCardPaymentMethod = () => {
  return (
    <div className={styles.Wrapper}>
      <VisaIcon />
      <MasterCardIcon />
      <AppleIcon />
      <GoogleIcon />
    </div>
  )
}
