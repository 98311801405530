import React from 'react';
import styles from "./styles.module.scss";
import { TARIFF_PACKAGE_ID } from "../../../../../../sdk/datagates/types/order/_common";
import { formatDate } from "../../../../../../shared/lib/utils/date-utils/formatDate";
import clsx from "clsx";
import { DongleGetResponse } from "../../../../../../sdk/datagates/types/dongle/_crud/get";
import { useIntl } from "react-intl";
import { UsePayDeviceMessages } from "../../usePayDevice";

interface TariffPackageCardProps {
  device?: DongleGetResponse
  getTariffPackagePrice: (tariffPackageId: number, withDevice?: boolean, count?: number) => number | undefined
  getTariffPackageTranslation: (tariffPackageId: number) => string
  tariffPackageId: number
  paymentMethod: {
    title: string
    paymentMethodType: number
    disabled?: boolean
    description: string
  }
  setTariffPackageId: React.Dispatch<React.SetStateAction<number>>
  title: string
}

export const TariffPackageCard = (props: TariffPackageCardProps) => {
  const {
    device,
    getTariffPackagePrice,
    getTariffPackageTranslation,
    tariffPackageId,
    paymentMethod,
    setTariffPackageId,
    title
  } = props;
  const intl = useIntl();

  const isActiveTab = tariffPackageId === paymentMethod.paymentMethodType;
  const isTariffPackageMonth = device?.tariffPackageId === TARIFF_PACKAGE_ID.MONTH;
  const isTariffPackageAnnual = device?.tariffPackageId === TARIFF_PACKAGE_ID.ANNUAL;
  const isTariffPackageActive = device?.isTariffPackageActive;

  const paymentMethodHandler = (id: number) => {
    if (id === TARIFF_PACKAGE_ID.MONTH && isTariffPackageAnnual && isTariffPackageActive) {
      return;
    }
    setTariffPackageId(id);
  };

  const renderSubscriptionInfo = () => (
    <div className={styles.SubscriptionInfo}>
      {device &&
				<>
					<div className={styles.SubscriptionWrapper}>
						<div className={clsx(styles.SubscriptionTitle)}>
              {intl.formatMessage(UsePayDeviceMessages.subscriptionStartTitle)}
						</div>
            {(device.activatedAt || device.createdAt) && (
              <div className={styles.SubscriptionValue}>
                {formatDate(device.activatedAt ? device.activatedAt : device.createdAt, 0, 'date')}
              </div>
            )}
					</div>
					<div className={styles.SubscriptionWrapper}>
						<div className={styles.SubscriptionTitle}>
              {intl.formatMessage(UsePayDeviceMessages.subscriptionEndTitle)}
						</div>
            {device.tariffPackageEnd && (
              <div className={clsx(styles.SubscriptionValue, !isTariffPackageActive && styles.ErrorSubscription)}>
                {formatDate(device.tariffPackageEnd, 0, 'date')}
              </div>
            )}
					</div>
				</>
      }
    </div>
  );

  const renderTariffPrice = (type: number) => (
    <div className={
      clsx(
        type === TARIFF_PACKAGE_ID.MONTH
          ?
          styles.TariffMonth
          :
          (device?.tariffPackageId === TARIFF_PACKAGE_ID.ANNUAL
            ?
            styles.TariffAnnualCurrent
            : styles.TariffAnnual
          )
      )}>
      €{getTariffPackagePrice(type)}
    </div>
  );

  const renderDescription = (description: string) => (
    <div className={styles.AnnualTariffWrapper}>
      <div className={styles.AnnualDescription}>
        {description}
      </div>
      <div className={clsx(styles.TariffAnnual, paymentMethod.disabled && styles.Disable)}>
        <span>
          €{getTariffPackagePrice(paymentMethod.paymentMethodType)}
        </span>
        {isTariffPackageAnnual && paymentMethod.paymentMethodType === device?.tariffPackageId && (
          <span className={styles.Gifts}>
            {intl.formatMessage(UsePayDeviceMessages.annualPaymentGift)}
          </span>
        )}
        {isTariffPackageMonth && (
          <span className={styles.Gifts}>
            + {intl.formatMessage({ id: 'giftTwoMonths', defaultMessage: 'gift 2 months' })}
          </span>
        )}
      </div>
    </div>
  );

  const renderContent = () => {
    if (isTariffPackageMonth && paymentMethod.paymentMethodType === TARIFF_PACKAGE_ID.MONTH) {
      return (
        <>
          {renderSubscriptionInfo()}
          {renderTariffPrice(TARIFF_PACKAGE_ID.MONTH)}
        </>
      );
    } else if (isTariffPackageAnnual && paymentMethod.paymentMethodType === TARIFF_PACKAGE_ID.ANNUAL) {
      return (
        <>
          {renderSubscriptionInfo()}
          {renderTariffPrice(TARIFF_PACKAGE_ID.ANNUAL)}
        </>
      );
    } else {
      return renderDescription(paymentMethod.description);
    }
  };

  return (
    <div
      className={clsx(
        styles.PaymentMethodCard,
        isActiveTab && styles.PaymentMethodCard_active,
        paymentMethod.disabled && styles.PaymentMethodCard_disabled,
      )}
      onClick={() => !paymentMethod.disabled && paymentMethodHandler(paymentMethod.paymentMethodType)}
    >
      <div className={styles.TitleAndRadio}>
        <div className={
          clsx(
            styles.Title,
            paymentMethod.disabled && styles.Disable
          )}
        >
          {title}
          {isActiveTab && (
            <div className={styles.Badge}>{intl.formatMessage(UsePayDeviceMessages.current)}</div>
          )}
        </div>
        <div
          className={clsx(
            styles.Checkbox,
            isActiveTab && styles.Active,
          )}
        ></div>
      </div>
      <div className={styles.ChildrenPaymentCard}>
        {renderContent()}
      </div>
    </div>
  );
};