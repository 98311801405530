import React, { FC } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

type CounterSize = 'small' | 'default'
type CounterVariant = 'outlined' | 'filled'

interface CounterProps {
  count?: number
  text?: string
  variant?: CounterVariant
  size?: CounterSize
  additionalClassNames?: string[]
}

export const Counter: FC<CounterProps> = (props) => {
  const {
    count = null,
    text,
    variant = 'outlined',
    size = 'default',
    additionalClassNames = [],
  } = props

  return (
    <div
      className={clsx(
        styles.Counter,
        count === 0 && styles.Counter_empty,
        size === 'small' && styles.Counter_small,
        variant === 'filled' && styles.Counter_filled,
        ...additionalClassNames,
      )}
    >
      {text ? `${count} ${text}` : count}
    </div>
  )
}
