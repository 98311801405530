import React, { Dispatch, FC, ReactNode, SetStateAction } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

interface PaymentMethodCardProps {
  title: string
  children?: ReactNode
  setPaymentMethod: Dispatch<SetStateAction<number>>
  paymentMethodType: number
  currentMethodType: number
  disabled?: boolean
}

export const PaymentMethodCard: FC<PaymentMethodCardProps> = (props) => {
  const {
    title,
    children,
    setPaymentMethod,
    paymentMethodType,
    currentMethodType,
    disabled,
  } = props

  const paymentMethodHandler = () => {
    if (paymentMethodType) setPaymentMethod(paymentMethodType)
  }

  return (
    <div
      className={clsx(
        styles.PaymentMethodCard,
        paymentMethodType === currentMethodType &&
          styles.PaymentMethodCard_active,
        disabled && styles.PaymentMethodCard_disabled,
      )}
      onClick={() => !disabled && paymentMethodHandler()}
    >
      <div className={styles.TitleAndRadio}>
        <div className={styles.TitleAndRadio__title}>{title}</div>
        <div
          className={clsx(
            styles.TitleAndRadio__checkbox,
            currentMethodType === paymentMethodType &&
              styles.TitleAndRadio__checkbox__active,
          )}
        ></div>
      </div>
      <div className={styles.ChildrenPaymentCard}>{children}</div>
    </div>
  )
}
