import React, { useRef, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useSnackbar } from '../../../../app/hooks/useSnackbar'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/submodal-close.svg'
import { CommonButtonMessages } from '../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { useBackendErrorCodes } from '../../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { useClickOutside } from '../../../../shared/lib/hooks/useClickOutside'
import { getErrorMessage } from '../../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Card } from '../../../../shared/ui-kit-2/data-display/card/Card'
import {
  Button,
  BUTTON_ICON_LIST_TYPE,
} from '../../../../shared/ui-kit-2/inputs/button/Button'
import { SNACKBAR_TYPES } from '../../../snackbar/SnackbarProvider'
import styles from './styles.module.scss'

interface UseDeleteSubmodalProps {
  handleAction: () => void | Promise<void>
  actionName: string
  itemName: string
  description?: string
  danger?: boolean
  actionButtonText?: string
  actionButtonIcon?: BUTTON_ICON_LIST_TYPE
}

const UseBoolActionSubmodalMessages = defineMessages({
  titleTemplate: {
    id: 'UseBoolActionSubmodalMessages.titleTemplate',
    defaultMessage: 'Are you sure you want to {actionName} it?',
  },
  telegramPlaceholder: {
    id: 'UseAddTelegramIdMessages.telegramPlaceholder',
    defaultMessage: 'Enter telegram chat ID',
  },
})

export const useBoolActionSubmodal = (props: UseDeleteSubmodalProps) => {
  const {
    handleAction,
    actionName,
    itemName,
    description,
    danger,
    actionButtonText,
    actionButtonIcon,
  } = props

  const intl = useIntl()
  const [loading, setLoading] = useState<boolean>(false)
  const { handleOpenSnackbar } = useSnackbar()
  const { resolveBackendError } = useBackendErrorCodes()
  const [isOpen, setIsOpen] = useState<boolean>()
  const ref = useRef<HTMLDivElement>(null)

  const onClose = () => {
    setIsOpen(false)
  }

  useClickOutside(ref, onClose)

  const onSubmit = async () => {
    try {
      setLoading(true)

      if (handleAction.constructor.name === 'AsyncFunction')
        await handleAction()
      else handleAction()

      onClose()
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    } finally {
      setLoading(false)
    }
  }

  const component = (
    <div className={styles.Overlay}>
      <Card additionalClassNames={[styles.CardAdditional]} currentRef={ref}>
        <div className={styles.Header}>
          <div className={styles.Title}>
            {intl.formatMessage(UseBoolActionSubmodalMessages.titleTemplate, {
              actionName: actionName,
              itemName: itemName,
            })}
          </div>

          <CloseIcon className={styles.Close} onClick={onClose} />
        </div>

        {description && <div className={styles.Description}>{description}</div>}

        <div className={styles.Actions}>
          <Button
            text={intl.formatMessage(CommonButtonMessages.no)}
            onClick={onClose}
            disabled={loading}
            variant={'blackTextBlackBorderOutlined'}
          />

          <Button
            text={
              actionButtonText ?? intl.formatMessage(CommonButtonMessages.yes)
            }
            onClick={onSubmit}
            disabled={loading}
            variant={danger ? 'danger' : 'green'}
            icon={actionButtonIcon}
          />
        </div>
      </Card>
    </div>
  )

  return {
    setIsOpen,
    component: isOpen ? component : null,
  }
}
