import { parseStringAsUTCDate } from '../../../../../../../shared/lib/utils/date-utils/parseStringAsUTCDate'
import {
  DongleListBackendResponse,
  DongleListResponse,
} from '../../../../../types/dongle/_crud/list'
import d from "final-form-arrays";

export const mapDongleListBackendToDongleList = (
  item: DongleListBackendResponse,
): DongleListResponse => {
  const { dongles, total_count } = item.data

  return {
    dongles: dongles.map((el) => {
      return {
        dongleId: el.dongle_id,
        bootstrapDongleId: el.bootstrap_dongle_id,
        userId: el.user_id,
        name: el.name,
        hotspotName: el.hotspot_name,
        hotspotPassword: el.hotspot_password,
        imeiManufacture: el.bootstrap_dongle.imei_manufacture,
        fakeImei: {
          fakeImeiId: el.fake_imei?.fake_imei_id,
          imei: el.fake_imei?.imei,
          createdAt: el.fake_imei?.created_at
        },
        phoneNumber: el.number,
        isOnline: el.is_online,
        isDeleted: el.is_deleted,
        apiVersion: el.api_version,
        isHotspotEnable: el.is_hotspot_enable,
        smsIncoming: el.sms_incoming,
        smsOutgoing: el.sms_outgoing,
        callIncoming: el.call_incoming,
        callOutgoing: el.call_outgoing,
        createdAt: el.created_at,
        updatedAt: el.updated_at,
        tariffPackageId: el.tariff_package_id,
        isActive: el.is_active,
        dongleSignalQualityId: el.dongle_signal_quality_id,
        isTariffPackageActive: el.is_tariff_package_active,
        tariffPackageEnd: el.tariff_package_end
          ? parseStringAsUTCDate(el.tariff_package_end)
          : undefined,
        activatedAt: el.activated_at
          ? parseStringAsUTCDate(el.activated_at)
          : undefined,
        dongleFlags: el.dongle_flags?.map(({ dongle_flag_id, name }) => ({
          dongleFlagId: dongle_flag_id,
          name
        }))
      }
    }),
    totalCount: total_count,
  }
}
