import {
  DongleCallDeleteBackendResponse,
  DongleCallDeleteResponse,
} from '../../../../../../types/dongle/call/_crud/delete'

export const mapDongleCallDeleteBackendToDongleCallDelete = (
  item: DongleCallDeleteBackendResponse,
): DongleCallDeleteResponse => {
  const { data } = item

  return {
    data: data,
  }
}
