import React from 'react'
import { PageWrapper } from '../../../../layouts/page-wrapper/PageWrapper'
import { ActivateDeviceContextProvider } from './contexts/ActivateDeviceContext'

export const ActivateDevicePage = () => {
  return (
    <PageWrapper>
      <ActivateDeviceContextProvider />
    </PageWrapper>
  )
}
