import { ReactElement } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Sticker } from '../../../shared/ui-kit-2/data-display/sticker/Sticker'

export const ORDER_STATUSES = {
  CREATED: 1,
  PRICE_DETERMINING: 2,
  PAYMENT_WAITING: 3,
  PAYED: 4,
  DELIVERING: 5,
  DONE: 6,
  CANCELLED: 7,
  DELIVERED: 8
}

const UseOrderStatusMessages = defineMessages({
  created: {
    id: 'UseOrderStatusMessages.created',
    defaultMessage: 'Created',
  },
  priceDetermining: {
    id: 'UseOrderStatusMessages.priceDetermining',
    defaultMessage: 'Clarify prices',
  },
  paymentWaiting: {
    id: 'UseOrderStatusMessages.paymentWaiting',
    defaultMessage: 'Awaiting payment',
  },
  payed: {
    id: 'UseOrderStatusMessages.payed',
    defaultMessage: 'Payed',
  },
  delivering: {
    id: 'UseOrderStatusMessages.delivering',
    defaultMessage: 'Delivering',
  },
  done: {
    id: 'UseOrderStatusMessages.done',
    defaultMessage: 'Done',
  },
  cancelled: {
    id: 'UseOrderStatusMessages.cancelled',
    defaultMessage: 'Cancelled',
  },
  delivered: {
    id: 'UseOrderStatusMessages.delivered',
    defaultMessage: 'Delivered'
  },
  unknown: {
    id: 'UseOrderStatusMessages.unknown',
    defaultMessage: 'Unknown status',
  },
})

export const useOrderStatus = () => {
  const intl = useIntl()

  const getOrderStatusStickerByStatusId = (
    statusId: number,
  ): { statusComponent: ReactElement } => {
    switch (statusId) {
      case ORDER_STATUSES.CREATED:
        return {
          statusComponent: (
            <Sticker
              text={intl.formatMessage(UseOrderStatusMessages.created)}
              color={'lightGreen'}
            />
          ),
        }
      case ORDER_STATUSES.PRICE_DETERMINING:
        return {
          statusComponent: (
            <Sticker
              text={intl.formatMessage(UseOrderStatusMessages.priceDetermining)}
              color={'blue'}
            />
          ),
        }
      case ORDER_STATUSES.PAYMENT_WAITING:
        return {
          statusComponent: (
            <Sticker
              text={intl.formatMessage(UseOrderStatusMessages.paymentWaiting)}
              color={'yellow'}
            />
          ),
        }
      case ORDER_STATUSES.PAYED:
        return {
          statusComponent: (
            <Sticker
              text={intl.formatMessage(UseOrderStatusMessages.payed)}
              color={'lightGreen'}
            />
          ),
        }
      case ORDER_STATUSES.DELIVERING:
        return {
          statusComponent: (
            <Sticker
              text={intl.formatMessage(UseOrderStatusMessages.delivering)}
              color={'yellow'}
            />
          ),
        }
      case ORDER_STATUSES.DONE:
        return {
          statusComponent: (
            <Sticker
              text={intl.formatMessage(UseOrderStatusMessages.done)}
              color={'lightGreen'}
            />
          ),
        }
      case ORDER_STATUSES.CANCELLED:
        return {
          statusComponent: (
            <Sticker
              text={intl.formatMessage(UseOrderStatusMessages.cancelled)}
              color={'red'}
            />
          ),
        }
      case ORDER_STATUSES.DELIVERED:
        return {
          statusComponent: (
            <Sticker
              text={intl.formatMessage(UseOrderStatusMessages.delivered)}
              color={'green'}
            />
          ),
        }
      default:
        return {
          statusComponent: (
            <Sticker
              text={intl.formatMessage(UseOrderStatusMessages.unknown)}
              color={'red'}
            />
          ),
        }
    }
  }

  const getOrderStatusTranslationByStatusId = (
    statusId: number,
  ): { statusTranslation: string } => {
    switch (statusId) {
      case ORDER_STATUSES.CREATED:
        return {
          statusTranslation: intl.formatMessage(UseOrderStatusMessages.created),
        }
      case ORDER_STATUSES.PRICE_DETERMINING:
        return {
          statusTranslation: intl.formatMessage(
            UseOrderStatusMessages.priceDetermining,
          ),
        }
      case ORDER_STATUSES.PAYMENT_WAITING:
        return {
          statusTranslation: intl.formatMessage(
            UseOrderStatusMessages.paymentWaiting,
          ),
        }
      case ORDER_STATUSES.PAYED:
        return {
          statusTranslation: intl.formatMessage(UseOrderStatusMessages.payed),
        }
      case ORDER_STATUSES.DELIVERING:
        return {
          statusTranslation: intl.formatMessage(
            UseOrderStatusMessages.delivering,
          ),
        }
      case ORDER_STATUSES.DONE:
        return {
          statusTranslation: intl.formatMessage(UseOrderStatusMessages.done),
        }
      case ORDER_STATUSES.CANCELLED:
        return {
          statusTranslation: intl.formatMessage(
            UseOrderStatusMessages.cancelled,
          ),
        }
      default:
        return {
          statusTranslation: intl.formatMessage(UseOrderStatusMessages.unknown),
        }
    }
  }

  const getOrderCancellationAvailableByStatusId = (
    statusId: number,
  ): { isCancellationAvailable: boolean } => {
    switch (statusId) {
      case ORDER_STATUSES.CREATED:
        return { isCancellationAvailable: true }
      case ORDER_STATUSES.PRICE_DETERMINING:
        return { isCancellationAvailable: true }
      case ORDER_STATUSES.PAYMENT_WAITING:
        return { isCancellationAvailable: true }
      case ORDER_STATUSES.PAYED:
        return { isCancellationAvailable: true }
      case ORDER_STATUSES.DELIVERING:
        return { isCancellationAvailable: false }
      case ORDER_STATUSES.DONE:
        return { isCancellationAvailable: true }
      case ORDER_STATUSES.CANCELLED:
        return { isCancellationAvailable: true }
      default:
        return { isCancellationAvailable: true }
    }
  }

  return {
    getOrderStatusStickerByStatusId,
    getOrderStatusTranslationByStatusId,
    getOrderCancellationAvailableByStatusId,
  }
}
