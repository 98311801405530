import React from 'react';
import {DeviceDetailsModalMessages} from "../../messages/DeviceDetailsModalMessages";
import {formatPhoneNumber} from "../../../../../sdk/formatters/format-phone-number";
import {useIntl} from "react-intl";
import styles from './styles.module.scss';
import {DongleGetResponse} from "../../../../../sdk/datagates/types/dongle/_crud/get";
import clsx from "clsx";
import {LoadingContent} from "../../../../loading-content/LoadingContent";

interface NetworkInformationProps {
  networkInformation: Record<string, string>
  networkInformationLoading: boolean
  device: DongleGetResponse | undefined
  isTariffPackageEnabled: boolean
}
export const NetworkInformation = (props: NetworkInformationProps) => {
  const {
    networkInformation,
    networkInformationLoading,
    device,
    isTariffPackageEnabled,
  } = props;
  const intl = useIntl();

  return (
    <LoadingContent
      loading={networkInformationLoading}
      content={(
        <div className={clsx(styles.List, isTariffPackageEnabled && styles.ErrorSubscription)}>
          <div className={styles.Row}>
            <div className={styles.Label}>
              {intl.formatMessage(DeviceDetailsModalMessages.networkName)}
            </div>
            <div className={styles.Value}>
              {networkInformation?.current_network_name ?? '-'}
            </div>
          </div>
          <div className={styles.Row}>
            <div className={styles.Label}>MCC/MNC</div>
            <div className={styles.Value}>
              {networkInformation?.mcc ?? '-'}/
              {networkInformation?.mnc ?? '-'}
            </div>
          </div>
          <div className={styles.Row}>
            <div className={styles.Label}>
              {intl.formatMessage(DeviceDetailsModalMessages.networkType)}
            </div>
            <div className={styles.Value}>
              {networkInformation?.network_type ?? '-'}
            </div>
          </div>
          <div className={styles.Row}>
            <div className={styles.Label}>
              {intl.formatMessage(DeviceDetailsModalMessages.number)}
            </div>
            <div className={styles.Value}>
              {formatPhoneNumber(device?.phoneNumber) ?? '-'}
            </div>
          </div>
          <div className={styles.Row}>
            <div className={styles.Label}>IMSI</div>
            <div className={styles.Value}>
              {networkInformation.imsi ?? '-'}
            </div>
          </div>
        </div>
      )}
    />
  )
};

