import React from 'react'
import styles from '../../styles.module.scss'
import { BalanceHistory } from './components/balance-history/BalanceHistory'
import { Balance } from './components/balance/Balance'
import { Card } from './components/card/Card'
import { ProfileForm } from './components/profile-page-form/ProfileForm'
import {AutoRenewal} from "./components/auto-renewal/AutoRenewal";
import {BankCards} from "./components/bank-cards-new/BankCards";

const ProfilePageEn = () => {
  return (
    <div className={styles.Cols}>
      <Card>
        <ProfileForm />
      </Card>
      <div className={styles.Col}>
        <Card noPadding={{ all: true }}>
          <Balance />
        </Card>
        <Card noPadding={{ all: true }}>
          <BankCards />
        </Card>
        <Card noPadding={{all: true}}>
          <AutoRenewal/>
        </Card>
        <BalanceHistory />
      </div>
    </div>
  )
}

export default ProfilePageEn
