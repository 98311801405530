import React, { FC } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ReactComponent as AddIcon } from '../../../assets/icons/custom-button-add.svg'
import styles from './styles.module.scss'

interface DashedAddButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const DashedAddButtonMessages = defineMessages({
  title: {
    id: 'DashedAddButtonMessages.title',
    defaultMessage: 'Add',
  },
})

export const DashedAddButton: FC<DashedAddButtonProps> = (props) => {
  const { ...otherProps } = props

  const intl = useIntl()

  return (
    <button className={styles.Button} type={'button'} {...otherProps}>
      <AddIcon />
      <div>{intl.formatMessage(DashedAddButtonMessages.title)}</div>
    </button>
  )
}
