const formatDateTime = (date: Date, useUTC: boolean): string => {
  const formatDatePart = (value: number) => String(value).padStart(2, '0');
  
 /* if (useUTC) {
    return `${date.getUTCFullYear()}-${formatDatePart(date.getUTCMonth() + 1)}-${formatDatePart(date.getUTCDate())} ${formatDatePart(date.getUTCHours())}:${formatDatePart(date.getUTCMinutes())}:${formatDatePart(date.getUTCSeconds())}`;
  } else {
    return `${date.getFullYear()}-${formatDatePart(date.getMonth() + 1)}-${formatDatePart(date.getDate())} ${formatDatePart(date.getHours())}:${formatDatePart(date.getMinutes())}:${formatDatePart(date.getSeconds())}`;
  }*/
  
  return `${date.getFullYear()}-${formatDatePart(date.getMonth() + 1)}-${formatDatePart(date.getDate())} ${formatDatePart(date.getHours())}:${formatDatePart(date.getMinutes())}:${formatDatePart(date.getSeconds())}`;
}

export const formatDate = (
 dateValue: string | string[],
 useUTC: boolean = false
): string => {
  if (Array.isArray(dateValue) && dateValue.length > 0) {
    const date = new Date(dateValue[0]);
    return formatDateTime(date, useUTC);
  }
  
  return typeof dateValue === 'string' ? dateValue : '';
}
