import React, {ComponentProps, FC, useState} from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { InputPhoneNumber } from "../../../../../ui-kit-2/inputs/input-phone-number/InputPhoneNumber";
import { getFieldError } from "../../../form-helpers/get-field-error/getFieldError";
import {composeValidators, useFormRules} from "../../../form-rules";

interface PhoneInputNumberProps {
  label?: string;
  initialValue?: string | null;
  customError?: string;
  width?: string | number;
  helperText?: string;
  canBeShort?: boolean;
  initialShortMode?: boolean;
}

export type InputFieldProps = PhoneInputNumberProps & ComponentProps<typeof Field<string>>;

export const PhoneInputFieldNumber: FC<InputFieldProps> = (props) => {
  const {
    initialValue = '',
    label,
    customError,
    width,
    helperText,
    validate,
    markAsRequired,
    canBeShort,
    initialShortMode = false,
    ...otherProps
  } = props;

  const { rulePhoneNew, ruleShortPhone, ruleRequired } = useFormRules();
  const [shortMode, setShortMode] = useState<boolean>(initialShortMode);

  return (
    <Field
      {...otherProps}
      validate={composeValidators(
        shortMode ? ruleShortPhone() : rulePhoneNew(),
        markAsRequired ? ruleRequired() : () => undefined
      )}
    >
      {(fieldRenderProps) => {
        const { input, meta, ...otherFieldRenderProps } =
          fieldRenderProps as FieldRenderProps<string>;

        return (
          <InputPhoneNumber
            label={label}
            error={getFieldError(meta) || customError}
            helperText={helperText}
            input={input}
            initialValue={initialValue}
            width={width}
            meta={meta}
            markAsRequired={markAsRequired}
            canBeShort={canBeShort}
            shortMode={shortMode}
            setShortMode={setShortMode}
            {...input}
            {...otherFieldRenderProps}
          />
        );
      }}
    </Field>
  );
};
