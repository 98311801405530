import { useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { globalHistory } from '../../../app/providers/with-router/withRouter'
import { RoutePath } from '../../../config/routes/constants/routePath'
import { Route } from '../../../config/routes/enums/route'
import { MODAL_TYPES } from '../../../layouts/modals/ModalsProvider'
import { SNACKBAR_TYPES } from '../../../layouts/snackbar/SnackbarProvider'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import {
  orderDeleteDatagate,
  orderGetDatagate,
  orderUpdateDatagate,
} from '../../datagates/api/order'
import { useBackendErrorCodes } from '../../datagates/helpers/_common/use-backend-error-codes'
import { OrderGetResponse } from '../../datagates/types/order/_crud/get'
import { ORDER_STATUSES } from '../use-order-status/useOrderStatusTranslation'

interface UseOrderProps {
  orderId: number | string | null
}

export const ORDER_TYPES = {
  DEVICES_WITH_SUBSCRIPTION: 1,
  SUBSCRIPTION_ONLY: 2,
}

const UseOrderMessage = defineMessages({
  deleteOrderTitle: {
    id: 'UseOrderMessage.deleteOrderTitle',
    defaultMessage: 'Order deleting',
  },
  deleteOrderDescription: {
    id: 'UseOrderMessage.deleteOrderDescription',
    defaultMessage: 'Are you sure you want to delete your order?',
  },
  deleteOrderSubmitText: {
    id: 'UseOrderMessage.delete',
    defaultMessage: 'Delete order',
  },
  deleteSuccess: {
    id: 'UseOrderMessage.deleteSuccess',
    defaultMessage: 'Order is successfully deleted',
  },
  deleteError: {
    id: 'UseOrderMessage.deleteError',
    defaultMessage: 'An error occurred while deleting order',
  },
  cancelOrderTitle: {
    id: 'UseOrderMessage.cancelOrderTitle',
    defaultMessage: 'Order cancellation',
  },
  cancelOrderDescription: {
    id: 'UseOrderMessage.cancelOrderDescription',
    defaultMessage: 'Are you sure you want to cancel your order?',
  },
  cancelOrderSubmitText: {
    id: 'UseOrderMessage.cancel',
    defaultMessage: 'Cancel order',
  },
  cancelSuccess: {
    id: 'UseOrderMessage.cancelSuccess',
    defaultMessage: 'Order is successfully canceled',
  },
  cancelError: {
    id: 'UseOrderMessage.cancelError',
    defaultMessage: 'An error occurred while cancelling order',
  },
})

export const useOrder = (props: UseOrderProps) => {
  const { orderId } = props

  const intl = useIntl()
  const { resolveBackendError } = useBackendErrorCodes()
  const { handleOpenSnackbar } = useSnackbar()
  const { handleOpenModal, handleHideModal } = useModal()

  const [loading, setLoading] = useState<boolean>(true)
  const [order, setOrder] = useState<OrderGetResponse>()

  const handleFetchOrder = async () => {
    if (orderId) {
      try {
        const { data } = await orderGetDatagate(null, [
          {
            name: 'order_id',
            value: orderId.toString(),
          },
        ])

        setOrder(data)
      } catch (e) {
        handleOpenSnackbar({
          type: SNACKBAR_TYPES.error,
          text: resolveBackendError(getErrorMessage(e)),
        })
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    void handleFetchOrder()
  }, [])

  if (!orderId) {
    return {
      order: null,
      loading: false,
      openCancelOrderModal: null,
    }
  }

  const handleCancelOrder = async (
    orderId: string,
    successCallback?: () => void,
    isCanceling?: boolean
  ) => {
    try {
      if (isCanceling) {
        await orderUpdateDatagate({ order_status_id: ORDER_STATUSES.CANCELLED }, [
          {
            name: 'order_id',
            value: orderId,
          },
        ])
      } else {
        await orderDeleteDatagate({}, [
          {
            name: 'order_id',
            value: orderId,
          },
        ])
      }

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(isCanceling ? UseOrderMessage.cancelSuccess : UseOrderMessage.deleteSuccess),
      })

      successCallback?.()

      handleHideModal()
      globalHistory.push(RoutePath[Route.Orders])
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(isCanceling ? UseOrderMessage.cancelError : UseOrderMessage.deleteError),
      })
    }
  }

    const openCancelOrderModal = (successCallback?: () => void, isCanceling?: boolean) =>
      handleOpenModal({
        type: MODAL_TYPES.DELETE_ITEM,
        props: {
          title: intl.formatMessage(isCanceling ? UseOrderMessage.cancelOrderTitle : UseOrderMessage.deleteOrderTitle),
          description: intl.formatMessage(isCanceling ? UseOrderMessage.cancelOrderDescription : UseOrderMessage.deleteOrderDescription),
          submitText: intl.formatMessage(isCanceling ? UseOrderMessage.cancelOrderSubmitText : UseOrderMessage.deleteOrderSubmitText),
          handleSubmit: () =>
            handleCancelOrder(orderId.toString(), successCallback, isCanceling),
        },
      })

  return {
    order,
    loading,
    openCancelOrderModal,
  }
}
