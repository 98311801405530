import React, { FC, useEffect, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../../../../app/hooks/useModal'
import { ReactComponent as RedirectionIcon } from '../../../../../../../../../assets/icons/redirection-title.svg'
import { CommonButtonMessages } from '../../../../../../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { CommonMultiselectMessages } from '../../../../../../../../../config/intl/common-messages/common-multiselect-messages/CommonMultiselectMessages'
import { MODAL_TYPES } from '../../../../../../../../../layouts/modals/ModalsProvider'
import { EmptyCallRedirections } from '../../../../../../../../../layouts/status-layouts/empty-call-redirections/EmptyCallRedirections'
import { DongleListResponse } from '../../../../../../../../../sdk/datagates/types/dongle/_crud/list'
import { formatPhoneNumber } from '../../../../../../../../../sdk/formatters/format-phone-number'
import { REDIRECTION_TYPES } from '../../../../../../../../../sdk/hooks/use-redirection-types/constants/RedirectionTypes'
import { useRedirections } from '../../../../../../../../../sdk/hooks/use-redirections/useRedirections'
import { Card } from '../../../../../../../../../shared/ui-kit-2/data-display/card/Card'
import { Counter } from '../../../../../../../../../shared/ui-kit-2/data-display/counter/Counter'
import { Button } from '../../../../../../../../../shared/ui-kit-2/inputs/button/Button'
import {
  Multiselect,
  MultiselectOption,
} from '../../../../../../../../../shared/ui-kit-2/inputs/multiselect/Multiselect'
import { Loader } from '../../../../../../../../../shared/ui-kit/data-display/loader/Loader'
import { CallsRedirectionCard } from './components/redirection-card/CallsRedirectionCard'
import styles from './styles.module.scss'

interface CallRedirectionListProps {
  activeDevices: DongleListResponse['dongles']
}

const CallRedirectionListMessages = defineMessages({
  title: {
    id: 'CallRedirectionListMessages.title',
    defaultMessage: 'Redirection rules',
  },
  allDevices: {
    id: 'CallRedirectionListMessages.allDevices',
    defaultMessage: 'All devices',
  },
})

export const CallsRedirectionList: FC<CallRedirectionListProps> = (props) => {
  const { activeDevices } = props

  const intl = useIntl()
  const { handleOpenModal } = useModal()
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<string[]>([])
  const {
    redirections,
    totalCount,
    handleFetch,
    handleDeleteRedirection,
    loading,
  } = useRedirections({
    page: 0,
    limit: 0,
    redirectionTypes: [REDIRECTION_TYPES.CALL],
    deviceIds: selectedDeviceIds.length > 0 ? selectedDeviceIds : undefined,
    takeAll: true,
  })
  const [initialLoading, setInitialLoading] = useState<boolean>(true)
  const [atLeastOneRedirectionCreated, setAtLeastOneRedirectionCreated] =
    useState<boolean>(false)

  const devicesOptions: MultiselectOption[] = useMemo(
    () =>
      activeDevices?.map((device) => ({
        key: device.dongleId,
        label: device.name,
        subLabel: formatPhoneNumber(device.phoneNumber),
        value: device.dongleId.toString(),
      })),
    [activeDevices],
  )

  useEffect(() => {
    if (totalCount > 0) setAtLeastOneRedirectionCreated(true)
  }, [totalCount])

  useEffect(() => {
    if (!loading) setInitialLoading(false)
  }, [loading])

  const handleOpenRedirectionSettingsModal = () => {
    handleOpenModal({
      type: MODAL_TYPES.REDIRECTION_SETTINGS,
      props: {
        redirectionTypeId: REDIRECTION_TYPES.CALL,
        handleFetch: handleFetch,
        phoneSourceVariant: 'international-only',
      },
    })
  }

  const content = !atLeastOneRedirectionCreated ? (
    <EmptyCallRedirections />
  ) : (
    <>
      <Multiselect
        controlledValues={selectedDeviceIds}
        setControlledValues={setSelectedDeviceIds}
        options={devicesOptions}
        placeholder={intl.formatMessage(CallRedirectionListMessages.allDevices)}
        allOptionsSwitcher={false}
        allOptionsSwitcherLabel={intl.formatMessage(
          CommonMultiselectMessages.all,
        )}
        notFoundText={intl.formatMessage(
          CommonMultiselectMessages.nothingFound,
        )}
        searchPlaceholder={intl.formatMessage(CommonMultiselectMessages.search)}
        multiselectSize={'small'}
      />

      {loading ? (
        <Loader />
      ) : (
        <div className={styles.ScrollableContent}>
          {redirections.map((r) => (
            <CallsRedirectionCard
              key={r.redirectionId}
              redirection={r}
              handleFetch={handleFetch}
              handleDeleteRedirection={handleDeleteRedirection}
            />
          ))}
        </div>
      )}
    </>
  )

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <div className={styles.Header}>
        <RedirectionIcon />
        <div className={styles.Header__title}>
          {intl.formatMessage(CallRedirectionListMessages.title)}
        </div>
        {!loading && (
          <Counter
            count={totalCount}
            variant={'filled'}
            additionalClassNames={[styles.Header__counterAdditional]}
          />
        )}
      </div>

      {initialLoading ? <Loader /> : content}

      <div className={styles.CreateContainer}>
        <Button
          text={intl.formatMessage(CommonButtonMessages.create)}
          size={'small'}
          onClick={handleOpenRedirectionSettingsModal}
        />
      </div>
    </Card>
  )
}
