import {
  DongleRedirectionDeleteBackendResponse,
  DongleRedirectionDeleteResponse,
} from '../../../../../../types/dongle/redirection/_crud/delete'

export const mapDongleRedirectionDeleteBackendToDongleRedirectionDelete = (
  item: DongleRedirectionDeleteBackendResponse,
): DongleRedirectionDeleteResponse => {
  const { data } = item

  return {
    data: data,
  }
}
