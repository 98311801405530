import {
  ContactUpdateBackendResponse,
  ContactUpdateResponse,
} from '../../../../../types/contact/_crud/update'

export const mapContactUpdateBackendToContactUpdate = (
  item: ContactUpdateBackendResponse,
): ContactUpdateResponse => {
  const { contact_id, user_id, phone, name } = item.data

  return {
    contactId: contact_id,
    userId: user_id,
    phone: phone,
    name: name,
  }
}
