import {
  ContactGroupDeleteBackendResponse,
  ContactGroupDeleteResponse,
} from '../../../../../types/contact-group/_crud/delete'

export const mapContactGroupDeleteBackendToContactGroupDelete = (
  item: ContactGroupDeleteBackendResponse,
): ContactGroupDeleteResponse => {
  const { data } = item

  return {
    data,
  }
}
