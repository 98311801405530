import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { useSiteVersion } from '../../../../../../app/hooks/useSiteVersion'
import { useSnackbar } from '../../../../../../app/hooks/useSnackbar'
import { AuthRoutePath } from '../../../../../../config/routes/constants/authRoutePath'
import { AuthRoute } from '../../../../../../config/routes/enums/authRoute'
import { SNACKBAR_TYPES } from '../../../../../../layouts/snackbar/SnackbarProvider'
import { loginDatagate } from '../../../../../../sdk/datagates/api/auth'
import { userGetDatagate } from '../../../../../../sdk/datagates/api/user'
import { useBackendErrorCodes } from '../../../../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { InputField } from '../../../../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import { InputPasswordField } from '../../../../../../shared/lib/form/form-field-adapters/v2/input-password-field/InputPasswordField'
import { useFormRules } from '../../../../../../shared/lib/form/form-rules'
import { getErrorMessage } from '../../../../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Button } from '../../../../../../shared/ui-kit-2/inputs/button/Button'
import { TextButton } from '../../../../../../shared/ui-kit-2/inputs/text-button/TextButton'
import { useAppDispatch } from '../../../../../../store'
import {
  setAccessToken,
  setRefreshToken,
  setUser,
} from '../../../../../../store/reducers/user'
import { LC } from '../../../../../../tests/e2e/locators'
import styles from './styles.module.scss'

type SignInFormType = {
  email: string
  password: string
  remember: boolean
}

const SignInFormMessages = defineMessages({
  emailLabel: {
    id: 'SignInFormMessages.emailLabel',
    defaultMessage: 'Your Email',
  },
  emailPlaceholder: {
    id: 'SignInFormMessages.emailPlaceholder',
    defaultMessage: 'Enter email',
  },
  passwordLabel: {
    id: 'SignInFormMessages.passwordLabel',
    defaultMessage: 'Password',
  },
  passwordPlaceholder: {
    id: 'SignInFormMessages.passwordPlaceholder',
    defaultMessage: 'Enter password',
  },
  forgotPassword: {
    id: 'SignInFormMessages.forgotPassword',
    defaultMessage: 'Forgot password?',
  },
  submit: {
    id: 'SignInFormMessages.submit',
    defaultMessage: 'Log in',
  },
  userGetError: {
    id: 'SignInFormMessages.userGetError',
    defaultMessage:
      'Unable to get user profile data. Please, contact our technical support.',
  },
})

export const SignInForm = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { siteVersion, siteDomainId } = useSiteVersion()
  const { resolveBackendError } = useBackendErrorCodes()
  const { handleOpenSnackbar } = useSnackbar()
  const { ruleRequired } = useFormRules()
  const [loading, setLoading] = useState<boolean>(false)

  const toRecoverPassword = () =>
    navigate(AuthRoutePath[AuthRoute.RecoverPassword])

  const getUser = async () => {
    try {
      const { data } = await userGetDatagate()
      dispatch(setUser(data))
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(SignInFormMessages.userGetError),
      })
    }
  }

  const onSignIn = async (values: SignInFormType) => {
    try {
      setLoading(true)

      const { data } = await loginDatagate({
        login: values.email,
        password: values.password,
        site_domain_id: siteDomainId,
      })

      setAccessToken(data.accessToken)
      setRefreshToken(data.refreshToken)

      await getUser()
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form
      onSubmit={onSignIn}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} className={styles.Form}>
          <div className={styles.FormFields}>
            <InputField
              name="email"
              validate={ruleRequired()}
              label={intl.formatMessage(SignInFormMessages.emailLabel)}
              placeholder={intl.formatMessage(
                SignInFormMessages.emailPlaceholder,
              )}
              markAsRequired={true}
              testId={LC.SIGN_IN_PAGE.EMAIL_INPUT}
            />

            <InputPasswordField
              name="password"
              validate={ruleRequired()}
              label={intl.formatMessage(SignInFormMessages.passwordLabel)}
              placeholder={intl.formatMessage(
                SignInFormMessages.passwordPlaceholder,
              )}
              markAsRequired={true}
              testId={LC.SIGN_IN_PAGE.PASSWORD_INPUT}
            />
          </div>

          <Button
            type={'submit'}
            text={intl.formatMessage(SignInFormMessages.submit)}
            loading={loading}
            disabled={loading || submitting}
            additionalClassNames={[styles.SignInButtonAdditional]}
            testId={LC.SIGN_IN_PAGE.SUBMIT_BTN}
          />

          <TextButton
            text={intl.formatMessage(SignInFormMessages.forgotPassword)}
            variant={'bold'}
            additionalClassNames={[styles.ForgotPasswordAdditional]}
            onClick={toRecoverPassword}
          />

          {siteVersion === 'ru' && (
            <div className={styles.RuHelpText}>
              Для использования платформы вам необходимо зарегистрироваться.
            </div>
          )}
        </form>
      )}
    />
  )
}
