import React, { FC, useState } from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { globalHistory } from '../../../app/providers/with-router/withRouter'
import { ReactComponent as BankCardIcon } from '../../../assets/icons/bank-card.svg'
import { ReactComponent as LegalEntitiesIcon } from '../../../assets/icons/legaEntities.svg'
import { RoutePath } from '../../../config/routes/constants/routePath'
import { Route } from '../../../config/routes/enums/route'
import { handlePaymentRedirection } from '../../../integrations/payment-redirection'
import { paymentRechargeDatagate } from '../../../sdk/datagates/api/payment'
import { useBackendErrorCodes } from '../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { ORDER_PAYMENT_TYPE_IDS } from '../../../sdk/datagates/types/order/_common'
import { PAYMENT_PROVIDERS_IDS } from '../../../sdk/datagates/types/payment/_common/constants'
import { INVOICE_TYPE_IDS } from '../../../sdk/hooks/use-invoice-types/useInvoiceTypes'
import { usePaymentMethods } from '../../../sdk/hooks/use-payment-methods/usePaymentMethods'
import { InputField } from '../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import { RadioField } from '../../../shared/lib/form/form-field-adapters/v2/radio-field/RadioField'
import { RadioGroupField } from '../../../shared/lib/form/form-field-adapters/v2/radio-group-field/RadioGroupField'
import {
  composeValidators,
  useFormRules,
} from '../../../shared/lib/form/form-rules'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import { RADIO_VARIANT_LIST } from '../../../shared/ui-kit-2/inputs/radio/components/Radio'
import { ItemWrapper } from '../../../shared/ui-kit/data-display/ItemWrapper/ItemWrapper'
import { LC } from '../../../tests/e2e/locators'
import { LoadingContent } from '../../loading-content/LoadingContent'
import { SNACKBAR_TYPES } from '../../snackbar/SnackbarProvider'
import { MODAL_TYPES } from '../ModalsProvider'
import styles from './styles.module.scss'

type Props = {
  handleFetch?: () => void
}

interface RechargeBalanceFormType {
  orderPaymentTypeId: string
  amount: number
  paymentMethodId: string
}

const RechargeBalanceModalMessages = defineMessages({
  title: {
    id: 'RechargeBalanceModalMessages.title',
    defaultMessage: 'Top Up balance',
  },
  inputLabel: {
    id: 'RechargeBalanceModalMessages.inputLabel',
    defaultMessage: 'Enter Amount',
  },
  radioGroupTitle: {
    id: 'RechargeBalanceModalMessages.radioGroupTitle',
    defaultMessage: 'Choose method',
  },
  success: {
    id: 'RechargeBalanceModalMessages.success',
    defaultMessage: 'Success',
  },
  orderPaymentTypeLabel: {
    id: 'RechargeBalanceModalMessages.orderPaymentTypeLabel',
    defaultMessage: 'Payment method',
  },
  optionBankCard: {
    id: 'RechargeBalanceModalMessages.optionNewCard',
    defaultMessage: 'Bank card',
  },
  newBankCard: {
    id: 'RechargeBalanceModalMessages.newBankCard',
    defaultMessage: '+ New Card',
  },
  wireMethodTitle: {
    id: 'RechargeBalanceModalMessages.wireMethodTitle',
    defaultMessage: 'Our manager will send you payment details',
  },
  optionWireTransfer: {
    id: 'RechargeBalanceModalMessages.orderPaymentTypeLabel',
    defaultMessage: 'By details for legal entities',
  },
  error: {
    id: 'RechargeBalanceModalMessages.error',
    defaultMessage: 'An error occurred while recharging balance',
  },
  submit: {
    id: 'RechargeBalanceModalMessages.submit',
    defaultMessage: 'Top up',
  },
})

const PAYMENT_METHOD_DEFAULT_OPTION = ''

const isNewCard = (value?: string): boolean =>
  !(value === PAYMENT_METHOD_DEFAULT_OPTION)

export const RechargeBalanceModal: FC<Props> = () => {
  const { handleHideModal, props } = useModal()
  const { defaultAmount } = props

  const intl = useIntl()
  const { resolveBackendError } = useBackendErrorCodes()
  const { ruleRequired, ruleMustBeNumber, ruleMinValue, ruleMaxValue } =
    useFormRules()
  const { handleOpenModal } = useModal()
  const { handleOpenSnackbar } = useSnackbar()

  const { paymentMethods, loading: paymentMethodsLoading } = usePaymentMethods()
  const [paymentTypeId, setIsPaymentTypeId] = useState(
    ORDER_PAYMENT_TYPE_IDS.BANK_CARD,
  )
  const [paymentMethodId, setIsPaymentMethodId] = useState<string>(
    PAYMENT_METHOD_DEFAULT_OPTION,
  )
  const [loading, setLoading] = useState<boolean>(false)
  const handleClickOrderPaymentType = (typeId: number) => {
    if (typeId === ORDER_PAYMENT_TYPE_IDS.BANK_CARD) {
      setIsPaymentTypeId(typeId)
    } else {
      setIsPaymentTypeId(typeId)
      setIsPaymentMethodId(PAYMENT_METHOD_DEFAULT_OPTION)
    }
  }

  const onSubmit = async (values: RechargeBalanceFormType) => {
    try {
      setLoading(true)

      const isWireTransfer =
        +values.orderPaymentTypeId === ORDER_PAYMENT_TYPE_IDS.WIRE_TRANSFER

      const invoiceTypeId = isWireTransfer
        ? INVOICE_TYPE_IDS.WIRE_TRANSFER
        : isNewCard(values.paymentMethodId)
          ? INVOICE_TYPE_IDS.CREDIT
          : null

      const paymentMethodId =
        !isWireTransfer && isNewCard(values.paymentMethodId)
          ? Number(values.paymentMethodId)
          : null

      const paymentProviderId =
        !isWireTransfer && isNewCard(values.paymentMethodId)
          ? PAYMENT_PROVIDERS_IDS.REDSYS
          : null

      const { data } = await paymentRechargeDatagate({
        amount: values.amount * 100,
        invoice_type_id: invoiceTypeId,
        payment_method_id: paymentMethodId,
        payment_provider_id: paymentProviderId,
      })

      if (data.paymentCredentials)
        handlePaymentRedirection(data.paymentCredentials)
      else {
        handleHideModal()
        handleOpenModal({
          type: MODAL_TYPES.WIRE_TRANSFER_SUCCESS,
          props: {
            onClose: () => globalHistory.push(RoutePath[Route.Orders]),
          },
        })
      }
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(RechargeBalanceModalMessages.error),
        ),
      })

      setLoading(false)
    }
  }

  const content = (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        amount: defaultAmount,
        orderPaymentTypeId: ORDER_PAYMENT_TYPE_IDS.BANK_CARD.toString(),
      }}
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit} className={styles.Content}>
          <InputField
            name={'amount'}
            label={intl.formatMessage(RechargeBalanceModalMessages.inputLabel)}
            validate={composeValidators(
              ruleRequired(),
              ruleMustBeNumber(),
              ruleMinValue(1),
              ruleMaxValue(99999999),
            )}
            markAsRequired={true}
            validationNeeded={false}
            postfix={<div className={styles.InputPostfix}>€</div>}
            placeholder={'0.00'}
            testId={LC.RECHARGE_BALANCE_MODAL.AMOUNT_INPUT}
          />
          <div className={styles.RadioGroupWrapper}>
            <div className={styles.RadioGroupWrapper__title}>
              {intl.formatMessage(RechargeBalanceModalMessages.radioGroupTitle)}
            </div>
            <RadioGroupField
              name={'orderPaymentTypeId'}
              additionalClassNames={[styles.RadioButtons]}
              variant={RADIO_VARIANT_LIST.outlined}
              group={[
                {
                  label: intl.formatMessage(
                    RechargeBalanceModalMessages.optionBankCard,
                  ),
                  value: ORDER_PAYMENT_TYPE_IDS.BANK_CARD.toString(),
                  comparedValue: paymentTypeId.toString(),
                  onClick: () =>
                    handleClickOrderPaymentType(
                      ORDER_PAYMENT_TYPE_IDS.BANK_CARD,
                    ),
                },
                {
                  label: intl.formatMessage(
                    RechargeBalanceModalMessages.optionWireTransfer,
                  ),
                  value: ORDER_PAYMENT_TYPE_IDS.WIRE_TRANSFER.toString(),
                  testId: LC.RECHARGE_BALANCE_MODAL.WIRE_TRANSFER_OPTION,
                  comparedValue: paymentTypeId.toString(),
                  onClick: () =>
                    handleClickOrderPaymentType(
                      ORDER_PAYMENT_TYPE_IDS.WIRE_TRANSFER,
                    ),
                },
              ]}
            />
          </div>

          {paymentTypeId === ORDER_PAYMENT_TYPE_IDS.BANK_CARD ? (
            <ItemWrapper>
              <div className={styles.BankCards}>
                <div className={styles.BankCardNewWrapper}>
                  <RadioField
                    name={'paymentMethodId'}
                    label={intl.formatMessage(
                      RechargeBalanceModalMessages.newBankCard,
                    )}
                    value={PAYMENT_METHOD_DEFAULT_OPTION}
                    comparedValue={paymentMethodId}
                    additionalClassNames={[styles.RadioButtonBankCardNew]}
                    variant={RADIO_VARIANT_LIST.outlined}
                    onClick={() => setIsPaymentMethodId('0')}
                  />
                  <div className={styles.BankCardIcon}>
                    <BankCardIcon />
                  </div>
                </div>

                {paymentMethods.map((paymentMethod) => (
                  <RadioGroupField
                    name={'paymentMethodId'}
                    additionalClassNames={[styles.RadioButtonBankCard]}
                    variant={RADIO_VARIANT_LIST.outlined}
                    group={[
                      {
                        label: paymentMethod.name,
                        value: paymentMethod.paymentMethodId.toString(),
                        comparedValue: paymentMethodId,
                        onClick: () =>
                          setIsPaymentMethodId(
                            paymentMethod.paymentMethodId.toString(),
                          ),
                      },
                    ]}
                  />
                ))}
              </div>
            </ItemWrapper>
          ) : (
            <div className={styles.WireMethod}>
              <div className={styles.WireMethod__title}>
                {intl.formatMessage(
                  RechargeBalanceModalMessages.wireMethodTitle,
                )}
              </div>
              <LegalEntitiesIcon />
            </div>
          )}

          <div className={styles.SubmitButton}>
            <Button
              type={'submit'}
              text={intl.formatMessage(RechargeBalanceModalMessages.submit)}
              additionalClassNames={[styles.content_actions_submit]}
              onClick={handleSubmit}
              disabled={loading}
              loading={loading}
              testId={LC.RECHARGE_BALANCE_MODAL.SUBMIT}
            />
          </div>
        </form>
      )}
    />
  )

  return (
    <Drawer
      title={intl.formatMessage(RechargeBalanceModalMessages.title)}
      isOpen={true}
      close={handleHideModal}
      testId={LC.RECHARGE_BALANCE_MODAL._}
      fullWidth
    >
      <LoadingContent loading={paymentMethodsLoading} content={content} />
    </Drawer>
  )
}
