import React, { FC } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../../../../app/hooks/useModal'
import { MODAL_TYPES } from '../../../../../../../../../layouts/modals/ModalsProvider'
import { useAppSelector } from '../../../../../../../../../store'
import styles from './styles.module.scss'

const BalancePaymentMethodMessages = defineMessages({
  title: {
    id: 'BalancePaymentMethodMessages.title',
    defaultMessage: 'Your balance:',
  },
  insufficientBalance: {
    id: 'BalancePaymentMethod.insufficientBalance',
    defaultMessage: 'Insufficient balance.',
  },
  topUp: {
    id: 'BalancePaymentMethod.topUp',
    defaultMessage: 'Top up balance',
  },
  balance: {
    id: 'BalancePaymentMethod.balance',
    defaultMessage: '€ {balance}',
  },
})

export const BalancePaymentMethod: FC<{
  balanceNotEnoughAmount: number | null
}> = ({ balanceNotEnoughAmount }) => {
  const int = useIntl()
  const { balance } = useAppSelector((state) => state.user)
  const { handleOpenModal } = useModal()

  const topUpBalanceHandler = () => {
    handleOpenModal({
      type: MODAL_TYPES.RECHARGE_BALANCE,
      props: {
        defaultAmount: balanceNotEnoughAmount ?? 0,
      },
    })
  }

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Balance}>
        <div className={styles.Balance__info}>
          <div>{int.formatMessage(BalancePaymentMethodMessages.title)}</div>
          <div>{balance} €</div>
        </div>
        {balanceNotEnoughAmount && (
          <div className={styles.EmptyBalance}>
            <div>
              {int.formatMessage(
                BalancePaymentMethodMessages.insufficientBalance,
              )}
            </div>
            <div onClick={topUpBalanceHandler}>
              {int.formatMessage(BalancePaymentMethodMessages.topUp)}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
