import React from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { useSiteVersion } from '../../../../../app/hooks/useSiteVersion'
import RedsysImg from '../../../../../assets/images/redsys.png'
import { RoutePath } from '../../../../../config/routes/constants/routePath'
import { Route } from '../../../../../config/routes/enums/route'
import { FooterMessages } from '../../../../../layouts/footer/Footer'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../../shared/lib/hooks/useLessThanDeviceScreen'
import BackgroundImg from '../../../assets/footer.png'
import { HomeSectionIds } from '../../../constants/section-anchors/SectionAnchors'
import { ContactUsForm } from './components/contact-us-form/ContactUsForm'
import styles from './styles.module.scss'

export const Home09Messages = defineMessages({
  title: {
    id: 'Home09Messages.title',
    defaultMessage: 'We’d Love to hear from you!',
  },
})

export const Home09 = () => {
  const isLessThanTablet = useLessThanDeviceScreen(ScreenDevices.LAPTOP)
  const { siteVersion } = useSiteVersion()
  const intl = useIntl()

  const Title = () => (
    <div className={clsx(styles.Title, styles.Title_desktop)}>
      {intl.formatMessage(Home09Messages.title)}
    </div>
  )

  const Form = () => (
    <div className={styles.Col}>
      <ContactUsForm />
    </div>
  )

  const Info = () => (
    <div className={styles.Info}>
      <div className={styles.Info__Cols}>
        <div className={styles.Info__Col}>
          <div className={styles.Info__Label}>Office</div>
          <a href={'tel:+442030849971'} className={styles.Info__Value}>
            +44 20 3084 9971
          </a>
        </div>
        <div className={styles.Info__Col}>
          <div className={styles.Info__Label}>Email</div>
          <a href={'mailto:info@telecorn.com'} className={styles.Info__Value}>
            info@telecorn.com
          </a>
        </div>
        <div className={styles.Info__Col}>
          <div className={styles.Info__Label}>Support</div>
          <a href={'tel:+442030849972'} className={styles.Info__Value}>
            +44 20 3084 9972
          </a>
        </div>
      </div>
      <div className={styles.Address}>
        20 Hammersmith Broadway , London, W6 7AF United Kingdom
      </div>
    </div>
  )

  const Footer = () => {
    const Logo = () => (
      <div className={styles.Logo}>
        {siteVersion !== 'ru' ? <img src={RedsysImg} alt="redsys" /> : <></>}
      </div>
    )

    const Copyright = () => (
      <div className={styles.Copyright}>© 2024 Telecorn</div>
    )

    return (
      <div className={styles.Footer}>
        {isLessThanTablet ? (
          <div className={styles.Footer__MainMobile}>
            <Logo />
            <Copyright />
          </div>
        ) : (
          <>
            <Logo />
            <Copyright />
          </>
        )}
        <div className={styles.Links}>
          {siteVersion !== 'ru' && (
            <>
              <a
                className={styles.Link}
                href={RoutePath[Route.LegalNotice]}
                target={'_blank'}
              >
                {intl.formatMessage(FooterMessages.legalNotice)}
              </a>
              <a
                className={styles.Link}
                href={RoutePath[Route.TermsOfUse]}
                target={'_blank'}
              >
                {intl.formatMessage(FooterMessages.termsAndConditions)}
              </a>
              <a
                className={styles.Link}
                href={RoutePath[Route.PrivacyPolicy]}
                target={'_blank'}
              >
                {intl.formatMessage(FooterMessages.privacyPolicy)}
              </a>
            </>
          )}
        </div>
      </div>
    )
  }

  const DesktopBlocks = () => (
    <div className={styles.Wrapper}>
      <div className={styles.Cols}>
        <div className={clsx(styles.Col, styles.Col_top)}>
          <Title />
          {siteVersion !== 'ru' && <Info />}
        </div>
        <Form />
      </div>
      <Footer />
    </div>
  )

  const MobileBlocks = () => (
    <div className={styles.Wrapper}>
      <div className={styles.Cols}>
        <div className={styles.Col}>
          <Title />
        </div>
        <div className={styles.Col}>
          <Form />
        </div>
        {siteVersion !== 'ru' && (
          <>
            <div className={styles.Col}>
              <Info />
            </div>
            <div className={styles.Divider} />
          </>
        )}
      </div>
      <Footer />
    </div>
  )

  return (
    <div className={styles.Container} id={HomeSectionIds.CONTACT}>
      <div className={styles.Background}>
        <img src={BackgroundImg} alt="" />
      </div>
      <div className={styles.Mask} />
      {isLessThanTablet ? <MobileBlocks /> : <DesktopBlocks />}
    </div>
  )
}
