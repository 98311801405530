import { Route } from '../enums/route'

export const RoutePath: { [key in Route]: string } = {
  [Route.Home]: '/',
  [Route.LegalNotice]: '/legal-notice',
  [Route.PrivacyPolicy]: '/privacy-policy',
  [Route.TermsOfUse]: '/terms-of-use',
  [Route.NotFound]: '/not-found',
  [Route.FAQ]: '/faq',

  [Route.Auth]: '/auth/*',

  [Route.PurchaseProcess]: '/lk/purchase-process',
  [Route.ActivateDevice]: '/lk/activate-device',
  [Route.PaymentSuccess]: '/lk/payment-success',
  [Route.PaymentFail]: '/lk/payment-fail',
  [Route.AddCardSuccess]: '/lk/add-card-success',
  [Route.Orders]: '/lk/orders',
  [Route.DeviceList]: '/lk/device-list',
  [Route.Sms]: '/lk/sms/*',
  [Route.Calls]: '/lk/calls/*',
  [Route.WifiList]: '/lk/wifi-list',
  [Route.VPN]: '/lk/vpn',
  [Route.Profile]: '/lk/profile',
  [Route.Api]: '/lk/api',
  [Route.Contacts]: '/lk/contacts',
}
