import { ReactNode } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import BackgroundImage from '../../../assets/background-figures-blue.png'
import { ReactComponent as SliderLineImage } from '../../../assets/slider-line.svg'
import { Slider } from '../../Slider/Slider'
import styles from './styles.module.scss'

type ItemProps = {
  title: string
  subtitle?: string
  text: string | ReactNode
}

const Home04Messages = defineMessages({
  sliderTitle: {
    id: 'Home04Messages.sliderTitle',
    defaultMessage: 'Trusted across industries',
  },
  slide1Title: {
    id: 'Home04Messages.slide1Title',
    defaultMessage: 'Transportation',
  },
  slide1Subtitle: {
    id: 'Home04Messages.slide1Subtitle',
    defaultMessage: 'Enhance Passenger Experiences',
  },
  slide1Text: {
    id: 'Home04Messages.slide1Text',
    defaultMessage:
      "Telecorn offers Wi-Fi Hotspot connectivity within vehicles, ensuring passengers stay connected and enjoy a more pleasant journey. Elevate your customer's experience and gather real-time data for informed decision-making.",
  },
  slide2Title: {
    id: 'Home04Messages.slide2Title',
    defaultMessage: 'Logistics and Healthcare',
  },
  slide2Subtitle: {
    id: 'Home04Messages.slide2Subtitle',
    defaultMessage: 'Optimize Operations with IoT',
  },
  slide2p1: {
    id: 'Home04Messages.slide2p1',
    defaultMessage:
      "Logistics: Telecorn's IoT capabilities enable real-time tracking and communication for cargo and fleet management, ensuring efficient operations.",
  },
  slide2p2: {
    id: 'Home04Messages.slide2p2',
    defaultMessage:
      "Healthcare: Telecorn's IoT solutions support telemedicine and secure patient care, boosting the quality of healthcare services.",
  },
  slide3Title: {
    id: 'Home04Messages.slide3Title',
    defaultMessage: 'Financial Institution',
  },
  slide3Subtitle: {
    id: 'Home04Messages.slide3Subtitle',
    defaultMessage: 'Secure Identity Verification',
  },
  slide3Text: {
    id: 'Home04Messages.slide3Text',
    defaultMessage:
      'Telecorn provides secure mobile identity verification through SMS and Voice Calls, enhancing user confidence and preventing unauthorized access. Guarantee user identity verification in the most secure way!',
  },
  slide4Title: {
    id: 'Home04Messages.slide4Title',
    defaultMessage: 'Retail: Interactive 2-Way Conversations and Chatbots',
  },
  slide4Text: {
    id: 'Home04Messages.slide4Text',
    defaultMessage:
      "Retail businesses can engage customers in real-time interactive dialogues with Telecorn's 2-Way Conversations and Chatbot technology, enhancing customer satisfaction and sales.",
  },
  slide5Title: {
    id: 'Home04Messages.slide5Title',
    defaultMessage: 'All Industries',
  },
  slide5Subtitle: {
    id: 'Home04Messages.slide5Subtitle',
    defaultMessage: 'SAPI Integration for Seamless Communication',
  },
  slide5Text: {
    id: 'Home04Messages.slide5Text',
    defaultMessage:
      "Telecorn's API Integration ensures businesses across all industries can easily connect with leading CRM platforms and more. Streamline interactions, personalize communications, and expand their reach without the complexities or operational expenses",
  },
})
export const Home04 = () => {
  const intl = useIntl()

  const SLIDER_DATA = [
    {
      title: intl.formatMessage(Home04Messages.slide1Title),
      subtitle: intl.formatMessage(Home04Messages.slide1Subtitle),
      text: intl.formatMessage(Home04Messages.slide1Text),
    },
    {
      title: intl.formatMessage(Home04Messages.slide2Title),
      subtitle: intl.formatMessage(Home04Messages.slide2Subtitle),
      text: (
        <>
          <p>{intl.formatMessage(Home04Messages.slide2p1)}</p>
          <p>{intl.formatMessage(Home04Messages.slide2p2)}</p>
        </>
      ),
    },
    {
      title: intl.formatMessage(Home04Messages.slide3Title),
      subtitle: intl.formatMessage(Home04Messages.slide3Subtitle),
      text: intl.formatMessage(Home04Messages.slide3Text),
    },
    {
      title: intl.formatMessage(Home04Messages.slide4Title),
      text: intl.formatMessage(Home04Messages.slide4Text),
    },
    {
      title: intl.formatMessage(Home04Messages.slide5Title),
      subtitle: intl.formatMessage(Home04Messages.slide5Subtitle),
      text: intl.formatMessage(Home04Messages.slide5Text),
    },
  ]

  const Item = ({ title, subtitle, text }: ItemProps) => (
    <div className={styles.Item}>
      <div className={styles.Item__Line}>
        <SliderLineImage />
      </div>
      <div className={styles.Item__Title}>{title}</div>
      <div className={styles.Item__Subtitle}>{subtitle}</div>
      <div className={styles.Item__Text}>{text}</div>
    </div>
  )

  return (
    <div className={styles.Container}>
      <div className={styles.Wrapper}>
        <div className={styles.Background}>
          <img src={BackgroundImage} alt="" />
        </div>
        <Slider
          title={intl.formatMessage(Home04Messages.sliderTitle)}
          items={SLIDER_DATA.map((slide) => (
            <Item key={slide.title} {...slide} />
          ))}
        />
      </div>
    </div>
  )
}
