import { parseStringAsUTCDate } from '../../../../../../../../shared/lib/utils/date-utils/parseStringAsUTCDate'
import {
  InvoiceGetBackendResponse,
  InvoiceGetResponse,
} from '../../../../../../types/billing/invoice/_crud/get'

export const mapInvoiceGetBackendToInvoiceGet = (
  item: InvoiceGetBackendResponse,
): InvoiceGetResponse => {
  const {
    invoice_id,
    user_id,
    created_at,
    updated_at,
    order_id,
    invoice_file_id,
    receipt_file_id,
    invoice_status_id,
    invoice_type_id,
  } = item.data

  return {
    invoiceId: invoice_id,
    userId: user_id,
    createdAt: parseStringAsUTCDate(created_at),
    updatedAt: parseStringAsUTCDate(updated_at),
    orderId: order_id,
    invoiceFileId: invoice_file_id,
    receiptFileId: receipt_file_id,
    invoiceStatusId: invoice_status_id,
    invoiceTypeId: invoice_type_id,
  }
}
