import {
  PaymentRechargeBackendResponse,
  PaymentRechargeResponse,
} from '../../../../types/payment/recharge'

export const mapPaymentRechargeBackendResponseToPaymentRechargeResponse = (
  item: PaymentRechargeBackendResponse,
): PaymentRechargeResponse => {
  const { status, payment_credentials } = item.data

  return {
    status: status,
    paymentCredentials: payment_credentials
      ? {
          paymentProviderId: payment_credentials.service,
          ...payment_credentials,
        }
      : undefined,
  }
}
