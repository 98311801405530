import { FC, ReactNode } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

interface ItemWrapperProps {
  children: ReactNode
  additionalClassNames?: string[]
}

export const ItemWrapper: FC<ItemWrapperProps> = ({
  children,
  additionalClassNames = [],
}) => (
  <div className={clsx(styles.ModalItemWrapper, ...additionalClassNames)}>
    {children}
  </div>
)
