import { createContext, FC, ReactNode, useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { useSiteVersion } from '../../../../app/hooks/useSiteVersion'
import enMessages from '../../languages/en.json'
import ruMessages from '../../languages/ru.json'

interface ILanguageContextProps {
  langCode: LangCodeEnum
  setLangCode: (lang: LangCodeEnum) => void
}

export enum LangCodeEnum {
  EN = 'en-us',
  RU = 'ru-ru',
}

export const langCodeNamesMap = {
  [LangCodeEnum.EN]: 'en',
  [LangCodeEnum.RU]: 'ru',
}

const intlMessagesMap = {
  [LangCodeEnum.EN]: enMessages,
  [LangCodeEnum.RU]: ruMessages,
}

export const LanguageContext = createContext<ILanguageContextProps>({
  langCode: LangCodeEnum.RU,
  setLangCode: () => {},
})

interface ILanguageWrapperProps {
  children: ReactNode
}

export const LanguageWrapper: FC<ILanguageWrapperProps> = ({ children }) => {
  const { langCode, setLangCode } = useSiteVersion()

  const [messages, setMessages] = useState<Record<any, any>>(
    intlMessagesMap[langCode],
  )

  useEffect(() => {
    localStorage.setItem('lang_code', langCode)
    setMessages(intlMessagesMap[langCode])
  }, [langCode])

  return (
    <LanguageContext.Provider value={{ langCode, setLangCode }}>
      <IntlProvider
        locale="en"
        defaultLocale="en-us"
        messages={messages}
        onError={() => {}}
      >
        {' '}
        {/*TODO rm onError if need unmute console.error about using defaultText as fallback*/}
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  )
}
