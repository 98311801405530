import React, { useEffect, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useSnackbar } from '../../../../app/hooks/useSnackbar'
import { RoutePath } from '../../../../config/routes/constants/routePath'
import { Route } from '../../../../config/routes/enums/route'
import { SNACKBAR_TYPES } from '../../../../layouts/snackbar/SnackbarProvider'
import { googleAuthCallbackDatagate } from '../../../../sdk/datagates/api/oauth'
import { userGetDatagate } from '../../../../sdk/datagates/api/user'
import { useBackendErrorCodes } from '../../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { getErrorMessage } from '../../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Loader } from '../../../../shared/ui-kit/data-display/loader/Loader'
import {
  setAccessToken,
  setRefreshToken,
  setUser,
} from '../../../../store/reducers/user'
import styles from './styles.module.scss'

const ThirdPartyPageMessages = defineMessages({
  userGetError: {
    id: 'ThirdPartyPageMessages.userGetError',
    defaultMessage:
      'Unable to get user profile data. Please, contact our technical support.',
  },
})

export const ThirdPartyPage = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const dispatch = useDispatch()
  const { resolveBackendError } = useBackendErrorCodes()
  const { handleOpenSnackbar } = useSnackbar()
  const [loading, setLoading] = useState<boolean>(true)
  const [success, setSuccess] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const service = searchParams.get('service')
  const code = searchParams.get('code')

  const toLinkIsOutdated = () => navigate(RoutePath[Route.NotFound])

  const getUser = async () => {
    try {
      const { data } = await userGetDatagate()
      dispatch(setUser(data))
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(ThirdPartyPageMessages.userGetError),
        ),
      })
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        if (!service || !code) {
          setLoading(false)
          return
        }

        // Login via service name and auth token
        if (service === 'google') {
          const { data } = await googleAuthCallbackDatagate({ code: code })
          setAccessToken(data.accessToken)
          setRefreshToken(data.refreshToken)
          await getUser()
        }
      } catch (e) {
        setSuccess(false)
      } finally {
        setLoading(false)
      }
    })()

    return () => {
      setSuccess(false)
      setLoading(true)
    }
  }, [])

  const isCheckParamsFail = useMemo(
    () => !(loading || success),
    [loading, success],
  )

  if (!service || !code || isCheckParamsFail) {
    toLinkIsOutdated()
    return <></>
  }

  return (
    <div className={styles.Container}>
      <Loader />
    </div>
  )
}
