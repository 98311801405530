import React, {FC} from 'react';
import { SelectSearchField } from "../../../../../../../../../../shared/lib/form/form-field-adapters/v2/select-search-field/SelectSearchField";
import { InputField } from "../../../../../../../../../../shared/lib/form/form-field-adapters/v2/input-field/InputField";
import { ProfileFormMessages, ProfileFormType } from "../../ProfileForm";
import { useIntl } from "react-intl";
import { useFormRules } from "../../../../../../../../../../shared/lib/form/form-rules";
import styles from './styles.module.scss';

interface DeliveryAddressProfileFormProps {
  initialValues: Partial<ProfileFormType>;
  countryOptions: {key: number, value: string, label: string, inputLabel: string}[];
}

export const DeliveryAddressProfileFormDesktop: FC<DeliveryAddressProfileFormProps> = (props) => {
  const { initialValues, countryOptions } = props;
  const intl = useIntl();
  const { ruleRequired, ruleNotIncludeNumbers } = useFormRules();

  return (
    <div className={styles.ProfileGrid}>
        <>
	        <SelectSearchField
		        name="legal.countryId"
		        validate={ruleRequired()}
		        options={countryOptions}
		        label={intl.formatMessage(ProfileFormMessages.countryLabel)}
		        placeholder={intl.formatMessage(ProfileFormMessages.countryPlaceholder)}
		        markAsRequired={true}
		        defaultInputValue={
              countryOptions.find((c) => c.value === initialValues.legal?.countryId)
                ?.inputLabel
            }
	        />

	        <InputField
		        name="legal.postalCode"
		        label={intl.formatMessage(ProfileFormMessages.postalCodeLabel)}
		        placeholder={intl.formatMessage(ProfileFormMessages.postalCodeLabel)}
	        />

	        <InputField
		        name="legal.streetAddress1"
		        validate={ruleRequired()}
		        label={intl.formatMessage(ProfileFormMessages.addressLine1Label)}
		        placeholder={intl.formatMessage(ProfileFormMessages.addressLine1Label)}
		        markAsRequired={true}
	        />

	        <InputField
		        name="legal.streetAddress2"
		        label={intl.formatMessage(ProfileFormMessages.addressLine2Label)}
		        placeholder={intl.formatMessage(ProfileFormMessages.addressLine2Label)}
	        />

	        <InputField
		        name="legal.city"
		        validate={ruleNotIncludeNumbers()}
		        label={intl.formatMessage(ProfileFormMessages.cityLabel)}
		        placeholder={intl.formatMessage(ProfileFormMessages.cityLabel)}
	        />

	        <InputField
		        name="legal.region"
		        validate={ruleNotIncludeNumbers()}
		        label={intl.formatMessage(ProfileFormMessages.regionLabel)}
		        placeholder={intl.formatMessage(ProfileFormMessages.regionLabel)}
	        />
        </>
    </div>
  );
};
