import {
  ContactGroupGetBackendResponse,
  ContactGroupGetResponse,
} from '../../../../../types/contact-group/_crud/get'

export const mapContactGroupGetBackendToContactGroupGet = (
  item: ContactGroupGetBackendResponse,
): ContactGroupGetResponse => {
  const { contact_group_id, user_id, name, contacts } = item.data

  return {
    contactGroupId: contact_group_id,
    userId: user_id,
    name: name,
    contacts: contacts.map((c) => ({
      contactId: c.contact_id,
      name: c.name,
      phone: c.phone,
      userId: c.user_id,
    })),
  }
}
