import { useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { SNACKBAR_TYPES } from '../../../layouts/snackbar/SnackbarProvider'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import {
  paymentMethodDeleteDatagate,
  paymentMethodListDatagate,
  paymentMethodUpdateDatagate,
} from '../../datagates/api/payment'
import { useBackendErrorCodes } from '../../datagates/helpers/_common/use-backend-error-codes'
import { PaymentMethodListResponse } from '../../datagates/types/payment/payment-method/_crud/list'

const UsePaymentMethodsMessages = defineMessages({
  successDelete: {
    id: 'UsePaymentMethodsMessages.successDelete',
    defaultMessage: 'Payment method is successfully deleted',
  },
  errorDelete: {
    id: 'UsePaymentMethodsMessages.successDelete',
    defaultMessage: 'An error occurred while deleting payment method',
  },
  successUpdate: {
    id: 'UsePaymentMethodsMessages.successUpdate',
    defaultMessage: 'Payment method is successfully updated',
  },
  errorUpdate: {
    id: 'UsePaymentMethodsMessages.errorUpdate',
    defaultMessage: 'An error occurred while updating payment method',
  },
})

export const usePaymentMethods = () => {
  const intl = useIntl()

  const { handleOpenSnackbar } = useSnackbar()
  const { handleHideModal } = useModal()
  const { resolveBackendError } = useBackendErrorCodes()

  const [loading, setLoading] = useState<boolean>(true)
  const [paymentMethods, setPaymentMethods] = useState<
    PaymentMethodListResponse['paymentMethods']
  >([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [errorText, setErrorText] = useState<string | null>(null)

  const handleDeletePaymentMethod = async (paymentMethodId: number) => {
    try {
      const urlParams = [
        { name: 'payment_method_id', value: paymentMethodId.toString() },
      ]

      await paymentMethodDeleteDatagate(null, urlParams)
      await handleFetch()
      handleHideModal()
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(UsePaymentMethodsMessages.successDelete),
      })
    } catch {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(UsePaymentMethodsMessages.errorDelete),
      })
    }
  }

  const handleUpdatePaymentMethod = async (
    paymentMethodId: number,
    type: 'main' | 'reserve',
    texts?: { successText?: string; errorText?: string },
  ) => {
    try {
      setLoading(true)

      const urlParams = [
        { name: 'payment_method_id', value: paymentMethodId.toString() },
      ]

      await paymentMethodUpdateDatagate(
        {
          is_default: type === 'main',
        },
        urlParams,
      )
      await handleFetch()
      handleHideModal()
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text:
          texts?.successText ||
          intl.formatMessage(UsePaymentMethodsMessages.successDelete),
      })
    } catch {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text:
          texts?.errorText ||
          intl.formatMessage(UsePaymentMethodsMessages.errorDelete),
      })
    }
  }

  const handleFetch = async () => {
    try {
      setLoading(true)

      const queryParams = [
        { name: 'order_by', value: 'created_at' },
        { name: 'order_type', value: 'desc' },
      ]

      const { data } = await paymentMethodListDatagate(null, null, queryParams)

      setTotalCount(data.paymentMethods.length)
      setPaymentMethods(data.paymentMethods)
    } catch (e) {
      setErrorText(resolveBackendError(getErrorMessage(e)))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void handleFetch()
  }, [])

  return {
    paymentMethods,
    totalCount,
    loading,
    errorText,
    handleFetch,
    handleUpdatePaymentMethod,
    handleDeletePaymentMethod,
  }
}
