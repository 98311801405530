import {
  CountryListBackendResponse,
  CountryListResponse,
} from '../../../../../types/country/_crud/list'

export const mapCountryListBackendToCountryList = (
  item: CountryListBackendResponse,
): CountryListResponse => {
  const { countries, total_count } = item.data

  return {
    countries: countries.map((c) => {
      return { countryId: c.country_id, iso: c.iso }
    }),
    totalCount: total_count,
  }
}
