import { wrapApiRequest } from '../../helpers/_common/wrap-api-request'
import { mapCallbackRequestFormBackendToCallbackRequestForm } from '../../mappers/callback/callback-request-form/map-callback-request-form-backend-to-callback-request-form'
import { mapCallbackRequestBackendToCallbackRequest } from '../../mappers/callback/callback-request/map-callback-request-backend-to-callback-request'
import {
  CallbackRequestBackendResponse,
  CallbackRequestRequest,
  CallbackRequestResponse,
} from '../../types/callback/callback-request'
import {
  CallbackRequestFormBackendResponse,
  CallbackRequestFormRequest,
  CallbackRequestFormRequestBuyDevice,
  CallbackRequestFormResponse,
} from '../../types/callback/callback-request-form'

export const callbackRequestDatagate = wrapApiRequest<
  CallbackRequestRequest,
  CallbackRequestBackendResponse,
  CallbackRequestResponse
>('callback-request', mapCallbackRequestBackendToCallbackRequest)

export const callbackRequestFormDatagate = wrapApiRequest<
  CallbackRequestFormRequest,
  CallbackRequestFormBackendResponse,
  CallbackRequestFormResponse
>('callback-request/form', mapCallbackRequestFormBackendToCallbackRequestForm)

export const callbackRequestFormDatagateBuyDevice = wrapApiRequest<
  CallbackRequestFormRequestBuyDevice,
  CallbackRequestFormBackendResponse,
  CallbackRequestFormResponse
>('callback-request', mapCallbackRequestFormBackendToCallbackRequestForm)
