import { useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { queryFromFilters } from '../../../layouts/filters/_helpers/queryFromFilters'
import { FilterType } from '../../../layouts/filters/hook/useFilters'
import { SNACKBAR_TYPES } from '../../../layouts/snackbar/SnackbarProvider'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import { dongleSmsDeleteDatagate } from '../../datagates/api/dongle'
import { smsListDatagate } from '../../datagates/api/sms'
import { useBackendErrorCodes } from '../../datagates/helpers/_common/use-backend-error-codes'
import { QueryParameter } from '../../datagates/helpers/_common/wrap-api-request'
import { SmsListResponse } from '../../datagates/types/sms/_crud/list'

type UseSmsesProps = {
  page: number
  limit: number
  smsTypeTab?: string
  filters?: FilterType
  takeAll?: boolean
  mailingHistoryId?: number
  sortBy?: string
  orderBy?: string
  smsSourceTypeIds?: number
}

export const SmsType = {
  OUTGOING: 1,
  INCOMING: 2,
  AUTO_REPLY: 3,
  REPLY: 4,
  DELAYED: 5,
  MAILING: 6,
  SILENT: 7,
}

export const SmsTypeTabs = {
  ALL: '',
  OUTGOING: SmsType.OUTGOING.toString(),
  INCOMING: SmsType.INCOMING.toString(),
  DELAYED: SmsType.DELAYED.toString(),
}

const UseSmsesMessages = defineMessages({
  positiveDeleted: {
    id: 'UseSmsesMessages.positiveDeleted',
    defaultMessage: 'SMS is successfully deleted',
  },
  negativeDeleted: {
    id: 'UseSmsesMessages.negativeDeleted',
    defaultMessage: 'An error occurred while deleting SMS',
  },
})

export const getSmsTypesFromSmsTypeTab = (smsTypeTab?: string) => {
  switch (smsTypeTab) {
    case SmsTypeTabs.OUTGOING:
      return [
        SmsType.OUTGOING,
        SmsType.REPLY,
        SmsType.AUTO_REPLY,
        SmsType.MAILING,
      ]
    case SmsTypeTabs.INCOMING:
      return [SmsType.INCOMING, SmsType.SILENT]
    case SmsTypeTabs.DELAYED:
      return [SmsType.DELAYED]
    default:
      return undefined
  }
}

export const canSmsBeReplied = (sms: SmsListResponse['smses'][0]) => {
  return (
    (sms.smsTypeId === SmsType.INCOMING || sms.smsTypeId === SmsType.SILENT) &&
    !(
      sms.repliedFromSms &&
      [SmsType.REPLY, SmsType.AUTO_REPLY].includes(sms.repliedFromSms.smsTypeId)
    )
  )
}

export const isSmsAutoReplied = (sms: SmsListResponse['smses'][0]) => {
  return sms.repliedFromSms?.smsTypeId === SmsType.AUTO_REPLY
}

export const isSmsManuallyReplied = (sms: SmsListResponse['smses'][0]) => {
  return sms.repliedFromSms?.smsTypeId === SmsType.REPLY
}

export const isSmsRedirected = (sms: SmsListResponse['smses'][0]) => {
  return sms.redirectedTo.length > 0
}

export const isDeviceSender = (smsTypeId: number) =>
  [
    SmsType.OUTGOING,
    SmsType.DELAYED,
    SmsType.REPLY,
    SmsType.AUTO_REPLY,
    SmsType.MAILING,
  ].includes(smsTypeId)

export const useSmses = (props: UseSmsesProps) => {
  const {
    page,
    limit,
    sortBy,
    orderBy,
    smsTypeTab,
    smsSourceTypeIds,
    filters,
    takeAll = false,
    mailingHistoryId,
  } = props

  const intl = useIntl()
  const { resolveBackendError } = useBackendErrorCodes()
  const { handleHideModal } = useModal()
  const { handleOpenSnackbar } = useSnackbar()

  const [smses, setSmses] = useState<SmsListResponse['smses']>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)

  const handleDeleteSms = async (dongleId: number, smsId: number) => {
    try {
      const urlParams = [
        { name: 'dongle_id', value: dongleId.toString() },
        { name: 'sms_id', value: smsId.toString() },
      ]

      await dongleSmsDeleteDatagate(null, urlParams)
      await handleFetch()
      handleHideModal()
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(UseSmsesMessages.positiveDeleted),
      })
    } catch {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(UseSmsesMessages.negativeDeleted),
      })
    }
  }
  const handleFetch = async () => {
    try {
      setLoading(true)

      const queryParams: QueryParameter[] = [
        { name: 'order_by', value: 'created_at' },
        { name: 'order_type', value: 'desc' },
      ]

      if (mailingHistoryId) {
        queryParams.push({
          name: 'mailing_history_ids',
          value: [mailingHistoryId.toString()],
        })
        queryParams.push({
          name: 'sms_type_ids',
          value: [SmsType.MAILING.toString()],
        })
      }

      const smsTypes = getSmsTypesFromSmsTypeTab(smsTypeTab)
      if (smsTypes && !mailingHistoryId) {
        queryParams.push({
          name: 'sms_type_ids',
          value: smsTypes.map((s) => s.toString()),
        })
      }

      if (!takeAll) {
        queryParams.push(
          { name: 'page', value: (page - 1).toString() },
          { name: 'limit', value: limit.toString() },
        )
      }

      if (filters) {
        const filterQueryParams = queryFromFilters(filters)
        queryParams.push(...filterQueryParams)
      }

      const { data } = await smsListDatagate(null, null, queryParams)

      setTotalCount(data.totalCount)
      setSmses(data.smses)
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void handleFetch()
  }, [page, limit, filters, sortBy, orderBy, smsTypeTab, smsSourceTypeIds])

  return {
    smses,
    totalCount,
    handleFetch,
    handleDeleteSms,
    loading,
  }
}
