import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { MAILING_STATUSES } from '../../../sdk/hooks/use-mailing-statuses/constants/MailingStatuses'
import { useMailing } from '../../../sdk/hooks/use-mailing/useMailing'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Loader } from '../../../shared/ui-kit/data-display/loader/Loader'
import styles from '../SmsMailingCreateModal/styles.module.scss'
import { SmsMailingCreatedContent } from './components/sms-mailing-created-content/SmsMailingCreatedContent'
import { SmsMailingDraftContent } from './components/sms-mailing-draft-content/SmsMailingDraftContent'

const SmsMailingViewModalMessages = defineMessages({
  mailingIdError: {
    id: 'SmsMailingViewModalMessages.mailingIdError',
    defaultMessage: 'An error occurred while trying to view mailing',
  },
  titleDraft: {
    id: 'SmsMailingViewModalMessages.titleDraft',
    defaultMessage: 'Create SMS Mailing',
  },
  title: {
    id: 'SmsMailingViewModalMessages.titleDraft',
    defaultMessage: 'Bulk SMS',
  },
})

export const SmsMailingViewModal = () => {
  const intl = useIntl()

  const { handleHideModal, props } = useModal()

  const { mailingId, handleFetch } = props

  const forceAction: 'delete' | 'cancel' | null = props.forceAction || null

  const { mailing, loading, handleCancelMailing, handleDeleteMailing } =
    useMailing({ mailingId: mailingId })

  if (loading) {
    return (
      <Drawer
        isOpen={true}
        close={handleHideModal}
        additionalClassNames={[styles.DrawerAdditional]}
      >
        <Loader />
      </Drawer>
    )
  }

  if (!mailing) {
    handleHideModal()
    return null
  }

  const title =
    mailing.mailingStatusId === MAILING_STATUSES.DRAFT
      ? intl.formatMessage(SmsMailingViewModalMessages.titleDraft)
      : intl.formatMessage(SmsMailingViewModalMessages.title)

  const afterAction = () => {
    handleHideModal()
    handleFetch?.()
  }

  const onDeleteMailing = async () => {
    await handleDeleteMailing(mailing.mailingId.toString(), handleHideModal)
    afterAction()
  }
  const onCancelMailing = async () => {
    await handleCancelMailing(mailing, handleHideModal)
    afterAction()
  }

  return (
    <Drawer
      title={title}
      isOpen={true}
      close={handleHideModal}
      additionalClassNames={[styles.DrawerAdditional]}
    >
      {mailing.mailingStatusId === MAILING_STATUSES.DRAFT ? (
        <SmsMailingDraftContent
          mailing={mailing}
          handleHideModal={handleHideModal}
          onDeleteMailing={onDeleteMailing}
          handleFetchMailings={handleFetch}
          forceAction={forceAction}
        />
      ) : (
        <SmsMailingCreatedContent
          mailing={mailing}
          handleHideModal={handleHideModal}
          onCancelMailing={onCancelMailing}
          onDeleteMailing={onDeleteMailing}
          forceAction={forceAction}
        />
      )}
    </Drawer>
  )
}
