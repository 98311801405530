export const getUrlParams = (): { [key: string]: string } => {
  const currentUrl = new URL(window.location.href);

  const params: { [key: string]: string } = {};

  currentUrl.searchParams.forEach((value, key) => {
    params[key] = value;
  });

  return params;
}
