import React from 'react'
import { useSiteVersion } from '../../../../app/hooks/useSiteVersion'
import { PageWrapper } from '../../../../layouts/page-wrapper/PageWrapper'
import ProfilePageEn from './components/profilePageEn/profilePageEn'
import ProfilePageRu from './components/profilePageRu/profilePageRu'

export const ProfilePage = () => {
  const { siteVersion } = useSiteVersion()

  const profileContent =
    siteVersion === 'en' ? <ProfilePageEn /> : <ProfilePageRu />

  return <PageWrapper>{profileContent}</PageWrapper>
}
