import {ReactComponent as NigeriaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Nigeria.svg";
import {ReactComponent as AntiguaAndBarbudaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/AntiguaAndBarbuda.svg";
import {ReactComponent as SwitzerlandIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Switzerland.svg";
import {ReactComponent as PanamaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Panama.svg";
import {ReactComponent as SouthKoreaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/SouthKorea.svg";
import {ReactComponent as OmanIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Oman.svg";
import {ReactComponent as SenegalIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Senegal.svg";
import {ReactComponent as ComorosIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Comoros.svg";
import {ReactComponent as MalaysiaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Malaysia.svg";
import {ReactComponent as MontenegroIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Montenegro.svg";
import {ReactComponent as NewZealandIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/NewZealand.svg";
import {ReactComponent as MadagascarIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Madagascar.svg";
import {ReactComponent as GrenadaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Grenada.svg";
import {ReactComponent as ElSalvadorIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/ElSalvador.svg";
import {ReactComponent as TongaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Tonga.svg";
import {ReactComponent as LibyaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Libya.svg";
import {ReactComponent as SaintVicentandtheGrenadinesIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/SaintVicentandtheGrenadines.svg";
import {ReactComponent as EquatorialGuineaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/EquatorialGuinea.svg";
import {ReactComponent as EcuadorIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Ecuador.svg";
import {ReactComponent as UnitedKingdomIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/UnitedKingdom.svg";
import {ReactComponent as GabonIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Gabon.svg";
import {ReactComponent as CyprusIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Cyprus.svg";
import {ReactComponent as AndorraIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Andorra.svg";
import {ReactComponent as GreeceIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Greece.svg";
import {ReactComponent as HolySeeIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/HolySee.svg";
import {ReactComponent as SintMaartenIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/SintMaarten.svg";
import {ReactComponent as BeninIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Benin.svg";
import {ReactComponent as BrazilIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Brazil.svg";
import {ReactComponent as MozambiqueIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Mozambique.svg";
import {ReactComponent as BelgiumIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Belgium.svg";
import {ReactComponent as BosniaAndHerzegovinaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/BosniaAndHerzegovina.svg";
import {ReactComponent as CostaRicaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/CostaRica.svg";
import {ReactComponent as MoldovaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Moldova.svg";
import {ReactComponent as SeychellesIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Seychelles.svg";
import {ReactComponent as BurundiIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Burundi.svg";
import {ReactComponent as SwedenIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Sweden.svg";
import {ReactComponent as NepalIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Nepal.svg";
import {ReactComponent as MonacoIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Monaco.svg";
import {ReactComponent as TurkeyIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Turkey.svg";
import {ReactComponent as CameroonIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Cameroon.svg";
import {ReactComponent as AustraliaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Australia.svg";
import {ReactComponent as SouthSudanIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/SouthSudan.svg";
import {ReactComponent as SyrianArabRepublicIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/SyrianArabRepublic.svg";
import {ReactComponent as KiribatiIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Kiribati.svg";
import {ReactComponent as ArmeniaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Armenia.svg";
import {ReactComponent as HondurasIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Honduras.svg";
import {ReactComponent as DRCongoIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/DRCongo.svg";
import {ReactComponent as RwandaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Rwanda.svg";
import {ReactComponent as AlgeriaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Algeria.svg";
import {ReactComponent as ArubaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Aruba.svg";
import {ReactComponent as BurkinaFasoIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/BurkinaFaso.svg";
import {ReactComponent as BouvetIslandIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/BouvetIsland.svg";
import {ReactComponent as SaudiArabiaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/SaudiArabia.svg";
import {ReactComponent as CubaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Cuba.svg";
import {ReactComponent as TajikistanIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Tajikistan.svg";
import {ReactComponent as KyrgyzstanIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Kyrgyzstan.svg";
import {ReactComponent as LaosIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Laos.svg";
import {ReactComponent as NorthMacedoniaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/NorthMacedonia.svg";
import {ReactComponent as EstoniaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Estonia.svg";
import {ReactComponent as RussianFederationIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/RussianFederation.svg";
import {ReactComponent as GuineaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Guinea.svg";
import {ReactComponent as TunisiaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Tunisia.svg";
import {ReactComponent as CuraçaoIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Curaçao.svg";
import {ReactComponent as UkraineIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Ukraine.svg";
import {ReactComponent as CzechRepublicIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/CzechRepublic.svg";
import {ReactComponent as AngolaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Angola.svg";
import {ReactComponent as DjiboutiIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Djibouti.svg";
import {ReactComponent as CambodiaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Cambodia.svg";
import {ReactComponent as TrinidadandTobagoIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/TrinidadandTobago.svg";
import {ReactComponent as LesothoIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Lesotho.svg";
import {ReactComponent as ZambiaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Zambia.svg";
import {ReactComponent as SouthAfricaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/SouthAfrica.svg";
import {ReactComponent as VanuatyIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Vanuaty.svg";
import {ReactComponent as BruneiDarussalamIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/BruneiDarussalam.svg";
import {ReactComponent as JamaicaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Jamaica.svg";
import {ReactComponent as TanzaniaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Tanzania.svg";
import {ReactComponent as IranIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Iran.svg";
import {ReactComponent as NamibiaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Namibia.svg";
import {ReactComponent as VenezuelaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Venezuela.svg";
import {ReactComponent as ColombiaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Colombia.svg";
import {ReactComponent as LiberiaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Liberia.svg";
import {ReactComponent as CapeVerdeIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/CapeVerde.svg";
import {ReactComponent as IcelandIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Iceland.svg";
import {ReactComponent as HungaryIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Hungary.svg";
import {ReactComponent as KazakhstanIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Kazakhstan.svg";
import {ReactComponent as ArgentinaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Argentina.svg";
import {ReactComponent as ChinaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/China.svg";
import {ReactComponent as PolandIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Poland.svg";
import {ReactComponent as BoliviaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Bolivia.svg";
import {ReactComponent as GuatemalaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Guatemala.svg";
import {ReactComponent as GhanaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Ghana.svg";
import {ReactComponent as KenyaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Kenya.svg";
import {ReactComponent as AfghanistanIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Afghanistan.svg";
import {ReactComponent as SamoaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Samoa.svg";
import {ReactComponent as NorwayIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Norway.svg";
import {ReactComponent as MexicoIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Mexico.svg";
import {ReactComponent as BelizeIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Belize.svg";
import {ReactComponent as BotswanaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Botswana.svg";
import {ReactComponent as MoroccoIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Morocco.svg";
import {ReactComponent as RomaniaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Romania.svg";
import {ReactComponent as ChileIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Chile.svg";
import {ReactComponent as IndonesiaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Indonesia.svg";
import {ReactComponent as SloveniaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Slovenia.svg";
import {ReactComponent as SlovakiaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Slovakia.svg";
import {ReactComponent as NauruIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Nauru.svg";
import {ReactComponent as LithuaniaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Lithuania.svg";
import {ReactComponent as NorthKoreaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/NorthKorea.svg";
import {ReactComponent as SierraLeoneIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/SierraLeone.svg";
import {ReactComponent as IrelandIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Ireland.svg";
import {ReactComponent as FinlandIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Finland.svg";
import {ReactComponent as UzbekistanIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Uzbekistan.svg";
import {ReactComponent as JapanIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Japan.svg";
import {ReactComponent as VietNamIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/VietNam.svg";
import {ReactComponent as UruguayIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Uruguay.svg";
import {ReactComponent as MaltaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Malta.svg";
import {ReactComponent as TimorLesteIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/TimorLeste.svg";
import {ReactComponent as EgyptIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Egypt.svg";
import {ReactComponent as NigerIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Niger.svg";
import {ReactComponent as HongKongIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/HongKong.svg";
import {ReactComponent as MalawiIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Malawi.svg";
import {ReactComponent as CroatiaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Croatia.svg";
import {ReactComponent as FijiIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Fiji.svg";
import {ReactComponent as JordanIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Jordan.svg";
import {ReactComponent as CongoIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Congo.svg";
import {ReactComponent as PalestineIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Palestine.svg";
import {ReactComponent as IraqIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Iraq.svg";
import {ReactComponent as BahamasIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Bahamas.svg";
import {ReactComponent as EthiopiaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Ethiopia.svg";
import {ReactComponent as NetherlandsIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Netherlands.svg";
import {ReactComponent as GeorgiaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Georgia.svg";
import {ReactComponent as LebanonIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Lebanon.svg";
import {ReactComponent as SingaporeIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Singapore.svg";
import {ReactComponent as PeruIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Peru.svg";
import {ReactComponent as IndiaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/India.svg";
import {ReactComponent as PhilippinesIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Philippines.svg";
import {ReactComponent as SolomonIslandsIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/SolomonIslands.svg";
import {ReactComponent as BarbadosIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Barbados.svg";
import {ReactComponent as GuineaBissauIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/GuineaBissau.svg";
import {ReactComponent as AlbaniaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Albania.svg";
import {ReactComponent as DenmarkIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Denmark.svg";
import {ReactComponent as PalauIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Palau.svg";
import {ReactComponent as BelarusIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Belarus.svg";
import {ReactComponent as EritreaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Eritrea.svg";
import {ReactComponent as ChadIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Chad.svg";
import {ReactComponent as LiechtensteinIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Liechtenstein.svg";
import {ReactComponent as ItalyIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Italy.svg";
import {ReactComponent as ZimbabweIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Zimbabwe.svg";
import {ReactComponent as FranceIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/France.svg";
import {ReactComponent as DominicaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Dominica.svg";
import {ReactComponent as KuwaitIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Kuwait.svg";
import {ReactComponent as ThailandIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Thailand.svg";
import {ReactComponent as MarshallIslandsIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/MarshallIslands.svg";
import {ReactComponent as BangladeshIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Bangladesh.svg";
import {ReactComponent as SerbiaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Serbia.svg";
import {ReactComponent as MaliIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Mali.svg";
import {ReactComponent as SurinameIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Suriname.svg";
import {ReactComponent as MongoliaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Mongolia.svg";
import {ReactComponent as BahrainIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Bahrain.svg";
import {ReactComponent as LuxembourgIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Luxembourg.svg";
import {ReactComponent as ParaguayIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Paraguay.svg";
import {ReactComponent as SriLankaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/SriLanka.svg";
import {ReactComponent as SaoTomeandPrincipeIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/SaoTomeandPrincipe.svg";
import {ReactComponent as EswatiniIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Eswatini.svg";
import {ReactComponent as PapuaNewGuineaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/PapuaNewGuinea.svg";
import {ReactComponent as PortugalIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Portugal.svg";
import {ReactComponent as MauritiusIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Mauritius.svg";
import {ReactComponent as SpainIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Spain.svg";
import {ReactComponent as UnitedArabEmiratesIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/UnitedArabEmirates.svg";
import {ReactComponent as TuvaluIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Tuvalu.svg";
import {ReactComponent as MyanmarIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Myanmar.svg";
import {ReactComponent as TurkmenistanIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Turkmenistan.svg";
import {ReactComponent as NicaraguaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Nicaragua.svg";
import {ReactComponent as HaitiIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Haiti.svg";
import {ReactComponent as SaintLuciaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/SaintLucia.svg";
import {ReactComponent as UgandaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Uganda.svg";
import {ReactComponent as AustriaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Austria.svg";
import {ReactComponent as IsraelIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Israel.svg";
import {ReactComponent as LatviaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Latvia.svg";
import {ReactComponent as SudanIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Sudan.svg";
import {ReactComponent as MaldivesIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Maldives.svg";
import {ReactComponent as SomaliaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Somalia.svg";
import {ReactComponent as SanMarinoIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/SanMarino.svg";
import {ReactComponent as GermanyIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Germany.svg";
import {ReactComponent as BhutanIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Bhutan.svg";
import {ReactComponent as QatarIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Qatar.svg";
import {ReactComponent as AzerbaijanIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Azerbaijan.svg";
import {ReactComponent as PakistanIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Pakistan.svg";
import {ReactComponent as MauritaniaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Mauritania.svg";
import {ReactComponent as BulgariaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Bulgaria.svg";
import {ReactComponent as TogoIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Togo.svg";
import {ReactComponent as GambiaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Gambia.svg";
import {ReactComponent as IvoryCoastIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/IvoryCoast.svg";
import {ReactComponent as YemenIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Yemen.svg";
import {ReactComponent as CanadaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Canada.svg";
import {ReactComponent as GuyanaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Guyana.svg";
import {ReactComponent as MicronesiaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/Micronesia.svg";
import {ReactComponent as DominicanRepublicIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/DominicanRepublic.svg";
import {ReactComponent as VirginIslandsIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/VirginIslands.svg";
import {ReactComponent as CentralAfricanRepublicIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/CentralAfricanRepublic.svg";
import {ReactComponent as UnitedStatesofAmericaIcon} from "../../ui-kit-2/inputs/input-phone-number/assets/icons/flags/UnitedStatesofAmerica.svg";

import {ReactNode} from "react";

export const CountryPhones: { [key: string]: { flag: ReactNode; code: string, label: string } } = {
    GB: { flag: <UnitedKingdomIcon />, code: '+44', label: 'United Kingdom' },
    CA: { flag: <CanadaIcon />, code: '+1', label: 'Canada' },
    US: { flag: <UnitedStatesofAmericaIcon />, code: '+1', label: 'USA' },
    NG: { flag: <NigeriaIcon />, code: '+234', label: 'Nigeria' },
    AG: { flag: <AntiguaAndBarbudaIcon />, code: '+1268', label: 'Antigua and Barbuda' },
    CH: { flag: <SwitzerlandIcon />, code: '+41', label: 'Switzerland' },
    PA: { flag: <PanamaIcon />, code: '+507', label: 'Panama' },
    KR: { flag: <SouthKoreaIcon />, code: '+82', label: 'South Korea' },
    OM: { flag: <OmanIcon />, code: '+968', label: 'Oman' },
    SN: { flag: <SenegalIcon />, code: '+221', label: 'Senegal' },
    KM: { flag: <ComorosIcon />, code: '+269', label: 'Comoros' },
    MY: { flag: <MalaysiaIcon />, code: '+60', label: 'Malaysia' },
    ME: { flag: <MontenegroIcon />, code: '+382', label: 'Montenegro' },
    NZ: { flag: <NewZealandIcon />, code: '+64', label: 'New Zealand' },
    MG: { flag: <MadagascarIcon />, code: '+261', label: 'Madagascar' },
    GD: { flag: <GrenadaIcon />, code: '+1473', label: 'Grenada' },
    SV: { flag: <ElSalvadorIcon />, code: '+503', label: 'El Salvador' },
    TO: { flag: <TongaIcon />, code: '+676', label: 'Tonga' },
    LY: { flag: <LibyaIcon />, code: '+218', label: 'Libya' },
    VC: { flag: <SaintVicentandtheGrenadinesIcon />, code: '+1784', label: 'Saint Vincent and the Grenadines' },
    GQ: { flag: <EquatorialGuineaIcon />, code: '+240', label: 'Equatorial Guinea' },
    EC: { flag: <EcuadorIcon />, code: '+593', label: 'Ecuador' },
    GA: { flag: <GabonIcon />, code: '+241', label: 'Gabon' },
    CY: { flag: <CyprusIcon />, code: '+357', label: 'Cyprus' },
    AD: { flag: <AndorraIcon />, code: '+376', label: 'Andorra' },
    GR: { flag: <GreeceIcon />, code: '+30', label: 'Greece' },
    VA: { flag: <HolySeeIcon />, code: '+379', label: 'Holy See' },
    SX: { flag: <SintMaartenIcon />, code: '+1721', label: 'Sint Maarten' },
    BJ: { flag: <BeninIcon />, code: '+229', label: 'Benin' },
    BR: { flag: <BrazilIcon />, code: '+55', label: 'Brazil' },
    MZ: { flag: <MozambiqueIcon />, code: '+258', label: 'Mozambique' },
    BE: { flag: <BelgiumIcon />, code: '+32', label: 'Belgium' },
    BA: { flag: <BosniaAndHerzegovinaIcon />, code: '+387', label: 'Bosnia and Herzegovina' },
    CR: { flag: <CostaRicaIcon />, code: '+506', label: 'Costa Rica' },
    MD: { flag: <MoldovaIcon />, code: '+373', label: 'Moldova' },
    SC: { flag: <SeychellesIcon />, code: '+248', label: 'Seychelles' },
    BI: { flag: <BurundiIcon />, code: '+257', label: 'Burundi' },
    SE: { flag: <SwedenIcon />, code: '+46', label: 'Sweden' },
    NP: { flag: <NepalIcon />, code: '+977', label: 'Nepal' },
    MC: { flag: <MonacoIcon />, code: '+377', label: 'Monaco' },
    TR: { flag: <TurkeyIcon />, code: '+90', label: 'Turkey' },
    CM: { flag: <CameroonIcon />, code: '+237', label: 'Cameroon' },
    AU: { flag: <AustraliaIcon />, code: '+61', label: 'Australia' },
    SS: { flag: <SouthSudanIcon />, code: '+211', label: 'South Sudan' },
    SY: { flag: <SyrianArabRepublicIcon />, code: '+963', label: 'Syrian Arab Republic' },
    KI: { flag: <KiribatiIcon />, code: '+686', label: 'Kiribati' },
    AM: { flag: <ArmeniaIcon />, code: '+374', label: 'Armenia' },
    HN: { flag: <HondurasIcon />, code: '+504', label: 'Honduras' },
    CD: { flag: <DRCongoIcon />, code: '+243', label: 'Democratic Republic of the Congo' },
    RW: { flag: <RwandaIcon />, code: '+250', label: 'Rwanda' },
    DZ: { flag: <AlgeriaIcon />, code: '+213', label: 'Algeria' },
    AW: { flag: <ArubaIcon />, code: '+297', label: 'Aruba' },
    BF: { flag: <BurkinaFasoIcon />, code: '+226', label: 'Burkina Faso' },
    BV: { flag: <BouvetIslandIcon />, code: '+47', label: 'Bouvet Island' },
    SA: { flag: <SaudiArabiaIcon />, code: '+966', label: 'Saudi Arabia' },
    CU: { flag: <CubaIcon />, code: '+53', label: 'Cuba' },
    TJ: { flag: <TajikistanIcon />, code: '+992', label: 'Tajikistan' },
    KG: { flag: <KyrgyzstanIcon />, code: '+996', label: 'Kyrgyzstan' },
    LA: { flag: <LaosIcon />, code: '+856', label: 'Laos' },
    MK: { flag: <NorthMacedoniaIcon />, code: '+389', label: 'North Macedonia' },
    EE: { flag: <EstoniaIcon />, code: '+372', label: 'Estonia' },
    RU: { flag: <RussianFederationIcon />, code: '+7', label: 'Russian Federation' },
    GN: { flag: <GuineaIcon />, code: '+224', label: 'Guinea' },
    TN: { flag: <TunisiaIcon />, code: '+216', label: 'Tunisia' },
    CW: { flag: <CuraçaoIcon />, code: '+599', label: 'Curaçao' },
    UA: { flag: <UkraineIcon />, code: '+380', label: 'Ukraine' },
    CZ: { flag: <CzechRepublicIcon />, code: '+420', label: 'Czech Republic' },
    AO: { flag: <AngolaIcon />, code: '+244', label: 'Angola' },
    DJ: { flag: <DjiboutiIcon />, code: '+253', label: 'Djibouti' },
    KH: { flag: <CambodiaIcon />, code: '+855', label: 'Cambodia' },
    TT: { flag: <TrinidadandTobagoIcon />, code: '+1868', label: 'Trinidad and Tobago' },
    LS: { flag: <LesothoIcon />, code: '+266', label: 'Lesotho' },
    ZM: { flag: <ZambiaIcon />, code: '+260', label: 'Zambia' },
    ZA: { flag: <SouthAfricaIcon />, code: '+27', label: 'South Africa' },
    VU: { flag: <VanuatyIcon />, code: '+678', label: 'Vanuatu' },
    BN: { flag: <BruneiDarussalamIcon />, code: '+673', label: 'Brunei Darussalam' },
    JM: { flag: <JamaicaIcon />, code: '+1876', label: 'Jamaica' },
    TZ: { flag: <TanzaniaIcon />, code: '+255', label: 'Tanzania' },
    IR: { flag: <IranIcon />, code: '+98', label: 'Iran' },
    NA: { flag: <NamibiaIcon />, code: '+264', label: 'Namibia' },
    VE: { flag: <VenezuelaIcon />, code: '+58', label: 'Venezuela' },
    CO: { flag: <ColombiaIcon />, code: '+57', label: 'Colombia' },
    LR: { flag: <LiberiaIcon />, code: '+231', label: 'Liberia' },
    CV: { flag: <CapeVerdeIcon />, code: '+238', label: 'Cape Verde' },
    IS: { flag: <IcelandIcon />, code: '+354', label: 'Iceland' },
    HU: { flag: <HungaryIcon />, code: '+36', label: 'Hungary' },
    KZ: { flag: <KazakhstanIcon />, code: '+7', label: 'Kazakhstan' },
    AR: { flag: <ArgentinaIcon />, code: '+54', label: 'Argentina' },
    CN: { flag: <ChinaIcon />, code: '+86', label: 'China' },
    PL: { flag: <PolandIcon />, code: '+48', label: 'Poland' },
    BO: { flag: <BoliviaIcon />, code: '+591', label: 'Bolivia' },
    GT: { flag: <GuatemalaIcon />, code: '+502', label: 'Guatemala' },
    GH: { flag: <GhanaIcon />, code: '+233', label: 'Ghana' },
    KE: { flag: <KenyaIcon />, code: '+254', label: 'Kenya' },
    AF: { flag: <AfghanistanIcon />, code: '+93', label: 'Afghanistan' },
    WS: { flag: <SamoaIcon />, code: '+685', label: 'Samoa' },
    NO: { flag: <NorwayIcon />, code: '+47', label: 'Norway' },
    MX: { flag: <MexicoIcon />, code: '+52', label: 'Mexico' },
    BZ: { flag: <BelizeIcon />, code: '+501', label: 'Belize' },
    BW: { flag: <BotswanaIcon />, code: '+267', label: 'Botswana' },
    MA: { flag: <MoroccoIcon />, code: '+212', label: 'Morocco' },
    RO: { flag: <RomaniaIcon />, code: '+40', label: 'Romania' },
    CL: { flag: <ChileIcon />, code: '+56', label: 'Chile' },
    ID: { flag: <IndonesiaIcon />, code: '+62', label: 'Indonesia' },
    SI: { flag: <SloveniaIcon />, code: '+386', label: 'Slovenia' },
    SK: { flag: <SlovakiaIcon />, code: '+421', label: 'Slovakia' },
    NR: { flag: <NauruIcon />, code: '+674', label: 'Nauru' },
    LT: { flag: <LithuaniaIcon />, code: '+370', label: 'Lithuania' },
    KP: { flag: <NorthKoreaIcon />, code: '+850', label: 'North Korea' },
    SL: { flag: <SierraLeoneIcon />, code: '+232', label: 'Sierra Leone' },
    IE: { flag: <IrelandIcon />, code: '+353', label: 'Ireland' },
    FI: { flag: <FinlandIcon />, code: '+358', label: 'Finland' },
    UZ: { flag: <UzbekistanIcon />, code: '+998', label: 'Uzbekistan' },
    JP: { flag: <JapanIcon />, code: '+81', label: 'Japan' },
    VN: { flag: <VietNamIcon />, code: '+84', label: 'Vietnam' },
    UY: { flag: <UruguayIcon />, code: '+598', label: 'Uruguay' },
    MT: { flag: <MaltaIcon />, code: '+356', label: 'Malta' },
    TL: { flag: <TimorLesteIcon />, code: '+670', label: 'Timor-Leste' },
    EG: { flag: <EgyptIcon />, code: '+20', label: 'Egypt' },
    NE: { flag: <NigerIcon />, code: '+227', label: 'Niger' },
    HK: { flag: <HongKongIcon />, code: '+852', label: 'Hong Kong' },
    MW: { flag: <MalawiIcon />, code: '+265', label: 'Malawi' },
    HR: { flag: <CroatiaIcon />, code: '+385', label: 'Croatia' },
    FJ: { flag: <FijiIcon />, code: '+679', label: 'Fiji' },
    JO: { flag: <JordanIcon />, code: '+962', label: 'Jordan' },
    CG: { flag: <CongoIcon />, code: '+242', label: 'Congo' },
    PS: { flag: <PalestineIcon />, code: '+970', label: 'Palestine' },
    IQ: { flag: <IraqIcon />, code: '+964', label: 'Iraq' },
    BS: { flag: <BahamasIcon />, code: '+1242', label: 'Bahamas' },
    ET: { flag: <EthiopiaIcon />, code: '+251', label: 'Ethiopia' },
    NL: { flag: <NetherlandsIcon />, code: '+31', label: 'Netherlands' },
    GE: { flag: <GeorgiaIcon />, code: '+995', label: 'Georgia' },
    LB: { flag: <LebanonIcon />, code: '+961', label: 'Lebanon' },
    SG: { flag: <SingaporeIcon />, code: '+65', label: 'Singapore' },
    PE: { flag: <PeruIcon />, code: '+51', label: 'Peru' },
    IN: { flag: <IndiaIcon />, code: '+91', label: 'India' },
    PH: { flag: <PhilippinesIcon />, code: '+63', label: 'Philippines' },
    SB: { flag: <SolomonIslandsIcon />, code: '+677', label: 'Solomon Islands' },
    BB: { flag: <BarbadosIcon />, code: '+1246', label: 'Barbados' },
    GW: { flag: <GuineaBissauIcon />, code: '+245', label: 'Guinea-Bissau' },
    AL: { flag: <AlbaniaIcon />, code: '+355', label: 'Albania' },
    DK: { flag: <DenmarkIcon />, code: '+45', label: 'Denmark' },
    PW: { flag: <PalauIcon />, code: '+680', label: 'Palau' },
    BY: { flag: <BelarusIcon />, code: '+375', label: 'Belarus' },
    ER: { flag: <EritreaIcon />, code: '+291', label: 'Eritrea' },
    TD: { flag: <ChadIcon />, code: '+235', label: 'Chad' },
    LI: { flag: <LiechtensteinIcon />, code: '+423', label: 'Liechtenstein' },
    IT: { flag: <ItalyIcon />, code: '+39', label: 'Italy' },
    ZW: { flag: <ZimbabweIcon />, code: '+263', label: 'Zimbabwe' },
    FR: { flag: <FranceIcon />, code: '+33', label: 'France' },
    DM: { flag: <DominicaIcon />, code: '+1767', label: 'Dominica' },
    KW: { flag: <KuwaitIcon />, code: '+965', label: 'Kuwait' },
    TH: { flag: <ThailandIcon />, code: '+66', label: 'Thailand' },
    MH: { flag: <MarshallIslandsIcon />, code: '+692', label: 'Marshall Islands' },
    BD: { flag: <BangladeshIcon />, code: '+880', label: 'Bangladesh' },
    RS: { flag: <SerbiaIcon />, code: '+381', label: 'Serbia' },
    ML: { flag: <MaliIcon />, code: '+223', label: 'Mali' },
    SR: { flag: <SurinameIcon />, code: '+597', label: 'Suriname' },
    MN: { flag: <MongoliaIcon />, code: '+976', label: 'Mongolia' },
    BH: { flag: <BahrainIcon />, code: '+973', label: 'Bahrain' },
    LU: { flag: <LuxembourgIcon />, code: '+352', label: 'Luxembourg' },
    PY: { flag: <ParaguayIcon />, code: '+595', label: 'Paraguay' },
    LK: { flag: <SriLankaIcon />, code: '+94', label: 'Sri Lanka' },
    ST: { flag: <SaoTomeandPrincipeIcon />, code: '+239', label: 'Sao Tome and Principe' },
    SZ: { flag: <EswatiniIcon />, code: '+268', label: 'Eswatini' },
    PG: { flag: <PapuaNewGuineaIcon />, code: '+675', label: 'Papua New Guinea' },
    PT: { flag: <PortugalIcon />, code: '+351', label: 'Portugal' },
    MU: { flag: <MauritiusIcon />, code: '+230', label: 'Mauritius' },
    ES: { flag: <SpainIcon />, code: '+34', label: 'Spain' },
    AE: { flag: <UnitedArabEmiratesIcon />, code: '+971', label: 'United Arab Emirates' },
    TV: { flag: <TuvaluIcon />, code: '+688', label: 'Tuvalu' },
    MM: { flag: <MyanmarIcon />, code: '+95', label: 'Myanmar' },
    TM: { flag: <TurkmenistanIcon />, code: '+993', label: 'Turkmenistan' },
    NI: { flag: <NicaraguaIcon />, code: '+505', label: 'Nicaragua' },
    HT: { flag: <HaitiIcon />, code: '+509', label: 'Haiti' },
    LC: { flag: <SaintLuciaIcon />, code: '+1758', label: 'Saint Lucia' },
    UG: { flag: <UgandaIcon />, code: '+256', label: 'Uganda' },
    AT: { flag: <AustriaIcon />, code: '+43', label: 'Austria' },
    IL: { flag: <IsraelIcon />, code: '+972', label: 'Israel' },
    LV: { flag: <LatviaIcon />, code: '+371', label: 'Latvia' },
    SD: { flag: <SudanIcon />, code: '+249', label: 'Sudan' },
    MV: { flag: <MaldivesIcon />, code: '+960', label: 'Maldives' },
    SO: { flag: <SomaliaIcon />, code: '+252', label: 'Somalia' },
    SM: { flag: <SanMarinoIcon />, code: '+378', label: 'San Marino' },
    DE: { flag: <GermanyIcon />, code: '+49', label: 'Germany' },
    BT: { flag: <BhutanIcon />, code: '+975', label: 'Bhutan' },
    QA: { flag: <QatarIcon />, code: '+974', label: 'Qatar' },
    AZ: { flag: <AzerbaijanIcon />, code: '+994', label: 'Azerbaijan' },
    PK: { flag: <PakistanIcon />, code: '+92', label: 'Pakistan' },
    MR: { flag: <MauritaniaIcon />, code: '+222', label: 'Mauritania' },
    BG: { flag: <BulgariaIcon />, code: '+359', label: 'Bulgaria' },
    TG: { flag: <TogoIcon />, code: '+228', label: 'Togo' },
    GM: { flag: <GambiaIcon />, code: '+220', label: 'Gambia' },
    CI: { flag: <IvoryCoastIcon />, code: '+225', label: 'Ivory Coast' },
    YE: { flag: <YemenIcon />, code: '+967', label: 'Yemen' },
    GY: { flag: <GuyanaIcon />, code: '+592', label: 'Guyana' },
    FM: { flag: <MicronesiaIcon />, code: '+691', label: 'Micronesia' },
    DO: { flag: <DominicanRepublicIcon />, code: '+1', label: 'Dominican Republic' },
    VI: { flag: <VirginIslandsIcon />, code: '+1', label: 'Virgin Islands' },
    CF: { flag: <CentralAfricanRepublicIcon />, code: '+236', label: 'Central African Republic' },
};
