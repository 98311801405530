import React, { FC } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

const SWITCHER_VARIANT_LIST = {
  gray: 'gray',
  white: 'white',
} as const

export interface SwitcherProps extends React.HTMLProps<HTMLInputElement> {
  label?: string
  error?: string
  markAsRequired?: boolean
  variant?: (typeof SWITCHER_VARIANT_LIST)[keyof typeof SWITCHER_VARIANT_LIST]
  additionalClassNames?: string[]
  testId?: string
}

export const Switcher: FC<SwitcherProps> = (props) => {
  const {
    label,
    testId,
    error,
    markAsRequired,
    variant = SWITCHER_VARIANT_LIST.gray,
    additionalClassNames = [],
    ...otherProps
  } = props

  return (
    <label
      className={clsx(styles.Container, ...additionalClassNames)}
      data-test-id={testId}
      tabIndex={0}
    >
      <input type="checkbox" className={styles.HiddenInput} {...otherProps} />

      <div
        className={clsx(
          styles.Switcher,
          variant === SWITCHER_VARIANT_LIST.white && styles.Switcher_white,
        )}
      >
        <div className={styles.Circle} />
      </div>

      {label && (
        <div className={styles.LabelWrapper}>
          <div className={styles.Label}>
            {label}
            {markAsRequired && <span>*</span>}
          </div>
          {error && <div className={styles.Error}>{error}</div>}
        </div>
      )}
    </label>
  )
}
