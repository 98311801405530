/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 modem_gltf.gltf
*/
// @ts-nocheck
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Modem3D(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/3d-models/modem/modem_gltf.gltf')
  return (
    <group position={[0, 0, 0]} ref={group} {...props} dispose={null}>
      <group position={[0, 0, 0]} rotation={[0, 0, Math.PI / 2]}>
        <group position={[-0.033, 0, 0]}>
          <mesh
            geometry={nodes.modem_telo.geometry}
            material={materials.body}
            position={[-0.127, -0.018, 0.002]}
          />
          <mesh
            geometry={nodes.modem_all2.geometry}
            material={materials.body_main}
            position={[-0.051, 0.005, 0]}
          />
        </group>
        <mesh
          geometry={nodes.Object145.geometry}
          material={materials.body}
          position={[0.469, 0, 0]}
        />
        <mesh
          geometry={nodes.Object001.geometry}
          material={materials['05 - Default']}
          position={[-0.084, 0.005, 0]}
        />
      </group>
      <mesh
        geometry={nodes.Object146.geometry}
        material={materials.body}
        position={[-0.006, -0.084, 0.001]}
        rotation={[0, 0, Math.PI / 2]}
      />
      <mesh
        geometry={nodes.Object147.geometry}
        material={materials['aluminium flash']}
        position={[-0.002, 0.243, -0.001]}
        rotation={[-1.559, -0.001, 1.572]}
        scale={[7.378, -7.597, 22.373]}
      />
      <mesh
        geometry={nodes.Object148.geometry}
        material={materials['black flash']}
        position={[-0.002, 0.243, -0.001]}
        rotation={[-1.559, -0.001, 1.572]}
        scale={[7.378, -7.597, 22.373]}
      />
      <mesh
        geometry={nodes.Object149.geometry}
        material={materials['al_2 flash']}
        position={[-0.002, 0.243, -0.001]}
        rotation={[-1.559, -0.001, 1.572]}
        scale={[7.378, -7.597, 22.373]}
      />
    </group>
  )
}

useGLTF.preload('/3d-models/modem/modem_gltf.gltf')
