import React, { FC } from 'react'
import clsx from 'clsx'
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/arrow-up.svg'
import { ReactComponent as HourglassIcon } from '../../../assets/icons/hourglass.svg'
import { SmsType } from '../../../sdk/hooks/use-smses/useSmses'
import styles from './styles.module.scss'

interface SmsTypeBadgeProps {
  type: number
}

export const SmsTypeBadge: FC<SmsTypeBadgeProps> = (props) => {
  const { type } = props

  return (
    <div
      className={clsx(
        styles.Badge,
        (type === SmsType.INCOMING || type === SmsType.SILENT) &&
          styles.Badge_incoming,
        type === SmsType.DELAYED && styles.Badge_delayed,
        [
          SmsType.OUTGOING,
          SmsType.REPLY,
          SmsType.AUTO_REPLY,
          SmsType.MAILING,
        ].includes(type) && styles.Badge_outgoing,
      )}
    >
      {type === SmsType.DELAYED && (
        <div className={styles.Badge__delayedIcon}>
          <HourglassIcon />
        </div>
      )}

      <ArrowUpIcon />
    </div>
  )
}
