import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { CommonTableMessages } from '../../../config/intl/common-messages/common-table-messages/CommonTableMessages'
import { SmsListResponse } from '../../../sdk/datagates/types/sms/_crud/list'
import {
  Table,
  TableColType,
} from '../../../shared/ui-kit-2/data-display/table/Table'
import { ReactComponent as SortAlphabetIconUnActive } from "../../../assets/icons/sort-by-alphabet-unactive.svg";
import { ReactComponent as SortAlphabetIconTrue } from "../../../assets/icons/sort-by-alphabet-true.svg";
import { ReactComponent as SortAlphabetIconFalse } from "../../../assets/icons/sort-by-alphabet-false.svg";
import { LC } from '../../../tests/e2e/locators'
import { NoDevicesForSmses } from '../../status-layouts/no-devices-for-smses/NoDevicesForSmses'
import { SmsTableRows } from './components/sms-table-rows/SmsTableRows'

interface SmsTableProps {
  smses: SmsListResponse['smses']
  totalCount: number
  loading: boolean
  handleFetch: () => Promise<void>
  handleDeleteSms: (dongleId: number, smsId: number) => Promise<void>
  activeDevicesTotalCount: number
  settings: Record<string, any>
  handleChangeSettings: (arg: Object) => void
}

export const SORTED_COLS = {
  status: 'sms_status_id',
  time: 'created_at'
}

export const SmsTable: FC<SmsTableProps> = (props) => {
  const {
    smses,
    loading,
    totalCount,
    handleFetch,
    handleDeleteSms,
    activeDevicesTotalCount,
    settings,
    handleChangeSettings
  } = props

  const intl = useIntl()

  const handlePageChange = (page: number, limit: number) => handleChangeSettings({ page, limit })

  const handleSorted = (field: string) => {
    if (!loading) {
      const orderBy = field !== settings.sortBy
        ? 'desc'
        : settings.orderBy === 'asc'
          ? 'desc'
          : 'asc'
      handleChangeSettings({ orderBy, sortBy: field })
    }
  }

  const getIsActive = (field: string) => settings.sortBy === field;
  const getIsSorted = (field: string) => getIsActive(field) && settings.orderBy === 'desc';

  const getSortIcon = (field: string) => {
    if (settings.sortBy === field) {
      return settings.orderBy === 'desc' ? <SortAlphabetIconTrue /> : <SortAlphabetIconFalse />
    }

    return <SortAlphabetIconUnActive />
  }

  const cols: TableColType[] = [
    { title: intl.formatMessage(CommonTableMessages.columnType) },
    {
      title: intl.formatMessage(CommonTableMessages.columnTime),
      position: 'center',
      handleSorted: () => handleSorted(SORTED_COLS.time),
      isSorted: getIsSorted(SORTED_COLS.time),
      isActive: getIsActive(SORTED_COLS.time),
    },
    {
      title: intl.formatMessage(CommonTableMessages.columnStatus),
      handleSorted: () => handleSorted(SORTED_COLS.status),
      isSorted: getIsSorted(SORTED_COLS.status),
      isActive: getIsActive(SORTED_COLS.status),
      sortIcon: getSortIcon(SORTED_COLS.status)
    },
    { title: intl.formatMessage(CommonTableMessages.columnSender) },
    { title: ' ', position: 'center' },
    { title: intl.formatMessage(CommonTableMessages.columnReceiver) },
    {
      title: intl.formatMessage(CommonTableMessages.columnSmsc),
      position: 'center',
    },
    { title: intl.formatMessage(CommonTableMessages.columnText) },
    {
      title: intl.formatMessage(CommonTableMessages.columnActions),
      position: 'right',
    },
  ]

  const rowGroups = SmsTableRows(smses, handleFetch, handleDeleteSms, settings)

  return (
    <Table
      cols={cols}
      rowGroups={rowGroups}
      currentPage={settings.page}
      totalCount={totalCount}
      onPageChange={handlePageChange}
      itemsPerPage={settings.limit}
      loading={loading}
      columnWidths={[0.5, 1, 1.5, 1.25, 0.5, 1.25, 2, 2, 2.5]}
      testId={LC.SMS.TABLE._}
      noDataComponent={activeDevicesTotalCount ? null : <NoDevicesForSmses />}
    />
  )
}
