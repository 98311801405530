import React, { useContext } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useTariffPackages } from '../../../../../../../../sdk/hooks/use-tariff-packages/useTariffPackages'
import { Card } from '../../../../../../../../shared/ui-kit-2/data-display/card/Card'
import { PaginationMobile } from '../../../../../../../../shared/ui-kit-2/data-display/pagination/PaginationMobile'
import { OrdersPageContext } from '../../../../contexts/OrdersPageContext'
import { OrderCardMobile } from './components/order-card/OrderCardMobile'
import styles from './style.module.scss'

const OrdersMobileMessages = defineMessages({
  title: {
    id: 'OrdersMobileMessages.title',
    defaultMessage: 'My orders',
  },
  loadMore: {
    id: 'OrdersMobileMessages.loadMore',
    defaultMessage: 'Load more',
  },
})

export const OrdersMobile = () => {
  const intl = useIntl()

  const {
    orderFullList,
    loading,
    totalCount,
    setOrdersPage,
    setOrdersLimitPage,
    limitOrdersPage,
    ordersPage,
  } = useContext(OrdersPageContext)

  const handlePageChange = (page: number, limit: number) => {
    setOrdersLimitPage(limit)
    setOrdersPage(page)
  }

  const { getTariffPackagePrice, getTariffPackageTranslation } =
    useTariffPackages()
  const sliceOrders = orderFullList.slice(0, 3000)

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <div className={styles.Title}>
        {intl.formatMessage(OrdersMobileMessages.title)}
      </div>

      <div className={styles.ScrollableContent}>
        {sliceOrders.map((order) => (
          <OrderCardMobile
            key={order.orderId}
            order={order}
            getTariffPackagePrice={getTariffPackagePrice}
            getTariffPackageTranslation={getTariffPackageTranslation}
          />
        ))}
      </div>
      <PaginationMobile
        currentPage={ordersPage}
        totalCount={totalCount}
        itemsPerPage={limitOrdersPage}
        onPageChange={handlePageChange}
      />
    </Card>
  )
}
