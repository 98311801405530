import {
  RedirectionDeleteBackendResponse,
  RedirectionDeleteResponse,
} from '../../../../../types/redirection/_crud/delete'

export const mapRedirectionDeleteBackendToRedirectionDelete = (
  item: RedirectionDeleteBackendResponse,
): RedirectionDeleteResponse => {
  const { data } = item

  return {
    data: data,
  }
}
