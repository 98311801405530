import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../app/hooks/useModal'
import { ReactComponent as CaretRightIcon } from '../../../../assets/icons/caret-right.svg'
import { ReactComponent as CreditCards } from '../../../../assets/icons/two-credit-card.svg'
import { useUserSubscription } from '../../../../store/hooks/useUserSubscription'
import { MODAL_TYPES } from '../../../modals/ModalsProvider'
import styles from './styles.module.scss'

const BannerDebtMessage = defineMessages({
  title: {
    id: 'BannerDebtMessage.title',
    defaultMessage: 'You have a debt',
  },
  description: {
    id: 'BannerDebtMessage.description',
    defaultMessage: `To unlock your personal account, pay the debt €{amount}`,
  },
})

export const BannerDebt = () => {
  const int = useIntl()
  const { isSubscriptionExpired, debtAmount } = useUserSubscription()
  const { handleOpenModal } = useModal()

  const topUpBalanceHandler = () => {
    handleOpenModal({
      type: MODAL_TYPES.RECHARGE_BALANCE,
      props: {
        defaultAmount: debtAmount,
      },
    })
  }

  if (!isSubscriptionExpired) {
    return null
  }

  return (
    <div className={styles.Banner}>
      <div className={styles.Svg}>
        <CreditCards />
      </div>
      <div className={styles.BannerInfo}>
        <div className={styles.Title}>
          {int.formatMessage(BannerDebtMessage.title)}
        </div>
        <div className={styles.DescriptionAndIcon}>
          <div>
            {int.formatMessage(BannerDebtMessage.description, {
              amount: debtAmount,
            })}
          </div>
          <CaretRightIcon onClick={topUpBalanceHandler} />
        </div>
      </div>
    </div>
  )
}
