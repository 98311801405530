import React, { useState } from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { LoadingContent } from '../../../../layouts/loading-content/LoadingContent'
import { PageWrapper } from '../../../../layouts/page-wrapper/PageWrapper'
import { NoPurchasedDevicesWifi } from '../../../../layouts/status-layouts/no-purchased-devices/wifi/NoPurchasedDevicesWifi'
import { useWifi } from '../../../../sdk/hooks/use-wifi/useWifi'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { Input } from '../../../../shared/ui-kit-2/inputs/input/Input'
import { SearchInputMobile } from '../../../../shared/ui-kit-2/inputs/searchInputMobile/SearchInputMobile'
import { ReactComponent as SearchIcon } from './components/assets/icons/search.svg'
import { WifiCardList } from './components/wifi-card-list/WifiCardList'
import styles from './styles.module.scss'

const WifiListPageMessages = defineMessages({
  title: {
    id: 'WifiListPageMessages.title',
    defaultMessage: 'WI-FI',
  },
  searchPlaceholder: {
    id: 'WifiListPageMessages.searchPlaceholder',
    defaultMessage: 'Search by name or phone',
  },
  devicesCount: {
    id: 'WifiListPageMessages.devices',
    defaultMessage: '{count} devices',
  },
})

export const WifiListPage = () => {
  const intl = useIntl()
  const [inputValue, setInputValue] = useState<string>('')
  const isMobile = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK)

  const {
    filteredDevices,
    handleUpdateWifi,
    handleSwitchHotspot,
    totalCount,
    loading,
  } = useWifi({ inputValue: inputValue })

  const emptyDeviceList = totalCount === 0;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const content = (
    <>
      {emptyDeviceList ? (
        <NoPurchasedDevicesWifi />
      ) : (
        <WifiCardList
          devices={filteredDevices}
          handleUpdateWifi={handleUpdateWifi}
          handleSwitchHotspot={handleSwitchHotspot}
        />
      )}
    </>
  )

  return (
    <PageWrapper>
      <>
        <div className={styles.PageHeader}>
          <div className={styles.TitleAndCounter}>
            <div className={styles.Title}>
              {intl.formatMessage(WifiListPageMessages.title)}
            </div>
            <div
              className={clsx(
                styles.Counter,
                filteredDevices.length === 0 && styles.Counter_empty,
              )}
            >
              {intl.formatMessage(WifiListPageMessages.devicesCount, {
                count: filteredDevices.length,
              })}
            </div>
          </div>
          {!emptyDeviceList && (
            <div className={styles.SearchInputContainer}>
              {isMobile ? (
                <SearchInputMobile
                  value={inputValue}
                  defaultValue={inputValue}
                  placeholder={intl.formatMessage(
                    WifiListPageMessages.searchPlaceholder,
                  )}
                  onChange={handleInputChange}
                  theme="outlined"
                />
              ) : (
                <Input
                  validationNeeded={false}
                  placeholder={intl.formatMessage(
                    WifiListPageMessages.searchPlaceholder,
                  )}
                  onChange={handleInputChange}
                  postfix={<SearchIcon />}
                />
              )}
            </div>
          )}
        </div>

        <LoadingContent loading={loading} content={content} />
      </>
    </PageWrapper>
  )
}
