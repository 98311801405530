import React, { FC } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../../../../../app/hooks/useModal'
import { ReactComponent as CaretRightIcon } from '../../../../../../../../../../assets/icons/caret-right.svg'
import { ReactComponent as GroupIcon } from '../../../../../../../../../../assets/icons/group-icon.svg'
import { MODAL_TYPES } from '../../../../../../../../../../layouts/modals/ModalsProvider'
import { ContactGroupListResponse } from '../../../../../../../../../../sdk/datagates/types/contact-group/_crud/list'
import { Counter } from '../../../../../../../../../../shared/ui-kit-2/data-display/counter/Counter'
import styles from './styles.module.scss'

const ContactGroupCardMessages = defineMessages({
  contacts: {
    id: 'ContactGroupCardMessages.contacts',
    defaultMessage: 'Contacts',
  },
})

interface ContactGroupCardProps {
  contactGroup: ContactGroupListResponse['contactGroups'][0]
  handleFetch: () => Promise<void>
  handleFetchContacts: () => Promise<void>
  handleDeleteContactGroup: (contactGroupId: number) => Promise<void>
}

export const ContactGroupCard: FC<ContactGroupCardProps> = (props) => {
  const intl = useIntl()
  const {
    contactGroup,
    handleDeleteContactGroup,
    handleFetchContacts,
    handleFetch,
  } = props

  const { handleOpenModal } = useModal()
  const openContactGroupSettingsModal = (contactGroupId: number) => {
    handleOpenModal({
      type: MODAL_TYPES.CONTACT_GROUP_SETTINGS_MODAL,
      props: {
        contactGroupId,
        handleFetchContacts,
        handleFetchContactGroups: handleFetch,
        handleDeleteContactGroup: () =>
          handleDeleteContactGroup(contactGroupId),
      },
    })
  }

  return (
    <div className={styles.GroupCard}>
      <div className={styles.NameGroup}>{contactGroup.name}</div>
      <div className={styles.GroupInfo}>
        <GroupIcon />
        <div className={styles.GroupNumber}>
          {intl.formatMessage(ContactGroupCardMessages.contacts)}
          <Counter
            count={contactGroup.contactsCount}
            variant={'filled'}
            size={'small'}
          />
        </div>
        <button
          className={styles.Button}
          onClick={() =>
            openContactGroupSettingsModal(contactGroup.contactGroupId)
          }
        >
          <CaretRightIcon />
        </button>
      </div>
    </div>
  )
}
