import { ThirdPartyPage } from '../../../../../../../pages/auth/subpages/third-party/ThirdPartyPage'
import { AuthRoutePath } from '../../../../../constants/authRoutePath'
import { RoutePath } from '../../../../../constants/routePath'
import { AuthRoute } from '../../../../../enums/authRoute'
import { Route } from '../../../../../enums/route'
import { RouteDescriptor } from '../../../../../types/routeDescriptor'

export const thirdParty: RouteDescriptor<AuthRoute.ThirdParty> = {
  route: AuthRoute.ThirdParty,
  type: 'subpage',
  title: 'Telecorn',
  path: AuthRoutePath[AuthRoute.ThirdParty],
  render: ThirdPartyPage,
  accessLevel: 'guest',
  noAccessRedirectPath: RoutePath[Route.DeviceList],
}
