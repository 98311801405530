import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { BuyDeviceButton } from '../../custom-buttons/buy-device-button/BuyDeviceButton'
import styles from './styles.module.scss'

const NoDevicesForSmsesMessages = defineMessages({
  title: {
    id: 'NoDevicesForSmsesMessages.title',
    defaultMessage: 'Buy Teleсorn modem to receive and send SMS',
  },
})

export const NoDevicesForSmses = () => {
  const intl = useIntl()

  return (
    <div className={styles.Container}>
      <div className={styles.Title}>
        {intl.formatMessage(NoDevicesForSmsesMessages.title)}
      </div>
      <BuyDeviceButton additionalClassNames={[styles.Btn]} />
    </div>
  )
}
