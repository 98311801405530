import { ProfilePage } from '../../../../../../../pages/lk/subpages/profile/ProfilePage'
import { AuthRoutePath } from '../../../../../constants/authRoutePath'
import { RoutePath } from '../../../../../constants/routePath'
import { AuthRoute } from '../../../../../enums/authRoute'
import { Route } from '../../../../../enums/route'
import { RouteDescriptor } from '../../../../../types/routeDescriptor'

export const profile: RouteDescriptor<Route.Profile> = {
  route: Route.Profile,
  type: 'subpage',
  title: 'Telecorn',
  path: RoutePath[Route.Profile],
  render: ProfilePage,
  accessLevel: 'user',
  noAccessRedirectPath: AuthRoutePath[AuthRoute.SignIn],
}
