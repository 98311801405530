import React, { ComponentProps, FC } from 'react'
import 'flatpickr/dist/themes/light.css'
import { Field, FieldRenderProps } from 'react-final-form'
import {
  Datepicker,
  DatepickerProps,
} from '../../../../../ui-kit-2/inputs/datepicker/Datepicker'
import { getFieldError } from '../../../form-helpers/get-field-error/getFieldError'

export type DatepickerFieldProps = DatepickerProps &
  ComponentProps<typeof Field<string>>

export const DatepickerField: FC<DatepickerFieldProps> = (props) => {
  const {
    label,
    error,
    helperText,
    width,
    withoutSeconds = true,
    minDateToday = false,
    testId,
    ...otherProps
  } = props

  return (
    <Field {...otherProps}>
      {(fieldRenderProps) => {
        const { input, meta, ...otherFieldRenderProps } =
          fieldRenderProps as FieldRenderProps<string>

        return (
          <Datepicker
            label={label}
            helperText={helperText}
            width={width}
            withoutSeconds={withoutSeconds}
            minDateToday={minDateToday}
            testId={testId}
            error={getFieldError(meta)}
            {...input}
            {...otherFieldRenderProps}
          />
        )
      }}
    </Field>
  )
}
