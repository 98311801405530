import React from 'react'
import clsx from 'clsx'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../shared/lib/hooks/useLessThanDeviceScreen'
import styles from './styles.module.scss'

type CardProps = {
  title: string
  subtitle?: string
  orientation?: 'vertical' | 'horizontal'
  image?: string
  video?: string
  options?: {
    mediumTitle?: boolean
    imageBeforeText?: boolean
    blurredBackground?: boolean
    columnView?: boolean
    halvingMode?: boolean // 50% height image and text
  }
  classes?: {
    container?: string
    background?: string
    wrapper?: string
    title?: string
    subtitle?: string
  }
}

export const Card = ({
  title,
  subtitle,
  orientation = 'horizontal',
  image,
  video,
  options,
  classes,
}: CardProps) => {
  const isLessThanTablet = useLessThanDeviceScreen(ScreenDevices.LAPTOP)

  // TODO: need FULL REFACTORING

  return (
    <div
      className={clsx(
        styles.Card,
        (orientation === 'vertical' || isLessThanTablet) &&
          styles.Card_vertical,
        (orientation === 'vertical' || isLessThanTablet) &&
          options?.imageBeforeText &&
          styles.Card_vertical_withInsideImage,
        options?.columnView && styles.Card_columnView,
        options?.blurredBackground && styles.Card_blurred,
        options?.halvingMode && styles.Card_halving,
        classes?.container,
      )}
    >
      {!options?.imageBeforeText && (
        <div className={clsx(styles.Background, classes?.background)}>
          {image ? (
            <img src={image} alt="" />
          ) : video ? (
            <video
              autoPlay
              muted
              loop
              controls={false}
              className={styles.Video}
              playsInline
            >
              <source src={video} type="video/mp4" />
            </video>
          ) : (
            <></>
          )}
          {options?.blurredBackground && <div className={styles.Blur} />}
        </div>
      )}
      <div className={clsx(styles.Wrapper, classes?.wrapper)}>
        {options?.imageBeforeText && (
          <div className={styles.ImageBeforeText}>
            <img src={image} alt="" />
          </div>
        )}
        <div
          className={clsx(
            styles.Title,
            options?.mediumTitle && styles.Title_medium,
            classes?.title,
          )}
        >
          {title}
        </div>
        {subtitle && (
          <div className={clsx(styles.Subtitle, classes?.subtitle)}>
            {subtitle}
          </div>
        )}
      </div>
    </div>
  )
}
