
import {Article1} from "./components/article1/Article1";
import {Article1_1} from "./components/article1-1/Article1-1";
import {Article1_2} from "./components/article1-2/Article1-2";
import {Article1_3} from "./components/article1-3/Article1-3";
import {Article2} from "./components/article2/Article2";
import {Article2_1} from "./components/article2-1/Article2-1";
import {Article2_2} from "./components/article2-2/Article2-2";
import {Article2_3} from "./components/article2-3/Article2-3";
import {Article2_4} from "./components/article2-4/Article2-4";
import {Article2_5} from "./components/article2-5/Article2-5";
import {Article2_6} from "./components/article2-6/Article2-6";
import {Article3} from "./components/article3/Article3";
import {Article3_1} from "./components/article3-1/Article3-1";
import {Article3_2} from "./components/article3-2/Article3-2";
import {Article3_3} from "./components/article3-3/Article3-3";
import {Article3_4} from "./components/article3-4/Article3-4";
import {Article4} from "./components/article4/Article4";
import {Article4_1} from "./components/article4-1/Article4-1";
import {Article4_2} from "./components/article4-2/Article4-2";
import {Article4_3} from "./components/article4-3/Article4-3";
import {Article5} from "./components/article5/Article5";
import {Article5_1} from "./components/article5-1/Article5-1";
import {Article6} from "./components/article6/Article6";
import {Article6_1} from "./components/article6-1/Article6-1";
import {Article7} from "./components/article7/Article7";
import {Article7_1} from "./components/article7-1/Article7-1";
import {Article7_2} from "./components/article7-2/Article7-2";
import {Article7_3} from "./components/article7-3/Article7-3";
import {Article7_4} from "./components/article7-4/Article7-4";
import {Article7_5} from "./components/article7-5/Article7-5";
import {Article7_6} from "./components/article7-6/Article7-6";
import {Article8} from "./components/article8/Article8";
import {Article8_1} from "./components/article8-1/Article8-1";
import {FAQSectionAnchors} from "../../constants/anchors";
import styles from './styles.module.scss';

const ARTICLES = [
  Article1,
  Article1_1,
  Article1_2,
  Article1_3,
  Article2,
  Article2_1,
  Article2_2,
  Article2_3,
  Article2_4,
  Article2_5,
  Article2_6,
  Article3,
  Article3_1,
  Article3_2,
  Article3_3,
  Article3_4,
  Article4,
  Article4_1,
  Article4_2,
  Article4_3,
  Article5,
  Article5_1,
  Article6,
  Article6_1,
  Article7,
  Article7_1,
  Article7_2,
  Article7_3,
  Article7_4,
  Article7_5,
  Article7_6,
  Article8,
  Article8_1
] as Array<any>;

export const FAQArticles = () => {
  return (
    <div className={styles.FAQArticles}>
      {ARTICLES.map((Article, i) => (
        <div id={Object.entries(FAQSectionAnchors)[i][1]} key={`article/${i}`} className={styles.Article}>
          <Article />
        </div>
      ))}
    </div>
  )
}