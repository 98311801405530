import React from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { CommonButtonMessages } from '../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { dongleUpdateDatagate } from '../../../sdk/datagates/api/dongle'
import { useBackendErrorCodes } from '../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { InputField } from '../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import { ItemWrapper } from '../../../shared/ui-kit/data-display/ItemWrapper/ItemWrapper'
import { LC } from '../../../tests/e2e/locators'
import { SNACKBAR_TYPES } from '../../snackbar/SnackbarProvider'
import styles from './styles.module.scss'

const EditDeviceNameModalMessages = defineMessages({
  title: {
    id: 'EditDeviceNameModalMessages.Title',
    defaultMessage: 'Edit Name',
  },
  positiveUpdated: {
    id: 'EditDeviceNameModalMessages.positiveUpdated',
    defaultMessage: 'Device name is successfully updated',
  },
  negativeUpdated: {
    id: 'EditDeviceNameModalMessages.negativeUpdated',
    defaultMessage: 'An error occurred while updating device name',
  },
  deviceNameLabel: {
    id: 'EditDeviceNameModalMessages.deviceNameLabel',
    defaultMessage: 'Device name',
  },
  deviceNamePlaceholder: {
    id: 'EditDeviceNameModalMessages.deviceNamePlaceholder',
    defaultMessage: 'Enter device name',
  },
})

interface EditDeviceNameModalFormType {
  deviceName: string
}

export const EditDeviceNameModal = () => {
  const { handleOpenModal, handleHideModal, props } = useModal()

  const {
    deviceId,
    deviceName: defaultDeviceName,
    handleFetchDevice,
    handleFetchDevices,
  } = props

  const intl = useIntl()
  const { handleOpenSnackbar } = useSnackbar()
  const { resolveBackendError } = useBackendErrorCodes()

  const HandleClose = () => handleHideModal()

  const onSubmit = async (values: EditDeviceNameModalFormType) => {
    try {
      await dongleUpdateDatagate(
        {
          name: values.deviceName,
        },
        [{ name: 'dongle_id', value: deviceId }],
      )

      await handleFetchDevice?.()
      await handleFetchDevices?.()

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(EditDeviceNameModalMessages.positiveUpdated),
      })
      HandleClose()
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(EditDeviceNameModalMessages.negativeUpdated),
        ),
      })
    }
  }

  return (
    <Drawer
      isOpen={true}
      close={HandleClose}
      title={intl.formatMessage(EditDeviceNameModalMessages.title)}
      fullWidth
    >
      <Form
        onSubmit={onSubmit}
        initialValues={{
          deviceName: defaultDeviceName,
        }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className={styles.Form}>
            <ItemWrapper>
              <InputField
                name={'deviceName'}
                label={intl.formatMessage(
                  EditDeviceNameModalMessages.deviceNameLabel,
                )}
                placeholder={intl.formatMessage(
                  EditDeviceNameModalMessages.deviceNamePlaceholder,
                )}
                testId={LC.DEVICE_SETTINGS.NAME}
                required
              />
            </ItemWrapper>
            <div className={styles.Actions}>
              <Button
                text={intl.formatMessage(CommonButtonMessages.cancel)}
                variant={'blackTextBlackBorderOutlined'}
                additionalClassNames={[styles.actions_cancel]}
                onClick={HandleClose}
              />
              <Button
                type={'submit'}
                text={intl.formatMessage(CommonButtonMessages.save)}
                disabled={submitting}
                additionalClassNames={[styles.actions_save]}
                testId={LC.DEVICE_SETTINGS.SUBMIT}
              />
            </div>
          </form>
        )}
      />
    </Drawer>
  )
}
