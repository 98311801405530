import {
  OrderPayBackendResponse,
  OrderPayResponse,
} from '../../../../types/order/pay'

export const mapOrderPayBackendToOrderPay = (
  item: OrderPayBackendResponse,
): OrderPayResponse => {
  const { status, payment_credentials } = item.data

  return {
    status: status,
    paymentCredentials: payment_credentials
      ? {
          paymentProviderId: payment_credentials.service,
          ...payment_credentials,
        }
      : undefined,
  }
}
