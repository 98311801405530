import parsePhoneNumber from 'libphonenumber-js'

export const phonePostProcessing = (phone: string) => {
  const phoneNumber = parsePhoneNumber(phone)
  if (!phoneNumber) {
    console.log('Cannot parse phone number' + phone)
    return phone
  }

  return phoneNumber.format('INTERNATIONAL').replaceAll(' ', '')
}
