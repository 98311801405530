import {
  DongleAtBackendResponse,
  DongleAtResponse,
} from '../../../../types/dongle/at'

export const mapDongleAtBackendToDongleAt = (
  item: DongleAtBackendResponse,
): DongleAtResponse => {
  const { result } = item.data

  return {
    result: result,
  }
}
