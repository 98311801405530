import { QueryParameter } from '../../../sdk/datagates/helpers/_common/wrap-api-request'
import { FilterType } from '../hook/useFilters'

export const queryFromFilters = (filters: FilterType): QueryParameter[] => {
  const queryParams: QueryParameter[] = []

  Object.entries(filters).forEach(([name, value]) => {
    if (value !== undefined) queryParams.push({ name, value })
  })

  return queryParams
}
