import { useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { SNACKBAR_TYPES } from '../../../layouts/snackbar/SnackbarProvider'
import { formatDate } from '../../../shared/lib/utils/date-utils/formatDate'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import {
  mailingDeleteDatagate,
  mailingGetDatagate,
  mailingUpdateDatagate,
} from '../../datagates/api/mailing'
import { useBackendErrorCodes } from '../../datagates/helpers/_common/use-backend-error-codes'
import { MailingGetResponse } from '../../datagates/types/mailing/_crud/get'
import { MAILING_STATUSES } from '../use-mailing-statuses/constants/MailingStatuses'

interface UseMailingProps {
  mailingId: number
}

const UseMailingMessage = defineMessages({
  mailingIdError: {
    id: 'UseMailingMessage.mailingIdError',
    defaultMessage: 'An error occurred while trying to view mailing',
  },
  cancelSuccess: {
    id: 'UseMailingMessage.cancelSuccess',
    defaultMessage: 'Mailing is successfully canceled',
  },
  cancelError: {
    id: 'UseMailingMessage.cancelError',
    defaultMessage: 'An error occurred while cancelling mailing',
  },
  deleteSuccess: {
    id: 'UseMailingMessage.deleteSuccess',
    defaultMessage: 'Mailing is successfully deleted',
  },
  deleteError: {
    id: 'UseMailingMessage.deleteError',
    defaultMessage: 'An error occurred while deleting mailing',
  },
})

export const useMailing = (props: UseMailingProps) => {
  const { mailingId } = props

  const intl = useIntl()
  const { resolveBackendError } = useBackendErrorCodes()
  const { handleOpenSnackbar } = useSnackbar()
  const { handleHideModal } = useModal()

  const [loading, setLoading] = useState<boolean>(true)
  const [mailing, setMailing] = useState<MailingGetResponse>()

  useEffect(() => {
    void handleFetchMailing()
  }, [])

  const handleFetchMailing = async () => {
    try {
      if (!mailingId)
        throw new Error(intl.formatMessage(UseMailingMessage.mailingIdError))

      const { data } = await mailingGetDatagate(null, [
        {
          name: 'mailing_id',
          value: mailingId.toString(),
        },
      ])

      setMailing(data)
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    } finally {
      setLoading(false)
    }
  }

  const handleCancelMailing = async (
    mailing: MailingGetResponse,
    successCallback?: () => void,
  ) => {
    try {
      await mailingUpdateDatagate(
        {
          /*    mailing_type_id: mailing.mailingTypeId,*/
          mailing_status_id: MAILING_STATUSES.CANCELLED,
          /*text: mailing.text,
        dongle_id: mailing.dongle.dongleId,
        mailing_phones: mailing.mailingPhones?.map(phone => phone.phone),
        mailing_contact_ids: mailing.contacts?.map(c => c.contactId),
        mailing_contact_group_ids: mailing.contactGroups?.map(cg => cg.contactGroupId),
        scheduled_at: mailing.scheduledAt ? formatDate(mailing.scheduledAt) : undefined,*/
        },
        [
          {
            name: 'mailing_id',
            value: mailing.mailingId.toString(),
          },
        ],
      )

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(UseMailingMessage.cancelSuccess),
      })

      successCallback?.()

      handleHideModal()
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(UseMailingMessage.cancelError),
      })
    }
  }

  const handleDeleteMailing = async (
    mailingId: string,
    successCallback?: () => void,
  ) => {
    try {
      if (mailing?.mailingStatusId === MAILING_STATUSES.DRAFT) {
        await mailingDeleteDatagate(null, [
          {
            name: 'mailing_id',
            value: mailingId,
          },
        ])
      } else {
        await mailingUpdateDatagate({ is_deleted: true }, [
          {
            name: 'mailing_id',
            value: mailingId,
          },
        ])
      }

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(UseMailingMessage.deleteSuccess),
      })

      successCallback?.()

      handleHideModal()
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(UseMailingMessage.deleteError),
      })
    }
  }
  return {
    mailing,
    loading,
    handleCancelMailing,
    handleDeleteMailing,
  }
}
