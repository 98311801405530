import { AxiosResponse, HttpStatusCode } from 'axios'

type ResolveResponseDataReturnType<TData> = {
  data: TData
  hasError: boolean
  errorCode: string
}

export const resolveResponseData = <TResData>(
  response: AxiosResponse<any, any>,
): ResolveResponseDataReturnType<TResData> => {
  if (!response || !response.data) throw new Error('Resolve response error')

  if (
    response.status >= 500 ||
    (response.status >= 300 && response.status < 400)
  )
    throw new Error('Unexpected error.')

  if (
    [HttpStatusCode.BadRequest, HttpStatusCode.Forbidden].includes(
      response.status,
    )
  )
    throw new Error(response.data.error_message_detail.length > 0 ? response.data.error_message_detail : response.data.error_message)

  return {
    errorCode: '',
    hasError: false,
    data: response.data,
  }
}
