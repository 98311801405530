import {
  UserUpdatePasswordBackendResponse,
  UserUpdatePasswordResponse,
} from '../../../../types/user/update-password'

export const mapUserUpdatePasswordBackendToUserUpdatePassword = (
  item: UserUpdatePasswordBackendResponse,
): UserUpdatePasswordResponse => {
  const { status } = item.data

  return {
    status: status,
  }
}
