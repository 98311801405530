import { defineMessages } from 'react-intl'

export const CommonTabMessages = defineMessages({
  filterAll: {
    id: 'CommonTabMessages.filterAll',
    defaultMessage: 'All',
  },
  filterIncoming: {
    id: 'CommonTabMessages.filterIncoming',
    defaultMessage: 'Sent',
  },
  filterOutgoing: {
    id: 'CommonTabMessages.filterOutgoing',
    defaultMessage: 'Received',
  },
  filterDelayed: {
    id: 'CommonTabMessages.filterDelayed',
    defaultMessage: 'Planned',
  },
  filterEnded: {
    id: 'CommonTabMessages.filterEnded',
    defaultMessage: 'Ended',
  },
  filterPlanned: {
    id: 'CommonTabMessages.filterPlanned',
    defaultMessage: 'Planned',
  },
  filterDraft: {
    id: 'CommonTabMessages.filterDraft',
    defaultMessage: 'Draft',
  },
})
