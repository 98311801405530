import { defineMessages, useIntl } from 'react-intl'
import { CommonButtonMessages } from '../../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { useBoolActionSubmodal } from '../useBoolActionSubmodal'

interface UseDeleteSmsSubmodalProps {
  onDeleteSms: () => Promise<void>
}

const UseDeleteSmsSubmodalMessages = defineMessages({
  actionName: {
    id: 'useDeleteSmsSubmodalMessages.actionName',
    defaultMessage: 'delete',
  },
  actionItem: {
    id: 'useDeleteSmsSubmodalMessages.actionItem',
    defaultMessage: 'sms',
  },
  description: {
    id: 'useDeleteSmsSubmodalMessages.description',
    defaultMessage: 'This action cannot be undone',
  },
})

export const useDeleteSmsSubmodal = (props: UseDeleteSmsSubmodalProps) => {
  const { onDeleteSms } = props

  const intl = useIntl()

  const {
    setIsOpen: setIsDeleteSubmodalOpen,
    component: deleteSubmodalComponent,
  } = useBoolActionSubmodal({
    handleAction: onDeleteSms,
    actionName: intl.formatMessage(UseDeleteSmsSubmodalMessages.actionName),
    itemName: intl.formatMessage(UseDeleteSmsSubmodalMessages.actionItem),
    description: intl.formatMessage(UseDeleteSmsSubmodalMessages.description),
    danger: true,
    actionButtonText: intl.formatMessage(CommonButtonMessages.yesDelete),
    actionButtonIcon: 'trash',
  })

  return {
    setIsDeleteSubmodalOpen,
    deleteSubmodalComponent,
  }
}
