import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Card } from '../../../shared/ui-kit-2/data-display/card/Card'
import styles from '../privacy-policy/styles.module.scss'
import { LegalNoticeMessages } from './messages'

export const LegalNotice = () => {
  const intl = useIntl()

  useEffect(() => {
    document.documentElement.lang = 'es'

    return () => {
      document.documentElement.lang = 'en'
    }
  }, [])

  return (
    <div className={styles.Page}>
      <Card additionalClassNames={[styles.Container]}>
        <div className={styles.Title}>
          {intl.formatMessage(LegalNoticeMessages.title)}
        </div>

        <div className={styles.Content}>
          <div className={styles.Text}>
            <table>
              <tr>
                <td>Nombre de la empresa</td>
                <td>Bringo Telecom S.L.</td>
              </tr>
              <tr>
                <td>Número de empresa</td>
                <td>B72427412</td>
              </tr>
              <tr>
                <td>Empresa dirección</td>
                <td>
                  Domicilio Social y Fiscal en la calle Marina 16-18, Planta 27,
                  Barcelona CP 08005 España
                </td>
              </tr>
              <tr>
                <td>CIF (VAT)</td>
                <td>ESB72427412</td>
              </tr>
              <tr>
                <td>Teléfono</td>
                <td>+ 34 600 001 005</td>
              </tr>
              <tr>
                <td>Correo electrónico</td>
                <td>info@bringo.eu</td>
              </tr>
              <tr>
                <td>URL de la empresa</td>
                <td>bringo.eu</td>
              </tr>
              <tr>
                <td>Información de la empresa</td>
                <td>
                  La nuestra presta servicios de telecomunicaciones y desarrollo
                  de software. Uno de los proyectos clave de la empresa es una
                  plataforma para trabajar a distancia utilizando nuestro módem.
                  A saber, utilizando el software desarrollado (plataforma
                  cliente) se puede configurar el envío y recepción de llamadas
                  y sms de forma cómoda para usted, con la posibilidad de
                  utilizar funcionalidades adicionales en un formato
                  conveniente.
                </td>
              </tr>
            </table>
          </div>
        </div>
      </Card>
    </div>
  )
}
