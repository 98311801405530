import React, { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useSnackbar } from '../../../../app/hooks/useSnackbar'
import { SNACKBAR_TYPES } from '../../../../layouts/snackbar/SnackbarProvider'
import { getGoogleAuthUrlDatagate } from '../../../../sdk/datagates/api/oauth'
import { useBackendErrorCodes } from '../../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { getErrorMessage } from '../../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Button } from '../../../../shared/ui-kit-2/inputs/button/Button'
import styles from './styles.module.scss'

type SocialMediaType = 'facebook' | 'google'

const SocialMediaAuthMessages = defineMessages({
  title: {
    id: 'SocialMediaAuthMessages.title',
    defaultMessage: 'Or log in with',
  },
  google: {
    id: 'SocialMediaAuthMessages.google',
    defaultMessage: 'Google',
  },
  facebook: {
    id: 'SocialMediaAuthMessages.facebook',
    defaultMessage: 'Facebook',
  },
  defaultError: {
    id: 'SocialMediaAuthMessages.defaultError',
    defaultMessage: 'An error occurred while logging in.',
  },
})

export const SocialMediaAuth = () => {
  const intl = useIntl()
  const { resolveBackendError } = useBackendErrorCodes()
  const { handleOpenSnackbar } = useSnackbar()
  const [loading, setLoading] = useState<boolean>(false)

  const loginViaSocialMedia = async (socialMedia: SocialMediaType) => {
    try {
      setLoading(true)

      if (socialMedia === 'google') {
        const { data } = await getGoogleAuthUrlDatagate()
        window.open(data.authUrl, '_self')
      }
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(SocialMediaAuthMessages.defaultError),
        ),
      })
    } finally {
      setLoading(false)
    }
  }

  const loginViaGoogle = () => !loading && loginViaSocialMedia('google')
  const loginViaFacebook = () => !loading && loginViaSocialMedia('facebook')

  //TODO return facebook after accept
  return (
    <div className={styles.Container}>
      <div className={styles.Title}>
        {intl.formatMessage(SocialMediaAuthMessages.title)}
      </div>

      <Button
        text={intl.formatMessage(SocialMediaAuthMessages.google)}
        variant={'white'}
        icon={'google'}
        onClick={loginViaGoogle}
      />
    </div>
  )
}
