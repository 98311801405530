import React, { FC } from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../app/hooks/useModal'
import { ReactComponent as WarningIcon } from '../../../../../../assets/icons/danger-triangle.svg'
import { MODAL_TYPES } from '../../../../../../layouts/modals/ModalsProvider'
import { DongleListResponse } from '../../../../../../sdk/datagates/types/dongle/_crud/list'
import { formatPhoneNumber } from '../../../../../../sdk/formatters/format-phone-number'
import { UpdateWifiFormType } from '../../../../../../sdk/hooks/use-wifi/useWifi'
import { ReactComponent as Settings } from './assets/icons/settings.svg'
import { WifiButton } from './components/WifiButton/WifiButton'
import styles from './styles.module.scss'

const WifiCardMessages = defineMessages({
  untitledDevice: {
    id: 'WifiCardMessages.untitledDevice',
    defaultMessage: 'Untitled device',
  },
  untitledWifi: {
    id: 'WifiCardMessages.untitledWifi',
    defaultMessage: 'Untitled Wifi',
  },
  passwordNotSet: {
    id: 'WifiCardMessages.passwordNotSet',
    defaultMessage: 'Password not set',
  },
  wifiName: {
    id: 'WifiCardMessages.wifiName',
    defaultMessage: 'Wifi Name:',
  },
  password: {
    id: 'WifiCardMessages.password',
    defaultMessage: 'Password:',
  },
  warning: {
    id: 'WifiCardMessages.warning',
    defaultMessage: 'Please connect the modem to the network',
  },
})

interface WifiCardProps {
  device: DongleListResponse['dongles'][0]
  handleUpdateWifi: (values: UpdateWifiFormType) => Promise<void>
  handleSwitchHotspot: (dongleId: number, enabled: boolean) => Promise<void>
}

export const WifiCard: FC<WifiCardProps> = (props) => {
  const { device, handleUpdateWifi, handleSwitchHotspot } = props
  const intl = useIntl()
  const { handleOpenModal } = useModal()

  const onEdit = () => {
    handleOpenModal({
      type: MODAL_TYPES.WIFI_SETTINGS,
      props: {
        handleSubmit: handleUpdateWifi,
        deviceId: device.dongleId,
        deviceName: device.name,
        hotspotName: device.hotspotName,
        hotspotPassword: device.hotspotPassword,
      },
    })
  }

  return (
    <div className={styles.CardWrapper}>
      <div
        key={device.dongleId}
        className={clsx(
          styles.Card,
          !device.isHotspotEnable && styles.Card_off,
          !device.isOnline && styles.Card_isOffline,
        )}
      >
        <div className={styles.Header}>
          <div className={styles.TitleAndSubtitle}>
            <div className={styles.Title}>
              {device.name ??
                intl.formatMessage(WifiCardMessages.untitledDevice)}
            </div>
            <div className={styles.Subtitle}>
              {formatPhoneNumber(device.phoneNumber)}
            </div>
          </div>
          <div className={clsx(styles.SettingBtn)}>
            <Settings onClick={() => onEdit()} />
          </div>
        </div>

        <WifiButton
          isHotspotEnabled={device.isHotspotEnable}
          handleSwitchHotspot={handleSwitchHotspot}
          deviceId={device.dongleId}
        />

        <div className={styles.Footer}>
          <div className={styles.FooterItem}>
            <div className={styles.FooterItem__title}>
              {intl.formatMessage(WifiCardMessages.wifiName)}
            </div>
            <div className={styles.FooterItem__value}>
              {device.hotspotName ??
                intl.formatMessage(WifiCardMessages.untitledWifi)}
            </div>
          </div>
          <div className={styles.FooterItem}>
            <div className={styles.FooterItem__title}>
              {intl.formatMessage(WifiCardMessages.password)}
            </div>
            <div className={styles.FooterItem__value}>
              {device.hotspotPassword ??
                intl.formatMessage(WifiCardMessages.passwordNotSet)}
            </div>
          </div>
        </div>
      </div>
      {!device.isOnline && (
        <div className={styles.Offline}>
          <WarningIcon />
          <div>{intl.formatMessage(WifiCardMessages.warning)}</div>
        </div>
      )}
    </div>
  )
}
