import {
  UserApiKeyBackendResponse,
  UserApiKeyResponse,
} from '../../../../types/user/api-key'

export const mapUserApiKeyBackendToUserApiKey = (
  item: UserApiKeyBackendResponse,
): UserApiKeyResponse => {
  const { api_key } = item.data

  return {
    apiKey: api_key,
  }
}
