import { FC, Fragment } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import {ScreenDevices, useLessThanDeviceScreen} from "../../../lib/hooks/useLessThanDeviceScreen";

export type StepperStepType = {
  label: string
  value: string
  onClick?: () => void
  disabled?: boolean
}

type StepperType = {
  value: string
  steps: Array<StepperStepType>
}

export const Stepper: FC<StepperType> = (props) => {
  const { value, steps } = props

  const activeIdx = steps.reduce((acc, step, index) => {
    if (value === step.value) acc = index
    return acc
  }, 0)

  const isTablet = useLessThanDeviceScreen(ScreenDevices.TABLET)

  return (
    <div className={styles.Container}>
      {steps.map((step, i) => {
        const isFirstStep = i === 0
        const isLastStep = i === steps.length - 1
        const ltCurrentStep = i < activeIdx
        const lteCurrentStep = i <= activeIdx

        return (
          <Fragment key={step.value}>
            <div
              className={clsx(
                styles.Step,
                ltCurrentStep && styles.Step_active,
                step.disabled && styles.Step_disabled,
              )}
              onClick={
                ltCurrentStep && !step.disabled ? step.onClick : undefined
              }
            >
              <div
                className={clsx(
                  styles.StepLine,
                  isFirstStep && styles.StepLine_first,
                  isLastStep && styles.StepLine_last,
                  lteCurrentStep && styles.StepLine_active,
                )}
              />

              <div
                className={clsx(
                  styles.StepCircle,
                  lteCurrentStep && styles.StepCircle_active,
                )}
              />

              <div className={styles.StepLabel}>{step.label}</div>
            </div>
            <div
              className={clsx(
                styles.FirstHalfLine,
                isLastStep && styles.FirstHalfLine_last,
                lteCurrentStep && styles.FirstHalfLine_active,
              )}
            />
            {!isTablet && (
              <div
                className={clsx(
                  styles.SecondHalfLine,
                  isLastStep && styles.SecondHalfLine_last,
                  ltCurrentStep && styles.SecondHalfLine_active,
                )}
              />
            )}
          </Fragment>
        )
      })}
    </div>
  )
}
