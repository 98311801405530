import React from 'react'
import { PageWrapper } from '../../../../layouts/page-wrapper/PageWrapper'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { ContactsDesktopPage } from './components/contactsDesktopPage/ContactsDesktopPage'
import { ContactsMobilePage } from './components/contactsMobilePage/ContactsMobilePage'
import { ContactsPageContextProvider } from './contexts/ContactsPageContext'

export const ContactsPage = () => {
  const isLaptopL = useLessThanDeviceScreen(ScreenDevices.LAPTOP_L)

  return (
    <ContactsPageContextProvider>
      <PageWrapper>
        {isLaptopL ? <ContactsMobilePage /> : <ContactsDesktopPage />}
      </PageWrapper>
    </ContactsPageContextProvider>
  )
}
