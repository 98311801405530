import { UserStatuses } from '../../../../../../store/reducers/user/types'

export const USER_TYPE_IDS = {
  ORGANIZATION: 1,
  NATURAL_PERSON: 2,
}

export interface UserGetRequest {}

export interface UserGetBackendResponse {
  data: {
    user_id: number
    user_status_id: number
    balance: number
    email: string
    user_type_id?: number
    name?: string
    last_name?: string
    company_name?: string
    company_phone?: string
    phone?: string
    billing_email?: string
    vat_number?: string
    postal_code?: string
    street_address_1?: string
    street_address_2?: string
    country_id?: number
    city?: string
    region?: string
    user_payment_type_id?: number
    legal_street_address_1?: string
    legal_street_address_2?: string
    legal_country_id?: number
    legal_postal_code?: string
    legal_city?: string
    legal_region?: string
  }
}

export interface UserGetResponse {
  userId: number
  status: UserStatuses
  balance: number
  email: string
  userTypeId?: number
  firstName?: string
  lastName?: string
  companyName?: string
  companyPhone?: string
  userPhone?: string
  billingEmail?: string
  vatNumber?: string
  postalCode?: string
  streetAddress1?: string
  streetAddress2?: string
  countryId?: number | string
  city?: string
  region?: string
  userPaymentTypeId?: number
  legal?: {
    streetAddress1?: string
    streetAddress2?: string
    postalCode?: string
    countryId?: number | string
    city?: string
    region?: string
  }
}
