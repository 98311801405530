import React, { FC, useState } from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { ReactComponent as BankCardIcon } from '../../../assets/icons/bank-card.svg'
import { handlePaymentRedirection } from '../../../integrations/payment-redirection'
import {
  orderCreateDatagate,
  orderPayDatagate,
} from '../../../sdk/datagates/api/order'
import { useBackendErrorCodes } from '../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { ORDER_PAYMENT_TYPE_IDS } from '../../../sdk/datagates/types/order/_common'
import { PAYMENT_PROVIDERS_IDS } from '../../../sdk/datagates/types/payment/_common/constants'
import { usePaymentMethods } from '../../../sdk/hooks/use-payment-methods/usePaymentMethods'
import { RadioField } from '../../../shared/lib/form/form-field-adapters/v2/radio-field/RadioField'
import { RadioGroupField } from '../../../shared/lib/form/form-field-adapters/v2/radio-group-field/RadioGroupField'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import { RADIO_VARIANT_LIST } from '../../../shared/ui-kit-2/inputs/radio/components/Radio'
import { ItemWrapper } from '../../../shared/ui-kit/data-display/ItemWrapper/ItemWrapper'
import { LC } from '../../../tests/e2e/locators'
import { LoadingContent } from '../../loading-content/LoadingContent'
import { SNACKBAR_TYPES } from '../../snackbar/SnackbarProvider'
import styles from './styles.module.scss'
import {donglePayDatagate} from "../../../sdk/datagates/api/dongle";
import {PAYMENT_TYPE_IDS} from "../../../sdk/datagates/types/dongle/pay/_common";

type Props = {
  handleFetch?: () => void
}

interface PaymentBankCardChooseFormType {
  orderPaymentTypeId: string
  paymentMethodId: string
}

const PaymentBankCardChooseModalMessages = defineMessages({
  title: {
    id: 'PaymentBankCardChooseModalMessages.title',
    defaultMessage: 'Choose the card or add new',
  },
  newBankCard: {
    id: 'PaymentBankCardChooseModalMessages.newBankCard',
    defaultMessage: '+ New Card',
  },
  existingCardPaySuccess: {
    id: 'PaymentBankCardChooseModalMessages.existingCardPaySuccess',
    defaultMessage: 'Order is successfully paid',
  },
  error: {
    id: 'PaymentBankCardChooseModalMessages.error',
    defaultMessage: 'An error occurred while order payment',
  },
  submit: {
    id: 'PaymentBankCardChooseModalMessages.submit',
    defaultMessage: 'Pay',
  },
})

const PAYMENT_METHOD_DEFAULT_OPTION = ''

const isNewCard = (value?: string): boolean =>
  !(value === PAYMENT_METHOD_DEFAULT_OPTION)

export const PaymentBankCardChooseModal: FC<Props> = () => {
  const { handleHideModal, props } = useModal()

  // Set orderId if order is already created or createOrderCallback if order is not created
  const {
    orderId: defaultOrderId,
    createOrderCallback,
    onExistingCardPaymentSuccess,
    dongleId,
    isDonglePay,
    tariffPackageId,
  } = props

  const intl = useIntl()
  const { resolveBackendError } = useBackendErrorCodes()
  const { handleOpenSnackbar } = useSnackbar()
  const [loading, setLoading] = useState<boolean>(false)

  const {
    paymentMethods,
    loading: paymentMethodsLoading
  } = usePaymentMethods()

  const [paymentMethodId, setIsPaymentMethodId] = useState<string>(
    PAYMENT_METHOD_DEFAULT_OPTION,
  )

  const onSubmit = async (values: PaymentBankCardChooseFormType) => {
    try {
      setLoading(true)
      if(!isDonglePay) {
        let orderId: number | undefined = defaultOrderId

        if (createOrderCallback) {
          const { data: order } = await (
            createOrderCallback as typeof orderCreateDatagate
          )?.()
          orderId = order.orderId
        }

        if (!orderId) throw new Error('Order is not created')

        const paymentMethodId = isNewCard(values.paymentMethodId)
          ? Number(values.paymentMethodId)
          : null

        const paymentProviderId = isNewCard(values.paymentMethodId)
          ? PAYMENT_PROVIDERS_IDS.REDSYS
          : null

        const { data } = await orderPayDatagate(
          {
            order_id: orderId,
            order_payment_type_id: ORDER_PAYMENT_TYPE_IDS.BANK_CARD,
            payment_method_id: paymentMethodId,
            payment_provider_id: paymentProviderId,
          },
          [{ name: 'order_id', value: orderId.toString() }],
        )

        if (!paymentMethodId && data.paymentCredentials) {
          handlePaymentRedirection(data.paymentCredentials)
        } else if (paymentMethodId) {
          handleOpenSnackbar({
            type: SNACKBAR_TYPES.success,
            text: intl.formatMessage(
              PaymentBankCardChooseModalMessages.existingCardPaySuccess,
            ),
          })
          onExistingCardPaymentSuccess?.()
        } else {
            throw new Error('Payment error')
        }
      } else {
          const paymentMethodId = isNewCard(values.paymentMethodId)
            ? Number(values.paymentMethodId)
            : null

          const paymentProviderId = isNewCard(values.paymentMethodId)
            ? PAYMENT_PROVIDERS_IDS.REDSYS
            : null
          const urlParams = [{name: 'dongle_id', value: dongleId.toString()}]

          const {data} = await donglePayDatagate({
            invoice_type_id: PAYMENT_TYPE_IDS.DONGLE_CARD,
            tariff_package_id: tariffPackageId,
            payment_method_id: paymentMethodId,
            payment_provider_id: paymentProviderId
          }, urlParams);

        if (!paymentMethodId && data.paymentCredentials) {
          handlePaymentRedirection(data.paymentCredentials)
        } else if (paymentMethodId) {
          handleOpenSnackbar({
            type: SNACKBAR_TYPES.success,
            text: intl.formatMessage(
              PaymentBankCardChooseModalMessages.existingCardPaySuccess,
            ),
          })
          onExistingCardPaymentSuccess?.()
        } else {
          throw new Error('Payment error')
        }
      }
      } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(PaymentBankCardChooseModalMessages.error),
        ),
      })

      setLoading(false)
    }
  }

  const content = (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        orderPaymentTypeId: ORDER_PAYMENT_TYPE_IDS.BANK_CARD.toString(),
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={styles.Content}>
          <ItemWrapper>
            <div className={styles.BankCards}>
              <div className={styles.BankCardNewWrapper}>
                <RadioField
                  name={'paymentMethodId'}
                  label={intl.formatMessage(
                    PaymentBankCardChooseModalMessages.newBankCard,
                  )}
                  value={PAYMENT_METHOD_DEFAULT_OPTION}
                  comparedValue={paymentMethodId}
                  additionalClassNames={[styles.RadioButtonBankCardNew]}
                  variant={RADIO_VARIANT_LIST.outlined}
                  onClick={() => setIsPaymentMethodId('0')}
                />
                <div className={styles.BankCardIcon}>
                  <BankCardIcon />
                </div>
              </div>

              {paymentMethods.map((paymentMethod) => (
                <RadioGroupField
                  name={'paymentMethodId'}
                  additionalClassNames={[styles.RadioButtonBankCard]}
                  variant={RADIO_VARIANT_LIST.outlined}
                  group={[
                    {
                      label: paymentMethod.name,
                      value: paymentMethod.paymentMethodId.toString(),
                      comparedValue: paymentMethodId,
                      onClick: () =>
                        setIsPaymentMethodId(
                          paymentMethod.paymentMethodId.toString(),
                        ),
                    },
                  ]}
                />
              ))}
            </div>
          </ItemWrapper>

          <div className={styles.SubmitButton}>
            <Button
              type={'submit'}
              text={intl.formatMessage(
                PaymentBankCardChooseModalMessages.submit,
              )}
              additionalClassNames={[styles.content_actions_submit]}
              onClick={handleSubmit}
              disabled={loading}
              loading={loading}
              testId={LC.PAYMENT_BANK_CARD_CHOOSE_MODAL.SUBMIT}
            />
          </div>
        </form>
      )}
    />
  )

  return (
    <Drawer
      title={intl.formatMessage(PaymentBankCardChooseModalMessages.title)}
      isOpen={true}
      close={handleHideModal}
      testId={LC.PAYMENT_BANK_CARD_CHOOSE_MODAL._}
    >
      <LoadingContent loading={paymentMethodsLoading} content={content} />
    </Drawer>
  )
}
