import { wrapApiRequest } from '../../helpers/_common/wrap-api-request'
import { mapCallListBackendToCallList } from '../../mappers/call/_crud/list/map-call-list-backend-to-call-list'
import {
  CallListBackendResponse,
  CallListRequest,
  CallListResponse,
} from '../../types/call/_crud/list'

export const callListDatagate = wrapApiRequest<
  CallListRequest,
  CallListBackendResponse,
  CallListResponse
>('call', mapCallListBackendToCallList, 'GET')
