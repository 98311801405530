import { useEffect } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { SNACKBAR_TYPES } from '../../layouts/snackbar/SnackbarProvider'
import { userGetDatagate } from '../../sdk/datagates/api/user'
import { useAppDispatch } from '../../store'
import { clearCart } from '../../store/reducers/cart'
import { clearUser, getRefreshToken, setUser } from '../../store/reducers/user'
import { useSnackbar } from './useSnackbar'

const UseAppFirstRender = defineMessages({
  restoreAuthFail: {
    id: 'UseAppFirstRender.restoreAuthFail',
    defaultMessage:
      'Failed to restore the session. Please log in to your account again.',
  },
})

export const useAppFirstRender = () => {
  const dispatch = useAppDispatch()
  const intl = useIntl()
  const { handleOpenSnackbar } = useSnackbar()

  const clearAllUserData = () => {
    dispatch(clearUser())
    dispatch(clearCart())
  }

  useEffect(() => {
    ;(async () => {
      try {
        const refreshToken = getRefreshToken()
        if (refreshToken) {
          const { data } = await userGetDatagate()
          dispatch(setUser(data))
        } else {
          clearAllUserData()
        }
      } catch (e) {
        handleOpenSnackbar({
          type: SNACKBAR_TYPES.warning,
          text: intl.formatMessage(UseAppFirstRender.restoreAuthFail),
        })
        clearAllUserData()
      }
    })()

    // Fixes mobile height recognition
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight)
    appHeight()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
