import { useRef } from 'react'

export function useDebounce<F extends (...args: any[]) => any>(
  func: F,
  delay: number,
): (...args: Parameters<F>) => void {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  function debounced(...args: Parameters<F>) {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      func(...args)
    }, delay)
  }

  return debounced
}
