import { wrapApiRequest } from '../../helpers/_common/wrap-api-request'
import { mapSmsListBackendToSmsList } from '../../mappers/sms/_crud/list/map-sms-list-backend-to-sms-list'
import {
  SmsListBackendResponse,
  SmsListRequest,
  SmsListResponse,
} from '../../types/sms/_crud/list'

export const smsListDatagate = wrapApiRequest<
  SmsListRequest,
  SmsListBackendResponse,
  SmsListResponse
>('sms', mapSmsListBackendToSmsList, 'GET')
