import React, { FC } from 'react'
import { DongleListResponse } from '../../../../../../sdk/datagates/types/dongle/_crud/list'
import { UpdateWifiFormType } from '../../../../../../sdk/hooks/use-wifi/useWifi'
import { LC } from '../../../../../../tests/e2e/locators'
import { WifiCard } from '../wifi-card/WifiCard'
import styles from './styles.module.scss'

interface WifiCardListProps {
  devices: DongleListResponse['dongles']
  handleUpdateWifi: (values: UpdateWifiFormType) => Promise<void>
  handleSwitchHotspot: (dongleId: number, enabled: boolean) => Promise<void>
}

export const WifiCardList: FC<WifiCardListProps> = (props) => {
  const { devices, handleUpdateWifi, handleSwitchHotspot } = props

  return (
    <div className={styles.Cards} data-test-id={LC.WIFI.CARDS}>
      {devices.map((device) => (
        <WifiCard
          key={device.dongleId}
          device={device}
          handleUpdateWifi={handleUpdateWifi}
          handleSwitchHotspot={handleSwitchHotspot}
        />
      ))}
    </div>
  )
}
