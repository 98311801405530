import {FC} from "react";
import {useSelector} from "react-redux";
import {Dialog_v2} from "../../shared/ui-kit-2/data-display/dialog-v2/Dialog_v2";

export const DialogProvider: FC = () => {
  // @ts-ignore
  const { isOpen, props } = useSelector(store => store.dialog);

  return isOpen
    ? <Dialog_v2 isOpen={isOpen} props={props} />
    : <></>
}
