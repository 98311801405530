import {SipUserGetBackendResponse, SipUserGetResponse} from "../../../../../types/sip-user/_crud/get";

export const mapSipUserGetBackendToSipUserGet = (item: SipUserGetBackendResponse): SipUserGetResponse => {
  const {
    pbx_sip_url,
    pbx_sip_user
  } = item.data;

  return {
    sipLogin: pbx_sip_user?.sip_login,
    sipPassword: pbx_sip_user?.sip_password,
    pbxSipUrl: pbx_sip_url
  }
}