import React, {useState} from 'react';
import clsx from "clsx";
import {DeviceDetailsModalMessages} from "../../messages/DeviceDetailsModalMessages";
import {Switcher} from "../../../../../shared/ui-kit-2/inputs/switcher/Switcher";
import {useIntl} from "react-intl";
import {DongleGetResponse} from "../../../../../sdk/datagates/types/dongle/_crud/get";
import { ReactComponent as SettingsIcon } from '../../../../../assets/icons/settings-v2.svg'
import { ReactComponent as WarningIcon } from '../../../../../assets/icons/danger-triangle.svg'
import {MODAL_TYPES} from "../../../ModalsProvider";
import {UpdateWifiFormType, useWifi} from "../../../../../sdk/hooks/use-wifi/useWifi";
import {useModal} from "../../../../../app/hooks/useModal";
import {SNACKBAR_TYPES} from "../../../../snackbar/SnackbarProvider";
import {getErrorMessage} from "../../../../../shared/lib/utils/get-error-message/getErrorMessage";
import {useSnackbar} from "../../../../../app/hooks/useSnackbar";
import {useBackendErrorCodes} from "../../../../../sdk/datagates/helpers/_common/use-backend-error-codes";
import styles from './styles.module.scss';

interface WifiDetailsProps {
  device: DongleGetResponse
  handleFetchDevices: () => Promise<void>
  handleSwitchHotspot: (dongleId: number, enabled: boolean) => Promise<void>
  isTariffPackageEnabled: boolean
}

export const WifiDetails = (propsWifi: WifiDetailsProps) => {
  const {
    device,
    handleFetchDevices,
    handleSwitchHotspot,
    isTariffPackageEnabled
  } = propsWifi;

  const intl = useIntl();
  const { handleOpenSnackbar } = useSnackbar()
  const { resolveBackendError } = useBackendErrorCodes()
  const { handleOpenModal } = useModal()
  const { handleUpdateWifi } = useWifi({ autoFetchNeeded: false })
  const [wifiEnabled, setWifiEnabled] = useState<boolean>(
    device?.isHotspotEnable ?? false,
  )
  const [switchWifiLoading, setSwitchWifiLoading] = useState<boolean>(false)

  const handleOpenWIFISettings = () => {
    if (device)
      handleOpenModal({
        type: MODAL_TYPES.WIFI_SETTINGS,
        props: {
          handleSubmit: (values: UpdateWifiFormType) =>
            handleUpdateWifi(values, () => {
              void handleFetchDevices()
            }),
          deviceId: device.dongleId,
          deviceName: device.name,
          hotspotName: device.hotspotName,
          hotspotPassword: device.hotspotPassword,
        },
      })
  }

  const handleSwitchWifi = async () => {
    setSwitchWifiLoading(true)
    if (device)
      try {
        await handleSwitchHotspot(device.dongleId, !wifiEnabled)
        setWifiEnabled((prev) => !prev)
      } catch (e) {
        handleOpenSnackbar({
          type: SNACKBAR_TYPES.error,
          text: resolveBackendError(getErrorMessage(e)),
        })
      } finally {
        setSwitchWifiLoading(false)
      }
  }
  return (
    <div className={clsx(styles.WifiWrapper, isTariffPackageEnabled && styles.ErrorSubscription)}>
      <div
        className={clsx(styles.Card, !device.isOnline && styles.Offline)}
      >
        <div className={styles.Header}>
          <div className={styles.Title}>
            {intl.formatMessage(DeviceDetailsModalMessages.wifi)}
          </div>
          <button
            className={styles.WifiSettings}
            onClick={handleOpenWIFISettings}
            disabled={isTariffPackageEnabled}
          >
            <SettingsIcon />
          </button>
          <div className={styles.WifiSwitcher}>
            <Switcher
              checked={wifiEnabled}
              onChange={handleSwitchWifi}
              disabled={switchWifiLoading || isTariffPackageEnabled}
            />
          </div>
        </div>
        <div className={styles.Divider} />
          <div className={styles.Cols}>
            <div className={styles.Col}>
              <div className={styles.Label}>
                {intl.formatMessage(DeviceDetailsModalMessages.wifiName)}
              </div>
              <div className={styles.Value}>{device.hotspotName}</div>
            </div>
            <div className={clsx(styles.Col, styles.Col_mrXL)}>
              <div className={styles.Label}>
                {intl.formatMessage(
                  DeviceDetailsModalMessages.wifiPassword,
                )}
              </div>
              <div className={styles.Value}>{device.hotspotPassword}</div>
            </div>
          </div>
      </div>
      {!device.isOnline && (
        <div className={styles.OfflineWifi}>
          <WarningIcon />
          <div>
            {intl.formatMessage(DeviceDetailsModalMessages.warning)}
          </div>
        </div>
      )}
    </div>
  );
};
