import React, { FC } from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { useSiteVersion } from '../../../../../../../../app/hooks/useSiteVersion'
import { LC } from '../../../../../../../../tests/e2e/locators'
import styles from './styles.module.scss'

const WifiButtonMessages = defineMessages({
  stateOn: {
    id: 'WifiButtonProps.stateOn',
    defaultMessage: 'On',
  },
  stateOff: {
    id: 'WifiButtonProps.stateOff',
    defaultMessage: 'Off',
  },
})

interface WifiButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isHotspotEnabled: boolean
  handleSwitchHotspot: (dongleId: number, enabled: boolean) => Promise<void>
  deviceId: number
}

export const WifiButton: FC<WifiButtonProps> = (props) => {
  const { isHotspotEnabled, handleSwitchHotspot, deviceId } = props
  const intl = useIntl()
  const { siteVersion } = useSiteVersion()

  return (
    <>
      {isHotspotEnabled ? (
        <div
          className={styles.On}
          data-test-id={LC.WIFI.CARDS.STATE_SWITCHER(deviceId)}
          onClick={() => handleSwitchHotspot(deviceId, false)}
        >
          <div className={styles.Circle}></div>
          <div className={styles.Circle__first}></div>
          <div className={styles.Circle__second}></div>
          <div className={styles.Circle__third}></div>
          <div
            className={clsx(
              styles.Circle__text,
              siteVersion === 'ru' && styles.Circle__text_small,
            )}
          >
            {intl.formatMessage(WifiButtonMessages.stateOn)}
          </div>
        </div>
      ) : (
        <div
          className={styles.Off}
          data-test-id={LC.WIFI.CARDS.STATE_SWITCHER(deviceId)}
          onClick={() => handleSwitchHotspot(deviceId, true)}
        >
          <div className={styles.Off_button}>
            {intl.formatMessage(WifiButtonMessages.stateOff)}
          </div>
        </div>
      )}
    </>
  )
}
