import React, { FC } from 'react'
import styles from './styles.module.scss'

interface PurchaseDetailProps {
  title: string
  value: string
}

export const PurchaseDetail: FC<PurchaseDetailProps> = (props) => {
  const { title, value } = props

  return (
    <div className={styles.Detail}>
      <div className={styles.Title}>{title}</div>
      <div className={styles.Value}>{value}</div>
    </div>
  )
}
