import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { Source } from '../_hooks/use-add-source/useAddSource'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { CommonButtonMessages } from '../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import {
  contactGroupCreateDatagate,
  contactGroupGetDatagate,
  contactGroupUpdateDatagate,
} from '../../../sdk/datagates/api/contact-group'
import { useBackendErrorCodes } from '../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { InputField } from '../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import { useFormRules } from '../../../shared/lib/form/form-rules'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import { LoadingContent } from '../../loading-content/LoadingContent'
import { SNACKBAR_TYPES } from '../../snackbar/SnackbarProvider'
import styles from '../ContactSettingsModal/styles.module.scss'
import { MODAL_TYPES } from '../ModalsProvider'
import { AddContactsToGroup } from './components/AddContactsToGroup/AddContactsToGroup'

interface ContactGroupSettingsFormType {
  name: string
}

const ContactGroupSettingsModalMessages = defineMessages({
  contactNotFound: {
    id: 'ContactGroupSettingsModalMessages.contactNotFound',
    defaultMessage: 'Contact group not found',
  },
  title: {
    id: 'ContactGroupSettingsModalMessages.title',
    defaultMessage: 'Add contact group',
  },
  titleEdit: {
    id: 'ContactGroupSettingsModalMessages.titleEdit',
    defaultMessage: 'Edit contact group',
  },
  groupNameLabel: {
    id: 'ContactGroupSettingsModalMessages.groupNameLabel',
    defaultMessage: 'Group name',
  },
  groupNamePlaceholder: {
    id: 'ContactGroupSettingsModalMessages.groupNamePlaceholder',
    defaultMessage: 'Enter group name',
  },
  negativeCreated: {
    id: 'ContactGroupSettingsModalMessages.negativeCreated',
    defaultMessage: 'An error occurred while creating contact group',
  },
  negativeUpdated: {
    id: 'ContactGroupSettingsModalMessages.negativeUpdated',
    defaultMessage: 'An error occurred while updating contact group',
  },
  positiveCreated: {
    id: 'ContactGroupSettingsModalMessages.positiveCreated',
    defaultMessage: 'Contact group is successfully created',
  },
  positiveUpdated: {
    id: 'ContactGroupSettingsModalMessages.positiveUpdated',
    defaultMessage: 'Contact group is successfully updated',
  },
  deleteTitle: {
    id: 'ContactGroupSettingsModalMessages.deleteTitle',
    defaultMessage: 'Are you sure you want to delete the group?',
  },
  fetchContactError: {
    id: 'ContactGroupSettingsModalMessages.fetchContactError',
    defaultMessage: 'An error occurred while fetching group',
  },
})

export const ContactGroupSettingsModal = () => {
  const { handleHideModal, handleOpenModal, props } = useModal()

  const {
    handleFetchContacts,
    handleFetchContactGroups,
    handleDeleteContactGroup,
  } = props
  const contactGroupId: number | undefined = props.contactGroupId

  const intl = useIntl()
  const { handleOpenSnackbar } = useSnackbar()
  const { ruleRequired } = useFormRules()
  const { resolveBackendError } = useBackendErrorCodes()
  const [initialValues, setInitialValues] = useState<
    Partial<ContactGroupSettingsFormType>
  >({})
  const [contactSources, setContactSources] = useState<Source[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const fetchInitialValues = async (contactGroupId: number) => {
    try {
      const { data } = await contactGroupGetDatagate(null, [
        { name: 'contact_group_id', value: contactGroupId.toString() },
      ])

      setInitialValues({
        name: data.name,
      })

      const defaultSources = data.contacts.map<Source>((c) => ({
        sourceType: 'contacts',
        sourceName: c.name,
        sourceValue: c.contactId.toString(),
        metaInformation: {
          name: c.name,
          phone: c.phone,
        },
      }))
      setContactSources(defaultSources)

      setLoading(false)
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(
          ContactGroupSettingsModalMessages.fetchContactError,
        ),
      })
    }
  }

  useEffect(() => {
    if (contactGroupId) {
      void fetchInitialValues(contactGroupId)
    } else {
      setLoading(false)
    }
  }, [])

  const onSubmit = async (values: ContactGroupSettingsFormType) => {
    try {
      const reqBody = {
        name: values.name,
        contact_ids: contactSources.map((c) => +c.sourceValue),
      }

      if (contactGroupId) {
        const urlParams = [
          {
            name: 'contact_group_id',
            value: contactGroupId.toString(),
          },
        ]
        await contactGroupUpdateDatagate(reqBody, urlParams)
      } else {
        await contactGroupCreateDatagate(reqBody)
      }

      await handleFetchContacts?.()
      await handleFetchContactGroups?.()

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(
          contactGroupId
            ? ContactGroupSettingsModalMessages.positiveUpdated
            : ContactGroupSettingsModalMessages.positiveCreated,
        ),
      })

      handleHideModal()
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(
            contactGroupId
              ? ContactGroupSettingsModalMessages.negativeUpdated
              : ContactGroupSettingsModalMessages.negativeCreated,
          ),
        ),
      })
    }
  }

  const onDelete = () => {
    if (contactGroupId) {
      handleOpenModal({
        type: MODAL_TYPES.DELETE_ITEM,
        props: {
          title: intl.formatMessage(
            ContactGroupSettingsModalMessages.deleteTitle,
          ),
          handleSubmit: async () => {
            await handleDeleteContactGroup?.()
            await handleFetchContactGroups?.()
            await handleFetchContacts?.()
          },
        },
      })
    } else {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(
          ContactGroupSettingsModalMessages.contactNotFound,
        ),
      })
    }
  }

  const submitText = contactGroupId
    ? intl.formatMessage(CommonButtonMessages.save)
    : intl.formatMessage(CommonButtonMessages.create)

  const title = contactGroupId
    ? intl.formatMessage(ContactGroupSettingsModalMessages.titleEdit)
    : intl.formatMessage(ContactGroupSettingsModalMessages.title)

  const content = (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit} className={styles.Content}>
            <InputField
              name={'name'}
              validate={ruleRequired()}
              markAsRequired={true}
              label={intl.formatMessage(
                ContactGroupSettingsModalMessages.groupNameLabel,
              )}
              placeholder={intl.formatMessage(
                ContactGroupSettingsModalMessages.groupNamePlaceholder,
              )}
            />

            <AddContactsToGroup
              contactSources={contactSources}
              setContactSources={setContactSources}
              selectedContact={[]}
              contactGroupId={contactGroupId}
            />

            <div className={styles.Actions}>
              {contactGroupId && (
                <Button
                  additionalClassNames={[styles.Delete]}
                  text={intl.formatMessage(CommonButtonMessages.delete)}
                  variant={'danger'}
                  icon={'trash'}
                  onClick={onDelete}
                />
              )}
              <Button
                type={'submit'}
                text={submitText}
                onClick={handleSubmit}
                disabled={submitting}
                loading={submitting}
              />
            </div>
          </form>
        )
      }}
    />
  )

  return (
    <Drawer
      isOpen={true}
      title={title}
      close={handleHideModal}
      additionalClassNames={[styles.Drawer]}
    >
      <LoadingContent loading={loading} content={content} />
    </Drawer>
  )
}
