import React, { Dispatch, FC, SetStateAction } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { CommonTableMessages } from '../../../config/intl/common-messages/common-table-messages/CommonTableMessages'
import { MailingListResponse } from '../../../sdk/datagates/types/mailing/_crud/list'
import {
  Table,
  TableColType,
} from '../../../shared/ui-kit-2/data-display/table/Table'
import { MailingTableRows } from './components/sms-mailing-table-rows/SmsMailingTableRows'

const MailingTableMessage = defineMessages({
  name: {
    id: 'MailingTable.name',
    defaultMessage: 'Campaign Name',
  },
})

interface MailingTableProps {
  mailings: MailingListResponse['mailings']
  totalCount: number
  page: number
  setPage: Dispatch<SetStateAction<number>>
  setLimit: Dispatch<SetStateAction<number>>
  pageLimit: number
  loading: boolean
  handleFetch: () => Promise<void>
}

export const MailingTable: FC<MailingTableProps> = (props) => {
  const {
    mailings,
    page,
    setPage,
    setLimit,
    pageLimit,
    totalCount,
    loading,
    handleFetch,
  } = props

  const intl = useIntl()

  const handlePageChange = (page: number, limit: number) => {
    setPage(page)
    setLimit(limit)
  }

  const cols: TableColType[] = [
    { title: intl.formatMessage(MailingTableMessage.name) },
    { title: intl.formatMessage(CommonTableMessages.columnDevice) },
    { title: null, position: 'center' },
    { title: intl.formatMessage(CommonTableMessages.columnAmountOfNumbers) },
    { title: intl.formatMessage(CommonTableMessages.columnText) },
    { title: intl.formatMessage(CommonTableMessages.columnSendTime) },
    { title: intl.formatMessage(CommonTableMessages.columnStatus) },
    {
      title: intl.formatMessage(CommonTableMessages.columnActions),
      position: 'right',
    },
  ]

  const rowGroups = MailingTableRows(mailings, handleFetch)

  return (
    <Table
      cols={cols}
      rowGroups={rowGroups}
      currentPage={page}
      totalCount={totalCount}
      onPageChange={handlePageChange}
      itemsPerPage={pageLimit}
      loading={loading}
      columnWidths={[1, 1, 0.5, 1, 2, 1, 1, 0.5]}
    />
  )
}
