import React, { FC, useContext } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../../../../app/hooks/useModal'
import { MODAL_TYPES } from '../../../../../../../../../layouts/modals/ModalsProvider'
import { TARIFF_PACKAGE_ID } from '../../../../../../../../../sdk/datagates/types/order/_common'
import { OrderListResponse } from '../../../../../../../../../sdk/datagates/types/order/_crud/list'
import { formatPrice } from '../../../../../../../../../sdk/formatters/format-price'
import { useOrderStatus } from '../../../../../../../../../sdk/hooks/use-order-status/useOrderStatusTranslation'
import { formatDate } from '../../../../../../../../../shared/lib/utils/date-utils/formatDate'
import { Card } from '../../../../../../../../../shared/ui-kit-2/data-display/card/Card'
import { OrdersPageContext } from '../../../../../contexts/OrdersPageContext'
import styles from './styles.module.scss'
import {OrderModalMessages} from "../../../../../../../../../layouts/modals/OrderModal/OrderModal";

interface OrderCardProps {
  order: OrderListResponse['orders'][0]
  getTariffPackagePrice: (
    tariffPackageId: number,
    withDevice?: boolean,
    count?: number,
  ) => number | undefined
  getTariffPackageTranslation: (tariffPackageId: number) => string
}

const OrderCardMessages = defineMessages({
  title: {
    id: 'OrderCardMessages.title',
    defaultMessage: `Order #{orderId}`,
  },
  modem: {
    id: 'OrderCardMessages.modem',
    defaultMessage: `modem`,
  },
  deliveryPrice: {
    id: 'OrderCardMessages.deliveryPrice',
    defaultMessage: `Delivery Price`,
  },
  totalPrice: {
    id: 'OrderCardMessages.totalPrice',
    defaultMessage: 'Total',
  },
})

export const OrderCard: FC<OrderCardProps> = (props) => {
  const { order, getTariffPackagePrice, getTariffPackageTranslation } = props

  const intl = useIntl()
  const { handleOpenModal } = useModal()
  const { fetchOrdersFirstPage } = useContext(OrdersPageContext)
  const { getOrderStatusStickerByStatusId } = useOrderStatus()
  const { statusComponent } = getOrderStatusStickerByStatusId(
    order.orderStatusId,
  )

  const monthPlanCount =
    order?.orderDongles.filter(
      (od) => od.tariffPackageId === TARIFF_PACKAGE_ID.MONTH,
    )?.length ?? 0
  const annualPlanCount =
    order?.orderDongles.filter(
      (od) => od.tariffPackageId === TARIFF_PACKAGE_ID.ANNUAL,
    )?.length ?? 0

  const handleOpenOrderModal = () =>
    handleOpenModal({
      type: MODAL_TYPES.ORDER,
      props: {
        orderId: order.orderId,
        afterSuccessCancelCallback: fetchOrdersFirstPage,
      },
    })

  return (
    <Card
      additionalClassNames={[styles.CardAdditional]}
      onClick={handleOpenOrderModal}
    >
      <div className={styles.Header}>
        <div className={styles.Header__left}>
          <div className={styles.Title}>
            {intl.formatMessage(OrderCardMessages.title, {
              orderId: order.orderId,
            })}
          </div>
          <div className={styles.Description}>
            {formatDate(order.createdAt, 0, 'datetime')}
          </div>
        </div>

        {statusComponent}
      </div>

      <div className={styles.Content}>
        <div className={styles.KeyValueItem}>
          <div className={styles.KeyValueItem__title}>
            {getTariffPackageTranslation(TARIFF_PACKAGE_ID.ANNUAL)}
          </div>
          <div className={styles.KeyValueItem__value}>
            {annualPlanCount}x{' '}
            {formatPrice(getTariffPackagePrice(TARIFF_PACKAGE_ID.ANNUAL))}
          </div>
        </div>
        <div className={styles.Divider} />
        <div className={styles.KeyValueItem}>
          <div className={styles.KeyValueItem__title}>
            {getTariffPackageTranslation(TARIFF_PACKAGE_ID.MONTH)}
          </div>
          <div className={styles.KeyValueItem__value}>
            {monthPlanCount}x{' '}
            {formatPrice(getTariffPackagePrice(TARIFF_PACKAGE_ID.MONTH, true))}
          </div>
        </div>
        <div className={styles.Divider} />
        <div className={styles.KeyValueItem}>
          <div className={styles.KeyValueItem__title}>
            {intl.formatMessage(OrderCardMessages.deliveryPrice)}
          </div>
          <div className={styles.KeyValueItem__value}>
            {order.deliveryPrice === 0
              ? intl.formatMessage(OrderModalMessages.free)
              : formatPrice(order.deliveryPrice)}
          </div>
        </div>

        <div className={styles.KeyValueItem}>
          <div className={styles.KeyValueItem__title_dark}>
            {intl.formatMessage(OrderCardMessages.totalPrice)}
          </div>
          <div className={styles.KeyValueItem__value}>
            {formatPrice(order.totalPrice)}
          </div>
        </div>
      </div>
    </Card>
  )
}
