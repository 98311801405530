import { wrapApiRequest } from '../../helpers/_common/wrap-api-request'
import { mapContactCreateBackendToContactCreate } from '../../mappers/contact/_crud/create/map-contact-create-backend-to-contact-create'
import { mapContactDeleteBackendToContactDelete } from '../../mappers/contact/_crud/delete/map-contact-delete-backend-to-contact-delete'
import { mapContactGetBackendToContactGet } from '../../mappers/contact/_crud/get/map-contact-get-backend-to-contact-get'
import { mapContactListBackendToContactList } from '../../mappers/contact/_crud/list/map-contact-list-backend-to-contact-list'
import { mapContactUpdateBackendToContactUpdate } from '../../mappers/contact/_crud/update/map-contact-update-backend-to-contact-update'
import {
  ContactCreateBackendResponse,
  ContactCreateRequest,
  ContactCreateResponse,
} from '../../types/contact/_crud/create'
import {
  ContactDeleteBackendResponse,
  ContactDeleteRequest,
  ContactDeleteResponse,
} from '../../types/contact/_crud/delete'
import {
  ContactGetBackendResponse,
  ContactGetRequest,
  ContactGetResponse,
} from '../../types/contact/_crud/get'
import {
  ContactListBackendResponse,
  ContactListRequest,
  ContactListResponse,
} from '../../types/contact/_crud/list'
import {
  ContactUpdateBackendResponse,
  ContactUpdateRequest,
  ContactUpdateResponse,
} from '../../types/contact/_crud/update'

export const contactListDatagate = wrapApiRequest<
  ContactListRequest,
  ContactListBackendResponse,
  ContactListResponse
>('contact', mapContactListBackendToContactList, 'GET')

export const contactGetDatagate = wrapApiRequest<
  ContactGetRequest,
  ContactGetBackendResponse,
  ContactGetResponse
>('contact/{contact_id}', mapContactGetBackendToContactGet, 'GET')

export const contactCreateDatagate = wrapApiRequest<
  ContactCreateRequest,
  ContactCreateBackendResponse,
  ContactCreateResponse
>('contact', mapContactCreateBackendToContactCreate, 'POST')

export const contactUpdateDatagate = wrapApiRequest<
  ContactUpdateRequest,
  ContactUpdateBackendResponse,
  ContactUpdateResponse
>('contact/{contact_id}', mapContactUpdateBackendToContactUpdate, 'PATCH')

export const contactDeleteDatagate = wrapApiRequest<
  ContactDeleteRequest,
  ContactDeleteBackendResponse,
  ContactDeleteResponse
>('contact/{contact_id}', mapContactDeleteBackendToContactDelete, 'DELETE')
