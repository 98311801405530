import { wrapApiRequest } from '../../helpers/_common/wrap-api-request'
import { mapCountryListBackendToCountryList } from '../../mappers/country/_crud/list/map-country-list-backend-to-country-list'
import {
  CountryListBackendResponse,
  CountryListRequest,
  CountryListResponse,
} from '../../types/country/_crud/list'

export const countryListDatagate = wrapApiRequest<
  CountryListRequest,
  CountryListBackendResponse,
  CountryListResponse
>('country', mapCountryListBackendToCountryList, 'GET')
