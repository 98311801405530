import { wrapApiRequest } from '../../helpers/_common/wrap-api-request'
import { mapOrderListBackendToOrderList } from '../../mappers/order/_crud/list/map-order-list-backend-to-order-list'
import { mapPaymentMethodCreateBackendToPaymentMethodCreate } from '../../mappers/payment/payment-method/_crud/create/map-payment-method-create-backend-to-payment-method-create'
import { mapPaymentMethodDeleteBackendToPaymentMethodDelete } from '../../mappers/payment/payment-method/_crud/delete/map-payment-method-delete-backend-to-payment-method-delete'
import { mapPaymentMethodGetBackendToPaymentMethodGet } from '../../mappers/payment/payment-method/_crud/get/map-payment-method-get-backend-to-payment-method-get'
import { mapPaymentMethodListBackendToPaymentMethodList } from '../../mappers/payment/payment-method/_crud/list/map-payment-method-list-backend-to-payment-method-list'
import { mapPaymentMethodUpdateBackendToPaymentMethodUpdate } from '../../mappers/payment/payment-method/_crud/update/map-payment-method-update-backend-to-payment-method-update'
import { mapPaymentRechargeBackendResponseToPaymentRechargeResponse } from '../../mappers/payment/recharge/map-payment-recharge-backend-response-to-payment-recharge-response'
import {
  OrderListBackendResponse,
  OrderListRequest,
  OrderListResponse,
} from '../../types/order/_crud/list'
import {
  PaymentMethodCreateBackendResponse,
  PaymentMethodCreateRequest,
  PaymentMethodCreateResponse,
} from '../../types/payment/payment-method/_crud/create'
import {
  PaymentMethodDeleteBackendResponse,
  PaymentMethodDeleteRequest,
  PaymentMethodDeleteResponse,
} from '../../types/payment/payment-method/_crud/delete'
import {
  PaymentMethodGetBackendResponse,
  PaymentMethodGetRequest,
  PaymentMethodGetResponse,
} from '../../types/payment/payment-method/_crud/get'
import {
  PaymentMethodListBackendResponse,
  PaymentMethodListRequest,
  PaymentMethodListResponse,
} from '../../types/payment/payment-method/_crud/list'
import {
  PaymentMethodUpdateBackendResponse,
  PaymentMethodUpdateRequest,
  PaymentMethodUpdateResponse,
} from '../../types/payment/payment-method/_crud/update'
import {
  PaymentRechargeBackendResponse,
  PaymentRechargeRequest,
  PaymentRechargeResponse,
} from '../../types/payment/recharge'

export const paymentRechargeDatagate = wrapApiRequest<
  PaymentRechargeRequest,
  PaymentRechargeBackendResponse,
  PaymentRechargeResponse
>(
  'payment/recharge',
  mapPaymentRechargeBackendResponseToPaymentRechargeResponse,
  'POST',
)

export const paymentMethodListDatagate = wrapApiRequest<
  PaymentMethodListRequest,
  PaymentMethodListBackendResponse,
  PaymentMethodListResponse
>(
  'payment/payment-method',
  mapPaymentMethodListBackendToPaymentMethodList,
  'GET',
)

export const paymentMethodGetDatagate = wrapApiRequest<
  PaymentMethodGetRequest,
  PaymentMethodGetBackendResponse,
  PaymentMethodGetResponse
>(
  'payment/payment-method/{payment_method_id}',
  mapPaymentMethodGetBackendToPaymentMethodGet,
  'GET',
)

export const paymentMethodCreateDatagate = wrapApiRequest<
  PaymentMethodCreateRequest,
  PaymentMethodCreateBackendResponse,
  PaymentMethodCreateResponse
>(
  'payment/payment-method',
  mapPaymentMethodCreateBackendToPaymentMethodCreate,
  'POST',
)

export const paymentMethodUpdateDatagate = wrapApiRequest<
  PaymentMethodUpdateRequest,
  PaymentMethodUpdateBackendResponse,
  PaymentMethodUpdateResponse
>(
  'payment/payment-method/{payment_method_id}',
  mapPaymentMethodUpdateBackendToPaymentMethodUpdate,
  'PUT',
)

export const paymentMethodDeleteDatagate = wrapApiRequest<
  PaymentMethodDeleteRequest,
  PaymentMethodDeleteBackendResponse,
  PaymentMethodDeleteResponse
>(
  'payment/payment-method/{payment_method_id}',
  mapPaymentMethodDeleteBackendToPaymentMethodDelete,
  'DELETE',
)
