import {
  LoginBackendResponse,
  LoginResponse,
} from '../../../../types/auth/login'

export const mapLoginBackendToLogin = (
  item: LoginBackendResponse,
): LoginResponse => {
  const { access_token, refresh_token } = item.data

  return {
    accessToken: access_token,
    refreshToken: refresh_token,
  }
}
