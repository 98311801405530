import {
  RedirectionCreateBackendResponse,
  RedirectionCreateResponse,
} from '../../../../../types/redirection/_crud/create'

export const mapRedirectionCreateBackendToRedirectionCreate = (
  item: RedirectionCreateBackendResponse,
): RedirectionCreateResponse => {
  const {
    redirection_id,
    redirection_type_id,
    condition,
    name,
    params,
    created_at,
    updated_at,
  } = item.data

  return {
    redirectionId: redirection_id,
    redirectionTypeId: redirection_type_id,
    condition: JSON.stringify(condition),
    name: name,
    params: JSON.stringify(params),
    createdAt: created_at,
    updatedAt: updated_at,
  }
}
