import {
  SignUpApproveBackendResponse,
  SignUpApproveResponse,
} from '../../../../types/auth/sign-up-approve'

export const mapSignUpApproveBackendToSignUpApprove = (
  item: SignUpApproveBackendResponse,
): SignUpApproveResponse => {
  const { user_id, access_token, refresh_token } = item.data

  return {
    userId: user_id,
    accessToken: access_token,
    refreshToken: refresh_token,
  }
}
