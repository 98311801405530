export const formatPhoneNumber = (phone: string | undefined): string => {
  if (!phone) return '-'

  let processedPhone = phone

  if (processedPhone.startsWith('0011'))
    processedPhone = processedPhone.replace('0011', '+')
  else if (processedPhone.startsWith('00'))
    processedPhone = processedPhone.replace('00', '+')
  else if (processedPhone?.startsWith('810'))
    processedPhone = processedPhone.replace('810', '+')
  else if (processedPhone?.startsWith('011'))
    processedPhone = processedPhone.replace('011', '+')
  else if (processedPhone?.startsWith('09'))
    processedPhone = processedPhone.replace('09', '+')

  if (processedPhone.startsWith('+'))
    processedPhone = processedPhone.replace('+', '')

  return processedPhone
}
