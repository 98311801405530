import React, { FC, useContext } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../../../../app/hooks/useModal'
import { ReactComponent as CaretRight } from '../../../../../../../../../assets/icons/caret-right.svg'
import { ReactComponent as DeleteIcon } from '../../../../../../../../../assets/icons/trash.svg'
import { MODAL_TYPES } from '../../../../../../../../../layouts/modals/ModalsProvider'
import { ContactListResponse } from '../../../../../../../../../sdk/datagates/types/contact/_crud/list'
import { ContactsPageContext } from '../../../../../contexts/ContactsPageContext'
import styles from './styles.module.scss'

const CardsContactMobileMessages = defineMessages({
  groupsCountTitle: {
    id: 'CardsContactMobileMessages.groupsCountTitle',
    defaultMessage: 'groups',
  },
  groupCountTitle: {
    id: 'CardsContactMobileMessages.groupCountTitle',
    defaultMessage: 'group',
  },
  actionDetails: {
    id: 'CardsContactMobileMessages.actionDetails',
    defaultMessage: 'Details',
  },
  deleteTitle: {
    id: 'CardsContactMobileMessages.deleteTitle',
    defaultMessage: 'Are you sure you want to delete the group?',
  },
})

interface CardsContactMobileProps {
  contact: ContactListResponse['contacts'][0]
}

export const CardsContactMobile: FC<CardsContactMobileProps> = (props) => {
  const { contact } = props
  const intl = useIntl()
  const { handleOpenModal } = useModal()

  const { handleFetchContacts, handleDeleteContact, handleFetchContactGroups } =
    useContext(ContactsPageContext)
  const contactGroupsTitle = () => {
    if (contact.groupsCount === 1 || contact.groupsCount === 0) {
      return `${contact.groupsCount} ${intl.formatMessage(CardsContactMobileMessages.groupCountTitle)}`
    } else {
      return `${contact.groupsCount} ${intl.formatMessage(CardsContactMobileMessages.groupsCountTitle)}`
    }
  }

  const onDelete = (contactId: number) => {
    handleOpenModal({
      type: MODAL_TYPES.DELETE_ITEM,
      props: {
        handleSubmit: async () => {
          await handleDeleteContact(contactId)
          await handleFetchContacts()
          await handleFetchContactGroups()
        },
        title: intl.formatMessage(CardsContactMobileMessages.deleteTitle),
      },
    })
  }

  const openContactSettingsModal = (contactId: number) => {
    handleOpenModal({
      type: MODAL_TYPES.CONTACT_SETTINGS_MODAL,
      props: {
        contactId: contactId,
        handleFetchContacts: handleFetchContacts(),
        handleFetchContactGroups,
        handleDeleteContact: () => handleDeleteContact(contactId),
      },
    })
  }

  return (
    <div className={styles.ContactCardContainer}>
      <div className={styles.CardInfo}>
        <div className={styles.CardName}>{contact.name}</div>
        <div className={styles.CardPhoneNumber}>{contact.phone}</div>
        <div className={styles.CardGroupsCount}>{contactGroupsTitle()}</div>
      </div>
      <div className={styles.CardActions}>
        <span
          className={styles.DeleteBtn}
          onClick={() => onDelete(contact.contactId)}
        >
          <DeleteIcon />
        </span>
        <button
          className={styles.DetailsBtn}
          onClick={() => openContactSettingsModal(contact.contactId)}
        >
          {intl.formatMessage(CardsContactMobileMessages.actionDetails)}
          <CaretRight />
        </button>
      </div>
    </div>
  )
}
