import {
  OAuthGoogleCallbackBackendResponse,
  OAuthGoogleCallbackResponse,
} from '../../../../../types/oauth/google/callback'

export const mapOAuthGoogleCallbackBackendToOAuthGoogleCallback = (
  item: OAuthGoogleCallbackBackendResponse,
): OAuthGoogleCallbackResponse => {
  const { access_token, refresh_token } = item.data

  return {
    accessToken: access_token,
    refreshToken: refresh_token,
  }
}
