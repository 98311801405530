import { getEnvironmentVariable } from '../../../shared/lib/utils/get-environment-variable/getEnvironmentVariable'

export const getNginxHost = (hostname: string = 'missing_variable') => {
  try {
    const envNginx = getEnvironmentVariable(hostname)

    if (process.env.NODE_ENV === 'production') {
      // eslint-disable-next-line no-restricted-globals
      return `${window.location.origin}/${envNginx}/`
    } else {
      return envNginx
    }
  } catch (e) {
    return `${window.location.origin}/react_api/`
  }
}
