import React, { FC, ReactNode } from 'react'
import clsx from 'clsx'
import { ReactComponent as CheckedIcon } from './assets/icons/checked.svg'
import styles from './styles.module.scss'

const CHECKBOX_VARIANT_LIST = {
  filled: 'filled',
  outlined: 'outlined',
} as const

export interface CheckboxProps extends React.HTMLProps<HTMLInputElement> {
  text: string | ReactNode
  subText?: string
  textReplacedElement?: ReactNode
  variant?: (typeof CHECKBOX_VARIANT_LIST)[keyof typeof CHECKBOX_VARIANT_LIST]
  error?: string
  markAsRequired?: boolean
  additionalClassNames?: string[]
  testId?: string
}

export const Checkbox: FC<CheckboxProps> = (props) => {
  const {
    text,
    subText,
    textReplacedElement,
    variant = CHECKBOX_VARIANT_LIST.filled,
    error,
    markAsRequired,
    additionalClassNames = [],
    testId,
    ...otherProps
  } = props

  return (
    <label
      className={clsx(styles.Container, ...additionalClassNames)}
      data-test-id={testId}
      tabIndex={0}
    >
      <input type="checkbox" className={styles.HiddenInput} {...otherProps} />

      <div
        className={clsx(
          styles.Checkbox,
          variant === CHECKBOX_VARIANT_LIST.outlined &&
            styles.Checkbox_outlined,
        )}
      >
        <CheckedIcon className={styles.CheckedIcon} />
      </div>

      {textReplacedElement ?? (
        <div className={styles.LabelWrapper}>
          <div className={styles.Label}>
            {subText && <div className={styles.Label__subtext}>{subText}</div>}{' '}
            {text}
            {markAsRequired && <span>*</span>}
          </div>
          {error && <div className={styles.Error}>{error}</div>}
        </div>
      )}
    </label>
  )
}
