import {
  DongleMobileNetworkInfoBackendResponse,
  DongleMobileNetworkInfoResponse,
} from '../../../../types/dongle/mobile-network-info'

export const mapDongleMobileNetworkInfoBackendToDongleMobileNetworkInfo = (
  item: DongleMobileNetworkInfoBackendResponse,
): DongleMobileNetworkInfoResponse => {
  const result = item.data.result

  return {
    networkInfoList: Object.entries(result).map(([k, v]) => {
      return { propName: k, propValue: v.toString() }
    }),
  }
}
