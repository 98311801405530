import {
  CheckAuthBackendResponse,
  CheckAuthResponse,
} from '../../../../types/auth/check-auth'

export const mapCheckAuthBackendToCheckAuth = (
  item: CheckAuthBackendResponse,
): CheckAuthResponse => {
  const { access_token, refresh_token } = item.data

  return {
    accessToken: access_token,
    refreshToken: refresh_token,
  }
}
