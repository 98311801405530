import React from 'react'
import { PageWrapperNoGUI } from '../../../../layouts/page-wrapper-no-gui/PageWrapperNoGUI'
import { PurchaseDetails } from './components/purchase-details/PurchaseDetails'
import { PurchaseSteps } from './components/purchase-steps/PurchaseSteps'
import { PurchaseProcessPageContextProvider } from './context/PurchaseProcessPageContextProvider'
import styles from './styles.module.scss'

export const PurchaseProcessPage = () => {
  return (
    <PageWrapperNoGUI>
      <PurchaseProcessPageContextProvider>
        <div className={styles.Container}>
          <PurchaseSteps />
          <PurchaseDetails />
        </div>
      </PurchaseProcessPageContextProvider>
    </PageWrapperNoGUI>
  )
}
