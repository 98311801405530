// @ts-nocheck

import { useEffect, useRef } from 'react'
import {useSelector} from "react-redux";

export function useClickOutside<T>(
  ref: ReturnType<typeof useRef>,
  onClickOutside: () => any,
) {
  const { isOpen } = useSelector((store) => store.dialog)

  useEffect(() => {
    function handleClickOutside(event: Event) {
      const isClickInsideFlatpickr =
        (event.target as Element).closest('.flatpickr-calendar') !== null
      //@ts-ignore
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !isClickInsideFlatpickr
        && !isOpen
      ) {
        onClickOutside()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, isOpen])
}
