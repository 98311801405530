import React, { FC, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useSnackbar } from '../../../../../app/hooks/useSnackbar'
import { ReactComponent as CopyIcon } from '../../../../../assets/icons/copy-icon.svg'
import { ReactComponent as DownloadIcon } from '../../../../../assets/icons/download.svg'
import { SNACKBAR_TYPES } from '../../../../../layouts/snackbar/SnackbarProvider'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { copyToClipboard } from '../../../../../shared/lib/utils/copy-to-clipboard/copyToClipboard'
import styles from './styles.module.scss'

const ApiFormMessages = defineMessages({
  label: {
    id: 'ApiFormMessages.label',
    defaultMessage: 'API Key',
  },
  copyText: {
    id: 'ApiFormMessages.copyText',
    defaultMessage: 'Copy',
  },
  copiedText: {
    id: 'ApiFormMessages.copiedText',
    defaultMessage: 'Copied',
  },
  download: {
    id: 'ApiFormMessages.download',
    defaultMessage: 'Download documentation file',
  },
})

interface ApiFormProps {
  apiKey: string | null
  swaggerText?: string
}

export const ApiForm: FC<ApiFormProps> = (props) => {
  const { apiKey, swaggerText } = props
  const intl = useIntl()
  const [copied, setCopied] = useState<boolean>(false)
  const isLessThanTablet = useLessThanDeviceScreen(ScreenDevices.TABLET)
  const { handleOpenSnackbar, handleHideSnackbar } = useSnackbar()

  const handleDownload = () => {
    if (swaggerText) {
      const blob = new Blob([swaggerText], { type: 'application/yaml' })

      const url = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.download = 'telecorn_api.yml'
      link.click()

      URL.revokeObjectURL(url)
    }
  }

  const handleCopyKey = () => {
    if (apiKey) {
      void copyToClipboard(apiKey)
      setCopied(true)

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(ApiFormMessages.copiedText),
      })

      setTimeout(() => {
        setCopied(false)
        handleHideSnackbar()
      }, 1000)
    }
  }

  const buttonText = useMemo(() => {
    return (
      !isLessThanTablet &&
      (copied
        ? intl.formatMessage(ApiFormMessages.copiedText)
        : intl.formatMessage(ApiFormMessages.copyText))
    )
  }, [copied, isLessThanTablet])

  return (
    <div className={styles.Form}>
      <div className={styles.Label}>
        {intl.formatMessage(ApiFormMessages.label)}
      </div>
      <div className={styles.Copy}>
        <div className={styles.CopyAndButton}>
          <div className={styles.Copy__field}>{apiKey}</div>
          <button
            className={styles.Copy__button}
            onClick={handleCopyKey}
            disabled={copied}
            type={'button'}
          >
            <CopyIcon />
            {buttonText}
          </button>
        </div>

        <button
          className={styles.DocumentationButton}
          onClick={handleDownload}
          type={'button'}
        >
          <DownloadIcon />
          {intl.formatMessage(ApiFormMessages.download)}
        </button>
      </div>
    </div>
  )
}
