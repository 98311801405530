import React from 'react'
import { useLocation } from 'react-router'
import { RoutePath } from '../../config/routes/constants/routePath'
import { Route } from '../../config/routes/enums/route'
import { useAppSelector } from '../../store'
import { GuestHeader } from './components/guest-header/GuestHeader'
import { UserHeader } from './components/user-header/UserHeader'

const GUEST_HEADER_ROUTES = [
  RoutePath[Route.Home],
  RoutePath[Route.FAQ]
]

export const Header = () => {
  const { isAuthenticated } = useAppSelector((state) => state.user)
  const { pathname } = useLocation()

  return isAuthenticated && !GUEST_HEADER_ROUTES.includes(pathname) ? (
    <UserHeader />
  ) : (
    <GuestHeader />
  )
}
