import React, { FC } from 'react'
import { ReactComponent as ErrorIcon } from './assets/icons/error.svg'
import styles from './styles.module.scss'

interface DisplayErrorProps {
  errorText: string | null
}

export const DisplayError: FC<DisplayErrorProps> = ({ errorText }) => {
  if (!errorText) return null

  return (
    <div className={styles.error}>
      <ErrorIcon />
      <span>{errorText}</span>
    </div>
  )
}
