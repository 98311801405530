import React, { Suspense, useEffect, useLayoutEffect, useState } from 'react'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router'

export const globalHistory = createBrowserHistory()

// @ts-ignore
const CustomRouter = ({ history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  })

  useLayoutEffect(() => history.listen(setState), [history])

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  )
}

export const withRouter = (component: () => React.ReactNode) => () => {
  // Set page scroll position to 0,0 on page change
  // useEffect(() => {
  //   const unlisten = globalHistory.listen(() => {
  //     window.scrollTo(0, 0);
  //   });
  //   return () => {
  //     unlisten();
  //   }
  // }, []);

  return (
    <CustomRouter history={globalHistory}>
      <Suspense fallback={<div>Loading...</div>}>{component()}</Suspense>
    </CustomRouter>
  )
}
