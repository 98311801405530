import { CallsListPage } from '../../../../../../../../../pages/lk/subpages/calls/subpages/calls-list/CallsListPage'
import { AuthRoutePath } from '../../../../../../../constants/authRoutePath'
import { AuthRoute } from '../../../../../../../enums/authRoute'
import { RouteDescriptor } from '../../../../../../../types/routeDescriptor'
import {CallsRoutePath} from "../../../../../../../constants/callsRoutePath";
import {CallsRoute} from "../../../../../../../enums/callsRoute";

export const callsSettings: RouteDescriptor<CallsRoute.CallsSettings> = {
  route: CallsRoute.CallsSettings,
  type: 'subpage',
  title: 'Telecorn',
  path: CallsRoutePath[CallsRoute.CallsSettings],
  render: CallsListPage,
  accessLevel: 'user',
  noAccessRedirectPath: AuthRoutePath[AuthRoute.SignIn],
}
