import {
  CalculateOrderPriceBackendResponse,
  CalculateOrderPriceResponse,
} from '../../../../types/order/calculate-order-price'

export const mapCalculateOrderPriceBackendToCalculateOrderPrice = (
  item: CalculateOrderPriceBackendResponse,
): CalculateOrderPriceResponse => {
  const { price, delivery_price, tariff_package_prices } = item.data

  let totalPrice: number | null = price

  if (delivery_price !== null) totalPrice += price
  else totalPrice = null

  return {
    price: price / 100,
    deliveryPrice: delivery_price ? delivery_price / 100 : delivery_price,
    totalPrice: totalPrice,
    tariffPackagePrices: tariff_package_prices.map((tp) => ({
      tariffPackageId: tp.tariff_package_id,
      price: tp.price ? tp.price / 100 : null,
    })),
  }
}
