import { defineMessages } from 'react-intl'

export const CommonButtonMessages = defineMessages({
  cancel: {
    id: 'CommonButtonMessages.cancel',
    defaultMessage: 'Cancel',
  },
  yesCancel: {
    id: 'CommonButtonMessages.yesCancel',
    defaultMessage: 'Yes, cancel',
  },
  save: {
    id: 'CommonButtonMessages.save',
    defaultMessage: 'Save',
  },
  create: {
    id: 'CommonButtonMessages.create',
    defaultMessage: 'Create',
  },
  delete: {
    id: 'CommonButtonMessages.delete',
    defaultMessage: 'Delete',
  },
  yesDelete: {
    id: 'CommonButtonMessages.yesDelete',
    defaultMessage: 'Yes, delete',
  },
  add: {
    id: 'CommonButtonMessages.add',
    defaultMessage: 'Add',
  },
  newContact: {
    id: 'CommonButtonMessages.newContact',
    defaultMessage: '+ Create new contact',
  },
  close: {
    id: 'CommonButtonMessages.close',
    defaultMessage: 'Close',
  },
  pay: {
    id: 'CommonButtonMessages.pay',
    defaultMessage: 'Pay',
  },
  no: {
    id: 'CommonButtonMessages.no',
    defaultMessage: 'No',
  },
  yes: {
    id: 'CommonButtonMessages.yes',
    defaultMessage: 'Yes',
  },
  send: {
    id: 'CommonButtonMessages.send',
    defaultMessage: 'Send',
  },
  reply: {
    id: 'CommonButtonMessages.reply',
    defaultMessage: 'Reply',
  },
  activate: {
    id: 'CommonButtonMessages.activate',
    defaultMessage: 'Activate',
  },
  back: {
    id: 'CommonButtonMessages.back',
    defaultMessage: 'Back',
  },
  details: {
    id: 'CommonButtonMessages.details',
    defaultMessage: 'Details',
  },
})
