import React, { useState } from 'react'
import { Card } from '../../../../shared/ui-kit-2/data-display/card/Card'
import { ForgotPasswordForm } from './components/forgot-password-form/ForgotPasswordForm'
import { ForgotPasswordVerificationForm } from './components/forgot-password-verification-form/ForgotPasswordVerificationForm'
import { SetNewPasswordForm } from './components/set-new-password-form/SetNewPasswordForm'
import styles from './styles.module.scss'

export const RecoverPasswordPage = () => {
  const [email, setEmail] = useState<string>('')
  const [verificationToken, setVerificationToken] = useState<string>('')

  const content = email ? (
    verificationToken ? (
      <SetNewPasswordForm email={email} verificationToken={verificationToken} />
    ) : (
      <ForgotPasswordVerificationForm
        email={email}
        setVerificationToken={setVerificationToken}
      />
    )
  ) : (
    <ForgotPasswordForm setEmail={setEmail} />
  )

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <div className={styles.CardContent}>{content}</div>
    </Card>
  )
}
