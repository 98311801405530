import {
  GeneratePasswordResetTokenBackendResponse,
  GeneratePasswordResetTokenResponse,
} from '../../../../types/auth/generate-password-reset-token'

export const mapGeneratePasswordResetTokenBackendToGeneratePasswordResetToken =
  (
    item: GeneratePasswordResetTokenBackendResponse,
  ): GeneratePasswordResetTokenResponse => {
    const { status } = item.data

    return {
      status: status,
    }
  }
