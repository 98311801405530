import React, { FC, useEffect, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../app/hooks/useModal'
import { CommonTabMessages } from '../../../../../../config/intl/common-messages/common-tab-messages/CommonTabMessages'
import { FilterOpenButton } from '../../../../../../layouts/custom-buttons/filter-open-button/FilterOpenButton'
import { useFilters } from '../../../../../../layouts/filters/hook/useFilters'
import { MODAL_TYPES } from '../../../../../../layouts/modals/ModalsProvider'
import { MailingTable } from '../../../../../../layouts/tables/sms-mailing-table/SmsMailingTable'
import { DongleListResponse } from '../../../../../../sdk/datagates/types/dongle/_crud/list'
import {
  MailingTabs,
  useMailings,
} from '../../../../../../sdk/hooks/use-mailings/useMailings'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { Card } from '../../../../../../shared/ui-kit-2/data-display/card/Card'
import { Button } from '../../../../../../shared/ui-kit-2/inputs/button/Button'
import { SegmentedControl } from '../../../../../../shared/ui-kit-2/inputs/segmented-control/SegmentedControl'
import styles from './styles.module.scss'

interface SmsMailingPageProps {
  activeDevices: DongleListResponse['dongles']
  activeDevicesLoading: boolean
}

const SmsMailingPageMessages = defineMessages({
  title: {
    id: 'SmsMailingPageMessages.title',
    defaultMessage: 'Bulk SMS',
  },
  create: {
    id: 'SmsMailingPageMessages.create',
    defaultMessage: 'Create New',
  },
})

export const SmsMailingPage: FC<SmsMailingPageProps> = ({ activeDevices, activeDevicesLoading }) => {
  const intl = useIntl()
  const { handleOpenModal } = useModal()

  const [mailingTab, setMailingTab] = useState<string>(MailingTabs.ALL)
  const [limit, setLimit] = useState<number>(50)
  const [page, setPage] = useState<number>(1)
  const isMobile = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK)

  const { filters, openFilters } = useFilters({
    filterFields: ['devices', 'sms-text'],
    devices: activeDevices,
    typeTab: mailingTab,
    setTypeTab: setMailingTab,
  })

  const { mailings, totalCount, handleFetch, loading } = useMailings({
    page,
    limit,
    mailingTab,
    filters,
  })

  const mailingStatusSegmentedGroup = useMemo(
    () => [
      {
        value: MailingTabs.ALL,
        comparedValue: mailingTab,
        label: intl.formatMessage(CommonTabMessages.filterAll),
        onClick: () => setMailingTab(MailingTabs.ALL),
      },
      {
        value: MailingTabs.ENDED,
        comparedValue: mailingTab,
        label: intl.formatMessage(CommonTabMessages.filterEnded),
        onClick: () => setMailingTab(MailingTabs.ENDED),
      },
      {
        value: MailingTabs.PLANNED,
        comparedValue: mailingTab,
        label: intl.formatMessage(CommonTabMessages.filterPlanned),
        onClick: () => setMailingTab(MailingTabs.PLANNED),
      },
      {
        value: MailingTabs.DRAFT,
        comparedValue: mailingTab,
        label: intl.formatMessage(CommonTabMessages.filterDraft),
        onClick: () => setMailingTab(MailingTabs.DRAFT),
      },
    ],
    [mailingTab],
  )

  const sliceMailings = mailings.slice(0, 3000)

  useEffect(() => {
    setPage(1)
  }, [mailingTab, filters])

  const handleOpenCreateMailingModal = () => {
    handleOpenModal({
      type: MODAL_TYPES.SMS_MAILING_CREATE,
      props: {
        handleFetch,
      },
    })
  }

  const hasFilters = !!Object.keys(filters).length

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <div className={styles.CardHeader}>
        <div className={styles.Title}>
          {intl.formatMessage(SmsMailingPageMessages.title)}
        </div>

        <div className={styles.HeaderNavigation}>
          {!isMobile && (
            <SegmentedControl
              name={'mailing-statuses'}
              group={mailingStatusSegmentedGroup}
            />
          )}
          <FilterOpenButton
            hasFilters={hasFilters}
            onClick={openFilters}
            loading={activeDevicesLoading}
          />
          <Button
            text={intl.formatMessage(SmsMailingPageMessages.create)}
            size={'small'}
            icon={'edit'}
            onClick={handleOpenCreateMailingModal}
            additionalClassNames={[styles.CreateButtonAdditional]}
          />
        </div>
      </div>

      <MailingTable
        mailings={sliceMailings}
        totalCount={totalCount}
        page={page}
        setPage={setPage}
        setLimit={setLimit}
        pageLimit={limit}
        loading={loading}
        handleFetch={handleFetch}
      />
    </Card>
  )
}
