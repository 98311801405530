import React, { useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../app/hooks/useModal'
import { CommonButtonMessages } from '../../../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { MODAL_TYPES } from '../../../../../../layouts/modals/ModalsProvider'
import { EmptyAutoReplies } from '../../../../../../layouts/status-layouts/empty-auto-replies/EmptyAutoReplies'
import { REDIRECTION_TYPES } from '../../../../../../sdk/hooks/use-redirection-types/constants/RedirectionTypes'
import { useRedirections } from '../../../../../../sdk/hooks/use-redirections/useRedirections'
import { Card } from '../../../../../../shared/ui-kit-2/data-display/card/Card'
import { Counter } from '../../../../../../shared/ui-kit-2/data-display/counter/Counter'
import { Button } from '../../../../../../shared/ui-kit-2/inputs/button/Button'
import { Loader } from '../../../../../../shared/ui-kit/data-display/loader/Loader'
import { AutoReplyCard } from '../sms-rules/components/sms-auto-reply-list/components/auto-reply-card/AutoReplyCard'
import styles from './styles.module.scss'

const SmsAutoReplyPageMessages = defineMessages({
  title: {
    id: 'SmsAutoReplyPageMessages.title',
    defaultMessage: 'Auto Reply',
  },
})

export const SmsAutoReplyPage = () => {
  const intl = useIntl()
  const { handleOpenModal } = useModal()
  const {
    redirections,
    totalCount,
    handleFetch,
    handleDeleteRedirection,
    loading,
  } = useRedirections({
    page: 0,
    limit: 0,
    redirectionTypes: [REDIRECTION_TYPES.AUTO_REPLY],
    takeAll: true,
  })
  const [initialLoading, setInitialLoading] = useState<boolean>(true)
  const [atLeastOneAutoReplyCreated, setAtLeastOneAutoReplyCreated] =
    useState<boolean>(false)

  useEffect(() => {
    if (totalCount > 0) setAtLeastOneAutoReplyCreated(true)
  }, [totalCount])

  useEffect(() => {
    if (!loading) setInitialLoading(false)
  }, [loading])

  const handleOpenAutoReplySettingsModal = () => {
    handleOpenModal({
      type: MODAL_TYPES.REDIRECTION_SETTINGS,
      props: {
        redirectionTypeId: REDIRECTION_TYPES.AUTO_REPLY,
        handleFetch: handleFetch,
      },
    })
  }

  const content = !atLeastOneAutoReplyCreated ? (
    <EmptyAutoReplies />
  ) : loading ? (
    <Loader />
  ) : (
    <div className={styles.ScrollableContent}>
      {redirections.map((autoReply) => (
        <AutoReplyCard
          key={autoReply.redirectionId}
          autoReply={autoReply}
          handleFetch={handleFetch}
          handleDeleteAutoReply={handleDeleteRedirection}
        />
      ))}
    </div>
  )

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <div className={styles.Header}>
        <div className={styles.Header__title}>
          {intl.formatMessage(SmsAutoReplyPageMessages.title)}
        </div>
        {!loading && (
          <Counter
            count={totalCount}
            variant={'filled'}
            additionalClassNames={[styles.Header__counterAdditional]}
          />
        )}
      </div>

      {initialLoading ? <Loader /> : content}

      <div className={styles.CreateContainer}>
        <Button
          text={intl.formatMessage(CommonButtonMessages.create)}
          size={'small'}
          onClick={handleOpenAutoReplySettingsModal}
        />
      </div>
    </Card>
  )
}
