import { useEffect, useState } from 'react'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { SNACKBAR_TYPES } from '../../../layouts/snackbar/SnackbarProvider'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import { orderListDatagate } from '../../datagates/api/order'
import { useBackendErrorCodes } from '../../datagates/helpers/_common/use-backend-error-codes'
import { OrderListResponse } from '../../datagates/types/order/_crud/list'
import { ORDER_TYPES } from '../use-order/useOrder'

type UseOrdersProps = {
  page?: number
  limit?: number
  orderTypes?: number[]
}

export const useOrders = (props: UseOrdersProps) => {
  const {
    page,
    limit,
    orderTypes = [ORDER_TYPES.DEVICES_WITH_SUBSCRIPTION],
  } = props

  const { handleOpenSnackbar } = useSnackbar()
  const { resolveBackendError } = useBackendErrorCodes()

  const [loading, setLoading] = useState<boolean>(true)
  const [initialLoaded, setInitialLoaded] = useState<boolean>(false)
  const [orders, setOrders] = useState<OrderListResponse['orders']>([])
  const [totalCount, setTotalCount] = useState<number>(0)

  const handleFetch = async () => {
    try {
      setLoading(true)

      const queryParams = [
        { name: 'order_by', value: 'created_at' },
        { name: 'order_type', value: 'desc' },
        { name: 'order_type_ids', value: orderTypes.map((o) => o.toString()) },
      ]

      if (page && limit) {
        queryParams.push({ name: 'page', value: (page - 1).toString() })
        queryParams.push({ name: 'limit', value: limit.toString() })
      }

      const { data } = await orderListDatagate(null, null, queryParams)

      setInitialLoaded(true)
      setTotalCount(data.totalCount)
      setOrders(data.orders)
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void handleFetch()
  }, [page, limit])

  return {
    orders,
    totalCount,
    loading,
    handleFetch,
    initialLoaded,
  }
}
