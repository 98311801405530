import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useModal } from '../../../app/hooks/useModal'
import { DongleListResponse } from '../../../sdk/datagates/types/dongle/_crud/list'
import { formatPhoneNumber } from '../../../sdk/formatters/format-phone-number'
import { MultiselectOption } from '../../../shared/ui-kit-2/inputs/multiselect/Multiselect'
import { MODAL_TYPES } from '../../modals/ModalsProvider'

interface UseFiltersProps {
  filterFields: FilterField[]
  devices?: DongleListResponse['dongles']
  testIds?: UseFiltersTestIdsType
  typeTab?: string
  setTypeTab?: Dispatch<SetStateAction<string>>
  handleChangeSettings?: (props: Record<string, unknown>) => void
}

export type FilterField = 'dates' | 'devices' | 'phone-numbers' | 'sms-text'

export type UseFiltersTestIdsType = Partial<{
  dateFromTestId: string
  dateToTestId: string
  devicesMultiSelectTestId: string
  clearAllButtonTestId: string
  submitButtonTestId: string
}>

export type FilterType = { [key: string]: string | string[] | undefined }

export const useFilters = (props: UseFiltersProps) => {
  const { filterFields, devices = [], testIds, typeTab, setTypeTab, handleChangeSettings } = props
  const { pathname } = useLocation()
  const { handleOpenModal } = useModal()
  const [isOpen, setIsOpen] = useState(false)
  const [filters, setFilters] = useState<FilterType>({})
  const [deviceOptions, setDeviceOptions] = useState<MultiselectOption[]>([])

  useEffect(() => {
    const deviceOptions: MultiselectOption[] = devices.map((device) => ({
      key: device.dongleId,
      label: device.name,
      subLabel: formatPhoneNumber(device.phoneNumber),
      value: device.dongleId.toString(),
    }))

    setDeviceOptions(deviceOptions)
  }, [devices])

  useEffect(() => {
    if (isOpen) {
      handleOpenModal({
        type: MODAL_TYPES.FILTERS,
        props: {
          closeFilters: () => setIsOpen(false),
          deviceOptions,
          filters,
          setFilters,
          filterFields,
          testIds,
          typeTab,
          setTypeTab,
          pathname,
        },
      })
    }
  }, [isOpen])

  useEffect(() => {
    if (handleChangeSettings) {
      handleChangeSettings(filters);
    }
  }, [filters])

  return {
    filters,
    openFilters: () => setIsOpen(true),
  }
}
