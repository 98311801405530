import React, { FC } from 'react'
import clsx from 'clsx'
import {
  RADIO_VARIANT_LIST,
  RadioProps,
} from '../../../../../ui-kit-2/inputs/radio/components/Radio'
import { RadioField } from '../radio-field/RadioField'

export interface RadioGroupProps {
  name: string
  group: Array<RadioProps>
  variant?: (typeof RADIO_VARIANT_LIST)[keyof typeof RADIO_VARIANT_LIST]
  additionalClassNames?: string[]
}

export const RadioGroupField: FC<RadioGroupProps> = (props) => {
  const {
    name,
    group,
    variant,
    additionalClassNames = [],
    ...otherProps
  } = props

  return (
    <div className={clsx(...additionalClassNames)}>
      {group.map(({ label, value, onClick }) => (
        <RadioField
          name={name}
          key={value}
          variant={variant}
          label={label ?? ''}
          value={value}
          onClick={onClick}
          {...otherProps}
        />
      ))}
    </div>
  )
}
