import {
  PaymentMethodGetBackendResponse,
  PaymentMethodGetResponse,
} from '../../../../../../types/payment/payment-method/_crud/get'

export const mapPaymentMethodGetBackendToPaymentMethodGet = (
  item: PaymentMethodGetBackendResponse,
): PaymentMethodGetResponse => {
  const { payment_method_id, name, is_default, is_secondary } = item.data

  return {
    paymentMethodId: payment_method_id,
    name: name,
    isDefault: is_default,
    isSecondary: is_secondary,
  }
}
