import {
  ContactGetBackendResponse,
  ContactGetResponse,
} from '../../../../../types/contact/_crud/get'

export const mapContactGetBackendToContactGet = (
  item: ContactGetBackendResponse,
): ContactGetResponse => {
  const { contact_id, user_id, phone, name, contact_groups } = item.data

  return {
    contactId: contact_id,
    userId: user_id,
    phone: phone,
    name: name,
    contactGroups: contact_groups.map((cg) => ({
      contactGroupId: cg.contact_group_id,
      name: cg.name,
      userId: cg.user_id,
    })),
  }
}
