import {InputField} from "../../../../../../../../shared/lib/form/form-field-adapters/v2/input-field/InputField";
import React, {Dispatch, FC, SetStateAction, useCallback, useContext} from "react";
import {
  SelectSearchField
} from "../../../../../../../../shared/lib/form/form-field-adapters/v2/select-search-field/SelectSearchField";
import {composeValidators, useFormRules} from "../../../../../../../../shared/lib/form/form-rules";
import {CALCULATE_DELIVERY_ERRORS} from "../../../../constants/calculateDeliveryErrors";
import {PurchaseStepShippingMessages} from "../../PurchaseStepShipping";
import {useIntl} from "react-intl";
import {PurchaseProcessPageContext} from "../../../../context/PurchaseProcessPageContextProvider";
import styles from "../../styles.module.scss";
import {Checkbox} from "../../../../../../../../shared/ui-kit-2/inputs/checkbox/Checkbox";
import {
  ProfileFormMessages
} from "../../../../../profile/components/profilePageEn/components/profile-page-form/ProfileForm";
import {USER_TYPE_IDS} from "../../../../../../../../sdk/datagates/types/user/_crud/get";

interface DeliveryFormProps {
  onFieldChange: () => void;
  countryOptions: {key: number, value: string, label: string, inputLabel: string}[];
  isLegalAddressSame: boolean;
  setIsLegalAddressSame: Dispatch<SetStateAction<boolean>>
}

export const DeliveryForm: FC<DeliveryFormProps> = ({
  onFieldChange,
  countryOptions,
  isLegalAddressSame,
  setIsLegalAddressSame
}) => {
  const intl = useIntl()
  const { ruleRequired } = useFormRules()
  const {
    formData,
    calculateDeliveryErrors,
    setCalculateDeliveryErrors
  } = useContext(PurchaseProcessPageContext)
  const calculateDeliveryHasErrors = !!(calculateDeliveryErrors && calculateDeliveryErrors.length > 0);
  const isOrganization =
    formData?.accountType === USER_TYPE_IDS.ORGANIZATION.toString()

  const handleNullingDeliveryErrors = useCallback((fieldName: string) => {
    if (calculateDeliveryHasErrors) {
      const filterDeliveryErrors = (errors: Array<string>) => calculateDeliveryErrors?.filter(item => !errors.includes(item)) || calculateDeliveryErrors;

      switch (fieldName) {
        case "countryId":
          setCalculateDeliveryErrors(filterDeliveryErrors([CALCULATE_DELIVERY_ERRORS.COUNTRY_ID]))
          break;

        case "postalCode":
          setCalculateDeliveryErrors(filterDeliveryErrors([CALCULATE_DELIVERY_ERRORS.POSTAL_CODE]))
          break;
      }
    }
  }, [calculateDeliveryHasErrors])

  const countryDefaultInputValue = countryOptions.find(
    (c) => c.value === formData?.countryId,
  )?.label

  return (
    <div className={styles.DeliveryAddress}>
      {isOrganization && (
        <div className={styles.CheckBoxLegal}>
          <Checkbox
            checked={isLegalAddressSame}
            text={intl.formatMessage(ProfileFormMessages.deliveryCheckBoxLabel)}
            onChange={() => setIsLegalAddressSame(prevState => !prevState)}
          />
        </div>
      )}
      {!isLegalAddressSame && (
        <div>
          <div className={styles.CategoryTitle}>
            {intl.formatMessage(PurchaseStepShippingMessages.deliveryAddressTitle)}
          </div>

          <div className={styles.InputGrid}>
            <InputField
              name="streetAddress1"
              validate={ruleRequired()}
              label={intl.formatMessage(
                PurchaseStepShippingMessages.streetAddress1Label,
              )}
              placeholder={intl.formatMessage(
                PurchaseStepShippingMessages.streetAddress1Placeholder,
              )}
              onChange={onFieldChange}
              markAsRequired={true}
            />

            <InputField
              name="streetAddress2"
              validate={ruleRequired()}
              label={intl.formatMessage(
                PurchaseStepShippingMessages.streetAddress2Label,
              )}
              placeholder={intl.formatMessage(
                PurchaseStepShippingMessages.streetAddress2Placeholder,
              )}
              onChange={onFieldChange}
              markAsRequired={true}
            />

            <SelectSearchField
              name="countryId"
              validate={ruleRequired()}
              options={countryOptions}
              label={intl.formatMessage(
                PurchaseStepShippingMessages.countryLabel,
              )}
              placeholder={intl.formatMessage(
                PurchaseStepShippingMessages.countryPlaceholder,
              )}
              defaultInputValue={countryDefaultInputValue}
              markAsRequired={true}
            />

            <InputField
              name="postalCode"
              validate={composeValidators(
                ruleRequired()
              )}
              label={intl.formatMessage(
                PurchaseStepShippingMessages.postalCodeLabel,
              )}
              placeholder={intl.formatMessage(
                PurchaseStepShippingMessages.postalCodePlaceholder,
              )}
              customError={calculateDeliveryErrors?.includes(CALCULATE_DELIVERY_ERRORS.POSTAL_CODE)
                ? intl.formatMessage(PurchaseStepShippingMessages.calculateDeliveryPostalCodeError)
                : undefined
              }
              onChange={() => {
                onFieldChange()
                handleNullingDeliveryErrors("postalCode")
              }}
              markAsRequired={true}
            />

            <InputField
              name="city"
              label={intl.formatMessage(
                PurchaseStepShippingMessages.cityLabel,
              )}
              placeholder={intl.formatMessage(
                PurchaseStepShippingMessages.cityPlaceholder,
              )}
              onChange={onFieldChange}
              markAsRequired={true}
            />

            <InputField
              name="region"
              label={intl.formatMessage(
                PurchaseStepShippingMessages.regionLabel,
              )}
              placeholder={intl.formatMessage(
                PurchaseStepShippingMessages.regionPlaceholder,
              )}
              onChange={onFieldChange}
              markAsRequired={true}
            />
          </div>
        </div>
      )}
    </div>
  )
}