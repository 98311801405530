import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useIntl } from 'react-intl'
import { useSnackbar } from '../../../../../app/hooks/useSnackbar'
import { ContactUsFormMessages } from '../../../../../pages/home/components/sections/home-09/components/contact-us-form/ContactUsForm'
import {
  ProfileFormMessages,
  ProfileFormType,
} from '../../../../../pages/lk/subpages/profile/components/profilePageEn/components/profile-page-form/ProfileForm'
import { callbackRequestFormDatagateBuyDevice } from '../../../../../sdk/datagates/api/callback'
import { useBackendErrorCodes } from '../../../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { TARIFF_PACKAGE_ID } from '../../../../../sdk/datagates/types/order/_common'
import { useTariffPackages } from '../../../../../sdk/hooks/use-tariff-packages/useTariffPackages'
import { InputField } from '../../../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import { useFormRules } from '../../../../../shared/lib/form/form-rules'
import { getErrorMessage } from '../../../../../shared/lib/utils/get-error-message/getErrorMessage'
import { Card } from '../../../../../shared/ui-kit-2/data-display/card/Card'
import { Button } from '../../../../../shared/ui-kit-2/inputs/button/Button'
import { useAppSelector } from '../../../../../store'
import { SNACKBAR_TYPES } from '../../../../snackbar/SnackbarProvider'
import {useSiteVersion} from "../../../../../app/hooks/useSiteVersion";
import styles from './styles.module.scss'
import {useModal} from "../../../../../app/hooks/useModal";
import {
  PhoneInputFieldNumber
} from "../../../../../shared/lib/form/form-field-templates/v2/phone-input-field-new/PhoneInputFieldNew";
import {getClearedPhoneNumber} from "../../../../../shared/lib/utils/get-cleared-phone-number/getClearedPhoneNumber";

interface ContactUsFormType {
  firstName: string
  email: string
  userPhone: string
}

export const BuyNewDeviceFormRu = () => {
  const intl = useIntl()
  const { handleHideModal } = useModal()
  const { email, phone, firstName } = useAppSelector((state) => state.user)
  const { handleOpenSnackbar } = useSnackbar()
  const { resolveBackendError } = useBackendErrorCodes()
  const { ruleNotIncludeNumbers } = useFormRules()

  const { getTariffPackagePrice } = useTariffPackages()

  const monthPlanPrice = getTariffPackagePrice(TARIFF_PACKAGE_ID.MONTH)
  const monthPlanAnnual = getTariffPackagePrice(TARIFF_PACKAGE_ID.ANNUAL)

  const [initialValues, setInitialValues] = useState<Partial<ProfileFormType>>(
    {},
  )

  const { siteDomainId } = useSiteVersion()

  useEffect(() => {
    setInitialValues({
      firstName: firstName,
      email: email,
      userPhone: phone
    })
  }, [])

  const onSubmit = async (values: ContactUsFormType) => {
    try {
      await callbackRequestFormDatagateBuyDevice({
        name: values.firstName,
        phone: getClearedPhoneNumber(values.userPhone),
        email: email ? email : '',
        site_domain_id: siteDomainId
      })

      handleHideModal()

      setTimeout(() => {
        handleOpenSnackbar({
          type: SNACKBAR_TYPES.success,
          text: intl.formatMessage(ContactUsFormMessages.success),
        })
      }, 300)
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(
          getErrorMessage(e),
          intl.formatMessage(ContactUsFormMessages.error),
        ),
      })
    }
  }

  return (
    <div className={styles.Container}>
      <div className={styles.ProductConditionsContainer}>
        <Card>
          <div className={styles.ProductContainer}>
            <div className={styles.Title}>Месячная подписка</div>
            <div className={styles.ProductConditions}>
              <div className={styles.ProductCondition}>
                <div className={styles.ProductConditionTitle}>
                  Месячная подписка
                </div>
                <div className={styles.ProductConditionPrice}>
                  {monthPlanPrice}₽
                </div>
              </div>
              <div className={styles.ProductCondition}>
                <div className={styles.ProductConditionTitle}>USB Модем</div>
                <div className={styles.ProductConditionPrice}>—</div>
              </div>
            </div>
          </div>
        </Card>
        <Card>
          <div className={styles.ProductContainer}>
            <div className={styles.Header}>
              <div className={styles.Title}>Годовая подписка</div>
              <div className={styles.Badge}>Экономия 1000 руб</div>
            </div>
            <div className={styles.ProductConditions}>
              <div className={styles.ProductCondition}>
                <div className={styles.ProductConditionTitle}>За год</div>
                <div className={styles.ProductConditionPrice}>
                  <span>{monthPlanAnnual}₽</span>
                  <span className={styles.PriceGreen}>6 000₽</span>
                </div>
              </div>
              <div className={styles.ProductCondition}>
                <div className={styles.ProductConditionTitle}>USB Модем</div>
                <div className={styles.ProductConditionPrice}>—</div>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className={styles.FormContainer}>
        <div className={styles.FormTitle}>
          Оставьте свои данные, с вами свяжется менеджер
        </div>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className={styles.Form}>
              <div className={styles.ItemName}>
                <InputField
                  name="firstName"
                  validate={ruleNotIncludeNumbers(true)}
                  label={intl.formatMessage(ProfileFormMessages.firstNameLabel)}
                  placeholder={intl.formatMessage(
                    ProfileFormMessages.firstNameLabel,
                  )}
                  markAsRequired={true}
                />
              </div>
              <div className={styles.ItemPhone}>
                <PhoneInputFieldNumber
                  name="userPhone"
                  label={intl.formatMessage(ProfileFormMessages.phoneNumberLabel)}
                  initialValue={phone}
                  markAsRequired={true}
                  noHelperText={true}
                />
              </div>
              <div className={styles.ItemButton}>
                <Button
                  text={intl.formatMessage(ContactUsFormMessages.submit)}
                  onClick={handleSubmit}
                  loading={submitting}
                  variant={'green'}
                  additionalClassNames={[styles.Button]}
                />
              </div>
            </form>
          )}
        />
      </div>
    </div>
  )
}
