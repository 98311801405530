import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Route as ReactRoute, useNavigate } from 'react-router'
import { Navigate, Routes } from 'react-router-dom'
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg'
import { authRoutes } from '../../config/routes/authRoutes'
import { AuthRoutePath } from '../../config/routes/constants/authRoutePath'
import { RoutePath } from '../../config/routes/constants/routePath'
import { AuthRoute } from '../../config/routes/enums/authRoute'
import { Route } from '../../config/routes/enums/route'
import { AccessRoute } from '../../routing/AccessRoute'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../shared/lib/hooks/useLessThanDeviceScreen'
import { AuthShowcase } from './components/auth-showcase/AuthShowcase'
import styles from './styles.module.scss'
import {useRootPath} from "../../shared/lib/hooks/useRootPath";

const AuthPageMessages = defineMessages({
  title: {
    id: 'AuthPageMessages.title',
    defaultMessage:
      'Take business communication to the next level with {serviceName}',
  },
  serviceName: {
    id: 'AuthPageMessages.serviceName',
    defaultMessage: 'Teleсorn',
  },
})

export const AuthPage = () => {
  const isMobile = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK)
  const navigate = useNavigate()
  const intl = useIntl()
  const rootPath = useRootPath();

  const toHome = () => navigate(rootPath);

  return (
    <>
      <div className={styles.Container}>
        {!isMobile ? (
          <AuthShowcase />
        ) : (
          <div className={styles.HeaderContainer}>
            <div className={styles.Header}>
              <LogoIcon onClick={toHome} />
            </div>
            <div className={styles.Title}>
              {intl.formatMessage(AuthPageMessages.title, {
                serviceName: (
                  <span>
                    {intl.formatMessage(AuthPageMessages.serviceName)}
                  </span>
                ),
              })}
            </div>
          </div>
        )}

        <Routes>
          {Object.values(authRoutes).map((r) => (
            <ReactRoute
              key={r.route}
              path={r.path.split('/').pop()}
              element={<AccessRoute key={r.route} route={r} />}
            />
          ))}

          <ReactRoute
            path={'*'}
            element={
              <Navigate to={AuthRoutePath[AuthRoute.SignIn]} replace={true} />
            }
          />
        </Routes>
      </div>
      {isMobile && (
        <>
          <video
            autoPlay
            muted
            loop
            controls={false}
            className={styles.Video}
            playsInline
          >
            <div className={styles.VideoMask}></div>
            <source src="/videos/auth.mp4" type="video/mp4" />
          </video>
          <div className={styles.VideoOverlay} />
        </>
      )}
    </>
  )
}
