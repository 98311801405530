import { defineMessages, useIntl } from 'react-intl'

const BackendErrorMessages = defineMessages({
  unknownError: {
    id: 'BackendErrorMessages.unknownError',
    defaultMessage: 'Unknown error. Please, try again later',
  },
  incorrectAuthCredentials: {
    id: 'BackendErrorMessages.incorrectAuthCredentials',
    defaultMessage: 'Incorrect email or password',
  },
  registerWeakPassword: {
    id: 'BackendErrorMessages.registerWeakPassword',
    defaultMessage: 'Weak password',
  },
  registerInvalidEmail: {
    id: 'BackendErrorMessages.registerInvalidEmail',
    defaultMessage: 'Invalid email',
  },
  registerPasswordsNotMatch: {
    id: 'BackendErrorMessages.registerPasswordsNotMatch',
    defaultMessage: 'Passwords do not match',
  },
  forgetPasswordWeakPassword: {
    id: 'BackendErrorMessages.forgetPasswordWeakPassword',
    defaultMessage: 'Weak password',
  },
  forgetPasswordPasswordsNotMatch: {
    id: 'BackendErrorMessages.forgetPasswordPasswordsNotMatch',
    defaultMessage: 'Passwords do not match',
  },
  callEmptyRequiredFields: {
    id: 'BackendErrorMessages.callEmptyRequiredFields',
    defaultMessage: 'You have empty required fields',
  },
  smsEmptyRequiredFields: {
    id: 'BackendErrorMessages.smsEmptyRequiredFields',
    defaultMessage: 'You have empty required fields',
  },
  hotspotWeakName: {
    id: 'BackendErrorMessages.hotspotWeakName',
    defaultMessage: 'Weak WI-FI name',
  },
  hotspotWeakPassword: {
    id: 'BackendErrorMessages.hotspotWeakPassword',
    defaultMessage: 'Weak WI-FI password',
  },
  redirectionEmptyFields: {
    id: 'BackendErrorMessages.redirectionEmptyFields',
    defaultMessage: 'You have empty required fields',
  },
  callbackRequestInvalidEmail: {
    id: 'BackendErrorMessages.callbackRequestInvalidEmail',
    defaultMessage: 'Invalid email',
  },
  callbackRequestInvalidPhone: {
    id: 'BackendErrorMessages.callbackRequestInvalidPhone',
    defaultMessage: 'Invalid phone',
  },
  callbackRequestEmptyFields: {
    id: 'BackendErrorMessages.callbackRequestEmptyFields',
    defaultMessage: 'You have empty required fields',
  },
  changePasswordPasswordsNotMatch: {
    id: 'BackendErrorMessages.changePasswordPasswordsNotMatch',
    defaultMessage: 'Passwords do not match',
  },
  sendSmsIncorrectScheduleDate: {
    id: 'BackendErrorMessages.sendSmsIncorrectScheduleDate',
    defaultMessage: 'Incorrect date given for scheduled SMS',
  },

  USER_ACCOUNT_ERROR_IDENTIFIER_USER_NOT_FOUND: {
    id: 'BackendErrorMessages.USER_ACCOUNT_ERROR_IDENTIFIER_USER_NOT_FOUND',
    defaultMessage: 'User not found',
  },
  AUTH_ERROR_IDENTIFIER_USER_WAS_ALREADY_APPROVED: {
    id: 'BackendErrorMessages.AUTH_ERROR_IDENTIFIER_USER_WAS_ALREADY_APPROVED',
    defaultMessage: 'User already approved',
  },
  AUTH_REGISTRATION_ERROR_IDENTIFIER_EMPTY_FIELDS: {
    id: 'BackendErrorMessages.AUTH_REGISTRATION_ERROR_IDENTIFIER_EMPTY_FIELDS',
    defaultMessage:
      'Sign up verification failed, please contact our technical support',
  },
  AUTH_FORGET_PASSWORD_ERROR_IDENTIFIER_EMPTY_FIELDS: {
    id: 'BackendErrorMessages.AUTH_FORGET_PASSWORD_ERROR_IDENTIFIER_EMPTY_FIELDS',
    defaultMessage:
      'Forgot password process failed, please contact our technical support',
  },
  CONTACT_ERROR_REDIRECTS_ARE_PRESENT_WHILE_DELETING_CONTACT: {
    id: 'BackendErrorMessages.CONTACT_ERROR_REDIRECTS_ARE_PRESENT_WHILE_DELETING_CONTACT',
    defaultMessage:
      'You have existing redirection or auto reply rules for this contact',
  },
  CONTACT_ERROR_REDIRECTS_ARE_PRESENT_WHILE_DELETING_CONTACT_GROUP: {
    id: 'BackendErrorMessages.CONTACT_ERROR_REDIRECTS_ARE_PRESENT_WHILE_DELETING_CONTACT_GROUP',
    defaultMessage:
      'You have existing redirection or auto reply rules for this contact group',
  },
  SMS_ERROR_ERROR_SMS_REPLY_FOUND: {
    id: 'BackendErrorMessages.SMS_ERROR_ERROR_SMS_REPLY_FOUND',
    defaultMessage:
      'An error occurred while creating SMS reply. Please contact our technical support',
  },
  SMS_ERROR_INVALID_SMS_STATUS: {
    id: 'BackendErrorMessages.SMS_ERROR_INVALID_SMS_STATUS',
    defaultMessage:
      'Invalid status while updating SMS. Please contact our technical support',
  },
  SMS_ERROR_ERROR_SMS_NOT_FOUND: {
    id: 'BackendErrorMessages.SMS_ERROR_ERROR_SMS_NOT_FOUND',
    defaultMessage: 'SMS not found',
  },
  SMS_ERROR_IDENTIFIER_LONG_MESSAGE: {
    id: 'BackendErrorMessages.SMS_ERROR_IDENTIFIER_LONG_MESSAGE',
    defaultMessage: 'Too long message text for SMS',
  },
  PAYMENT_ERROR_IDENTIFIER_NOT_ENOUGH_MONEY: {
    id: 'BackendErrorMessages.PAYMENT_ERROR_IDENTIFIER_NOT_ENOUGH_MONEY',
    defaultMessage: 'Not enough money to pay',
  },
  CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_CONTACT: {
    id: 'BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_CONTACT',
    defaultMessage: 'An error occurred while saving contact',
  },
  CONTACT_ERROR_IDENTIFIER_ERROR_EMPTY_PHONE_NUMBER: {
    id: 'BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_ERROR_EMPTY_PHONE_NUMBER',
    defaultMessage: 'Empty phone given for contact',
  },
  CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_CONTACT_GROUP: {
    id: 'BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_CONTACT_GROUP',
    defaultMessage: 'An error occurred while saving contact group',
  },
  CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_DELETING_CONTACT: {
    id: 'BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_DELETING_CONTACT',
    defaultMessage: 'An error occurred while deleting contact',
  },
  CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_DELETING_CONTACT_GROUP: {
    id: 'BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_DELETING_CONTACT_GROUP',
    defaultMessage: 'An error occurred while saving contact group',
  },
  CONTACT_ERROR_IDENTIFIER_CONTACT_NOT_FOUND: {
    id: 'BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_CONTACT_NOT_FOUND',
    defaultMessage: 'Contact not found',
  },
  CONTACT_ERROR_IDENTIFIER_CONTACT_GROUP_NOT_FOUND: {
    id: 'BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_CONTACT_GROUP_NOT_FOUND',
    defaultMessage: 'Contact group not found',
  },
  CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_CONTACT_CONTACT_GROUPS: {
    id: 'BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_CONTACT_CONTACT_GROUPS',
    defaultMessage: 'An error occurred while linking contacts to contact group',
  },
  CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SMS_PROCESSING: {
    id: 'BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SMS_PROCESSING',
    defaultMessage: 'An error occurred while setting contact for SMS',
  },
  CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_CALL_PROCESSING: {
    id: 'BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_CALL_PROCESSING',
    defaultMessage: 'An error occurred while setting contact for call',
  },
  MAILING_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_MAILING: {
    id: 'BackendErrorMessages.MAILING_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_MAILING',
    defaultMessage: 'An error occurred while saving mailing',
  },
  MAILING_ERROR_IDENTIFIER_ERROR_WHILE_DELETING_MAILING: {
    id: 'BackendErrorMessages.MAILING_ERROR_IDENTIFIER_ERROR_WHILE_DELETING_MAILING',
    defaultMessage: 'An error occurred while deleting mailing',
  },
  MAILING_ERROR_IDENTIFIER_ERROR_MAILING_NOT_FOUND: {
    id: 'BackendErrorMessages.MAILING_ERROR_IDENTIFIER_ERROR_MAILING_NOT_FOUND',
    defaultMessage: 'Mailing not found',
  },
  MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_PHONES: {
    id: 'BackendErrorMessages.MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_PHONES',
    defaultMessage: 'An error occurred while saving phones for mailing',
  },
  MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_CONTACTS: {
    id: 'BackendErrorMessages.MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_CONTACTS',
    defaultMessage: 'An error occurred while saving contact for mailing',
  },
  MAILING_ERROR_IDENTIFIER_UNKNOWN_CONTACTS: {
    id: 'BackendErrorMessages.MAILING_ERROR_IDENTIFIER_UNKNOWN_CONTACTS',
    defaultMessage: 'Unknown contact given while saving mailing',
  },
  MAILING_ERROR_IDENTIFIER_UNKNOWN_CONTACT_GROUPS: {
    id: 'BackendErrorMessages.MAILING_ERROR_IDENTIFIER_UNKNOWN_CONTACT_GROUPS',
    defaultMessage: 'Unknown contact group given while saving mailing',
  },
  MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_CONTACT_GROUPS: {
    id: 'BackendErrorMessages.MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_CONTACT_GROUPS',
    defaultMessage: 'An error occurred while saving contact group for mailing',
  },
  MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_UPDATE: {
    id: 'BackendErrorMessages.MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_UPDATE',
    defaultMessage: 'Invalid status while updating mailing',
  },
  MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_CREATE: {
    id: 'BackendErrorMessages.MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_CREATE',
    defaultMessage: 'Invalid status while creating mailing',
  },
  MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_DELETE: {
    id: 'BackendErrorMessages.MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_DELETE',
    defaultMessage: 'Invalid status while deleting mailing',
  },
  MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_SOFT_DELETE: {
    id: 'BackendErrorMessages.MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_SOFT_DELETE',
    defaultMessage: 'Invalid status while mailing soft delete',
  },
  MAILING_ERROR_IDENTIFIER_EXCEED_DONGLE_SMS_LIMIT: {
    id: 'BackendErrorMessages.MAILING_ERROR_IDENTIFIER_EXCEED_DONGLE_SMS_LIMIT',
    defaultMessage: 'Daily mailing SMS limit exceeded for this modem',
  },
  MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_HISTORY: {
    id: 'BackendErrorMessages.MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_HISTORY',
    defaultMessage: 'An error occurred while creating history for mailing',
  },
  MAILING_ERROR_IDENTIFIER_EMPTY_SENDERS: {
    id: 'BackendErrorMessages.MAILING_ERROR_IDENTIFIER_EMPTY_SENDERS',
    defaultMessage:
      'Receivers cannot be empty. Please choose at least one phone/contact/group',
  },
  CONTACT_ERROR_IDENTIFIER_ALREADY_EXISTS_SAME_NUMBER: {
    id: 'BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_ALREADY_EXISTS_SAME_NUMBER',
    defaultMessage: 'Contact with same number already exists',
  },
  CONTACT_ERROR_IDENTIFIER_ALREADY_EXISTS_SAME_NAME: {
    id: 'BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_ALREADY_EXISTS_SAME_NAME',
    defaultMessage: 'Contact with same name already exists',
  },
  CONTACT_ERROR_IDENTIFIER_INVALID_USER_ID: {
    id: 'BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_INVALID_USER_ID',
    defaultMessage: 'Invalid User ID given',
  },
  CONTACT_ERROR_IDENTIFIER_BIG_PHONE: {
    id: 'BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_BIG_PHONE',
    defaultMessage: 'Too long phone number',
  },
  CONTACT_ERROR_IDENTIFIER_BIG_NAME: {
    id: 'BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_BIG_NAME',
    defaultMessage: 'Too long name',
  },
  CONTACT_ERROR_IDENTIFIER_EMPTY_REQUIRED_FIELDS: {
    id: 'BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_EMPTY_REQUIRED_FIELDS',
    defaultMessage: 'Empty required fields given',
  },
  DONGLE_ERROR_BOOTSTRAP_DONGLE_NOT_FOUND: {
    id: 'BackendErrorMessages.DONGLE_ERROR_BOOTSTRAP_DONGLE_NOT_FOUND',
    defaultMessage: 'Modem not found',
  },
  DONGLE_ERROR_DONGLE_CREATE_ORDER_NOT_FOUND: {
    id: 'BackendErrorMessages.DONGLE_ERROR_DONGLE_CREATE_ORDER_NOT_FOUND',
    defaultMessage: 'Please choose subscription plan for this modem',
  },
  DONGLE_ERROR_DONGLE_CREATE_ORDER_DONGLE_USED: {
    id: 'BackendErrorMessages.DONGLE_ERROR_DONGLE_CREATE_ORDER_DONGLE_USED',
    defaultMessage: 'This modem is already in use',
  },
  DONGLE_ERROR_DONGLE_CREATE_ORDER_FOUND: {
    id: 'BackendErrorMessages.DONGLE_ERROR_DONGLE_CREATE_ORDER_FOUND',
    defaultMessage:
      'You have payed subscriptions. Please choose subscription plan for this modem',
  },
  CONTACT_ERROR_INVALID_NUMBER: {
    id: 'BackendErrorMessages.CONTACT_ERROR_INVALID_NUMBER',
    defaultMessage: 'Incorrect phone format when working with a contact',
  },
  CALL_ERROR_INVALID_RECEIVER_NUMBER: {
    id: 'BackendErrorMessages.CALL_ERROR_INVALID_RECEIVER_NUMBER',
    defaultMessage: 'Incorrect phone format when working with a call',
  },
  MAILING_ERROR_IDENTIFIER_INVALID_SCHEDULED_AT: {
    id: 'BackendErrorMessages.MAILING_ERROR_IDENTIFIER_INVALID_SCHEDULED_AT',
    defaultMessage: 'Incorrect sending time when creating/editing a mailing',
  },
  MAILING_PHONES_ERROR_IDENTIFIER_INVALID_PHONE: {
    id: 'BackendErrorMessages.MAILING_PHONES_ERROR_IDENTIFIER_INVALID_PHONE',
    defaultMessage: 'Invalid phone number format given',
  },
})

export enum BackendErrorCodes {
  // Неверный логин/пароль при входе
  AUTH_ERROR_IDENTIFIER_USER_NOT_FOUND = 'AUTH_ERROR_IDENTIFIER_USER_NOT_FOUND',

  // Слабый пароль при регистрации
  AUTH_REGISTRATION_ERROR_IDENTIFIER_WEAK_PASSWORD = 'AUTH_REGISTRATION_ERROR_IDENTIFIER_WEAK_PASSWORD',
  // Некорректный email при регистрации
  AUTH_REGISTRATION_ERROR_IDENTIFIER_INVALID_EMAIL = 'AUTH_REGISTRATION_ERROR_IDENTIFIER_INVALID_EMAIL',
  // Пароли не совпадают при регистрации
  AUTH_REGISTRATION_ERROR_IDENTIFIER_NOT_SAME_PASSWORD_PASSWORD = 'AUTH_REGISTRATION_ERROR_IDENTIFIER_NOT_SAME_PASSWORD_PASSWORD',

  // Слабый пароль при смене пароля через "Забыл пароль"
  AUTH_FORGET_PASSWORD_ERROR_IDENTIFIER_WEAK_PASSWORD = 'AUTH_FORGET_PASSWORD_ERROR_IDENTIFIER_WEAK_PASSWORD',
  // Пароли не совпадают при смене пароля через "Забыл пароль"
  AUTH_FORGET_PASSWORD_ERROR_IDENTIFIER_NOT_SAME_PASSWORD = 'AUTH_FORGET_PASSWORD_ERROR_IDENTIFIER_NOT_SAME_PASSWORD',

  // Не заполнены обязательные поля при создании звонка
  CALL_ERROR_IDENTIFIER_EMPTY_FIELDS = 'CALL_ERROR_IDENTIFIER_EMPTY_FIELDS',
  // Не заполнены обязательные поля при создании СМС
  SMS_ERROR_IDENTIFIER_EMPTY_FIELDS = 'SMS_ERROR_IDENTIFIER_EMPTY_FIELDS',

  // Слабое название wifi-сети при редактировании WiFi-точки
  DONGLE_ERROR_IDENTIFIER_WEAK_HOTSPOT_SSID = 'DONGLE_ERROR_IDENTIFIER_WEAK_HOTSPOT_SSID',
  // Слабый пароль wifi-сети при редактировании WiFi-точки
  DONGLE_ERROR_IDENTIFIER_WEAK_HOTSPOT_PASSWORD = 'DONGLE_ERROR_IDENTIFIER_WEAK_HOTSPOT_PASSWORD',

  // Не заполнены обязательные поля при создании редиректа
  REDIRECTION_ERROR_IDENTIFIER_EMPTY_FIELDS = 'REDIRECTION_ERROR_IDENTIFIER_EMPTY_FIELDS',

  // Некорректный email при создании заявки на обратный звонок
  CALLBACK_REQUEST_ERROR_IDENTIFIER_INVALID_EMAIL = 'CALLBACK_REQUEST_ERROR_IDENTIFIER_INVALID_EMAIL',
  // Некорректный номер телефона при создании заявки на обратный звонок
  CALLBACK_REQUEST_ERROR_IDENTIFIER_INVALID_PHONE = 'CALLBACK_REQUEST_ERROR_IDENTIFIER_INVALID_PHONE',
  // Не заполнены обязательные поля при создании заявки на обратный звонок
  CALLBACK_REQUEST_ERROR_IDENTIFIER_EMPTY_FIELDS = 'CALLBACK_REQUEST_ERROR_IDENTIFIER_EMPTY_FIELDS',

  // Не совпадают пароли при изменении пароля в личном кабинете
  USER_ACCOUNT_ERROR_IDENTIFIER_NOT_SAME_PASSWORD = 'USER_ACCOUNT_ERROR_IDENTIFIER_NOT_SAME_PASSWORD',

  // Некорректное время отправки для отложенного сообщения
  SMS_ERROR_IDENTIFIER_INVALID_SCHEDULED_AT = 'SMS_ERROR_IDENTIFIER_INVALID_SCHEDULED_AT',

  // Когда ищем пользователя по разным критерям, и он не найден. Ошибка может вернуться в куче разных мест
  USER_ACCOUNT_ERROR_IDENTIFIER_USER_NOT_FOUND = 'USER_ACCOUNT_ERROR_IDENTIFIER_USER_NOT_FOUND',

  // Когда пользователь подтверждает регистрацию больше одного раза
  AUTH_ERROR_IDENTIFIER_USER_WAS_ALREADY_APPROVED = 'AUTH_ERROR_IDENTIFIER_USER_WAS_ALREADY_APPROVED',

  // Не все данные отправлены в метод получения нового токена для подтверждения почты
  AUTH_REGISTRATION_ERROR_IDENTIFIER_EMPTY_FIELDS = 'AUTH_REGISTRATION_ERROR_IDENTIFIER_EMPTY_FIELDS',

  // Не все данные переданы при попытке запросить новый токен для восстановления пароля
  AUTH_FORGET_PASSWORD_ERROR_IDENTIFIER_EMPTY_FIELDS = 'AUTH_FORGET_PASSWORD_ERROR_IDENTIFIER_EMPTY_FIELDS',

  // Когда юзер удаляет контакт, но при этом на этот контакт настроены редиректы
  CONTACT_ERROR_REDIRECTS_ARE_PRESENT_WHILE_DELETING_CONTACT = 'CONTACT_ERROR_REDIRECTS_ARE_PRESENT_WHILE_DELETING_CONTACT',

  // Когда юзер удаляет контакт группу, но при этом на этот контакт настроены редиректы
  CONTACT_ERROR_REDIRECTS_ARE_PRESENT_WHILE_DELETING_CONTACT_GROUP = 'CONTACT_ERROR_REDIRECTS_ARE_PRESENT_WHILE_DELETING_CONTACT_GROUP',

  // Неправильный replies_sms_id при создании реплая
  SMS_ERROR_ERROR_SMS_REPLY_FOUND = 'SMS_ERROR_ERROR_SMS_REPLY_FOUND',

  // Неправильный статус при обновлении смс
  SMS_ERROR_INVALID_SMS_STATUS = 'SMS_ERROR_INVALID_SMS_STATUS',

  // Не нашлась смс для редактирования
  SMS_ERROR_ERROR_SMS_NOT_FOUND = 'SMS_ERROR_ERROR_SMS_NOT_FOUND',

  // Длинное сообщение для смс
  SMS_ERROR_IDENTIFIER_LONG_MESSAGE = 'SMS_ERROR_IDENTIFIER_LONG_MESSAGE',

  // Недостаточно денег для оплаты заказа
  PAYMENT_ERROR_IDENTIFIER_NOT_ENOUGH_MONEY = 'PAYMENT_ERROR_IDENTIFIER_NOT_ENOUGH_MONEY',

  // Ошибка при создании или обновлении контакта
  CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_CONTACT = 'CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_CONTACT',

  // Пустой телефон при создании контакта
  CONTACT_ERROR_IDENTIFIER_ERROR_EMPTY_PHONE_NUMBER = 'CONTACT_ERROR_IDENTIFIER_ERROR_EMPTY_PHONE_NUMBER',

  // Ошибка при создании или обновлении группы контакта
  CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_CONTACT_GROUP = 'CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_CONTACT_GROUP',

  // Ошибка при удалении контакта
  CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_DELETING_CONTACT = 'CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_DELETING_CONTACT',

  // Ошибка при удалении контакт группы
  CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_DELETING_CONTACT_GROUP = 'CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_DELETING_CONTACT_GROUP',

  // Не найден контакт
  CONTACT_ERROR_IDENTIFIER_CONTACT_NOT_FOUND = 'CONTACT_ERROR_IDENTIFIER_CONTACT_NOT_FOUND',

  // Не найдена группа контакта
  CONTACT_ERROR_IDENTIFIER_CONTACT_GROUP_NOT_FOUND = 'CONTACT_ERROR_IDENTIFIER_CONTACT_GROUP_NOT_FOUND',

  // Ошибка при привязке контактов к группам
  CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_CONTACT_CONTACT_GROUPS = 'CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_CONTACT_CONTACT_GROUPS',

  // Ошибка при проставлении контактов смскам
  CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SMS_PROCESSING = 'CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SMS_PROCESSING',

  // Ошибка при проставлении контактов звонкам
  CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_CALL_PROCESSING = 'CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_CALL_PROCESSING',

  // Ошибка при обновлении или создании рассылки
  MAILING_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_MAILING = 'MAILING_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_MAILING',

  // Ошибка при удалении рассылки
  MAILING_ERROR_IDENTIFIER_ERROR_WHILE_DELETING_MAILING = 'MAILING_ERROR_IDENTIFIER_ERROR_WHILE_DELETING_MAILING',

  // Рассылка не найдена
  MAILING_ERROR_IDENTIFIER_ERROR_MAILING_NOT_FOUND = 'MAILING_ERROR_IDENTIFIER_ERROR_MAILING_NOT_FOUND',

  // Ошибка при создании телефонов рассылки
  MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_PHONES = 'MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_PHONES',

  // Ошибка при создании контактов рассылки
  MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_CONTACTS = 'MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_CONTACTS',

  // Неизвестный контакт при создании или редактировании рассылки
  MAILING_ERROR_IDENTIFIER_UNKNOWN_CONTACTS = 'MAILING_ERROR_IDENTIFIER_UNKNOWN_CONTACTS',

  // Неизвестная группа при создании или редактировании рассылки
  MAILING_ERROR_IDENTIFIER_UNKNOWN_CONTACT_GROUPS = 'MAILING_ERROR_IDENTIFIER_UNKNOWN_CONTACT_GROUPS',

  // Ошибка при создании контакт группы для рассылки
  MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_CONTACT_GROUPS = 'MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_CONTACT_GROUPS',

  // Недопустимый статус при обновлении рассылки
  MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_UPDATE = 'MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_UPDATE',

  // Недопустимый статус при создании рассылки
  MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_CREATE = 'MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_CREATE',

  // Недопустимый статус при удалении рассылки
  MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_DELETE = 'MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_DELETE',

  // Недопустимый статус при софт удалении рассылки
  MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_SOFT_DELETE = 'MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_SOFT_DELETE',

  // Превышен лимит донгла в день для рассылки
  MAILING_ERROR_IDENTIFIER_EXCEED_DONGLE_SMS_LIMIT = 'MAILING_ERROR_IDENTIFIER_EXCEED_DONGLE_SMS_LIMIT',

  // Ошибка при создании отметки о начале рассылки
  MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_HISTORY = 'MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_HISTORY',

  // Пустые контакты/телефоны/группы в рассылке
  MAILING_ERROR_IDENTIFIER_EMPTY_SENDERS = 'MAILING_ERROR_IDENTIFIER_EMPTY_SENDERS',

  // Есть уже такой контакт с таким номером
  CONTACT_ERROR_IDENTIFIER_ALREADY_EXISTS_SAME_NUMBER = 'CONTACT_ERROR_IDENTIFIER_ALREADY_EXISTS_SAME_NUMBER',

  // Есть уже такой контакт с таким именем
  CONTACT_ERROR_IDENTIFIER_ALREADY_EXISTS_SAME_NAME = 'CONTACT_ERROR_IDENTIFIER_ALREADY_EXISTS_SAME_NAME',

  // Некорретный пользователь
  CONTACT_ERROR_IDENTIFIER_INVALID_USER_ID = 'CONTACT_ERROR_IDENTIFIER_INVALID_USER_ID',

  // Длинный номер телефона
  CONTACT_ERROR_IDENTIFIER_BIG_PHONE = 'CONTACT_ERROR_IDENTIFIER_BIG_PHONE',

  // Длинное имя
  CONTACT_ERROR_IDENTIFIER_BIG_NAME = 'CONTACT_ERROR_IDENTIFIER_BIG_NAME',

  // Пустые обязательные поля
  CONTACT_ERROR_IDENTIFIER_EMPTY_REQUIRED_FIELDS = 'CONTACT_ERROR_IDENTIFIER_EMPTY_REQUIRED_FIELDS',

  // Заказ не найден при попытке подключить новый модем, но найден сам модем
  DONGLE_ERROR_BOOTSTRAP_DONGLE_NOT_FOUND = 'DONGLE_ERROR_BOOTSTRAP_DONGLE_NOT_FOUND',

  // Заказ не найден при попытке подключить новый модем, но найден сам модем
  DONGLE_ERROR_DONGLE_CREATE_ORDER_NOT_FOUND = 'DONGLE_ERROR_DONGLE_CREATE_ORDER_NOT_FOUND',

  // Модем уже используется
  DONGLE_ERROR_DONGLE_CREATE_ORDER_DONGLE_USED = 'DONGLE_ERROR_DONGLE_CREATE_ORDER_DONGLE_USED',

  // При попытке подключить новый модем был найден оплаченный заказ с подпиской, можно привязать его к модему
  DONGLE_ERROR_DONGLE_CREATE_ORDER_FOUND = 'DONGLE_ERROR_DONGLE_CREATE_ORDER_FOUND',

  // Неверный формат телефона при работе с контактом
  CONTACT_ERROR_INVALID_NUMBER = 'CONTACT_ERROR_INVALID_NUMBER',

  // Неверный формат телефона при работе со звонком
  CALL_ERROR_INVALID_RECEIVER_NUMBER = 'CALL_ERROR_INVALID_RECEIVER_NUMBER',

  // Неверный scheduled_at при создании/редактировании рассылки
  MAILING_ERROR_IDENTIFIER_INVALID_SCHEDULED_AT = 'MAILING_ERROR_IDENTIFIER_INVALID_SCHEDULED_AT',

  // Передан неверный формат телефона
  MAILING_PHONES_ERROR_IDENTIFIER_INVALID_PHONE = 'MAILING_PHONES_ERROR_IDENTIFIER_INVALID_PHONE',
}

export const useBackendErrorCodes = () => {
  const intl = useIntl()

  const resolveBackendError = (
    errorCode?: string | null,
    defaultErrorMessage?: string,
  ) => {
    if (!errorCode) return null

    switch (errorCode) {
      case BackendErrorCodes.AUTH_ERROR_IDENTIFIER_USER_NOT_FOUND:
        return intl.formatMessage(BackendErrorMessages.incorrectAuthCredentials)

      case BackendErrorCodes.AUTH_REGISTRATION_ERROR_IDENTIFIER_WEAK_PASSWORD:
        return intl.formatMessage(BackendErrorMessages.registerWeakPassword)
      case BackendErrorCodes.AUTH_REGISTRATION_ERROR_IDENTIFIER_INVALID_EMAIL:
        return intl.formatMessage(BackendErrorMessages.registerInvalidEmail)
      case BackendErrorCodes.AUTH_REGISTRATION_ERROR_IDENTIFIER_NOT_SAME_PASSWORD_PASSWORD:
        return intl.formatMessage(
          BackendErrorMessages.registerPasswordsNotMatch,
        )

      case BackendErrorCodes.AUTH_FORGET_PASSWORD_ERROR_IDENTIFIER_WEAK_PASSWORD:
        return intl.formatMessage(
          BackendErrorMessages.forgetPasswordWeakPassword,
        )
      case BackendErrorCodes.AUTH_FORGET_PASSWORD_ERROR_IDENTIFIER_NOT_SAME_PASSWORD:
        return intl.formatMessage(
          BackendErrorMessages.forgetPasswordPasswordsNotMatch,
        )

      case BackendErrorCodes.CALL_ERROR_IDENTIFIER_EMPTY_FIELDS:
        return intl.formatMessage(BackendErrorMessages.callEmptyRequiredFields)
      case BackendErrorCodes.SMS_ERROR_IDENTIFIER_EMPTY_FIELDS:
        return intl.formatMessage(BackendErrorMessages.smsEmptyRequiredFields)

      case BackendErrorCodes.DONGLE_ERROR_IDENTIFIER_WEAK_HOTSPOT_SSID:
        return intl.formatMessage(BackendErrorMessages.hotspotWeakName)
      case BackendErrorCodes.DONGLE_ERROR_IDENTIFIER_WEAK_HOTSPOT_PASSWORD:
        return intl.formatMessage(BackendErrorMessages.hotspotWeakPassword)

      case BackendErrorCodes.REDIRECTION_ERROR_IDENTIFIER_EMPTY_FIELDS:
        return intl.formatMessage(BackendErrorMessages.redirectionEmptyFields)

      case BackendErrorCodes.CALLBACK_REQUEST_ERROR_IDENTIFIER_INVALID_EMAIL:
        return intl.formatMessage(
          BackendErrorMessages.callbackRequestInvalidEmail,
        )
      case BackendErrorCodes.CALLBACK_REQUEST_ERROR_IDENTIFIER_INVALID_PHONE:
        return intl.formatMessage(
          BackendErrorMessages.callbackRequestInvalidPhone,
        )
      case BackendErrorCodes.CALLBACK_REQUEST_ERROR_IDENTIFIER_EMPTY_FIELDS:
        return intl.formatMessage(
          BackendErrorMessages.callbackRequestEmptyFields,
        )

      case BackendErrorCodes.USER_ACCOUNT_ERROR_IDENTIFIER_NOT_SAME_PASSWORD:
        return intl.formatMessage(
          BackendErrorMessages.changePasswordPasswordsNotMatch,
        )

      case BackendErrorCodes.SMS_ERROR_IDENTIFIER_INVALID_SCHEDULED_AT:
        return intl.formatMessage(
          BackendErrorMessages.sendSmsIncorrectScheduleDate,
        )

      case BackendErrorCodes.USER_ACCOUNT_ERROR_IDENTIFIER_USER_NOT_FOUND:
        return intl.formatMessage(
          BackendErrorMessages.USER_ACCOUNT_ERROR_IDENTIFIER_USER_NOT_FOUND,
        )
      case BackendErrorCodes.AUTH_ERROR_IDENTIFIER_USER_WAS_ALREADY_APPROVED:
        return intl.formatMessage(
          BackendErrorMessages.AUTH_ERROR_IDENTIFIER_USER_WAS_ALREADY_APPROVED,
        )
      case BackendErrorCodes.AUTH_REGISTRATION_ERROR_IDENTIFIER_EMPTY_FIELDS:
        return intl.formatMessage(
          BackendErrorMessages.AUTH_REGISTRATION_ERROR_IDENTIFIER_EMPTY_FIELDS,
        )
      case BackendErrorCodes.AUTH_FORGET_PASSWORD_ERROR_IDENTIFIER_EMPTY_FIELDS:
        return intl.formatMessage(
          BackendErrorMessages.AUTH_FORGET_PASSWORD_ERROR_IDENTIFIER_EMPTY_FIELDS,
        )
      case BackendErrorCodes.CONTACT_ERROR_REDIRECTS_ARE_PRESENT_WHILE_DELETING_CONTACT:
        return intl.formatMessage(
          BackendErrorMessages.CONTACT_ERROR_REDIRECTS_ARE_PRESENT_WHILE_DELETING_CONTACT,
        )
      case BackendErrorCodes.CONTACT_ERROR_REDIRECTS_ARE_PRESENT_WHILE_DELETING_CONTACT_GROUP:
        return intl.formatMessage(
          BackendErrorMessages.CONTACT_ERROR_REDIRECTS_ARE_PRESENT_WHILE_DELETING_CONTACT_GROUP,
        )
      case BackendErrorCodes.SMS_ERROR_ERROR_SMS_REPLY_FOUND:
        return intl.formatMessage(
          BackendErrorMessages.SMS_ERROR_ERROR_SMS_REPLY_FOUND,
        )
      case BackendErrorCodes.SMS_ERROR_INVALID_SMS_STATUS:
        return intl.formatMessage(
          BackendErrorMessages.SMS_ERROR_INVALID_SMS_STATUS,
        )
      case BackendErrorCodes.SMS_ERROR_ERROR_SMS_NOT_FOUND:
        return intl.formatMessage(
          BackendErrorMessages.SMS_ERROR_ERROR_SMS_NOT_FOUND,
        )
      case BackendErrorCodes.SMS_ERROR_IDENTIFIER_LONG_MESSAGE:
        return intl.formatMessage(
          BackendErrorMessages.SMS_ERROR_IDENTIFIER_LONG_MESSAGE,
        )
      case BackendErrorCodes.PAYMENT_ERROR_IDENTIFIER_NOT_ENOUGH_MONEY:
        return intl.formatMessage(
          BackendErrorMessages.PAYMENT_ERROR_IDENTIFIER_NOT_ENOUGH_MONEY,
        )
      case BackendErrorCodes.CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_CONTACT:
        return intl.formatMessage(
          BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_CONTACT,
        )
      case BackendErrorCodes.CONTACT_ERROR_IDENTIFIER_ERROR_EMPTY_PHONE_NUMBER:
        return intl.formatMessage(
          BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_ERROR_EMPTY_PHONE_NUMBER,
        )
      case BackendErrorCodes.CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_CONTACT_GROUP:
        return intl.formatMessage(
          BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_CONTACT_GROUP,
        )
      case BackendErrorCodes.CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_DELETING_CONTACT:
        return intl.formatMessage(
          BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_DELETING_CONTACT,
        )
      case BackendErrorCodes.CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_DELETING_CONTACT_GROUP:
        return intl.formatMessage(
          BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_DELETING_CONTACT_GROUP,
        )
      case BackendErrorCodes.CONTACT_ERROR_IDENTIFIER_CONTACT_NOT_FOUND:
        return intl.formatMessage(
          BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_CONTACT_NOT_FOUND,
        )
      case BackendErrorCodes.CONTACT_ERROR_IDENTIFIER_CONTACT_GROUP_NOT_FOUND:
        return intl.formatMessage(
          BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_CONTACT_GROUP_NOT_FOUND,
        )
      case BackendErrorCodes.CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_CONTACT_CONTACT_GROUPS:
        return intl.formatMessage(
          BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_CONTACT_CONTACT_GROUPS,
        )
      case BackendErrorCodes.CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SMS_PROCESSING:
        return intl.formatMessage(
          BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_SMS_PROCESSING,
        )
      case BackendErrorCodes.CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_CALL_PROCESSING:
        return intl.formatMessage(
          BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_ERROR_WHILE_CALL_PROCESSING,
        )
      case BackendErrorCodes.MAILING_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_MAILING:
        return intl.formatMessage(
          BackendErrorMessages.MAILING_ERROR_IDENTIFIER_ERROR_WHILE_SAVING_MAILING,
        )
      case BackendErrorCodes.MAILING_ERROR_IDENTIFIER_ERROR_WHILE_DELETING_MAILING:
        return intl.formatMessage(
          BackendErrorMessages.MAILING_ERROR_IDENTIFIER_ERROR_WHILE_DELETING_MAILING,
        )
      case BackendErrorCodes.MAILING_ERROR_IDENTIFIER_ERROR_MAILING_NOT_FOUND:
        return intl.formatMessage(
          BackendErrorMessages.MAILING_ERROR_IDENTIFIER_ERROR_MAILING_NOT_FOUND,
        )
      case BackendErrorCodes.MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_PHONES:
        return intl.formatMessage(
          BackendErrorMessages.MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_PHONES,
        )
      case BackendErrorCodes.MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_CONTACTS:
        return intl.formatMessage(
          BackendErrorMessages.MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_CONTACTS,
        )
      case BackendErrorCodes.MAILING_ERROR_IDENTIFIER_UNKNOWN_CONTACTS:
        return intl.formatMessage(
          BackendErrorMessages.MAILING_ERROR_IDENTIFIER_UNKNOWN_CONTACTS,
        )
      case BackendErrorCodes.MAILING_ERROR_IDENTIFIER_UNKNOWN_CONTACT_GROUPS:
        return intl.formatMessage(
          BackendErrorMessages.MAILING_ERROR_IDENTIFIER_UNKNOWN_CONTACT_GROUPS,
        )
      case BackendErrorCodes.MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_CONTACT_GROUPS:
        return intl.formatMessage(
          BackendErrorMessages.MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_CONTACT_GROUPS,
        )
      case BackendErrorCodes.MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_UPDATE:
        return intl.formatMessage(
          BackendErrorMessages.MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_UPDATE,
        )
      case BackendErrorCodes.MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_CREATE:
        return intl.formatMessage(
          BackendErrorMessages.MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_CREATE,
        )
      case BackendErrorCodes.MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_DELETE:
        return intl.formatMessage(
          BackendErrorMessages.MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_DELETE,
        )
      case BackendErrorCodes.MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_SOFT_DELETE:
        return intl.formatMessage(
          BackendErrorMessages.MAILING_ERROR_IDENTIFIER_INVALID_STATUS_FOR_SOFT_DELETE,
        )
      case BackendErrorCodes.MAILING_ERROR_IDENTIFIER_EXCEED_DONGLE_SMS_LIMIT:
        return intl.formatMessage(
          BackendErrorMessages.MAILING_ERROR_IDENTIFIER_EXCEED_DONGLE_SMS_LIMIT,
        )
      case BackendErrorCodes.MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_HISTORY:
        return intl.formatMessage(
          BackendErrorMessages.MAILING_ERROR_IDENTIFIER_UNABLE_TO_CREATE_HISTORY,
        )
      case BackendErrorCodes.MAILING_ERROR_IDENTIFIER_EMPTY_SENDERS:
        return intl.formatMessage(
          BackendErrorMessages.MAILING_ERROR_IDENTIFIER_EMPTY_SENDERS,
        )
      case BackendErrorCodes.CONTACT_ERROR_IDENTIFIER_ALREADY_EXISTS_SAME_NUMBER:
        return intl.formatMessage(
          BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_ALREADY_EXISTS_SAME_NUMBER,
        )
      case BackendErrorCodes.CONTACT_ERROR_IDENTIFIER_ALREADY_EXISTS_SAME_NAME:
        return intl.formatMessage(
          BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_ALREADY_EXISTS_SAME_NAME,
        )
      case BackendErrorCodes.CONTACT_ERROR_IDENTIFIER_INVALID_USER_ID:
        return intl.formatMessage(
          BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_INVALID_USER_ID,
        )
      case BackendErrorCodes.CONTACT_ERROR_IDENTIFIER_BIG_PHONE:
        return intl.formatMessage(
          BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_BIG_PHONE,
        )
      case BackendErrorCodes.CONTACT_ERROR_IDENTIFIER_BIG_NAME:
        return intl.formatMessage(
          BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_BIG_NAME,
        )
      case BackendErrorCodes.CONTACT_ERROR_IDENTIFIER_EMPTY_REQUIRED_FIELDS:
        return intl.formatMessage(
          BackendErrorMessages.CONTACT_ERROR_IDENTIFIER_EMPTY_REQUIRED_FIELDS,
        )

      case BackendErrorCodes.DONGLE_ERROR_BOOTSTRAP_DONGLE_NOT_FOUND:
        return intl.formatMessage(
          BackendErrorMessages.DONGLE_ERROR_BOOTSTRAP_DONGLE_NOT_FOUND,
        )
      case BackendErrorCodes.DONGLE_ERROR_DONGLE_CREATE_ORDER_NOT_FOUND:
        return intl.formatMessage(
          BackendErrorMessages.DONGLE_ERROR_DONGLE_CREATE_ORDER_NOT_FOUND,
        )
      case BackendErrorCodes.DONGLE_ERROR_DONGLE_CREATE_ORDER_DONGLE_USED:
        return intl.formatMessage(
          BackendErrorMessages.DONGLE_ERROR_DONGLE_CREATE_ORDER_DONGLE_USED,
        )
      case BackendErrorCodes.DONGLE_ERROR_DONGLE_CREATE_ORDER_FOUND:
        return intl.formatMessage(
          BackendErrorMessages.DONGLE_ERROR_DONGLE_CREATE_ORDER_FOUND,
        )

      case BackendErrorCodes.CONTACT_ERROR_INVALID_NUMBER:
        return intl.formatMessage(
          BackendErrorMessages.CONTACT_ERROR_INVALID_NUMBER,
        )
      case BackendErrorCodes.CALL_ERROR_INVALID_RECEIVER_NUMBER:
        return intl.formatMessage(
          BackendErrorMessages.CALL_ERROR_INVALID_RECEIVER_NUMBER,
        )
      case BackendErrorCodes.MAILING_ERROR_IDENTIFIER_INVALID_SCHEDULED_AT:
        return intl.formatMessage(
          BackendErrorMessages.MAILING_ERROR_IDENTIFIER_INVALID_SCHEDULED_AT,
        )
      case BackendErrorCodes.MAILING_PHONES_ERROR_IDENTIFIER_INVALID_PHONE:
        return intl.formatMessage(
          BackendErrorMessages.MAILING_PHONES_ERROR_IDENTIFIER_INVALID_PHONE,
        )
    }

    if (defaultErrorMessage) return defaultErrorMessage
    return intl.formatMessage(BackendErrorMessages.unknownError)
  }

  return { resolveBackendError }
}
