import React, { ReactNode } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

type Props = {
  children: ReactNode
  noPadding?: {
    all?: boolean
    top?: boolean
    bottom?: boolean
    right?: boolean
    left?: boolean
  }
}

export const Card = ({ children, noPadding }: Props) => (
  <div
    className={clsx(
      styles.Card,
      noPadding?.all && styles.Card_npAll,
      noPadding?.top && styles.Card_npTop,
      noPadding?.bottom && styles.Card_npBottom,
      noPadding?.right && styles.Card_npRight,
      noPadding?.left && styles.Card_npLeft,
    )}
  >
    {children}
  </div>
)
