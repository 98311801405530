import { SmsRoute } from './enums/smsRoute'
import { smsRules } from './route-descriptors/user/lk/subpages/sms/subpages/sms-rules/sms-rules'
import { RouteDescriptor } from './types/routeDescriptor'
import {CallsRoute} from "./enums/callsRoute";
import {callsList} from "./route-descriptors/user/lk/subpages/calls/subpages/calls-list/calls-list";
import {callsSettings} from "./route-descriptors/user/lk/subpages/calls/subpages/calls-settings/calls-settings";

export const callsRoutes: { [key in CallsRoute]: RouteDescriptor<key> } = {
  [CallsRoute.CallsList]: callsList,
  [CallsRoute.CallsSettings]: callsSettings,
}
