import { useEffect, useState } from 'react'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import { transactionListDatagate } from '../../datagates/api/billing'
import { useBackendErrorCodes } from '../../datagates/helpers/_common/use-backend-error-codes'
import { TransactionListResponse } from '../../datagates/types/billing/transaction/_crud/list'

type UseTransactionsProps = {
  page: number
  limit: number
}

export const useTransactions = (props: UseTransactionsProps) => {
  const { page, limit } = props

  const { resolveBackendError } = useBackendErrorCodes()

  const [loading, setLoading] = useState<boolean>(true)
  const [transactions, setTransactions] = useState<
    TransactionListResponse['transactions']
  >([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [errorText, setErrorText] = useState<string | null>(null)

  const handleFetch = async () => {
    try {
      setLoading(true)

      const queryParams = [
        { name: 'page', value: (page - 1).toString() },
        { name: 'limit', value: limit.toString() },
        { name: 'order_by', value: 'created_at' },
        { name: 'order_type', value: 'desc' },
      ]

      const { data } = await transactionListDatagate(null, null, queryParams)
      setTotalCount(data.totalCount)
      setTransactions(data.transactions)
    } catch (e) {
      setErrorText(resolveBackendError(getErrorMessage(e)))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void handleFetch()
  }, [page, limit])

  return {
    transactions,
    totalCount,
    loading,
    errorText,
    handleFetch,
  }
}
