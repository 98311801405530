import React, { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import binIcon from '../../../assets/images/bin.png'
import { CommonButtonMessages } from '../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import { LC } from '../../../tests/e2e/locators'
import styles from './styles.module.scss'

const DeleteItemModalMessages = defineMessages({
  title: {
    id: 'DeleteItemModalMessages.delete',
    defaultMessage: 'Are you sure you want to delete the {deletingItemName}?',
  },
  itemDefault: {
    id: 'DeleteItemModalMessages.itemDefault',
    defaultMessage: 'it',
  },
  description: {
    id: 'DeleteItemModalMessages.description',
    defaultMessage: 'This action cannot be undone',
  },
  delete: {
    id: 'DeleteItemModalMessages.delete',
    defaultMessage: 'Delete',
  },
})

export const DeleteItemModal = () => {
  const { handleHideModal, props } = useModal()

  const intl = useIntl()
  const {
    title,
    description,
    submitText,
    deletingItemName = intl.formatMessage(DeleteItemModalMessages.itemDefault),
  } = props

  const [submitting, setSubmitting] = useState<boolean>(false)

  const handleDelete = async () => {
    setSubmitting(true)
    await props?.handleSubmit()
    setSubmitting(false)
  }

  return (
    <Drawer isOpen={true} close={handleHideModal}>
      <div className={styles.Container}>
        <div className={styles.Bin}>
          <img src={binIcon} alt="" />
        </div>

        <div className={styles.Content}>
          <div className={styles.Title}>
            {title ??
              intl.formatMessage(DeleteItemModalMessages.title, {
                deletingItemName,
              })}
          </div>

          <div className={styles.Description}>
            {description ??
              intl.formatMessage(DeleteItemModalMessages.description)}
          </div>

          <div className={styles.Buttons}>
            <Button
              text={intl.formatMessage(CommonButtonMessages.cancel)}
              variant={'blackTextBlackBorderOutlined'}
              onClick={handleHideModal}
              disabled={submitting}
            />
            <Button
              text={
                submitText ?? intl.formatMessage(DeleteItemModalMessages.delete)
              }
              variant={'danger'}
              onClick={handleDelete}
              disabled={submitting}
              loading={submitting}
              icon={'trash'}
              testId={LC.MODAL.DELETE}
            />
          </div>
        </div>
      </div>
    </Drawer>
  )
}
