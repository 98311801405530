import { wrapApiRequest } from '../../helpers/_common/wrap-api-request'
import { mapDocumentGetBackendToDocumentGet } from '../../mappers/document/_crud/get/map-document-get-backend-to-document-get'
import {
  DocumentGetBackendResponse,
  DocumentGetRequest,
  DocumentGetResponse,
} from '../../types/document/_crud/get'

export const documentGetDatagate = wrapApiRequest<
  DocumentGetRequest,
  DocumentGetBackendResponse,
  DocumentGetResponse
>('document/{document_id}', mapDocumentGetBackendToDocumentGet, 'GET')
