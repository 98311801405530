import { useRef, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/submodal-close.svg'
import { useClickOutside } from '../../../../shared/lib/hooks/useClickOutside'
import { Card } from '../../../../shared/ui-kit-2/data-display/card/Card'
import { SegmentedControlOptionProps } from '../../../../shared/ui-kit-2/inputs/segmented-control/components/SegmentedControlOption'
import { SegmentedControl } from '../../../../shared/ui-kit-2/inputs/segmented-control/SegmentedControl'
import { SourceContacts } from './components/source-contacts/SourceContacts'
import { SourcePhones } from './components/source-phones/SourcePhones'
import styles from './styles.module.scss'

interface UseAddSourceProps {
  processOnSave: (redirectionSource: Source) => void
  sourceList: Source[]
  phoneSourceVariant?: PhoneSourceVariant
}

export type PhoneSourceVariant = 'international-only' | 'all'
type SourceType = 'phone' | 'contacts' | 'contact-groups' | 'all-contacts'

export type Source = {
  sourceType: SourceType
  // for phone numbers it is phone, for contacts/contact groups it is id
  sourceName: string
  sourceValue: string
  metaInformation?: any
}

const UseAddSourceMessages = defineMessages({
  title: {
    id: 'UseAddSourceMessages.title',
    defaultMessage: 'Add incoming number',
  },
  optionPhoneNumber: {
    id: 'UseAddSourceMessages.optionPhoneNumber',
    defaultMessage: 'Phone number',
  },
  optionContacts: {
    id: 'UseAddSourceMessages.optionContacts',
    defaultMessage: 'Contacts',
  },
  optionGroups: {
    id: 'UseAddSourceMessages.optionGroups',
    defaultMessage: 'Groups',
  },
  allContacts: {
    id: 'UseAddSourceMessages.allContacts',
    defaultMessage: 'All contacts',
  },
})

const SOURCE_TYPE_TOP_LEVEL_OPTIONS = {
  PHONES: 'phones',
  CONTACTS: 'contacts',
}

export const useAddSource = (props: UseAddSourceProps) => {
  const { processOnSave, sourceList, phoneSourceVariant = 'all' } = props

  const intl = useIntl()
  const [isOpen, setIsOpen] = useState<boolean>()
  const [option, setOption] = useState<string>(
    SOURCE_TYPE_TOP_LEVEL_OPTIONS.PHONES,
  )
  const ref = useRef<HTMLDivElement>(null)

  const onClose = () => {
    setOption(SOURCE_TYPE_TOP_LEVEL_OPTIONS.PHONES)
    setIsOpen(false)
  }

  useClickOutside(ref, onClose)

  const onSave = (source: Source) => {
    processOnSave(source)
    onClose()
  }

  const options: SegmentedControlOptionProps[] = [
    {
      label: intl.formatMessage(UseAddSourceMessages.optionPhoneNumber),
      value: SOURCE_TYPE_TOP_LEVEL_OPTIONS.PHONES,
      comparedValue: option,
      onClick: () => setOption(SOURCE_TYPE_TOP_LEVEL_OPTIONS.PHONES),
    },
    {
      label: intl.formatMessage(UseAddSourceMessages.optionContacts),
      value: SOURCE_TYPE_TOP_LEVEL_OPTIONS.CONTACTS,
      comparedValue: option,
      onClick: () => setOption(SOURCE_TYPE_TOP_LEVEL_OPTIONS.CONTACTS),
    },
  ]

  const component = (
    <div className={styles.Overlay}>
      <Card additionalClassNames={[styles.CardAdditional]} currentRef={ref}>
        <div className={styles.Header}>
          <div className={styles.Title}>
            {intl.formatMessage(UseAddSourceMessages.title)}
          </div>
          <CloseIcon className={styles.Close} onClick={onClose} />
        </div>

        <SegmentedControl name={'source-type-top-level'} group={options} />

        {option === SOURCE_TYPE_TOP_LEVEL_OPTIONS.PHONES && (
          <SourcePhones
            onSave={onSave}
            sourceList={sourceList}
            phoneSourceVariant={phoneSourceVariant}
          />
        )}
        {option === SOURCE_TYPE_TOP_LEVEL_OPTIONS.CONTACTS && (
          <SourceContacts
            onSave={onSave}
            sourceList={sourceList}
            onClose={onClose}
          />
        )}
      </Card>
    </div>
  )

  return {
    setIsOpen,
    component: isOpen ? component : null,
  }
}
